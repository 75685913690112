/**
 * Currency Formatter: Takes a currency code as input and returns the respective currency symbol
 *
 * @param {string} currencyCode - The 3-letter currency code like 'USD', 'EUR', etc.
 * @returns {string} - The currency symbol corresponding to the currency code.
 */
export function currencyFormatter(currencyCode) {
  const currencySymbols = {
    'USD': '$',
    'EUR': '€',
    'GBP': '£',
    'JPY': '¥',
    'AUD': 'A$',
    'CAD': 'C$',
    // Add more currencies as needed
  };

  // Return the symbol if it exists, otherwise return the original currency code
  return currencySymbols[currencyCode] || currencyCode;
}
