import React from 'react';
import { Formik } from 'formik';
import { isUndefined } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getSessionUserId } from 'services/session/selectors';
import { del } from 'services/rest';
import { notifyError, notifySuccess } from 'services/notification/actions';
import { editableTournamentWizardValidationSchema, initialTournamentWizardValidationSchema, } from './ValidationSchema';
import { TournamentWizardForm } from './TournamentWizardForm.component';
import { getInitialTournament } from './utils';
import { isTournamentUpcoming } from 'enums';
import { browserHistory } from 'services/history';
import styles from './styles.module.scss';
const requestUrl = '/tournaments/';
export const TournamentWizard = ({ onSubmit, values, defaultTab, id, }) => {
    const userId = useSelector(getSessionUserId);
    const dispatch = useDispatch();
    const handleFormDelete = async () => {
        try {
            await del(`${requestUrl}${id}`);
            dispatch(notifySuccess('Tournament deleted successfully!'));
            browserHistory.push('/tournaments');
        }
        catch (e) {
            console.error(e);
            dispatch(notifyError("Couldn't delete tournament!"));
        }
    };
    const initialTournament = getInitialTournament(userId, 'Scolia', values);
    const isEdit = !isUndefined(id);
    const isTournamentStartedOrFinished = !isUndefined(values?.state)
        ? !isTournamentUpcoming(values.state)
        : false;
    return (React.createElement("div", { className: styles.tournamentWizard },
        React.createElement(Formik, { initialValues: initialTournament, enableReinitialize: true, onSubmit: onSubmit, validationSchema: isTournamentStartedOrFinished
                ? editableTournamentWizardValidationSchema
                : initialTournamentWizardValidationSchema }, ({ handleChange, handleSubmit, handleBlur, setFieldValue, setFieldError, setErrors, values, errors, touched, }) => (React.createElement(TournamentWizardForm, { onChange: handleChange, onSubmit: handleSubmit, onBlur: handleBlur, onDelete: handleFormDelete, onSetFieldValue: setFieldValue, onSetFieldError: setFieldError, onSetErrors: setErrors, values: values, errors: errors, touched: touched, isEdit: isEdit, defaultTab: defaultTab, tournamentId: id })))));
};
