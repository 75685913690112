import cn from 'classnames';
import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { ThemesEnum } from 'enums';
import { Portal } from 'components/Portal';
import styles from './styles.module.scss';
const AbstractModal = ({ theme = ThemesEnum.Light, isOpen, onDismiss, allowWidening, className, backdropClassName, children, useDarkerBackdrop, }) => {
    return (React.createElement(CSSTransition, { unmountOnExit: true, in: isOpen, timeout: 300, classNames: {
            enter: styles['fade-enter'],
            enterActive: styles['fade-enter-active'],
            exit: styles['fade-exit'],
            exitActive: styles['fade-exit-active'],
        } },
        React.createElement("div", { className: cn(styles.backdrop, backdropClassName ? [backdropClassName] : undefined, {
                [styles.useDarkerBackdrop]: useDarkerBackdrop,
            }), onClick: e => {
                e.stopPropagation();
                e?.nativeEvent?.stopImmediatePropagation();
                onDismiss();
            } },
            React.createElement("div", { className: cn(styles.modal, className ? [className] : undefined, {
                    [styles[theme.toLocaleLowerCase()]]: theme,
                    [styles.wide]: allowWidening,
                }), onClick: e => e.stopPropagation() }, children))));
};
const ThemedAbstractModal = (props) => {
    return (React.createElement(Portal, null,
        React.createElement(AbstractModal, { ...props })));
};
export default ThemedAbstractModal;
