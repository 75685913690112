import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import cn from 'classnames';
import isoCountries from 'i18n-iso-countries';
import { isNull } from 'lodash-es';
import { boards as boardsResource } from 'constants/Resources';
import { getPreparedFiltersForSelectedFields } from 'utils';
import { getIsAdmin } from 'services/session/selectors';
import { BoardState } from 'constants/BoardState';
import { EntityTable } from 'components/EntityTable';
import { SSelect } from 'components/Form';
import formatLabel from 'utils/formatLabel';
import * as boardActions from './actions';
import { getFilter } from 'components/ListSelector';
const defaultSorted = [
    {
        id: 'name',
        desc: false,
    },
];
const BooleanFilter = ({ filter, onChange }) => (React.createElement("select", { onChange: (event) => onChange(event.target.value), style: { width: '100%' }, value: filter ? filter.value : 'All' },
    React.createElement("option", { value: "All" }, "All"),
    React.createElement("option", { value: "true" }, "True"),
    React.createElement("option", { value: "false" }, "False")));
const SBC_MODEL_OPTIONS = {
    pro: 'Pro',
    home: 'Home',
    home2: 'Home2',
};
const STATUS_OPTIONS = {
    Attached: 'Attached',
    ReadyToActivate: 'ReadyToActivate',
    InStock: 'InStock',
    Detached: 'Detached',
    Suspended: 'Suspended',
};
const SBC_TYPE_OPTIONS = {
    true: 'FakeSBC',
    false: 'SBC',
};
const ACCESSIBILITY_OPTIONS = {
    true: 'Public',
    false: 'Private',
};
const selectColumns = [
    'status',
    'isFakeSbc',
    'isPublic',
    'isHomeSbc',
    'isLimitedBoard',
    'sbcModel',
];
const mapStateToProps = (state) => ({
    data: state.data.boards,
    countries: state.data.countries,
    totalCount: state.scenes.boards.list.totalCount,
    isLoading: state.scenes.boards.list.isLoading,
    isAdmin: getIsAdmin(state),
    isDownloading: state.scenes.boards.list.isDownloading,
});
const connector = connect(mapStateToProps);
const List = ({ data, countries, isLoading, totalCount, isAdmin, isDownloading, dispatch, }) => {
    const columns = useMemo(() => [
        {
            Header: 'Name',
            accessor: 'name',
            filterable: true,
        },
        {
            Header: 'Serial Number',
            accessor: 'serialNumber',
            filterable: true,
            Cell: ({ value, row }) => {
                return React.createElement(Link, { to: `/boards/${row._original.id}` },
                    " ",
                    value,
                    " ");
            },
        },
        {
            Header: 'Country',
            id: 'addressData.countryAlpha2Code',
            accessor: 'addressData.country',
            filterable: true,
            Filter: ({ filter, onChange }) => (React.createElement(SSelect, { onChange: (event) => onChange(event.target.value), value: filter ? filter.value : '', options: countries.map((country) => ({
                    value: isoCountries.alpha3ToAlpha2(country.alpha3Code),
                    label: country.name,
                })) })),
            Cell: ({ value }) => {
                return React.createElement("div", null, value && value);
            },
        },
        {
            Header: 'Status',
            accessor: 'status',
            filterable: true,
            Filter: getFilter(STATUS_OPTIONS, false),
        },
        {
            Header: 'SBC Type',
            accessor: 'isFakeSbc',
            maxWidth: 110,
            filterable: true,
            Cell: ({ value }) => (React.createElement(Badge, { color: cn({ secondary: value, info: !value }) }, value ? 'FakeSBC' : 'SBC')),
            Filter: getFilter(SBC_TYPE_OPTIONS, false),
        },
        {
            Header: 'Accessibility',
            accessor: 'isPublic',
            maxWidth: 100,
            filterable: true,
            Cell: ({ value }) => (React.createElement(Badge, { color: cn({ info: value === true, dark: value === false }) }, value ? 'Public' : 'Private')),
            Filter: getFilter(ACCESSIBILITY_OPTIONS),
        },
        {
            Header: 'State',
            accessor: 'currentState.state',
            filterable: false,
            sortable: false,
            maxWidth: 70,
            Cell: ({ value }) => (React.createElement(Badge, { color: cn({
                    orange: value === BoardState.InGame.type,
                    success: value === BoardState.Online.type,
                    secondary: value === BoardState.Offline.type,
                    danger: value === BoardState.Error.type,
                    'orange-2': value === BoardState.Initializing.type || value === BoardState.Updating.type,
                    'blue-2': value === BoardState.Calibrating.type,
                }) }, value ? BoardState[value].label : '-')),
        },
        {
            Header: 'Board Code',
            accessor: 'currentState.boardCode',
            filterable: true,
            sortable: false,
            Cell: ({ value }) => (React.createElement(Badge, { color: cn({ dark: !isNull(value), light: isNull(value) }) }, value)),
        },
        {
            Header: 'SBC Model',
            accessor: 'sbcModel',
            filterable: true,
            Filter: getFilter(SBC_MODEL_OPTIONS),
            Cell: ({ value }) => {
                return React.createElement("div", null, value && formatLabel(value));
            },
        },
        {
            Header: 'Flex board',
            accessor: 'isLimitedBoard',
            filterable: true,
            maxWidth: 100,
            Cell: ({ value }) => value ? React.createElement(Badge, { color: 'secondary' }, "True") : null,
            Filter: BooleanFilter,
        },
        {
            Header: "Owner's E-mail",
            accessor: 'ownerEmail',
            sortable: true,
            filterable: true,
        },
        {
            Header: 'Firmware',
            accessor: 'firmware.lastReportedVersionNumber',
            sortable: true,
            filterable: true,
        },
        {
            Header: 'Hardware',
            accessor: 'hardwareVersion',
            sortable: true,
            filterable: true,
        },
        {
            Header: 'Platform',
            accessor: 'platformVersion',
            sortable: true,
            filterable: true,
        },
    ], [countries]);
    const handleFetchData = ({ filter, ...rest }) => {
        const clonedFilter = getPreparedFiltersForSelectedFields(filter, selectColumns);
        dispatch(boardActions.getBoards({ filter: clonedFilter, ...rest }));
    };
    const boards = Object.values(data);
    return (React.createElement("div", null,
        React.createElement(EntityTable, { resource: boardsResource, columns: columns, selectColumns: selectColumns, data: boards, defaultPageRowSize: 200, totalCount: totalCount, defaultSorted: defaultSorted, isLoading: isLoading, onFetchData: handleFetchData, createResourceButtons: [
                { value: 'New Board', link: '/boards/new' },
                { value: 'Create multiple Boards', link: '/boards/new/multiple', color: 'success' },
            ], quickFilters: [{ name: 'Flex only', filterId: 'isLimitedBoard', filterValue: 'true' }], customDownloadButtons: [
                {
                    name: "Export boards' location",
                    actionParams: {
                        resource: 'board-location',
                        columns: {
                            name: 'board_name',
                            'addressData.formattedAddress': 'address',
                            'addressData.geometry.lat': 'latitude',
                            'addressData.geometry.lng': 'longitude',
                        },
                    },
                },
                {
                    name: "Export boards' data",
                },
            ], isAdmin: isAdmin, isDownloading: isDownloading })));
};
List.displayName = 'Boards.List';
export default connector(List);
