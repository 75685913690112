import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import actions from './actions';
import { actions as downloadActions } from 'services/entityTable';
import { boardUpdateProgress } from 'constants/Resources';
import { boards } from 'constants/Resources';

const INITIAL_STATE = {
  isLoading: true,
  page: 0,
  totalCount: 0,
  pageSize: 0,
  isDownloading: false,
  isDownloadingUpdateProgress: false,
};

const list = handleActions(
  {
    [actions.getBoards]: (state, { _payload }) => {
      return update(state, {
        isLoading: {
          $set: true,
        },
      });
    },

    [actions.getBoardsSuccess]: (
      state,
      { payload: { page, totalCount, pageSize } }
    ) => ({
      page,
      pageSize,
      totalCount,
      isLoading: false,
    }),

    [actions.getBoardsFailure]: () => ({
      isLoading: false,
    }),

    [downloadActions.download]: (state, {payload: {resource}}) => {
      if(resource === boardUpdateProgress) {
        return update(state, {
          isDownloadingUpdateProgress: {
            $set: true,
          },
        });
      } else if(resource === boards) {
        return update(state, {
          isDownloading: {
            $set: true,
          },
        });
      } else {
        return state;
      }
    },

    [downloadActions.downloadSuccessful]: (state, {payload}) => {
      if(payload === boardUpdateProgress) {
        return update(state, {
          isDownloadingUpdateProgress: {
            $set: false,
          },
        });
      } else if(payload === boards) {
        return update(state, {
          isDownloading: {
            $set: false,
          },
        });
      }  else {
        return state;
      }
    }, 

    [downloadActions.downloadFailure]: (state, {payload}) => {
      if(payload === boardUpdateProgress) {
        return update(state, {
          isDownloadingUpdateProgress: {
            $set: false,
          },
        });
      } else if(payload === boards) {
        return update(state, {
          isDownloading: {
            $set: false,
          },
        });
      }  else {
        return state;
      }
    }, 
  },
  INITIAL_STATE
);

export default list;
