import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import { BoardForm } from 'scenes/Boards/components/BoardForm';
import { actions as hardwareActions } from 'scenes/Hardwares/components/List';
import { actions as platformActions } from 'scenes/Platforms/components/List'

import { getBoard } from 'scenes/Boards/actions';

import actions from './actions';


class Edit extends Component {
  componentDidMount() {
    const { board } = this.props;

    if (!board || board.__isStale) {
      this.props.dispatch(getBoard(this.boardId));
    }

    this.props.dispatch(hardwareActions.getHardwares());
    this.props.dispatch(platformActions.getPlatforms());
  }

  handleFormSubmit = (values, _actions) =>
    this.props.dispatch(
      actions.updateBoard({
        boardId: this.boardId,
        board: values,
      })
    );

  get boardId() {
    return this.props.match.params.boardId;
  }

  render() {
    const { board, formError } = this.props;

    if (!board || board.deletedAt) {
      return null;
    }

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" lg={{ size: 10, offset: 1 }}>
            <Card color="white">
              <CardHeader>
                <div className="card-header__left">
                  <i className="fa fa-align-justify" /> Edit Board
                </div>
              </CardHeader>
              <CardBody>
                <BoardForm
                  board={board}
                  formError={formError}
                  onSubmit={this.handleFormSubmit}
                  isEdit
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  board: state.data.boards[ownProps.match.params.boardId],
  formError: state.scenes.boards.edit.editError,
});

export default connect(mapStateToProps)(Edit);
