import React, { Component, createRef } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { connect } from 'react-redux';
import cn from 'classnames';
import { debounce } from 'lodash-es';

import Message from './components/Message/Message';
import actions from './actions';
import { getChatRoom } from './selectors';
import { getChatRooms } from '../List/actions';
import styles from './styles.module.scss';

export class Show extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scrollOffset: 0,
    };

    this.messagesEnd = createRef(null);
    this.containerRef = createRef(null);
  }
  componentDidMount() {
    const { match: {params: {roomId: id}} } = this.props;

    if (!this.props.room.participantCount) {
      this.props.dispatch(getChatRooms());
    }
    this.props.dispatch(actions.subscribeToGroup({ groupId: 'lobby', id: id}));
    this.props.dispatch(actions.getMessagesRequest({ groupId: 'lobby' }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.room.messages !== this.props.room.messages && this.state.scrollOffset < 30) {
      this.messagesEnd.scrollIntoView({ behavior: 'smooth' });
    }
  }

  componentWillUnmount() {
    this.props.dispatch(actions.unsubscribeToGroup({ groupId: 'lobby' }));
  }

  handleScroll = () => {
    if (this.containerRef.current) {
      const bottomOffset =
        this.containerRef.current.scrollHeight -
        (this.containerRef.current.scrollTop + this.containerRef.current.offsetHeight);
      this.setState({ scrollOffset: bottomOffset });
    }
  };

  render() {
    const {
      room: { id, messages, participantCount },
      isLoading,
      isParticipantsLoading,
    } = this.props;

    return (
      <div className="animated fadeIn">
        {(
          <Card color="white">
            <CardHeader>
              <div className="card-header__left">
                <i className="fa fa-align-justify" /> {id || 'lobby'}
                {isLoading && <i className="fa fa-spinner fa-spin fa-fw" />}
              </div>
              <div className={cn(styles.activeBoards, 'card-header__right')}>
                <div className={styles.activeBoardsWrapper}>
                  <div className={styles.labelWrapper}>PARTICIPANTS</div>
                  <span>
                    {(participantCount || participantCount === 0) && !isParticipantsLoading ? (
                      participantCount
                    ) : (
                      <i className="fa fa-spinner fa-spin fa-fw" />
                    )}
                  </span>
                  <button
                    className={styles.reloadButton}
                    disabled={isParticipantsLoading}
                    onClick={() => this.props.dispatch(getChatRooms())}
                  >
                    <i className="cui-reload" />
                  </button>
                </div>
              </div>
            </CardHeader>

            <CardBody>
              {isLoading ? (
                <i className="fa fa-spinner fa-spin fa-fw" />
              ) : (
                <div
                  className={styles.chatWrapper}
                  ref={this.containerRef}
                  onScroll={debounce(this.handleScroll, 300)}
                >
                  {messages.map(msg => (
                    <Message key={msg.id} message={msg}></Message>
                  ))}
                  <div
                    style={{ clear: 'both' }}
                    ref={el => {
                      this.messagesEnd = el;
                    }}
                  ></div>
                </div>
              )}
            </CardBody>
          </Card>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  room: getChatRoom(state, ownProps.match.params.roomId),
  isLoading: state.scenes.chat.show.isLoading,
  isParticipantsLoading: state.scenes.chat.show.isParticipantsLoading,
});

export default connect(mapStateToProps)(Show);
