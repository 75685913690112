import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import { Create } from './components/Create';
import { Edit } from './components/Edit';
import { List } from './components/List';
import { Show } from './components/Show';

class Boards extends Component {
  render() {
    const {
      match: { url },
    } = this.props;
    return (
      <Switch>
        <Route component={List} exact name="Board list" path={`${url}/`} />
        <Route component={Create} exact name="New board" path={`${url}/new`} />
        <Route
          render={props => <Create {...props} isMultiple={true} />}
          exact
          name="Create multiple Boards"
          path={`${url}/new/multiple`}
        />
        <Route
          component={Show}
          exact
          name="Board info"
          path={`${url}/:boardId`}
        />
        <Route
          component={Edit}
          exact
          name="Board edit"
          path={`${url}/:boardId/edit`}
        />
      </Switch>
    );
  }
}

export default Boards;
