import Date from './Date'
import Default from './Default'
import Link from './Link'
import Boolean from './Boolean'
import Enum from './Enum'
import { Select } from './Select.component'
import { Checkbox } from './Checkbox.component'
import { DatePicker } from './DatePicker.component'
import Button from './Button'
import EditableEmail from './EditableEmail'
import EditableToken from './EditableToken'
import Text from "./Text"
import Textarea from "./Textarea"
import { Slider } from './Slider.component'

export default {
  Default,
  DatePicker,
  Boolean,
  Date,
  Link,
  Enum,
  Select,
  Checkbox,
  Button,
  EditableEmail,
  EditableToken,
  Text,
  Textarea,
  Slider,
}