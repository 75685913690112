import { BullThrowEnum, ScoreCorrectionEnum } from "enums";
import { minToSec } from "utils";

export const TIMER_CUSTOM_VALUE = `custom`;

export const ScoreCorrectionOptions = [
  {
    label: ScoreCorrectionEnum.Approval,
    tooltip: "Players can initiate a Score Correction process, and their opponent can approve or decline the request based on the camera images of the initiator.",
    value: true,
  },
  {
    label: ScoreCorrectionEnum.Disabled,
    tooltip:  "Score Correction is not allowed on the tournament. Players can switch to the next player, but they can't add or modify throws.",
    value: false,
  }
]

export const BullThrowOptions = [
  {
    label: BullThrowEnum.Wdf,
    tooltip: "The player who throws nearer the centre of the dartboard shall throw first in the match. Players should throw again in reverse order if both darts are in the Bull, or if both darts are in the 25.",
    value: BullThrowEnum.Wdf,
  },
  {
    label: BullThrowEnum.Pdc,
    tooltip: "Only those darts that hit the Bull or 25 will count in deciding the order of play. Players should throw again in reverse order if both darts land in the same segment (Bull, 25, Out).",
    value: BullThrowEnum.Pdc,
  },
]

export const StartTimerOptions = [
  {
    label: '5 min',
    value: minToSec(5),
  },
  {
    label: '10 min',
    value: minToSec(10),
  },
  {
    label: '20 min',
    value: minToSec(20),
  },
  {
    label: 'Custom',
    value: TIMER_CUSTOM_VALUE,
  },
]

export const FinishTimerOptions = [
  {
    label: '15 min',
    value: minToSec(15),
  },
  {
    label: '30 min',
    value: minToSec(30),
  },
  {
    label: '45 min',
    value: minToSec(45),
  },
  {
    label: 'Custom',
    value: TIMER_CUSTOM_VALUE,
  },
]

export const PerVisitTimerOptions = [
  {
    label: '2 min',
    value: minToSec(2),
  },
  {
    label: '3 min',
    value: minToSec(3),
  },
  {
    label: '5 min',
    value: minToSec(5),
  },
  /* { for later purposes
    label: `None`,
    value: `none`,
  }, */
]
