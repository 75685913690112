import { call, put, takeLatest } from 'redux-saga/effects';

import { Products } from 'services/api';
import { callApi } from 'services/rest';

import {
  getProductsList,
  getProductsListFailure,
  getProductsListSuccess,
} from 'scenes/Products/components/List/actions';

function* watchGetProducts({ payload: { queryParams } }) {
  try {
    const { data } = yield call(callApi, Products.index, queryParams);
    yield put(getProductsListSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(getProductsListFailure());
  }
}

// It must be wrapped in a function, so execution will happen only on function call
export default () => takeLatest(getProductsList, watchGetProducts);
