import React from 'react';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import { isNullOrUndefined } from 'utils';
import styles from './styles.module.scss';
export const GlassTable = ({ title, className = '', children, placeholder, headerElements }) => {
    const isDataAvailable = !isNullOrUndefined(children);
    return (React.createElement("div", { className: cn(styles.glassTable, [className]) },
        React.createElement("div", { className: styles.glassTableHeader },
            React.createElement("div", { className: styles.title }, title),
            headerElements && !isEmpty(headerElements) && React.createElement("div", { className: styles.headerElementContainer }, headerElements)),
        isDataAvailable ? (React.createElement("table", { className: styles.glassTableBody }, children)) : (React.createElement("div", { className: styles.placeholder }, placeholder))));
};
