import { call, put, takeLatest } from 'redux-saga/effects';

import { Boards } from 'services/api';
import { callApi } from 'services/rest';

import {
  getBoardLimitedThrows,
  getBoardLimitedThrowsFailure,
  getBoardLimitedThrowsSuccess,
} from 'scenes/Boards/actions';

function* watchGetBoardLimitedThrows({ payload: { boardId, filters} }) {
  try {
    let { data } = yield call(callApi, Boards.limitedThrows, boardId, filters);
    data.boardId = boardId;
    yield put(getBoardLimitedThrowsSuccess(data));
  } catch (e) {
    yield put(getBoardLimitedThrowsFailure(e.isFetch ? e.data.error : e));
  }
}

export default () =>
  takeLatest(getBoardLimitedThrows, watchGetBoardLimitedThrows);
