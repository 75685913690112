import { take, call, select } from 'redux-saga/effects';

import { Socket, actions as wsActions } from 'services/ws';
import { browserHistory } from 'services/history';

import { OPENED } from 'constants/WsStatuses'

import {
  subscribeToGroup
} from 'scenes/Chat/components/Show/actions';

export default function* watchSubscribeToGroup() {
  while (true) {
    const { payload: { groupId, id } } = yield take(subscribeToGroup);

    if(id!=="lobby") browserHistory.push('/chat/');
    const payload = wsActions.Management.groupChatSubscribe({groupId});

    const wsConnection = yield select((state) => state.ws.status === OPENED);

    if(wsConnection){
      yield call([Socket, Socket.send], JSON.stringify(payload));
    }
    else{
      yield take(wsActions.initWsConnectionSuccess);
      yield call([Socket, Socket.send], JSON.stringify(payload));
    }
  }
}
