import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames'

import { SLabelContainer } from 'ScoliaComponents';

import { mapBoolPropsToClassnames as mapBPTC } from 'utils';
import { useScrollFade } from 'hooks';

import styles from './styles.module.scss';
import { isEmpty } from 'lodash';

const ImageRadio = ({ src, checked, name, onChange, value }) => {

  const handleSelect = e => {
    e.target?.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    onChange({ ...e, target: { name, value } })
  }

  return (
    <button
      onClick={handleSelect}
      className={cn(styles.imageRadio, mapBPTC(styles, { checked }))}
      type="button"
    >
      <img src={src} alt="background" />
    </button>
  )
}

const ImageWrapper = ({ label, name, error, hint, options, selectedValue, onChange }) => {
  const scrollRef = useRef()
  const { isFadeLeft, isFadeRight } = useScrollFade(scrollRef);

  return (
    <SLabelContainer className={styles.imageContainerContainer} label={label} name={name} nowrap={false} error={error} hint={hint}>
      <div className={cn(styles.imageContainer, mapBPTC(styles, { isFadeLeft, isFadeRight }))}>
        <div className={styles.imageWrapper} ref={scrollRef}>
          {isEmpty(options) ? (
            <span className={styles.placeholder}>Essential option is missing. Please inform the technical team.</span>
          ) : (
            options.map((image) => (
              <ImageRadio
                key={image.src}
                checked={selectedValue === image.value}
                onChange={onChange}
                name={name}
                {...image}
              />
            ))
          )}
        </div>
      </div>
    </SLabelContainer>
  )
}

ImageWrapper.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    src: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  error: PropTypes.string,
  hint: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default ImageWrapper