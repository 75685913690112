import { combineReducers } from 'redux';

import { reducer as boards } from './Boards';
import { reducer as onlineBoards } from './OnlineBoards';
import { reducer as login } from './Login';
import { reducer as feedbacks } from './Feedbacks';
import { reducer as firmwares } from './Firmwares';
import { reducer as hardwares } from './Hardwares';
import { reducer as users } from './Users';
import { reducer as resourceLogs } from './ResourceLogs';
import { reducer as rankingList } from './RankingList';
import { reducer as rankings } from './Rankings';
import { reducer as systemUsages } from './SystemUsages';
import { reducer as dashboard } from './Dashboard';
import { reducer as serviceAccounts } from './ServiceAccounts';
import { reducer as storeSnapshot } from './StoreSnapshot';
import { reducer as manufacturers } from './Manufacturers';
import { reducer as chat } from './Chat';
import { reducer as notifications } from './Notifications';
import { reducer as tournaments } from './Tournaments';
import { reducer as privateTournaments } from './PrivateTournaments';
import { reducer as commercialPartners } from './CommercialPartners';
import { reducer as subscriptions } from './Subscriptions';
import { reducer as products } from './Products';
import { reducer as tokenPurchases } from './TokenPurchases';
import { reducer as platforms } from './Platforms';
import { ScoreCorrectionStatisticsReducer as scoreCorrectionStatistics } from './ScoreCorrectionStatistics';

const scenes = combineReducers({
  boards,
  onlineBoards,
  feedbacks,
  firmwares,
  hardwares,
  login,
  resourceLogs,
  rankingList,
  systemUsages,
  users,
  dashboard,
  serviceAccounts,
  storeSnapshot,
  manufacturers,
  chat,
  notifications,
  tournaments,
  privateTournaments,
  rankings,
  commercialPartners,
  subscriptions,
  products,
  tokenPurchases,
  platforms,
  scoreCorrectionStatistics,
});

export default scenes;
