import {
  call,
  delay,
  cancel,
  cancelled,
  fork,
  select,
  take,
} from 'redux-saga/effects';

import { Socket, actions as wsActions } from 'services/ws';

import actions from 'scenes/Boards/components/Show/actions';
import { selectors } from 'scenes/Boards/components/Show';

// Minimum delay between image requests
// (it may be longer depending on the localizator and server response)
const FPS = 250;

function* streamImages(boardId) {
  try {
    while (true) {
      // Get the actual stream configuration from the store
      const config = yield select(selectors.getStreamConfig);
      // Assemble the payload that will be sent to the Api
      const payload = wsActions.Management.getCameraImages({
        boardId,
        ...config,
      });
      // Send the actual WS message (and start measuring the delay)
      const t0 = performance.now();
      yield call([Socket, Socket.send], JSON.stringify(payload));
      // Wait for the response (and finish the measure)
      yield take(wsActions.Api.cameraImages);
      const t1 = performance.now();
      const time = parseInt(t1 - t0, 0);

      // Make sure to never refresh images faster than the specified FPS
      if (time < FPS) {
        // Wait N milliseconds before the next round (~ refresh rate, currently 4 FPS)
        yield delay(FPS - time);
      }
    }
  } finally {
    if (yield cancelled()) {
      // TODO: Do some cleanup, eg. delete stored images from the localStorage
    }
  }
}

// REVIEW: https://redux-saga.js.org/docs/advanced/TaskCancellation.html
export default function* streamSaga() {
  while (true) {
    // Watch for stream starting requests
    const {
      payload: { boardId },
    } = yield take(actions.startStream);
    // Fork the streaming task, so that it is non-blocking
    const streamTask = yield fork(streamImages, boardId);
    // Wait for a stream finish request...
    yield take(actions.finishStream);
    // ... and cancel the streaming task run in the background
    yield cancel(streamTask);
  }
}
