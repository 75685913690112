import { createActions } from 'redux-actions';

const managementActions = createActions(
  {
    MGMT: {
      COMMON: {
        GET_CAMERA_IMAGES: undefined,
        GET_GROUP_CHAT_MESSAGES: undefined,
        GROUP_CHAT_SUBSCRIBE: undefined,
        GROUP_CHAT_UNSUBSCRIBE: undefined,
        RECALIBRATE: undefined,
        BOARD_UPDATES_SUBSCRIBE: undefined,
        BOARD_UPDATES_UNSUBSCRIBE: undefined,
        TOURNAMENT_UPDATES_SUBSCRIBE: undefined,
        TOURNAMENT_UPDATES_UNSUBSCRIBE: undefined,
      },
    }
  },
  { namespace: '::' }
);

export default managementActions.mgmt.common;
