/* eslint-disable react/no-string-refs */
import React, { Component } from 'react';

class CollapseList extends Component {
  constructor(props) {
    super(props);
    this.childRefs = [];
  }

  openAll() {
    this.childRefs.forEach(childRef => {
      if (childRef.current) {
        childRef.current.open();
      }
    });
  }

  closeAll() {
    this.childRefs.forEach(childRef => {
      if (childRef.current) {
        childRef.current.close();
      }
    });
  }

  onChangeHandler = () => {
    this.props.onChange();
  };

  isAllOpened = () => this.childRefs.every(childRef => childRef.current && childRef.current.isOpened());

  isAllClosed = () => this.childRefs.every(childRef => childRef.current && !childRef.current.isOpened());

  render() {
    this.childRefs = [];

    const children = React.Children.map(this.props.children, (child) => {
      const ref = React.createRef();
      this.childRefs.push(ref);
      return React.cloneElement(child, {
        ref: ref,
        onChange: this.onChangeHandler,
      });
    });

    return <div>{children}</div>;
  }
}

export default CollapseList;
