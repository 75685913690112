import { call, put, takeLatest } from 'redux-saga/effects';

import { Manufacturers } from 'services/api';
import { browserHistory } from 'services/history';
import { callApi } from 'services/rest';

import { getManufacturer, getManufacturerFailure, getManufacturerSuccess } from 'scenes/Manufacturers/actions';

function* watchGetManufacturer({ payload: { manufacturerId } }) {
  try {
    const { data } = yield call(callApi, Manufacturers.show, manufacturerId);
    yield put(getManufacturerSuccess(data));
  } catch (e) {
    yield put(getManufacturerFailure());
    browserHistory.push('/manufacturers');
  }
}

// It must be wrapped in a function, so execution will happen only on function call
export default () => takeLatest(getManufacturer, watchGetManufacturer);
