import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { GameTypeEnum } from 'enums';
import { formatDateNumber } from 'utils';

import styles from './styles.module.scss';


const GAME_TYPE_LABEL = {
  RaceTo: 'Race to',
  BestOf: 'Best of',
};

const GAME_OUT_TYPE_LABEL = {
  Double: 'D-Out',
  Straight: 'S-Out',
};

const GAME_IN_TYPE_LABEL = {
  Double: 'D-In',
  Straight: 'S-In',
};

export class LobbyInvitation extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  open() {
    this.setState({ isOpen: true });
  }

  close() {
    this.setState({ isOpen: false });
  }

  isOpened() {
    return this.state.isOpen;
  }

  get gameConfig() {

    const {
      game: {
        configuration: { gameOutType, gameInType, scoreType, startScore, sets, legs, gameMode, bullThrow },
        type: gameType
      },
      senderStarts,
      isIncoming
    } = this.props;


    switch (gameType) {
      case GameTypeEnum.X01:
        return (
          <div className={styles.gameConfig}>
            {startScore} • {GAME_TYPE_LABEL[scoreType]} {parseInt(sets) > 1 ? `${sets} sets,` : ''} {legs}
            {parseInt(legs) > 1 ? ' legs' : ' leg'} • {GAME_OUT_TYPE_LABEL[gameOutType]} {gameInType === 'Double' ? `• ${GAME_IN_TYPE_LABEL[gameInType]}` : ''} {bullThrow ? '• ' + bullThrow + ' rules' : (((senderStarts && !isIncoming) || (!senderStarts && isIncoming)) ? '• You start' : '• Opponent starts')}
          </div>
        )
      case GameTypeEnum.Cricket:
        return (
          <div className={styles.gameConfig}>
            Cricket • {GAME_TYPE_LABEL[scoreType]} {parseInt(sets) > 1 ? `${sets} sets,` : ''}  {legs} {parseInt(legs) > 1 ? ' legs' : ' leg'} • {gameMode === 'CutThroat' ? 'Cut-Throat' : gameMode}
            {bullThrow ? '• ' + bullThrow + ' rules' : (((senderStarts && !isIncoming) || (!senderStarts && isIncoming)) ? '• You start' : '• Opponent starts')}</div>
        )
      default:
        return <div className={styles.gameConfig}>Unhandled game type</div>
    }
  }

  render() {
    const { from, to, sentAt, handleDelete, id, status, resolvedAt} = this.props;
    const startDate = new Date(sentAt);
    const resolveDate = resolvedAt ?  new Date(resolvedAt) : null;
    return (
      <Fragment>
        <Row>
          <div className={styles.wrapper}>
            <Col xs={2}>
              <Link className={styles.fromUser} to={'users/' + from.userId}>{from.nickname}</Link>
            </Col>
            <Col xs={2}>
              <Link className={styles.toUser} to={'users/' + to.userId}>{to.nickname}</Link>
            </Col>
            <Col xs={3} >
              <div className={styles.startTime}>{startDate.getFullYear() +
                '.' +
                formatDateNumber(startDate.getMonth() + 1) +
                '.' +
                formatDateNumber(startDate.getDate()) +
                '.' +
                ' ' +
                formatDateNumber(startDate.getHours()) +
                ':' +
                formatDateNumber(startDate.getMinutes())}</div>
            </Col>
            <Col xs={2}>
              <div>{resolveDate ? startDate.getFullYear() +
                '.' +
                formatDateNumber(resolveDate.getMonth() + 1) +
                '.' +
                formatDateNumber(resolveDate.getDate()) +
                '.' +
                ' ' +
                formatDateNumber(resolveDate.getHours()) +
                ':' +
                formatDateNumber(resolveDate.getMinutes()) : 'null'}</div>
            </Col>
            <Col xs={1}>
              <div>{status}</div>
            </Col>
            {true && (
              <i
                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                className={cn(
                  styles.toggleIcon,
                  `fa fa-angle-${this.state.isOpen ? 'up' : 'down'}`
                )}
              />
            )}
          </div>
        </Row>

        <div
          className={cn([
            styles.border,
            { [styles.borderActive]: this.state.isOpen },
          ])}
        />
        <Collapse
          isOpen={this.state.isOpen}
          onEntering={this.props.onChange}
          onExiting={this.props.onChange}
        >
          <div className={styles.collapse}>
            {this.gameConfig}
            <i className={cn("fa fa-lg fa-trash", styles.deleteButton)} onClick={() => handleDelete(id)} aria-hidden="true"></i>

          </div>

        </Collapse>
        
      </Fragment>
    )
  }
}

LobbyInvitation.propTypes = {
  from: PropTypes.object.isRequired,
  to: PropTypes.object.isRequired,
  game: PropTypes.object.isRequired,
  sentAt: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  resolvedAt: PropTypes.string,
}

export default LobbyInvitation;
