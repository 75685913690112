import { createAction } from 'redux-actions';

export const getProduct = createAction('PRODUCT_GET_REQUEST');
export const getProductFailure = createAction('PRODUCT_GET_FAILURE');
export const getProductSuccess = createAction('PRODUCT_GET_SUCCESS');
export const resetProduct = createAction('PRODUCT_SHOW_RESET');

export default {
  getProduct,
  getProductFailure,
  getProductSuccess,
  resetProduct,
};
