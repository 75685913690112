import React, { useState, useEffect, useRef } from 'react';
import { isNull } from 'lodash';
import cn from 'classnames';
import AvatarEditor from 'react-avatar-editor';
import { ThemedModal } from 'components/ThemedModal';
import { ThemesEnum } from 'enums';
import { checkMobilePlatform } from 'utils';
import styles from './styles.module.scss';
import { useEvent } from 'react-use';
export const CropModal = ({ isOpen, onDismiss, onSubmit, image, theme = ThemesEnum.Light, borderRadius = 250, height = 250, width = 250, title, isRotatable = true, shouldResize = true, isDeclineHidden = false, }) => {
    const [rotate, setRotate] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [, setUpdate] = useState(0);
    const inputRef = useRef(null);
    const editorRef = useRef(null);
    const handleOrientation = () => setUpdate(x => x + 1);
    useEvent('orientationchange', handleOrientation);
    useEvent('resize', handleOrientation);
    useEffect(() => {
        if (isOpen) {
            document.getElementsByTagName('body')[0].style.height = '100%';
            document.getElementsByTagName('body')[0].style.overflow = 'hidden';
            document.getElementsByTagName('html')[0].style.height = '100%';
            document.getElementsByTagName('html')[0].style.overflow = 'hidden';
            setRotate(0);
            setZoom(1);
        }
        else {
            document.getElementsByTagName('body')[0].style.height = '';
            document.getElementsByTagName('body')[0].style.overflow = '';
            document.getElementsByTagName('html')[0].style.height = '';
            document.getElementsByTagName('html')[0].style.overflow = '';
        }
    }, [isOpen]);
    const handleRotateChange = () => {
        setRotate(rotate - 90);
    };
    const handleZoomChange = (e) => {
        setZoom(parseFloat(e.target.value));
    };
    const handleTap = (e) => {
        if (checkMobilePlatform()) {
            const value = (e.screenX - e.target.getBoundingClientRect().left) / e.target.offsetWidth + 1;
            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set;
            nativeInputValueSetter?.call(inputRef.current, value < 1 ? 1 : value > 2 ? 2 : value);
            const ev2 = new Event('input', { bubbles: true });
            if (isNull(inputRef?.current))
                return;
            (inputRef?.current).dispatchEvent(ev2);
        }
    };
    const handleOnSubmit = () => {
        if (editorRef.current) {
            const canvasScaled = shouldResize
                ? editorRef.current.getImageScaledToCanvas()?.toDataURL()
                : editorRef.current.getImage()?.toDataURL();
            onSubmit(canvasScaled);
        }
    };
    const CropFrameColors = {
        [ThemesEnum.Light]: [255, 255, 255, 0.6],
        [ThemesEnum.Astro]: [9, 35, 47, 0.6],
        [ThemesEnum.Dark]: [0, 0, 0, 0.6],
    };
    const modalContent = (React.createElement("div", { className: styles.cropModalContent },
        React.createElement(AvatarEditor, { border: 10, borderRadius: borderRadius, color: CropFrameColors[theme], width: width, height: height, image: image, ref: editorRef, rotate: rotate, scale: zoom }),
        React.createElement("div", { className: styles.actionContent },
            React.createElement("div", { className: styles.scale },
                React.createElement("label", { htmlFor: "zoom" },
                    "Scale image",
                    " "),
                React.createElement("div", { className: styles.inputContainer },
                    React.createElement("input", { defaultValue: "1", name: "zoom", type: "range", max: "2", min: "1", step: "0.01", ref: inputRef, onClick: handleTap, onChange: handleZoomChange, className: cn(styles.rangeInput, styles[theme]) }),
                    isRotatable && (React.createElement("i", { className: "sis-fw sis-crop_rotate", onClick: handleRotateChange })))))));
    return (React.createElement(ThemedModal, { className: styles.modal, title: title ?? "Position and size your photo", isOpen: isOpen, onDismiss: onDismiss, onAccept: handleOnSubmit, theme: theme, isDeclineHidden: isDeclineHidden }, modalContent));
};
