import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { Badge, Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import { Link } from 'react-router-dom';

import { deleteFirmware, downloadFirmware } from 'scenes/Firmwares/actions';

import { default as formatDate } from 'utils/formatDate';

import { getIsAdmin } from 'services/session/selectors';

import { getFirmwares } from './actions';

import styles from './styles.module.scss';

class List extends Component {
  componentDidMount() {
    this.props.dispatch(getFirmwares());
  }

  componentDidUpdate(prevProps) {
    if (this.props.file !== prevProps.file) {
      document.getElementById('myCheck').click();
    }
  }

  delete(id) {
    const result = window.confirm(
      'Are you sure you want to delete this firmware?'
    );
    if (result) {
      this.props.dispatch(deleteFirmware(id));
    }
  }

  download(id) {
    this.props.dispatch(downloadFirmware(id));
  }

  get firmwares() {
    const { firmwares, isLoading, isFirmwareDownloading, isAdmin } = this.props;

    if (isLoading || !firmwares) {
      return null;
    }

    return Object.keys(firmwares).map(firmwareId => {
      const firmware = firmwares[firmwareId];

      return (
        <tr key={firmware.id}>
          <td>
            <Link to={`/firmwares/${firmware.id}`}>
              {firmware.versionNumber}
            </Link>
          </td>
          <td>
            <Badge
              color={cn({
                success: firmware.isStable,
                warning: !firmware.isStable,
              })}
            >
              {firmware.isStable ? 'stable' : 'RC'}
            </Badge>
          </td>
          <td>{formatDate(firmware.releasedAt)}</td>
          <td>
            {this.getCompatibleVersion(firmware.compatibleHardwareDocuments)}
          </td>
          <td>
            {this.getCompatibleVersion(firmware.compatiblePlatformDocuments)}
          </td>
          <td>
            {isAdmin &&
              <Fragment>
                <a
                  style={{ display: 'none' }}
                  id="myCheck"
                  href={this.props.file}
                  download={this.props.filename}
                >
                  a
                </a>
                {isFirmwareDownloading ? (
                  <i className="fa fa-spinner fa-spin fa-fw" />
                ) : (
                  <i
                    className={cn('fa fa-download', styles.downloadButton)}
                    onClick={() => this.download(firmware.id)}
                  />
                )}

                {!firmware.isStable && (
                  <i
                    className={cn('fa fa-trash', styles.deleteButton)}
                    onClick={() => this.delete(firmware.id)}
                  />
                )}
              </Fragment>
            }
          </td>
        </tr>
      );
    });
  }

  getCompatibleVersion = docs => {
    return docs.map((hw, i) =>
      i < docs.length - 1 ? (
        <span key={hw.versionNumber + '_badge'}>
          <Badge
            color={cn({
              success: true,
            })}
          >
            {hw.versionNumber}
          </Badge>
          {', '}
        </span>
      ) : (
        <span key={hw.versionNumber + '_badge'}>
          <Badge
            color={cn({
              success: true,
            })}
          >
            {hw.versionNumber}
          </Badge>
        </span>
      )
    );
  };

  handleRefreshButtonClick = _e => this.props.dispatch(getFirmwares());

  render() {
    const { isLoading, isAdmin } = this.props;

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" lg={{ size: 10, offset: 1 }}>
            <Card color="white">
              <CardHeader>
                <div className="card-header__left">
                  <i className="fa fa-align-justify" /> Firmwares
                  {isLoading && <i className="fa fa-spinner fa-spin fa-fw" />}
                </div>
                <div className="card-header__right">
                  {isAdmin && <Link to="/firmwares/new">
                    <button className="btn btn-primary btn-md" type="button">
                      Create firmware
                    </button>
                  </Link>}
                  <button
                    className="btn btn-success btn-md ms-1"
                    disabled={isLoading}
                    onClick={this.handleRefreshButtonClick}
                  >
                    <i className="cui-reload" />
                  </button>
                </div>
              </CardHeader>

              <CardBody>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Version number</th>
                      <th>Stable</th>
                      <th>Released at</th>
                      <th>Compatible hardwares</th>
                      <th>Compatible platforms</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>{this.firmwares}</tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

List.displayName = 'Firmwares.List';

const mapStateToProps = state => ({
  firmwares: state.data.firmwares,
  isLoading: state.scenes.firmwares.list.isLoading,
  file: state.data.download.file,
  filename: state.data.download.filename,
  isFirmwareDownloading: state.scenes.firmwares.list.isDownloading,
  isAdmin: getIsAdmin(state),
});

export default connect(mapStateToProps)(List);
