export const getTokenPurchases = state => ({
  data: state.data.tokenPurchases,
  page: state.scenes.tokenPurchases.list.page,
  pageSize: state.scenes.tokenPurchases.list.pageSize,
  totalCount: state.scenes.tokenPurchases.list.totalCount,
  isLoading: state.scenes.tokenPurchases.list.isLoading,
  isDownloading: state.scenes.tokenPurchases.list.isDownloading
})

export default {
  getTokenPurchases,
};
