import { put, takeEvery } from 'redux-saga/effects';

export default function makeTypedNotificationWatcher({
  actions = [],
  actionCreator,
}) {
  if (typeof actionCreator !== 'function') {
    throw new TypeError(
      `Invalid actionCreator passed in! Expected function, got ${typeof actionCreator}`
    );
  }

  const watchNotification = function*({ payload }) {
    const action = actionCreator(payload);
    yield put(action);
  };

  // It must be wrapped in a function, so execution will happen only on function call
  return () => takeEvery(actions, watchNotification);
}
