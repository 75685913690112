import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader } from 'reactstrap';
import styles from './styles.module.scss';
import SessionLine from './components/SessionLine';
import { default as formatDate } from 'utils/formatDate';
import {
  getCalculatedOfflineSessionsInRows,
  getCalculatedOnlineSessions,
  getSessionsAreLoading,
  getSessionsForList,
  getIsHybridSbc,
} from './selectors';
import { DateRangeFilter } from 'components/DateRangeFilter';
import SessionList from './components/SessionList';

class ConnectionHistory extends PureComponent {
  state = {
    highlighter: {
      visible: false,
      percentageFrom: '',
      percentageWidth: '',
    },
  };

  handleHighlight = data => {
    const { highlighter } = this.state;

    this.setState({ highlighter: { ...highlighter, ...data } });
  };

  render() {
    const {
      isLoading,
      onlineSessions,
      offlineSessionsInRows,
      sessionsForList,
      isHybridSbc,
      onDateRangeChange,
      filters: { startDate, endDate },
    } = this.props;

    const { highlighter } = this.state;

    return (
      <div>
        <Card color="white">
          <CardHeader>
            <div className="card-header__left">
              <i className="fa fa-history" /> Connection History
            </div>
            <DateRangeFilter onDateRangeChange={onDateRangeChange} disabled={isLoading}/>
          </CardHeader>

          <CardBody>
            {!isLoading ? (
              <Fragment>
                <div className={styles.historyContainer}>
                  <div className={styles.sessionContainer}>
                    {highlighter.visible && (
                      <div
                        className={styles.highlighter}
                        style={{
                          left: highlighter.percentageFrom,
                          width: highlighter.percentageWidth,
                        }}
                      />
                    )}
                    {offlineSessionsInRows.map((row, index) => (
                      <div key={index} className={styles.historyRow}>
                        {row.map(session => (
                          <SessionLine
                            key={session.id}
                            session={session}
                            handleHighlight={this.handleHighlight}
                          />
                        ))}
                      </div>
                    ))}
                    <div className={styles.historyRow}>
                      {onlineSessions.map(session => (
                        <SessionLine
                          key={session.id}
                          session={session}
                          handleHighlight={this.handleHighlight}
                        />
                      ))}
                    </div>
                  </div>

                  <div className={styles.timeLine}>
                    <div>{formatDate(startDate)}</div>
                    <div>{formatDate(endDate)}</div>
                  </div>
                </div>
                <SessionList sessions={sessionsForList} isHybridSbc={isHybridSbc} />
              </Fragment>
            ) : (
              <i className="fa fa-spinner fa-spin fa-fw" />
            )}
          </CardBody>
        </Card>
      </div>
    );
  }
}

ConnectionHistory.propTypes = {
  boardId: PropTypes.string.isRequired,
  onDateRangeChange: PropTypes.func.isRequired,
  onlineSessions: PropTypes.array.isRequired,
  offlineSessionsInRows: PropTypes.array.isRequired,
  sessionsForList: PropTypes.array.isRequired,
  isHybridSbc: PropTypes.bool,
  isLoading: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => ({
  offlineSessionsInRows: getCalculatedOfflineSessionsInRows(state, ownProps),
  onlineSessions: getCalculatedOnlineSessions(state, ownProps),
  sessionsForList: getSessionsForList(state, ownProps),
  isLoading: getSessionsAreLoading(state),
  isHybridSbc: getIsHybridSbc(state, ownProps),
});

export default connect(mapStateToProps)(ConnectionHistory);
