import React, { useRef } from 'react'
import cn from 'classnames'
import { useSelector } from 'react-redux'

import { Link } from 'react-router-dom'

import ConditionalWrapper from 'components/ConditionalWrapper/ConditionalWrapper'

import { TournamentStageLabels } from 'constants/Tournament'
import { TournamentStageType } from 'enums'

import styles from './styles.module.scss'

const Matches = ({getTournamentSelector}) => {
  const containerRef = useRef(null);
  const { participants, stages } = useSelector(getTournamentSelector);

  const handleClickLeft = () => {
    if( containerRef?.current?.clientWidth > 0 ) {
      containerRef.current.scrollTo({
        left: containerRef.current.scrollLeft - 200,
        behavior: 'smooth',
      })
    }
  };

  const handleClickRight = () => {
    if( containerRef?.current?.clientWidth > 0 ) {
      containerRef.current.scrollTo({
        left: containerRef.current.scrollLeft + 200,
        behavior: 'smooth',
      })
    }
  };

  if( !stages ) {
    return <i className="fa fa-spinner fa-spin fa-fw" />
  }

  return (
    <div>
      <div className={styles.matchesContainer} ref={containerRef} >
        <div className={styles.stagesWrapper}>
          {Object.values(TournamentStageType).reverse().map(element => {
              if ( !stages[element] || stages[element]?.length === 0 )
                return null;

              return (
                <div className={styles.stageContainer} key={element} >
                  <div className={styles.stageName}>
                    {TournamentStageLabels[element]}
                  </div>
                  {stages[element].map(match => match.matchId < 1 ? null : (
                  <div className={cn(styles.matchContainer, {[styles.inactive]: match.state === 'TBD' || match.state === 'Finished'})} key={match.matchId}>
                      <div>{match.matchId}</div>
                      <div className={cn(styles.match, {
                        [styles.isTBD]: match.state === 'TBD',
                        [styles.isOngoing]: match.state === 'Ongoing',
                        [styles.isUpcoming]: match.state === 'Upcoming',
                        [styles.isFinished]: match.state === 'Finished',
                        [styles.isCancelled]: match.absentParticipantIds?.length === match.participantIds?.length,
                      })} key={match.matchId}>
                        <div className={cn(styles.participant, {
                          [styles.isWinner]: match.winnerPlayerUserId === match?.participantIds[0] && match?.participantIds[0],
                        })}>
                          <div>
                            <ConditionalWrapper
                              condition={match?.participantIds[0]}
                              wrapper={children => (
                                <Link to={`/users/${match?.participantIds[0]}`}>
                                  {match.absentParticipantIds.includes(match?.participantIds[0]) ? <i className='fa fa-fw fa-ban' /> : null} {children}
                                </Link>
                              )}
                            >
                              {match?.participantIds[0] ? participants[match?.participantIds[0]]?.nickname : 'TBA'}
                            </ConditionalWrapper>
                          </div>
                          <div>{Number.isNaN(match?.participantsMetaData[0]?.score) || (['TBD', 'Upcoming'].includes(match.state)) ? '-' : match?.participantsMetaData[0]?.score}</div>
                        </div>
                        <div className={cn(styles.participant, {
                          [styles.isWinner]: match.winnerPlayerUserId === match?.participantIds[1] && match?.participantIds[1],
                        })}>
                          <div>
                            <ConditionalWrapper
                              condition={match?.participantIds[1]}
                              wrapper={children => (
                                <Link to={`/users/${match?.participantIds[1]}`}>
                                  {match.absentParticipantIds.includes(match?.participantIds[1]) ? <i className='fa fa-fw fa-ban' /> : null} {children}
                                </Link>
                              )}
                            >
                              {match?.participantIds[1] ? participants[match?.participantIds[1]]?.nickname : 'TBA'}
                            </ConditionalWrapper> 
                          </div>
                          <div>{Number.isNaN(match?.participantsMetaData[1]?.score) || (['TBD', 'Upcoming'].includes(match.state)) ? '-' : match?.participantsMetaData[1]?.score}</div>
                        </div>
                      </div>
                  </div>
                  ))}
                </div>
              )
            })
          }
        </div>
      </div>
      <div className={styles.scrollBtnContainer}>
        <button onClick={handleClickLeft}>
          <i className='fa fa-fw fa-arrow-left' />
        </button>
        <button onClick={handleClickRight}>
          <i className='fa fa-fw fa-arrow-right' />
        </button>
      </div>
    </div>
  )
}

export default Matches;
