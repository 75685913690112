import { call, delay, put, takeLatest } from 'redux-saga/effects';

import { Boards } from 'services/api';
import { callApi } from 'services/rest';

import {
  getBoardSessions,
  getBoardSessionsFailure,
  getBoardSessionsSuccess,
} from 'scenes/Boards/actions';

function* watchGetBoardSessions({ payload: { boardId, isHybridSbc, filters } }) {
  try {
    let { data: sessions } = yield call(callApi, Boards.sessions, boardId, filters);
    let { data: offlineSessions } = isHybridSbc
      ? yield call(callApi, Boards.offlineSessions, boardId, filters)
      : { data: [] };

    let res = {
      boardId: boardId,
      sessions,
      offlineSessions,
    };

    yield delay(200);
    yield put(getBoardSessionsSuccess(res));
  } catch (e) {
    yield put(getBoardSessionsFailure(e.isFetch ? e.data.error : e));
  }
}

export default () => takeLatest(getBoardSessions, watchGetBoardSessions);
