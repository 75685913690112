import { isUndefined } from "lodash-es";

/**
 * Merge the given date and time strings into a single Date object.
 *
 * @param {string} dateStr - The date string in the format "YYYY-MM-DD".
 * @param {string} timeStr - The time string in the format "HH:MM".
 * @returns {Date} - The merged Date object.
 * @throws {Error} - If the date or time format is invalid.
 */
export function mergeDateTime(dateStr, timeStr) {
  if (isUndefined(dateStr) || isUndefined(timeStr)) {
    return undefined
  }

  // Validate date format (YYYY-MM-DD)
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateRegex.test(dateStr)) {
    console.error('Invalid date format. Expected format: YYYY-MM-DD');
    return undefined
  }

  // Validate time format (HH:MM)
  const timeRegex = /^(2[0-3]|[01]?[0-9]):[0-5][0-9]$/;
  if (!timeRegex.test(timeStr)) {
    console.error('Invalid time format. Expected format: HH:MM');
    return undefined
  }

  const [year, month, day] = dateStr.split('-').map(Number);
  const [hours, minutes] = timeStr.split(':').map(Number);

  const date = new Date(year, month - 1, day, hours, minutes);
  return date;
}
