import React from 'react';
import cn from 'classnames';
import { uniqueId } from 'lodash';
import { Tooltip } from 'react-tooltip';
import styles from './styles.module.scss';
export const SLabel = ({ className = '', isRequired = false, htmlFor, children, nowrap = true, tooltip, ...rest }) => {
    const id = uniqueId();
    return (React.createElement(React.Fragment, null,
        React.createElement("label", { htmlFor: htmlFor, className: cn(styles.SLabel, [className], {
                [styles.isRequired]: isRequired,
                [styles.nowrap]: nowrap,
                [styles.hasTooltip]: !!tooltip,
            }), ...rest, "data-tooltip-id": `label_${id}`, "data-tooltip-content": tooltip, "data-tooltip-class-name": styles.tooltip }, children),
        React.createElement(Tooltip, { id: `label_${id}`, place: "top-start" })));
};
SLabel.displayName = 'SLabel';
export default SLabel;
