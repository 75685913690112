import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import actions from './actions';
import { actions as downloadActions } from 'services/entityTable';
import { boardRankings } from 'constants/Resources';

const INITIAL_STATE = {
  data: null,
  isLoading: true,
  page: 0,
  pageSize: 0,
  totalCount: 0,
  isDownloading: false,
  priceOptions: {
    isLoading: false,
    options: null,
  },
};

const list = handleActions(
  {
    [actions.getSubscriptionsList]: (state, { _payload }) =>
      update(state, {
        isLoading: {
          $set: true,
        },
      }),

    [actions.getSubscriptionsListSuccess]: (state, { payload: { page, pageSize, totalCount, data } }) =>
      update(state, {
        data: { $set: data },
        page: { $set: page },
        pageSize: { $set: pageSize },
        totalCount: { $set: totalCount },
        isLoading: { $set: false },
      }),

    [actions.getSubscriptionsListFailure]: (state, { _payload }) =>
      update(state, {
        data: { $set: null },
        page: { $set: 0 },
        pageSize: { $set: 0 },
        totalCount: { $set: 0 },
        isLoading: { $set: false },
      }),

    [downloadActions.download]: (state, { payload: { resource } }) => {
      if (resource === boardRankings) {
        return update(state, {
          isDownloading: {
            $set: true,
          },
        });
      } else {
        return state;
      }
    },

    [downloadActions.downloadSuccessful]: (state, { payload }) => {
      if (payload === boardRankings) {
        return update(state, {
          isDownloading: {
            $set: false,
          },
        });
      } else {
        return state;
      }
    },

    [downloadActions.downloadFailure]: (state, { payload }) => {
      if (payload === boardRankings) {
        return update(state, {
          isDownloading: {
            $set: false,
          },
        });
      } else {
        return state;
      }
    },

    [actions.getPriceOptions]: (state, { _payload }) =>
      update(state, {
        priceOptions: {
          isLoading: { $set: true },
          options: { $set: null }
        }
      }),

    [actions.getPriceOptionsSuccess]: (state, { payload }) =>
      update(state, {
        priceOptions: {
          isLoading: { $set: false },
          options: { $set: payload }
        }
      }),

    [actions.getPriceOptionsFailure]: (state, { _payload }) =>
      update(state, {
        priceOptions: {
          isLoading: { $set: false },
          options: { $set: {} }
        }
      }),
  },
  INITIAL_STATE
);

export default list;
