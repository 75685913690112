import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { List } from './components/List'

const TokenPurchases = ({ match: { url } }) => {
  return (
    <Switch>
      <Route
        component={List}
        exact
        name="Token purchases"
        path={`${url}/`}
      />
    </Switch>
  )
}

export default TokenPurchases