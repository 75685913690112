import React, { Component } from 'react';
import Select from 'react-select';
import { Badge, Alert, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import formatDate from 'utils/formatDate';

import styles from './styles.module.scss';
export default class ConnectedBoard extends Component {
  render() {
    const {
      error,
      readOnly,
      board,
      boards,
      isSuspended,
      isForwarding,
      onSerialNumberChange,
      onIsSuspendedChange,
      onIsForwardingChange,
      onSerialNumberInputChange,
      onDelete,
      index,
    } = this.props;

    return (
      <div className={styles.connectedBoard}>
        {readOnly ? (
          <Row>
            <Col sm={4}>
              <Link to={`/boards/${board.boardId}`}>{board.serialNumber}</Link>
            </Col>
            <Col sm={3}>
              <Badge
                color={cn({
                  secondary: board.isSuspended,
                  success: !board.isSuspended,
                })}
              >
                {isSuspended ? 'Suspended' : 'Active'}
              </Badge>
            </Col>
            <Col sm={3}>
              <Badge
                color={cn({
                  secondary: !isForwarding,
                  success: isForwarding,
                })}
              >
                {isForwarding ? 'Enabled' : 'Disabled'}
              </Badge>
            </Col>
            <Col sm={2}>
              <span>{board.attachedAt ? formatDate(board.attachedAt) : '-'}</span>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col md={6} style={{ paddingRight: '0px' }}>
              <Select
                name="board"
                value={board}
                onChange={board =>
                  onSerialNumberChange(index, { ...board, ...board })
                }
                options={boards}
                getOptionLabel={option => option.serialNumber}
                getOptionValue={option => option.boardId}
                onInputChange={onSerialNumberInputChange}
              />
            </Col>
            <Col md={2} style={{ textAlign: 'center', paddingLeft: '0px' }}>
              <input
                style={{ marginTop: '10px' }}
                disabled={!board.serialNumber}
                checked={isSuspended}
                name="isSuspended"
                type="checkbox"
                onChange={() => onIsSuspendedChange(index)}
              />
            </Col>
            <Col md={2} style={{ textAlign: 'center', paddingLeft: '0px' }}>
              <input
                style={{ marginTop: '10px' }}
                disabled={!board.serialNumber}
                checked={isForwarding}
                name="isForwarding"
                type="checkbox"
                onChange={() => onIsForwardingChange(index)}
              />
            </Col>
            <Col md={2} style={{ padding: '12px' }}>
              <i
                className={cn(styles.deleteButton, `fa fa-trash fa-lg`)}
                onClick={() => onDelete(index)}
              />
            </Col>
            <Row>
              <Col>
                {error && (
                  <Alert color="danger">
                    <i className="fa fa-exclamation-triangle" />
                    {error.serialNumber}
                  </Alert>
                )}
              </Col>
            </Row>
          </Row>
        )}
      </div>
    );
  }
}

ConnectedBoard.propTypes = {
  error: PropTypes.object,
  readOnly: PropTypes.bool,
  board: PropTypes.object,
  boards: PropTypes.array.isRequired,
  isSuspended: PropTypes.bool,
  isForwarding: PropTypes.bool.isRequired,
  onSerialNumberChange: PropTypes.func.isRequired,
  onIsSuspendedChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};
