import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from 'recharts';
import { Card, CardBody, Col, Row } from 'reactstrap';
import cn from 'classnames';

import { formatNumberWithThousandSeparators } from 'utils';
import { tooltipFormatter } from 'utils/tooltipFormatter';

import styles from './styles.module.scss';

const LABELS = {
  finished: 'Finished',
  aborted: 'Aborted',
  forsaken: 'Forsaken',
  x01Total: 'X01',
  atcTotal: 'ATC',
  bobs27Total: "Bob's 27",
  cricketTotal: "Cricket",
  randomCricketTotal: "Random Cricket",
  shanghaiTotal: "Shanghai",
};

const FINITENESS_COLORS = ['#20A8DA', '#A5A8AA', '#cccecf'];
const TYPES_COLORS = ['#63C2DE', '#3B5998'];

const RADIAN = Math.PI / 180;

class Games extends Component {
  constructor(props) {
    super(props);

    this.state = { isAnimationActive: true };
  }

  componentDidMount() {
    setTimeout(() => this.setState({ isAnimationActive: false }), 1900);
  }

  get gamesByFiniteness() {
    const {
      finished,
      aborted,
      forsaken,
    } = this.props.boardStatistics.general.gamesCount;

    return [
      { name: LABELS['finished'], value: finished },
      { name: LABELS['aborted'], value: aborted },
      { name: LABELS['forsaken'], value: forsaken },
    ];
  }

  get gamesByType() {
    const {
      x01: { total: x01Total },
      aroundTheClock: { total: atcTotal },
      bobs27: { total: bobs27Total },
      cricket: { total: cricketTotal },
      shanghai: { total: shanghaiTotal },
      randomCricket: { total: randomCricketTotal },
    } = this.props.boardStatistics;

    return [
      { name: LABELS['x01Total'], value: x01Total },
      { name: LABELS['atcTotal'], value: atcTotal },
      { name: LABELS['bobs27Total'], value: bobs27Total },
      { name: LABELS['cricketTotal'], value: cricketTotal },
      { name: LABELS['randomCricketTotal'], value: randomCricketTotal },
      { name: LABELS['cricketTotal'], value: shanghaiTotal },
    ];
  }

  renderFinitenessChartLabel = (args) => this.renderChartLabel(args, 0);

  renderTypesChartLabel = (args) => this.renderChartLabel(args, 1);

  renderChartLabel = (
    { cx, cy, midAngle, innerRadius, outerRadius, percent, index },
    chartId
  ) => {
    const innerLabelRadius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const innerLabelX = cx + innerLabelRadius * Math.cos(-midAngle * RADIAN);
    const innerLabelY = cy + innerLabelRadius * Math.sin(-midAngle * RADIAN);

    const outerLabelRadius = innerRadius + (outerRadius - innerRadius) * 1.1;
    const outerLabelX = cx + outerLabelRadius * Math.cos(-midAngle * RADIAN);
    const outerLabelY = cy + outerLabelRadius * Math.sin(-midAngle * RADIAN);

    return (
      <Fragment>
        <text
          x={innerLabelX}
          y={innerLabelY}
          fill="white"
          textAnchor={innerLabelX > cx ? 'start' : 'end'}
          dominantBaseline="central"
        >
          {`${(percent * 100).toFixed(0)}%`}
        </text>
        <text
          x={outerLabelX}
          y={outerLabelY}
          fill="black"
          textAnchor={outerLabelX > cx ? 'start' : 'end'}
          dominantBaseline="central"
        >
          {chartId === 0
            ? this.gamesByFiniteness[index].name
            : this.gamesByType[index].name}
        </text>
      </Fragment>
    );
  };

  get renderGamesFinitenessChart() {
    return (
      <ResponsiveContainer width="100%" height={180}>
        <PieChart width={400} height={400}>
          <Pie
            data={this.gamesByFiniteness}
            labelLine={false}
            label={this.renderFinitenessChartLabel}
            isAnimationActive={this.state.isAnimationActive}
            outerRadius={60}
            fill="#8884d8"
            dataKey="value"
          >
            {this.gamesByFiniteness.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={FINITENESS_COLORS[index % FINITENESS_COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip formatter={(value) => tooltipFormatter(value, this.gamesByFiniteness)} />
        </PieChart>
      </ResponsiveContainer>
    );
  }

  get renderGamesTypeChart() {
    return (
      <ResponsiveContainer width="100%" height={180}>
        <PieChart width={400} height={400}>
          <Pie
            data={this.gamesByType}
            labelLine={false}
            label={this.renderTypesChartLabel}
            isAnimationActive={this.state.isAnimationActive}
            outerRadius={60}
            fill="#8884d8"
            dataKey="value"
          >
            {this.gamesByType.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={TYPES_COLORS[index % TYPES_COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip formatter={(value) => tooltipFormatter(value, this.gamesByFiniteness)} />
        </PieChart>
      </ResponsiveContainer>
    );
  }

  render() {
    const {
      total, online,
    } = this.props?.boardStatistics?.general?.gamesCount || {};

    const percentage = ((online / total) * 100).toFixed(0);

    return (
      <Card color="white">
        <CardBody>
          <Fragment>
            <Row>
              <Col xs={6}>
                <strong className={cn('text-value h2', styles.textValue)}>
                  { formatNumberWithThousandSeparators(total) }
                </strong>
                <span className="text-muted text-uppercase fw-bold">
                  Total Games
                </span>
              </Col>
              <Col xs={6}>
                <div className={cn('text-muted text-end', styles.cardHeader)}>
                  All Games
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6} key={'onlineGamesStat'}>
                <div className={cn('callout')}>
                  <div className={styles.calloutValueContainer}>
                    <span className={styles.calloutValue}>
                      { formatNumberWithThousandSeparators(online) }
                    </span>
                    <span className={styles.calloutValuePercentage}>
                      {percentage + '%'}
                    </span>
                  </div>
                  <small className="text-muted text-uppercase fw-bold">
                    Online
                  </small>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={6}>
                <strong
                  className={
                    'text-muted text-uppercase text-center fw-bold'
                  }
                >
                  Games Finiteness
                </strong>
                {this.renderGamesFinitenessChart}
              </Col>
              <Col sm={12} lg={6}>
                <strong
                  className={
                    'text-muted text-uppercase text-center fw-bold'
                  }
                >
                  Games Type
                </strong>
                {this.renderGamesTypeChart}
              </Col>
            </Row>
          </Fragment>
        </CardBody>
      </Card>
    );
  }
}

Games.propTypes = {
  boardStatistics: PropTypes.object.isRequired,
};

export default Games;
