import { createAction } from 'redux-actions';

export const logIn = createAction('LOG_IN_REQUEST');
export const logInFailure = createAction('LOG_IN_FAILURE');
export const logInSuccess = createAction('LOG_IN_SUCCESS');
export const logOut = createAction('LOG_OUT');

export default {
  logIn,
  logInFailure,
  logInSuccess,
  logOut,
};
