import { all, take, race, call } from 'redux-saga/effects';

import watchDeleteTournament from './watchers/DeleteTournament';
import watchGetTournament from './watchers/GetTournament';
import watchSubscribeToTournamentUpdates from './watchers/SubscribeToTournamentUpdates';
import watchUnsubscribeToTournamentUpdates from './watchers/UnsubscribeToTournamentUpdates';

import tournamentSaga from './workers/tournament';

import apiActions from 'services/ws/actions/Api';
import { subscribeToTournamentUpdates, unsubscribeToTournamentUpdates } from '../actions';

export default function* showSaga() {
  yield all([
    watchDeleteTournament(),
    watchGetTournament(),
    watchSubscribeToTournamentUpdates(),
    watchUnsubscribeToTournamentUpdates(),
    subscribeSaga(),
  ]);
}

function* subscribeSaga() {
  while (true) {
    yield take(subscribeToTournamentUpdates);
  
    yield race({
      saga: call(publishSaga),
      leave: take(unsubscribeToTournamentUpdates),
    });
  }
}

function* publishSaga() {
  while (true) {
    const message = yield take(apiActions.publish);

    yield call(tournamentSaga, message.payload);
  }
}
