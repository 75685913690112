import { DateTime } from 'luxon';

export function getCurrentDateTime(now: DateTime  =  DateTime.now(), language: string = "en") {
  const year = now.year;
  const month = now.month; // Adjusting because Luxon's month is 1-indexed, but the original function seems to expect 0-indexed
  const day = now.day;
  const monthString = now.setLocale(language).toFormat('MMMM'); // Formats the month as a long string (e.g., "January")
  const hour = now.hour;
  const minute = now.minute;

  return { year, month, day, monthString, hour, minute };
}

export function getWeeksInMonth(year: number, month: number): { day: number; month: number }[][] {
  let days: { day: number; month: number }[] = [];

  // Start from the first day of the month
  let currentDate = DateTime.local(year, month, 1);

  // Adjust to the start of the week
  currentDate = currentDate.startOf('week');

  // Determine the last day to include in the view
  const lastDayOfMonthView = DateTime.local(year, month).endOf('month').endOf('week');

  // Fill the days array
  while (currentDate <= lastDayOfMonthView) {
    days.push({ day: currentDate.day, month: currentDate.month });
    currentDate = currentDate.plus({ days: 1 });
  }

  // Group days into weeks
  const weeks: { day: number; month: number }[][] = [];
  let week: { day: number; month: number }[] = [];

  for (const day of days) {
    week.push(day);
    if (week.length === 7) {
        weeks.push(week);
        week = [];
    }
  }

  // In case the last week is not complete (less than 7 days)
  if (week.length > 0) {
      weeks.push(week);
  }

  return weeks;
}