import {
  PrivateTournamentThumbnails,
  PrivateTournamentThumbnailOptions,
} from 'constants/ScoliaThumbnails';

export const isDefaultThumbnail = (imageName) => {
  return Object.values(PrivateTournamentThumbnails).includes(imageName);
};

export const getThumbnailImage = (image) => {
  if (isDefaultThumbnail(image)) {
    return PrivateTournamentThumbnailOptions.find(
      (elem) => elem.value === image,
    ).src;
  }

  return image;
};
