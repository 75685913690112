import {
  PrivateTournamentInviteReplyType,
  TournamentParticipantStatusEnum,
} from 'enums';
import { Tournament } from 'interfaces';
import { ParticipantUpdateEvent } from 'typeDefs';

export const handleChangeParticipantReply = (
  tournament: Tournament,
  payload: ParticipantUpdateEvent,
) => {
  const { userId } = payload.participant;

  const newTournament = { ...tournament };

  if (
    newTournament.participantsOrder &&
    !newTournament.participantsOrder?.includes(payload.participant.userId)
  ) {
    newTournament.participantsOrder.push(payload.participant.userId);
  }

  if (!newTournament.participants[userId]) {
    newTournament.participants[userId] = payload.participant;
  }

  if (payload.reply === PrivateTournamentInviteReplyType.Accept) {
    newTournament.participants[userId].status =
      TournamentParticipantStatusEnum.Accepted;
  } else if (payload.reply === PrivateTournamentInviteReplyType.Decline) {
    if (newTournament.organiser.organiserId === userId) {
      delete newTournament.participants[userId];
      newTournament.participantsOrder = newTournament.participantsOrder?.filter(
        (participantId) => participantId !== userId,
      );
    } else {
      newTournament.participants[userId].status =
        TournamentParticipantStatusEnum.Declined;
    }
  }

  return newTournament;
};
