import { call, put, takeLatest } from 'redux-saga/effects';

import { ServiceAccounts } from 'services/api';

import { browserHistory } from 'services/history';
import { callApi } from 'services/rest';

import {
  updateServiceAccount,
  updateServiceAccountFailure,
  updateServiceAccountSuccess,
} from 'scenes/ServiceAccounts/components/Edit/actions';

function* watchEditServiceAccount({ payload: { serviceAccountId, serviceAccount } }) {
  try {
    const { data: updatedServiceAccount } = yield call(callApi, ServiceAccounts.update, serviceAccountId, serviceAccount);
    yield put(updateServiceAccountSuccess(updatedServiceAccount));
    browserHistory.push(`/service-accounts/${serviceAccountId}`);
  } catch (e) {
    if (!e.isFetch) {
      throw e;
    }
    yield put(updateServiceAccountFailure(e.data.error));
  }
}

export default () => takeLatest(updateServiceAccount, watchEditServiceAccount);
