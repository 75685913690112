import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Badge, Card, CardBody, CardHeader } from 'reactstrap';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { deleteFirmware, downloadFirmware } from 'scenes/Firmwares/actions';
import { default as formatDate } from 'utils/formatDate';

class MainInfo extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hardwares: Object.entries(this.props.hardwares).reduce(
        (acc, [key, value]) => {
          acc.push({ value: key, label: value.versionNumber });
          return acc;
        },
        []
      ),
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.file !== prevProps.file) {
      document.getElementById('myCheck').click();
    }
  }

  delete(id) {
    const result = window.confirm(
      'Are you sure you want to delete this firmware?'
    );
    if (result) {
      this.props.dispatch(deleteFirmware(id));
    }
  }

  download(id) {
    this.props.dispatch(downloadFirmware(id));
  }

  getCompatibleVersion = docs => {
    return docs.map((doc, i) =>
      i < docs.length - 1 ? (
        <span key={doc.versionNumber + '_badge'}>
          <Badge
            color={cn({
              success: true,
            })}
          >
            {doc.versionNumber}
          </Badge>
          {', '}
        </span>
      ) : (
        <span key={doc.versionNumber + '_badge'}>
          <Badge
            color={cn({
              success: true,
            })}
          >
            {doc.versionNumber}
          </Badge>
        </span>
      )
    );
  };

  render() {
    const {
      versionNumber,
      releasedAt,
      lastModifiedAt,
      description,
      isStable,
      id,
      uploadedByDocument,
      originalFileName,
      generatedFileName,
      fileSize,
      checksumHash,
      isFirmwareDownloading,
      isAdmin,
    } = this.props;

    return (
      <div>
        <Card color="white">
          <CardHeader>
            <div className="card-header__left">
              <i className="fa fa-align-justify" /> Firmware Data
            </div>
            {isAdmin && <div className="card-header__right">
              <Link to={`/firmwares/${id}/edit`}>
                <button className="btn btn-primary btn-sm" type="button">
                  Edit Firmware
                </button>
              </Link>
              <a
                style={{ display: 'none' }}
                id="myCheck"
                href={this.props.file}
                download={this.props.filename}
              >
                a
              </a>
              {isFirmwareDownloading ? (
                <button
                  className="btn btn-success btn-sm ms-1"
                  disabled={isFirmwareDownloading}
                  type="button"
                >
                  <i className="fa fa-spinner fa-spin fa-fw" />
                </button>
              ) : (
                <button
                  className="btn btn-success btn-sm ms-1"
                  disabled={isStable}
                  type="button"
                  onClick={() => this.download(id)}
                >
                  Download Firmware
                </button>
              )}

              <button
                className="btn btn-danger btn-sm ms-1"
                disabled={isStable}
                type="button"
                onClick={() => this.delete(id)}
              >
                Delete Firmware
              </button>
            </div>}
          </CardHeader>

          <CardBody>
            <div className="key-value-data-container">
              <div className="row">
                <div className="col-sm-4">Version Number</div>
                <div className="col-sm-8">
                  <strong>{versionNumber || 'N/A'}</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">Release Date</div>
                <div className="col-sm-8">{formatDate(releasedAt)}</div>
              </div>
              <div className="row">
                <div className="col-sm-4">Stability</div>
                <div className="col-sm-8">
                  <Badge
                    color={cn({
                      success: isStable,
                      warning: !isStable,
                    })}
                  >
                    {isStable ? 'Stable' : 'RC'}
                  </Badge>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">Compatible hardware</div>
                <div className="col-sm-8">
                  {this.getCompatibleVersion(this.props.compatibleHardwareDocuments) || 'N/A'}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">Compatible platform</div>
                <div className="col-sm-8">
                  {this.getCompatibleVersion(this.props.compatiblePlatformDocuments) || 'N/A'}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">Description</div>
                <div className="col-sm-8">{description || '-'}</div>
              </div>

              <hr className="mt-lg-5" />

              <div className="row">
                <div className="col-sm-4">Original file name</div>
                <div className="col-sm-8">{originalFileName || '-'}</div>
              </div>
              <div className="row">
                <div className="col-sm-4">Generated file name</div>
                <div className="col-sm-8">{generatedFileName || '-'}</div>
              </div>
              <div className="row">
                <div className="col-sm-4">File size</div>
                <div className="col-sm-8">
                  {fileSize ? fileSize + ' Byte' : '-'}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">Checksum hash</div>
                <div className="col-sm-8">{checksumHash || '-'}</div>
              </div>
              <div className="row">
                <div className="col-sm-4">Uploaded by</div>
                <div className="col-sm-8">
                  {uploadedByDocument ? uploadedByDocument.email : '-'}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">Last modified</div>
                <div className="col-sm-8">
                  {formatDate(lastModifiedAt, true)}
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

MainInfo.propTypes = {
  versionNumber: PropTypes.string,
  releasedAt: PropTypes.string,
  description: PropTypes.string,
  lastModifiedAt: PropTypes.string,
  isStable: PropTypes.bool,
  compatibleHardware: PropTypes.array,
  compatibleHardwareDocuments: PropTypes.array,
  compatiblePlatformDocuments: PropTypes.array,
  hardwares: PropTypes.object,
  uploadedByDocument: PropTypes.object,
  originalFileName: PropTypes.string,
  generatedFileName: PropTypes.string,
  fileSize: PropTypes.number,
  checksumHash: PropTypes.string,
  isAdmin: PropTypes.bool,
};

export default connect()(MainInfo);
