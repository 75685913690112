import { TournamentPlaceTitles } from "enums";

export const PrizePoolPlaceholders = {
  [TournamentPlaceTitles.winner]: "Enter prize for 1st place",
  [TournamentPlaceTitles.runnerUp]: "Enter prize for 2nd place",
  [TournamentPlaceTitles.semiFinalists]: "Enter prize for 3rd places",
  [TournamentPlaceTitles.top8]: "Enter prize for Top 8 places",
  [TournamentPlaceTitles.top16]: "Enter prize for Top 16 places",
  [TournamentPlaceTitles.top32]: "Enter prize for Top 32 places",
  [TournamentPlaceTitles.top64]: "Enter prize for Top 64 places",
  [TournamentPlaceTitles.top128]: "Enter prize for Top 128 places",
  [TournamentPlaceTitles.top256]: "Enter prize for Top 256 places",
}