import { createAction } from 'redux-actions';

export const configureStream = createAction('STREAM_CONFIGURE');

export const deleteBoard = createAction('BOARD_DELETE_REQUEST');
export const deleteBoardFailure = createAction('BOARD_DELETE_FAILURE');
export const deleteBoardSuccess = createAction('BOARD_DELETE_SUCCESS');

export const upgradeLimitedBoardService = createAction('UPGRADE_LIMITED_BOARD_SERVICE_REQUEST');
export const upgradeLimitedBoardServiceFailure = createAction('UPGRADE_LIMITED_BOARD_SERVICE_FAILURE');
export const upgradeLimitedBoardServiceSuccess = createAction('UPGRADE_LIMITED_BOARD_SERVICE_SUCCESS');

export const changeTestMode = createAction('CHANGE_TEST_MODE_REQUEST');
export const changeTestModeFailure = createAction('CHANGE_TEST_MODE_FAILURE');
export const changeTestModeSuccess = createAction('CHANGE_TEST_MODE_SUCCESS');

export const changeWifiConfiguration = createAction('CHANGE_WIFI_CONFIGURATION_REQUEST');
export const changeWifiConfigurationFailure = createAction('CHANGE_WIFI_CONFIGURATION_FAILURE');
export const changeWifiConfigurationSuccess = createAction('CHANGE_WIFI_CONFIGURATION_SUCCESS');

export const finishStream = createAction('STREAM_FINISH');

export const getSnapshot = createAction('SNAPSHOT_GET_REQUEST');
export const getSnapshotFailure = createAction('SNAPSHOT_GET_FAILURE');
export const getSnapshotSuccess = createAction('SNAPSHOT_GET_SUCCESS');

export const boardCalibrate = createAction('BOARD_CALIBRATE');
export const subscribeToBoardUpdates = createAction('SUBSCRIBE_TO_BOARD_UPDATES');
export const unsubscribeToBoardUpdates = createAction('UNSUBSCRIBE_TO_BOARD_UPDATES');

export const startStream = createAction('STREAM_START');
export const updateBoard = createAction('UPDATE_BOARD');

export const getInvoiceUrl = createAction('GET_INVOICE_URL_REQUEST');
export const boardTransactionInvoiceUrl = createAction('BOARD_TRANSACTION_INVOICE_URL');

export default {
  configureStream,
  deleteBoard,
  deleteBoardFailure,
  deleteBoardSuccess,
  upgradeLimitedBoardService,
  upgradeLimitedBoardServiceFailure,
  upgradeLimitedBoardServiceSuccess,
  changeTestMode,
  changeTestModeFailure,
  changeTestModeSuccess,
  changeWifiConfiguration,
  changeWifiConfigurationFailure,
  changeWifiConfigurationSuccess,
  finishStream,
  getSnapshot,
  getSnapshotFailure,
  getSnapshotSuccess,
  startStream,
  boardCalibrate,
  subscribeToBoardUpdates,
  unsubscribeToBoardUpdates,
  updateBoard,
  getInvoiceUrl,
  boardTransactionInvoiceUrl,
};
