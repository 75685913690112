import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import { Link } from 'react-router-dom';

import { getPlatforms } from "data/Platforms/selectors"

import { getIsAdmin } from 'services/session/selectors';

import actions from './actions';
import { PlatformList } from "./components/PlatformList"
import { getPlatformsState } from "./selectors"

const List = () => {
  const dispatch = useDispatch()
  const platforms = useSelector(getPlatforms)
  const { isLoading } = useSelector(getPlatformsState)
  const isAdmin = useSelector(getIsAdmin)

  useEffect(() => {
    dispatch(actions.getPlatforms());
  }, [dispatch])

  const handleRefreshButtonClick = _e => dispatch(actions.getPlatforms());

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" lg={{ size: 10, offset: 1 }}>
          <Card>
            <CardHeader>
              <div className="card-header__left">
                <i className="fa fa-cogs" /> Platform versions
                {isLoading && <i className="fa fa-spinner fa-spin fa-fw" />}
              </div>
              <div className="card-header__right">
                {isAdmin && <Link to="/platforms/new">
                  <button className="btn btn-primary btn-md" type="button">
                    Create new platform
                  </button>
                </Link>}
                <button
                  className="btn btn-success btn-md ml-1"
                  disabled={isLoading}
                  onClick={handleRefreshButtonClick}
                >
                  <i className="cui-reload" />
                </button>
              </div>
            </CardHeader>

            <CardBody>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Version</th>
                    <th>Release Date</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <PlatformList platforms={platforms} isLoading={isLoading} />
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default List
