import { createAction } from 'redux-actions';

export const subscribeToTournamentUpdates = createAction('SUBSCRIBE_TO_TOURNAMENT_UPDATES');
export const unsubscribeToTournamentUpdates = createAction('UNSUBSCRIBE_TO_TOURNAMENT_UPDATES');

export const deleteTournament = createAction('TOURNAMENT_DELETE_REQUEST');
export const deleteTournamentFailure = createAction('TOURNAMENT_DELETE_FAILURE');
export const deleteTournamentSuccess = createAction('TOURNAMENT_DELETE_SUCCESS');

export const getTournament = createAction('TOURNAMENT_GET_REQUEST');
export const getTournamentFailure = createAction('TOURNAMENT_GET_FAILURE');
export const getTournamentSuccess = createAction('TOURNAMENT_GET_SUCCESS');

export const updateTournamentMatch = createAction('TOURNAMENT_UPDATE_MATCH');
export const changeTournamentState = createAction('TOURNAMENT_CHANGE_STATE');
export const updateParticipants = createAction('TOURNAMENT_UPDATE_PARTICIPANTS');
export const updateResults = createAction('TOURNAMENT_UPDATE_RESULTS');

export const clearTournament = createAction('TOURNAMENT_CLEAR');

export default {
  subscribeToTournamentUpdates,
  unsubscribeToTournamentUpdates,
  deleteTournament,
  deleteTournamentFailure,
  deleteTournamentSuccess,
  getTournament,
  getTournamentFailure,
  getTournamentSuccess,
  clearTournament,
  updateTournamentMatch,
  changeTournamentState,
  updateParticipants,
  updateResults,
};
