import { combineReducers } from 'redux';

import { reducer as list } from './components/List';
import { reducer as show } from './components/Show';

const Products = combineReducers({
  list,
  show,
});

export default Products;
