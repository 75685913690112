import { Tournament } from 'interfaces';
import { ParticipantUpdateEvent } from 'typeDefs';

export const handleAddOrRemoveParticipant = (
  tournament: Tournament,
  friendIds: string[],
  payload: ParticipantUpdateEvent,
) => {
  const {
    numberOfParticipants,
    newParticipants: payloadsNewParticipants,
    removedParticipant,
  } = payload;
  const newParticipants = { ...tournament.participants };
  let newParticipantsOrder = [...(tournament.participantsOrder ?? [])];

  if (payloadsNewParticipants) {
    payloadsNewParticipants.forEach((participant) => {
      newParticipants[participant.userId] = participant;

      if (!newParticipantsOrder.includes(participant.userId)) {
        newParticipantsOrder = [...newParticipantsOrder, participant.userId];
      }
    });
  } else if (removedParticipant) {
    delete newParticipants[removedParticipant];
    newParticipantsOrder = newParticipantsOrder.filter(
      (p) => p !== removedParticipant,
    );
  }

  Object.keys(newParticipants).forEach((key, index) => {
    newParticipants[key].id = index + 1;
    newParticipants[key].isFriend = friendIds.includes(key);
  });

  return {
    ...tournament,
    participants: newParticipants,
    participantsOrder: newParticipantsOrder,
    numberOfParticipants,
  };
};
