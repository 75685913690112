import { combineReducers } from 'redux';

import { reducer as list } from './components/List';
import { reducer as show } from './components/Show';
import { reducer as showDetails } from './components/Show/components/Details';

const users = combineReducers({
  show,
  list,
  showDetails,
});

export default users;
