import { createAction } from 'redux-actions';

export const getRankings = createAction(
  'RANKINGS_GET_REQUEST',
  payload => ({
    queryParams: payload,
  })
);
export const getRankingsFailure = createAction('RANKINGS_GET_FAILURE');
export const getRankingsSuccess = createAction('RANKINGS_GET_SUCCESS');

export default {
  getRankings,
  getRankingsFailure,
  getRankingsSuccess,
};
