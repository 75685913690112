import { handleActions } from 'redux-actions';

import { actions as tournamentListActions } from 'scenes/Tournaments/components/List';
import { actions as listActions } from 'scenes/Tournaments/components/List';

import { prepareTournamentList } from 'utils/tournamentUtils';

const INITIAL_STATE = [];

const actionTypes = [
  listActions.getTournamentsSuccess,
  tournamentListActions.deleteMultipleTournamentsSuccess,
  tournamentListActions.copyTournamentsSuccess,
];

const handleSuccess = (_state, { payload: { data } }) => {
  return prepareTournamentList(data);
};

const tournamentsReducer = handleActions(
  actionTypes.reduce((acc, actionType) => {
    acc[actionType] = handleSuccess;
    return acc;
  }, {}),
  INITIAL_STATE
);

export default tournamentsReducer;
