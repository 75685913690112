import { createActions } from 'redux-actions';

const apiActions = createActions(
  {
    API: {
      COMMON: {
        CAMERA_IMAGES: undefined,
        GROUP_CHAT_MESSAGES: undefined,
        GROUP_CHAT_MESSAGE: undefined,
        PUBLISH: undefined,
      }
    },
  },
  { namespace: '::' }
);

export default apiActions.api.common;
