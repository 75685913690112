import React from 'react';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import { NavLink } from 'react-router-dom';
import { ThemesEnum, TournamentParticipantStatusEnum } from 'enums';
import { SButton, SButtonVariantOptionsEnum, SizesEnum } from 'ScoliaComponents';
import { GlassTable } from 'components/GlassTable';
import { DeleteIcon, RefreshIcon } from 'components/Icons';
import { formatNumberWithThousandSeparators } from 'utils';
import styles from './styles.module.scss';
export const InvitedParticipantsList = ({ participantsOrder = [], participants = {}, onDelete = () => { }, onResend = () => { } }) => {
    return (React.createElement(GlassTable, { title: 'Participants', placeholder: React.createElement("div", null, "The invited participants will appear here.") }, !isEmpty(participantsOrder) ? (React.createElement(React.Fragment, null,
        React.createElement("thead", { className: styles.smallView },
            React.createElement("tr", null,
                React.createElement("th", null, "#"),
                React.createElement("th", { className: styles.name }, "Name"),
                React.createElement("th", null, "Country"),
                React.createElement("th", { className: styles.compactView }, "Scolia ID"),
                React.createElement("th", { className: styles.compactView }, "Games Played"),
                React.createElement("th", { className: styles.compactView }, "Overall average"),
                React.createElement("th", { className: cn(styles.status, styles.statusHeader, styles.smallView) },
                    React.createElement("span", { className: styles.statusContent }, "Status")),
                React.createElement("th", null),
                React.createElement("th", null))),
        React.createElement("tbody", null, participantsOrder.map((userId, idx) => {
            const { scoliaId, nickname, status, overallStats } = participants[userId] ?? {};
            const { playedGames, average } = overallStats ?? {};
            return (React.createElement("tr", { key: userId, id: `${scoliaId}` },
                React.createElement("td", null, idx + 1),
                React.createElement("td", { className: styles.name },
                    React.createElement(NavLink, { to: `/users/${userId}` }, nickname)),
                React.createElement("td", { className: styles.country }, participants[userId]?.country),
                React.createElement("td", { className: styles.compactView },
                    "#",
                    scoliaId),
                React.createElement("td", { className: styles.compactView }, formatNumberWithThousandSeparators(playedGames)),
                React.createElement("td", { className: styles.compactView }, average?.toFixed(1)),
                React.createElement("td", { className: cn(styles.status, styles.smallView) }, status && (React.createElement("span", { className: cn(styles.statusContent, styles[status]) }, status))),
                React.createElement("td", { className: styles.actionButtonContainer }, status === TournamentParticipantStatusEnum.Declined && (React.createElement(SButton, { name: "player-reinvite", variant: SButtonVariantOptionsEnum.TertiaryGray, size: SizesEnum.Small, theme: ThemesEnum.Astro, onClick: (_event) => onResend(userId) },
                    React.createElement(RefreshIcon, null)))),
                React.createElement("td", { className: styles.actionButtonContainer },
                    React.createElement(SButton, { name: "player-delete", variant: SButtonVariantOptionsEnum.TertiaryGray, size: SizesEnum.Small, theme: ThemesEnum.Astro, onClick: (_event) => onDelete(userId) },
                        React.createElement(DeleteIcon, null)))));
        })))) : null));
};
