import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TournamentList } from 'components/TournamentList';

import { getPreparedFiltersForSelectedFields } from 'utils';

import selectors from './selectors';
import actions from './actions';
import { privateTournaments } from 'constants/Resources';

const selectColumns = ['isScoreCorrectionAllowed', 'organiserType'];

const columns = ['name', 'organiser', 'state', 'currentTokenCost', 'tournamentStartDate', 'createdAt', 'minParticipants', 'maxParticipants', 'numberOfParticipants', 'config', 'isScoreCorrectionAllowed']

const List = ({ match: { url } }) => {
  const data = useSelector(selectors.getTournaments);
  const tournamentList = useSelector(selectors.getTournamentList);

  const [filters, setFilters] = useState({});

  const dispatch = useDispatch();

  const handleFetchData = ({ filter, ...rest }) => {
    const clonedFilter = getPreparedFiltersForSelectedFields([...filter, { id: 'organiserType', value: 'User' }], selectColumns);
    dispatch(actions.getPrivateTournaments({ filter: clonedFilter, ...rest }));

    //save filtering for deleting
    setFilters({ filters: { filter: clonedFilter, ...rest } });
  };

  return (
    <TournamentList
      url={url}
      data={data ?? []}
      {...tournamentList}
      onFetchData={handleFetchData}
      filters={filters}
      selectColumns={selectColumns}
      resource={privateTournaments}
      columns={columns}
    />
  )
};

List.displayName = 'Tournaments.List';

export default List;