import { call, put, takeLatest } from 'redux-saga/effects';

import { Tournaments } from 'services/api';

import { browserHistory } from 'services/history';
import { callApi } from 'services/rest';
import { notifyError, notifySuccess } from 'services/notification/actions';

import {
  deleteMultipleTournaments,
  deleteMultipleTournamentsFailure,
  deleteMultipleTournamentsSuccess,
} from 'scenes/Tournaments/components/List/actions';

function* watchDeleteTournaments({ payload: {keys, filter} }) {

  const filterObj = {};
  filter.filters.filter.forEach(field => filterObj[field.id] = field.value)

  const sortObj = {};
  filter.filters.sort.forEach(field => sortObj[field.id] = field.value)

  try {
    const { data } = yield call(callApi, Tournaments.deleteMultiple, {tournamentIds: keys, meta: {
      filter: filterObj,
      sort: sortObj,
      pageSize: filter.pageSize,
      page: filter.page,
    }});

    yield put(deleteMultipleTournamentsSuccess(data));
    yield put(notifySuccess(
      `${keys.length > 1 ?  `${keys.length} tournaments were` : 'Tournament was'} deleted successfully.`
    ))
    browserHistory.push('/tournaments');

  } catch (e) {
    if (!e.isFetch) {
      throw e;
    }
    yield put(deleteMultipleTournamentsFailure(e.data.error));
    yield put(notifyError(`Failed to delete ${keys.length > 1 ?  'tournaments' : 'tournament'}.`))

  }
}

export default () => takeLatest(deleteMultipleTournaments, watchDeleteTournaments);