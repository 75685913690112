import React, { useState } from 'react';
import { useMedia } from 'react-use';
import { isNull } from 'lodash';
import { ThemesEnum } from 'enums';
import { MD } from 'constants/Breakpoints';
import { SKIP_CHANGE_STAGE_MODAL } from 'constants/StoreKeys';
import { extractTime, getTodayMorning, mergeDateTime } from 'utils';
import { maxParticipants, minParticipants } from 'components/TournamentWizard/ValidationSchema';
import { SInput, SPackageRadio, SDatePicker, SScrollContainer, SOptionsInput } from 'ScoliaComponents';
import { HeaderTitle } from '../../HeaderTitle';
import { ConfirmModifyStagesModal } from './components';
import { filterStagesByMaxParticipants, formatDate, generateHalfHourTimes, isTimeBeforeNow, validateTime } from './utils';
import { tournamentTypeOptions, maxParticipantsOptions, minParticipantsOptions } from './constants';
import styles from './styles.module.scss';
const Basics = ({ title, onBack, values, errors, touched, isEdit, isEditingDisabled, onChange, onBlur, onSetFieldValue, onSetFieldError, }) => {
    const [isStageChangedModalOpen, setIsStageChangedModalOpen] = useState(false);
    const [modifiedGameConfig, setModifiedGameConfig] = useState([]);
    const [maxParticipantValue, setMaxParticipantValue] = useState(values.maxParticipants);
    const [minParticipantValue, setMinParticipantValue] = useState(values.minParticipants);
    const [timeOptions] = useState(generateHalfHourTimes());
    const isMediumDisplay = useMedia(`(min-width: ${MD}px)`);
    const [time, setTime] = useState(extractTime(values.tournamentStartDate));
    const now = getTodayMorning();
    const toggleModal = () => {
        setIsStageChangedModalOpen(prevValue => !prevValue);
    };
    const validateMaxParticipants = (value) => {
        const participants = Object.keys(values.participants ?? {}).length;
        //has to wait for onblur event clarification
        setTimeout(() => {
            if (value < participants) {
                onSetFieldError('maxParticipants', `Max. participants has to be at least the number of current participants ${participants}.`);
            }
            else {
                onSetFieldError('maxParticipants');
            }
        });
    };
    const handleTimeSelection = (value, name) => {
        const newValue = mergeDateTime(formatDate(`${values[name]}`), value);
        const newIsoDate = new Date(newValue).toISOString();
        onSetFieldValue(name, newIsoDate);
        //has to wait for onblur event clarification
        setTimeout(() => {
            if (isTimeBeforeNow(newIsoDate)) {
                onSetFieldError('tournamentStartDateTime', "Tournament start time has to be later than now.");
            }
            else {
                onSetFieldError('tournamentStartDateTime');
            }
        });
    };
    const handleDateChange = (value, name) => {
        const formattedValue = formatDate(new Date(value).toISOString());
        const newValue = mergeDateTime(formattedValue, extractTime(values[name]));
        const newIsoDate = new Date(newValue).toISOString();
        onSetFieldValue(name, newIsoDate);
    };
    const handleParticipantNumberChange = (value, name) => {
        onSetFieldValue(name, value);
    };
    const handleNameBlur = event => {
        onBlur(event);
    };
    const handleTimeChange = (value) => {
        const regex = /^[0-9:]{1,5}$/;
        if (value && !regex.test(value)) {
            return;
        }
        if (validateTime(value)) {
            handleTimeSelection(value, 'tournamentStartDate');
            onSetFieldError('tournamentStartDateTime');
        }
        setTime(value);
    };
    const handleTimeBlur = (event) => {
        const inputTime = event.target.value;
        if (!validateTime(inputTime)) {
            onSetFieldError('tournamentStartDateTime', "Tournament start time should be valid HH:MM format.");
        }
    };
    const onMaxParticipantNumberChange = (gameConfig) => () => {
        const newGameConfig = gameConfig ?? modifiedGameConfig;
        onSetFieldValue('variousGameConfig', newGameConfig);
        setIsStageChangedModalOpen(false);
    };
    const getParticipantValueBetweenRange = (value) => {
        //If empty value, set it to minParticipants
        const participants = Number.isNaN(parseInt(value)) ? minParticipants : parseInt(value);
        //if eventValue less than minParticipants, set it to minParticipants, if eventValue more than maxParticipants, set it to maxParticipants, else leave it to eventValue
        return participants < minParticipants ? minParticipants : participants > maxParticipants ? maxParticipants : participants;
    };
    const handleMaxParticipantsBlur = (event) => {
        onBlur(event);
        const maxParticipants = getParticipantValueBetweenRange(event.target.value);
        if (maxParticipants < values.minParticipants) {
            onSetFieldValue('minParticipants', maxParticipants);
        }
        onSetFieldValue('maxParticipants', maxParticipants);
        validateMaxParticipants(maxParticipants);
        const { isStageChanged, newGameConfig } = filterStagesByMaxParticipants(maxParticipants, values.variousGameConfig, maxParticipantValue);
        const shouldSkipChangeStateValue = localStorage.getItem(SKIP_CHANGE_STAGE_MODAL);
        const shouldSkipChangeState = shouldSkipChangeStateValue ? JSON.parse(shouldSkipChangeStateValue) : false;
        if (!isStageChanged || (isStageChanged && shouldSkipChangeState)) {
            onMaxParticipantNumberChange(newGameConfig)();
            return;
        }
        setModifiedGameConfig(newGameConfig);
        setIsStageChangedModalOpen(true);
    };
    const handleMinParticipantFilterBlur = (event) => {
        const minParticipants = getParticipantValueBetweenRange(event.target.value);
        onSetFieldValue('minParticipants', minParticipants);
        if (minParticipants > values.maxParticipants) {
            const { newGameConfig } = filterStagesByMaxParticipants(minParticipants, values.variousGameConfig, maxParticipantValue);
            onSetFieldValue('variousGameConfig', newGameConfig);
            onSetFieldValue('maxParticipants', minParticipants);
            validateMaxParticipants(minParticipants);
        }
    };
    //To store values which can be set back if user dismisses the modal
    const handleMaxParticipantsFocus = event => {
        setMaxParticipantValue(parseInt(event.target.value));
        setMinParticipantValue(values.minParticipants);
    };
    const onConfirmModifyStagesDismiss = () => {
        onSetFieldValue('maxParticipants', maxParticipantValue);
        onSetFieldValue('minParticipants', minParticipantValue);
        toggleModal();
    };
    return (React.createElement(SScrollContainer, null,
        React.createElement(ConfirmModifyStagesModal, { isOpen: isStageChangedModalOpen, onDismiss: onConfirmModifyStagesDismiss, onAccept: onMaxParticipantNumberChange() }),
        !isNull(title) && (React.createElement(HeaderTitle, { title: title, onBack: onBack })),
        React.createElement("div", { className: styles.tournamentBasicsSection },
            React.createElement(SInput, { label: "Tournament name", name: "name", maxLength: 32, placeholder: "Ex. my first tournament", value: values.name, error: touched.name ? errors.name : undefined, onChange: onChange, onBlur: handleNameBlur, theme: ThemesEnum.Astro })),
        React.createElement("div", { className: styles.tournamentBasicsSection },
            React.createElement(SPackageRadio, { label: "Tournament type", name: "participationType", theme: ThemesEnum.Astro, isVertical: !isMediumDisplay, options: tournamentTypeOptions, value: values.participationType, error: touched.participationType ? errors.participationType : undefined, onSelect: onChange, disabled: isEditingDisabled })),
        React.createElement("div", { className: styles.tournamentBasicsSection },
            React.createElement("div", { className: styles.tournamentBasicsSectionElem },
                React.createElement(SOptionsInput, { onChange: handleParticipantNumberChange, label: "Max. participants", name: "maxParticipants", options: maxParticipantsOptions, value: values.maxParticipants, error: isEdit ? errors.maxParticipants : undefined, onSelect: handleParticipantNumberChange, onFocus: handleMaxParticipantsFocus, onBlur: handleMaxParticipantsBlur, tooltip: "The maximum number of players to join the tournament", theme: ThemesEnum.Astro, lockedOptionContainerSize: true, useFiltering: false, type: "number", min: minParticipants, step: 1, disabled: isEditingDisabled })),
            React.createElement("div", { className: styles.tournamentBasicsSectionElem },
                React.createElement(SOptionsInput, { onChange: handleParticipantNumberChange, label: "Min. participants", name: "minParticipants", options: minParticipantsOptions, value: values.minParticipants, onSelect: handleParticipantNumberChange, onBlur: handleMinParticipantFilterBlur, tooltip: "The minimum number of players to join the tournament", theme: ThemesEnum.Astro, lockedOptionContainerSize: true, useFiltering: false, type: "number", min: minParticipants, step: 1, disabled: isEditingDisabled }))),
        React.createElement("div", { className: styles.tournamentBasicsSection },
            React.createElement("div", { className: styles.tournamentBasicsSectionElem },
                React.createElement(SDatePicker, { label: "Tournament starts", name: "tournamentStartDate", min: now.toISOString(), error: touched.tournamentStartDate ? errors.tournamentStartDate : undefined, value: formatDate(`${values.tournamentStartDate}`), onChange: handleDateChange, onBlur: onBlur, theme: ThemesEnum.Astro, disabled: isEditingDisabled })),
            React.createElement("div", { className: styles.tournamentBasicsSectionElem },
                React.createElement(SOptionsInput, { onChange: handleTimeChange, onSelect: handleTimeChange, placeholder: "00:00 (Your timezone)", name: "tournamentStartDateTime", value: time, theme: ThemesEnum.Astro, error: errors.tournamentStartDateTime, onBlur: handleTimeBlur, options: timeOptions, lockedOptionContainerSize: true, useFiltering: false, disabled: isEditingDisabled })))));
};
Basics.displayName = 'Basics';
export default Basics;
