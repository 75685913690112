import { get, patch, post, del } from 'services/rest';

const url = '/boards';

const Boards = {
  index: queryParams => get(url, queryParams),
  create: board => post(url, board),
  createMultiple: payload => post(`${url}/multiple`, payload),
  delete: boardId => del(`${url}/${boardId}`),
  show: boardId => get(`${url}/extended/${boardId}`),
  update: (boardId, payload) => patch(`/management${url}/${boardId}`, payload),
  currentState: boardId => get(`${url}/${boardId}/currentState`),
  statistics: (boardId, queryParams) => get(`${url}/extended/${boardId}/statistics`, queryParams),
  sessions: (boardId, filters) => get(`${url}/${boardId}/sessions`, filters),
  limitedThrows: (boardId, filters) => get(`${url}/${boardId}/limitedThrows`, filters),
  offlineSessions: (boardId, filters) => get(`${url}/${boardId}/offline-sessions`, filters),
  onlineBoards: (queryParams) => get(`${url}/online`, queryParams),
  storedBoardCameraImages: (boardId, imageId) => get(`${url}/${boardId}/images/${imageId}`),
  updateLimitedBoardService: (boardId, isServiceActive) => patch(`${url}/${boardId}/updateServiceState`, { isServiceActive }),
  changeTestMode: (boardId, isInTestMode) => patch(`${url}/${boardId}/setBoardTestMode`, { isInTestMode }),
  changeWifiConfiguration: (boardId, isWifiConfigurationActive) => patch(`${url}/${boardId}/setBoardWifiConfiguration`, { isWifiConfigurationActive }),
};

export default Boards;
