import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import { List } from './components/List';
import { Show } from './components/Show';

class Users extends Component {
  render() {
    const {
      match: { url },
    } = this.props;

    return (
      <Switch>
        <Route component={List} exact name="Users list" path={`${url}/`} />
        <Route
          component={Show}
          exact
          name="Users info"
          path={`${url}/:userId`}
        />
      </Switch>
    );
  }
}

export default Users;
