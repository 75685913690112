import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import { actions as firmwareActions } from 'scenes/Firmwares';
import { actions as editActions } from 'scenes/Firmwares/components/Edit';
import { actions as listActions } from 'scenes/Firmwares/components/List';

const INITIAL_STATE = {};

const firmwaresReducer = handleActions(
  {
    [listActions.getFirmwaresSuccess]: (_state, { payload }) => {
      const firmwares = payload.reduce((firmwares, firmware) => {
        firmware.id = firmware._id;

        firmwares[firmware.id] = firmware;
        return firmwares;
      }, {});

      return {
        ...firmwares,
      };
    },

    [firmwareActions.getFirmwareSuccess]: (state, { payload: firmware }) => {
      firmware.id = firmware._id;

      return update(state, {
        [firmware.id]: {
          $set: firmware,
        },
      });
    },

    [editActions.updateFirmwareSuccess]: (state, { payload: firmware }) =>
      update(state, {
        [firmware._id]: {
          $set: { ...firmware, id: firmware._id },
        },
      }),
  },
  INITIAL_STATE
);

export default firmwaresReducer;
