import React from 'react';
import { Link } from 'react-router-dom';
import { getFilter } from 'components/ListSelector';
import { formatDate, isNullOrUndefined } from 'utils';
import { useQuery } from 'react-query';
import { ScoreCorrections } from 'services/api';
import { ScoreCorrectionFairnessStatusEnum } from 'enums';
export const useScoreCorrectionStatistics = (queryFilters) => {
    const query = useQuery({
        queryKey: ['scoreCorrection', 'statistics', queryFilters],
        queryFn: () => ScoreCorrections.statistics(queryFilters),
        select: (data) => data?.data,
        refetchOnWindowFocus: false,
        enabled: !isNullOrUndefined(queryFilters),
    });
    return query;
};
export const getColumns = () => {
    const formatPercentage = (value) => {
        if (isNullOrUndefined(value)) {
            return ' - ';
        }
        else {
            return `${(value * 100).toFixed(2)}%`;
        }
    };
    const getAlignedCell = (value) => (React.createElement("div", { style: { width: '100%', textAlign: 'center' } }, value));
    const headerValues = [
        {
            Header: 'Nickname',
            headerStyle: { whiteSpace: 'pre-line', wordWrap: 'break-word' },
            accessor: 'nickname',
            sortable: false,
            Cell: (props) => getAlignedCell(props.value),
        },
        {
            Header: 'Email',
            headerStyle: { whiteSpace: 'pre-line', wordWrap: 'break-word' },
            accessor: 'email',
            filterable: true,
            sortable: false,
            Cell: (props) => getAlignedCell(React.createElement(Link, { to: `/users/${props?.original?.userId}` }, props.value)),
        },
        {
            Header: 'Last correction',
            headerStyle: { whiteSpace: 'pre-line', wordWrap: 'break-word' },
            accessor: 'lastCorrection',
            width: 80,
            sortable: true,
            Cell: ({ value }) => getAlignedCell(formatDate(value)),
        },
        {
            Header: 'Last reset',
            headerStyle: { whiteSpace: 'pre-line', wordWrap: 'break-word' },
            accessor: 'lastReset',
            width: 80,
            sortable: true,
            Cell: ({ value }) => getAlignedCell(formatDate(value)),
        },
        {
            Header: 'Status',
            headerStyle: { whiteSpace: 'pre-line', wordWrap: 'break-word' },
            accessor: 'status',
            sortable: false,
            filterable: true,
            Filter: getFilter(ScoreCorrectionFairnessStatusEnum),
            Cell: (props) => getAlignedCell(props.value),
        },
        {
            Header: 'Throws',
            headerStyle: { whiteSpace: 'pre-line', wordWrap: 'break-word' },
            accessor: 'throwNumber',
            width: 70,
            sortable: false,
            Cell: (props) => getAlignedCell(props.value),
        },
        {
            Header: 'Manually added',
            headerStyle: { whiteSpace: 'pre-line', wordWrap: 'break-word' },
            accessor: 'manuallyAdded',
            width: 70,
            sortable: false,
            Cell: (props) => getAlignedCell(props.value),
        },
        {
            Header: 'Manually added %',
            headerStyle: { whiteSpace: 'pre-line', wordWrap: 'break-word' },
            accessor: 'manuallyAddedPercent',
            width: 70,
            sortable: false,
            Cell: ({ value }) => getAlignedCell(formatPercentage(value)),
        },
        {
            Header: 'Modified',
            headerStyle: { whiteSpace: 'pre-line', wordWrap: 'break-word' },
            accessor: 'modified',
            width: 70,
            sortable: false,
            Cell: (props) => getAlignedCell(props.value),
        },
        {
            Header: 'Modified %',
            headerStyle: { whiteSpace: 'pre-line', wordWrap: 'break-word' },
            accessor: 'modifiedPercent',
            width: 70,
            sortable: false,
            Cell: ({ value }) => getAlignedCell(formatPercentage(value)),
        },
        {
            Header: 'For',
            headerStyle: { whiteSpace: 'pre-line', wordWrap: 'break-word' },
            accessor: 'forAmount',
            width: 50,
            sortable: false,
            Cell: (props) => getAlignedCell(props.value),
        },
        {
            Header: 'Against',
            headerStyle: { whiteSpace: 'pre-line', wordWrap: 'break-word' },
            accessor: 'againstAmount',
            width: 60,
            sortable: false,
            Cell: (props) => getAlignedCell(props.value),
        },
        {
            Header: 'Checkout for',
            headerStyle: { whiteSpace: 'pre-line', wordWrap: 'break-word' },
            accessor: 'checkoutForAmount',
            width: 75,
            sortable: false,
            Cell: (props) => getAlignedCell(props.value),
        },
        {
            Header: 'Checkout against',
            headerStyle: { whiteSpace: 'pre-line', wordWrap: 'break-word' },
            accessor: 'checkoutAgainstAmount',
            width: 75,
            sortable: false,
            Cell: (props) => getAlignedCell(props.value),
        },
        {
            Header: 'SC Fairness',
            headerStyle: { whiteSpace: 'pre-line', wordWrap: 'break-word' },
            accessor: 'fairness',
            width: 70,
            sortable: true,
            Cell: ({ value }) => getAlignedCell(formatPercentage(value)),
        },
        {
            Header: 'Checkout SC Fairness',
            headerStyle: { whiteSpace: 'pre-line', wordWrap: 'break-word' },
            accessor: 'checkoutFairness',
            width: 75,
            sortable: true,
            Cell: ({ value }) => getAlignedCell(formatPercentage(value)),
        },
        {
            Header: 'Outgoing SC acceptance %',
            headerStyle: { whiteSpace: 'pre-line', wordWrap: 'break-word' },
            accessor: 'outgoingScAcceptanceRate',
            width: 80,
            sortable: true,
            Cell: ({ value }) => getAlignedCell(formatPercentage(value)),
        },
        {
            Header: 'Incoming SC acceptance %',
            headerStyle: { whiteSpace: 'pre-line', wordWrap: 'break-word' },
            accessor: 'incomingScAcceptanceRate',
            width: 80,
            sortable: true,
            Cell: ({ value }) => getAlignedCell(formatPercentage(value)),
        },
    ];
    return headerValues;
};
