import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Card, CardBody, CardHeader } from 'reactstrap';

import TableRow from 'components/TableRow';

import { AdminWrapper, ViewerWrapper } from './components';

import actions from './actions';

const Details = ({ countries, user, isAdmin, sessionUserId, userId, isSaveDisabled }) => {
  const dispatch = useDispatch();

  const userCountry = () => {
    const result = countries.find(c => c.alpha3Code === user.country);

    if (countries.length > 0 && user.country) {
      return result ? result.name : user.country;
    }

    return null;
  };

  if (!user) {
    return <i className="fa fa-spinner fa-spin fa-fw" />;
  }

  const renderRoleSetting = () => {
    return (!isAdmin || sessionUserId === userId) && <TableRow.Enum label="Role" value={user.role} />;
  };

  const handleEmailChange = email => {
    dispatch(
      actions.updateUser({
        userId: userId,
        email,
      })
    );
  };

  const handleTokenBalanceChange = tokenBalance => {
    dispatch(
      actions.updateUser({
        userId: userId,
        tokenBalance,
      })
    );
  };

  return (
    <div>
      <Card color="white">
        <CardHeader>
          <div className="card-header__left">
            <i className="fa fa-align-justify" /> Details
          </div>
        </CardHeader>
        <CardBody>
          <div className="key-value-data-container">
            <TableRow.Default label="Nickname" value={user.nickname} />
            <TableRow.Default label="Name" value={user.fullName} />
            <TableRow.Date label="BirthDate" value={user.birthDate} />
            {isAdmin ? (
              <TableRow.EditableEmail value={user.email} onSubmit={handleEmailChange} />
            ) : (
              <TableRow.Default label="Email" value={user.email} />
            )}
            <TableRow.Date label="Registered" value={user.registeredAt} />
            <TableRow.Boolean label="Registration Confirmed" value={user.hasRegistrationCompleted} />
            <TableRow.Boolean label="Subscribed" value={user.isSubscribed} />
            <TableRow.Boolean label="Dark Theme" value={user.darkTheme} />
            <TableRow.Default label="Country" value={userCountry()} />
            <TableRow.Default label="City" value={user.city} />
            <TableRow.Default label="Zip Code" value={user.zipCode} />
            <TableRow.Default label="Address" value={user.address} />
            {isAdmin ? (
              <TableRow.EditableToken value={user.tokenBalance} onSubmit={handleTokenBalanceChange} />
            ) : (
              <TableRow.Default label="Token Balance" value={user.tokenBalance} />
            )}
            {renderRoleSetting()}
            {isAdmin ? (
              <AdminWrapper {...{ user, isAdmin, sessionUserId, userId, isSaveDisabled }} />
            ) : (
              <ViewerWrapper user={user} />
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

Details.propTypes = {
  user: PropTypes.object.isRequired,
  countries: PropTypes.array,
  isAdmin: PropTypes.bool,
  isSaveDisabled: PropTypes.bool,
};

export default Details;
