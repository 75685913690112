import React from 'react';
import { useMedia } from 'react-use';
import { MD } from 'constants/Breakpoints';
import { WizardPageTitles } from 'components/TournamentWizard/constants';
import { ArrowIcon, ArrowIconDirection } from 'components/Icons';
import styles from './styles.module.scss';
export const HeaderTitle = ({ onBack, title }) => {
    const isMediumDisplay = useMedia(`(min-width: ${MD}px)`);
    if (!isMediumDisplay) {
        return null;
    }
    return (React.createElement("div", { className: styles.title }, onBack ? (React.createElement("button", { onClick: onBack, className: styles.backBtn },
        React.createElement(ArrowIcon, { direction: ArrowIconDirection.Left }),
        " ",
        WizardPageTitles[title])) : (WizardPageTitles[title])));
};
