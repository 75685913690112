import { createAction } from 'redux-actions';

export const getUserGameHistory = createAction('USER_GAME_HISTORY_REQUEST');
export const getUserGameHistoryFailure = createAction('USER_GAME_HISTORY_FAILURE');
export const getUserGameHistorySuccess = createAction('USER_GAME_HISTORY_SUCCESS');
export const resetUserGameHistory = createAction('USER_GAME_HISTORY_RESET');

export default {
  getUserGameHistory,
  getUserGameHistoryFailure,
  getUserGameHistorySuccess,
  resetUserGameHistory,
};
