import { handleActions } from 'redux-actions';

import actions from './actions';

const INITIAL_STATE = {
  isLoading: true,
  platforms: [],
};

const list = handleActions(
  {
    [actions.getPlatforms]: state => ({
      ...state,
      isLoading: true,
    }),

    [actions.getPlatformsSuccess]: () => ({
      isLoading: false,
    }),

    [actions.getPlatformsFailure]: () => ({
      isLoading: false,
    }),
  },
  INITIAL_STATE
);

export default list;
