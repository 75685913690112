import { call, put, takeLatest } from 'redux-saga/effects';

import { Rankings } from 'services/api';
import { callApi } from 'services/rest';

import {
  getRankings,
  getRankingsFailure,
  getRankingsSuccess,
} from 'scenes/Rankings/components/List/actions';

function* watchGetRankings({ payload: { queryParams } }) {
  try {
    const { filter, sort, ...rest } = queryParams
    const newFields = []
    let isTimeNeeded = true

    const newFilter = filter.filter(elem => {
      if ( elem.id === 'gte:createdAt' ) {
        const from = new Date(elem.value)
        newFields.push({ id: 'eq:year', value: from.getFullYear() })
        newFields.push({ id: 'eq:month', value: from.getMonth()+1 })
        isTimeNeeded = false
        return false
      } else if ( elem.id === 'user' ) {
        newFields.push({ id: 'like:nickname', value: elem.value })
        return false
      } else if ( elem.id === 'email' ) {
        newFields.push({ id: 'like:email', value: elem.value })
        return false
      } else if ( elem.id === 'tp' || elem.id === 'position'  ) {
        newFields.push({ id: `eq:${elem.id}`, value: elem.value })
        return false
      } else if ( elem.id === 'scoliaId' ) {
        newFields.push({ id: `${elem.id}`, value: elem.value[0] === '#' ? elem.value.slice(1) : elem.value })
        return false
      }

      return true
    })

    if ( isTimeNeeded ) {
      newFilter.push({ id: 'year', value: '-1' })
      newFilter.push({ id: 'month', value: '-1' })
    }

    const newSort = sort.map(elem => elem.id === 'user' ? { id: 'nickname', value: elem.value } : elem)

    const query = { filter: [...newFilter, ...newFields], sort: newSort, ...rest }

    const { data } = yield call(callApi, Rankings.index, query);
    yield put(getRankingsSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(getRankingsFailure());
  }
}

// It must be wrapped in a function, so execution will happen only on function call
export default () => takeLatest(getRankings, watchGetRankings);
