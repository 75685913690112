import { call, put, takeLatest, select, take } from 'redux-saga/effects';

import { BoardState } from 'constants/BoardState';

import { Boards } from 'services/api';
import { callApi } from 'services/rest';
import { Socket, actions as wsActions } from 'services/ws';

import { getSnapshot, getSnapshotFailure, getSnapshotSuccess } from 'scenes/Boards/components/Show/actions';
import { selectors } from 'scenes/Boards/components/Show';
import { getBoardState } from '../../selectors';

function* watchGetSnapshot({ payload: { boardId } }) {
  try {
    // Get the actual stream configuration from the store
    const config = yield select(selectors.getStreamConfig);

    // Is board online?
    const boardStatus = yield select(state => getBoardState(state, boardId));

    if (
      boardStatus === BoardState.Offline.type ||
      boardStatus === BoardState.Initializing.type ||
      boardStatus === BoardState.Updating.type ||
      boardStatus === BoardState.Unknown.type
    ) {
      const image1 = yield call(callApi, Boards.storedBoardCameraImages, boardId, 1);
      const image2 = yield call(callApi, Boards.storedBoardCameraImages, boardId, 2);
      const image3 = yield call(callApi, Boards.storedBoardCameraImages, boardId, 3);

      yield put(
        getSnapshotSuccess(
          [image1.data.encodedImage, image2.data.encodedImage, image3.data.encodedImage].map(
            i => `data:image/jpeg;base64,${i}`
          )
        )
      );
    } else {
      // Assemble the payload that will be sent to the Api
      const payload = wsActions.Management.getCameraImages({
        boardId,
        ...config,
      });

      // Send the actual WS message
      yield call([Socket, Socket.send], JSON.stringify(payload));

      // Wait for the response
      const {
        payload: { images },
      } = yield take(wsActions.Api.cameraImages);

      // Dispatch the images (here they are ObjectURLs already)
      yield put(getSnapshotSuccess(images));
    }
  } catch (e) {
    if (e && e.status === 404) {
      yield put(getSnapshotFailure('No stored camera images for the board.'));
    } else {
      yield put(getSnapshotFailure());
    }
  }
}

// It must be wrapped in a function, so execution will happen only on function call
export default () => takeLatest(getSnapshot, watchGetSnapshot);
