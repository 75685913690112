import { get, patch, post, del } from 'services/rest';

const url = '/hardware';

const Hardwares = {
  index: queryParams => get(url, queryParams),
  create: hardware => post(url, hardware),
  delete: hardwareId => del(`${url}/${hardwareId}`),
  show: hardwareId => get(`${url}/${hardwareId}`),
  update: (hardwareId, payload) => patch(`${url}/${hardwareId}`, payload),
};

export default Hardwares;
