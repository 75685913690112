import { createAction } from 'redux-actions';

import Management from './Management';
import Api from './Api';

export const closeWsConnection = createAction('WS_CONNECTION_CLOSE');
export const initWsConnection = createAction('WS_CONNECTION_INIT_REQUEST');
export const initWsConnectionFailure = createAction(
  'WS_CONNECTION_INIT_FAILURE'
);
export const initWsConnectionSuccess = createAction(
  'WS_CONNECTION_INIT_SUCCESS'
);

export { Api, Management };

export default {
  Api,
  closeWsConnection,
  initWsConnection,
  initWsConnectionFailure,
  initWsConnectionSuccess,
  Management,
};
