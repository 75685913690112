import { call, put, takeLatest } from 'redux-saga/effects';

import { ServiceAccounts } from 'services/api';

import { browserHistory } from 'services/history';
import { callApi } from 'services/rest';

import {
  createServiceAccount,
  createServiceAccountFailure,
  createServiceAccountSuccess,
} from 'scenes/ServiceAccounts/components/Create/actions';

function* watchCreateServiceAccount({ payload: { serviceAccount } }) {
  try {
    yield call(callApi, ServiceAccounts.create, serviceAccount);
    yield put(createServiceAccountSuccess());
    browserHistory.push('/service-accounts');
  } catch (e) {
    if (!e.isFetch) {
      throw e;
    }
    yield put(createServiceAccountFailure(e.data.error));
  }
}

export default () => takeLatest(createServiceAccount, watchCreateServiceAccount);
