import { createAction } from 'redux-actions';

export const editTournament = createAction('EDIT_TOURNAMENT_REQUEST');
export const editTournamentFailure = createAction('EDIT_TOURNAMENT_FAILURE');
export const editTournamentSuccess = createAction('EDIT_TOURNAMENT_SUCCESS');

export default {
  editTournament,
  editTournamentFailure,
  editTournamentSuccess,
};
