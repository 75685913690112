import { take, call } from 'redux-saga/effects';

import { Socket, actions as wsActions } from 'services/ws';

import {
  unsubscribeToGroup
} from 'scenes/Chat/components/Show/actions';

export default function* watchUnsubscribeToGroup() {
  while (true) {
    const { payload: { groupId } } = yield take(unsubscribeToGroup);
    const payload = wsActions.Management.groupChatUnsubscribe({groupId})
    yield call([Socket, Socket.send], JSON.stringify(payload));
  }
}
