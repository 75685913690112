import makeTypedNotificationWatcher from '../makeTypedNotificationWatcher';

import { notifyWarning } from 'services/notification/actions';

// TODO: Register (failure) actions here
const actions = [];

export default makeTypedNotificationWatcher({
  actions,
  actionCreator: notifyWarning,
});
