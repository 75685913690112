import React, { useRef } from 'react';
import cn from 'classnames';
import { mapBoolPropsToClassnames as mapBPTC } from 'utils';
import { useScrollFade } from 'hooks';
import styles from './styles.module.scss';
export const SScrollContainer = ({ className, children, ...rest }) => {
    const scrollRef = useRef(null);
    const { isFadeTop, isFadeBottom } = useScrollFade(scrollRef);
    return (React.createElement("div", { className: cn(styles.scrollContainer, [className], mapBPTC(styles, { isFadeBottom, isFadeTop })), ...rest },
        React.createElement("div", { className: cn(styles.contentContainer), ref: scrollRef }, children)));
};
SScrollContainer.displayName = 'SScrollContainer';
