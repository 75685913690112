import { all } from 'redux-saga/effects';

import watchUpdateConfig from './updateConfig';
import fetchConfig from './fetchConfig';

export default function* configurationSaga() {
  yield all([
    fetchConfig(),
    watchUpdateConfig(),
  ]);
}