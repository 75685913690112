import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import { actions } from 'scenes/Notifications';

const INITIAL_STATE = {
  isLoading : true,
};

const notificationReducer = handleActions(
  {
    [actions.getNotifications]: state => {
      return update(state, {
        'isLoading': {
          $set : true,
        },
      });
    },

    [actions.getNotificationsSuccess]: state => {
      return update(state, {
        'isLoading': {
          $set : false,
        },
      });
    },

    [actions.getNotificationsFailure]: state => {
      return update(state, {
        'isLoading': {
          $set : false,
        },
      });
    },
  },
  INITIAL_STATE
);

export default notificationReducer;
