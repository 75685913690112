export default function createFormData(object) {
  const formData = new FormData();

  for (let property in object) {
    if (object.hasOwnProperty(property)) {
      if (Array.isArray(object[property])) {
        formData.append(property, JSON.stringify(object[property]));
      } else {
        formData.append(property, object[property]);
      }
    }
  }

  return formData;
}
