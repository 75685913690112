import { handleActions } from 'redux-actions';

import { actions as listActions } from 'scenes/Rankings/components/List';

const INITIAL_STATE = [];

const rankingsReducer = handleActions(
  {
    [listActions.getRankingsSuccess]: (_state, { payload: { data } }) => {
      return data ? data?.map(user => ({
        ...user,
        user: {
          nickname: user.nickname,
          id: user.userId,
        }
      })) : INITIAL_STATE;
    },
  },
  INITIAL_STATE
);

export default rankingsReducer;
