import { call, put, takeLatest } from 'redux-saga/effects';

import { PrivateTournaments } from 'services/api';
import { browserHistory } from 'services/history';
import { callApi } from 'services/rest';
import { notifyError, notifySuccess } from 'services/notification/actions';

import {
  killPrivateTournament,
  killPrivateTournamentFailure,
  killPrivateTournamentSuccess,
} from 'scenes/PrivateTournaments/components/Show/actions';

function* watchKillPrivateTournament({ payload }) {
  try {
    yield call(callApi, PrivateTournaments.kill, `${payload}`);

    yield put(killPrivateTournamentSuccess());
    yield put(notifySuccess('Graceful tournament kill was successful.'));
    browserHistory.push(`/private-tournaments`);
  } catch (e) {
    yield put(killPrivateTournamentFailure());
    yield put(notifyError('Failed to gracefully kill the tournament'));
  }
}

export default () => takeLatest(killPrivateTournament, watchKillPrivateTournament);
