import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import { isEmpty } from 'lodash-es';

import { actions as dashboardActions } from 'scenes/Dashboard';

const INITIAL_STATE = {
  users: {
    total: 0,
    isConfirmed: 0,
    isSubscribed: 0,
    attachedWithGoogle: 0,
    attachedWithFacebook: 0,
  },
  boards: {
    total: 0,
    public: 0,
    private: 0,
    groupedByStatus: {
      inStock: 0,
      readyToActivate: 0,
      attached: 0,
      detached: 0,
      suspended: 0,
    },
  },
  boardStatistics: {
    general: {
      throwsCount: {
        addedByGs: 0,
        automaticallyDetected: 0,
        manuallyAdded: 0,
        modified: 0,
        total: 0,
      },
      gamesCount: {
        total: 0,
        finished: 0,
        aborted: 0,
        forsaken: 0,
        online: 0,
      },
      outcastThrowsCount: 0,
    },
    x01: {
      170: 0,
      301: 0,
      501: 0,
      custom: 0,
      total: 0,
      raceTo: 0,
      bestOf: 0,
      singleOut: 0,
      doubleOut: 0,
      masterOut: 0,
    },
    aroundTheClock: {
      total: 0,
      singleMultiplier: 0,
      doubleMultiplier: 0,
      tripleMultiplier: 0,
      oneToBullOrder: 0,
      bullToOneOrder: 0,
      randomOrder: 0,
    },
    bobs27: {
      total: 0,
      standard: 0,
      allowNegativeScore: 0,
    },
    cricket: {
      total: 0,
      standard: 0,
      cutThroat: 0,
    },
    shanghai: {
      total: 0,
      normal: 0,
      short: 0,
    },
    randomCricket: {
      total: 0,
      normal: 0,
      hidden: 0,
    },
  },
};

const dashboardReducer = handleActions(
  {
    [dashboardActions.getDashboardSuccess]: (state, { payload }) => {
      if (isEmpty(payload)) {
        return INITIAL_STATE;
      }
      return update(state, {
        boards: {
          $set: payload.boards,
        },
        users: {
          $set: payload.users,
        },
        boardStatistics: {
          $set: payload.boardStatistics,
        },
      });
    },
  },
  INITIAL_STATE
);

export default dashboardReducer;
