import React from 'react';

import { formatDate } from "utils";
import formatLabel from 'utils/formatLabel';

const Date = ({ label, value }) => {
  return (
    <div className="row">
      <div className="col-sm-4">{formatLabel(label)}</div>
      <div className="col-sm-8">{formatDate(value)}</div>
    </div>
  )
}

export default Date