import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import cn from 'classnames';

import { resourceLogs } from 'constants/Resources';

import { formatDateNumber } from 'utils';

import * as resourceLogActions from './actions';
import { EntityTable } from 'components/EntityTable';
import { formatDate, getPreparedFiltersForSelectedFields } from 'utils';

import styles from './styles.module.scss';

const defaultSorted = [
  {
    id: 'date',
    desc: true,
  },
];

class List extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
    };

    this.selectColumns = ['resource', 'type'];
  }

  handleFetchData = ({ filter, ...rest }) => {
    const clonedFilter = getPreparedFiltersForSelectedFields(filter, this.selectColumns);

    this.props.dispatch(
      resourceLogActions.getResourceLogs({ filter: clonedFilter, ...rest })
    );
  };

  handleRowClick = row => {
    this.setState({
      selectedRow: row,
      isModalOpen: true,
    });
  };

  toggleModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  validateIdLengths = (value) => {
    return value ? (value.length === 0 || value.length === 24) : true;
  }

  validatingTextFilter = ({ filter, onChange }) => {
    return (
      <input
        onChange={event => onChange(event.target.value)}
        className={cn(styles.inputField, {
          [styles.isInvalid]: !this.validateIdLengths(filter ? filter.value : ''),
        })}
        value={filter ? filter.value : ''}
      />
    );
  };

  get columns() {
    return [
      {
        Header: 'User E-mail',
        accessor: 'user.email',
        filterable: true,
      },
      {
        Header: 'User ID',
        accessor: 'user.userId',
        filterable: true,
        Filter: this.validatingTextFilter,
      },
      {
        Header: 'Resource',
        accessor: 'resource',
        filterable: true,
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            className={styles.inputField}
            value={filter ? filter.value : 'All'}
          >
            <option value="All">All</option>
            <option value="UserStatistics">UserStatistics</option>
            <option value="Game">Game</option>
            <option value="Board">Board</option>
            <option value="Feedback">Feedback</option>
            <option value="Firmware">Firmware</option>
            <option value="Hardware">Hardware</option>
            <option value="User">User</option>
            <option value="Tournament">Tournament</option>
            <option value="InstantMessages">InstantMessages</option>
            <option value="Friendship">Friendship</option>
          </select>
        ),
      },
      {
        Header: 'Entity ID',
        accessor: 'entityId',
        filterable: true,
        Filter: this.validatingTextFilter
      },
      {
        Header: 'Action',
        accessor: 'type',
        filterable: true,
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            className={styles.inputField}
            value={filter ? filter.value : 'All'}
          >
            <option value="All">All</option>
            <option value="Common">Common</option>
            <option value="Create">Create</option>
            <option value="Edit">Edit</option>
            <option value="View">View</option>
          </select>
        ),
      },
      {
        Header: 'Description',
        accessor: 'description',
        filterable: true
      },
      {
        Header: 'Date',
        accessor: 'date',
        Cell: props => {
          const date = new Date(props.value);
          return (
            date.getFullYear() +
            '.' +
            formatDateNumber(date.getMonth() + 1) +
            '.' +
            formatDateNumber(date.getDate()) +
            '.' +
            ' ' +
            formatDateNumber(date.getHours()) +
            ':' +
            formatDateNumber(date.getMinutes())
          );
        },
      },
    ];
  }

  get entityModal() {
    const { isModalOpen, selectedRow } = this.state;

    return (
      <div>
        <Modal isOpen={isModalOpen} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>Log Info</ModalHeader>
          {isModalOpen && (
            <ModalBody>
              <div className="key-value-data-container">
                <div className="row">
                  <div className="col-sm-4">Entity ID:</div>
                  <div className="col-sm-8">{selectedRow.entityId}</div>
                </div>

                <div className="row">
                  <div className="col-sm-4">Resource:</div>
                  <div className="col-sm-8">{selectedRow.resource}</div>
                </div>

                <div className="row">
                  <div className="col-sm-4">Type:</div>
                  <div className="col-sm-8">{selectedRow.type}</div>
                </div>

                <div className="row">
                  <div className="col-sm-4">Date:</div>
                  <div
                    className="col-sm-8"
                    style={{ maxHeight: '40vh', overflow: 'auto' }}
                  >
                    {formatDate(selectedRow.date, true)}
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-4">Description:</div>
                  <div className="col-sm-8">{selectedRow.description}</div>
                </div>
              </div>
              <hr />
              <div className="key-value-data-container">
                <div className="row">
                  <div className="col-sm-4">User ID:</div>
                  <div className="col-sm-8">{selectedRow.user.userId}</div>
                </div>

                <div className="row">
                  <div className="col-sm-4">User e-mail:</div>
                  <div className="col-sm-8">{selectedRow.user.email}</div>
                </div>

                {selectedRow.metadata?.payload && (
                  <div className="row">
                    <div className="col-sm-4">Metadata:</div>
                    <div className="col-sm-8"><pre>{JSON.stringify(selectedRow.metadata.payload, null, 2)}</pre></div>
                  </div>
                )}
              </div>
            </ModalBody>
          )}
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  render() {
    const { data, page, pageSize, isLoading, totalCount, isDownloading, isError } = this.props;

    return (
      <div>
        <EntityTable
          resource={resourceLogs}
          columns={this.columns}
          selectColumns={this.selectColumns}
          data={isError ? [] : data}
          page={page}
          pageSize={pageSize}
          totalCount={totalCount}
          defaultSorted={defaultSorted}
          isLoading={isLoading}
          onFetchData={this.handleFetchData}
          hasDateFilter
          onRowClick={this.handleRowClick}
          hasValidation
          isDownloading={isDownloading}
          validators={{
            'user.userId': this.validateIdLengths,
            'entityId': this.validateIdLengths
          }}
        />
        {this.entityModal}
      </div>
    );
  }
}

List.displayName = 'ResourceLogs.List';

const mapStateToProps = state => ({
  data: state.data.resourceLogs,
  page: state.scenes.resourceLogs.list.page,
  pageSize: state.scenes.resourceLogs.list.pageSize,
  totalCount: state.scenes.resourceLogs.list.totalCount,
  isLoading: state.scenes.resourceLogs.list.isLoading,
  isDownloading: state.scenes.resourceLogs.list.isDownloading,
  isError: state.scenes.resourceLogs.list.isError,
});

export default connect(mapStateToProps)(List);
