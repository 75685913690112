import { all } from 'redux-saga/effects';

import watchGetChatRooms from './watchers/getChatRooms';
import chatMessagesDownload from './watchers/chatMessagesDownload';

export default function* listSaga() {
  yield all([
    watchGetChatRooms(), chatMessagesDownload()
  ]);
}
