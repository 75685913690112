import React, { useMemo } from 'react';
import { Link as ClientLink } from 'react-router-dom';

import formatLabel from 'utils/formatLabel';

const Link = ({ label, value, defaultUrlBody }) => {
  const labelToShow = useMemo(() => label.replace('Reference', ''), [label])
  const link = useMemo(() => `${defaultUrlBody ?? `/${labelToShow}s/`}${value.id}`, [labelToShow, value, defaultUrlBody])

  return (
    <div className="row">
      <div className="col-sm-4">{formatLabel(labelToShow)}</div>
      <div className="col-sm-8">
        {link.startsWith('http') ? (
          <a target="_blank" rel="noreferrer noopener" href={link}>{value.name}</a>
        ) : (
          <ClientLink to={link}>{value.name}</ClientLink>
        )}
      </div>
    </div>
  )
}

export default Link