import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import actions from './actions';
import selectors from './selectors';

import { tokenTransactions } from 'constants/Resources';

import { getColumns } from './utils';
import { getPreparedFiltersForSelectedFields } from 'utils';

import { EntityTable } from 'components/EntityTable';

const pageSize = 25;
const DEFAULT_SORT = [
  {
    id: 'createdAt',
    desc: true,
  },
];
const selectColumns = ['transactionType'];

const TokenHistory = ({ userId, isAdmin }) => {
  const dispatch = useDispatch();

  const { page, totalCount, data, isLoading } = useSelector(selectors.getTokenHistory);
  const isPaddleSandbox = useSelector(selectors.getIsUsingPaymentSandbox);

  const handleFetchData = (options) => {
    const preparedFilters = getPreparedFiltersForSelectedFields(options.filter, selectColumns);
    dispatch(actions.getUserTokenHistory({ userId, filter: preparedFilters, ...options }));
  };

  return (
    <EntityTable
      resource={tokenTransactions}
      selectColumns={selectColumns}
      columns={getColumns(isPaddleSandbox)}
      data={data}
      page={page}
      pageSize={pageSize}
      defaultPageRowSize={pageSize}
      totalCount={totalCount}
      defaultSorted={DEFAULT_SORT}
      isLoading={isLoading}
      onFetchData={handleFetchData}
      isAdmin={isAdmin}
      isDownloadPossible={false}
    />
  );
};

TokenHistory.displayName = 'TokenHistory';

TokenHistory.propTypes = {
  userId: PropTypes.string,
  userNickname: PropTypes.string,
};

export default TokenHistory;
