import { handleActions } from 'redux-actions';

import actions from 'scenes/Firmwares/actions';

const INITIAL_STATE = {
  isLoading: false,
  isDownloading: false,
};

const showReducer = handleActions(
  {
    [actions.downloadFirmware]: state => ({
      ...state,
      isDownloading: true,
    }),

    [actions.downloadFirmwareSuccess]: state => ({
      ...state,
      isDownloading: false,
    }),

    [actions.getFirmware]: (state, { payload: { isLoading } }) => ({
      ...state,
      isLoading,
    }),

    [actions.getFirmwareSuccess]: state => ({
      ...state,
      isLoading: false,
    }),

    [actions.getFirmwareFailure]: state => ({
      ...state,
      isLoading: false,
    }),
  },
  INITIAL_STATE
);

export default showReducer;
