import { call, put, takeLatest } from 'redux-saga/effects';

import { Manufacturers } from 'services/api';
import { browserHistory } from 'services/history';
import { callApi } from 'services/rest';

import {
  deleteManufacturer,
  deleteManufacturerFailure,
  deleteManufacturerSuccess,
} from 'scenes/Manufacturers/components/Show/actions';

function* watchDeleteManufacturer({ payload }) {
  try {
    yield call(callApi, Manufacturers.delete, `${payload}`);
    yield put(deleteManufacturerSuccess());
    browserHistory.push(`/manufacturers`);
  } catch (e) {
    console.log(e);
    yield put(deleteManufacturerFailure());
  }
}

export default () => takeLatest(deleteManufacturer, watchDeleteManufacturer);
