import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import actions from './actions';

const INITIAL_STATE = {
  isLoading: false
};

const editReducer = handleActions(
  {
    [actions.updateManufacturer]: (state) =>
      update(state, {
        isUpdatePending: {
          $set: true
        },
        updateError: {
          $set: ''
        }
      }),

    [actions.updateManufacturerSuccess]: (state) =>
      update(state, {
        isUpdatePending: {
          $set: false
        },
        isUpdateSuccessful: {
          $set: true
        },
        updateError: {
          $set: ''
        }
      }),

    [actions.updateManufacturerFailure]: (state, { payload }) =>
      update(state, {
        isUpdatePending: {
          $set: false
        },
        isUpdateSuccessful: {
          $set: false
        },
        updateError: {
          $set: payload
        }
      })
  },
  INITIAL_STATE
);

export default editReducer;
