import React from 'react';

import styles from './styles.module.scss'

const InputSelect = props => (
  <select className={styles.selectInput} {...props} >
    {props.options.map(opt => <option value={opt.value} key={opt.value}>{opt.label}</option>)}
  </select>
);

InputSelect.displayName = 'Input.InputSelect';

export default InputSelect;
