import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Badge, Card, CardBody, CardHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import cn from 'classnames';

import { FlexBadge } from 'components/FlexBadge';
import TableRow from 'components/TableRow'

import { statuses } from 'constants/BoardStatuses';
import { BoardState } from 'constants/BoardState';

import actions from 'scenes/Boards/components/Show/actions';
import formatLabel from 'utils/formatLabel';

import styles from './styles.module.scss';

const CONFIRM_DELETION_TEXT = 'Are you sure you want to delete this board?\n\nAfter board deletion, the board won\'t be able to open a websocket connection to the server, and the users can\'t see the board at \'My boards\' page anymore!'
const CONFIRM_LIMITED_BOARD_SERVICE_CHANGE_TRUE = 'Are you sure you want to set this board\'s service activeness? By setting this to true you will upgrade this board into an unlimited plan without any subscription.'
const CONFIRM_LIMITED_BOARD_SERVICE_CHANGE_FALSE = 'Are you sure you want to set this board\'s service activeness? By setting this to false the user will have to upgrade the board themselves for the unlimited plan.'
const CONFIRM_TEST_MODE_CHANGE = 'Are you sure you want to change this board\'s Test Mode value?'
const CONFIRM_WIFI_CONFIGURATION_CHANGE = 'Are you sure you want to change this board\'s Wifi Configuration value?'

const MainInfo = ({
  id,
  ipAddress,
  name,
  serialNumber,
  status,
  serviceAccounts,
  hardwareVersion,
  platformVersion,
  hardwares,
  platforms,
  currentState,
  uploadCameraImages,
  ftpUploadAtTakeout,
  saveAll,
  firmware,
  redirectionUrl,
  isLoading,
  isPublic,
  isHomeSbc,
  isHybridSbc,
  isFakeSbc,
  isLimitedBoard,
  isRedirected,
  ownerEmail,
  ownerId,
  isAdmin,
  canUpdate,
  useFixedBoardCode,
  deletedAt,
  limitedBoardProperties,
  subscriptionMetadata,
  isInTestMode,
  isWifiConfigurationActive,
  isWifiCompatible,
  isBannedFromTournaments,
  bannedPeriod,
  sbcModel,
}) => {
  const statusLabel = statuses.find(statusObj => statusObj.value === status).label
  const hardwareVersionLabel = hardwares[hardwareVersion] ? hardwares[hardwareVersion].versionNumber : '-'
  const platformVersionLabel = platforms[platformVersion] ? platforms[platformVersion].versionNumber : '-'

  const hasSubscription = subscriptionMetadata?.isServiceActive && subscriptionMetadata?.subscriptionId

  const dispatch = useDispatch()

  const handleDelete = (id) => {
    const result = window.confirm(CONFIRM_DELETION_TEXT);

    if (result) {
      dispatch(actions.deleteBoard(id));
    }
  }

  const handleLimitedPeriodChange = () => {
    const result = window.confirm(limitedBoardProperties.isServiceActive ? CONFIRM_LIMITED_BOARD_SERVICE_CHANGE_FALSE : CONFIRM_LIMITED_BOARD_SERVICE_CHANGE_TRUE);

    if (result && !hasSubscription && !isLoading) {
      dispatch(actions.upgradeLimitedBoardService({
        boardId: id,
        isServiceActive: !limitedBoardProperties.isServiceActive,
      }))
    }
  }

  const handleTestModeChange = () => {
    const result = window.confirm(CONFIRM_TEST_MODE_CHANGE);

    if (result && !isLoading) {
      dispatch(actions.changeTestMode({
        boardId: id,
        isInTestMode: !isInTestMode,
      }))
    }
  }

  const handleWifiConfigurationChange = () => {
    const result = window.confirm(CONFIRM_WIFI_CONFIGURATION_CHANGE);

    if ( result && !isLoading ) {
      dispatch(actions.changeWifiConfiguration({
        boardId: id,
        isWifiConfigurationActive: !isWifiConfigurationActive,
      }))
    }
  }

  return (
    <div>
      <Card color="white">
        <CardHeader>
          <div className="card-header__left">
            <i className="fa fa-align-justify" /> Main Data
          </div>
          {!deletedAt && (isAdmin || canUpdate) && (
            <div className="card-header__right">
              <Link to={`/boards/${id}/edit`}>
                <button className="btn btn-primary btn-sm" type="button">
                  Edit Board
                </button>
              </Link>
              <button className="btn btn-danger btn-sm ms-1" type="button" onClick={() => handleDelete(id)} disabled={!isAdmin}>
                Delete Board
              </button>
            </div>
          )}
        </CardHeader>
        <CardBody>
          {!isLoading ? (
            <>
              {deletedAt && <div className="alert alert-danger" role="alert">The board has been deleted!</div>}
              {isRedirected && <div className="alert alert-warning" role="alert">The board has been redirected!</div>}
              <div className="key-value-data-container">
                <div className="row">
                  <div className="col-sm-4">Object ID</div>
                  <div className="col-sm-8">{id || '-'}</div>
                </div>
                <div className="row">
                  <div className="col-sm-4">Name</div>
                  <div className="col-sm-8">
                    <strong>{name || '-'}</strong>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">Serial number</div>
                  <div className="col-sm-8">
                    <strong>{serialNumber || '-'}</strong>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">IP Address</div>
                  <div className="col-sm-8">{ipAddress || '-'}</div>
                </div>
                <div className="row">
                  <div className="col-sm-4">Status</div>
                  <div className="col-sm-8">{statusLabel || '-'}</div>
                </div>
                <div className="row">
                  <div className="col-sm-4">SBC Type</div>
                  <div className="col-sm-8">
                    <Badge
                      color={cn({
                        secondary: isFakeSbc,
                        info: !isFakeSbc,
                      })}
                    >
                      {isFakeSbc ? 'FakeSBC' : 'SBC'}
                    </Badge>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">State</div>
                  <div className="col-sm-8">
                    {currentState && (
                      <Badge
                        color={cn({
                          orange: currentState.state === BoardState.InGame.type,
                          success: currentState.state === BoardState.Online.type,
                          secondary: currentState.state === BoardState.Offline.type,
                          danger: currentState.state === BoardState.Error.type,
                          'orange-2':
                            currentState.state === BoardState.Initializing.type ||
                            currentState.state === BoardState.Updating.type,
                          'blue-2': currentState.state === BoardState.Calibrating.type,
                        })}
                      >
                        {currentState && currentState.state ? BoardState[currentState.state].label || '-' : '-'}
                      </Badge>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">Board Code</div>
                  <div className="col-sm-8">{currentState && (currentState.boardCode || '-')}</div>
                </div>
                {!isHomeSbc && <div className="row">
                  <div className="col-sm-4">Fixed board code</div>
                  <div className="col-sm-8">
                    <Badge
                      color={cn({
                        success: useFixedBoardCode,
                        warning: !useFixedBoardCode,
                      })}
                    >
                      {useFixedBoardCode ? 'yes' : 'no'}
                    </Badge>
                  </div>
                </div>}
                <div className="row">
                  <div className="col-sm-4">Home Board</div>
                  <div className="col-sm-8">
                    <Badge
                      color={cn({
                        success: isHomeSbc,
                        warning: !isHomeSbc,
                      })}
                    >
                      {isHomeSbc ? 'yes' : 'no'}
                    </Badge>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">SBC Model</div>
                  <div className="col-sm-8">{formatLabel(sbcModel)}</div>
                </div>
                <div className="row">
                  <div className="col-sm-4">Hybrid Board</div>
                  <div className="col-sm-8">
                    <Badge
                      color={cn({
                        success: isHybridSbc,
                        warning: !isHybridSbc,
                      })}
                    >
                      {isHybridSbc ? 'yes' : 'no'}
                    </Badge>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">Owner's E-mail</div>
                  <div className="col-sm-8">{ownerEmail && ownerId ? <Link to={`/users/${ownerId}/`}>{ownerEmail}</Link> || '-' : '-'}</div>
                </div>
                <div className="row">
                  <div className="col-sm-4">Hardware version</div>
                  <div className="col-sm-8">{hardwareVersionLabel}</div>
                </div>
                <div className="row">
                  <div className="col-sm-4">Platform version</div>
                  <div className="col-sm-8">{platformVersionLabel}</div>
                </div>
                <div className="row">
                  <div className="col-sm-4">Last reported version</div>
                  <div className="col-sm-8">{firmware.lastReportedVersionNumber || "-"}</div>
                </div>
                <div className="row">
                  <div className="col-sm-4">Public Board</div>
                  <div className="col-sm-8">
                    <Badge
                      color={cn({
                        success: isPublic,
                        warning: !isPublic,
                      })}
                    >
                      {isPublic ? 'yes' : 'no'}
                    </Badge>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">Only stable firmware</div>
                  <div className="col-sm-8">
                    <Badge
                      color={cn({
                        success: firmware.onlyStableFirmware,
                        warning: !firmware.onlyStableFirmware,
                      })}
                    >
                      {firmware.onlyStableFirmware ? 'yes' : 'no'}
                    </Badge>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">Auto update</div>
                  <div className="col-sm-8">
                    <Badge
                      color={cn({
                        success: firmware.isAutoUpdateEnabled,
                        warning: !firmware.isAutoUpdateEnabled,
                      })}
                    >
                      {firmware.isAutoUpdateEnabled ? 'yes' : 'no'}
                    </Badge>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">Uploading camera images</div>
                  <div className="col-sm-8">
                    <Badge
                      color={cn({
                        success: uploadCameraImages,
                        warning: !uploadCameraImages,
                      })}
                    >
                      {uploadCameraImages ? 'yes' : 'no'}
                    </Badge>
                  </div>
                </div>
                {uploadCameraImages ? <div className="row">
                  <div className="col-sm-4">Save</div>
                  <div className="col-sm-8">{saveAll ? 'All images' : 'Corrected images'}</div>
                </div> : null}
                {uploadCameraImages ? <div className="row">
                  <div className="col-sm-4">Upload at</div>
                  <div className="col-sm-8">{ftpUploadAtTakeout ? 'Takeout' : 'Game end'}</div>
                </div> : null}
                <div className="row">
                  <div className="col-sm-4">Redirected</div>
                  <div className="col-sm-8">
                    <Badge
                      color={cn({
                        success: isRedirected,
                        warning: !isRedirected,
                      })}
                    >
                      {isRedirected ? 'yes' : 'no'}
                    </Badge>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">Redirection Url</div>
                  <div className="col-sm-8">{redirectionUrl || '-'}</div>
                </div>
                <div className="row">
                  <div className="col-sm-4">Service Accounts</div>
                  <div className='col-sm-8'>
                    {!isEmpty(serviceAccounts) ?
                      serviceAccounts.map(serviceAccount =>
                        <Link to={`/service-accounts/${serviceAccount.id}`} key={serviceAccount.id}>
                          <div style={{ color: '#20a8d8' }}>{serviceAccount.name}</div>
                        </Link>)
                      : '-'
                    }
                  </div>
                </div><div className="row">
                  <div className="col-sm-4">WiFi Compatible</div>
                  <div className="col-sm-8">
                    <Badge
                      color={cn({
                        success: isWifiCompatible,
                        warning: !isWifiCompatible,
                      })}
                    >
                      {isWifiCompatible ? 'yes' : 'no'}
                    </Badge>
                  </div>
                </div>
                {!isWifiCompatible &&
                  <div className="row">
                    <div className="col-sm-4">Is Wifi Configuration Active</div>
                    <div className="col-sm-8">
                      {(isAdmin || canUpdate) ? (
                        <input
                          checked={isWifiConfigurationActive}
                          name="isWifiConfigurationActive"
                          type="checkbox"
                          onChange={handleWifiConfigurationChange}
                          disabled={isLoading}
                        />
                      ) : (
                        <Badge color={cn({
                          success: isWifiConfigurationActive,
                          warning: !isWifiConfigurationActive,
                        })}>
                          {isWifiConfigurationActive ? 'yes' : 'no'}
                        </Badge>
                      )}
                    </div>
                  </div>
                }
                <div className="row">
                  <div className="col-sm-4">Is Test Mode Active</div>
                  <div className="col-sm-8">
                    {(isAdmin || canUpdate) ? (
                      <input
                        checked={isInTestMode}
                        name="isInTestMode"
                        type="checkbox"
                        onChange={handleTestModeChange}
                        disabled={isLoading}
                      />
                    ) : (
                      <Badge color={cn({
                        success: isInTestMode,
                        warning: !isInTestMode,
                      })}>
                        {isInTestMode ? 'yes' : 'no'}
                      </Badge>
                    )}
                  </div>
                </div>
                <TableRow.Boolean label="Banned from tournaments" value={isBannedFromTournaments} />
                {isBannedFromTournaments && <>
                  <div className='row'>
                    <div className='col-md-12'>Banned Period</div>
                  </div>
                  <TableRow.Date label="Date from" value={bannedPeriod.bannedFrom} />
                  <TableRow.Date label="Date to" value={bannedPeriod.bannedTo} />
                </>}
                {isLimitedBoard && (
                  <>
                    <div className={styles.flexDetails}>
                      <FlexBadge /> details
                    </div>
                    <div className="row">
                      <div className="col-sm-4">Flex board</div>
                      <div className="col-sm-8">
                        <Badge
                          color={cn({
                            success: isLimitedBoard,
                            warning: !isLimitedBoard,
                          })}
                        >
                          {isLimitedBoard ? 'yes' : 'no'}
                        </Badge>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">Has active subscription</div>
                      <div className="col-sm-8">
                        <Badge color={cn({
                          success: hasSubscription,
                          warning: !hasSubscription,
                        })}>
                          {hasSubscription ? 'yes' : 'no'}
                        </Badge>
                        {hasSubscription && (
                          <Link className={styles.subscriptionLink} to={`/subscriptions/${subscriptionMetadata.subscriptionId}`}>Visit subscription</Link>
                        )}
                      </div>
                    </div>
                    {!isEmpty(limitedBoardProperties) && (
                      <div className="row">
                        <div className="col-sm-4">Unlimited Access Active</div>
                        <div className="col-sm-8">
                          {(isAdmin || canUpdate) ? (
                            <input
                              checked={limitedBoardProperties.isServiceActive}
                              name="isInLimitedPeriod"
                              type="checkbox"
                              onChange={handleLimitedPeriodChange}
                              disabled={hasSubscription || isLoading}
                            />
                          ) : (
                            <Badge color={cn({
                              success: limitedBoardProperties.isServiceActive,
                              warning: !limitedBoardProperties.isServiceActive,
                            })}>
                              {limitedBoardProperties.isServiceActive ? 'yes' : 'no'}
                            </Badge>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          ) : (
            <i className="fa fa-spinner fa-spin fa-fw" />
          )}
        </CardBody>
      </Card>
    </div>
  );
}

MainInfo.propTypes = {
  boardCode: PropTypes.string,
  fixedBoardCode: PropTypes.string,
  isHomeSbc: PropTypes.bool,
  isHybridSbc: PropTypes.bool,
  id: PropTypes.string.isRequired,
  ipAddress: PropTypes.string,
  name: PropTypes.string.isRequired,
  owner: PropTypes.string,
  serialNumber: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  serviceAccounts: PropTypes.array.isRequired,
  currentState: PropTypes.object,
  hardwares: PropTypes.object.isRequired,
  platforms: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLimitedBoard: PropTypes.bool.isRequired,
  ownerEmail: PropTypes.string,
  ownerId: PropTypes.string,
  isWifiConfigurationActive: PropTypes.bool,
  isWifiCompatible: PropTypes.bool,
};

export default MainInfo;
