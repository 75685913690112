export const getBoardsForServiceAccount = state => {
  return Object.values(state.data.boards).map(item => {
    return { serialNumber: item.serialNumber, boardId: item.id };
  })
}

export const getServiceAccountFormError = state => state.scenes.serviceAccounts.create.createError

export default {
  getBoardsForServiceAccount,
  getServiceAccountFormError,
}