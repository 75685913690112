import React, { Component, Fragment } from 'react';
import { Alert, Button, Card, CardBody, CardHeader, Col, FormGroup, Label, Row } from 'reactstrap';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis, Legend } from 'recharts';
import cn from 'classnames';

import { actions as notificationActions } from 'services/notification';

import { debounce, formatDate } from 'utils';

import styles from './styles.module.scss';
import { connect } from 'react-redux';
import { getSystemAnalytics } from './actions';

import DatePicker from 'react-datepicker';

const INTERVALS = ['5-min', 'hour' /*, 'day', 'month' */];

const PERIODS = [
  { key: 'today', label: 'Today' },
  { key: 'week', label: 'Last week' },
  { key: 'month', label: 'Last Month' },
];

const minDate = 2015;
const maxDate = 2050;

const TABS = [
  { key: 'statuses', label: 'Boards' },
  { key: 'connections', label: 'Connections' },
  { key: 'participants', label: 'Lobby Participants' },
];

class SystemAnalytics extends Component {
  constructor(props) {
    super(props);

    // Initializing date inputs to get the last 30 days
    const thirtyDaysEarlier = new Date();
    thirtyDaysEarlier.setDate(thirtyDaysEarlier.getDate() - 30);

    this.state = {
      viewType: 'hour',
      dateFrom: thirtyDaysEarlier,
      dateTo: new Date(new Date().setHours(23, 59, 0)),
      refreshRemainingTime: 30,
      systemAnalyticsPeriod: PERIODS[2].key,
      shouldShowAttachedBoard: true,
      shouldShowOnlineBoard: true,
      shouldShowInGameBoard: true,
      shouldShowSbcConnectionCount: true,
      shouldShowClientConnectionCount: true,
      shouldShowGuestConnectionCount: true,
      shouldShowAvailableParticipants: true,
      shouldShowInGameParticipants: true,
      activeTab: TABS[0].key,
    };
  }

  componentDidMount() {
    this.getChart();
    // Initialize auto data refreshing
    setInterval(() => {
      this.getChart();
    }, 300000);
  }

  handleValueChange = (date, field) => {
    if (date > new Date()) {
      this.props.dispatch(notificationActions.notifyError('Date cannot set to future date.'));
      return;
    }

    this.setState(
      {
        [field]: date,
        systemAnalyticsPeriod: null,
      },
      () => {
        this.debouncedGetChart();
      }
    );
  };

  renderLastXPeriod = (_e, period) => {
    let daysCount;

    switch (period) {
      case 'today':
        daysCount = 0;
        break;
      case 'week':
        daysCount = 7;
        break;
      case 'month':
        daysCount = 30;
        break;
      default:
        daysCount = 30;
        break;
    }

    let now = new Date();
    let xDaysEarlier = new Date();
    if (daysCount === 0) {
      xDaysEarlier.setHours(0, 0, 0, 0);
      now = new Date(now.setHours(23, 59, 0));
    }
    xDaysEarlier.setDate(xDaysEarlier.getDate() - daysCount);

    this.setState(
      {
        dateFrom: xDaysEarlier,
        dateTo: now,
        systemAnalyticsPeriod: period,
      },
      () => {
        this.getChart();
      }
    );
  };

  getChart = () => {
    const { viewType, dateFrom, dateTo } = this.state;

    if (dateFrom && dateTo) {
      const fromYear = dateFrom.getFullYear();
      const toYear = dateTo.getFullYear();

      if (
        dateFrom < dateTo &&
        fromYear > minDate &&
        fromYear < maxDate &&
        toYear > minDate &&
        toYear < maxDate
      ) {
        this.props.dispatch(
          getSystemAnalytics({
            viewType,
            dateFrom: dateFrom.toISOString(),
            dateTo: dateTo.toISOString(),
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          })
        );
      }
    }
  };

  debouncedGetChart = debounce(this.getChart);

  toggleTab = (_e, tab) => {
    this.setState({ activeTab: tab });
  };

  setViewType = (_e, type) => {
    this.setState(
      {
        viewType: type,
      },
      () => {
        this.getChart();
      }
    );
  };

  get dataTypeButtons() {
    const { activeTab } = this.state;
    return (
      <div>
        {TABS.map((tab) => (
          <button
            key={tab.key}
            className={cn(styles.dataTypeButton, {
              [styles.active]: tab.key === activeTab,
            })}
            type="button"
            onClick={(e) => this.toggleTab(e, tab.key)}
          >
            {tab.label}
          </button>
        ))}
      </div>
    );
  }

  get chartFilters() {
    const {
      shouldShowAttachedBoard,
      shouldShowOnlineBoard,
      shouldShowInGameBoard,
      shouldShowSbcConnectionCount,
      shouldShowClientConnectionCount,
      shouldShowGuestConnectionCount,
      shouldShowAvailableParticipants,
      shouldShowInGameParticipants,
      activeTab,
    } = this.state;

    return (
      <div className={styles.filterWrapper}>
        {activeTab === 'statuses' && (
          <Fragment>
            <div className={styles.filterUnit}>
              <label className={styles.container}>
                Attached Board
                <input
                  checked={shouldShowAttachedBoard}
                  name="shouldShowAttachedBoard"
                  type="checkbox"
                  onChange={this.toggleChartFilter}
                />
                <span className={styles.checkmark}></span>
              </label>
            </div>

            <div className={styles.filterUnit}>
              <label className={styles.container}>
                Online Board
                <input
                  checked={shouldShowOnlineBoard}
                  name="shouldShowOnlineBoard"
                  type="checkbox"
                  onChange={this.toggleChartFilter}
                />
                <span className={styles.checkmark}></span>
              </label>
            </div>

            <div className={styles.filterUnit}>
              <label className={styles.container}>
                InGame Board
                <input
                  checked={shouldShowInGameBoard}
                  name="shouldShowInGameBoard"
                  type="checkbox"
                  onChange={this.toggleChartFilter}
                />
                <span className={styles.checkmark}></span>
              </label>
            </div>
          </Fragment>
        )}

        {activeTab === 'connections' && (
          <Fragment>
            <div className={styles.filterUnit}>
              <label className={styles.container}>
                SBC Connection
                <input
                  checked={shouldShowSbcConnectionCount}
                  name="shouldShowSbcConnectionCount"
                  type="checkbox"
                  onChange={this.toggleChartFilter}
                />
                <span className={styles.checkmark}></span>
              </label>
            </div>

            <div className={styles.filterUnit}>
              <label className={styles.container}>
                Client Connection
                <input
                  checked={shouldShowClientConnectionCount}
                  name="shouldShowClientConnectionCount"
                  type="checkbox"
                  onChange={this.toggleChartFilter}
                />
                <span className={styles.checkmark}></span>
              </label>
            </div>

            <div className={styles.filterUnit}>
              <label className={styles.container}>
                Guest Connection
                <input
                  checked={shouldShowGuestConnectionCount}
                  name="shouldShowGuestConnectionCount"
                  type="checkbox"
                  onChange={this.toggleChartFilter}
                />
                <span className={styles.checkmark}></span>
              </label>
            </div>
          </Fragment>
        )}
        {activeTab === 'participants' && (
          <Fragment>
            <div className={styles.filterUnit}>
              <label className={styles.container}>
                Available
                <input
                  checked={shouldShowAvailableParticipants}
                  name="shouldShowAvailableParticipants"
                  type="checkbox"
                  onChange={this.toggleChartFilter}
                />
                <span className={styles.checkmark}></span>
              </label>
            </div>

            <div className={styles.filterUnit}>
              <label className={styles.container}>
                InGame
                <input
                  checked={shouldShowInGameParticipants}
                  name="shouldShowInGameParticipants"
                  type="checkbox"
                  onChange={this.toggleChartFilter}
                />
                <span className={styles.checkmark}></span>
              </label>
            </div>
          </Fragment>
        )}
      </div>
    );
  }

  toggleChartFilter = ({ target: { name } }) => this.setState({ [name]: !this.state[name] });

  get renderSystemAnalyticsChart() {
    const {
      shouldShowAttachedBoard,
      shouldShowOnlineBoard,
      shouldShowInGameBoard,
      shouldShowSbcConnectionCount,
      shouldShowClientConnectionCount,
      shouldShowGuestConnectionCount,
      shouldShowAvailableParticipants,
      shouldShowInGameParticipants,
      activeTab,
    } = this.state;

    return (
      <ResponsiveContainer width="100%" height={400}>
        <AreaChart data={this.chartData}>
          <XAxis dataKey="label" />
          <YAxis />
          <Tooltip />
          <Legend />
          {activeTab === 'statuses' && shouldShowAttachedBoard && (
            <Area
              type="monotone"
              dataKey="attachedBoard"
              name="Attached Board"
              stackId="1"
              stroke="#8884d8"
              fill="#8884d8"
            />
          )}
          {activeTab === 'statuses' && shouldShowOnlineBoard && (
            <Area
              type="monotone"
              dataKey="onlineBoard"
              name="Online Board"
              stackId="1"
              stroke="#82ca9d"
              fill="#82ca9d"
            />
          )}
          {activeTab === 'statuses' && shouldShowInGameBoard && (
            <Area
              type="monotone"
              dataKey="inGameBoard"
              name="Ingame Board"
              stackId="1"
              stroke="#ffc658"
              fill="#ffc658"
            />
          )}
          {activeTab === 'connections' && shouldShowSbcConnectionCount && (
            <Area
              type="monotone"
              dataKey="sbcConnectionCount"
              name="SBC Connection"
              stackId="1"
              stroke="#8884d8"
              fill="#8884d8"
            />
          )}
          {activeTab === 'connections' && shouldShowClientConnectionCount && (
            <Area
              type="monotone"
              dataKey="clientConnectionCount"
              name="Client Connection"
              stackId="1"
              stroke="#82ca9d"
              fill="#82ca9d"
            />
          )}
          {activeTab === 'connections' && shouldShowGuestConnectionCount && (
            <Area
              type="monotone"
              dataKey="guestConnectionCount"
              name="Guest Connection"
              stackId="1"
              stroke="#ffc658"
              fill="#ffc658"
            />
          )}
          {activeTab === 'participants' && shouldShowAvailableParticipants && (
            <Area
              type="monotone"
              dataKey="availableLobbyParticipant"
              name="Available"
              stackId="1"
              stroke="#8884d8"
              fill="#8884d8"
            />
          )}
          {activeTab === 'participants' && shouldShowInGameParticipants && (
            <Area
              type="monotone"
              dataKey="inGameLobbyParticipant"
              name="InGame"
              stackId="1"
              stroke="#ffc658"
              fill="#ffc658"
            />
          )}
        </AreaChart>
      </ResponsiveContainer>
    );
  }

  get dataIntervalButtons() {
    const { isLoading } = this.props;
    return INTERVALS.map((interval) => (
      <Button
        key={`interval-button-${interval}`}
        outline
        color="primary"
        disabled={isLoading}
        className={cn(styles.dataIntervalButton, {
          active: this.state.viewType === interval,
        })}
        onClick={(e) => this.setViewType(e, interval)}
      >
        <span className="text-uppercase">{interval === '5-min' ? '5 mins' : interval}</span>
      </Button>
    ));
  }

  get chartData() {
    return this.props.systemAnalytics.data.map((data) => ({
      id: data._id,
      label: formatDate(data._label, true),
      attachedBoard: data.attachedBoard,
      onlineBoard: data.onlineBoard,
      inGameBoard: data.inGameBoard,
      sbcConnectionCount: data.sbcConnectionCount,
      clientConnectionCount: data.clientConnectionCount,
      guestConnectionCount: data.guestConnectionCount,
      availableLobbyParticipant: data.availableLobbyParticipant,
      inGameLobbyParticipant: data.inGameLobbyParticipant,
    }));
  }

  get dateInputs() {
    const { isLoading } = this.props;
    return (
      <Row className={styles.dateInputs}>
        <Col sm="6" md="5">
          <FormGroup className={styles.datePickerFormGroup}>
            <Label htmlFor="From">From</Label>
            <DatePicker
              selected={this.state.dateFrom}
              onChange={(e) => this.handleValueChange(e, 'dateFrom')}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={5}
              dateFormat="yyyy.MM.dd HH:mm"
              placeholderText="éééé. hh. nn."
              className="form-control"
              isClearable
            />
          </FormGroup>
        </Col>
        <Col sm="6" md="5">
          <FormGroup className={styles.datePickerFormGroup}>
            <Label htmlFor="From">To</Label>
            <DatePicker
              selected={this.state.dateTo}
              onChange={(e) => this.handleValueChange(e, 'dateTo')}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={5}
              dateFormat="yyyy.MM.dd HH:mm"
              placeholderText="éééé. hh. nn."
              className="form-control"
              isClearable
            />
          </FormGroup>
        </Col>
        <Col sm="12" md="2">
          <Button
            outline
            color="primary"
            className={styles.refreshButton}
            onClick={(_e) => this.getChart()}
            disabled={isLoading}
          >
            <span className="text-uppercase">Refresh</span>
          </Button>
        </Col>
      </Row>
    );
  }

  get dateAlerts() {
    const { dateFrom, dateTo } = this.state;

    const now = new Date();

    const fromYear = dateFrom ? dateFrom.getFullYear() : null;
    const toYear = dateTo ? dateTo.getFullYear() : null;
    const dateNow = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();

    const fromDatems = dateFrom
      ? new Date(fromYear, dateFrom.getMonth(), dateFrom.getDate()).getTime()
      : null;
    const toDatems = dateTo
      ? new Date(toYear, dateTo.getMonth(), dateTo.getDate()).getTime()
      : null;

    const datesHasNoUnion = dateFrom && dateTo && dateFrom > dateTo;

    return (
      <Fragment>
        <Col xs={12}>
          <Alert color="danger" isOpen={dateNow < fromDatems}>
            From date cannot set to future date!
          </Alert>
        </Col>
        <Col xs={12}>
          <Alert color="danger" isOpen={dateNow < toDatems}>
            To date cannot set to future date!
          </Alert>
        </Col>
        <Col xs={12}>
          <Alert color="danger" isOpen={datesHasNoUnion}>
            From date is later than to date!
          </Alert>
        </Col>
        <Col xs={12}>
          <Alert
            color="danger"
            isOpen={(fromYear && fromYear > maxDate) || (toYear && toYear > maxDate)}
          >
            Maximum year is {maxDate}!
          </Alert>
        </Col>
        <Col xs={12}>
          <Alert
            color="danger"
            isOpen={(fromYear && fromYear < minDate) || (toYear && toYear < minDate)}
          >
            Minimum year is {minDate}!
          </Alert>
        </Col>
      </Fragment>
    );
  }

  get periodButtons() {
    const { isLoading } = this.props;
    return PERIODS.map(({ key, label }) => (
      <Col sm="4" key={key + '-period-button'}>
        <Button
          outline
          color="primary"
          disabled={isLoading}
          className={cn(styles.dataPeriodButton, {
            active: this.state.systemAnalyticsPeriod === key,
          })}
          onClick={(e) => this.renderLastXPeriod(e, key)}
        >
          <span className="text-uppercase">{label}</span>
        </Button>
      </Col>
    ));
  }

  render() {
    return (
      <Card color="white">
        <CardHeader>
          <div className="card-header__left">
            <i className="fa fa-line-chart" /> System Analytics
          </div>
          <div className="card-header__right">{this.dataTypeButtons}</div>
        </CardHeader>
        <CardBody>
          <Fragment>
            <Row>
              <Col xs={6}>
                <Row>
                  <Col xs={12}>{this.dateInputs}</Col>
                  <Col sx={12}>
                    <Row>{this.periodButtons}</Row>
                  </Col>
                </Row>
              </Col>
              <Col xs={6}>
                <div className={styles.dataIntervalButtons}>{this.dataIntervalButtons}</div>

                {this.chartFilters}
              </Col>
            </Row>
            <Row>{this.dateAlerts}</Row>
            <Row>
              <Col xs={12}>{this.renderSystemAnalyticsChart}</Col>
            </Row>
          </Fragment>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state, _ownProps) => ({
  systemAnalytics: state.data.systemUsages.systemAnalytics,
  isLoading: state.scenes.systemUsages.systemAnalytics.isLoading,
});

export default connect(mapStateToProps)(SystemAnalytics);
