import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import actions from './actions';
import { actions as downloadActions } from 'services/entityTable';
import { users } from 'constants/Resources';

const INITIAL_STATE = {
  isLoading: true,
  page: 0,
  pageSize: 0,
  totalCount: 0,
  isDownloading: false,
  isError: false
};

const list = handleActions(
  {
    [actions.getUsers]: (state, { _payload }) => {
      return update(state, {
        isLoading: {
          $set: true,
        },
      });
    },

    [actions.getUsersSuccess]: (state, { payload: { page, pageSize, totalCount } }) => ({
      page,
      pageSize,
      totalCount,
      isLoading: false,
    }),

    [actions.getUsersFailure]: () => ({
      isLoading: false,
      page: 0,
      pageSize: 0,
      totalCount: 0,
      isError: true,
    }),

    [downloadActions.download]: (state, { payload: { resource } }) => {
      if (resource === users) {
        return update(state, {
          isDownloading: {
            $set: true,
          },
        });
      } else {
        return state;
      }
    },

    [downloadActions.downloadSuccessful]: (state, { payload }) => {
      if (payload === users) {
        return update(state, {
          isDownloading: {
            $set: false,
          },
        });
      } else {
        return state;
      }
    },

    [downloadActions.downloadFailure]: (state, { payload }) => {
      if (payload === users) {
        return update(state, {
          isDownloading: {
            $set: false,
          },
        });
      } else {
        return state;
      }
    },
  },
  INITIAL_STATE
);

export default list;
