import React from 'react';
import { isNull, isUndefined } from 'lodash';
import { SScrollContainer } from 'ScoliaComponents';
import { HeaderTitle } from '../../HeaderTitle';
import { Editor } from './components';
export const Description = ({ title, onBack, values, touched, errors, onChange, onBlur }) => {
    return (React.createElement(SScrollContainer, null,
        !isNull(title) && (React.createElement(HeaderTitle, { title: title, onBack: onBack })),
        !isUndefined(values.description) && (React.createElement(Editor, { name: "description", value: values.description, error: errors.description, touched: touched.description, onChange: onChange, onBlur: onBlur }))));
};
