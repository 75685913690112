import React from 'react'

import formatLabel from 'utils/formatLabel';

const Textarea = ({ label, labelClass = "col-sm-4", valueClass = "col-sm-8", isLabelFormatted = true, ...rest }) => {
  return (
    <dl className="row">
      <dt className={labelClass}>{isLabelFormatted ? formatLabel(label) : label}</dt>
      <dd className={valueClass}>
        <textarea
          {...rest}
        />
      </dd>
    </dl>
  )
}

export default Textarea