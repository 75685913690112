import React from 'react'
import { Link } from 'react-router-dom';
import { formatDate } from "utils"

const PlatformList = ({ platforms, isLoading }) => {
  if (isLoading) {
    return null;
  }

  return Object.keys(platforms).map((platformId, idx) => {
    const platform = platforms[platformId];
    return (
      <tr key={Object.getOwnPropertyNames(platforms)[idx]}>
        <td>
          <Link to={`/platforms/${platform.id}`}>
            {platform.versionNumber}
          </Link>
        </td>
        <td>{formatDate(platform.releasedAt)}</td>
        <td>{platform.description}</td>
      </tr>
    );
  });
}

export default PlatformList