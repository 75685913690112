import { all } from 'redux-saga/effects';

import watchGetSubscriptionsList from './watchers/GetSubscriptionsList';
import watchGetPriceOptions from './watchers/GetPriceOptions';

export default function* listSaga() {
  yield all([
    watchGetSubscriptionsList(),
    watchGetPriceOptions(),
  ]);
}
