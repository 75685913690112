import { createAction } from 'redux-actions';

export const getUsers = createAction(
  'USERS_GET_REQUEST'
);

export const getUsersFailure = createAction('USERS_GET_FAILURE');
export const getUsersSuccess = createAction('USERS_GET_SUCCESS');

export default {
  getUsers,
  getUsersFailure,
  getUsersSuccess,
};
