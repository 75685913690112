import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { List } from './components/List';
import { Show } from './components/Show';

const Products = ({ match: { url } }) => {
  return (
    <Switch>
      <Route component={List} exact name="Products List" path={`${url}/`} />
      <Route component={Show} exact name="Products info" path={`${url}/:productId`} />
    </Switch>
  );
}

export default Products;
