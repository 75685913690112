import React from 'react'
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import cn from 'classnames';

import { VERSION_NUMBER } from 'constants/Validation-regexp';

import { Input } from 'components/Form';
import TableRow from 'components/TableRow';

import { default as formatDate } from 'utils/formatDate';

import styles from './styles.module.scss';

const getValidationSchema = () =>
  Yup.object().shape({
    versionNumber: Yup.string()
      .required('Version number is required!')
      .matches(VERSION_NUMBER, 'Invalid version number format!'),
    releasedAt: Yup.string().required('Release date is required!'),
  });

const PlatformForm = ({ platform, formError, isEdit, isAdmin, onSubmit }) => {

  const initialState = {
    versionNumber: platform.versionNumber || '',
    description: platform.description || '',
    releasedAt: formatDate(platform.releasedAt) || '',
  }

  const handleSubmit = values => {
    onSubmit(values);
  };

  return (
    <div>
      <Formik
        initialValues={initialState}
        validationSchema={getValidationSchema()}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          values,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <TableRow.Text
              label="Version Number"
              labelClass={!isEdit ? "col-sm-3 required" : "col-sm-3"}
              valueClass="col-sm-9"
              inputClass="s-input"
              name="versionNumber"
              type="text"
              value={values.versionNumber}
              onBlur={handleBlur}
              onChange={handleChange}
              isDisabled={isEdit}
              error={touched.versionNumber && errors.versionNumber}
              hint={
                isEdit
                  ? null
                  : 'Required semantic version format (X.Y.Z), and can not be changed anymore.'
              }
            />

            <dl className="row">
              <dt className="col-sm-3">Release Date</dt>
              <dd className="col-sm-9">
                <Input.Date
                  disabled={isEdit}
                  name="releasedAt"
                  error={touched.releasedAt && errors.releasedAt}
                  value={values.releasedAt}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </dd>
            </dl>

            <TableRow.Textarea
              label="Description"
              labelClass="col-sm-3"
              valueClass="col-sm-9"
              className="col-sm-9"
              rows="10"
              name="description"
              value={values.description}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Some description, or change log..."
            />

            {formError && (
              <div className={cn(styles.formError, 'col-sm-12')}>
                {formError}
              </div>
            )}

            <div className="action-footer">
              {isAdmin && <button className="btn btn-success btn-md" type="submit">
                {!isEdit ? "Create platform" : "Save platform"}
              </button>}
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

PlatformForm.propTypes = {
  platform: PropTypes.object.isRequired,
  formError: PropTypes.string,
  isEdit: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
};

PlatformForm.defaultProps = {
  platform: {},
};

export default PlatformForm