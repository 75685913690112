import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { Input } from 'components/Form';

import { checkWhitespaceOnly, checkWhitespaceStartEnd } from 'utils/checkWhitespace';

import { Alert } from 'reactstrap';
import { default as formatDate } from 'utils/formatDate';
import { getHardwareLabel } from 'utils/getHardwareLabel';

import styles from './styles.module.scss';

const todayDate = () => {
  let d = new Date();
  let date_format_str =
    d.getFullYear().toString() +
    '-' +
    ((d.getMonth() + 1).toString().length === 2
      ? (d.getMonth() + 1).toString()
      : '0' + (d.getMonth() + 1).toString()) +
    '-' +
    (d.getDate().toString().length === 2
      ? d.getDate().toString()
      : '0' + d.getDate().toString());
  return date_format_str;
};

class Form extends Component {
  constructor(props) {
    super(props);

    const { firmware, isEdit } = this.props;

    const compatibleHardware =
      isEdit && firmware.compatibleHardware.length !== 0
        ? firmware.compatibleHardwareDocuments.reduce((acc, hwDoc) => {
          acc.push({ value: hwDoc._id, label: getHardwareLabel(hwDoc) });
          return acc;
        }, [])
        : [];

    this.state = {
      form: {
        versionNumber: firmware.versionNumber || '',
        releasedAt: isEdit
          ? formatDate(this.props.firmware.releasedAt)
          : todayDate(),
        description: firmware.description || '',
        fileContent: '',
        isStable: firmware.isStable || false,
        compatibleHardware: compatibleHardware,
      },
      hardwareVersions: Object.entries(this.props.hardwares).reduce(
        (acc, [key, value]) => {
          acc.push({ value: key, label: getHardwareLabel(value) });
          return acc;
        },
        []
      ),
      isSetToStable: false,
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.hardwares !== this.props.hardwares) {
      this.setState({
        hardwareVersions: Object.entries(this.props.hardwares).reduce(
          (acc, [key, value]) => {
            acc.push({ value: key, label: getHardwareLabel(value) });
            return acc;
          },
          []
        ),
      });
    }
  }

  getValidationSchema = () =>
    Yup.object().shape({
      versionNumber: Yup.string()
        .required('Version number is required!')
        .test('white-space-free', 'Version number is required and cannot contain only spaces!', checkWhitespaceOnly)
        .test('white-space-free', 'Version number cannot start or end with space!', checkWhitespaceStartEnd),
      releasedAt: Yup.date().required('Released date is required!'),
      description: Yup.string(),
      fileContent: this.props.isEdit
        ? Yup.mixed()
        : Yup.mixed().required('Firmware file is required!'),
      isStable: Yup.bool().required('Stability is required!'),
      compatibleHardware: Yup.array(),
    });

  handleSubmit = values => {
    const compatibleHardwareIds = values.compatibleHardware.reduce(
      (acc, hw) => {
        acc.push(hw.value);
        return acc;
      },
      []
    );

    const modifiedValues = {
      ...values,
      compatibleHardware: [...compatibleHardwareIds],
    };

    this.props.onSubmit(modifiedValues);
  };

  render() {
    const { form } = this.state;
    const { isEdit, formError, isLoading, isSuccessful, isAdmin } = this.props;

    if (isLoading) {
      return <i className="fa fa-spinner fa-spin fa-fw" />;
    }

    return (
      <Formik
        initialValues={form}
        validationSchema={this.getValidationSchema}
        onSubmit={this.handleSubmit}
      >
        {({
          errors,
          touched,
          values,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            {this.state.isSetToStable && (
              <Alert color="warning">
                Once a firmware is set to stable it can't be set back to not
                stable and can't be deleted, so take attention.
              </Alert>
            )}
            {!isSuccessful && <Alert color="danger">{formError}</Alert>}
            <dl className="row">
              <dt className="col-sm-3 required">Version number</dt>
              <dd className="col-sm-9">
                <Input.Text
                  name="versionNumber"
                  value={values.versionNumber}
                  error={touched.versionNumber && errors.versionNumber}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={isEdit}
                />
              </dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-3">Released at</dt>
              <dd className="col-sm-9">
                <Input.Date
                  name="releasedAt"
                  value={values.releasedAt}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={isEdit}
                  error={touched.releasedAt && errors.releasedAt}
                />
              </dd>
            </dl>
            {!isEdit && (
              <dl className="row">
                <Fragment>
                  <dt className="col-sm-3 required">Firmware file</dt>
                  <dd className="col-sm-9">
                    <Input.File
                      name="fileContent"
                      type="file"
                      //onBlur={handleBlur}
                      onChange={event => {
                        setFieldValue(
                          'fileContent',
                          event.currentTarget.files[0]
                        );
                      }}
                      error={touched.fileContent && errors.fileContent}
                    />
                  </dd>
                </Fragment>
              </dl>
            )}

            <dl className="row">
              <dt className="col-sm-3">Stable</dt>
              <dd className="col-sm-9">
                <input
                  checked={values.isStable}
                  name="isStable"
                  type="checkbox"
                  onBlur={handleBlur}
                  onChange={() => {
                    this.setState({
                      isSetToStable: !values.isStable,
                    });
                    setFieldValue('isStable', !values.isStable);
                  }}
                  disabled={this.state.form.isStable}
                />
              </dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-3">Compatible hardware</dt>
              <dd className="col-sm-9">
                <Select
                  classNamePrefix="react-select"
                  value={values.compatibleHardware}
                  name="compatibleHardware"
                  isMulti
                  onChange={selectedOptions =>
                    setFieldValue('compatibleHardware', selectedOptions || [])
                  }
                  options={this.state.hardwareVersions}
                />
              </dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-3">Description</dt>
              <dd className="col-sm-9">
                <textarea
                  className={styles.formTextarea}
                  name="description"
                  value={values.description}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </dd>
            </dl>
            <div className="action-footer">
              {isAdmin && <button className="btn btn-success btn-md" type="submit">
                Create firmware
              </button>}
            </div>
          </form>
        )}
      </Formik>
    );
  }
}
Form.propTypes = {
  firmware: PropTypes.object.isRequired,
  formError: PropTypes.string,
  isEdit: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  hardwares: PropTypes.object,
  isSuccessful: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

Form.defaultProps = {
  firmware: {},
  isSuccessful: true,
};

export default Form;
