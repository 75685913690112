import { createAction } from 'redux-actions';

export const createTournament = createAction('CREATE_TOURNAMENT_REQUEST');
export const createTournamentFailure = createAction('CREATE_TOURNAMENT_FAILURE');
export const createTournamentSuccess = createAction('CREATE_TOURNAMENT_SUCCESS');

export const resetCreateMultipleTournamentsState = createAction(
  'RESET_CREATE_MULTIPLE_TOURNAMENTS_STATE'
);

export default {
  createTournament,
  createTournamentFailure,
  createTournamentSuccess,
  resetCreateMultipleTournamentsState
};
