import { X01UserSubStatistics } from 'schemas';

export const getFormattedNumber = (number: number) =>
  number ? (Number.isInteger(number) ? number : number.toFixed(2)) : 0;

export const formatNumber = (number: number, precision = 1, stringify = false) =>
  number
    ? Number.isInteger(number)
      ? stringify
        ? number > 999
          ? (number / 1000).toFixed(precision) + 'k'
          : number
        : number
      : parseFloat(String(number)).toFixed(precision)
    : 0;

export const firstXAverage = (userStats: X01UserSubStatistics) => {
  const { first3Average, first6Average, first9Average } = userStats;

  const averages = [first3Average, first6Average, first9Average];

  const sums = averages.filter(avg => avg !== undefined).reduce((sum, a) => sum + a.sum, 0);
  const counts = averages.filter(avg => avg !== undefined).reduce((sum, a) => sum + a.count, 0);

  return counts === 0 ? 0 : getFormattedNumber((sums / counts) * 3);
};
