import { createAction } from 'redux-actions';

export const getManufacturer = createAction(
  'MANUFACTURER_GET_REQUEST',
  (manufacturerId, queryParams, isLoading = true) => ({
    manufacturerId,
    queryParams,
    isLoading
  })
);

export const getManufacturerFailure = createAction('MANUFACTURER_GET_FAILURE');
export const getManufacturerSuccess = createAction('MANUFACTURER_GET_SUCCESS');

export default {
  getManufacturer,
  getManufacturerFailure,
  getManufacturerSuccess
};
