import React from 'react';

import formatLabel from 'utils/formatLabel';

const Default = ({ label, value }) => {
  return (
    <div className="row">
      <div className="col-sm-4">{formatLabel(label)}</div>
      <div className="col-sm-8">{value ?? '-'}</div>
    </div>
  )
}

export default Default