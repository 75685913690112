import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import actions from './actions';

const INITIAL_STATE = {
  generatedSerialNumbers: [],
};

const createReducer = handleActions(
  {
    [actions.createTournament]: state =>
      update(state, {
        isCreatePending: {
          $set: true,
        },
        createError: {
          $set: '',
        },
      }),

    [actions.createMultiple]: state =>
      update(state, {
        isCreateMultiplePending: {
          $set: true,
        },
        createMultipleError: {
          $set: '',
        },
      }),

    [actions.createTournamentSuccess]: state =>
      update(state, {
        isCreatePending: {
          $set: false,
        },
        isCreateSuccessful: {
          $set: true,
        },
        createError: {
          $set: '',
        },
      }),

    [actions.createMultipleTournamentsSuccess]: (state, { payload }) =>
      update(state, {
        isCreateMultiplePending: {
          $set: false,
        },
        isCreateMultipleSuccessful: {
          $set: true,
        },
        createMultipleError: {
          $set: '',
        },
        generatedSerialNumbers: {
          $set: payload,
        },
      }),

    [actions.resetCreateMultipleTournamentsState]: state =>
      update(state, {
        isCreateMultiplePending: {
          $set: false,
        },
        isCreateMultipleSuccessful: {
          $set: false,
        },
        createMultipleError: {
          $set: '',
        },
        generatedSerialNumbers: {
          $set: [],
        },
      }),

    [actions.createTournamentFailure]: (state, { payload }) =>
      update(state, {
        isCreateMultiplePending: {
          $set: false,
        },
        isCreateMultipleSuccessful: {
          $set: false,
        },
        createMultiplerror: {
          $set: payload,
        },
      }),

    [actions.createMultipleTournamentsFailure]: (state, { payload }) =>
      update(state, {
        isCreatePending: {
          $set: false,
        },
        isCreateSuccessful: {
          $set: false,
        },
        createError: {
          $set: payload,
        },
      }),
  },
  INITIAL_STATE
);

export default createReducer;
