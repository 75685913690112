import store from 'store2';
import { eventChannel } from 'redux-saga';
import { all, call, put, race, select, take } from 'redux-saga/effects';
import { eventBus } from 'eventBus';

import { dataURIToBlob, isNullOrUndefined } from 'utils';

import { selectors as boardsShowSelectors } from 'scenes/Boards/components/Show';

import actions from './actions';

let socket = null;

function _initConnection(wsUrl) {
  return new Promise((resolve, reject) => {
    // TODO: Instantiate a decorated WS object here
    socket = new WebSocket(wsUrl);

    socket.onopen = () => {
      console.log(`WS opened on ${wsUrl}`);
      resolve(socket);
    };

    socket.onclose = (event) => {
      console.log(`Socket closed (${event.reason})`);
    };

    socket.onerror = (err) => {
      console.log(err);
      reject(err);
    };
  });
}

function _initChannel(socket) {
  return eventChannel((emit) => {
    socket.onmessage = (event) => {
      let data;

      try {
        // Parse and push to channel
        // TODO: Schema validation
        data = JSON.parse(event.data);
        // emit(JSON.parse(event.data));
      } catch (e) {
        console.log('Could not parse msg content as JSON');
        return;
      }

      emit(data);
    };

    return () => {
      socket.close();
    };
  });
}

function* _dispatchFromChannel(channel) {
  while (true) {
    const event = yield take(channel);

    if (event.type === actions.Api.cameraImages.toString()) {
      const shouldProcessImages = yield select(boardsShowSelectors.getShouldPreProcessApiImages);
      let images = [];

      // Only process images to Blobs if streaming
      if (shouldProcessImages) {
        // REVIEW: Use yield all and yield call
        // REVIEW: https://github.com/redux-saga/redux-saga/issues/306
        images = yield all(
          event.payload.images.map(function* (img) {
            const blob = yield call(dataURIToBlob, img);
            return yield call(window.URL.createObjectURL, blob);
          })
        );
      }

      yield put(
        actions.Api.cameraImages({
          ...event.payload,
          images,
        })
      );
    } else {
      if (!isNullOrUndefined(event.payload?.data?.eventType)) {
        eventBus.emit(event.payload.data.eventType, event.payload);
      }
      yield put(event);
    }
  }
}

// function* _internalWatcher() {}

function* _externalWatcher(channel) {
  yield call(_dispatchFromChannel, channel);
}

function* getWsUrl() {
  const { wsManagementUrl } = yield select((state) => state.configuration);

  if (!wsManagementUrl) {
    console.warn('No management WS url is present');
    return '';
  }

  return store.has('token') ? wsManagementUrl + `?token=${store.get('token')}` : wsManagementUrl;
}

function* wsSaga() {
  while (true) {
    yield take(actions.initWsConnection);

    let socket;
    try {
      const wsUrl = yield call(getWsUrl);
      socket = yield call(_initConnection, wsUrl);
      yield put(actions.initWsConnectionSuccess());
    } catch (e) {
      console.log(e.message);
      yield put(actions.initWsConnectionFailure());
    }

    if (socket) {
      const channel = yield call(_initChannel, socket);

      const { cancel } = yield race({
        watchers: all([
          // call(_internalWatcher, socket), // TODO
          call(_externalWatcher, channel),
        ]),
        cancel: take(actions.closeWsConnection),
      });

      if (cancel) {
        channel.close();
      }
    }
  }
}

export { socket as Socket };

export default wsSaga;
