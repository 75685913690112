import { call, put, takeLatest } from 'redux-saga/effects';

import { Boards } from 'services/api';

import { callApi } from 'services/rest';

import { notifyError, notifySuccess } from 'services/notification/actions';

import {
  createMultipleBoards,
  createMultipleBoardsFailure,
  createMultipleBoardsSuccess,
} from 'scenes/Boards/components/Create/actions';

function* watchCreateMultipleBoards({ payload: { values } }) {
  try {
    const { data: { serialNumbers }} = yield call(callApi, Boards.createMultiple, values);

    yield put(createMultipleBoardsSuccess(serialNumbers));
    yield put(notifySuccess(`Serial numbers were created successfully.`))
  } catch (e) {
    if (!e.isFetch) {
      throw e;
    }
    yield put(createMultipleBoardsFailure(e.data.error));
    yield put(notifyError(`Failed to generate serial numbers.`))

  }
}

export default () => takeLatest(createMultipleBoards, watchCreateMultipleBoards);