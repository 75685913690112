import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import { actions as platformActions } from 'scenes/Platforms';
import { actions as listActions } from 'scenes/Platforms/components/List';

const INITIAL_STATE = {};

const platformsReducer = handleActions(
  {
    [listActions.getPlatformsSuccess]: (_state, { payload }) => {
      const platforms = payload.reduce((platforms, platform) => {
        platform.id = platform._id;
        delete platform._id;

        platforms[platform.id] = platform;
        return platforms;
      }, {});

      return {
        ...platforms
      }
    },

    [platformActions.getPlatformSuccess]: (state, { payload: platform }) => {
      platform.id = platform._id;
      delete platform._id;
      return update(state, {
        [platform.id]: {
          $set: platform
        }
      });
    }
  },
  INITIAL_STATE
)

export default platformsReducer;