import { call, put, takeLatest } from 'redux-saga/effects';

import { Tournaments } from 'services/api';

import {
  getPrivateTournaments,
  getPrivateTournamentsFailure,
  getPrivateTournamentsSuccess,
} from 'scenes/PrivateTournaments/components/List/actions';
import { callApi } from 'services/rest';

function* watchGetTournaments({ payload }) {
  try {
    const { data } = yield call(callApi, Tournaments.list, payload);
    yield put(getPrivateTournamentsSuccess(data));
  } catch (e) {
    yield put(getPrivateTournamentsFailure());
  }
}

// It must be wrapped in a function, so execution will happen only on function call
export default () => takeLatest(getPrivateTournaments, watchGetTournaments);
