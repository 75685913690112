import { createAction } from 'redux-actions';

export const getSystemAnalytics = createAction('SYSTEM_ANALYTICS_GET_REQUEST');
export const getSystemAnalyticsSuccess = createAction('SYSTEM_ANALYTICS_GET_SUCCESS');
export const getSystemAnalyticsFailure = createAction('SYSTEM_ANALYTICS_GET_FAILURE');

export default {
  getSystemAnalytics,
  getSystemAnalyticsSuccess,
  getSystemAnalyticsFailure,
};
