import { createAction } from 'redux-actions';

export const getTokenPurchases = createAction('TOKEN_PURCHASES_GET_REQUEST');
export const getTokenPurchasesFailure = createAction('TOKEN_PURCHASES_GET_FAILURE');
export const getTokenPurchasesSuccess = createAction('TOKEN_PURCHASES_GET_SUCCESS');

export default {
  getTokenPurchases,
  getTokenPurchasesFailure,
  getTokenPurchasesSuccess,
};
