import { formatDateNumber } from 'utils';

export const getDateForFileName = () => {
  const now = new Date();
  const nowFormatted =
    '' +
    now.getFullYear() +
    '-' +
    formatDateNumber(now.getMonth() + 1) +
    '-' +
    formatDateNumber(now.getDate()) +
    '-' +
    formatDateNumber(now.getHours()) +
    '-' +
    formatDateNumber(now.getMinutes()) +
    '-' +
    formatDateNumber(now.getSeconds());

  return nowFormatted;
};