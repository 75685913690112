export const GameEndStatus = {
  Forsaken: 'Forsaken',
  Aborted: 'Aborted',
  Finished: 'Finished'
}

export const getStatusVariables = (status) => {
  const isFinished = status === GameEndStatus.Finished;
  const isAborted = status === GameEndStatus.Aborted;
  const isForsaken = status === GameEndStatus.Forsaken;
  const isForfeited = status === 'Forfeited'; //Forfeited is not part of the GameEndStatus enum

  return { isFinished, isAborted, isForsaken, isForfeited };
}

export default GameEndStatus