import { handleActions } from 'redux-actions'

import actions from './actions'
import { actions as wsActions } from 'services/ws'
import { actions as boardActions } from 'scenes/Boards'

const INITIAL_STREAM_CONFIG = {
  quality: 50,
  scale: 25,
};

const INITIAL_STATE = {
  isLoading: false,
  isLoadingCurrentState: false,
  isLoadingStatistics: false,
  isLoadingLimitedThrows: false,
  isSnapshotPending: false,
  isStreaming: false,
  currentlyPendingInvoiceUrl: false,
  stream: [],
  streamConfig: INITIAL_STREAM_CONFIG,
  snapshotError: null
};

const showReducer = handleActions(
  {
    [boardActions.getBoard]: (state, { payload: { isLoading } }) => ({
      ...state,
      isLoading,
    }),

    [boardActions.getBoardSuccess]: state => ({
      ...state,
      isLoading: false,
    }),

    [boardActions.getBoardFailure]: state => ({
      ...state,
      isLoading: false,
    }),

    [boardActions.getBoardSessions]: state => ({
      ...state,
      isLoadingSessions: true,
    }),

    [boardActions.getBoardSessionsSuccess]: state => ({
      ...state,
      isLoadingSessions: false,
    }),

    [boardActions.getBoardSessionsFailure]: state => ({
      ...state,
      isLoadingSessions: false,
    }),

    [boardActions.getBoardStatistics]: state => ({
      ...state,
      isLoadingStatistics: true,
    }),

    [boardActions.getBoardStatisticsSuccess]: state => ({
      ...state,
      isLoadingStatistics: false,
    }),

    [boardActions.getBoardStatisticsFailure]: state => ({
      ...state,
      isLoadingStatistics: false,
    }),

    [boardActions.getBoardCurrentState]: state => ({
      ...state,
      isLoadingCurrentState: true,
    }),

    [boardActions.getBoardCurrentStateSuccess]: state => ({
      ...state,
      isLoadingCurrentState: false,
    }),

    [boardActions.getBoardLimitedThrows]: state => ({
      ...state,
      isLoadingLimitedThrows: true,
    }),

    [boardActions.getBoardLimitedThrowsSuccess]: state => ({
      ...state,
      isLoadingLimitedThrows: false,
    }),

    [boardActions.getBoardLimitedThrowsFailure]: state => ({
      ...state,
      isLoadingLimitedThrows: false,
    }),

    [actions.startStream]: state => ({
      ...state,
      isStreaming: true,
    }),

    [actions.finishStream]: state => ({
      ...state,
      isStreaming: false,
      stream: [],
    }),

    [actions.configureStream]: (state, { payload }) => ({
      ...state,
      streamConfig: payload === null ? INITIAL_STREAM_CONFIG : payload,
    }),

    [actions.getSnapshot]: state => ({
      ...state,
      isSnapshotPending: true,
    }),

    [actions.getSnapshotSuccess]: (state, { payload }) => ({
      ...state,
      isSnapshotPending: false,
      stream: payload,
      snapshotError: null
    }),

    [actions.getSnapshotFailure]: (state, { payload }) => ({
      ...state,
      isSnapshotPending: false,
      snapshotError: payload,
      stream: []
    }),

    [actions.deleteBoard]: state => ({
      ...state,
      isLoading: true,
    }),

    [actions.deleteBoardSuccess]: state => ({
      ...state,
      isLoading: false,
    }),

    [actions.deleteBoardFailure]: state => ({
      ...state,
      isLoading: false,
    }),

    [actions.upgradeLimitedBoardService]: state => ({
      ...state,
      isLoading: true,
    }),

    [actions.upgradeLimitedBoardServiceSuccess]: state => ({
      ...state,
      isLoading: false,
    }),

    [actions.upgradeLimitedBoardServiceFailure]: state => ({
      ...state,
      isLoading: false,
    }),

    [actions.changeTestMode]: state => ({
      ...state,
      isLoading: true,
    }),

    [actions.changeTestModeSuccess]: state => ({
      ...state,
      isLoading: false,
    }),

    [actions.changeTestModeFailure]: state => ({
      ...state,
      isLoading: false,
    }),

    [actions.changeWifiConfiguration]: state => ({
      ...state,
      isLoading: true,
    }),

    [actions.changeTestModeSuccess]: state => ({
      ...state,
      isLoading: false,
    }),

    [actions.changeTestModeFailure]: state => ({
      ...state,
      isLoading: false,
    }),

    [actions.getInvoiceUrl]: (state, { payload }) => ({
      ...state,
      currentlyPendingInvoiceUrl: payload.paddleTransactionId
    }),

    [actions.boardTransactionInvoiceUrl]: state => ({
      ...state,
      currentlyPendingInvoiceUrl: false,
    }),

    [wsActions.Api.cameraImages]: (state, { payload: { images: stream } }) =>
      state.isStreaming || state.isSnapshotPending
        ? {
            ...state,
            stream,
          }
        : state,
  },
  INITIAL_STATE
)

export default showReducer
