import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Alert } from 'reactstrap';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { checkWhitespaceOnly, checkWhitespaceStartEnd } from 'utils/checkWhitespace';

import { Input } from 'components/Form';
import { Preview } from '../Preview';

import styles from './styles.module.scss';

const hashConfig = {
  trigger: '#',
  separator: ' ',
};

const notificationTypes = [
  { value: 'warning', label: 'Warning' },
  { value: 'success', label: 'Success' },
  { value: 'info', label: 'Info' },
  { value: 'error', label: 'Error' },
];

class Form extends Component {
  constructor(props) {
    super(props);

    const { notification } = this.props;

    const html = notification.content || '';

    const editorState = this.textToEditorState(html);

    this.state = {
      editorState: editorState,
      form: {
        title: notification.title || '',
        content: notification.content || '',
        isActive: notification.isActive || false,
        type: notification.type || 'warning',
      },
    };
  }

  componentDidUpdate(prevProps) {
    const { notification, isEdit } = this.props;

    if (isEdit && prevProps.notification !== notification) {
      this.setState({
        form: notification,
        editorState: this.textToEditorState(notification.content),
      });
    } else if ( prevProps.isEdit && !isEdit ) {
      this.setState({
        form: {
          title: '',
          content: '',
          isActive: false,
          type: 'warning',
        },
        editorState: this.textToEditorState('')
      });
    }

  }

  getValidationSchema = () =>
    Yup.object().shape({
      title: Yup.string()
        .required('Title is  required!')
        .test(
          'white-space-free',
          'Title is required and cannot contain only spaces!',
          checkWhitespaceOnly
        )
        .test('white-space-free', 'Title cannot start or end with space!', checkWhitespaceStartEnd),
      content: Yup.string().required('Content required!'),
      type: Yup.string().required('Content required!'),
    });

  onEditorStateChange = (editorState, setFieldValue, setFieldError) => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const rawText = rawContentState.blocks
      .map(block => (!block.text.trim() && '\n') || block.text)
      .join('\n');
    //we validate here, before transforming input to html
    if (rawText.trim().length === 0) {
      setFieldError('content', 'Content required!');
      setFieldValue('content', '');
    } else {
      const markup = draftToHtml(rawContentState, hashConfig);
      setFieldError('content', '');
      setFieldValue('content', markup);
    }

    this.setState({
      editorState: editorState,
    });
  };

  handleSubmit = values => {
    this.props.onSubmit(values);
  };

  handleDelete = e => {
    e.preventDefault();
    this.props.onDelete();
  };

  textToEditorState = text => {
    const { contentBlocks, entityMap } = htmlToDraft(text);

    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    return EditorState.createWithContent(contentState);
  };

  render() {
    const { form } = this.state;
    const { isEdit, isLoading, isAdmin } = this.props;

    if (isLoading) {
      return <i className="fa fa-spinner fa-spin fa-fw" />;
    }

    return (
      <>
        <Formik
          initialValues={form}
          validationSchema={this.getValidationSchema}
          onSubmit={this.handleSubmit}
          enableReinitialize
        >
          {({
            errors,
            touched,
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldError,
          }) => (
            <form onSubmit={handleSubmit} className={styles.form}>
              <dl className="row">
                <dt className="col-sm-2 required">Title</dt>
                <dd className="col-sm-10">
                  {isAdmin ? (
                    <Input.Text
                      name="title"
                      value={values.title}
                      error={touched.title && errors.title}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={!isAdmin}
                      placeholder={"Enter the notification title"}
                    />
                  ) : (
                    values.title
                  )}
                </dd>
              </dl>
              <dl className="row">
                <dt className="col-sm-2 required">Content</dt>
                <dd className="col-sm-10">
                  {isAdmin ? (
                    <>
                      <Editor
                        editorState={this.state.editorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName={styles.richTextField}
                        onEditorStateChange={e =>
                          this.onEditorStateChange(e, setFieldValue, setFieldError)
                        }
                        onChange={handleChange}
                        spellCheck
                        toolbar={{
                          options: ['inline', 'emoji', 'remove', 'history'],
                          inline: {
                            options: ['bold', 'italic', 'underline', 'strikethrough'],
                          },
                        }}
                        placeholder={"Enter the notification content"}
                      />
                      {errors?.content && (
                        <Alert color="danger">
                          <i className="fa fa-exclamation-triangle" />
                          {errors?.content}
                        </Alert>
                      )}
                    </>
                  ) : (
                    values.content
                  )}
                </dd>
              </dl>
              {isAdmin && (
                <>
                  <dl className="row">
                    <dt className="col-sm-2">Type</dt>
                    <dd className="col-sm-10">
                      <select
                        name="type"
                        id="exampleSelect"
                        onChange={e => setFieldValue('type', e.target.value)}
                        value={values.type}
                      >
                        {notificationTypes.map(type => (
                          <option value={type.value} key={type.value}>
                            {type.label}
                          </option>
                        ))}
                      </select>
                    </dd>
                  </dl>
                  <div className="action-footer">
                    <button className="btn btn-success btn-md" type="submit">
                      {isEdit ? 'Update' : 'Create'} notification
                    </button>
                    {isEdit && (
                      <button
                        className="btn btn-danger btn-md"
                        onClick={this.handleDelete}
                        style={{ marginRight: '10px' }}
                      >
                        Delete notification
                      </button>
                    )}
                  </div>
                </>
              )}
              <div className={styles.previewContainer}>
                <div className={styles.previewLabel}>Preview: </div>
                <Preview content={values.content} title={values.title} type={values.type} />
              </div>
            </form>
          )}
        </Formik>
      </>
    );
  }
}
Form.propTypes = {
  isEdit: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  isAdmin: PropTypes.bool,
};

Form.defaultProps = {
  notification: {},
  isAdmin: false,
};

export default Form;
