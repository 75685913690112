import { createAction } from 'redux-actions';

export const updateManufacturer = createAction('UPDATE_MANUFACTURER_REQUEST');
export const updateManufacturerFailure = createAction('UPDATE_MANUFACTURER_FAILURE');
export const updateManufacturerSuccess = createAction('UPDATE_MANUFACTURER_SUCCESS');

export default {
  updateManufacturer,
  updateManufacturerFailure,
  updateManufacturerSuccess
};
