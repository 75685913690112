import { call, put, takeLatest } from 'redux-saga/effects';

import { Firmwares } from 'services/api';
import { browserHistory } from 'services/history';

import {
  deleteFirmware,
  deleteFirmwareFailure,
  deleteFirmwareSuccess,
} from 'scenes/Firmwares/actions';
import { getFirmwares } from 'scenes/Firmwares/components/List/actions';
import { callApi } from 'services/rest';

function* watchDeleteFirmware({ payload }) {
  try {
    yield call(callApi, Firmwares.delete, `${payload}`);
    yield put(deleteFirmwareSuccess());
    yield put(getFirmwares());
    browserHistory.push(`/firmwares`);
  } catch (e) {
    console.log(e);
    yield put(deleteFirmwareFailure());
  }
}

export default () => takeLatest(deleteFirmware, watchDeleteFirmware);
