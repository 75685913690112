import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import { List } from './components/List';
import { Show } from './components/Show';
import { Create } from './components/Create';
import { Edit } from './components/Edit';

class CommercialPartners extends Component {
  render() {
    const { match: { url } } = this.props;
    return (
      <Switch>
        <Route
          component={Create}
          exact
          name="Create partner"
          path={`${url}/new`}
        />
        <Route
          component={Edit}
          exact
          name="Edit partner"
          path={`${url}/:serviceAccountId/edit`}
        />
      <Route component={List} exact name="Partner list" path={`${url}/`} />
        <Route
          component={Show}
          exact
          name="Partner info"
          path={`${url}/:serviceAccountId`}
        />
      </Switch>
    );
  }
}

export default CommercialPartners;
