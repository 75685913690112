import { all } from 'redux-saga/effects';

import watchCreateNotification from './watchers/createNotification';
import watchDeleteNotification from './watchers/deleteNotifications';
import watchGetNotifications from './watchers/getNotifications';
import watchUpdateNotifications from './watchers/updateNotification';
import watchUpdateVisibility from './watchers/updateVisibility';

export default function* notificationsSaga() {
  yield all([
    watchGetNotifications(),
    watchUpdateNotifications(),
    watchCreateNotification(),
    watchDeleteNotification(),
    watchUpdateVisibility(),
  ]);
}
