import { call, put, takeLatest } from 'redux-saga/effects';

import { TokenPurchases } from 'services/api';
import { callApi } from 'services/rest';

import { getTokenPurchases, getTokenPurchasesFailure, getTokenPurchasesSuccess } from "scenes/TokenPurchases/components/List/actions"

function* watchGetTokenPurchases({ payload }) {
  try {
    const { data } = yield call(callApi, TokenPurchases.index, payload);
    yield put(getTokenPurchasesSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(getTokenPurchasesFailure());
  }
}

export default () => takeLatest(getTokenPurchases, watchGetTokenPurchases);