import { call, put, takeLatest } from 'redux-saga/effects';

import { Platforms } from 'services/api';
import { callApi } from 'services/rest';

import {
  getPlatforms,
  getPlatformsFailure,
  getPlatformsSuccess,
} from 'scenes/Platforms/components/List/actions';

function* watchGetPlatforms({ payload: { queryParams } }) {
  try {
    const { data } = yield call(callApi, Platforms.index, queryParams);
    yield put(getPlatformsSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(getPlatformsFailure());
  }
}

// It must be wrapped in a function, so execution will happen only on function call
export default () => takeLatest(getPlatforms, watchGetPlatforms);
