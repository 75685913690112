import React, { Fragment, useReducer } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Button, FormFeedback } from 'reactstrap';
import DatePicker from 'react-datepicker';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { isEqual, last } from 'lodash-es';

import {
  CONFIG_TYPES,
  COLOR_OPTIONS,
  STAGES_BY_ORDER,
  SCORE_CORRECTION,
  FREQUENCY_OPTIONS,
  DEFAULT_GAME_CONFIG,
  DEFAULT_TOURNAMENT_CONFIG_FORM,
} from 'constants/TournamentConfiguration';
import {
  STAGE_PRIZES,
  AVG_OPTIONS,
  TOURNAMENT_STAGES,
  TournamentStageLabels,
} from 'constants/Tournament';
import { TournamentState } from 'enums';

import { MAX_DESCRIPTION_LENGTH, tournamentFormValidationObject } from '../Import/validationSchema';

import { Input } from 'components/Form';
import InputSelect from 'components/Form/Input/Select';
import GameConfiguration from './components/GameConfiguration/GameConfiguration';

import highestPowerof2 from 'utils/highestPowerOf2';
import minMaxFilter from 'utils/minMaxFilter';

import styles from './styles.module.scss';

const hashConfig = {
  trigger: '#',
  separator: ' ',
};

// these are for description change
const textToEditorState = (text) => {
  const { contentBlocks, entityMap } = htmlToDraft(text);
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

  return EditorState.createWithContent(contentState);
};

//for transforming the various configuration form
const transformVariousGameConfig = (variousGameConfig) => {
  const transformedConfig = {};
  const availableFirstToThrows = ['WDF', 'PDC'];

  STAGES_BY_ORDER.forEach((stageKey) => {
    transformedConfig[stageKey] = variousGameConfig?.[stageKey]
      ? {
          startTimer: parseInt(variousGameConfig[stageKey].startTimer),
          inactivityTimer: parseInt(variousGameConfig[stageKey].inactivityTimer),
          startScore: parseInt(variousGameConfig[stageKey].startScore),
          scoreType: variousGameConfig[stageKey].scoreType,
          sets: parseInt(variousGameConfig[stageKey].sets),
          legs: parseInt(variousGameConfig[stageKey].legs),
          gameInType: variousGameConfig[stageKey].gameInType,
          gameOutType: variousGameConfig[stageKey].gameOutType,
          finishTimer: parseInt(variousGameConfig[stageKey].finishTimer),
          __type: variousGameConfig[stageKey].__type,
          firstToThrow:
            (availableFirstToThrows.includes(variousGameConfig[stageKey].bullThrow)
              ? variousGameConfig[stageKey].bullThrow
              : variousGameConfig[stageKey].firstToThrow) ?? null,
        }
      : {
          ...DEFAULT_GAME_CONFIG,
        };

    if (!transformedConfig[stageKey]?.startTimer) delete transformedConfig[stageKey].startTimer;

    if (!transformedConfig[stageKey]?.finishTimer) delete transformedConfig[stageKey].finishTimer;

    if (!transformedConfig[stageKey]?.inactivityTimer)
      delete transformedConfig[stageKey].inactivityTimer;
  });

  return transformedConfig;
};

const transformExpiredDate = (registrationStartDate, registrationEndDate, tournamentStartDate) => {
  const convertedRegistrationStartDate = new Date(registrationStartDate);
  const convertedRegistrationEndDate = new Date(registrationEndDate);
  const convertedTournamentStartDate = new Date(tournamentStartDate);

  if (convertedRegistrationStartDate > Date.now())
    return {
      registrationStartDate: convertedRegistrationStartDate,
      registrationEndDate: convertedRegistrationEndDate,
      tournamentStartDate: convertedTournamentStartDate,
    };
  const registrationStartDateHelper = convertedRegistrationStartDate;
  let newRegistrationStartDate = new Date();

  newRegistrationStartDate.setDate(newRegistrationStartDate.getDate() + 1);
  newRegistrationStartDate.setHours(registrationStartDateHelper.getHours());
  newRegistrationStartDate.setMinutes(registrationStartDateHelper.getMinutes());
  newRegistrationStartDate.setSeconds(0);

  const registrationEndDiff = convertedRegistrationEndDate - registrationStartDateHelper;
  const tournamentStartDiff = convertedTournamentStartDate - registrationStartDateHelper;

  let newRegistrationEndDate = new Date(newRegistrationStartDate.getTime() + registrationEndDiff);
  let newTournamentStartDate = new Date(newRegistrationStartDate.getTime() + tournamentStartDiff);

  return {
    registrationStartDate: newRegistrationStartDate,
    registrationEndDate: newRegistrationEndDate,
    tournamentStartDate: newTournamentStartDate,
  };
};

const getInitialState = ({ tournament, isEdit, userId }) => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  // load existing tournament
  if (tournament) {
    const variousGameConfig = transformVariousGameConfig(tournament.variousGameConfig);
    let variousIntervals = [];

    //handle stages order
    if (tournament.configType === 'Fixed') {
      variousIntervals.push(STAGES_BY_ORDER);
    } else {
      STAGES_BY_ORDER.forEach((stage, idx) => {
        if (idx === 0) {
          variousIntervals.push([stage]);
        } else if (variousGameConfig[stage]) {
          const prevStage = STAGES_BY_ORDER[idx - 1];
          if (isEqual(variousGameConfig[prevStage], variousGameConfig[stage])) {
            variousIntervals[variousIntervals?.length - 1] = [...last(variousIntervals), stage];
          } else {
            variousIntervals.push([stage]);
          }
        }
      });

      const res = handleStageIntervalsCalculations(
        Object.keys(tournament.initialPrizing.stagePrizes)?.length - 1,
        variousIntervals
      );
      variousIntervals = res.variousIntervals;
    }

    const tournamentDates = transformExpiredDate(
      tournament.registrationStartDate,
      tournament.registrationEndDate,
      tournament.tournamentStartDate
    );

    const { initialPrizing, ...rest } = tournament;

    return {
      editorState: textToEditorState(tournament?.description),
      prizeCategoryCounter: Object.keys(initialPrizing.stagePrizes)?.length ?? 9,
      variousIntervals: variousIntervals,
      form: {
        ...rest,
        ...tournamentDates,
        organiser: {
          name: rest.organiser.name,
          organiserId: userId,
        },
        gameConfig: rest.gameConfig
          ? {
              startTimer: parseInt(rest.gameConfig.startTimer),
              inactivityTimer: parseInt(rest.gameConfig.inactivityTimer),
              startScore: parseInt(rest.gameConfig.startScore),
              scoreType: rest.gameConfig.scoreType,
              sets: parseInt(rest.gameConfig.sets),
              legs: parseInt(rest.gameConfig.legs),
              gameInType: rest.gameConfig.gameInType,
              gameOutType: rest.gameConfig.gameOutType,
              finishTimer: parseInt(rest.gameConfig.finishTimer),
              firstToThrow: rest.gameConfig.bullThrow,
              __type: rest.gameConfig.__type,
            }
          : DEFAULT_GAME_CONFIG,
        variousGameConfig: variousGameConfig,
        multipleTournamentQuantity: 1,
        state: isEdit ? rest.state : null,
        stagePrizes: initialPrizing.stagePrizes,
      },
      recAverages: AVG_OPTIONS.find(
        (element) =>
          element.minValue === rest.constraints.recommended.averageRange.min &&
          element.maxValue === rest.constraints.recommended.averageRange.max
      ),
    };
  }

  // initialize new tournament
  const editorState = textToEditorState('');

  return {
    editorState: editorState,
    prizeCategoryCounter: 9,
    variousIntervals: [STAGES_BY_ORDER],
    form: {
      ...DEFAULT_TOURNAMENT_CONFIG_FORM,
      registrationStartDate: new Date(tomorrow.setHours(12, 0, 0, 0)),
      registrationEndDate: new Date(tomorrow.setHours(18, 0, 0, 0)),
      tournamentStartDate: new Date(tomorrow.setHours(18, 0, 0, 0)),
      multipleTournamentQuantity: 1,
      organiser: {
        ...DEFAULT_TOURNAMENT_CONFIG_FORM.organiser,
        organiserId: userId,
      },
    },
    recAverages: AVG_OPTIONS[0],
  };
};

const stateHandler = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'partialUpdate':
      return {
        ...state,
        ...payload,
      };
    default:
      throw new Error('Invalid action type was given in TournamentForm');
  }
};

const handleStageIntervalsCalculations = (stageNumber, currentIntervals) => {
  if (stageNumber >= 2) {
    let availableStages = [...STAGES_BY_ORDER].slice(0, stageNumber);
    let newVariousIntervals = [];
    currentIntervals.forEach((interval, intervalIdx) => {
      newVariousIntervals[intervalIdx] = [];
      interval.forEach((stage) => {
        if (availableStages.includes(stage)) {
          newVariousIntervals[intervalIdx].push(stage);
          availableStages = availableStages.filter((elem) => elem !== stage);
        }
      });
    });

    newVariousIntervals = newVariousIntervals.filter((elem) => !!elem?.length);

    if (Array.isArray(last(newVariousIntervals))) {
      newVariousIntervals[newVariousIntervals?.length - 1] = [
        ...last(newVariousIntervals),
        ...availableStages,
      ];
    }

    return { variousIntervals: newVariousIntervals };
  }
};

const TournamentForm = ({ tournament, dataErrors, userId, isEdit, isShow, onSubmit }) => {
  const [state, updateState] = useReducer(
    stateHandler,
    getInitialState({ tournament, isEdit, userId })
  );

  const onEditorStateChange = (editorState, setFieldValue, setFieldError) => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const rawText = rawContentState.blocks
      .map((block) => (!block.text.trim() && '\n') || block.text)
      .join('\n');

    //we validate here, before transforming input to html
    if (rawText.trim()?.length === 0) {
      setFieldValue('description', '');
      setFieldError('description', 'Description required!');
    } else if (rawText?.length > MAX_DESCRIPTION_LENGTH) {
      setFieldError('description', `Must be max ${MAX_DESCRIPTION_LENGTH} characters long!`);
    } else {
      const markup = draftToHtml(rawContentState, hashConfig);
      setFieldValue('description', markup);
      setFieldError('description', '');
    }

    updateState({
      type: 'partialUpdate',
      payload: { editorState: editorState },
    });
  };

  // handle prize category changes
  const handleMaxParticipantsChange = (e, setFieldValue) => {
    setFieldValue && setFieldValue(e.target.name, e.target.value);
    if (e.target.value <= 256) {
      //get the highestPowerOf2
      const lowerPow = Math.log(highestPowerof2(e.target.value)) / Math.log(2);
      const indexes = Math.pow(2, lowerPow) < e.target.value ? lowerPow + 2 : lowerPow + 1;
      const prizeCategoryCounter = minMaxFilter(indexes, 1, 9);

      //handle variable configuration stages
      const newIntervals = handleStageIntervalsCalculations(
        prizeCategoryCounter - 1,
        state.variousIntervals
      );
      updateState({ type: 'partialUpdate', payload: { prizeCategoryCounter, ...newIntervals } });
    }
  };

  const isEditable = () => {
    if (isShow) return false;

    if (!isEdit) return true;

    return (
      isEdit &&
      tournament &&
      [
        TournamentState.Announced,
        TournamentState.RegistrationOpen,
        TournamentState.RegistrationClosed,
        TournamentState.RegistrationFull,
      ].includes(tournament?.state)
    );
  };

  const handleQuantityChange = (e, setFieldValue) => {
    if (e.target.value > 0) setFieldValue('multipleTournamentQuantity', e.target.value);
  };

  const handleSubmit = (values) => {
    const { prizeCategoryCounter } = state;

    const variousGameConfig = {};
    if (state.variousIntervals) {
      state.variousIntervals.forEach((interval) => {
        interval.forEach((stage) => {
          variousGameConfig[stage] = values.variousGameConfig[interval[0]];
        });
      });
    }

    const formData = {
      name: values.name,
      description: values.description,
      registrationStartDate: values.registrationStartDate,
      registrationEndDate: values.registrationEndDate,
      tournamentStartDate: values.tournamentStartDate,
      maxParticipants: parseInt(values.maxParticipants),
      minParticipants: parseInt(values.minParticipants),
      gameConfig: {
        startScore: values.gameConfig.startScore,
        scoreType: values.gameConfig.scoreType,
        sets: parseInt(values.gameConfig.sets),
        legs: parseInt(values.gameConfig.legs),
        gameInType: values.gameConfig.gameInType,
        gameOutType: values.gameConfig.gameOutType,
        firstToThrow: values.gameConfig.firstToThrow,
        startTimer: parseInt(values.gameConfig.startTimer),
        finishTimer: parseInt(values.gameConfig.finishTimer),
        inactivityTimer: parseInt(values.gameConfig.inactivityTimer),
        __type: values.gameType,
      },
      variousGameConfig: transformVariousGameConfig(variousGameConfig),
      constraints: values.constraints,
      organiser: values.organiser,
      structure: values.structure,
      participationType: values.participationType,
      entryFee: values.entryFee,
      isScoreCorrectionAllowed:
        values.isScoreCorrectionAllowed === 'true' || values.isScoreCorrectionAllowed === true,
      registrationType: values.registrationType,
      configType: values.configType,
      gameType: values.gameType,
      penalty: values.penalty ?? 0,
    };

    const stagePrizes = {};
    for (let i = 0; i < prizeCategoryCounter; i++) {
      //save only the categories we need
      stagePrizes[STAGE_PRIZES[i].key] = parseInt(values.stagePrizes[STAGE_PRIZES[i].key]);

      if (i + 1 === prizeCategoryCounter) {
        formData.stagePrizes = stagePrizes;
      }
    }

    //clean up not used attributes
    if (!values.gameConfig.startTimer) delete formData.gameConfig.startTimer;

    if (!values.gameConfig.finishTimer) delete formData.gameConfig.finishTimer;

    if (!values.gameConfig.inactivityTimer) delete formData.gameConfig.inactivityTimer;

    if (!values.gameConfig.isScoreCorrectionAllowed)
      delete formData.gameConfig.isScoreCorrectionAllowed;

    if (values.configType === 'Fixed') {
      delete formData.variousGameConfig;
    } else {
      delete formData.gameConfig;
      const availableStages = STAGES_BY_ORDER.slice(0, prizeCategoryCounter - 1);
      Object.keys(formData.variousGameConfig).forEach((stage) => {
        if (!availableStages.includes(stage)) {
          delete formData.variousGameConfig[stage];
        }
      });
    }

    //send form data
    if (isEdit) {
      onSubmit({
        ...formData,
      });
    } else {
      onSubmit({
        tournament: formData,
        meta: {
          frequency:
            values.isMultiple && values.frequency ? values.frequency : FREQUENCY_OPTIONS[0].value,
          multipleTournamentQuantity:
            values.isMultiple && parseInt(values.multipleTournamentQuantity)
              ? parseInt(values.multipleTournamentQuantity)
              : 1,
        },
      });
    }
  };

  const handleSelectVariousInterval = (selected, interval) => {
    const a = [];
    const b = [];
    let found = false;

    interval.forEach((stage) => {
      if (TournamentStageLabels[stage] === selected) found = true;

      if (!found) {
        a.push(stage);
      } else {
        b.push(stage);
      }
    });

    const newIntervals = [];
    state.variousIntervals.forEach((iteratedInterval) => {
      if (iteratedInterval[0] === interval[0]) {
        newIntervals.push(a);
        newIntervals.push(b);
      } else {
        newIntervals.push(iteratedInterval);
      }
    });

    updateState({
      type: 'partialUpdate',
      payload: { variousIntervals: newIntervals },
    });
  };

  const handleDeleteInterval = (intervalIdx, setFieldValue, variousGameConfig) => {
    const newConfig = variousGameConfig[state.variousIntervals[intervalIdx - 1][0]];

    //override the deleted interval's stage configs
    state.variousIntervals[intervalIdx].forEach((stage) => {
      setFieldValue(`variousGameConfig.${stage}`, newConfig);
    });

    //and then handle the intervals themselves
    const newIntervals = [];
    state.variousIntervals.forEach((elem, idx) => {
      if (idx === intervalIdx) {
        newIntervals[idx - 1] = [...newIntervals[idx - 1], ...state.variousIntervals[intervalIdx]];
      } else if (idx !== intervalIdx) {
        newIntervals.push(elem);
      }
    });

    updateState({
      type: 'partialUpdate',
      payload: { variousIntervals: newIntervals },
    });
  };

  const handleChangeConfigType = (value, setFieldValue, gameConfig, variousGameConfig) => {
    if (value === 'Fixed') {
      setFieldValue('gameConfig', variousGameConfig.final);
    }

    if (value === 'Various') {
      STAGES_BY_ORDER.forEach((stageKey) => {
        setFieldValue(`variousGameConfig.${stageKey}`, gameConfig);
      });
      const newIntervals = handleStageIntervalsCalculations(
        state.prizeCategoryCounter - 1,
        state.variousIntervals
      );
      updateState({ type: 'partialUpdate', payload: { ...newIntervals } });
    }

    setFieldValue('configType', value);
  };

  return (
    <div>
      <Formik
        initialValues={state.form}
        validationSchema={tournamentFormValidationObject}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          values,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldError,
        }) => (
          <form onSubmit={handleSubmit}>
            <dl className="row">
              <div className={styles.basicInput}>
                <dt className="col-sm-3">Name:</dt>
                <dd className="col-sm-9">
                  <Input.Text
                    error={touched.name && errors.name}
                    name="name"
                    type="text"
                    value={values.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={isShow}
                  />
                </dd>
              </div>
            </dl>
            <dl className="row">
              <div className={styles.basicInput}>
                <dt className="col-sm-3">Description:</dt>
                <dd className="col-sm-9">
                  {isShow ? (
                    <div dangerouslySetInnerHTML={{ __html: values.description }} />
                  ) : (
                    <Editor
                      editorState={state.editorState}
                      wrapperClassName="demo-wrapper"
                      editorClassName={styles.richTextField}
                      onEditorStateChange={(e) =>
                        onEditorStateChange(e, setFieldValue, setFieldError)
                      }
                      onChange={handleChange}
                      stripPastedStyles={false}
                      handlePastedText={() => false}
                      spellCheck
                      toolbar={{
                        options: [
                          'inline',
                          'fontSize',
                          'fontFamily',
                          'textAlign',
                          'list',
                          'link',
                          'colorPicker',
                          'emoji',
                          'remove',
                          'history',
                        ],
                        inline: {
                          options: [
                            'bold',
                            'italic',
                            'underline',
                            'strikethrough',
                            'superscript',
                            'subscript',
                          ],
                        },
                        fontSize: {
                          options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                        },
                        fontFamily: {
                          options: [
                            'Arial',
                            'Georgia',
                            'Impact',
                            'Tahoma',
                            'Times New Roman',
                            'Verdana',
                          ],
                        },
                        list: {
                          inDropdown: false,
                          options: ['unordered', 'ordered', 'indent', 'outdent'],
                        },
                        textAlign: {
                          inDropdown: false,
                          options: ['left', 'center', 'right', 'justify'],
                        },
                        colorPicker: {
                          popupClassName: styles.colorPickerPopUp,
                          colors: COLOR_OPTIONS,
                        },
                        link: {
                          inDropdown: false,
                          showOpenOptionOnHover: true,
                          defaultTargetOption: '_self',
                          options: ['link', 'unlink'],
                        },
                      }}
                      placeholder={'Enter the description'}
                    />
                  )}
                  {errors.description && (
                    <div className={styles.mockedErrorMsg}>
                      <i className="fa fa-exclamation-triangle" />
                      {`${errors.description}`}
                    </div>
                  )}
                </dd>
              </div>
            </dl>
            {!isShow && !isEdit && (
              <dl className="row">
                <div className={styles.basicInput}>
                  <dt className="col-sm-3">Create multiple tournaments</dt>
                  <dd className="col-sm-9">
                    <input
                      checked={values.isMultiple}
                      // eslint-disable-next-line react/no-unknown-property
                      error={touched.isMultiple && errors.isMultiple}
                      name="isMultiple"
                      type="checkbox"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={isShow || isEdit}
                    />
                    {touched.isMultiple && errors.isMultiple && (
                      <FormFeedback>{errors.isMultiple}</FormFeedback>
                    )}
                  </dd>
                </div>
              </dl>
            )}
            {values.isMultiple && (
              <>
                <dl className="row">
                  <div className={styles.basicInput}>
                    <dt className="col-sm-3">Frequency:</dt>
                    <dd className="col-sm-3">
                      <InputSelect
                        name="frequency"
                        value={values.frequency}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        options={FREQUENCY_OPTIONS}
                        disabled={isShow || isEdit}
                      />
                    </dd>
                    <dt className="col-sm-3">Number of the tournaments to create:</dt>
                    <dd className="col-sm-3">
                      <Input.Number
                        name="multipleTournamentQuantity"
                        value={values.multipleTournamentQuantity}
                        onBlur={handleBlur}
                        onChange={(e) => handleQuantityChange(e, setFieldValue)}
                        disabled={!values.isMultiple || isEdit || isShow}
                        error={
                          touched.multipleTournamentQuantity && errors.multipleTournamentQuantity
                        }
                      />
                    </dd>
                  </div>
                </dl>
              </>
            )}
            <dl className="row">
              <dt className="col-sm-3">Registration start date</dt>
              <dd className="col-sm-9">
                <div>
                  <DatePicker
                    selected={values.registrationStartDate}
                    showTimeSelect
                    timeFormat="HH:mm"
                    dateFormat="yyyy.MM.dd HH:mm"
                    placeholderText="yyyy. mm. dd."
                    onChange={(date) => setFieldValue('registrationStartDate', date)}
                    timeIntervals={5}
                    className="form-control"
                    onBlur={handleBlur}
                    name="registrationStartDate"
                    disabled={isShow || isEdit}
                  />
                </div>
                {errors.registrationStartDate && (
                  <div className={styles.mockedErrorMsg}>
                    <i className="fa fa-exclamation-triangle" />
                    {` ${errors.registrationStartDate}`}
                  </div>
                )}
              </dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-3">Registration end date</dt>
              <dd className="col-sm-9">
                <div>
                  <DatePicker
                    selected={values.registrationEndDate}
                    showTimeSelect
                    timeFormat="HH:mm"
                    dateFormat="yyyy.MM.dd HH:mm"
                    placeholderText="yyyy. mm. dd."
                    onChange={(date) => setFieldValue('registrationEndDate', date)}
                    timeIntervals={5}
                    className="form-control"
                    onBlur={handleBlur}
                    error={touched.registrationEndDate && errors.registrationEndDate}
                    name="registrationEndDate"
                    disabled={isShow || isEdit}
                  />
                </div>
                {errors.registrationEndDate && (
                  <div className={styles.mockedErrorMsg}>
                    <i className="fa fa-exclamation-triangle" />
                    {` ${errors.registrationEndDate}`}
                  </div>
                )}
              </dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-3">Tournament start date</dt>
              <dd className="col-sm-9">
                <div>
                  <DatePicker
                    selected={values.tournamentStartDate}
                    showTimeSelect
                    timeFormat="HH:mm"
                    dateFormat="yyyy.MM.dd HH:mm"
                    placeholderText="yyyy. mm. dd."
                    onChange={(date) => setFieldValue('tournamentStartDate', date)}
                    timeIntervals={5}
                    className="form-control"
                    onBlur={handleBlur}
                    error={touched.tournamentStartDate && errors.tournamentStartDate}
                    name="tournamentStartDate"
                    disabled={isShow || isEdit}
                  />
                </div>
                {errors.tournamentStartDate && (
                  <div className={styles.mockedErrorMsg}>
                    <i className="fa fa-exclamation-triangle" />
                    {` ${errors.tournamentStartDate}`}
                  </div>
                )}
              </dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-3">Recommended averages</dt>
              <dd className="col-sm-3">
                <InputSelect
                  name="averages"
                  value={state.recAverages.value}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    const element = AVG_OPTIONS.find((element) => element.value === e.target.value);
                    updateState({
                      type: 'partialUpdate',
                      payload: { recAverages: element },
                    });

                    const averages = e.target.value.split('-');
                    setFieldValue('constraints.recommended.averageRange.min', averages[0]);
                    setFieldValue('constraints.recommended.averageRange.max', averages[1]);
                  }}
                  options={AVG_OPTIONS}
                  disabled={isShow || isEdit}
                />
                {errors?.constraints?.recommended?.averageRange?.min && (
                  <div className={styles.mockedErrorMsg}>
                    <i className="fa fa-exclamation-triangle" />
                    {` ${errors?.constraints?.recommended?.averageRange?.min}`}
                  </div>
                )}
                {errors?.constraints?.recommended?.averageRange?.max && (
                  <div className={styles.mockedErrorMsg}>
                    <i className="fa fa-exclamation-triangle" />
                    {` ${errors?.constraints?.recommended?.averageRange?.max}`}
                  </div>
                )}
              </dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-3">Number of min. participants</dt>
              <dd className="col-sm-3">
                <Input.Number
                  name="minParticipants"
                  error={touched.minParticipants && errors.minParticipants}
                  value={values.minParticipants}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  min={4}
                  max={values.maxParticipants || 256}
                  disabled={isShow || isEdit}
                />
              </dd>
              <dt className="col-sm-3">Number of max. participants</dt>
              <dd className="col-sm-3">
                <Input.Number
                  name="maxParticipants"
                  error={touched.maxParticipants && errors.maxParticipants}
                  value={values.maxParticipants}
                  onChange={(e) => handleMaxParticipantsChange(e, setFieldValue)}
                  onBlur={handleBlur}
                  min={values.minParticipants || 4}
                  max={256}
                  disabled={isShow || isEdit}
                />
              </dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-3">Penalty points:</dt>
              <dd className="col-sm-3">
                <Input.Number
                  name="penalty"
                  error={touched.penalty && errors.penalty}
                  value={values.penalty}
                  onChange={(e) => setFieldValue('penalty', parseInt(parseFloat(e.target.value)))}
                  onBlur={handleBlur}
                  min={0}
                  disabled={isShow || isEdit}
                />
              </dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-12">Prize pool:</dt>
            </dl>
            {[...Array(state.prizeCategoryCounter)].map((_x, i) => (
              <dl className="row" key={i}>
                <div className={styles.basicInput}>
                  <dt className="col-sm-1" />
                  <dt className="col-sm-2">{STAGE_PRIZES[i].key}</dt>
                  <dd className="col-sm-3">
                    <Input.Number
                      name={`stagePrizes.${[STAGE_PRIZES[i].key]}`}
                      value={values?.stagePrizes ? values?.stagePrizes[STAGE_PRIZES[i].key] : ''}
                      error={
                        touched?.stagePrizes &&
                        touched?.stagePrizes[STAGE_PRIZES[i].key] &&
                        errors?.stagePrizes &&
                        errors?.stagePrizes[STAGE_PRIZES[i].key]
                      }
                      onBlur={handleBlur}
                      onChange={(e) =>
                        setFieldValue(`stagePrizes.${[STAGE_PRIZES[i].key]}`, e.target.value)
                      }
                      disabled={isShow || !isEditable()}
                      min={0}
                    />
                  </dd>
                </div>
              </dl>
            ))}
            {errors?.stagePrizes && (
              <div className={styles.mockedErrorMsg}>
                <i className="fa fa-exclamation-triangle" />
                {` ${errors?.stagePrizes}`}
              </div>
            )}
            <dl className="row">
              <div className={styles.basicInput}>
                <dt className="col-sm-3">Organiser Type:</dt>
                <dd className="col-sm-3">
                  <Input.Text
                    error={touched?.organiserType && errors?.organiserType}
                    name="organiserType"
                    type="text"
                    value={values.organiserType}
                    onBlur={handleBlur}
                    onChange={(e) => setFieldValue('organiserType', e.target.value)}
                    disabled
                  />
                </dd>
                <dt className="col-sm-3">Structure:</dt>
                <dd className="col-sm-3">
                  <Input.Text
                    error={touched.structure && errors.structure}
                    name="structure"
                    type="text"
                    value={values.structure}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled
                  />
                </dd>
              </div>
            </dl>
            <dl className="row">
              <div className={styles.basicInput}>
                <dt className="col-sm-3">Participation Type:</dt>
                <dd className="col-sm-3">
                  <Input.Text
                    error={touched.participationType && errors.participationType}
                    name="participationType"
                    type="text"
                    value={values.participationType}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled
                  />
                </dd>
                <dt className="col-sm-3">Entry fee:</dt>
                <dd className="col-sm-3">
                  <Input.Text
                    error={touched.entryFee && errors.entryFee}
                    name="entryFee"
                    type="text"
                    value={values.entryFee}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled
                  />
                </dd>
              </div>
            </dl>
            <dl className="row">
              <div className={styles.basicInput}>
                <dt className="col-sm-3">Score correction:</dt>
                <dd className="col-sm-3">
                  <InputSelect
                    error={touched.isScoreCorrectionAllowed && errors.isScoreCorrectionAllowed}
                    name="isScoreCorrectionAllowed"
                    type="text"
                    value={values.isScoreCorrectionAllowed}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    options={SCORE_CORRECTION}
                    disabled={isShow || !isEditable()}
                  />
                </dd>
                <dt className="col-sm-3">Registration Type:</dt>
                <dd className="col-sm-3">
                  <Input.Text
                    error={touched.registrationType && errors.registrationType}
                    name="registrationType"
                    type="text"
                    value={values.registrationType}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled
                  />
                </dd>
              </div>
            </dl>
            <dl className="row">
              <div className={styles.basicInput}>
                <dt className="col-sm-3">Configuration type:</dt>
                <dd className="col-sm-3">
                  <InputSelect
                    error={touched.configType && errors.configType}
                    name="configType"
                    type="text"
                    value={values.configType}
                    onBlur={handleBlur}
                    onChange={(e) =>
                      handleChangeConfigType(
                        e.target.value,
                        setFieldValue,
                        values.gameConfig,
                        values.variousGameConfig
                      )
                    }
                    options={CONFIG_TYPES}
                    disabled={isShow || !isEditable()}
                  />
                </dd>
                <dt className="col-sm-3">Selected Game Type:</dt>
                <dd className="col-sm-3">
                  <Input.Text
                    error={touched.gameType && errors.gameType}
                    name="gameType"
                    type="text"
                    value={values.gameType}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled
                  />
                </dd>
              </div>
            </dl>
            {errors?.configType && (
              <div className={styles.mockedErrorMsg}>
                <i className="fa fa-exclamation-triangle" />
                {` ${errors?.configType}`}
              </div>
            )}
            {values.configType === 'Fixed' && (
              <GameConfiguration
                configuration={values.gameConfig}
                configurationPath="gameConfig"
                handleBlur={handleBlur}
                handleChange={handleChange}
                disabled={isShow || !isEditable()}
                errors={errors?.gameConfig}
              />
            )}
            {values.configType === 'Various' &&
              state.variousIntervals
                .slice()
                .reverse()
                .map((interval, idx) => (
                  <Fragment key={`${interval[0]}_${idx}`}>
                    <div className={styles.configIntervaleSelector}>
                      {interval?.length > 1 ? <span>To</span> : null}
                      <InputSelect
                        name={`intervalSelector_${idx}`}
                        type="text"
                        value={interval[0]}
                        onChange={(e) => handleSelectVariousInterval(e.target.value, interval)}
                        options={TOURNAMENT_STAGES.filter((elem) => interval.includes(elem.key))}
                        disabled={isShow || !isEditable()}
                      />
                      {interval[0] !== STAGES_BY_ORDER[0] && (
                        <div className={styles.intervalDeleteContainer}>
                          <Button
                            color="danger"
                            onClick={() =>
                              handleDeleteInterval(
                                state.variousIntervals.indexOf(interval),
                                setFieldValue,
                                values.variousGameConfig
                              )
                            }
                            disabled={isShow || !isEditable()}
                          >
                            <i className="cui-trash" />
                          </Button>
                        </div>
                      )}
                    </div>
                    <GameConfiguration
                      configuration={values.variousGameConfig?.[interval[0]]}
                      configurationPath={`variousGameConfig.${[interval[0]]}`}
                      handleBlur={handleBlur}
                      handleChange={(e) => {
                        interval.forEach((stage) => {
                          setFieldValue(
                            `variousGameConfig.${stage}.${last(e.target.name.split('.'))}`,
                            e.target.value
                          );
                        });
                      }}
                      disabled={isShow || !isEditable()}
                      errors={errors?.variousGameConfig?.[interval[0]]}
                    />
                  </Fragment>
                ))}
            {!isShow && (
              <dl className="row">
                <Button type="submit" color="success">
                  Save
                </Button>
              </dl>
            )}
            {dataErrors?.length > 0 &&
              dataErrors.map((err, idx) => (
                <div key={`error_${idx}`} className={styles.mockedErrorMsg}>
                  <i className="fa fa-exclamation-triangle" />
                  {` ${err}`}
                </div>
              ))}
          </form>
        )}
      </Formik>
    </div>
  );
};

TournamentForm.propTypes = {
  tournament: PropTypes.object,
  dataErrors: PropTypes.array,
  isShow: PropTypes.bool,
  isEdit: PropTypes.bool,
  userId: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default TournamentForm;
