import React, { useCallback, useState } from 'react';
import { useLocalStorage } from 'react-use';
import PropTypes from 'prop-types';

import { SLabelContainer, SScrollContainer, SAnimateVariantsEnum, SAnimate } from 'ScoliaComponents';
import { GameConfigurationCard } from './components/GameConfigurationCard';
import { ScoreCorrectionOptions, BullThrowOptions, StartTimerOptions, FinishTimerOptions, PerVisitTimerOptions } from './constants';
import { HeaderTitle } from '../../HeaderTitle';
import { deepClone } from 'utils';

import { TransitionGroup } from 'react-transition-group';
import { ThemedModal } from 'components/ThemedModal';
import { SKIP_DELETE_STAGE_MODAL } from 'constants/StoreKeys';
import { SRadioGroup } from 'ScoliaComponents/SRadioGroup';
import { WizardPages } from 'components/TournamentWizard/constants';
import { ThemesEnum } from 'enums';

import styles from './styles.module.scss';

const ConfirmStageDeleteModal = ({isOpen, onAccept, toggleModal}) => {
  const [ skipDeleteStage, setSkipDeleteStage ] = useLocalStorage(SKIP_DELETE_STAGE_MODAL, false);

  return (
    <ThemedModal 
      title="Delete stage"
      customAccept="Yes, delete"
      isOpen={isOpen}
      onAccept={onAccept}
      onDismiss={toggleModal}
      acceptType="warning"
      storageKey={SKIP_DELETE_STAGE_MODAL}
      skipConfirmation={skipDeleteStage}
      onSkipConfirmation={() => setSkipDeleteStage(!skipDeleteStage)}
      theme={ThemesEnum.Astro}
      modalContentClass={styles.deleteModalContent}
    >
      <span>{"Are you sure you want to delete this stage? It will affect the rounds that belong to other stages."}</span>
    </ThemedModal>
  )
}

ConfirmStageDeleteModal.propTypes = {
  isOpen: PropTypes.bool,
  onAccept: PropTypes.func,
  toggleModal: PropTypes.func,
}
const GameConfiguration = ({ title, onBack, values, errors, touched, onBlur, onSetFieldValue, isEditingDisabled }) => {

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [stageIndexToDelete, setStageIndexToDelete] = useState(0);

  const gameConfigs = values.variousGameConfig;

  const toggleModal = () => {
    setIsDeleteModalOpen((prevValue)=>!prevValue);
  }

  const getGameConfigurationError = (t = {}, e = {}) => {
    const errorKey = Object.keys(t).find((key) => (t[key] && e[key]))

    if ( errorKey ) {
      return e[errorKey]
    }

    return null
  }

  const error = getGameConfigurationError(touched.gameConfig, errors.gameConfig)

  const handleSelection = (value, name) => {
    onSetFieldValue && onSetFieldValue(name, value)
  }

  const onToStageClick = (selectedStage) => {
    const newConfigs = [];
    const newGameConfig = deepClone(gameConfigs);

    for (let i = 0; i < newGameConfig.length; i++) {
      const config = newGameConfig[i];
      const index = config.stages.indexOf(selectedStage);

      if (index !== -1) {
          const newStages = config.stages.slice(0, index + 1);
          const newConfig = {...config, stages: newStages};
          newConfigs.push(newConfig);
          const remainingStages = config.stages.slice(index + 1);

          if (remainingStages.length > 0) {
              const remainingConfig = {...config, stages: remainingStages};
              newConfigs.push(remainingConfig);
          }
      } else {
          newConfigs.push(config);
      }
    }

    newGameConfig.splice(0, newGameConfig.length, ...newConfigs);

    onSetFieldValue('variousGameConfig', newGameConfig);
  }

  const onRemoveStage = useCallback((index) => () => {
    const newGameConfig = deepClone(gameConfigs);
    const indexToDelete = index ?? stageIndexToDelete;
    
    if (indexToDelete > 0 && indexToDelete < newGameConfig.length) {
      const mergedStages = newGameConfig[indexToDelete - 1].stages.concat(newGameConfig[indexToDelete].stages);
      newGameConfig[indexToDelete - 1] = {...newGameConfig[indexToDelete-1], stages: mergedStages};
      newGameConfig.splice(indexToDelete, 1);
    }

    setIsDeleteModalOpen(false);
    onSetFieldValue('variousGameConfig', newGameConfig);
  }, [gameConfigs, stageIndexToDelete, onSetFieldValue])

  const onRemoveStageClick = useCallback((index) => (event) => {
    event.preventDefault();

    const shouldSkipDelete = localStorage.getItem(SKIP_DELETE_STAGE_MODAL);
    setStageIndexToDelete(index);

    if (JSON.parse(shouldSkipDelete)) {
      onRemoveStage(index)(event);
      return;
    }

    setIsDeleteModalOpen(true);
  }, [onRemoveStage]);

  return (
    <SScrollContainer>
      <ConfirmStageDeleteModal isOpen={isDeleteModalOpen} onAccept={onRemoveStage()} toggleModal={toggleModal}/>

      <HeaderTitle title={title} onBack={onBack} />
      <div className={styles.gameConfigurationInnerWrapper}>
        <div className={styles.fixGameConfig}>
          <SLabelContainer label="Game setup per stages" name="gameSetup" error={error}>
          <TransitionGroup>
            {gameConfigs.map((game, index)=>(
              <SAnimate variant={SAnimateVariantsEnum.SlideInBottom} mountOnEnter key={game.stages[0]} className={styles.gameOption}>
                <GameConfigurationCard
                  values={values}
                  gameConfig={game}
                  onSelect={handleSelection}
                  onBlur={onBlur}
                  idx={index}
                  from={game.stages[0]}
                  to={game.stages[game.stages.length - 1]}
                  onToStageClick={onToStageClick}
                  onRemoveStageClick={onRemoveStageClick}
                  errors={errors}
                  touched={touched}
                  disabled={isEditingDisabled}
                />
              </SAnimate>
              ))}
            </TransitionGroup>
          </SLabelContainer>
        </div>

        <div className={styles.globalGameConfiguration}>
          <SRadioGroup
            name="globalStartTimer"
            label="Start timer"
            options={StartTimerOptions}
            tooltip="The amount of time participants have to start their scheduled games."
            value={values.globalStartTimer}
            onSelect={handleSelection}
            disabled={isEditingDisabled}
          />
        </div>
        <div className={styles.globalGameConfiguration}>
          <SRadioGroup
            name="globalFinishTimer"
            label="Finish timer per player"
            options={FinishTimerOptions}
            tooltip="The amount of time participants have to finish their games."
            value={values.globalFinishTimer}
            onSelect={handleSelection}
            disabled={isEditingDisabled}
          />
        </div>
        <div className={styles.globalGameConfiguration}>
          <SRadioGroup
            name="globalInactivityTimer"
            label="Per-visit timer"
            options={PerVisitTimerOptions}
            tooltip="The amount of time participants have to finish each visit."
            value={values.globalInactivityTimer}
            onSelect={handleSelection}
            disabled={isEditingDisabled}
          />
        </div>
        <div className={styles.globalGameConfiguration}>
          <SRadioGroup
            name="isScoreCorrectionAllowed"
            label="Score correction"
            options={ScoreCorrectionOptions}
            value={values.isScoreCorrectionAllowed}
            onSelect={handleSelection}
            disabled={isEditingDisabled}
          />
        </div>
        <div className={styles.globalGameConfiguration}>
          <SRadioGroup
            name="bullThrow"
            label="Throw for bull"
            options={BullThrowOptions}
            value={values.bullThrow}
            onSelect={handleSelection}
            disabled={isEditingDisabled}
          />
        </div>
      </div>
    </SScrollContainer>
  )
};

GameConfiguration.displayName = 'GameConfiguration';

GameConfiguration.propTypes = {
  title: PropTypes.oneOf(Object.values(WizardPages)),
  onBack: PropTypes.func,
  //values: tournamentPropTypes,
  errors: PropTypes.object,
  touched: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onSetFieldValue: PropTypes.func.isRequired,
};

export default GameConfiguration;
