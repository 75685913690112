import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';

import { debounce } from 'utils';
import { getIsAdmin } from 'services/session/selectors';

import { actions as boardActions } from 'scenes/Boards/components/List';
import { getServiceAccount } from 'scenes/ServiceAccounts/components/Show/actions';
import { ServiceAccountForm } from 'scenes/ServiceAccounts/components/ServiceAccountForm';

import actions from './actions';

class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterValue: '',
    };
  }
  
  handleFormSubmit = values => {
    this.props.dispatch(
      actions.updateServiceAccount({
        serviceAccountId: this.serviceAccountId,
        serviceAccount: values,
      })
    );
  };

  componentDidMount() {
    const { serviceAccount, dispatch } = this.props;

    if (!serviceAccount || serviceAccount.__isStale) {
      dispatch(getServiceAccount(this.serviceAccountId));
    }
    dispatch(boardActions.getBoards());
  }

  get serviceAccountId() {
    return this.props.match.params.serviceAccountId;
  }

  handleSerialNumberInputChange = e => {
    this.setState({ filterValue: e });
    e && this.props.dispatch(boardActions.getBoards({ filter: [{ id: 'serialNumber', value: e }] }));
  };
  debouncedHandleSerialInputChange = debounce(this.handleSerialNumberInputChange);

  render() {
    const { formError, boards, serviceAccount, isLoading, isAdmin } = this.props;

    if (!serviceAccount || !boards || isLoading) {
      return <i className="fa fa-spinner fa-spin fa-fw" />;
    }

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" lg={{ size: 10, offset: 1 }}>
            <Card color="white">
              <CardHeader>
                <div className="card-header__left">
                  <i className="fa fa-align-justify" /> Edit Service Account
                </div>
              </CardHeader>
              <CardBody>
                <ServiceAccountForm
                  formError={formError}
                  boards={boards}
                  serviceAccount={serviceAccount}
                  onSubmit={this.handleFormSubmit}
                  onSerialNumberInputChange={this.debouncedHandleSerialInputChange}
                  isEdit
                  isAdmin={isAdmin}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  formError: state.scenes.serviceAccounts.create.createError,
  boards: Object.values(state.data.boards).map(item => {
    return { serialNumber: item.serialNumber, boardId: item.id };
  }),
  serviceAccount: state.data.serviceAccounts[ownProps.match.params.serviceAccountId],
  isAdmin: getIsAdmin(state),
});

export default connect(mapStateToProps)(Edit);

Edit.propTypes = {
  formError: PropTypes.string,
  boards: PropTypes.array.isRequired,
  serviceAccount: PropTypes.object,
  isAdmin: PropTypes.bool,
};
