import React, { Component, Fragment } from 'react';
import { Row, Col, Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { ToggleFilter } from 'scenes/StoreSnapshot/components/StoreTable/components/ToggleFilter';

import styles from './styles.module.scss';

class LobbyParticipant extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  open() {
    this.setState({ isOpen: true });
  }

  close() {
    this.setState({ isOpen: false });
  }

  isOpened() {
    return this.state.isOpen;
  }

  render() {
    const {
      user: { entityUrl: userEntityUrl, nickname },
      clientConnections,
      availability,
      sbc: { entityUrl: sbcEntityUrl, name: sbcName },
    } = this.props;
    return (
      <Fragment>
        <Row>
          <div className={styles.wrapper}>
            <Col xs={3}>
              <Link to={userEntityUrl}>{nickname}</Link>
            </Col>
            <Col xs={3}>
              <div
                className={cn(styles.availability, {
                  [styles.online]: availability === 'Online',
                  [styles.inGame]: availability === 'InGame',
                })}
              >
                {availability}
              </div>
            </Col>
            <Col xs={6} className={styles.sbc}>
              <Link to={sbcEntityUrl}>{sbcName}</Link>
            </Col>

            {clientConnections.length > 0 && (
              <i
                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                className={cn(
                  styles.toggleIcon,
                  `fa fa-angle-${this.state.isOpen ? 'up' : 'down'}`
                )}
              />
            )}
          </div>
        </Row>

        <div
          className={cn([
            styles.border,
            { [styles.borderActive]: this.state.isOpen },
          ])}
        />
        <Collapse
          isOpen={this.state.isOpen}
          onEntering={this.props.onChange}
          onExiting={this.props.onChange}
        >
          {clientConnections.length > 0 && (
            <div className={styles.collapse}>
              <ToggleFilter
                readonly
                label="Client Connections"
                count={clientConnections.length}
              />
              <ul className={styles.wsList}>
                {clientConnections.map(wsId => <li key={wsId}>{wsId}</li>)}
              </ul>
            </div>
          )}
        </Collapse>
      </Fragment>
    );
  }
}

LobbyParticipant.propTypes = {
  user: PropTypes.object.isRequired,
  clientConnections: PropTypes.array.isRequired,
  sbc: PropTypes.object.isRequired,
  availability: PropTypes.string.isRequired,
};

export default LobbyParticipant;
