const GAME_TYPE_LABEL = {
  RaceTo: 'Race to',
  BestOf: 'Best of',
};

const GAME_OUT_TYPE_LABEL = {
  Double: 'D-Out',
  Straight: 'S-Out',
  Master: 'M-Out',
};

const GAME_IN_TYPE_LABEL = {
  Double: 'D-In',
  Straight: 'S-In',
};

export const getConfiguration = (config,showFirstToThrow=false) => {
  if( config?.Final ) return 'Variable configuration'

  return (
    `${
      showFirstToThrow ? (config?.firstToThrow || config?.bullThrow ? `${config?.firstToThrow ? config?.firstToThrow : config?.bullThrow} • ` : '-') : ''
    }${
      config?.startScore
    } • ${
      GAME_TYPE_LABEL[config?.scoreType]
    } ${
      parseInt(config?.sets) > 1 ? `${config?.sets} sets, ` : ''}${config?.legs} ${parseInt(config?.legs) > 1 ? 'legs' : 'leg'
    } • ${
      GAME_OUT_TYPE_LABEL[config?.gameOutType]
    }${
      config?.gameInType === 'Double' ? `• ${GAME_IN_TYPE_LABEL[config?.gameInType]}` : ''
    }`
  )
}

export default getConfiguration