import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Card, CardHeader, CardBody } from 'reactstrap'

import { TournamentForm } from '../TournamentForm';

import { getTournament } from '../Show/actions';
import actions from './actions'

const Edit = ({ match }) => {
  const dispatch = useDispatch();

  const tournament = useSelector(state => state.scenes.tournaments.show.tournament);
  const userId = useSelector(state => state.session._id);

  const [isReady, setReady] = useState(false);

  useEffect(() => {
    if(match?.params?.tournamentId && Object.keys(tournament)?.length === 0){
      const tournamentId = match?.params?.tournamentId;
      dispatch(getTournament(tournamentId));
    } else {
      setReady(true)
    }
  }, [ dispatch, tournament, match ])

  const handleSubmitEdit = data => {
    const tournamentId = match.params.tournamentId;

    data.organiser.organiserId = userId;

    dispatch(actions.editTournament({data, tournamentId}))
  }

  return (
    <div className="animated fadeIn">
      <Card color="white">
        <CardHeader>
          <div className="card-header__left">
            <i className="fa fa-align-justify" /> Tournament edit
          </div>
        </CardHeader>
        <CardBody>
          {isReady && <TournamentForm
            onSubmit={handleSubmitEdit}
            tournament={Object.keys(tournament)?.length > 0 ? tournament : null}
            userId={userId}
            isEdit
          />}
        </CardBody>
      </Card>
    </div>
  );
}

Edit.displayName = 'Tournaments.Edit';

export default Edit;
