import { call, put, takeLatest } from 'redux-saga/effects';

import { Hardwares } from 'services/api';
import { callApi } from 'services/rest';
import { browserHistory } from 'services/history';

import {
  getHardware,
  getHardwareFailure,
  getHardwareSuccess,
} from 'scenes/Hardwares/actions';

function* watchGetHardware({ payload: { hardwareId } }) {
  try {
    const { data } = yield call(callApi, Hardwares.show, hardwareId);
    yield put(getHardwareSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(getHardwareFailure());
    browserHistory.push('/hardwares/');
  }
}

// It must be wrapped in a function, so execution will happen only on function call
export default () => takeLatest(getHardware, watchGetHardware);
