import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';

import { Header } from 'components/Header';
import { Sidebar } from 'components/Sidebar';
import { Breadcrumb } from 'components/Breadcrumb';

import { Boards } from 'scenes/Boards';
import { Dashboard } from 'scenes/Dashboard';
import { Feedbacks } from 'scenes/Feedbacks';
import { Firmwares } from 'scenes/Firmwares';
import { Hardwares } from 'scenes/Hardwares';
import { OnlineBoards } from 'scenes/OnlineBoards';
import { RankingList } from 'scenes/RankingList';
import { Rankings } from 'scenes/Rankings';
import { ResourceLogs } from 'scenes/ResourceLogs';
import { SystemUsages } from 'scenes/SystemUsages';
import { Users } from 'scenes/Users';
import { Games } from 'scenes/Games';
import { BoardUpdateProgress } from 'scenes/BoardUpdateProgress';
import { ServiceAccounts } from 'scenes/ServiceAccounts';
import { StoreSnapshot } from 'scenes/StoreSnapshot';
import { Manufacturers } from 'scenes/Manufacturers';
import { Chat } from 'scenes/Chat';
import { Notifications } from 'scenes/Notifications';
import { Configs } from 'scenes/Configs';
import { Tournaments } from 'scenes/Tournaments';
import { PrivateTournaments } from 'scenes/PrivateTournaments';
import { CommercialPartners } from 'scenes/CommercialPartners'
import { Subscriptions } from 'scenes/Subscriptions';
import { Products } from 'scenes/Products';
import { TokenPurchases } from "scenes/TokenPurchases"
import { Platforms } from 'scenes/Platforms';
import { ScoreCorrectionStatistics } from 'scenes/ScoreCorrectionStatistics';

import { actions as wsActions } from 'services/ws';
import { actions as countriesAction } from 'data/Countries';

class MainLayout extends Component {
  componentDidMount() {
    this.props.dispatch(wsActions.initWsConnection());
    this.props.dispatch(countriesAction.getCountries());
  }

  componentWillUnmount() {
    this.props.dispatch(wsActions.closeWsConnection());
  }

  render() {
    return (
      <div className="app">
        <Header />
        <div className="app-body" id="main">
          <Sidebar {...this.props} />
          <main className="main">
            <Breadcrumb />
            <Container fluid>
              <Switch>
                <Route path='/dashboard' name="Dashboard" component={Dashboard}>
                  <Route path='/' component={Dashboard} />
                  <Route path='*' render={() => <Redirect to={'/dashboard'} />} />
                </Route>
                <Route path='/online-boards/' name="Online Boards" component={OnlineBoards}>
                  <Route path='/:id' component={OnlineBoards} />
                  <Route path='*' render={() => <Redirect to={'/online-boards'} />} />
                </Route>
                <Route path='/ranking-list/' name="Ranking List" component={RankingList}>
                  <Route path='/:id' component={RankingList} />
                  <Route path='*' render={() => <Redirect to={'/ranking-list'} />} />
                </Route>
                <Route path='/rankings/' name="Rankings" component={Rankings}>
                  <Route path='/:id' component={Rankings} />
                  <Route path='*' render={() => <Redirect to={'/rankings'} />} />
                </Route>
                <Route path='/board-update-progress/' name="BoardUpdateProgress" component={BoardUpdateProgress}>
                  <Route path='/:id' component={BoardUpdateProgress} />
                  <Route path='*' render={() => <Redirect to={'/board-update-progress'} />} />
                </Route>
                <Route component={Hardwares} name="Hardwares" path='/hardwares/' />
                <Route component={Boards} name="Boards" path="/boards/" />
                <Route component={Firmwares} name="Firmwares" path='/firmwares/' />
                <Route component={Feedbacks} name="Feedbacks" path='/feedbacks/' >
                  <Route path='' component={Feedbacks} />
                  <Route path='*' render={() => <Redirect to={'/feedbacks'} />} />
                </Route>
                <Route component={ServiceAccounts} name="Service Accounts" path='/service-accounts/' />
                <Route path='/commercial-partners/' name="Commercial Partners" component={CommercialPartners}>
                  <Route path='/:id' component={CommercialPartners} />
                  <Route path='*' render={() => <Redirect to={'/commercial-partners'} />} />
                </Route>
                <Route component={Users} name="Users" path="/users/" />
                <Route component={Games} name="Games" path="/games/" />
                <Route path='/resource-logs/' name="Resource Logs" component={ResourceLogs}>
                  <Route path='' component={ResourceLogs} />
                  <Route path='*' render={() => <Redirect to={'/resource-logs'} />} />
                </Route>
                <Route path='/system-usages/' name="System usages" component={SystemUsages}>
                  <Route path='' component={SystemUsages} />
                  <Route path='*' render={() => <Redirect to={'/system-usages'} />} />
                </Route>
                <Route path='/store-snapshot/' name="Store Snapshot" component={StoreSnapshot}>
                  <Route path='' component={StoreSnapshot} />
                  <Route path='*' render={() => <Redirect to={'/store-snapshot'} />} />
                </Route>
                <Route path='/manufacturers/' name="Hybrid SBC-s" component={Manufacturers} />
                <Route component={Chat} name="Chat" path="/chat/" />
                <Route path='/notifications/' name="Notifications" component={Notifications}>
                  <Route path='' component={Notifications} />
                  <Route path='*' render={() => <Redirect to={'/notifications'} />} />
                </Route>
                <Route component={Configs} name="Configs" path="/configs/" />
                <Route component={Tournaments} name="Tournaments" path="/tournaments/" />
                <Route component={PrivateTournaments} name="Tournaments" path="/private-tournaments/" />
                <Route component={Subscriptions} name="Subscriptions" path="/subscriptions/" />
                <Route component={Products} name="Products" path="/products/" />
                <Route component={ScoreCorrectionStatistics} name="Score Correction Statistics" path="/score-correction-statistics" />
                <Route component={TokenPurchases} name="Token purchases" path="/token-purchases"  />
                <Route component={Platforms} name="Platforms" path="/platforms/" />
                <Redirect from="/" to="/dashboard" />
              </Switch>
            </Container>
          </main>
        </div>
      </div>
    );
  }
}

export default connect()(MainLayout);
