import store from 'store2';

export { default as callApi } from './callApi';

// Endpoint prefix that is proxied to the API
const PREFIX = '/api';

// Basic headers
const HEADERS = {
  Accept: 'application/json',
};

// Default opts for setting headers
const HEADER_OPTS = {
  isContentJson: true,
  isCors: false,
  sendAuth: true,
};

// Array of status codes that do not come back with a response body either by standard or convention
// This is checked so that the response body is not tried to be parsed as JSON at all
const EMPTY_RESPONSES = [204];

const _getUrl = (url) => (url.startsWith('http') ? url : `${PREFIX}${url}`);

const _getQueryParams = (params) => {
  if (typeof params !== 'object') {
    throw new Error(`Expected type Object for 'params', got ${typeof params}`);
  }

  if (Object.keys(params).length === 0) {
    return '';
  }

  let queryString = '?';

  Object.keys(params).forEach((key, idx) => {
    if (Array.isArray(params[key])) {
      params[key].forEach((param, idy) => {
        queryString += `${idx === 0 && idy === 0 ? '' : '&'}${key}[${param.id ?? param-1}]=${window.encodeURIComponent(param.value ?? param)}`;
      });
    } else {
      queryString += `${idx > 0 ? '&' : ''}${key}=${window.encodeURIComponent(
        params[key]
      )}`;
    }
  });

  return queryString
};


const _getHeaders = (opts) => {
  // Provide default opts values
  // XXX: We are not mutating the opts here, it just holds a reference!
  opts = Object.assign({}, HEADER_OPTS, opts);

  // Add headers based on opts
  // XXX: Do not mutate the HEADERS const, create a new object!
  let headers = Object.assign({}, HEADERS, {
    ...(opts.isContentJson ? { 'Content-Type': 'application/json' } : {}),
    // 'Access-Control-Allow-Origin': opts.isCors && '*',
    // XXX: Normally should not check `opts.isCors` for Authorization,
    // but restcountries blocks requests with such header
    Authorization:
      store.has('token') && opts.sendAuth && `Bearer ${store.get('token')}`,
  });

  // Since the above step might assign falsy props, we have to remove them
  for (const header in headers) {
    if (headers.hasOwnProperty(header) && !headers[header]) {
      delete headers[header];
    }
  }

  return headers;
};

const _blob = async (url, opts) => {
  const response = await fetch(url, opts);
  let data = {};
  let filename = 'undefined';

  if (!EMPTY_RESPONSES.includes(response.status)) {
    response.headers.forEach((value, key) => {
      if (key === 'content-disposition') {
        filename = value.split('"')[1];
      }
    });
    try {
      data = await response.blob();

      data = URL.createObjectURL(new Blob([data]));
    } catch (e) {
      console.warn(`Received empty/invalid ${response.status} response`);
    }
  }

  // TODO: Handle 40X and 50X errors properly
  if (response.ok) {
    return {
      status: response.status,
      data,
      filename,
    };
  } else {
    const error = new Error(
      `Fetch returned ${response.status} for ${opts.method} ${response.url}`
    );

    Object.assign(error, {
      isFetch: true,
      status: response.status,
      data,
    });

    throw error;
  }
};

const _fetch = async (url, opts) => {
  const response = await fetch(url, opts);

  let data = {};

  let contentType = 'json';

  response.headers.forEach((value, header) => {
    if (header === 'content-type' && value.includes('text/csv')) {
      contentType = 'csv';
    }
  });

  // If the response has body
  if (!EMPTY_RESPONSES.includes(response.status)) {
    try {
      if (contentType === 'csv') {
        data = await response.text();
      } else {
        data = await response.json();
      }
    } catch (e) {
      console.warn(`Received empty/invalid ${response.status} response`);
    }
  }

  // TODO: Handle 40X and 50X errors properly
  if (response.ok) {
    return {
      status: response.status,
      data,
    };
  } else {
    const error = new Error(
      `Fetch returned ${response.status} for ${opts.method} ${response.url}`
    );

    Object.assign(error, {
      isFetch: true,
      status: response.status,
      data,
    });

    throw error;
  }
};

export async function get(url, queryParams = {}) {
  if (typeof queryParams !== 'object') {
    throw new Error(
      `Expected type Object for 'queryParams', got ${typeof queryParams}`
    );
  }

  const URL = _getUrl(url) + _getQueryParams(queryParams);

  const OPTS = {
    method: 'GET',
    headers: _getHeaders({ isContentJson: false }),
  };

  return _fetch(URL, OPTS);
}

export async function getBlob(url, queryParams = {}) {
  if (typeof queryParams !== 'object') {
    throw new Error(
      `Expected type Object for 'queryParams', got ${typeof queryParams}`
    );
  }

  const URL = _getUrl(url) + _getQueryParams(queryParams);

  const OPTS = {
    method: 'GET',
    headers: _getHeaders({ isContentJson: false }),
  };

  return _blob(URL, OPTS);
}

export async function post(url, payload = null, queryParams = {}) {
  if (payload === null) {
    throw new Error('Can not send POST request with empty payload');
  }

  if (typeof queryParams !== 'object') {
    throw new Error(
      `Expected type Object for 'queryParams', got ${typeof queryParams}`
    );
  }

  const isFormPayload = payload instanceof FormData;
  const URL = _getUrl(url) + _getQueryParams(queryParams);

  const OPTS = {
    method: 'POST',
    // Do NOT set/overwrite the Content-Type header if we are about to send a multipart request
    headers: _getHeaders({ isContentJson: !isFormPayload }),
    // Multipart body can be passed directly to fetch, Content-Type will be set automatically
    body: isFormPayload ? payload : JSON.stringify(payload),
  };

  return _fetch(URL, OPTS);
}

export async function patch(url, payload = null) {
  if (payload === null) {
    throw new Error('Can not send PATCH request with empty payload');
  }

  const isFormPayload = payload instanceof FormData;

  const URL = _getUrl(url);

  const OPTS = {
    method: 'PATCH',
    headers: _getHeaders({ isContentJson: !isFormPayload }),
    body: isFormPayload ? payload : JSON.stringify(payload),
  };

  return _fetch(URL, OPTS);
}

export async function put(url, payload = null) {
  const isFormDataPayload = payload instanceof FormData;

  if (
    payload === null ||
    (typeof payload === 'object' &&
      !Object.keys(payload).length &&
      !isFormDataPayload)
  ) {
    throw new Error('Can not send PATCH request with empty payload');
  }

  const URL = _getUrl(url);

  const OPTS = {
    method: 'PUT',
    // Do NOT set/overwrite the Content-Type header if we are about to send a multipart request
    headers: _getHeaders({ isContentJson: !isFormDataPayload }),
    // Multipart body can be passed directly to fetch, Content-Type will be set automatically
    body: isFormDataPayload ? payload : JSON.stringify(payload),
  };

  return _fetch(URL, OPTS);
}

export async function del(url) {
  const URL = _getUrl(url);

  const OPTS = {
    method: 'DELETE',
    headers: _getHeaders({ isContentJson: false }),
  };

  return _fetch(URL, OPTS);
}

export async function corsGet(url, queryParams = {}, headerOpts = {}) {
  if (typeof queryParams !== 'object') {
    throw new Error(
      `Expected type Object for 'queryParams', got ${typeof queryParams}`
    );
  }

  const URL = _getUrl(url) + _getQueryParams(queryParams);

  const OPTS = {
    method: 'GET',
    // Make sure to pass opts last here to override their passed equvivalent
    headers: _getHeaders({ ...headerOpts, isContentJson: false, isCors: true }),
  };

  return _fetch(URL, OPTS);
}
