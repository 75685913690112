import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';

import TableRow from 'components/TableRow'
import { isNullOrUndefined } from 'utils';

import actions from '../../actions';
import styles from '../styles.module.scss';


const Roles = [
  { value: 'Admin', label: 'Admin' },
  { value: 'User', label: 'User' },
  { value: 'Viewer', label: 'Viewer' }
]

const getValidationScema = () =>
  Yup.object().shape({
    isBannedFromTournaments: Yup.boolean(),
    bannedPeriod: Yup.object().when('isBannedFromTournaments', {
      is: true,
      then: Yup.object().shape({
        // Not work required message
        bannedFrom: Yup.date().required(),
        bannedTo: Yup.date().required()
      })
    })
  })

const ErrorMsg = () => {
  return <div className={styles.errorMsg}>
    The date is required!
  </div>
}

const AdminWrapper = ({ user, isAdmin, sessionUserId, userId, isSaveDisabled }) => {
  const dispatch = useDispatch();

  const userBannedFrom = user?.bannedPeriod?.bannedFrom;
  const userBannedTo = user?.bannedPeriod?.bannedTo;
  const userBannedFromDate = new Date(userBannedFrom);
  const userBannedToDate = new Date(userBannedTo);
  const isBanValid = userBannedToDate >= Date.now();
  const isBanned = user?.isBannedFromTournaments && isBanValid;
  const bannedFrom = userBannedFrom && isBanValid ? userBannedFromDate : new Date(new Date().setHours(0, 0, 0, 0));
  const bannedTo = userBannedFrom && isBanValid ? userBannedToDate : undefined;

  const getInitialState = () => {
    return {
      userRole: {
        value: user.role,
        label: user.role,
      },
      boardExcessiveUsageEmail: user?.boardExcessiveUsageEmail,
      boardExcessiveUsageNotification: user?.boardExcessiveUsageNotification,
      isBannedFromTournaments: isBanned,
      isEmailActive: user?.isEmailActive,
      bannedPeriod: {
        bannedFrom: bannedFrom,
        bannedTo: bannedTo,
      }
    }
  }

  const handleSubmit = values => {
    const { userRole, boardExcessiveUsageEmail, boardExcessiveUsageNotification, isBannedFromTournaments, bannedPeriod, isEmailActive } = values;
    dispatch(
      actions.updateUser({
        userId: userId,
        userRole: userRole.value,
        boardExcessiveUsageEmail: boardExcessiveUsageEmail,
        boardExcessiveUsageNotification: boardExcessiveUsageNotification,
        prevUserProps: user,
        isBannedFromTournaments: isBannedFromTournaments,
        bannedPeriod: isBannedFromTournaments ? bannedPeriod : null,
        isEmailActive: isEmailActive
      })
    );
  }

  const isDisabledButton = (values) => {
    const isUserRoleNotChanged = values?.userRole.value === user.role;
    const isEmailActiveNotChanged = values?.isEmailActive === user.isEmailActive;
    const isBoardExcessiveUsageEmailNotChanged = values?.boardExcessiveUsageEmail === user.boardExcessiveUsageEmail;
    const isBoardExcessiveUsageNotificationNotChanged = values?.boardExcessiveUsageNotification === user.boardExcessiveUsageNotification;

    const isBannedPeriodNotDifferent = (bannedTo, bannedFrom) => {
      return bannedTo.getTime() === userBannedToDate.getTime() && bannedFrom.getTime() === userBannedFromDate.getTime();
    };

    const isBannedFromTournamentsNotSaveable = () => {
      const { isBannedFromTournaments, bannedPeriod } = values;
      const isBannedFromTournamentsNotChanged = isBannedFromTournaments === user?.isBannedFromTournaments;

      const { bannedTo, bannedFrom } = bannedPeriod;
      if (isNullOrUndefined(bannedTo) || isNullOrUndefined(bannedFrom)) {
        return true;
      }

      if (isBannedFromTournamentsNotChanged && !isBannedFromTournaments) {
        return true;
      }

      if (isBannedFromTournaments && isBannedPeriodNotDifferent(bannedTo, bannedFrom)) {
        return true;
      }
    }

    return ((isUserRoleNotChanged &&
      isEmailActiveNotChanged &&
      isBoardExcessiveUsageEmailNotChanged &&
      isBoardExcessiveUsageNotificationNotChanged &&
      isBannedFromTournamentsNotSaveable()
    ) || isSaveDisabled)
  }

  return <Formik
    initialValues={getInitialState()}
    validationSchema={getValidationScema()}
    onSubmit={handleSubmit}>
    {({ errors, values, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
      <>
        {sessionUserId !== userId &&
          //Admins can change anyone's role except their owns
          <div className={styles.rowHolder}>
            <TableRow.Select
              label="Role"
              value={values.userRole}
              name="userRole"
              selectClass={styles.selector}
              onBlur={handleBlur}
              onChange={(data) => setFieldValue("userRole", data)}
              options={Roles}
              isDisabled={!isAdmin || sessionUserId === userId}
            />
          </div>
        }
        <TableRow.Checkbox
          label="Excessive usage email"
          value={values.boardExcessiveUsageEmail}
          name="boardExcessiveUsageEmail"
          type="checkbox"
          onBlur={handleBlur}
          onChange={handleChange}
          isDisabled={!isAdmin}
        />
        <TableRow.Checkbox
          label="Excessive usage notification"
          value={values.boardExcessiveUsageNotification}
          name="boardExcessiveUsageNotification"
          type="checkbox"
          onBlur={handleBlur}
          onChange={handleChange}
          isDisabled={!isAdmin}
        />
        <TableRow.Checkbox
          label="Banned from tournaments"
          value={values.isBannedFromTournaments}
          name='isBannedFromTournaments'
          type='checkbox'
          onBlur={handleBlur}
          onChange={handleChange}
          isDisabled={!isAdmin}
        />
        <TableRow.Checkbox
          label="Email is active"
          value={values.isEmailActive}
          name='isEmailActive'
          type='checkbox'
          onBlur={handleBlur}
          onChange={handleChange}
          isDisabled={!isAdmin}
        />
        {values.isBannedFromTournaments &&
          <div className={styles.dateRow}>
            <div className='col-md-12'>Banned Period</div>
            <TableRow.DatePicker
              label="Date from"
              value={values.bannedPeriod.bannedFrom}
              name='bannedPeriod.bannedFrom'
              onBlur={handleBlur}
              onChange={date => {
                setFieldValue('bannedPeriod.bannedFrom', date);
              }}
              isError={!!errors.bannedPeriod?.bannedFrom}
              errorMsg={<ErrorMsg />}
            />
            <TableRow.DatePicker
              label="Date to"
              value={values.bannedPeriod.bannedTo}
              name='bannedPeriod.bannedTo'
              onBlur={handleBlur}
              onChange={date => {
                setFieldValue('bannedPeriod.bannedTo', date);
              }}
              isError={!!errors.bannedPeriod?.bannedTo}
              errorMsg={<ErrorMsg />}
            />
          </div>
        }
        <TableRow.Button
          btnLabel={isSaveDisabled ? <i className="fa fa-spinner fa-spin fa-fw" /> : 'Save'}
          onClick={handleSubmit}
          isDisabled={isDisabledButton(values)}
        />
      </>)}
  </Formik>
}

AdminWrapper.propTypes = {
  user: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool,
  isSaveDisabled: PropTypes.bool,
  sessionUserId: PropTypes.string,
  userId: PropTypes.string,
};

export default AdminWrapper