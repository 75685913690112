import { createAction } from 'redux-actions';

export const getMessage = createAction('GET_MESSAGE');
export const getMessages = createAction('GET_MESSAGES');
export const getMessagesRequest = createAction('GET_MESSAGES_REQUEST');
export const subscribeToGroup = createAction('SUBSCRIBE_TO_GROUP');
export const unsubscribeToGroup = createAction('UNSUBSCRIBE_TO_GROUP');

export default {
  getMessage,
  getMessages,
  subscribeToGroup,
  unsubscribeToGroup,
  getMessagesRequest
};
