import { createAction } from 'redux-actions';

export const createFirmware = createAction('CREATE_FIRMWARE_REQUEST');
export const createFirmwareFailure = createAction('CREATE_FIRMWARE_FAILURE');
export const createFirmwareSuccess = createAction('CREATE_FIRMWARE_SUCCESS');


export default {
  createFirmware,
  createFirmwareFailure,
  createFirmwareSuccess,
};
