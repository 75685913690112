import React, { useRef, useState } from 'react';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import { Portal } from 'components/Portal';
import { DropdownDirection, useDropdownPositioning } from '../hooks';
import { SizesEnum } from '../enums';
import { SScrollContainer } from '../SScrollContainer';
import { SAnimate, SAnimateVariantsEnum } from '../SAnimate';
import { SCard, SCardVariants } from '../SCard';
import { SLabelContainer } from '../SLabelContainer';
import { isNullOrUndefined, mapBoolPropsToClassnames as mapBPTC, onlyNumbers } from 'utils';
import styles from './styles.module.scss';
import { ArrowIcon, ArrowIconDirection } from 'components/Icons';
const containerMaxHeight = 280;
const RegExpCollector = {
    POSITIVE_INTEGER_REGEX: /^[0-9]*$/,
    ALLOW_NEGATIVE_INTEGER_REGEX: /^-?[0-9]*$/,
    POSITIVE_INTEGER_AND_FLOAT_REGEX: /^[0-9]*[.,]?[0-9]*$/,
    ALLOW_NEGATIVE_INTEGER_AND_FLOAT_REGEX: /^-?[0-9]*[.,]?[0-9]*$/
};
export function SOptionsInput({ name, hint, error, label, value, theme, tooltip, className, options = [], type = 'text', size = SizesEnum.Medium, showLabel = true, lockedOptionContainerSize = false, isDropdownIconVisible = true, isRequired = false, useFiltering = true, optionsContainerClassName = '', onSelect, onChange, onFocus, onBlur, ...rest }) {
    const [isInFocus, setIsInFocus] = useState(false);
    const inputRef = useRef(null);
    const dropdownDirection = useDropdownPositioning(inputRef, containerMaxHeight);
    const handlePreventDefault = event => {
        event.preventDefault();
        event.stopPropagation();
    };
    const handleFocus = event => {
        onFocus && onFocus(event);
        setIsInFocus(true);
    };
    const handleBlur = event => {
        handlePreventDefault(event);
        setTimeout(() => {
            onBlur && onBlur(event);
            setIsInFocus(false);
        }, 500);
    };
    const handleSelect = (event, selectedValue) => {
        handlePreventDefault(event);
        onSelect && onSelect(selectedValue, name);
        setIsInFocus(false);
    };
    const handleChange = event => {
        onChange(event.target.value, name);
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.currentTarget.blur();
        }
        const min = rest?.min;
        const step = rest?.step;
        if (min >= 0 && step === 1) {
            onlyNumbers(e, RegExpCollector.POSITIVE_INTEGER_REGEX);
        }
        if (min >= 0 && isNullOrUndefined(step)) {
            onlyNumbers(e, RegExpCollector.POSITIVE_INTEGER_AND_FLOAT_REGEX);
        }
        if (isNullOrUndefined(min) && step === 1) {
            onlyNumbers(e, RegExpCollector.ALLOW_NEGATIVE_INTEGER_REGEX);
        }
        if (isNullOrUndefined(min) && isNullOrUndefined(step) && type === 'number') {
            onlyNumbers(e, RegExpCollector.ALLOW_NEGATIVE_INTEGER_AND_FLOAT_REGEX);
        }
    };
    const isUp = dropdownDirection === DropdownDirection.up;
    const isDown = dropdownDirection === DropdownDirection.down;
    let dropdownSizes;
    let containerStyles = {};
    let scrollContainerStyles = {};
    if (inputRef?.current) {
        const rect = inputRef.current.getBoundingClientRect();
        dropdownSizes = {
            width: inputRef.current.clientWidth,
            height: inputRef.current.clientHeight,
            top: rect.top,
            left: rect.left,
        };
        containerStyles = {
            top: `${isDown
                ? (dropdownSizes?.top ?? 0) + (dropdownSizes?.height ?? 0) + 8
                : dropdownSizes?.top}px`,
            left: `${dropdownSizes?.left}px`,
            width: lockedOptionContainerSize
                ? `${dropdownSizes?.width}px`
                : 'min-content',
            height: `${containerMaxHeight}px`,
        };
        scrollContainerStyles = {
            maxHeight: `${containerMaxHeight}px`,
        };
    }
    const availableOptions = options.filter(option => {
        if (!value || !useFiltering) {
            return true;
        }
        return `${option.value}`.includes(`${value}`);
    });
    const isOpen = !isEmpty(availableOptions) && isInFocus;
    return (React.createElement(SLabelContainer, { error: error, hint: hint, label: label, name: name, isRequired: isRequired, showLabel: showLabel, tooltip: tooltip, style: { position: 'relative' } },
        React.createElement("input", { id: name, name: name, value: value, ref: inputRef, className: cn(styles.SInput, styles[theme], styles[size], [className], {
                [styles.error]: !!error,
            }), type: type, onChange: handleChange, onFocus: handleFocus, onBlur: handleBlur, onKeyDown: handleKeyDown, ...rest }),
        React.createElement(SAnimate, { in: !!error },
            React.createElement("i", { className: cn('sis-failed', styles.errorIcon, styles[size], mapBPTC(styles, { showLabel })) })),
        isDropdownIconVisible && !error && (React.createElement("div", { className: cn(styles.arrow, styles[size], mapBPTC(styles, { isOpen })) },
            React.createElement(ArrowIcon, { direction: ArrowIconDirection.Down }))),
        React.createElement(Portal, null,
            React.createElement("div", { className: styles.optionsContainerBackground },
                React.createElement("div", { style: containerStyles, className: cn(styles.optionsContainer, [optionsContainerClassName], mapBPTC(styles, { isUp, isDown, isOpen })) },
                    React.createElement(SAnimate, { variant: SAnimateVariantsEnum.SlideInTop, in: isOpen, onClick: handlePreventDefault },
                        React.createElement(SCard, { theme: theme, className: styles.optionsWrapper, variant: SCardVariants.Solid, outline: true },
                            React.createElement(SScrollContainer, { style: scrollContainerStyles }, availableOptions.map((option, index) => (React.createElement("button", { key: index, value: option.value, className: styles.option, onClick: event => handleSelect(event, option.value) }, option.label)))))))))));
}
SOptionsInput.displayName = 'SOptionsInput';
