import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import selectors from './selectors';
import { scoreCorrections } from 'constants/Resources';
import { getColumns, useScoreCorrectionHistory } from './utils';
import { EntityTable } from 'components/EntityTable';
import { useQueryClient } from 'react-query';
const pageSize = 25;
const DEFAULT_SORT = [
    {
        id: 'date',
        desc: true,
    },
];
const selectColumns = ['__type', 'isAdvantageous', 'status'];
export const ScoreCorrectionHistory = ({ userId, isAdmin }) => {
    const queryClient = useQueryClient();
    const [parameters, setParameters] = useState();
    const { isDownloading } = useSelector(selectors.getScoreCorrectionHistory);
    const clientUrl = useSelector((state) => state.configuration?.clientUrl);
    const { data: scoreCorrectionHistory, isLoading } = useScoreCorrectionHistory(userId, parameters);
    const handleFetchData = (options, isRefresh) => {
        setParameters(options);
        if (isRefresh) {
            queryClient.invalidateQueries(['scoreCorrection', 'history', userId]);
        }
    };
    return (React.createElement(EntityTable, { resource: scoreCorrections, selectColumns: selectColumns, columns: getColumns(clientUrl), data: scoreCorrectionHistory?.data ?? [], defaultPageRowSize: pageSize, totalCount: scoreCorrectionHistory?.totalCount ?? 0, datePropertyKey: "date", hasDateFilter: true, defaultSorted: DEFAULT_SORT, isLoading: isLoading, isDownloading: isDownloading, onFetchData: handleFetchData, isAdmin: isAdmin, downloadParams: { discriminator: userId } }));
};
