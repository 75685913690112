import { createAction } from 'redux-actions';

export const getDashboard = createAction('DASHBOARD_GET_REQUEST');
export const getDashboardSuccess = createAction('DASHBOARD_GET_SUCCESS');
export const getDashboardFailure = createAction('DASHBOARD_GET_FAILURE');

export default {
  getDashboard,
  getDashboardSuccess,
  getDashboardFailure,
};
