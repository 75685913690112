import { createAction } from 'redux-actions';

export const getFirmwares = createAction(
  'FIRMWARES_GET_REQUEST',
  (queryParams, isLoading = true) => ({
    queryParams,
    isLoading,
  })
);
export const getFirmwaresFailure = createAction('FIRMWARES_GET_FAILURE');
export const getFirmwaresSuccess = createAction('FIRMWARES_GET_SUCCESS');

export default {
  getFirmwares,
  getFirmwaresFailure,
  getFirmwaresSuccess,
};
