import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import styles from './styles.module.scss'


const Checkbox = ({ disabled, checked, onChange, className, name }) => {
  return (
    <div className={cn(styles.checkbox, className, {
      [styles.disabled]: disabled,
      [styles.checked]: checked,
    })}>
      <input
        type="checkbox"
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        name={name}
      />
    </div>
  )
}

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
}

export default Checkbox