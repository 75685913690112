import { handleActions, combineActions } from 'redux-actions';

import {
  removeNotification,
  notifySuccess,
  notifyWarning,
  notifyError,
} from './actions';

const INITIAL_STATE = [];

const notificationsReducer = handleActions(
  {
    [combineActions(notifySuccess, notifyWarning, notifyError)]: (
      state,
      { payload: notification }
    ) => [...state, notification],

    [removeNotification]: (state, { payload: notificationId }) =>
      state.filter(item => item.id !== notificationId),
  },
  INITIAL_STATE
);

export default notificationsReducer;
