import { createAction } from 'redux-actions';

export const getHardwares = createAction(
  'HARDWARES_GET_REQUEST',
  (isLoading = true) => ({
    isLoading,
  })
);
export const getHardwaresFailure = createAction('HARDWARES_GET_FAILURE');
export const getHardwaresSuccess = createAction('HARDWARES_GET_SUCCESS');

export default {
  getHardwares,
  getHardwaresFailure,
  getHardwaresSuccess,
};
