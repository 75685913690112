import React, { useEffect } from 'react';
import { isUndefined } from 'lodash';
import { TournamentWizard } from 'components/TournamentWizard';
import { getInvitationStatuses } from 'utils';
import { InvitedParticipantsList, ParticipantsStatusHeader } from './components';
import { Card, Col, Row } from 'reactstrap';
import { prepareWizardFormValues } from 'components/TournamentWizard/utils';
import { useDeleteParticipantInvitation, useFetchInvitationalTournament, useFormEditSubmit, useResendParticipantInvitation, } from 'scenes/Tournaments/hooks';
import { uploadThumbnail } from '../utils';
import { isImagePrivateTournamentThumbnail } from 'constants/ScoliaThumbnails';
import { useDispatch } from 'react-redux';
import { subscribeToPrivateTournamentUpdates, unsubscribeToPrivateTournamentUpdates, } from 'scenes/PrivateTournaments/components/Show/actions';
export function EditInvitational({ match }) {
    const dispatch = useDispatch();
    const tournamentId = match?.params?.tournamentId;
    useEffect(() => {
        dispatch(subscribeToPrivateTournamentUpdates({ channel: 'tournament', tournamentId: tournamentId }));
        return () => {
            dispatch(unsubscribeToPrivateTournamentUpdates({ channel: 'tournament', tournamentId: tournamentId }));
        };
    }, [dispatch, tournamentId]);
    const { isLoading, error, data: tournament } = useFetchInvitationalTournament(tournamentId);
    const { mutate: deleteParticipantInvitation } = useDeleteParticipantInvitation(tournamentId);
    const { mutate: resendParticipantInvitation } = useResendParticipantInvitation(tournamentId);
    const { mutate: formEditSubmit } = useFormEditSubmit(tournamentId);
    if (isLoading) {
        return React.createElement("i", { className: "fa fa-spinner fa-spin fa-fw" });
    }
    if (error) {
        return 'Something went wrong!';
    }
    const handleFormEditSubmit = async (values) => {
        const formattedValues = prepareWizardFormValues(values);
        const { thumbnailUrl, thumbnailImage } = values;
        if (thumbnailUrl && !isImagePrivateTournamentThumbnail(thumbnailUrl) && thumbnailImage) {
            const fileName = await uploadThumbnail(thumbnailImage);
            if (!isUndefined(fileName)) {
                formattedValues.thumbnailUrl = fileName;
                delete formattedValues.thumbnailImage;
            }
        }
        delete formattedValues.participantsOrder;
        delete formattedValues.penalty;
        delete formattedValues.registrationStartDate;
        delete formattedValues.currentTokenCost;
        delete formattedValues._id;
        delete formattedValues.__v;
        delete formattedValues.participants;
        delete formattedValues.numberOfParticipants;
        delete formattedValues.isSharable;
        delete formattedValues.stages;
        delete formattedValues.createdAt;
        delete formattedValues.state;
        delete formattedValues.participantsOrder;
        delete formattedValues.tournamentEndDate;
        delete formattedValues.organiserType;
        formEditSubmit(formattedValues);
    };
    const { participants = {}, participantsOrder = [], maxParticipants = 0 } = tournament ?? {};
    const { acceptedInvitations, declinedInvitations, pendingInvitations } = getInvitationStatuses(Object.values(participants));
    return (React.createElement(Row, { className: "d-flex flex-wrap flex-grow-1 w-100 gap" },
        React.createElement(Col, { style: { width: '100%', maxWidth: '800px' }, className: "mb-2" },
            React.createElement(TournamentWizard, { values: tournament, id: tournamentId, onSubmit: handleFormEditSubmit })),
        !isUndefined(tournamentId) && (React.createElement(Col, { style: { width: '100%', minWidth: '800px', maxWidth: '1000px' } },
            React.createElement(Card, { color: "dark", className: "p-2" },
                React.createElement(ParticipantsStatusHeader, { acceptedInvitations: acceptedInvitations, declinedInvitations: declinedInvitations, pendingInvitations: pendingInvitations, maxParticipants: maxParticipants }),
                React.createElement(InvitedParticipantsList, { participantsOrder: participantsOrder, participants: participants, onDelete: deleteParticipantInvitation, onResend: resendParticipantInvitation }))))));
}
