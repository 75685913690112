import i18n from 'i18next';
// import Backend from 'i18next-xhr-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { en, hu } from 'constants/Translations';

i18n
  // .use(Backend)
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'en', // Later can be dynamic with LanguageDetector - now only english
    // debug: process.env.NODE_ENV !== 'production',
    debug: false, // TODO: Remove when i18n is actual
    resources: {
      en,
      hu,
    },
    ns: ['apiErrors', 'common'],
    defaultNS: 'common',
  });

export default i18n;
