import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Row } from 'reactstrap';
import cn from 'classnames';

import { formatNumberWithThousandSeparators } from 'utils';
import styles from './styles.module.scss';

const LABELS = {
  total: 'Total',
  singleMultiplier: 'Singles',
  doubleMultiplier: 'Doubles',
  tripleMultiplier: 'Trebles',
  oneToBullOrder: '1-20 order',
  bullToOneOrder: '20-1 order',
  randomOrder: 'Random order',
};

class ATCStats extends Component {
  get dataForCallouts() {
    const data = [];

    let usersData = Object.entries(this.props.stats);

    usersData.splice(0, 1);

    for (let [key, value] of usersData) {
      data.push({ key, value });
    }

    return data;
  }

  getCalloutValue = (key, value) => {
    const {
      total,
    } = this.props?.stats || {};

    const percentage = ((value / total) * 100).toFixed(0);

    return (
      <div className={styles.calloutValueContainer}>
        <span className={styles.calloutValue}>
          { formatNumberWithThousandSeparators(value) }
        </span>
        <span className={styles.calloutValuePercentage}>
          {percentage + '%'}
        </span>
      </div>
    );
  };

  get callouts() {
    return this.dataForCallouts.map(({ key, value }) => (
      <Col xs={6} sm={4} key={'atcstats-callout_' + key}>
        <div className={cn('callout ')}>
          {this.getCalloutValue(key, value)}
          <small className="text-muted text-uppercase fw-bold">
            {LABELS[key]}
          </small>
        </div>
      </Col>
    ));
  }

  render() {
    const {
      total,
    } = this.props?.stats || {};

    return (
      <Card color="white">
        <CardBody>
          <Fragment>
            <Row>
              <Col xs={8}>
                <strong className={cn('text-value h2', styles.textValue)}>
                  { formatNumberWithThousandSeparators(total) }
                </strong>
                <span className="text-muted text-uppercase fw-bold">
                  Total Games
                </span>
              </Col>
              <Col xs={4}>
                <div className={cn('text-muted text-end', styles.cardHeader)}>
                  ATC
                </div>
              </Col>
            </Row>
            <Row>{this.callouts}</Row>
          </Fragment>
        </CardBody>
      </Card>
    );
  }
}

ATCStats.propTypes = {
  stats: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

ATCStats.defaultProps = {
  isLoading: true,
};

export default ATCStats;
