import { inRange } from 'lodash';
import { deepClone, getNextHourDate } from 'utils';
import { X01 } from 'constants/GameTypes';
import {
  TournamentStructure,
  TournamentParticipationType,
  TournamentEntryFee,
  TournamentConfigType,
  TournamentRegistrationType,
  PrivateTournamentBackgroundImagesEnum,
  BullThrowEnum,
  ThrowOrderTypeEnum,
  X01GameInOutTypeEnum,
  ScoreTypeEnum,
  isTournamentUpcoming,
} from 'enums';

import { getAvailableTournamentStages } from 'utils/tournamentUtils';
import {
  FinishTimerOptions,
  PerVisitTimerOptions,
  StartTimerOptions,
  TIMER_CUSTOM_VALUE,
} from './components/Pages/GameConfiguration/constants';
import { PrivateTournamentThumbnails } from 'constants/ScoliaThumbnails';
import { STAGES_BY_ORDER } from 'constants/TournamentConfiguration';

export const MAX_PARTICIPANTS = 4;

const getSanitizedGameConfig = (
  config,
  bullThrow,
  gameType,
  globalStartTimer,
  globalFinishTimer,
  globalInactivityTimer
) => {
  const gameConfig = {
    ...config,
    ...(bullThrow && { firstToThrow: bullThrow, __type: gameType }),
  };

  if (gameConfig?.startScore) {
    gameConfig.startScore = parseInt(gameConfig.startScore);
  }

  delete gameConfig?._id;
  delete gameConfig.bullThrow;
  delete gameConfig.isScoreCorrectionAllowed;

  if (globalStartTimer !== 'custom') {
    gameConfig.startTimer = globalStartTimer;
  }

  if (globalFinishTimer !== 'custom') {
    gameConfig.finishTimer = globalFinishTimer * 2;
  } else {
    gameConfig.finishTimer *= 2;
  }

  if (globalInactivityTimer !== 'none') {
    gameConfig.inactivityTimer = globalInactivityTimer;
  } else {
    delete gameConfig.inactivityTimer;
  }

  return gameConfig;
};

const generateFixedGameConfig = (values) => {
  const {
    variousGameConfig,
    bullThrow,
    gameType,
    globalStartTimer,
    globalFinishTimer,
    globalInactivityTimer,
  } = values;

  const { stages, ...rest } = variousGameConfig[0];

  return getSanitizedGameConfig(
    rest,
    bullThrow,
    gameType,
    globalStartTimer,
    globalFinishTimer,
    globalInactivityTimer
  );
};

const generateVariousGameConfig = (values) => {
  const {
    variousGameConfig,
    bullThrow,
    gameType,
    globalStartTimer,
    globalFinishTimer,
    globalInactivityTimer,
  } = values;

  return variousGameConfig.reduce((acc, config) => {
    const { stages, ...rest } = config;

    stages.forEach((stage) => {
      acc[stage] = getSanitizedGameConfig(
        rest,
        bullThrow,
        gameType,
        globalStartTimer,
        globalFinishTimer,
        globalInactivityTimer
      );
    });

    return acc;
  }, {});
};

const disabledFields = [
  'scoreCorrection',
  'bullThrow',
  'userCurrentAverage',
  'isAbleToJoin',
  'globalStartTimer',
  'globalFinishTimer',
  'globalInactivityTimer',
  'results',
  '_id',
  'numberOfParticipants',
  'isSharable',
  'createdAt',
  'tournamentEndDate',
  'participants',
  'stages',
  'state',
  'organiserType',
  '__v',
];

export const prepareWizardFormValues = (values) => {
  const newValues = deepClone(values);

  newValues.maxParticipants = parseInt(newValues.maxParticipants);
  newValues.minParticipants = parseInt(newValues.minParticipants);

  if (newValues.variousGameConfig.length === 1) {
    newValues['gameConfig'] = generateFixedGameConfig(newValues);
    newValues.configType = TournamentConfigType.Fixed;
    delete newValues.variousGameConfig;
  } else if (newValues.variousGameConfig.length > 1) {
    newValues['variousGameConfig'] = generateVariousGameConfig(newValues);
    newValues.configType = TournamentConfigType.Various;
    delete newValues.gameConfig;
  }

  if (values.state && !isTournamentUpcoming(values.state)) {
    delete newValues.gameConfig;
  }

  for (const field of disabledFields) {
    delete newValues[field];
  }

  newValues.registrationEndDate = newValues.tournamentStartDate;

  if (newValues.thumbnailUrl !== 'custom' && newValues.thumbnailImage) {
    delete newValues.thumbnailImage;
  }

  return newValues;
};

export const newX01Config = () => {
  return {
    startScore: 501,
    legs: 3,
    sets: 1,
    scoreType: ScoreTypeEnum.RaceTo,
    gameInType: X01GameInOutTypeEnum.Straight,
    gameOutType: X01GameInOutTypeEnum.Double,
    throwOrderType: ThrowOrderTypeEnum.Bull,
    startTimer: StartTimerOptions[0].value,
    finishTimer: FinishTimerOptions[0].value,
  };
};

export const defaultDescription = `
    <p><strong>🎯 Welcome to Scolia's Invitational Darts Championship! 🎯</strong></p>
    <p></p>
    <p>Greetings to all our players! Get ready for action-packed matches, bullseyes, and unforgettable moments.</p>
    <p>Let's aim high, play fair, and enjoy every moment. May the best player win!</p>
    <p></p>
    <p>🎉 Game on! 🎉</p>
  `;

export const newTournament = (organiserId, nickname) => {
  const defaultX01Config = newX01Config();

  return {
    name: "Scolia's invitational tournament",
    description: defaultDescription,
    tournamentStartDate: getNextHourDate(1),
    maxParticipants: MAX_PARTICIPANTS,
    minParticipants: 4,
    organiser: {
      organiserId: organiserId ?? null,
      name: nickname ?? null,
    },
    structure: TournamentStructure.SingleKnockout,
    participationType: TournamentParticipationType.Online,
    entryFee: TournamentEntryFee.Free,
    isScoreCorrectionAllowed: true,
    registrationType: TournamentRegistrationType.Invitational,
    gameType: X01.type,
    configType: TournamentConfigType.Fixed,
    variousGameConfig: [
      {
        stages: getAvailableTournamentStages(MAX_PARTICIPANTS),
        ...defaultX01Config,
      },
    ],
    bullThrow: BullThrowEnum.Wdf,
    customPrizes: {
      winner: '',
      runnerUp: '',
      semiFinalists: '',
    },
    thumbnailUrl: PrivateTournamentThumbnails.goldenTrophy,
    backgroundUrl: PrivateTournamentBackgroundImagesEnum.tournamentBackground1,
    globalStartTimer: StartTimerOptions[0].value,
    globalFinishTimer: FinishTimerOptions[2].value,
    globalInactivityTimer: PerVisitTimerOptions[0].value,
  };
};

export function isInFreeTier(n) {
  return inRange(n, 0, 9);
}

export function getConfigurationFee(maxParticipants) {
  return isInFreeTier(maxParticipants) ? 0 : maxParticipants - 8;
}

export function calculatePrivateTournamentFeeDifference(
  newParticipantCount,
  initialParticipantCount = 0
) {
  const initialConfigFee = getConfigurationFee(initialParticipantCount);
  const newConfigFee = getConfigurationFee(newParticipantCount);

  if (initialConfigFee === newConfigFee) return 0;

  return newConfigFee - initialConfigFee;
}

export const convertConfigStagesInCreatedTournament = (values) => {
  const { gameConfig, variousGameConfig, maxParticipants, configType } = values;

  if (gameConfig && configType === TournamentConfigType.Fixed) {
    const config = [
      {
        stages: getAvailableTournamentStages(maxParticipants),
        ...gameConfig,
      },
    ];

    return { ...values, variousGameConfig: config };
  }

  const variousStages = [];

  const orderedStages = [...STAGES_BY_ORDER].reverse();

  const orderedVariousConfig = orderedStages.reduce((acc, stage) => {
    if (variousGameConfig.hasOwnProperty(stage)) {
      return {
        ...acc,
        [stage]: variousGameConfig[stage],
      };
    }

    return acc;
  }, {});

  for (const stage in orderedVariousConfig) {
    if (variousStages.length === 0) {
      variousStages.push({ stages: [stage], ...orderedVariousConfig[stage] });
      continue;
    }

    const { stages, ...rest } = variousStages[variousStages.length - 1];

    if (JSON.stringify(orderedVariousConfig[stage]) === JSON.stringify(rest)) {
      stages.push(stage);
      continue;
    }

    variousStages.push({ stages: [stage], ...orderedVariousConfig[stage] });
  }

  return { ...values, variousGameConfig: variousStages };
};

const hasMatch = (options, value) => {
  return options.some((option) => option.value === value);
};

const isTimerEqualInEveryConfig = (gameConfigs, key) => {
  return gameConfigs.every((config) => config[key] === gameConfigs[0][key]);
};

//outer timer values are for radio buttons, config timer values are for custom time values
export const convertTimerValuesFromGameConfig = (values) => {
  const { gameConfig, variousGameConfig, configType } = values;

  if (gameConfig && configType === TournamentConfigType.Fixed) {
    const globalStartTimer = hasMatch(StartTimerOptions, gameConfig.startTimer)
      ? gameConfig.startTimer
      : TIMER_CUSTOM_VALUE;
    const globalFinishTimer = hasMatch(FinishTimerOptions, gameConfig.finishTimer)
      ? gameConfig.finishTimer / 2
      : TIMER_CUSTOM_VALUE;
    const globalInactivityTimer = gameConfig.inactivityTimer;
    variousGameConfig[0] = { ...variousGameConfig[0], finishTimer: gameConfig.finishTimer / 2 };

    return {
      ...values,
      variousGameConfig,
      globalStartTimer,
      globalFinishTimer,
      globalInactivityTimer,
    };
  }

  const startTimerFromConfig = variousGameConfig[0].startTimer;
  const finishTimerFromConfig = variousGameConfig[0].finishTimer;
  const globalInactivityTimer = variousGameConfig[0].inactivityTimer;
  const globalStartTimer =
    isTimerEqualInEveryConfig(variousGameConfig, 'startTimer') &&
    hasMatch(StartTimerOptions, startTimerFromConfig)
      ? startTimerFromConfig
      : TIMER_CUSTOM_VALUE;
  const globalFinishTimer =
    isTimerEqualInEveryConfig(variousGameConfig, 'finishTimer') &&
    hasMatch(FinishTimerOptions, finishTimerFromConfig)
      ? finishTimerFromConfig / 2
      : TIMER_CUSTOM_VALUE;

  const variousGameConfigWithProperStartTimerValue = variousGameConfig.map((config) => ({
    ...config,
    finishTimer: config.finishTimer / 2,
  }));

  return {
    ...values,
    variousGameConfig: variousGameConfigWithProperStartTimerValue,
    globalStartTimer,
    globalFinishTimer,
    globalInactivityTimer,
  };
};

export const getInitialTournament = (organiserId, nickname, values) => {
  if (!values) return newTournament(organiserId, nickname);

  const valuesWithProperConfigs = convertConfigStagesInCreatedTournament(values);
  const valuesWithProperTimeValues = convertTimerValuesFromGameConfig(valuesWithProperConfigs);

  return valuesWithProperTimeValues;
};
