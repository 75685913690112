import { call, put, takeLatest } from 'redux-saga/effects';

import { Notifications } from 'services/api';

import { callApi } from 'services/rest';
import { notifyError, notifySuccess } from 'services/notification/actions';

import {
  getNotifications,
  deleteNotification,
  deleteNotificationFailure,
  deleteNotificationSuccess,
} from 'scenes/Notifications/actions';

function* watchDeleteNotification({ payload: {notificationId}}) {
  try {
    yield call(callApi, Notifications.delete, notificationId);
    yield put(deleteNotificationSuccess());
    yield put(notifySuccess(`Notification (${notificationId}) has been deleted successfully.`))

    yield put(getNotifications());

  } catch (e) {
    yield put(deleteNotificationFailure(e));
    yield put(notifyError(`Failed to delete notification ${notificationId}.`))
  }
}

export default () => takeLatest(deleteNotification, watchDeleteNotification);
