import { all, spawn, call } from 'redux-saga/effects';

import { sagas as dataSaga } from 'data';

import { sagas as boardsSaga } from 'scenes/Boards';
import { sagas as onlineBoardsSaga } from 'scenes/OnlineBoards';
import { sagas as feedbacksSaga } from 'scenes/Feedbacks';
import { sagas as firmwaresSaga } from 'scenes/Firmwares';
import { sagas as serviceAccountsSaga } from 'scenes/ServiceAccounts';
import { sagas as storeSnapshotSaga } from 'scenes/StoreSnapshot';
import { sagas as hardwaresSaga } from 'scenes/Hardwares';
import { sagas as usersSaga } from 'scenes/Users';
import { sagas as resourceLogsSaga } from 'scenes/ResourceLogs';
import { sagas as rankingListSaga } from 'scenes/RankingList';
import { sagas as rankingsSaga } from 'scenes/Rankings';
import { sagas as systemUsages } from 'scenes/SystemUsages';
import { sagas as dashboardSaga } from 'scenes/Dashboard';
import { sagas as configurationSaga } from 'services/configuration';
import { sagas as notificationSaga } from 'services/notification';
import { sagas as sessionSaga } from 'services/session';
import { sagas as wsSaga } from 'services/ws';
import { sagas as entityTableSaga } from 'services/entityTable';
import { sagas as manufacturersSaga } from 'scenes/Manufacturers/';
import { sagas as chatSaga } from 'scenes/Chat/';
import { sagas as notificationsSaga } from 'scenes/Notifications/';
import { sagas as tournamentsSaga } from 'scenes/Tournaments/';
import { sagas as privateTournamentsSaga } from 'scenes/PrivateTournaments/';
import { sagas as products } from 'scenes/Products/';
import { sagas as subscriptions } from 'scenes/Subscriptions/';
import { sagas as tokenPurchases } from 'scenes/TokenPurchases/';
import { sagas as platforms } from 'scenes/Platforms/';

const rootSagas = [
  // --- General Sagas ---
  dataSaga,
  configurationSaga, // Client configuration fetching
  notificationSaga, // Notification handling
  sessionSaga, // Session/login-logout handling
  entityTableSaga, // Entity table's (export) handling

  // --- WS Sagas ---
  wsSaga,

  // --- Scene Sagas ---
  boardsSaga,
  onlineBoardsSaga,
  feedbacksSaga,
  firmwaresSaga,
  hardwaresSaga,
  usersSaga,
  resourceLogsSaga,
  systemUsages,
  rankingListSaga,
  rankingsSaga,
  dashboardSaga,
  serviceAccountsSaga,
  storeSnapshotSaga,
  manufacturersSaga,
  chatSaga,
  notificationsSaga,
  tournamentsSaga,
  privateTournamentsSaga,
  products,
  subscriptions,
  tokenPurchases,
  platforms,
];

const spawnRestartableSaga = (saga) =>
  spawn(function* () {
    while (true) {
      try {
        yield call(saga);
      } catch (error) {
        console.error(error, saga);
      }
    }
  });

export default function* rootSaga() {
  yield all(rootSagas.map(spawnRestartableSaga));
}
