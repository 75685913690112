import { call, put, takeLatest } from 'redux-saga/effects';

import { Tournaments } from 'services/api';

import {
  getTournaments,
  getTournamentsFailure,
  getTournamentsSuccess,
} from 'scenes/Tournaments/components/List/actions';
import { callApi } from 'services/rest';

function* watchGetTournaments({ payload }) {
  try {
    const { data } = yield call(callApi, Tournaments.list, payload);
    yield put(getTournamentsSuccess(data));
  } catch (e) {
    yield put(getTournamentsFailure());
  }
}

// It must be wrapped in a function, so execution will happen only on function call
export default () => takeLatest(getTournaments, watchGetTournaments);
