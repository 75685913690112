import { handleActions } from 'redux-actions';
import decodeJwt from 'jwt-decode';

import { logInSuccess, logOut } from './actions';

const INITIAL_STATE = null;

const session = handleActions(
  {
    [logInSuccess]: (_state, { payload: { token, ttl } }) => {
      try {
        return {
          ...decodeJwt(token),
          ttl,
        };
      } catch (e) {
        console.log(e);
        console.warn('Failed to decode JWT token');
        return null;
      }
    },

    [logOut]: () => INITIAL_STATE,
  },
  INITIAL_STATE
);

export default session;
