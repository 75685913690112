import { createAction } from 'redux-actions';

export const download = createAction('ENTITY_TABLE_EXPORT_DOWNLOAD_REQUEST');
export const downloadSuccessful = createAction('ENTITY_TABLE_EXPORT_DOWNLOAD_SUCCESS');
export const downloadFailure = createAction('ENTITY_TABLE_EXPORT_DOWNLOAD_FAILURE');

export default {
  download,
  downloadSuccessful,
  downloadFailure
};
