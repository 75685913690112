import { call, put, takeLatest } from 'redux-saga/effects';

import { Statistics } from 'services/api';
import { callApi } from 'services/rest';

import {
  getSystemAnalytics,
  getSystemAnalyticsSuccess,
  getSystemAnalyticsFailure,
} from 'scenes/SystemUsages/components/SystemAnalytics/actions';

function* watchGetSystemAnalytics({payload}) {
  try {
    const { data } = yield call(callApi, Statistics.systemAnalyticsChart, payload);

    yield put(getSystemAnalyticsSuccess({ data }));
  } catch (e) {
    console.log(e);
    yield put(getSystemAnalyticsFailure());
  }
}

// It must be wrapped in a function, so execution will happen only on function call
export default () => takeLatest(getSystemAnalytics, watchGetSystemAnalytics);
