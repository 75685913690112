import React from 'react';
import { PlayerCardHeader } from './components/PlayerCardHeader';
import { PlayerCardBody } from './components/PlayerCardBody';
export const PlayerCardStatistics = ({ userStats }) => {
    if (!userStats) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(PlayerCardHeader, null),
        React.createElement(PlayerCardBody, { userStats: userStats })));
};
