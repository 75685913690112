import { call, put, takeLatest } from 'redux-saga/effects';

import { ServiceAccounts } from 'services/api';
import { callApi } from 'services/rest';

import {
  getServiceAccountActivity,
  getServiceAccountActivityFailure,
  getServiceAccountActivitySuccess,
} from 'scenes/ServiceAccounts/components/Show/actions';

function* watchGetServiceAccountActivity({
  payload: { serviceAccountId, minDay, minThrows, from, to },
}) {
  try {
    const { data } = yield call(
      callApi,
      ServiceAccounts.activity,
      serviceAccountId,
      {
        minDay,
        minThrows,
        from,
        to,
      }
    );
    yield put(getServiceAccountActivitySuccess({ data, serviceAccountId }));
  } catch (e) {
    console.log(e);
    yield put(getServiceAccountActivityFailure());
  }
}

export default () =>
  takeLatest(getServiceAccountActivity, watchGetServiceAccountActivity);
