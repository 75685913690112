import React from 'react'
import PropTypes from 'prop-types'

import { SRadio } from '../SRadio'

import styles from './styles.module.scss'

export const SRadioDescriptionOption = ({ name, value, checked, label, description, onChange }) => {
  return (
    <button type='button' value={value} name={name} onClick={_e => onChange({ target: { name, value } })} className={styles.option}>
      <div className={styles.optionInputContainer}>
        <SRadio checked={checked} value={value} name={name} onChange={onChange} />
      </div>
      <div className={styles.optionMetaContainer}>
        <label className={styles.optionLabel}>{label}</label>
        <div className={styles.optionDescription}>{description}</div>
      </div>
    </button>
  )
}

SRadioDescriptionOption.displayName = 'SRadioDescriptionOption';

SRadioDescriptionOption.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  checked: PropTypes.bool,
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default SRadioDescriptionOption
