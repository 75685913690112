import React from 'react';
import { Badge } from 'reactstrap';

import formatLabel from 'utils/formatLabel';

const Enum = ({ label, value, colorMap = {} }) => {
  return (
    <div className="row">
      <div className="col-sm-4">{formatLabel(label)}</div>
      <div className="col-sm-8">
        <Badge color={colorMap[value]}>
          {formatLabel(value)}
        </Badge>
      </div>
    </div>
  )
}

export default Enum