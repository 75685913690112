import { handleActions } from 'redux-actions';

import { actions as listActions } from 'scenes/ServiceAccounts/components/List';
import { actions as showActions } from 'scenes/ServiceAccounts/components/Show';

const INITIAL_STATE = [];

const usersReducer = handleActions(
  {
    [listActions.getServiceAccountsSuccess]: (state, { payload: { data } }) => {
      const serviceAccounts = data.reduce((serviceAccounts, serviceAccount) => {
        serviceAccount.id = serviceAccount._id;
        delete serviceAccount._id;

        serviceAccounts[serviceAccount.id] = serviceAccount;
        return serviceAccounts;
      }, {});

      return {
        ...serviceAccounts,
      };
    },

    [showActions.getServiceAccountSuccess]: (state, { payload }) => {
      payload.id = payload._id;
      delete payload._id;
      return {
        ...state,
        [payload.id]: { ...state[payload.id], ...payload },
      };
    },

    [showActions.getServiceAccountActivitySuccess]: (state, { payload: { data, serviceAccountId} }) => {
      return {
        ...state,
        [serviceAccountId]: { ...state[serviceAccountId], boardsActivity: data },
      };
    },
  },
  INITIAL_STATE
);

export default usersReducer;
