import { createAction } from 'redux-actions';

export const getSubscription = createAction('SUBSCRIPTION_GET_REQUEST');
export const getSubscriptionFailure = createAction('SUBSCRIPTION_GET_FAILURE');
export const getSubscriptionSuccess = createAction('SUBSCRIPTION_GET_SUCCESS');
export const resetSubscription = createAction('SUBSCRIPTION_SHOW_RESET');

export default {
  getSubscription,
  getSubscriptionFailure,
  getSubscriptionSuccess,
  resetSubscription,
};
