import React from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Badge } from 'reactstrap'

import { EntityTable } from 'components/EntityTable'

import * as rankingsActions from './actions'
import * as rankingSelectors from 'scenes/Rankings/selectors'

import { isNullOrUndefined } from 'utils'

const columns = [
  {
    Header: 'Pos.',
    accessor: 'position',
    filterable: true,
    sortable: true,
    maxWidth: 80,
    Cell: row => <Centered>{row.value}</Centered>
  },
  {
    Header: 'Nickname',
    accessor: 'user',
    filterable: true,
    Cell: row => (
      <NavLink to={`users/${row.value.id}`}>
        {row.value.nickname}
      </NavLink>
    )
  },
  {
    Header: 'Scolia ID',
    accessor: 'scoliaId',
    filterable: true,
    sortable: true,
    Cell: row => <Centered>
      <Badge color='success' >
        #{row.value}
      </Badge>
    </Centered>,
  },
  {
    Header: 'Email',
    accessor: 'email',
    filterable: true,
    sortable: true,
  },
  {
    Header: 'Tournament points',
    accessor: 'tp',
    filterable: true,
    sortable: true,
    maxWidth: 180,
    Cell: row => (
      <Centered>
        <TournamentPointsBadge value={row.value}/>
      </Centered>
    )
  },
]

const defaultSorted = [
  {
    id: 'position',
    desc: false,
  },
]

const List = () => {
  const dispatch = useDispatch()

  const { data, page, pageSize, isLoading, totalCount/*, isDownloading*/ } = useSelector(state => rankingSelectors.getRankings(state))
  
  const handleFetchData = query =>  dispatch(rankingsActions.getRankings(query))

  return (
    <div>
      <EntityTable
        resource={'rankings'}
        columns={columns}
        data={data}
        page={page}
        pageSize={pageSize}
        defaultSorted={defaultSorted}
        totalCount={totalCount}
        isLoading={isLoading}
        onFetchData={handleFetchData}
        hasDateFilter
        showMonthYearPicker
        datePropertyKey={'createdAt'}
        //isDownloading={isDownloading}
        isDownloading //until this is implemented, let's have a default disabled btn
      />
    </div>
  )
}


List.displayName = 'Rankings.List'

export default List

const Centered = ({ children }) => {
  return <div style={{width: '100%', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{children}</div>
}

const TournamentPointsBadge = ({ value }) => {
  if ( isNullOrUndefined(value) ) return null

  const formatMaxPrizePool = (prize, withLabel = true) => {
    return prize.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + (withLabel ? ' TP' : '')
  }

  const badgeStyle = {
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#EF9D34',
    background: 'rgb(14,28,35, .8)',
    borderRadius: '9px',
    padding: '0px 8px',
    whiteSpace: 'nowrap',
    width: 'min-content',
  }

  return <div style={badgeStyle}>{formatMaxPrizePool(value)}</div>
} 
