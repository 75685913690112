import { createAction } from 'redux-actions';

export const createBoard = createAction('CREATE_BOARD_REQUEST');
export const createBoardFailure = createAction('CREATE_BOARD_FAILURE');
export const createBoardSuccess = createAction('CREATE_BOARD_SUCCESS');

export const createMultipleBoards = createAction(
  'CREATE_MULTIPLE_BOARDS_REQUEST'
);
export const createMultipleBoardsFailure = createAction(
  'CREATE_MULTIPLE_BOARDS_FAILURE'
);
export const createMultipleBoardsSuccess = createAction(
  'CREATE_MULTIPLE_BOARDS_SUCCESS'
);
export const resetCreateMultipleBoardsState = createAction(
  'RESET_CREATE_MULTIPLE_BOARDS_STATE'
);

export default {
  createBoard,
  createBoardFailure,
  createBoardSuccess,
  createMultipleBoards,
  createMultipleBoardsFailure,
  createMultipleBoardsSuccess,
  resetCreateMultipleBoardsState
};
