import React from 'react'

import {
  START_SCORE_OPTIONS,
  FIRST_TO_THROW,
  GAME_IN_TYPES,
  GAME_OUT_TYPES,
  SET_OPTIONS,
  LEG_OPTIONS,
} from 'constants/TournamentConfiguration'

import { Input } from 'components/Form'
import InputSelect from 'components/Form/Input/Select'

import styles from './styles.module.scss'

const GameConfiguration = ({
  configuration,
  configurationPath,
  handleBlur,
  handleChange,
  disabled,
  errors,
}) => {
  return (
    <div className={styles.configurationCard}>
      <div className={styles.row}>
        <label>Game configuration:</label>
      </div>
      <div className={styles.row}>
        <div className={styles.basicInput}>
          <div className={styles.configurationRow}>
            <InputSelect
              name={`${configurationPath}.firstToThrow`}
              value={configuration?.firstToThrow}
              onBlur={handleBlur}
              onChange={handleChange}
              options={FIRST_TO_THROW}
              disabled={disabled}
            />
            <Input.Number
              name={`${configurationPath}.startScore`}
              value={configuration?.startScore}
              onBlur={handleBlur}
              onChange={handleChange}
              min={101}
              max={9999}
              disabled={disabled}
            />
            <InputSelect
              name={`${configurationPath}.scoreType`}
              value={configuration?.scoreType}
              onBlur={handleBlur}
              onChange={handleChange}
              options={START_SCORE_OPTIONS}
              disabled={disabled}
            />
            <InputSelect
              name={`${configurationPath}.sets`}
              value={configuration?.sets}
              onBlur={handleBlur}
              onChange={handleChange}
              options={SET_OPTIONS}
              disabled={disabled}
            />
            <InputSelect
              name={`${configurationPath}.legs`}
              value={configuration?.legs}
              onBlur={handleBlur}
              onChange={handleChange}
              options={LEG_OPTIONS}
              disabled={disabled}
            />
            <InputSelect
              name={`${configurationPath}.gameInType`}
              value={configuration?.gameInType}
              onBlur={handleBlur}
              onChange={handleChange}
              options={GAME_IN_TYPES}
              disabled={disabled}
            />
            <InputSelect
              name={`${configurationPath}.gameOutType`}
              value={configuration?.gameOutType}
              onBlur={handleBlur}
              onChange={handleChange}
              options={GAME_OUT_TYPES}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
      {errors?.firstToThrow && <div className={styles.mockedErrorMsg}>
        <i className="fa fa-exclamation-triangle" />{` ${errors?.firstToThrow}`}
      </div>}
      {errors?.sets && <div className={styles.mockedErrorMsg}>
        <i className="fa fa-exclamation-triangle" />{` ${errors?.sets}`}
      </div>}
      {errors?.legs && <div className={styles.mockedErrorMsg}>
        <i className="fa fa-exclamation-triangle" />{` ${errors?.legs}`}
      </div>}

      <div className={styles.row}>
        <div className={styles.timerSection}>
          <div>
            <label>Start Timer In Seconds:</label>
          </div>
          <Input.Number
            name={`${configurationPath}.startTimer`}
            value={configuration?.startTimer}
            onBlur={handleBlur}
            onChange={handleChange}
            disabled={disabled}
          />
        </div>
        <div className={styles.timerSection}>
          <div>
            <label>Finish Timer In Seconds:</label>
          </div>
          <Input.Number
            name={`${configurationPath}.finishTimer`}
            value={configuration?.finishTimer}
            onBlur={handleBlur}
            onChange={handleChange}
            disabled={disabled}
          />
        </div>
        <div className={styles.timerSection}>
          <div>
            <label>Inactivity Timer In Seconds:</label>
          </div>
          <Input.Number
            name={`${configurationPath}.inactivityTimer`}
            value={configuration?.inactivityTimer}
            onBlur={handleBlur}
            onChange={handleChange}
            disabled={disabled}
          />
        </div>
      </div>
      {errors?.startTimer && <div className={styles.mockedErrorMsg}>
        <i className="fa fa-exclamation-triangle" />{` ${errors?.startTimer}`}
      </div>}
      {errors?.finishTimer && <div className={styles.mockedErrorMsg}>
        <i className="fa fa-exclamation-triangle" />{` ${errors?.finishTimer}`}
      </div>}
      {errors?.inactivityTimer && <div className={styles.mockedErrorMsg}>
        <i className="fa fa-exclamation-triangle" />{` ${errors?.inactivityTimer}`}
      </div>}
    </div>
  )
}

export default GameConfiguration