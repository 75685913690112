export interface HeaderElem {
  overAllLabel: string,
  tournamentLabel: string,
  label?: string,
  key: string,
  overallAccessor: string,
  tournamentAccessor: string,
  size: number,
  common: boolean,
}

export const Headers: HeaderElem[] = [
  {
    overAllLabel: 'Nickname',
    tournamentLabel: 'Nickname',
    key: 'nickname',
    overallAccessor: 'nickname',
    tournamentAccessor: 'nickname',
    size: 2,
    common: true
  },
  {
    overAllLabel: 'Status',
    tournamentLabel: 'Status',
    key: 'status',
    overallAccessor: 'status',
    tournamentAccessor: 'status',
    size: 2,
    common: true
  },
  {
    overAllLabel: 'Scolia Id',
    tournamentLabel: 'Scolia Id',
    key: 'scoliaId',
    overallAccessor: 'scoliaId',
    tournamentAccessor: 'scoliaId',
    size: 2,
    common: true
  },
  {
    overAllLabel: 'TP',
    tournamentLabel: 'Won prize',
    key: 'tournamentPoints',
    overallAccessor: 'overallStats.tournamentPoints',
    tournamentAccessor: 'tournamentStats.tournamentPoints',
    size: 2,
    common: true
  },
  {
    overAllLabel: 'Overall average',
    tournamentLabel: 'Tournament average',
    label: 'Average',
    key: 'average',
    overallAccessor: 'overallStats.average',
    tournamentAccessor: 'tournamentStats.average.value',
    size: 2,
    common: true
  },
  {
    overAllLabel: 'Games played',
    tournamentLabel: 'Games won',
    key: 'games',
    overallAccessor: 'overallStats.playedGames',
    tournamentAccessor: 'tournamentStats.wonGames',
    size: 2,
    common: true
  }
]