import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { List, Show } from './components'

const PrivateTournaments = ({ match: { url } }) => {
  return (
    <Switch>
      <Route
        component={List}
        exact
        name="Tournament list"
        path={`${url}/`}
      />
      <Route
        component={Show}
        exact
        name="Tournament info"
        path={`${url}/:tournamentId`}
      />
    </Switch>
  );
}

export default PrivateTournaments;
