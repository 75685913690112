import { call, put } from 'redux-saga/effects';
import store from 'store2';

import { AUTHORIZATION_TOKEN_EXPIRED } from 'constants/ApiErrors';
// import { navigateToSaga } from 'services/history';
import { actions as sessionActions } from 'services/session';

export default function* callApi(apiCall, ...payload) {
  try {
    return yield apiCall(...payload);
  } catch (e) {
    if (e.status === 401) {
      yield call([store, store.remove], 'token');
      yield put(
        sessionActions.logInFailure({ error: AUTHORIZATION_TOKEN_EXPIRED })
      );
      yield put(sessionActions.logOut());
    } else if (e.status === 403) {
      // TODO
      // yield call(navigateToSaga, '/forbidden');
    } else if ([404].includes(e.status)) {
      // TODO
      // yield call(navigateToSaga, '/page-not-found');
      // yield put(
      //   notifyError(
      //     'The requested resource could not be found but may be available in the future.'
      //   )
      // );
    } else {
      // TODO handle every other error status codes...
      // yield put(notifyError(e.data.error));
    }

    throw e;
  }
}
