import { createAction } from 'redux-actions';

export const updateUser = createAction('USER_UPDATE_REQUEST');
export const updateUserFailure = createAction('USER_UPDATE_FAILURE');
export const updateUserSuccess = createAction('USER_UPDATE_SUCCESS');

export default {
  updateUser,
  updateUserFailure,
  updateUserSuccess,
};
