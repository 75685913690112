import { call, put, takeLatest } from 'redux-saga/effects';

import { ServiceAccounts } from 'services/api';

import {
  getServiceAccounts,
  getServiceAccountsFailure,
  getServiceAccountsSuccess,
} from 'scenes/ServiceAccounts/components/List/actions';
import { callApi } from 'services/rest';

function* watchGetServiceAccounts({ payload }) {
  try {
    const { data } = yield call(callApi, ServiceAccounts.index, payload);
    yield put(getServiceAccountsSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(getServiceAccountsFailure());
  }
}

// It must be wrapped in a function, so execution will happen only on function call
export default () => takeLatest(getServiceAccounts, watchGetServiceAccounts);
