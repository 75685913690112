import { call, put, takeLatest } from 'redux-saga/effects';

import { Tournaments } from 'services/api';

import { browserHistory } from 'services/history';
import { callApi } from 'services/rest';
import { notifyError, notifySuccess } from 'services/notification/actions';

import {
  deleteTournament,
  deleteTournamentFailure,
  deleteTournamentSuccess,
} from 'scenes/Tournaments/components/Show/actions';

function* watchDeleteTournament({ payload }) {

  try {
    yield call(callApi, Tournaments.delete, `${payload}`);
    yield put(deleteTournamentSuccess());
    yield put(notifySuccess('Tournament was deleted successfully.'))
    browserHistory.push(`/tournaments`);
  } catch (e) {
    console.log(e);
    yield put(deleteTournamentFailure());
    yield put(notifyError('Failed to delete tournament'))
  }

}

export default () => takeLatest(deleteTournament, watchDeleteTournament);