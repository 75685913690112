import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Image extends Component {
  handleImageLoadedEvent = e => window.URL.revokeObjectURL(e.target.src);

  render() {
    const { blob, idx } = this.props;

    return (
      <img
        src={blob}
        alt={`stream_${idx}`}
        onLoad={this.handleImageLoadedEvent}
      />
    );
  }
}

Image.propTypes = {
  blob: PropTypes.string.isRequired,
  idx: PropTypes.number.isRequired,
};

export default Image;
