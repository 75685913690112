import { createAction } from 'redux-actions';

export const getUser = createAction('USER_GET_REQUEST', (userId, queryParams) => ({
  userId,
  queryParams,
}));
export const getUserFailure = createAction('USER_GET_FAILURE');
export const getUserSuccess = createAction('USER_GET_SUCCESS');
export const resetUser = createAction('RESET_USER');

export const getUserBoards = createAction('USER_BOARDS_GET_REQUEST');
export const getUserBoardsSuccess = createAction('USER_BOARDS_GET_SUCCESS');
export const getUserBoardsFailure = createAction('USER_BOARDS_GET_FAILURE');

export default {
  getUser,
  getUserFailure,
  getUserSuccess,
  resetUser,
  getUserBoards,
  getUserBoardsSuccess,
  getUserBoardsFailure,
};
