import React, { useState } from 'react'
import PropTypes from 'prop-types'
import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html'
import DOMPurify from 'dompurify';

import { Editor as RichTextEditor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import { SAnimate, SError } from 'ScoliaComponents'

import { toolbarConfig } from './contants'

import styles from './styles.module.scss'
import './react-draft-wysiwyg-override.scss'

const textToEditorState = text => {
  const { contentBlocks, entityMap } = htmlToDraft(text)
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)

  return EditorState.createWithContent(contentState)
}

const hashConfig = {
  trigger: '#',
  separator: ' ',
}

const Editor = ({ value, name, onChange, error, touched, onBlur }) => {
  const [editorState, setEditorState] = useState(textToEditorState(value))

  const handleEditorStateChange = (editorState) => {
    const rawContentState = convertToRaw(editorState.getCurrentContent())
    const markup = draftToHtml(rawContentState, hashConfig)

    const safeHTML = DOMPurify.sanitize(markup, { ADD_ATTR: ['target'] });

    setEditorState(editorState)
    onChange({ target: { name, value: safeHTML } })
  }

  const handleBlur = (_event) => {
    onBlur({ target: { name, value } })
  }

  return (
    <div className="astro">
      <RichTextEditor
        name={name}
        placeholder="Enter description"
        editorState={editorState}
        onEditorStateChange={handleEditorStateChange}
        onBlur={handleBlur}
        stripPastedStyles={false}
        toolbar={toolbarConfig}
        spellCheck
        editorClassName={styles.richTextField}
        wrapperClassName={styles.rickTextEditorWrapper}
        handlePastedText={() => false}
      />
      <SAnimate in={!!error && touched}>
        <SError>{error}</SError>
      </SAnimate>
    </div>
  )
}

Editor.displayName = 'Editor'

Editor.propTypes = {
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  touched: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
}

export default Editor