import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import { Link } from 'react-router-dom';

import { getIsAdmin } from 'services/session/selectors';

import actions from './actions';

class List extends Component {
  componentDidMount() {
    this.props.dispatch(actions.getHardwares());
  }

  handleRefreshButtonClick = _e => this.props.dispatch(actions.getHardwares());

  formatDate = date => {
    var d = new Date(date);
    var date_format_str =
      d.getFullYear().toString() +
      '-' +
      ((d.getMonth() + 1).toString().length === 2
        ? (d.getMonth() + 1).toString()
        : '0' + (d.getMonth() + 1).toString()) +
      '-' +
      (d.getDate().toString().length === 2
        ? d.getDate().toString()
        : '0' + d.getDate().toString());
    return date ? date_format_str : '-';
  };

  get hardwareList() {
    const { hardwareList, isLoading } = this.props;

    if (isLoading) {
      return null;
    }

    return Object.keys(hardwareList).map((hardwareId, idx) => {
      const hardware = hardwareList[hardwareId];
      return (
        <tr key={Object.getOwnPropertyNames(hardwareList)[idx]}>
          <td>
            <Link to={`/hardwares/${hardware.id}`}>
              {hardware.versionNumber}
            </Link>
          </td>
          <td>{this.formatDate(hardware.releasedAt)}</td>
          <td>{hardware.description}</td>
        </tr>
      );
    });
  }

  render() {
    const { isLoading, isAdmin } = this.props;

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" lg={{ size: 10, offset: 1 }}>
            <Card color="white">
              <CardHeader>
                <div className="card-header__left">
                  <i className="fa fa-cogs" /> Hardware versions
                  {isLoading && <i className="fa fa-spinner fa-spin fa-fw" />}
                </div>
                <div className="card-header__right">
                  {isAdmin && <Link to="/hardwares/new">
                    <button className="btn btn-primary btn-md" type="button">
                      Create new hardware
                    </button>
                  </Link>}
                  <button
                    className="btn btn-success btn-md ms-1"
                    disabled={isLoading}
                    onClick={this.handleRefreshButtonClick}
                  >
                    <i className="cui-reload" />
                  </button>
                </div>
              </CardHeader>

              <CardBody>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Version</th>
                      <th>Release Date</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>{this.hardwareList}</tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

List.displayName = 'Hardwares.List';

const mapStateToProps = state => ({
  hardwareList: state.data.hardwares,
  isLoading: state.scenes.hardwares.list.isLoading,
  isAdmin: getIsAdmin(state),
});

export default connect(mapStateToProps)(List);
