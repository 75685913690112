import { createAction } from 'redux-actions';

export const fetchConfiguration = createAction('CONFIGURATION_FETCH_REQUEST');
export const fetchConfigurationFailure = createAction('CONFIGURATION_FETCH_FAILURE');
export const fetchConfigurationSuccess = createAction('CONFIGURATION_FETCH_SUCCESS');
export const fetchManagementConfigurationSuccess = createAction(
  'CONFIGURATION_MANAGEMENT_FETCH_SUCCESS'
);

export const updateConfiguration = createAction(
  'UPDATE_CONFIGURATION_REQUEST',
  (payload) => payload,
  (_payload, meta) => meta
);

export const updateConfigurationSuccess = createAction('UPDATE_CONFIGURATION_SUCCESS');
export const updateConfigurationFailure = createAction('UPDATE_CONFIGURATION_FAILURE');

export default {
  fetchConfiguration,
  fetchConfigurationFailure,
  fetchConfigurationSuccess,
  fetchManagementConfigurationSuccess,
  updateConfiguration,
  updateConfigurationSuccess,
  updateConfigurationFailure,
};
