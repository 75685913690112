import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { isNull } from 'lodash';
import { notifyError, notifySuccess } from 'services/notification';
import { Configuration } from 'services/api';
import TableRow from 'components/TableRow';
export const LiveBoardViewEdit = ({ config }) => {
    const dispatch = useDispatch();
    const validationSchema = {
        enabledOnOlineGames: Yup.boolean()
            .required('Enabled on online games field is required'),
        enabledOnScoliaTournaments: Yup.boolean()
            .required('Enabled on Scolia Tournaments field is required'),
        enabledOnPrivateTournaments: Yup.boolean()
            .required('Enabled on Private Tournaments field is required'),
        framePerSeconds: Yup.number()
            .required('Frame per seconds field is required'),
        quality: Yup.number()
            .required('Stream quality field is required')
            .min(1)
            .max(100),
        scale: Yup.number()
            .required('Stream scale field is required')
            .min(14)
            .max(50),
    };
    const handleSubmit = async (values) => {
        try {
            if (values.createdAt) {
                delete values.createdAt;
            }
            if (values.type) {
                delete values.type;
            }
            await Configuration.update({
                liveBoardViewConfiguration: values
            });
            dispatch(notifySuccess('Live board view streaming configuration have been updated successfully.'));
        }
        catch (e) {
            console.error(e);
            dispatch(notifyError('Couldn\'t modify live board view streaming configuration.'));
        }
    };
    const FrameOptions = useMemo(() => {
        return [
            { label: '1', value: 1 },
            { label: '2', value: 2 },
            { label: '3', value: 3 },
            { label: '4', value: 4 },
            { label: '5', value: 5 },
            { label: '10', value: 10 },
        ];
    }, []);
    return (React.createElement(Formik, { initialValues: config, validationSchema: validationSchema, onSubmit: handleSubmit }, ({ errors, values, handleBlur, handleChange, handleSubmit, setFieldValue }) => (React.createElement("form", { onSubmit: handleSubmit },
        React.createElement(TableRow.Checkbox, { label: "Enabled on Online games", labelClass: "col-sm-3", valueClass: "col-sm-9 d-flex", isLabelFormatted: false, name: "enabledOnOnlineGames", value: values.enabledOnOnlineGames, onBlur: handleBlur, onChange: handleChange, isDisabled // TODO, and remove descriptionText when done
            : true, descriptionText: 'This feature is not available yet' }),
        React.createElement(TableRow.Checkbox, { label: "Enabled on Scolia Tournaments", labelClass: "col-sm-3", valueClass: "col-sm-9 d-flex", isLabelFormatted: false, name: "enabledOnScoliaTournaments", value: values.enabledOnScoliaTournaments, onBlur: handleBlur, onChange: handleChange, isDisabled // TODO, and remove descriptionText when done
            : true, descriptionText: 'This feature is not available yet' }),
        React.createElement(TableRow.Checkbox, { label: "Enabled on Private Tournaments", labelClass: "col-sm-3", valueClass: "col-sm-9 d-flex", isLabelFormatted: false, name: "enabledOnPrivateTournaments", value: values.enabledOnPrivateTournaments, onBlur: handleBlur, onChange: handleChange, isDisabled // TODO, and remove descriptionText when done
            : true, descriptionText: 'This feature is not available yet' }),
        React.createElement(TableRow.Select, { label: "Frames per second", labelClass: "col-sm-3", valueClass: "col-sm-9", name: "streamingProperties.framePerSeconds", value: values.streamingProperties.framesPerSecond, onChange: (option) => {
                if (!isNull(option)) {
                    setFieldValue('streamingProperties.framesPerSecond', option.value);
                }
            }, options: FrameOptions }),
        React.createElement(TableRow.Slider, { label: "Quality", labelClass: "col-sm-3", valueClass: "col-sm-9 d-flex flex-column", name: "streamingProperties.quality", value: values.streamingProperties.quality, onChange: handleChange, min: 1, max: 100, helperText: `Quality (${values.streamingProperties.quality}%)` }),
        React.createElement(TableRow.Slider, { label: "Scale", labelClass: "col-sm-3", valueClass: "col-sm-9 d-flex flex-column", name: "streamingProperties.scale", value: values.streamingProperties.scale, onChange: handleChange, min: 14, max: 50, helperText: `Scale (${values.streamingProperties.scale} => ${Math.round(720 * (values.streamingProperties.scale / 100))}px)` }),
        React.createElement("div", { className: "action-footer" },
            React.createElement("button", { type: "submit", className: "btn btn-success btn-md", disabled: !!Object.keys(errors).length }, "Save config"))))));
};
