import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { has } from 'lodash-es';
import cn from 'classnames';

import WebSocketConnections from './components/WebSocketConnections/WebSocketConnections';
import Rooms from './components/Rooms/Rooms';
import LobbyParticipants from './components/LobbyParticipants/LobbyParticipants';
import LobbyInvitations from './components/LobbyInvitations/LobbyInvitations';

import { deleteWSConnection, deleteLobbyInvitation } from 'scenes/StoreSnapshot/components/actions';

import { getIsAdmin } from 'services/session/selectors';

import {
  getRooms,
  getConnections,
  getLobbyParticipants,
} from '../../selectors';

import styles from './styles.module.scss';

const tabs = [
  {
    label: 'Websocket Connections',
    id: 'ws_tab',
  },
  {
    label: 'Rooms',
    id: 'rooms_tab',
  },
  {
    label: 'Lobby Participants',
    id: 'lobby_participants_tab',
  },
  {
    label: 'Lobby Invitations',
    id: 'lobby_invitations_tab',
  },
];

class StoreTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: props.defaultTab || tabs[1].id,
    };
  }

  handleTabSelect = e => {
    this.setState({ ...this.state, activeTab: e.target.id });
  };

  handleDeleteWSConnection = (wsConnectionEntity) => {
    // Is it a Service Account connection or not?
    const socketId = ['wsId', 'serialNumber', 'boardId'].every(key => has(wsConnectionEntity, key)) ? wsConnectionEntity.wsId : wsConnectionEntity;

    const result = window.confirm(`Are you sure you want to delete socket connection: ${socketId} ?`);
    if (result) {
      this.props.dispatch(deleteWSConnection(socketId));
    }
  }

  handleDeleteLobbyInvitation = (id) => {
    const result = window.confirm(`Are you sure you want to delete lobby invitation: ${id} ?`);
    if (result) {
      this.props.dispatch(deleteLobbyInvitation(id));
    }
  }

  render() {
    const [wsTab, roomsTab, lobbyParticipantsTab, lobbyInvitationsTab] = tabs;
    const { isLoading, connections, room, lobbyParticipants, lobbyInvitations, isAdmin } = this.props;

    return (
      <Row>
        <Col  xl="6">
          <div className={styles.wrapper}>
            <div className={styles.tabsWrapper}>
              <div
                className={cn([
                  styles.tab,
                  styles.active,
                  styles.fullBorderRadius,
                ])}
                key={wsTab.id}
                id={wsTab.id}
              >
                {wsTab.label}
              </div>
            </div>

            <div className={styles.tabsContentWrapper}>
              <div className={styles.filterLabelWrapper}>
                <div className={styles.filterLabel}>Toggle Filters</div>
                <div className={styles.filterLabel}>Toggle Accordions</div>
              </div>
              <div className={cn([styles.tabContent, styles.tabContentActive])}>
                <WebSocketConnections {...connections} isLoading={isLoading} isAdmin={isAdmin} handleDelete={this.handleDeleteWSConnection}/>
              </div>
            </div>
          </div>
        </Col>
        <Col xl="6">
          <div className={styles.wrapper}>
            <div className={styles.tabsWrapper}>
              {tabs.filter(tab => tab !== wsTab).map(tab => (
                <div
                  className={cn([
                    styles.tab,
                    {
                      [styles.active]: this.state.activeTab === tab.id,
                    },
                  ])}
                  key={tab.id}
                  id={tab.id}
                  onClick={this.handleTabSelect}
                >
                  {tab.label}
                </div>
              ))}
            </div>

            <div className={styles.tabsContentWrapper}>
              <div className={styles.filterLabelWrapper}>
                <div className={styles.filterLabel}>Toggle Filters</div>
                <div className={styles.filterLabel}>Toggle Accordions</div>
              </div>
              <div
                className={cn([
                  styles.tabContent,
                  {
                    [styles.tabContentActive]:
                      this.state.activeTab === roomsTab.id,
                  },
                ])}
              >
                <Rooms {...room} isLoading={isLoading} />
              </div>

              <div
                className={cn([
                  styles.tabContent,
                  {
                    [styles.tabContentActive]:
                      this.state.activeTab === lobbyParticipantsTab.id,
                  },
                ])}
              >
                <LobbyParticipants
                  lobbyParticipants={lobbyParticipants}
                  isLoading={isLoading}
                />
              </div>
              <div
                className={cn([
                  styles.tabContent,
                  {
                    [styles.tabContentActive]:
                      this.state.activeTab === lobbyInvitationsTab.id,
                  },
                ])}
              >
                <LobbyInvitations lobbyInvitations={lobbyInvitations} isLoading={isLoading} handleDelete={this.handleDeleteLobbyInvitation} />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

StoreTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  store: PropTypes.object.isRequired,
  room: PropTypes.object.isRequired,
  connections: PropTypes.object.isRequired,
  lobbyParticipants: PropTypes.array.isRequired,
  lobbyInvitations: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
};

const mapStateToProps = state => ({
  isLoading: state.scenes.storeSnapshot.isLoading,
  store: state.data.storeSnapshot.store,
  room: getRooms(state.data.storeSnapshot),
  connections: getConnections(state.data.storeSnapshot),
  lobbyParticipants: getLobbyParticipants(state.data.storeSnapshot),
  lobbyInvitations: state.data.storeSnapshot.lobbyInvitations,
  isAdmin: getIsAdmin(state),
});

export default connect(mapStateToProps)(StoreTable);
