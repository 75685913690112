import React from 'react';
import { isNull } from 'lodash';
import { TournamentResultLabels } from 'constants/Tournament';
import { SInput, SScrollContainer } from 'ScoliaComponents';
import { ThemesEnum } from 'enums';
import { HeaderTitle } from '../../HeaderTitle';
import { PrizePoolPlaceholders } from './constants';
import { getAllowedPrizePoolsByParticipants } from 'utils/tournamentUtils';
import styles from './styles.module.scss';
export const PrizePool = ({ title, onBack, values, onChange }) => {
    const allowedPlaces = getAllowedPrizePoolsByParticipants(values.maxParticipants);
    return (React.createElement(SScrollContainer, null,
        !isNull(title) && (React.createElement(HeaderTitle, { title: title, onBack: onBack })),
        React.createElement("span", { className: styles.subtitle }, "Share details about the prizes with the players."),
        TournamentResultLabels.map(({ prizeKey, label }) => {
            if (!allowedPlaces.includes(prizeKey)) {
                return null;
            }
            return (React.createElement("div", { className: styles.inputContainer, key: prizeKey },
                React.createElement(SInput, { label: label, value: values.customPrizes[prizeKey], name: `customPrizes.${prizeKey}`, onChange: onChange, maxLength: 30, placeholder: PrizePoolPlaceholders[prizeKey], theme: ThemesEnum.Light })));
        })));
};
PrizePool.displayName = 'PrizePool';
export default PrizePool;
