import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import { actions as storeSnapshotActions } from 'scenes/StoreSnapshot';
import { camelCase } from 'lodash-es';

const INITIAL_STATE = {
  store: {},
  connections: {},
  room: {},
  lobbyParticipants: [],
  lobbyInvitations: [],
};

const storeSnapshotReducer = handleActions(
  {
    [storeSnapshotActions.getStoreSnapshotSuccess]: (state, { payload: { store, connections, room, lobbyParticipants, pendingInvitations } }) =>
      update(state, {
        store: {
          $set: store,
        },
        connections: {
          $set: Object.entries(connections).reduce((acc,curr)=>{
            acc[`${camelCase(curr[0])}`] = curr[1]
            return acc;
          },{}),
        },
        room: {
          $set: room,
        },
        lobbyParticipants: {
          $set: lobbyParticipants
        },
        lobbyInvitations:{
          $set: pendingInvitations
        },
      }),
  },
  INITIAL_STATE
);

export default storeSnapshotReducer;
