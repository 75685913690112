import { createAction } from 'redux-actions';

export const getUserTokenHistory = createAction('USER_TOKEN_HISTORY_REQUEST');
export const getUserTokenHistoryFailure = createAction('USER_TOKEN_HISTORY_FAILURE');
export const getUserTokenHistorySuccess = createAction('USER_TOKEN_HISTORY_SUCCESS');
export const resetUserTokenHistory = createAction('USER_TOKEN_HISTORY_RESET');

export default {
  getUserTokenHistory,
  getUserTokenHistoryFailure,
  getUserTokenHistorySuccess,
  resetUserTokenHistory,
}