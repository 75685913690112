import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Nav, NavbarBrand, NavbarToggler } from 'reactstrap';

import { HeaderDropdown } from './components/HeaderDropdown';

import { actions as sessionActions } from 'services/session';

class Header extends Component {
  sidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-hidden');
  }

  sidebarMinimize(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-minimized');
  }

  mobileSidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-mobile-show');
  }

  asideToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('aside-menu-hidden');
  }

  handleLogoutClick = e => {
    this.props.dispatch(sessionActions.logOut(e));
  };

  render() {
    const { connectionStatus } = this.props;

    return (
      <header className="app-header navbar">
        <NavbarToggler className="d-lg-none" onClick={this.mobileSidebarToggle}>
          <span className="navbar-toggler-icon" />
        </NavbarToggler>
        <NavbarBrand href="/dashboard" />
        <NavbarToggler className="d-md-down-none" onClick={this.sidebarToggle}>
          <span className="navbar-toggler-icon" />
        </NavbarToggler>

        <Nav className="ms-auto" navbar>
          <HeaderDropdown
            connectionStatus={connectionStatus}
            onLogoutClick={this.handleLogoutClick}
          />
        </Nav>
      </header>
    );
  }
}

const mapStateToProps = state => ({
  connectionStatus: state.ws.status,
});

export default compose(
  connect(
    mapStateToProps,
    null
  )
)(Header);
