export const ScoreTypes = {
  RaceTo: 'RaceTo',
  BestOf: 'BestOf',
}

export const Temp_ScoreTypeOptions = [
  {
    value: ScoreTypes.RaceTo,
    label: 'Race to'
  },
  {
    value: ScoreTypes.BestOf,
    label: 'Best of'
  },
];
