import { all } from 'redux-saga/effects';

import { sagas as createSaga } from 'scenes/Manufacturers/components/Create';
import { sagas as editSaga } from 'scenes/Manufacturers/components/Edit';
import { sagas as listSaga } from 'scenes/Manufacturers/components/List';
import { sagas as showSaga } from 'scenes/Manufacturers/components/Show';

export default function* manufacturersSaga() {
  yield all([ listSaga(), createSaga(), showSaga(), editSaga() ]);
}
