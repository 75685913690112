import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import { actions as systemUsageActions } from 'scenes/SystemUsages/components/SystemUsage';
import { actions as systemAnalyticsActions } from 'scenes/SystemUsages/components/SystemAnalytics';

const INITIAL_STATE = {
  users: {
    total: 0,
    isConfirmed: 0,
    isSubscribed: 0,
    attachedWithGoogle: 0,
    attachedWithFacebook: 0,
  },
  boards: {
    total: 0,
    public: 0,
    private: 0,
    groupedByStatus: {
      inStock: 0,
      readyToActivate: 0,
      attached: 0,
      detached: 0,
      suspended: 0,
    },
  },
  boardStatistics: {
    general: {
      throwsCount: {
        addedByGs: 0,
        automaticallyDetected: 0,
        manuallyAdded: 0,
        modified: 0,
        total: 0,
      },
      gamesCount: {
        total: 0,
        finished: 0,
        aborted: 0,
        forsaken: 0,
      },
    },
    x01: {
      170: 0,
      301: 0,
      501: 0,
      custom: 0,
      total: 0,
      raceTo: 0,
      bestOf: 0,
      singleOut: 0,
      doubleOut: 0,
    },
    aroundTheClock: {
      total: 0,
      singleMultiplier: 0,
      doubleMultiplier: 0,
      tripleMultiplier: 0,
      oneToBullOrder: 0,
      bullToOneOrder: 0,
      randomOrder: 0,
    },
  },
  systemUsage: {
    data: [],
    dataSum: {
      totalThrowsSum: 0,
      totalGamesSum: 0,
    },
  },
  systemAnalytics: {
    data: [],
  },
};

const systemUsagesReducer = handleActions(
  {
    [systemUsageActions.getSystemUsageSuccess]: (
      state,
      { payload: { data, dataSum } }
    ) => {
      return update(state, {
        systemUsage: {
          data: { $set: data },
          dataSum: { $set: dataSum },
        },
      });
    },

    [systemAnalyticsActions.getSystemAnalyticsSuccess]: (
      state,
      { payload: { data } }
    ) => {
      return update(state, {
        systemAnalytics: {
          data: { $set: data },

        },
      });
    },
  },
  INITIAL_STATE
);

export default systemUsagesReducer;
