import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import { getIsAdmin } from 'services/session/selectors';

import { getManufacturer } from 'scenes/Manufacturers/actions';
import { ManufacturerForm } from 'scenes/Manufacturers/components/ManufacturerForm';

import actions from './actions';

class Edit extends Component {
  componentDidMount() {
    this.props.dispatch(getManufacturer(this.manufacturerId));
  }

  handleFormSubmit = ({ ...manufacturerProperties }, _actions) => {
    this.props.dispatch(
      actions.updateManufacturer({
        manufacturerId: this.manufacturerId,
        ...manufacturerProperties,
      })
    );
  };

  get manufacturerId() {
    return this.props.match.params.manufacturerId;
  }
  render() {
    const { manufacturer, updateError, isLoading, isUpdateSuccessful, isAdmin } = this.props;

    if (!manufacturer || isLoading) {
      return <i className="fa fa-spinner fa-spin fa-fw" />;
    }

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" lg={{ size: 10, offset: 1 }}>
            <Card color="white">
              <CardHeader>
                <div className="card-header__left">
                  <i className="fa fa-align-justify" /> Edit Manufacturer
                </div>
              </CardHeader>
              <CardBody>
                <ManufacturerForm
                  manufacturer={manufacturer}
                  onSubmit={this.handleFormSubmit}
                  isSuccessful={isUpdateSuccessful}
                  formError={updateError}
                  isEdit
                  isAdmin={isAdmin}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

Edit.displayName = 'Manufactures.Edit';

const mapStateToProps = (state, ownProps) => ({
  isUpdateSuccessful: state.scenes.manufacturers.edit.isUpdateSuccessful,
  updateError: state.scenes.manufacturers.edit.updateError,
  isLoading: state.scenes.manufacturers.edit.isLoading,
  manufacturer: state.data.manufacturers[ownProps.match.params.manufacturerId],
  isAdmin: getIsAdmin(state),
});

export default connect(mapStateToProps)(Edit);
