import { all } from 'redux-saga/effects';

import { sagas as listSaga } from 'scenes/Platforms/components/List';
import { sagas as createSaga } from 'scenes/Platforms/components/Create';
import { sagas as editSaga } from 'scenes/Platforms/components/Edit';
import { sagas as showSaga } from 'scenes/Platforms/components/Show';

export default function* platformsSaga() {
  yield all([listSaga(), createSaga(), editSaga(), showSaga()]);
}
