import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Table } from 'reactstrap';
import cn from 'classnames';
import Button from 'components/TableRow/Button';
import { formatDate } from 'utils';
import styles from './styles.module.scss';
export function BanHistory({ banHistory }) {
    const [pageNumber, setPageNumber] = useState(1);
    const incrementPageNumber = () => {
        setPageNumber((pageNumber) => pageNumber + 1);
    };
    return (React.createElement("div", null,
        React.createElement(Card, null,
            React.createElement(CardHeader, null,
                React.createElement("div", { className: "card-header__left" },
                    React.createElement("i", { className: "fa fa-align-justify" }),
                    " Ban History")),
            React.createElement(CardBody, null,
                React.createElement("div", { className: cn(styles.banHistoryTable, { [styles.isScrollable]: pageNumber > 1 }) },
                    React.createElement(Table, { responsive: true },
                        React.createElement("thead", null,
                            React.createElement("tr", null,
                                React.createElement("th", null, "Ban issued"),
                                React.createElement("th", null, "Ban start"),
                                React.createElement("th", null, "Ban end"))),
                        React.createElement("tbody", null, banHistory
                            .slice(0, pageNumber * 10)
                            .map(({ banIssueDate, bannedFrom, bannedTo }) => (React.createElement("tr", { key: `ban_${banIssueDate.toString()}` },
                            React.createElement("td", null, formatDate(banIssueDate, true)),
                            React.createElement("td", null, formatDate(bannedFrom, true)),
                            React.createElement("td", null, formatDate(bannedTo, true))))))),
                    banHistory.length - pageNumber * 10 > 0 && (React.createElement(Button, { isDisabled: false, onClick: incrementPageNumber, btnLabel: "Show more" })))))));
}
