import { all } from 'redux-saga/effects';

import { sagas as listSaga } from 'scenes/ServiceAccounts/components/List';
import { sagas as showSaga } from 'scenes/ServiceAccounts/components/Show';
import { sagas as createSaga } from 'scenes/ServiceAccounts/components/Create';
import { sagas as editSaga } from 'scenes/ServiceAccounts/components/Edit';

export default function* serviceAccountsSaga() {
  yield all([listSaga(), showSaga(), createSaga(), editSaga()]);
}
