import { createAction } from 'redux-actions';

export const subscribeToPrivateTournamentUpdates = createAction('SUBSCRIBE_TO_PRIVATE_TOURNAMENT_UPDATES');
export const unsubscribeToPrivateTournamentUpdates = createAction('UNSUBSCRIBE_TO_PRIVATE_TOURNAMENT_UPDATES');

export const killPrivateTournament = createAction('PRIVATE_TOURNAMENT_KILL_REQUEST');
export const killPrivateTournamentFailure = createAction('PRIVATE_TOURNAMENT_KILL_FAILURE');
export const killPrivateTournamentSuccess = createAction('PRIVATE_TOURNAMENT_KILL_SUCCESS');

export const getPrivateTournament = createAction('PRIVATE_TOURNAMENT_GET_REQUEST');
export const getPrivateTournamentFailure = createAction('PRIVATE_TOURNAMENT_GET_FAILURE');
export const getPrivateTournamentSuccess = createAction('PRIVATE_TOURNAMENT_GET_SUCCESS');

export const updatePrivateTournamentMatch = createAction('PRIVATE_TOURNAMENT_UPDATE_MATCH');
export const changePrivateTournamentState = createAction('PRIVATE_TOURNAMENT_CHANGE_STATE');
export const updateParticipants = createAction('PRIVATE_TOURNAMENT_UPDATE_PARTICIPANTS');
export const updateResults = createAction('PRIVATE_TOURNAMENT_UPDATE_RESULTS');

export const clearPrivateTournament = createAction('PRIVATE_TOURNAMENT_CLEAR');

export default {
  subscribeToPrivateTournamentUpdates,
  unsubscribeToPrivateTournamentUpdates,
  killPrivateTournament,
  killPrivateTournamentFailure,
  killPrivateTournamentSuccess,
  getPrivateTournament,
  getPrivateTournamentFailure,
  getPrivateTournamentSuccess,
  clearPrivateTournament,
  updatePrivateTournamentMatch,
  changePrivateTournamentState,
  updateParticipants,
  updateResults,
};
