import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import { CollapseList } from 'scenes/StoreSnapshot/components/StoreTable/components/CollapseList';
import { ToggleFilter } from 'scenes/StoreSnapshot/components/StoreTable/components/ToggleFilter';

import { WebSocketConnection } from './components/WebSocketConnection';

import styles from './styles.module.scss';

const filters = [
  {
    id: 'client',
    label: 'Client',
  },
  {
    id: 'sbc',
    label: 'SBC',
  },
  {
    id: 'guest',
    label: 'Guest',
  },
  {
    id: 'serviceAccount',
    label: 'Service Account',
  },
  {
    id: 'total',
    label: 'Total',
    readonly: true,
    background: '#8252EA',
  },
];

const columns = [
  {
    label: 'Type',
    size: 2,
  },

  {
    label: 'ID',
    size: 5,
  },
  {
    label: 'Entity',
    size: 5,
  },
];

class WebSocketConnections extends Component {
  constructor(props) {
    super(props);
    this.wsConnectionsListRef = React.createRef();
    this.state = {
      allOpened: false,
    };

    // Filters initialization
    this.state = {
      ...this.state,
      ...filters.reduce(
        (acc, curr) => {
          acc.filters[curr.id] = true;
          return acc;
        },
        { filters: {} }
      ),
    };
  }

  toggleAll = () => {
    this.state.allOpened
      ? this.wsConnectionsListRef.current.closeAll()
      : this.wsConnectionsListRef.current.openAll();
    this.setState({ allOpened: !this.state.allOpened });
  };

  onCollapseListChange = () => {
    this.wsConnectionsListRef.current.isAllOpened() &&
      this.setState({ allOpened: true });
    this.wsConnectionsListRef.current.isAllClosed() &&
      this.setState({ allOpened: false });
  };

  get webSocketConnections() {
    const { client, guest, sbc, serviceAccount } = this.props;
    const {
      filters: {
        sbc: sbcFilter,
        client: clientFilter,
        guest: guestFilter,
        serviceAccount: serviceAccountFilter,
      },
    } = this.state;
    let webSocketConnetions = [];

    clientFilter && (webSocketConnetions = webSocketConnetions.concat(client));
    guestFilter && (webSocketConnetions = webSocketConnetions.concat(guest));
    sbcFilter && (webSocketConnetions = webSocketConnetions.concat(sbc));
    serviceAccountFilter &&
      (webSocketConnetions = webSocketConnetions.concat(serviceAccount));

    return webSocketConnetions;
  }

  get getWSConnectionsCount() {
    const { client, guest, sbc, serviceAccount } = this.props;

    const clientCount = Object.keys(client).length;
    const guestCount = Object.keys(guest).length;
    const sbcCount = Object.keys(sbc).length;
    const serviceAccountCount = Object.keys(serviceAccount).length;
    const total = parseInt(
      clientCount + guestCount + sbcCount + serviceAccount,
      10
    );

    return {
      client: clientCount,
      guest: guestCount,
      sbc: sbcCount,
      serviceAccount: serviceAccountCount,
      total,
    };
  }

  toggleFilter = filterId => {
    this.setState(
      update(this.state, {
        filters: {
          [filterId]: {
            $set: !this.state.filters[filterId],
          },
        },
      })
    );
  };

  render() {
    const { allOpened } = this.state;
    const { isLoading, handleDelete, isAdmin } = this.props;

    return (
      <div>
        <div className={styles.topbarWrapper}>
          <div className={styles.filterWrapper}>
            {filters.map(filter => (
              <ToggleFilter
                id={filter.id}
                key={filter.id}
                label={filter.label}
                count={this.getWSConnectionsCount[filter.id]}
                checked={this.state.filters[filter.id]}
                onToggleFilter={this.toggleFilter}
                readonly={filter.readonly}
                background={filter.background}
              />
            ))}
          </div>

          <div className={styles.accordionToggleButton}>
            <div onClick={this.toggleAll} className={styles.toggleButton}>
              {allOpened ? 'Close' : 'Open'} all
            </div>
          </div>
        </div>

        <div className={styles.tableHeader}>
          <Row>
            {columns.map(column => (
              <Col key={column.label} xs={column.size}>
                {column.label}
              </Col>
            ))}
          </Row>
        </div>

        {isLoading && (
          <div className={styles.spinnerWrapper}>
            <i className="fa fa-spinner fa-spin fa-fw" />
          </div>
        )}
        <div style={{ display: isLoading ? 'none' : 'block' }}>
          <CollapseList
            ref={this.wsConnectionsListRef}
            onChange={this.onCollapseListChange}
          >
            {this.webSocketConnections.map(wsConnection => (
              <WebSocketConnection key={wsConnection.id} {...wsConnection} handleDelete={handleDelete} isAdmin={isAdmin}/>
            ))}
          </CollapseList>
        </div>
      </div>
    );
  }
}

WebSocketConnections.propTypes = {
  client: PropTypes.array,
  guest: PropTypes.array,
  sbc: PropTypes.array,
  serviceAccount: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  handleDelete: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
};

WebSocketConnections.defaultProps = {
  client: [],
  guest: [],
  sbc: [],
  serviceAccount: [],
  isLoading: true,
  isAdmin: false,
};

export default WebSocketConnections;
