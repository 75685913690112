import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import actions from './actions';

const INITIAL_STATE = {};
const createReducer = handleActions(
  {
    [actions.createManufacturer]: (state) =>
      update(state, {
        isCreatePending: {
          $set: true
        },
        createError: {
          $set: ''
        }
      }),

    [actions.createManufacturerSuccess]: (state) =>
      update(state, {
        isCreatePending: {
          $set: false
        },
        isCreateSuccessful: {
          $set: true
        },
        createError: {
          $set: ''
        }
      }),

    [actions.createManufacturerFailure]: (state, { payload }) =>
      update(state, {
        isCreatePending: {
          $set: false
        },
        isCreateSuccessful: {
          $set: false
        },
        createError: {
          $set: payload
        }
      })
  },
  INITIAL_STATE
);

export default createReducer;
