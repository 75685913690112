import { call, put, takeLatest } from 'redux-saga/effects';

import { Hardwares } from 'services/api';

import { browserHistory } from 'services/history';

import {
  createHardware,
  createHardwareFailure,
  createHardwareSuccess,
} from 'scenes/Hardwares/components/Create/actions';
import { callApi } from 'services/rest';

function* watchCreateHardware({ payload: { hardware } }) {
  try {
    yield call(callApi, Hardwares.create, hardware);
    yield put(createHardwareSuccess());
    browserHistory.push('/hardwares');
  } catch (e) {
    if (!e.isFetch) {
      throw e;
    }
    yield put(createHardwareFailure(e.error));
  }
}

export default () => takeLatest(createHardware, watchCreateHardware);
