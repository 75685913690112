import { useState, useEffect } from 'react'

import { ParticipantMap } from 'components/TournamentWizard/components/Pages/Participants/TournamentParticipantInvite';
import { Participant } from 'interfaces';
import { isNull } from 'lodash';

export const useSortedList = (participantsOrder: string[], participantsByIds: ParticipantMap) => {

  const [keyList, setKeyList] = useState<string[]>(participantsOrder);
  const [isBottomTopOrdering, setBottomTopRendering] = useState<boolean>(false);
  const [orderingType, setOrderingType] = useState<string | null>(null);
  const [accessor, setAccessor] = useState<string | null>(null);

  const handleOrderClick = ({ key, accessor }: { key: string, accessor: string }) => {
    if (orderingType === key) {
      setBottomTopRendering(!isBottomTopOrdering);
    } else {
      setOrderingType(key);
      setAccessor(accessor);
    }
  };

  const handleReset = () => {
    setBottomTopRendering(false);
    setOrderingType(null);
  };

  useEffect(() => {
    if (!participantsOrder || participantsOrder?.length === 0 || !participantsByIds) {
      setKeyList([]);
      return;
    }

    if (!orderingType) {
      setKeyList(participantsOrder);
      return;
    }

    let newOrder = [...participantsOrder];
    newOrder.sort(function (a, b) {
      if (isNull(accessor)) {
        return 1
      }

      const accessors = accessor.split('.') as (keyof Participant)[];

      let first: any = participantsByIds[a];
      let second: any = participantsByIds[b];

      accessors.forEach((acc) => {
        first = first?.[acc] ?? first;
        second = second?.[acc] ?? second;
      });

      if (['nickname', 'scoliaId'].includes(orderingType)) {
        first = first?.toUpperCase ? first.toUpperCase() : 'N/A';
        second = second?.toUpperCase ? second?.toUpperCase() : 'N/A';
      }

      if (first < second) {
        return -1;
      }
      if (first > second) {
        return 1;
      }
      return 0;
    });

    if (isBottomTopOrdering) {
      newOrder = newOrder.reverse();
    }

    setKeyList(newOrder);
  }, [orderingType, participantsOrder, participantsByIds, isBottomTopOrdering, accessor]);

  return { keyList, orderingType, isBottomTopOrdering, handleOrderClick, handleReset };
};
