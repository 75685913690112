import React, { useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { MainData } from './components/MainData'
import { Prices } from './components/Prices'

import actions from './actions'
import selectors from './selectors'

const Show = ({ match: { params: { productId } } }) => {
  const dispatch = useDispatch();
  const { data, isLoading } = useSelector(selectors.getProductShowData);

  useEffect(() => {
    dispatch(actions.getProduct(productId));

    return () => {
      dispatch(actions.resetProduct());
    }
  }, [dispatch, productId]);

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" md="8" >
          <MainData isLoading={isLoading} {...data} />
        </Col>
        <Col xs="12" md="4">
          <Prices isLoading={isLoading} prices={data?.prices} />
        </Col>
      </Row>
    </div>
  )
}

Show.displayName = 'Show';

Show.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      productId: PropTypes.string,
    })
  })
}

export default Show