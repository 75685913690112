import { handleActions } from 'redux-actions';

import { actions as boardsActions } from 'scenes/Boards/components/List';

const INITIAL_STATE = [];

const countries = handleActions(
  {
    [boardsActions.getCountriesSuccess]: (_state, { payload }) => {
      return payload;
    },
  },
  INITIAL_STATE
);

export default countries;
