import * as Yup from 'yup';
import { checkWhitespaceOnly, checkWhitespaceStartEnd } from 'utils/checkWhitespace'

export const MAX_DESCRIPTION_LENGTH = 3000

function validateDescription(value) {
  function getTextContentFromMarkup(markup) {
    return new DOMParser()
      .parseFromString(markup, "text/html")
      .body
      .textContent;
  }

  //this returns the raw value of markup
  const text = getTextContentFromMarkup(value);
  return text.length <= MAX_DESCRIPTION_LENGTH;
}

const gameConfigSchema = {
  startScore: Yup.number()
    .required('Start score is required')
    .min(101, 'Must be min 101!')
    .max(9999, 'Must be max 9999!'),
  scoreType: Yup.string().required('Score type is required'),
  sets: Yup.number()
    .required('Sets number is required')
    .min(1, 'Must be min 1!')
    .max(15, 'Must be max 15!')
    .test('even-set-check', "Even sets are not allowed at 'Best of' type.", function(value) {
      const { scoreType } = this.parent;

      return scoreType === 'RaceTo' || value % 2 !== 0;
    }),
  legs: Yup.number()
    .required('Legs number is required')
    .min(1, 'Must be min 1!')
    .max(15, 'Must be max 15!')
    .test('even-leg-check', "Even legs are disabled at 'Best of' type.", function(value) {
      const { scoreType } = this.parent;

      return scoreType === 'RaceTo' || value % 2 !== 0;
    }),
  gameInType: Yup.string().required('Game-in type is required.'),
  gameOutType: Yup.string().required('Game-out type is required.'),
  firstToThrow: Yup.string().required('Player order definition is required.'),
  __type: Yup.string().required('Game type definition is required.'),
  startTimer: Yup.number()
    .positive()
    .integer()
    .min(1, 'Must be min 1!')
    .nullable(true)
    .transform((_, val) => (val === Number(val) ? val : null)),
  finishTimer: Yup.number()
    .positive()
    .integer()
    .min(1, 'Must be min 1!')
    .nullable(true)
    .transform((_, val) => (val === Number(val) ? val : null)),
  inactivityTimer: Yup.number()
    .positive()
    .integer()
    .min(1, 'Must be min 1!')
    .nullable(true)
    .transform((_, val) => (val === Number(val) ? val : null)),
};

const getTournamentCommonObjValidation = (partialSchema) => Yup.object().shape({
  ...partialSchema,
  name: Yup.string()
    .required('Name is required')
    .max(32, 'Must be max 32 characters long!')
    .test('white-space-free', 'Name is required and cannot contain only spaces!', checkWhitespaceOnly)
    .test('white-space-commence', 'Name cannot start or end with space!', checkWhitespaceStartEnd),
  description: Yup.string()
    .required('Description is required!')
    .test('description-length', `Must be max ${MAX_DESCRIPTION_LENGTH} characters long!`, validateDescription),
  registrationStartDate: Yup.date()
    .required('Registration start date is required')
    .test(
      "reg-start-date-compare",
      "Registration end date cannot be earlier than now.",
      function (value) {
        const { state } = this.parent;
        if( state ) return true;

        const now = new Date();
        const start = new Date(value);
        return start.getTime() >= now.getTime();
      }
    ),
  registrationEndDate: Yup.date()
    .required('Registration end date is required')
    .test(
      "registration_end_date_compare_test",
      "Registration end date must take place after registration start date.",
      function (value) {
        const { state } = this.parent;
        if( state ) return true;

        const { registrationStartDate } = this.parent;
        const end = new Date(value);
        return end.getTime() >= registrationStartDate.getTime();
      }
    ),
  tournamentStartDate: Yup.date()
    .required('Tournament start date is required')
    .test(
      "tournament_start_date_compare_test",
      "Registration end date must take place after (or at the same time as) registration end date.",
      function (value) {
        const { state } = this.parent;
        if( state ) return true;

        const { registrationEndDate } = this.parent;
        const end = new Date(value);
        return end.getTime() >= registrationEndDate.getTime();
      }
    ),
  maxParticipants: Yup.number()
    .required('Number of maximum participants is required')
    .min(4, 'Number of maximum participants can not be less than 4!')
    .max(256, 'Number of maximum participants can not be more than 256!')
    .test(
      "max_participants_compare_test",
      "Maximum participants number cannot be less than the minimum participants number",
      function (value) {
        const { minParticipants } = this.parent;
        return value >= minParticipants;
      }
    ),
  minParticipants: Yup.number()
    .required('Number of minimum participants is required')
    .min(4, 'Number of minimum participants must be min 4!')
    .max(256, 'Number of minimum participants must be max 256!')
    .test(
      'min-participants-compare-test',
      "Minimum participants number cannot be more than the maximum participants number.",
      function (value) {
        const { maxParticipants } = this.parent;
        return value <= maxParticipants;
      }
    ),
  gameConfig: Yup.object().shape({ ...gameConfigSchema }),
  variousGameConfig: Yup.object().shape({
    final: Yup.object().shape({ ...gameConfigSchema }),
    semiFinal: Yup.object().shape({ ...gameConfigSchema }),
    quarterFinal: Yup.object().shape({ ...gameConfigSchema }),
    top16: Yup.object().shape({ ...gameConfigSchema }),
    top32: Yup.object().shape({ ...gameConfigSchema }),
    top64: Yup.object().shape({ ...gameConfigSchema }),
    top128: Yup.object().shape({ ...gameConfigSchema }),
    top256: Yup.object().shape({ ...gameConfigSchema }),
  }),
  constraints: Yup.object().shape({
    recommended: Yup.object().shape({
      averageRange: Yup.object().shape({
        max: Yup.number()
          .required('Recommended maximum average is required.')
          .test(
            "recommended_max_average_compare_test",
            "Recommended maximum average can not be less than the recommended minimum average!",
            function (value) {
              if( this.parent?.min && value ) {
                return value >= this.parent?.min;
              }

              return true;
            }
          )
          .test(
            "recommended_max_average_value_test",
            "Recommended maximum average should match the recommended minimum average's range!",
            function (value) {
              if(
                ( value === 40 && (this.parent.min === 30 || this.parent.min === 0) ) ||
                ( value === 50 && (this.parent.min === 40 || this.parent.min === 0) ) ||
                ( value === 60 && (this.parent.min === 50 || this.parent.min === 0) ) ||
                ( value === 70 && this.parent.min === 60 ) ||
                ( value === 80 && this.parent.min === 70 ) ||
                ( value === 180 && (this.parent.min === 0 || this.parent.min === 50 || this.parent.min === 60 || this.parent.min === 80))
               ) {
                return true;
              }

              return false;
            }
          ),
        min: Yup.number()
          .required('Recommended minimum average is required')
          .test(
            "recommended_min_average_compare_test",
            "Recommended minimum average can not be more than the recommended maximum average!",
            function (value) {
              if( this.parent?.max && value ) {
                return value <= this.parent?.max;
              }

              return true;
            }
          )
          .test(
            "recommended_min_average_value_test",
            "Recommended minimum average should match the recommended maximum average's range!",
            function (value) {
              if(
                ( value === 0 && (this.parent.max === 40 || this.parent.max === 50 || this.parent.max === 60 || this.parent.max === 180)) ||
                ( value === 40 && this.parent.max === 50 ) ||
                ( value === 50 && (this.parent.max === 60 || this.parent.max === 180) ) ||
                ( value === 60 && (this.parent.max === 70 || this.parent.max === 180) ) ||
                ( value === 70 && this.parent.max === 80 ) ||
                ( value === 80 && this.parent.max === 180 )
               ) {
                return true;
              }

              return false;
            }
          ),
      })
    })
  }),
  organiser: Yup.object().shape({
    name: Yup.string()
      .required("Organiser name is required"),
    organiserId: Yup.string()
      .required("organiserId name is required")
      .test(
        "organiserId length validation",
        "Not correct organiserId!",
        function (value) {
          return value.length === 24;
        }
      ),
  }),
  structure: Yup.string()
    .required('Structure is required')
    .oneOf(['SingleKnockout']),
  participationType: Yup.string()
    .required('Participation type is required')
    .oneOf(['Online', 'Local', 'Hybrid']),
  entryFee: Yup.string()
    .required('Entry fee is required')
    .oneOf(['Free']),
  isScoreCorrectionAllowed: Yup.bool()
    .required('isScoreCorrectionAllowed is required'),
  registrationType: Yup.string()
    .required('Registration type is required')
    .oneOf(['Open', 'Invitational']),
  configType: Yup.string()
    .required('Configuration type is required')
    .oneOf(['Fixed', 'Various']),
  gameType: Yup.string()
    .required('Game type is required')
    .oneOf(['X01']),
  penalty: Yup.number()
    .min(0, 'Number of maximum participants can not be less than 0!'),
  organiserType: Yup.string()
    .required('Organiser type is required')
    .oneOf(['Scolia']),
});

const stagePrizesObjectValidation = Yup.object()
  .required() //yup don't allow to check any parent of a parent element during a test
  .test(      //so we check in the parent obj the values therefor we can compare with max participant num
    "winner_required_test",
    "Stage winner prizes are required",
    function (value) {
      if(value.winner) return true;
      return false;
    }
  )
  .test(
    "runner_up_required_test",
    "Stage runner up prizes are required",
    function (value) {
      if(value.runnerUp) return true;
      return false;
    }
  )
  .test(
    "semi_finalist_required_test",
    "Stage semi finalist prizes are required",
    function (value) {
      if(value.semiFinalists) return true;
      return false;
    }
  )
  .test(
    "top_8_stage_prize_required_test",
    "Stage top8 prizes are required",
    function (value) {
      const { maxParticipants } = this.parent;
      if(maxParticipants > 4 && !value.top8) return false;
      return true;
    }
  )
  .test(
    "top_16_stage_prize_required_test",
    "Stage top16 prizes are required",
    function (value) {
      const { maxParticipants } = this.parent;
      if(maxParticipants > 8 && !value.top16) return false;
      return true;
    }
  )
  .test(
    "top_32_stage_prize_required_test",
    "Stage top32 prizes are required",
    function (value) {
      const { maxParticipants } = this.parent;
      if(maxParticipants > 16 && !value.top32) return false;
      return true;
    }
  )
  .test(
    "top_64_stage_prize_required_test",
    "Stage top64 prizes are required",
    function (value) {
      const { maxParticipants } = this.parent;
      if(maxParticipants > 32 && !value.top64) return false;
      return true;
    }
  )
  .test(
    "top_128_stage_prize_required_test",
    "Stage top128 prizes are required",
    function (value) {
      const { maxParticipants } = this.parent;
      if(maxParticipants > 64 && !value.top128) return false;
      return true;
    }
  )
  .test(
    "top_256_stage_prize_required_test",
    "Stage top256 prizes are required",
    function (value) {
      const { maxParticipants } = this.parent;
      if(maxParticipants > 128 && !value.top256) return false;
      return true;
    }
  )

export const tournamentEntityValidationObject = getTournamentCommonObjValidation({
  initialPrizing: Yup.object().shape({
    stagePrizes: stagePrizesObjectValidation,
  }),
})

export const tournamentFormValidationObject = getTournamentCommonObjValidation({
  stagePrizes: stagePrizesObjectValidation,
})
