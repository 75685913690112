import { all } from 'redux-saga/effects';

import { sagas as listSaga } from 'scenes/Products/components/List';
import { sagas as showSaga } from 'scenes/Products/components/Show';

export default function* ProductsSaga() {
  yield all([
    listSaga(),
    showSaga(),
  ]);
}
