import { createSelector } from 'reselect';

import { BoardState } from 'constants/BoardState';

export const getBoardList = state => {
  const { __isStale, ...boards } = state.data.boards;

  if (__isStale) {
    return {};
    // Since Object.keys().length is checked in the component to determine
    // if the list should be fetched
  } else {
    return boards;
  }
};

export const getOnlineBoards = createSelector(
  getBoardList,
  boards =>
    Object.values(boards).length > 0
      ? Object.values(boards).filter(
          board => board.currentState && board.currentState.state === BoardState.Online.type
        )
      : {}
);

export const getOfflineBoards = createSelector(
  getBoardList,
  boards =>
    Object.values(boards).length > 0
      ? Object.values(boards).filter(
          board => board.currentState && board.currentState.state === BoardState.Offline.type
        )
      : {}
);

export const getInGameBoards = createSelector(
  getBoardList,
  boards =>
    Object.values(boards).length > 0
      ? Object.values(boards).filter(
          board => board.currentState && board.currentState.state === BoardState.InGame.type
        )
      : {}
);

export default {
  getBoardList,
  getOnlineBoards,
  getOfflineBoards,
  getInGameBoards,
};
