import { createAction } from 'redux-actions';

export const getServiceAccounts = createAction('SERVICE_ACCOUNTS_GET_REQUEST');

export const getServiceAccountsFailure = createAction(
  'SERVICE_ACCOUNTS_GET_FAILURE'
);
export const getServiceAccountsSuccess = createAction(
  'SERVICE_ACCOUNTS_GET_SUCCESS'
);

export default {
  getServiceAccounts,
  getServiceAccountsFailure,
  getServiceAccountsSuccess,
};
