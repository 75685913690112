import { call, put, takeLatest } from 'redux-saga/effects';

import { Firmwares } from 'services/api';
import { callApi } from 'services/rest';

import { browserHistory } from 'services/history';

import {
  updateFirmware,
  updateFirmwareFailure,
  updateFirmwareSuccess,
} from 'scenes/Firmwares/components/Edit/actions';

function* watchUpdateFirmware({ payload: { firmwareId, firmware } }) {
  try {
    const { data: updatedFirmware } = yield call(callApi, Firmwares.update, firmwareId, {
      isStable: firmware.isStable,
      compatibleHardware: firmware.compatibleHardware,
      compatiblePlatform: firmware.compatiblePlatform,
      description: firmware.description,
    });
    yield put(updateFirmwareSuccess(updatedFirmware));

    browserHistory.push(`/firmwares/${firmwareId}`);
  } catch (e) {
    if (!e.isFetch) {
      throw e;
    }
    if(e.data.error === 'DuplicateCompatibleHardwareWithVersionNumber')
      yield put(updateFirmwareFailure('Couldn\'t update firmware! Version number already exists.'));
    else yield put(updateFirmwareFailure('An error occurred'));  }
}

export default () => takeLatest(updateFirmware, watchUpdateFirmware);
