import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

import actions from './actions';

const INITIAL_STATE = null;

const configurationReducer = handleActions(
  {
    [actions.fetchConfigurationSuccess]: (state, { payload: config }) =>
      update(state, {
        $set: config,
      }),
  },
  INITIAL_STATE
);

export default configurationReducer;
