import { createAction } from 'redux-actions';

export const getBoard = createAction(
  'BOARD_GET_REQUEST',
  (boardId, queryParams, isLoading = true) => ({
    boardId,
    queryParams,
    isLoading,
  })
);
export const getBoardFailure = createAction('BOARD_GET_FAILURE');
export const getBoardSuccess = createAction('BOARD_GET_SUCCESS');

export const getBoardStatistics = createAction('BOARD_STATISTICS_GET_REQUEST');
export const getBoardStatisticsSuccess = createAction(
  'BOARD_STATISTICS_GET_SUCCESS'
);
export const getBoardStatisticsFailure = createAction(
  'BOARD_STATISTICS_GET_FAILURE'
);

export const getBoardSessions = createAction('BOARD_SESSIONS_GET_REQUEST');
export const getBoardSessionsSuccess = createAction(
  'BOARD_SESSIONS_GET_SUCCESS'
);
export const getBoardSessionsFailure = createAction(
  'BOARD_SESSIONS_GET_FAILURE'
);

export const getBoardCurrentState = createAction(
  'BOARD_CURRENT_STATE_GET_REQUEST'
);
export const getBoardCurrentStateSuccess = createAction(
  'BOARD_CURRENT_STATE_GET_SUCCESS'
);
export const getBoardCurrentStateFailure = createAction(
  'BOARD_CURRENT_STATE_GET_FAILURE'
);
export const getBoardLimitedThrows = createAction('BOARD_LIMITED_THROWS_GET');
export const getBoardLimitedThrowsSuccess = createAction('BOARD_LIMITED_THROWS_GET_SUCCESS');
export const getBoardLimitedThrowsFailure = createAction('BOARD_LIMITED_THROWS_GET_FAILURE');

export const getCountries = createAction('COUNTRIES_GET_REQUEST');
export const getCountriesFailure = createAction('COUNTRIES_GET_FAILURE');
export const getCountriesSuccess = createAction('COUNTRIES_GET_SUCCESS');

export default {
  getBoard,
  getBoardFailure,
  getBoardSessions,
  getBoardSessionsSuccess,
  getBoardSessionsFailure,
  getBoardSuccess,
  getBoardStatistics,
  getBoardStatisticsSuccess,
  getBoardStatisticsFailure,
  getBoardCurrentState,
  getBoardCurrentStateSuccess,
  getBoardCurrentStateFailure,
  getBoardLimitedThrows,
  getBoardLimitedThrowsSuccess,
  getBoardLimitedThrowsFailure,
  getCountries,
  getCountriesFailure,
  getCountriesSuccess,
};
