import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import { updateBoard } from 'scenes/Boards/components/Show/actions'

import { actions as boardActions } from 'scenes/Boards';
import { actions as editActions } from 'scenes/Boards/components/Edit';
import { actions as listActions } from 'scenes/Boards/components/List';
import { actions as userShowActions } from 'scenes/Users/components/Show';

const INITIAL_STATE = {};

const boardsReducer = handleActions(
  {
    [listActions.getBoardsSuccess]: (state, { payload: { data } }) => {
      const boards = data.reduce((boards, board) => {
        board.id = board._id;
        delete board._id;

        boards[board.id] = board;
        return boards;
      }, {});

      return { ...boards };
    },

    [updateBoard]: (state, { payload: { subject, data: { currentState } } }) => {
      return update(state, {
        [subject]: {
          'currentState': {
            $set: currentState,
          }
        },
      });
    },

    [boardActions.getBoardSuccess]: (state, { payload: board }) => {
      board.id = board._id;
      delete board._id;

      if (state[board.id]) {
        return update(state, {
          [board.id]: {
            $merge: board,
          },
        });
      }

      return update(state, {
        [board.id]: {
          $set: board,
        },
      });
    },

    [boardActions.getBoardSessionsSuccess]: (
      state,
      { payload: { boardId, sessions, offlineSessions } }
    ) => {
      return {
        ...state,
        [boardId]: {
          ...state[boardId],
          sessions: [...sessions],
          offlineSessions: [...offlineSessions],
        },
      };
    },

    [boardActions.getBoardStatisticsSuccess]: (state, { payload: { boardId, ...statistics } }) => {
      return {
        ...state,
        [boardId]: { ...state[boardId], statistics: { ...statistics } },
      };
    },

    [boardActions.getBoardCurrentStateSuccess]: (
      state,
      { payload: { boardId, ...currentState } }
    ) => {
      return {
        ...state,
        [boardId]: { ...state[boardId], currentState: { ...currentState } },
      };
    },

    [boardActions.getBoardLimitedThrowsSuccess]: (
      state,
      { payload: { boardId, throwsDetected } }
    ) => {
      return {
        ...state,
        [boardId]: { ...state[boardId], limitedThrows: { ...throwsDetected } },
      };
    },

    [editActions.updateBoardSuccess]: (state, { payload: board }) =>
      update(state, {
        [board._id]: {
          $set: { ...board, id: board._id, __isStale: true },
        },
      }),

    [userShowActions.getUserBoardsSuccess]: (state, { payload }) => {
      const boards = payload.reduce((boards, board) => {
        board.id = board._id;
        delete board._id;

        boards[board.id] = board;
        return boards;
      }, {});

      return {
        ...boards,
      };
    },
  },
  INITIAL_STATE
);

export default boardsReducer;
