import { createAction } from 'redux-actions';

export const createHardware = createAction('CREATE_HARDWARE_REQUEST');
export const createHardwareFailure = createAction('CREATE_HARDWARE_FAILURE');
export const createHardwareSuccess = createAction('CREATE_HARDWARE_SUCCESS');

export default {
  createHardware,
  createHardwareFailure,
  createHardwareSuccess,
};
