import { createAction } from 'redux-actions';

export const getUsersEmails = createAction('USERS_EMAILS_GET_REQUEST');
export const getUsersEmailsFailure = createAction('USERS_EMAILS_GET_FAILURE');
export const getUsersEmailsSuccess = createAction('USERS_EMAILS_GET_SUCCESS');

export default {
  getUsersEmails,
  getUsersEmailsFailure,
  getUsersEmailsSuccess,
};