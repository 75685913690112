import React from 'react';
import cn from 'classnames';
import { SHint } from '../SHint';
import { SLabel } from '../SLabel';
import { SError } from '../SError';
import { SAnimate } from '../SAnimate';
import styles from './styles.module.scss';
export const SLabelContainer = ({ className, label = '', isRequired, name, showLabel = true, nowrap = true, children, error, tooltip, hint, ...rest }) => {
    return (React.createElement("div", { className: cn(styles.SInputContainer, [className]), ...rest },
        showLabel && (React.createElement(SLabel, { htmlFor: name, isRequired: isRequired, nowrap: nowrap, tooltip: tooltip }, label)),
        children,
        React.createElement(SAnimate, { in: !!hint },
            React.createElement(SHint, null, hint)),
        React.createElement(SAnimate, { in: !!error },
            React.createElement(SError, null, error))));
};
SLabelContainer.displayName = 'SLabelContainer';
