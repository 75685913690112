export enum TournamentPlaceTitles {
  winner = 'winner',
  runnerUp = 'runnerUp',
  semiFinalists = 'semiFinalists',
  top8 = 'top8',
  top16 = 'top16',
  top32 = 'top32',
  top64 = 'top64',
  top128 = 'top128',
  top256 = 'top256',
}