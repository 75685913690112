import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { isEmpty } from 'lodash-es';

import { Avatar } from './components/Avatar';
import { Details } from './components/Details';
import { Statistics } from './components/Statistics';
import { UsersBoards } from './components/UsersBoards';
import { UserFriends } from './components/UserFriends';
import { GameHistory } from './components/GameHistory';
import { TokenHistory } from './components/TokenHistory';
import { ScoreCorrectionHistory } from './components/ScoreCorrectionHistory';
import { BanHistory } from 'components/BanHistory';

import { getIsAdmin, getSessionUserId } from 'services/session/selectors';

import actions from './actions';

const Show = ({ match }) => {
  const dispatch = useDispatch();
  const boards = useSelector(state => state.data.boards);
  const countries = useSelector(state => state.data.countries);
  const { user } = useSelector(state => state.scenes.users.show);
  const isSaveDisabled = useSelector(state => state.scenes.users.showDetails.isSaveDisabled);
  const isAdmin = useSelector(state => getIsAdmin(state));
  const sessionUserId = useSelector(state => getSessionUserId(state));

  const userId = match.params.userId;

  const userBoards = useMemo(() => {
    const filtered = Object.values(boards).filter(board => board.ownerId === userId);

    return filtered.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.id]: curr,
      }),
      {}
    );
  }, [boards, userId]);

  useEffect(() => {
    dispatch(actions.getUser(userId));
    dispatch(actions.getUserBoards({ ownerId: userId }));

    return () => {
      dispatch(actions.resetUser());
    };
  }, [dispatch, userId]);

  if (!user) {
    return null;
  }

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12">
          <Row>
            <Col md="5" xs="12">
              <Details
                user={user}
                countries={countries}
                isAdmin={isAdmin}
                userId={userId}
                dispatch={dispatch}
                sessionUserId={sessionUserId}
                isSaveDisabled={isSaveDisabled}
              />
            </Col>
            <Col md="7" xs="12">
              <Avatar user={user} />
              <Statistics userId={userId} />
              <BanHistory banHistory={user.banHistory} />
            </Col>
          </Row>
          <UsersBoards boards={userBoards} />
          <UserFriends
            friends={
              Array.isArray(user?.friends) ? user?.friends?.filter(friend => !isEmpty(friend)) : []
            }
            userId={userId}
            userNickname={user.nickname}
          />
          <GameHistory userId={userId} userNickname={user.nickname} />
          <TokenHistory userId={userId} isAdmin={isAdmin} />
          <ScoreCorrectionHistory userId={userId} isAdmin={isAdmin}  />
        </Col>
      </Row>
    </div>
  );
};

Show.displayName = 'Users.Show';

export default Show;
