import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { default as formatDate } from 'utils/formatDate';
import styles from './styles.module.scss';
import { DateRangePicker, defaultStaticRanges } from 'react-date-range';
import { datesEqual, startOfDayOf, endOfDayOf } from 'utils';

const INITIAL_DATE_RANGE = {
  startDate: startOfDayOf(new Date()),
  endDate: endOfDayOf(new Date()),
  key: 'dateRange',
};

function DateRangeFilter({ onDateRangeChange, isLoading, dateRange }) {
  const [dateRangePickerVisible, setDateRangePickerVisible] = useState(false);
  const [focusedRange, setFocusedRange] = useState([0, 0]);
  const [currentDateRange, setDateRange] = useState(dateRange ? {...dateRange, key: INITIAL_DATE_RANGE.key} : INITIAL_DATE_RANGE);

  const handleClear = () => {
    setDateRange(INITIAL_DATE_RANGE);
    setDateRangePickerVisible(false);
  };

  const handleDateRangeChange = ({ dateRange }) => {
    setDateRange(dateRange);

    const endDateIsPicked = focusedRange[1] === 1;

    // DateRangePicker has no callback on static dateRange click
    // so we have to manually check the selected values :/
    const staticDateRangeIsPicked = defaultStaticRanges.find(
      item =>
        datesEqual(item.range().startDate, dateRange.startDate) &&
        datesEqual(item.range().endDate, dateRange.endDate)
    );

    if (endDateIsPicked || staticDateRangeIsPicked) {
      setDateRangePickerVisible(false);
    }
  };

  useEffect(
    () => {
      !dateRangePickerVisible &&
        onDateRangeChange({
          startDate: startOfDayOf(currentDateRange.startDate),
          endDate: endOfDayOf(currentDateRange.endDate),
        });
    },
    [currentDateRange.startDate, currentDateRange.endDate, dateRangePickerVisible, onDateRangeChange]
  );

  return (
    <Fragment>
      <div className={styles.dateInputWrapper}>
        <div className={styles.labelWrapper}>Date Range</div>
        <span>
          <input
            value={`${formatDate(currentDateRange.startDate)} - ${formatDate(
              currentDateRange.endDate
            )}`}
            readOnly={true}
            onClick={() => setDateRangePickerVisible(true)}
          />
          <i onClick={handleClear} className="fas fa-times" />
        </span>
      </div>
      {isLoading ?
          <i className="fa fa-spinner fa-spin fa-fw" />
        :
          (dateRangePickerVisible && (
            <span style={{ position: 'absolute' }}>
              <DateRangePicker
                onChange={handleDateRangeChange}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={[currentDateRange]}
                focusedRange={focusedRange}
                onRangeFocusChange={setFocusedRange}
                direction="horizontal"
                inputRanges={[]}
                className={styles.dateRangePicker}
              />
            </span>
          )
      )}
    </Fragment>
  );
}

DateRangeFilter.propTypes = {
  onDateRangeChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  dateRange: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }),
};

export default DateRangeFilter;
