import { call, delay, put, takeLatest } from 'redux-saga/effects';

import { Boards } from 'services/api';
import { callApi } from 'services/rest';

import {
  getBoardStatistics,
  getBoardStatisticsFailure,
  getBoardStatisticsSuccess,
} from 'scenes/Boards/actions';

function* watchGetBoardStatistics({ payload: { boardId, filters } }) {
  try {
    let { data } = yield call(callApi, Boards.statistics, boardId, filters);
    data.boardId = boardId;
    yield delay(200);
    yield put(getBoardStatisticsSuccess(data));
  } catch (e) {
    yield put(getBoardStatisticsFailure(e.isFetch ? e.data.error : e));
  }
}

export default () => takeLatest(getBoardStatistics, watchGetBoardStatistics);
