import { handleActions } from 'redux-actions';

import actions from './actions';

const INITIAL_STATE = {
  isLoading: true,
  page: 0,
  pageSize: 0,
  totalCount: 0,
};

const listReducer = handleActions(
  {
    [actions.getOnlineBoards]: state => ({
      ...state,
      isLoading: true,
    }),

    [actions.getOnlineBoardsSuccess]: (state, {payload: { page, pageSize, totalCount }}) => ({
      ...state,
      isLoading: false,
      page,
      pageSize,
      totalCount
    }),

    [actions.getOnlineBoardsFailure]: state => ({
      ...state,
      isLoading: false,
    }),
  },
  INITIAL_STATE
);

export default listReducer;
