import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

class Footer extends Component {

  get footerText() {
    const { page, pageSize, totalCount } = this.props;

    return (
      <span>
        Showing {page * pageSize + 1}-{(page + 1) * pageSize > totalCount
          ? totalCount
          : (page + 1) * pageSize}{' '}
        of total {totalCount} rows
      </span>
    );
  }

  render() {
    return <div className={styles.reactTableCustomFooter}>{this.footerText}</div>;
  }
}

Footer.propTypes = {
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
};

Footer.defaultProps = {
  page: 0,
  pageSize: 0,
  totalCount: 0
};

export default Footer;
