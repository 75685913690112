import { combineReducers } from 'redux';

import { reducer as boards } from './Boards';
import { reducer as onlineBoards } from './OnlineBoards';
import { reducer as countries } from './Countries';
import { reducer as feedbacks } from './Feedbacks';
import { reducer as firmwares } from './Firmwares';
import { reducer as hardwares } from './Hardwares';
import { reducer as download } from './Download';
import { reducer as users } from './Users';
import { reducer as resourceLogs } from './ResourceLogs';
import { reducer as rankingList } from './RankingList';
import { reducer as rankings } from './Rankings';
import { reducer as systemUsages } from './SystemUsages';
import { reducer as dashboard } from './Dashboard';
import { reducer as serviceAccounts } from './ServiceAccounts';
import { reducer as storeSnapshot } from './StoreSnapshot';
import { reducer as manufacturers } from './Manufacturers';
import { reducer as chat } from './Chat';
import { reducer as notifications } from './Notifications';
import { reducer as tournaments } from './Tournaments';
import { reducer as privateTournaments } from './PrivateTournaments';
import { reducer as usersEmails } from "./UsersEmails";
import { reducer as tokenPurchases } from "./TokenPurchases";
import { reducer as platforms } from "./Platforms";

const dataReducer = combineReducers({
  boards,
  onlineBoards,
  countries,
  download,
  feedbacks,
  firmwares,
  hardwares,
  users,
  resourceLogs,
  rankingList,
  rankings,
  systemUsages,
  dashboard,
  serviceAccounts,
  storeSnapshot,
  manufacturers,
  chat,
  notifications,
  tournaments,
  privateTournaments,
  usersEmails,
  tokenPurchases,
  platforms,
});

export default dataReducer;
