export const X01 = {
    type: 'X01',
    label: 'X01',
};
export const AROUND_THE_CLOCK = {
    type: 'AroundTheClock',
    label: 'Around The Clock',
};
export const BOBS_27 = {
    type: 'Bobs27',
    label: "Bob's 27",
};
export const CRICKET = {
    type: 'Cricket',
    label: 'Cricket',
};
export const SHANGHAI = {
    type: 'Shanghai',
    label: 'Shanghai',
};
export const BULL_THROW = {
    type: 'BullThrow',
    label: 'Bull Throw',
};
export const RANDOM_CRICKET = {
    type: 'RandomCricket',
    label: 'Random Cricket',
};

export default [
    X01,
    AROUND_THE_CLOCK,
    BOBS_27,
    CRICKET,
    BULL_THROW,
    // SHANGHAI
    RANDOM_CRICKET
].reduce(
    (acc, game) => ({
        ...acc,
        [game.type]: game,
    }),
    {}
);
