import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import cn from 'classnames';

import { EMAIL_ADDRESS } from 'constants/Validation-regexp';

import { Input } from 'components/Form';

import { checkWhitespaceOnly, checkWhitespaceStartEnd } from 'utils/checkWhitespace';

import styles from './styles.module.scss';


class ManufacturerForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        name: this.props.manufacturer.name || '',
        phoneNumber: this.props.manufacturer.phoneNumber || '',
        email: this.props.manufacturer.email || '',
        address: this.props.manufacturer.address || '',
      },
    };
  }

  getValidationSchema = () =>
    Yup.object().shape({
      name: Yup.string()
        .required('Name is required')
        .test('white-space-free', 'Name is required and cannot contain only spaces!', checkWhitespaceOnly)
        .test('white-space-free', 'Name cannot start or end with space!', checkWhitespaceStartEnd),
      phoneNumber: Yup.string(),
      email: Yup.string().matches(EMAIL_ADDRESS, 'Invalid email address'),
      address: Yup.string(),
    });

  handleSubmit = (values) => {
    this.props.onSubmit(values);
  };

  render() {
    const { form } = this.state;
    const { formError, isAdmin } = this.props;

    return (
      <div>
        <Formik initialValues={form} validationSchema={this.getValidationSchema} onSubmit={this.handleSubmit}>
          {({ errors, touched, values, handleBlur, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <dl className="row">
                <dt className="col-sm-3">Name*</dt>
                <dd className="col-sm-9">
                  <Input.Text
                    error={touched.name && errors.name}
                    name="name"
                    type="text"
                    value={values.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </dd>
              </dl>
              <dl className="row">
                <dt className="col-sm-3">Telephone</dt>
                <dd className="col-sm-9">
                  <Input.Text
                    error={touched.phoneNumber && errors.phoneNumber}
                    name="phoneNumber"
                    type="text"
                    value={values.phoneNumber}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </dd>
              </dl>
              <dl className="row">
                <dt className="col-sm-3">E-Mail</dt>
                <dd className="col-sm-9">
                  <Input.Text
                    error={touched.email && errors.email}
                    name="email"
                    type="text"
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </dd>
              </dl>
              <dl className="row">
                <dt className="col-sm-3">Address</dt>
                <dd className="col-sm-9">
                  <Input.Text
                    error={touched.address && errors.address}
                    name="address"
                    type="text"
                    value={values.address}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </dd>
              </dl>

              {formError && <div className={cn(styles.formError, 'col-sm-12')}>{formError}</div>}

              <div className="action-footer">
                {isAdmin && <button className="btn btn-success btn-md" type="submit">
                  {this.props.manufacturer.name ? 'Update Manufacturer' : 'Create manufacturer'}
                </button>}
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

ManufacturerForm.propTypes = {
  manufacturer: PropTypes.object.isRequired,
  formError: PropTypes.string,
  isEdit: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
};

ManufacturerForm.defaultProps = {
  manufacturer: {},
};

export default ManufacturerForm;
