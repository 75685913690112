import fileDownload from 'js-file-download';

import { Export } from 'services/api';
import { callApi } from 'services/rest';
import {
  downloadSystemUsage,
  downloadSystemUsageSuccess,
  downloadSystemUsageFailure,
} from 'scenes/SystemUsages/components/SystemUsage/actions';
import { call, put, takeLatest } from 'redux-saga/effects';

type WatchDownloadSystemUsageProps = {
  viewType: string;
  dateFrom: Date;
  dateTo: Date;
  _label: string;
  automaticallyDetectedThrows: number;
  addedOrModifiedThrows: number;
  outcastThrows: number;
  practiceGameThrows: number;
  finishedGames: number;
  unfinishedGames: number;
  totalGames: number;
  totalThrows: number;
  totalX01Throws: number;
  totalCricketThrows: number;
  totalRandomCricketThrows: number;
  totalShanghaiThrows: number;
  totalAtcThrows: number;
  totalBobs27Throws: number;
  totalPracticeGamePowerScoringThrows: number;
  totalPracticeGameCheckoutStreakThrows: number;
  totalPracticeGame121Throws: number;
  isCustomQuery: boolean;
};

function* watchDownloadSystemUsage({
  payload: {
    viewType,
    dateFrom,
    dateTo,
    _label,
    automaticallyDetectedThrows,
    addedOrModifiedThrows,
    outcastThrows,
    practiceGameThrows,
    finishedGames,
    unfinishedGames,
    totalGames,
    totalThrows,
    isCustomQuery,
    totalX01Throws,
    totalCricketThrows,
    totalRandomCricketThrows,
    totalShanghaiThrows,
    totalAtcThrows,
    totalBobs27Throws,
    totalPracticeGamePowerScoringThrows,
    totalPracticeGameCheckoutStreakThrows,
    totalPracticeGame121Throws,
  },
}: {
  payload: WatchDownloadSystemUsageProps;
}) {
  try {
    const filter = [];
    const sort: any[] = [];

    if (viewType) {
      filter.push({
        id: `viewType`,
        value: viewType,
      });
    }

    if (dateFrom) {
      filter.push({
        id: `dateFrom`,
        value: dateFrom,
      });
    }

    if (dateTo) {
      filter.push({
        id: `dateTo`,
        value: dateTo,
      });
    }

    filter.push({
      id: `timeZone`,
      value: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    if (dateFrom) {
      filter.push({
        id: `timeZoneOffset`,
        value: dateFrom.getTimezoneOffset(),
      });
    }

    const { data } = yield call(
      callApi,
      Export.download,
      {
        resource: 'system-usage',
        columns: {
          _label,
          automaticallyDetectedThrows,
          addedOrModifiedThrows,
          outcastThrows,
          practiceGameThrows,
          totalThrows,
          finishedGames,
          unfinishedGames,
          totalGames,
          totalX01Throws,
          totalCricketThrows,
          totalRandomCricketThrows,
          totalShanghaiThrows,
          totalAtcThrows,
          totalBobs27Throws,
          totalPracticeGamePowerScoringThrows,
          totalPracticeGameCheckoutStreakThrows,
          totalPracticeGame121Throws,
        },
        isCustomQuery,
      },
      { filter, sort }
    );

    fileDownload(data, `SystemUsage${dateFrom}-${dateTo}.csv`);
    yield put(downloadSystemUsageSuccess());
  } catch (e) {
    console.warn('TODO: Handle these with eg. toaster');
    console.log(e);
    yield put(downloadSystemUsageFailure());
  }
}

export default () => takeLatest(downloadSystemUsage, watchDownloadSystemUsage);
