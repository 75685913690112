import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import * as sessionActions from 'services/session/actions';

const INITIAL_STATE = {
  loginForm: {
    error: '',
  },
  isLoginPending: false,
};

const login = handleActions(
  {
    [sessionActions.logInSuccess]: state =>
      update(state, {
        loginForm: {
          error: {
            $set: '',
          },
        },
        isLoginPending: { $set: false },
      }),

    [sessionActions.logInFailure]: (state, { payload: { error } }) =>
      update(state, {
        loginForm: {
          error: {
            $set: error,
          },
        },
        isLoginPending: { $set: false },
      }),

    [sessionActions.logIn]: state =>
      update(state, {
        loginForm: {
          error: {
            $set: '',
          },
        },
        isLoginPending: { $set: true },
      }),

  },
  INITIAL_STATE
);

export default login;
