import { createAction } from 'redux-actions';

export const getChatRooms = createAction('CHAT_ROOMS_GET_REQUEST');
export const getChatRoomsFailure = createAction('CHAT_ROOMS_GET_FAILURE');
export const getChatRoomsSuccess = createAction('CHAT_ROOMS_GET_SUCCESS');
export const download = createAction('CHAT_MESSAGES_DOWNLOAD_REQUEST');
export const downloadSuccessful= createAction('CHAT_MESSAGES_DOWNLOAD_SUCCESS');
export const downloadFailure = createAction('CHAT_MESSAGES_DOWNLOAD_FAILURE');

export default {
  getChatRooms,
  getChatRoomsFailure,
  getChatRoomsSuccess,
  download,
  downloadSuccessful,
  downloadFailure
};
