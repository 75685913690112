import { createAction } from 'redux-actions';

export const importTournaments = createAction('TOURNAMENTS_IMPORT_REQUEST');
export const importTournamentsFailure = createAction('TOURNAMENTS_IMPORT_FAILURE');
export const importTournamentsSuccess = createAction('TOURNAMENTS_IMPORT_SUCCESS');

export default {
  importTournaments,
  importTournamentsFailure,
  importTournamentsSuccess,
};
