import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import { CardBody } from 'reactstrap'
import PropTypes from 'prop-types'

import { STAGE_PRIZES } from 'constants/Tournament'

import { TournamentForm } from 'scenes/Tournaments/components/TournamentForm'

import highestPowerof2 from 'utils/highestPowerOf2'

import { getConfiguration } from 'utils/x01ConfigFormatter'
import formatDate from 'utils/formatDate'

import styles from './styles.module.scss'

const TournamentCard = ({ tournament, isExpandedList, dataErrors, userId }) => {
  const [isExpanded, setExpanded] = useState(false);
  const [overallPricePool, setOverallPricePool] = useState(0);
  const [validationErrors, setValidationErrors] = useState([]);

  // set expand based on list setting
  useEffect(() => {
    setExpanded(isExpandedList)
  }, [ isExpandedList ])

  // calculate overallPricePool
  useEffect(() => {
    const { initialPrizing, maxParticipants } = tournament;
 
    //get the highestPowerof2 
    const lowerPow = Math.log(highestPowerof2(maxParticipants))/Math.log(2);
    const indexes = Math.pow(2, lowerPow) < maxParticipants ? lowerPow+1 : lowerPow;

    let prices = 0;

    STAGE_PRIZES.slice(0, indexes+1).forEach((e, i) => {
      let playerNr = e.playerNum;
      if( i === indexes && indexes !== lowerPow ) {
        playerNr = maxParticipants-Math.pow(2, i-1)
      }
      prices += initialPrizing.stagePrizes[e.key]*playerNr
    })

    setOverallPricePool(prices)
  }, [  tournament ])

  //get validation data
  useEffect(() => {
    setValidationErrors(dataErrors)
  }, [ dataErrors ])

  const getMinimalInfos = () => {
    const regStartDate = new Date(tournament.registrationStartDate);
    const regEndDate = new Date(tournament.registrationEndDate);
    const startDate = new Date(tournament.tournamentStartDate);

    return (
      <div className={styles.minimalInfoWrapper}>
          <div className={styles.minimalBlock}>
            <div className={styles.name}><b>{tournament.name}</b></div>
            <div>{getConfiguration(tournament.gameConfig)}</div>
            <div>Min. {tournament.minParticipants} - Max. {tournament.maxParticipants} players</div>
          </div>
          <div className={styles.minimalBlockDate}>
            <div>Registration starts at: <b>{formatDate(regStartDate, true)}</b></div>
            <div>Registration ends at: <b>{formatDate(regEndDate, true)}</b></div>
            <div>Tournament starts at:  <b>{formatDate(startDate, true)}</b></div>
          </div>
          <div className={styles.minimalBlock}>
            <div>Overall price pool:</div>
            <div><b>{overallPricePool} TP</b></div>
          </div>
      </div>
    )
  }

  return (
    <div 
      className={cn(styles.card, {
        [styles.inInvalid]: validationErrors.length > 0,
        [styles.isExpanded]: isExpanded
      })}
      onClick={() => setExpanded(!isExpanded)}
    >
        {
          !isExpanded ? (
            <div className={styles.row}>
              {getMinimalInfos()}
            </div>
          ) : (
            <CardBody>
              <TournamentForm
                tournament={tournament}
                isShow
                dataErrors={dataErrors}
                userId={userId}
              />
            </CardBody>
          )
        }
    </div>
  );
}

TournamentCard.propTypes = {
  tournament: PropTypes.object.isRequired,
  isExpandedList: PropTypes.bool,
  dataErrors: PropTypes.array,
  userId: PropTypes.string.isRequired,
}

export default TournamentCard;