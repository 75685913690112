import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import { List } from './components/List';
import { Show } from './components/Show';

class Chat extends Component {
  render() {
    const {
      match: { url },
    } = this.props;

    return (
      <Switch>
        <Route component={List} exact name="Chat rooms" path={`${url}/`} />
        <Route component={Show} exact name="Room info" path={`${url}/:roomId`} />
      </Switch>
    );
  }
}

export default Chat;
