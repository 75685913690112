import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { get } from 'services/rest';
import { PlayerCardEnum } from 'enums';
import { GameStatistics } from './components/GameStatistics';
import { StatisticsTabs } from './components/StatisticsTabs';
import { PlayerCardStatistics } from './components/PlayerCardStatistics';
export const Statistics = ({ userId }) => {
    const [selectedGameType, setSelectedGameType] = useState(PlayerCardEnum.PlayerCard);
    const isSelectedGameTypePlayerCard = selectedGameType === PlayerCardEnum.PlayerCard;
    const isPlayerCardResponse = (data) => isSelectedGameTypePlayerCard;
    const url = `/users/${userId}/` +
        `${isSelectedGameTypePlayerCard ? 'player-card' : `statistics/${selectedGameType}`}`;
    const { isLoading: isStatisticsLoading, data: userStats } = useQuery([url], async () => {
        const data = (await get(url));
        if (isPlayerCardResponse(data?.data)) {
            const playerCardResponse = data?.data;
            return playerCardResponse?.statistics ?? undefined;
        }
        const gameStatisticsResponse = data?.data;
        return gameStatisticsResponse?.aggregatedStats ?? undefined;
    });
    const handleGameTypeSelect = (gameType) => {
        if (gameType !== selectedGameType) {
            setSelectedGameType(gameType);
        }
    };
    const isPlayerCard = (userStat) => isSelectedGameTypePlayerCard;
    return (React.createElement("div", null,
        React.createElement(Card, null,
            React.createElement(CardHeader, null,
                React.createElement("div", { className: "card-header__left" },
                    React.createElement("i", { className: "fa fa-align-justify" }),
                    " Statistics")),
            React.createElement(CardBody, null,
                React.createElement(StatisticsTabs, { handleGameTypeSelect: handleGameTypeSelect }),
                isStatisticsLoading ? (React.createElement("i", { className: "fa fa-spinner fa-spin fa-fw" })) : (userStats &&
                    (isPlayerCard(userStats) ? (React.createElement(PlayerCardStatistics, { userStats: userStats })) : (React.createElement(GameStatistics, { selectedGameType: selectedGameType, userStats: userStats }))))))));
};
