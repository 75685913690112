import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import { Create } from './components/Create';
import { Edit } from './components/Edit';
import { List } from './components/List';
import { Show } from './components/Show';

class Manufacturers extends Component {
  render() {
    const { match: { url } } = this.props;
    return (
      <Switch>
        <Route component={List} exact name="Hybrid SBC-s list" path={`${url}/`} />
        <Route component={Create} exact name="Create manufacturer" path={`${url}/new`} />
        <Route component={Edit} exact name="Manufacturer info" path={`${url}/:manufacturerId/edit`} />
        <Route component={Show} exact name="Manufacturer info" path={`${url}/:manufacturerId`} />
      </Switch>
    );
  }
}

export default Manufacturers;
