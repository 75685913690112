import { createAction } from 'redux-actions';

export const getHardware = createAction(
  'HARDWARE_GET_REQUEST',
  (hardwareId, queryParams, isLoading = true) => ({
    hardwareId,
    queryParams,
    isLoading,
  })
);

export const getHardwareFailure = createAction('HARDWARE_GET_FAILURE');
export const getHardwareSuccess = createAction('HARDWARE_GET_SUCCESS');

export default {
  getHardware,
  getHardwareFailure,
  getHardwareSuccess,
};
