export function checkWhitespaceOnly(value) {
  if (value?.match(/^\s+$/g) && value.match(/^\s+$/g).length > 0) {
    return false;
  } else {
    return true;
  }
}

export function checkWhitespaceStartEnd(value) {
  if (value?.match(/^\s+.*/my) || value?.match(/.*\s+$/my)) {
    return false;
  } else {
    return true;
  }
}
