import React from 'react';
import { Formik } from 'formik';
import TableRow from 'components/TableRow';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { getIsAdmin, getConfiguration } from 'services/session/selectors';
import actions from 'services/configuration/actions';
export const GeneralConfigsForm = () => {
    const isAdmin = useSelector(getIsAdmin);
    const config = useSelector(getConfiguration);
    const form = {
        dashboardYoutubeUrl: config.dashboardYoutubeUrl,
        privateTournamentConcurrentLimit: config.privateTournamentConcurrentLimit,
        isTurnServerEnabled: config.turnServer.isTurnServerEnabled,
        isTFBFalseTakeoutReal: config.isTFBFalseTakeoutReal,
    };
    const dispatch = useDispatch();
    const getValidationSchema = () => {
        let configsSchema = Yup.object().shape({
            dashboardYoutubeUrl: Yup.string().test('youtube-api', 'Invalid video ID', async (videoId) => {
                if (!videoId)
                    return true;
                if (videoId.length !== 11)
                    return false;
                const response = await fetch(`https://www.youtube.com/oembed?url=http%3A//youtube.com/watch%3Fv%3D${videoId}&format=json`);
                return response.ok;
            }),
        });
        return configsSchema;
    };
    const handleSubmit = (values) => dispatch(actions.updateConfiguration({
        dashboardYoutubeUrl: values.dashboardYoutubeUrl,
        privateTournamentConcurrentLimit: values.privateTournamentConcurrentLimit,
        turnServer: {
            isTurnServerEnabled: values.isTurnServerEnabled,
        },
        isTFBFalseTakeoutReal: values.isTFBFalseTakeoutReal,
    }));
    return (React.createElement("div", null,
        React.createElement(Formik, { initialValues: form, validationSchema: getValidationSchema, onSubmit: handleSubmit }, ({ errors, values, handleBlur, handleChange, handleSubmit }) => (React.createElement("form", { onSubmit: handleSubmit },
            React.createElement(TableRow.Text, { label: "Youtube video ID", labelClass: "col-sm-3", valueClass: "col-sm-9", isLabelFormatted: false, inputClass: "s-input", error: errors.dashboardYoutubeUrl, name: "dashboardYoutubeUrl", placeholder: 'wemw4wATYKM', isDisabled: false, type: "text", value: values.dashboardYoutubeUrl || '', onBlur: handleBlur, onChange: handleChange }),
            React.createElement("dl", { className: "row" },
                React.createElement("dt", { className: "col-sm-3" }),
                React.createElement("dd", { className: "col-sm-9" },
                    React.createElement("i", null, "https://www.youtube.com/watch?v="),
                    React.createElement("b", null, "wemw4wATYKM"))),
            React.createElement(TableRow.Text, { label: "Concurrent private tournament per user limit", labelClass: "col-sm-3", valueClass: "col-sm-9", isLabelFormatted: false, inputClass: "s-input", error: errors.privateTournamentConcurrentLimit, name: "privateTournamentConcurrentLimit", type: "number", placeholder: 1, isDisabled: false, min: "1", value: values.privateTournamentConcurrentLimit, required: false, onBlur: handleBlur, onChange: handleChange }),
            React.createElement(TableRow.Checkbox, { label: "Enable TURN Server", labelClass: "col-sm-3", valueClass: "col-sm-9", isLabelFormatted: false, name: "isTurnServerEnabled", value: values.isTurnServerEnabled, onBlur: handleBlur, onChange: handleChange, isDisabled: false }),
            React.createElement(TableRow.Checkbox, { label: "Enable TFB false takeout as real takeout", labelClass: "col-sm-3", valueClass: "col-sm-9", isLabelFormatted: false, isDisabled: false, name: "isTFBFalseTakeoutReal", value: values.isTFBFalseTakeoutReal, onBlur: handleBlur, onChange: handleChange }),
            React.createElement("div", { className: "action-footer" }, isAdmin && (React.createElement("button", { className: "btn btn-success btn-md", type: "submit", disabled: !!Object.keys(errors).length }, "Save config"))))))));
};
