import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { STAGE_PRIZES } from 'constants/Tournament'
import { Badge, Card, CardBody, CardHeader } from 'reactstrap'
import { isNullOrUndefined } from 'utils';
import { formatMaxPrizePool } from 'utils/tournamentUtils'
import styles from './styles.module.scss'

const PrizePool = ({ label='Prize pool', pool, maxPrizePool, achievablePrizePool, penalty }) => (
  <Card color="white">
    <CardHeader>
      <div className="card-header__left">
        {label}
      </div>
    </CardHeader>
    <CardBody>
    {pool ? (
      <div style={{display: 'flex', flexDirection: 'column'}}>
        {Object.keys(pool).map((rank, idx) =>  <div style={{display: 'flex', flexDirection: 'row'}} key={idx}>
          <dt className="col-sm-8">{
            STAGE_PRIZES.find(category => category.key === rank)?.label
          }</dt>
          <dd className="col-sm-4">
            <Badge
              color={cn({
                warning: idx === 0,
                secondary: idx !== 0,
              })}
            >
              {formatMaxPrizePool(pool[rank])}
            </Badge>
          </dd>
        </div>)}
        <MaxPrizePool label="Max by stage" value={maxPrizePool} />
        {
          achievablePrizePool && (
            <MaxPrizePool label="Max by participants" value={achievablePrizePool} />
          )
        }
      </div>
    ) : (
      'There is no information'
    )}
    {!isNullOrUndefined(penalty) && 
      <div style={{display: 'flex', flexDirection: 'row', whiteSpace: 'nowrap' }}>
        <dt className="col-sm-8">
          Penalty:
        </dt>
        <dt className="col-sm-4">
          <Badge color={'danger'}>{formatMaxPrizePool(penalty)}</Badge>
        </dt>
      </div>
    }
    </CardBody>
  </Card>
)

const MaxPrizePool = ({label, value}) => (
  <div style={{display: 'flex', flexDirection: 'row', whiteSpace: 'nowrap'}}>
    <dt className="col-sm-8">{label}:</dt>
    <dt className={`col-sm-4 ${styles.marginBottom}`}>{value ? formatMaxPrizePool(value) : '-'}</dt>
  </div>
)

PrizePool.propTypes = {
  achievablePrizePool: PropTypes.number,
  label: PropTypes.string,
  maxPrizePool: PropTypes.number.isRequired,
  pool: PropTypes.object,
  penalty: PropTypes.number,
}

export default PrizePool;