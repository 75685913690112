import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import cn from 'classnames';

import {VERSION_NUMBER} from 'constants/Validation-regexp';

import { Input } from 'components/Form';
import TableRow from 'components/TableRow';

import { default as formatDate } from 'utils/formatDate';

import styles from './styles.module.scss';

class HardwareForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        versionNumber: this.props.hardware.versionNumber || '',
        description: this.props.hardware.description || '',
        releasedAt: formatDate(this.props.hardware.releasedAt) || '',
        isLimitedHardware: this.props.hardware.isLimitedHardware || false,
        isWifiCompatible: this.props.hardware.isWifiCompatible || false,
      },
    };
  }

  getValidationSchema = () =>
    Yup.object().shape({
      versionNumber: Yup.string()
        .required('Version number is required!')
        .matches(VERSION_NUMBER, 'Invalid version number format!'),
      releasedAt: Yup.string().required('Release date is required!'),
    });

  handleSubmit = values => {
    this.props.onSubmit(values);
  };

  render() {
    const { form } = this.state;
    const { formError, isEdit, isAdmin } = this.props;

    return (
      <div>
        <Formik
          initialValues={form}
          validationSchema={this.getValidationSchema}
          onSubmit={this.handleSubmit}
        >
          {({
            errors,
            touched,
            values,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <dl className="row">
                <dt className="col-sm-3">Version Number*</dt>
                <dd className="col-sm-9">
                  <Input.Text
                    disabled={isEdit}
                    error={touched.versionNumber && errors.versionNumber}
                    name="versionNumber"
                    type="text"
                    value={values.versionNumber}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    hint={
                      isEdit
                        ? null
                        : 'Required semantic version format (X.Y.Z), and can not be changed anymore.'
                    }
                  />
                </dd>
              </dl>
              <dl className="row">
                <dt className="col-sm-3">Release Date</dt>
                <dd className="col-sm-9">
                  <Input.Date
                    disabled={isEdit}
                    name="releasedAt"
                    error={touched.releasedAt && errors.releasedAt}
                    value={values.releasedAt}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </dd>
              </dl>
              <dl className="row">
                <dt className="col-sm-3">Limited hardware</dt>
                <dd className="col-sm-9">
                  <Input.Text
                    disabled={isEdit}
                    checked={values.isLimitedHardware}
                    type="checkbox"
                    name="isLimitedHardware"
                    // eslint-disable-next-line react/no-unknown-property
                    error={touched.isLimitedHardware && errors.isLimitedHardware}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </dd>
              </dl>
              <TableRow.Checkbox
                isDisabled={isEdit && !isAdmin}
                label="WiFi compatible"
                labelClass="col-sm-3"
                isLabelFormatted={false}
                valueClass="col-sm-9"
                value={values.isWifiCompatible}
                error={touched.isWifiCompatible && errors.isWifiCompatible}
                name="isWifiCompatible"
                type="checkbox"
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <dl className="row">
                <dt className="col-sm-3">Description</dt>
                <dd className="col-sm-9">
                  <textarea
                    className="col-sm-9"
                    rows="10"
                    name="description"
                    value={values.description}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Some description, or change log..."
                  />
                </dd>
              </dl>

              {formError && (
                <div className={cn(styles.formError, 'col-sm-12')}>
                  {formError}
                </div>
              )}

              <div className="action-footer">
                {isAdmin && <button className="btn btn-success btn-md" type="submit">
                  { isEdit ? 'Edit hardware' : 'Create hardware' }
                </button>}
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

HardwareForm.propTypes = {
  hardware: PropTypes.object.isRequired,
  formError: PropTypes.string,
  isEdit: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
};

HardwareForm.defaultProps = {
  hardware: {},
};

export default HardwareForm;
