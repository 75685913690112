import { get } from 'services/rest'

const url = '/products/'

const Products = {
  index: queryParams => get(url, queryParams),
  show: productId => get(`${url}${productId}`),
}

export default Products
