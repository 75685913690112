import { handleActions } from 'redux-actions';

import actions from './actions';
import {
  getChatRooms,
  getChatRoomsFailure,
  getChatRoomsSuccess,
} from '../List/actions'

const INITIAL_STATE = {
  isLoading: true,
  isParticipantsLoading: true,
};

const show = handleActions(
  {
    [actions.getMessages]: (state) => ({
      ...state,
      isLoading: false
    }),

    [actions.getMessagesRequest]: (state) => ({
      ...state,
      isLoading: true
    }),
    [getChatRooms]: (state) => ({
      ...state,
      isParticipantsLoading: true
    }),

    [getChatRoomsSuccess]: (state) => ({
      ...state,
      isParticipantsLoading: false
    }),

    [getChatRoomsFailure]: (state) => ({
      ...state,
      isParticipantsLoading: false
    }),
  },

  INITIAL_STATE
);

export default show;
