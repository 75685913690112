import { get, patch } from 'services/rest';

const url = '/config';

const Configuration = {
  fetch: () => get(`${url}`),
  update: (requestBody) => patch(`${url}`, requestBody),
};

export default Configuration;
