import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import actions from './actions';
import {
  getFirmware,
  getFirmwareSuccess,
  getFirmwareFailure,
} from 'scenes/Firmwares/actions';

import { getFirmwares } from '../List/actions';

const INITIAL_STATE = {
  isLoading: false,
};

const createReducer = handleActions(
  {
    [actions.createFirmware]: state =>
      update(state, {
        isCreatePending: {
          $set: true,
        },
        createError: {
          $set: '',
        },
      }),

    [actions.createFirmwareSuccess]: state =>
      update(state, {
        isCreatePending: {
          $set: false,
        },
        isCreateSuccessful: {
          $set: true,
        },
        createError: {
          $set: '',
        },
      }),

    [actions.createFirmwareFailure]: (state, { payload }) =>
      update(state, {
        isCreatePending: {
          $set: false,
        },
        isCreateSuccessful: {
          $set: false,
        },
        createError: {
          $set: payload,
        },
      }),

    [getFirmware]: (state, { payload: { isLoading } }) => ({
      ...state,
      isLoading,
    }),

    [getFirmwareSuccess]: state => ({
      ...state,
      isLoading: false,
    }),

    [getFirmwareFailure]: state => ({
      ...state,
      isLoading: false,
    }),

    [getFirmwares]: state =>
      update(state, {
        $unset: ['isCreateSuccessful']
      }),
  },
  INITIAL_STATE
);

export default createReducer;
