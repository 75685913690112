import { handleActions } from 'redux-actions';

import actions from './actions';

const INITIAL_STATE = {};

const editReducer = handleActions(
  {
    [actions.getBoard]: (state, { payload: { isLoading } }) => ({
      ...state,
      isLoading,
    }),

    [actions.getBoardSuccess]: state => ({
      ...state,
      isLoading: false,
    }),

    [actions.getBoardFailure]: state => ({
      ...state,
      isLoading: false,
    }),

    [actions.updateBoard]: state => ({
      ...state,
      isLoading: true,
    }),

    [actions.updateBoardSuccess]: state => ({
      ...state,
      isLoading: false,
      editError: '',
    }),

    [actions.updateBoardFailure]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      editError: payload,
    }),
  },
  INITIAL_STATE
);

export default editReducer;
