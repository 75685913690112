import React from 'react';
import cn from 'classnames';
import styles from '../../styles.module.scss';
export const AtcStatistics = ({ userStats }) => {
    const { hitRateOverall, medOverall } = userStats;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: cn(styles.rowHeader, 'col-sm-4') }, "MED"),
            React.createElement("div", { className: cn(styles.rowHeader, 'col-sm-4') }, "MHD"),
            React.createElement("div", { className: cn(styles.rowHeader, 'col-sm-4') }, "MVD")),
        medOverall && (React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col-sm-4" }, medOverall.med ? `${medOverall.med.toFixed(2)}mm` : '-'),
            React.createElement("div", { className: "col-sm-4" }, medOverall.mhd ? `${medOverall.mhd.toFixed(2)}mm` : '-'),
            React.createElement("div", { className: "col-sm-4" }, medOverall.mvd ? `${medOverall.mvd.toFixed(2)}mm` : '-'))),
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: cn(styles.rowHeader, 'col-sm-4') }, "Single Hit Rate"),
            React.createElement("div", { className: cn(styles.rowHeader, 'col-sm-4') }, "Double Hit Rate"),
            React.createElement("div", { className: cn(styles.rowHeader, 'col-sm-4') }, "Treble Hit Rate")),
        hitRateOverall && (React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col-sm-4" }, hitRateOverall.single?.percentage
                ? `${hitRateOverall.single?.percentage.toFixed(2)}%`
                : '-'),
            React.createElement("div", { className: "col-sm-4" }, hitRateOverall.double?.percentage ? `${hitRateOverall.double?.percentage}%` : '-'),
            React.createElement("div", { className: "col-sm-4" }, hitRateOverall.triple?.percentage ? `${hitRateOverall.triple?.percentage}%` : '-')))));
};
