import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Tooltip } from 'reactstrap';
import { default as formatElapsedTime } from 'utils/formatElapsedTime';
import { default as formatDate } from 'utils/formatDate';
import styles from '../styles.module.scss';

function SessionLine({
  session: {
    percentageFrom,
    percentageWidth,
    overlapped,
    onlineTime,
    duration,
    startTime,
    endTime,
    type,
    rawData: { _id: id, throws, outcastThrows, isRestarted },
  },
  handleHighlight,
}) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const tooltipContent = {
    online: [
      {
        label: 'Online time:',
        value: formatElapsedTime(duration, true),
        className: styles.onlineTime,
      },
      {
        label: 'Outcast Throws:',
        value: outcastThrows,
        className: styles.throws,
      },
      {
        label: 'Restarted:',
        value: isRestarted ? 'Yes' : 'No',
        className: styles.restarted,
      },
    ],
    offline: [
      {
        label: 'Offline time:',
        value: formatElapsedTime(duration, true),
        className: styles.offlineTime,
      },
      {
        label: 'Online time:',
        value: formatElapsedTime(onlineTime, true),
        className: styles.onlineTime,
      },
      {
        label: 'Throws:',
        value: throws,
        className: styles.throws,
      },
    ],
  };

  return (
    <Fragment>
      <div
        id={`session-${id}`}
        className={cn(styles.sessionLine, {
          [styles.overlapped]: overlapped,
          [styles.online]: type === 'online',
          [styles.offline]: type === 'offline' && !overlapped,
        })}
        style={{ left: percentageFrom, width: percentageWidth }}
        onMouseEnter={() => handleHighlight({ visible: true, percentageFrom, percentageWidth })}
        onMouseLeave={() => handleHighlight({ visible: false })}
      />
      <Tooltip
        target={`session-${id}`}
        isOpen={tooltipOpen}
        toggle={toggle}
        hideArrow={true}
        innerClassName={cn(styles.tooltip, {
          [styles.overlapped]: overlapped,
          [styles.online]: type === 'online',
          [styles.offline]: type === 'offline' && !overlapped,
        })}
      >
        <div className={styles.innerContainer}>
          {tooltipContent[type].map(({ label, value, className }, index) => (
            <div className={className} key={`${index}_${label}`}>
              <div className={styles.label}>{label}</div>
              <div className={styles.value}>{value}</div>
            </div>
          ))}
          <hr />
          <div className={styles.from}>
            <div className={styles.label}>From:</div>
            <div className={styles.value}>{formatDate(startTime, true)}</div>
          </div>
          <div className={styles.to}>
            <div className={styles.label}>To:</div>
            <div className={styles.value}>{formatDate(endTime, true)}</div>
          </div>
        </div>
        {overlapped && <div className={styles.overlapIndicator}>OVERLAP DETECTED</div>}
      </Tooltip>
    </Fragment>
  );
}

SessionLine.propTypes = {
  handleHighlight: PropTypes.func.isRequired,
  session: PropTypes.object.isRequired,
};

export default SessionLine;
