import { call, put, takeLatest } from 'redux-saga/effects';
import fileDownload from 'js-file-download';

import { Export } from 'services/api';
import { callApi } from 'services/rest';
import { notifyError, notifySuccess } from 'services/notification/actions';

import { download, downloadSuccessful, downloadFailure } from 'services/entityTable/actions';
import { csvTimeZoneCorrection } from 'utils/csvTimeZoneCorrection';
import { getDateForFileName } from 'utils/getDateForFileName';

function* watchExportDownload({ payload: { resource, columns, filter, sort, discriminator } }) {
  try {
    const { data } = yield call(
      callApi,
      Export.download,
      { resource, columns, discriminator },
      {
        filter,
        sort,
      }
    );

    const correctedCsv = csvTimeZoneCorrection(data);
    fileDownload(correctedCsv, `${resource}_${getDateForFileName()}.csv`);
    yield put(downloadSuccessful(resource));
    yield put(notifySuccess(`Downloaded successfully.`));
  } catch (e) {
    console.log(e)
    yield put(downloadFailure(resource));
    yield put(notifyError(`Failed to generate list.`));
  }
}

export default () => takeLatest(download, watchExportDownload);
