import { createAction } from 'redux-actions';

export const createManufacturer = createAction('CREATE_MANUFACTURER_REQUEST');
export const createManufacturerFailure = createAction('CREATE_MANUFACTURER_FAILURE');
export const createManufacturerSuccess = createAction('CREATE_MANUFACTURER_SUCCESS');

export default {
  createManufacturer,
  createManufacturerFailure,
  createManufacturerSuccess
};
