import React from 'react'
import PropTypes from 'prop-types'
import { Badge, Card, CardBody, CardHeader, Table } from 'reactstrap'
import cn from 'classnames'
import { Link } from 'react-router-dom';

import { FRIENDSHIP_STATUSES } from 'constants/Friends'

const ColoredCheck = ({ condition }) => {
  return (
    <Badge
      color={cn({
        success: condition,
        secondary: !condition,
      })}
    >
      {condition ? 'True' : 'False'}
    </Badge>
  )
}

const avatarContainer = {
  width: '32px',
  height: '32px',
  background: 'rgb(218, 226, 232)',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '8px',
  overflow: 'hidden',
}

const UserFriends = ({ friends, userId, userNickname }) => {
  return (
    <div>
      <Card color="white">
        <CardHeader>
          <div className="card-header__left">
            <i className="fa fa-align-justify" /> {userNickname ?? 'User'}'s friendships
          </div>
        </CardHeader>
        <CardBody>
          <Table responsive>
            <thead>
              <tr>
                <th>User</th>
                <th>Country</th>
                <th>Given account access to friend</th>
                <th>Received account access by friend</th>
                <th>Given board access to friend</th>
                <th>Received board access by friend</th>
              </tr>
            </thead>
            {friends && Array.isArray(friends) && friends.length > 0 ? (
              <tbody>
                {friends?.map(friend => {
                  const friendId = Object.keys(friend?.permissions)?.find(elem => elem !== userId)
                  return (
                    <tr key={friendId}>
                      <td>
                        <Link to={`/users/${friend?.userId}`} style={{ display: 'flex', alignItems: 'center' }}>
                          <span style={avatarContainer}>
                            {friend?.avatar &&
                              <img src={friend?.avatar} alt="avatar" style={{ maxWidth: '30px', maxHeight: '30px' }} />
                            }
                          </span>
                          <span>
                            {friend.nickname}
                          </span>
                        </Link>
                      </td>
                      <td>{friend.country}</td>
                      <td><ColoredCheck condition={friend?.permissions[friendId]?.accountAccess} /></td>
                      <td><ColoredCheck condition={friend?.permissions[userId]?.accountAccess} /></td>
                      <td><ColoredCheck condition={friend?.permissions[friendId]?.boardAccess} /></td>
                      <td><ColoredCheck condition={friend?.permissions[userId]?.boardAccess} /></td>
                    </tr>
                  )  
                })}
              </tbody>
            ) : null}
          </Table>
          {!(friends && Array.isArray(friends) && friends.length > 0) &&
            <div style={{ margin: '8px' }}>
              {userNickname ?? 'User'} has no accepted friendship.
            </div>
          }
        </CardBody>
      </Card>
    </div>
  )
}

UserFriends.propTypes = {
  userId: PropTypes.string.isRequired,
  friends: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string,
      country: PropTypes.string,
      customFlag: PropTypes.string,
      from: PropTypes.string,
      to: PropTypes.string,
      nickname: PropTypes.string.isRequired,
      permissions: PropTypes.object.isRequired,
      status: PropTypes.oneOf([
        FRIENDSHIP_STATUSES.Accepted,
        FRIENDSHIP_STATUSES.Pending
      ]),
      userId: PropTypes.string.isRequired,
    })
  )
}

export default UserFriends