import { all } from 'redux-saga/effects';

import watchInfoNotifications from './watchers/InfoNotifications';
import watchWarningNotifications from './watchers/WarningNotifications';
import watchErrorNotifications from './watchers/ErrorNotifications';
import watchDeleteNotification from './watchers/DeleteNotification';

export default function* NotificationSaga() {
  yield all([
    watchInfoNotifications(),
    watchWarningNotifications(),
    watchErrorNotifications(),
    watchDeleteNotification(),
  ]);
}
