import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'reactstrap';
import update from 'immutability-helper';

import { CollapseList } from 'scenes/StoreSnapshot/components/StoreTable/components/CollapseList';
import { ToggleFilter } from 'scenes/StoreSnapshot/components/StoreTable/components/ToggleFilter';

import { LobbyInvitation } from './components';

import styles from './styles.module.scss';

const filters = [
  {
    id: 'resolvedAtIsNull',
    label: 'not yet resolved',
    background: '#43C108',
  },
  {
    id: 'total',
    label: 'Total',
    readonly: true,
    background: '#8252EA',
  },
];

const columns = [
  {
    label: 'From',
    size: 2,
  },

  {
    label: 'To',
    size: 2,
  },
  {
    label: 'Sent at',
    size: 3,
  },
  {
    label: 'Resolved at',
    size: 2,
  },
  {
    label: 'Status',
    size: 1,
  },
  {
    label: '',
    size: 1,
  },
];

export class LobbyInvitations extends Component {
  constructor(props) {
    super(props);
    this.lobbyInvitationsListRef = React.createRef();
    this.state = {
      allOpened: false,
    };

    // Filters initialization
    this.state = {
      ...this.state,
      ...filters.reduce(
        (acc, curr) => {
          acc.filters[curr.id] = true;
          return acc;
        },
        { filters: {} }
      ),
    };
  }

  toggleAll = () => {
    this.state.allOpened
      ? this.lobbyInvitationsListRef.current.closeAll()
      : this.lobbyInvitationsListRef.current.openAll();
    this.setState({ allOpened: !this.state.allOpened });
  };

  onCollapseListChange = () => {
    this.lobbyInvitationsListRef.current.isAllOpened() &&
      this.setState({ allOpened: true });
    this.lobbyInvitationsListRef.current.isAllClosed() &&
      this.setState({ allOpened: false });
  };

  get lobbyInvitations() {
    const { lobbyInvitations } = this.props;

    const {
      filters: { resolvedAtIsNull},
    } = this.state;

    let filteredLobbyInvitations = [];

    filteredLobbyInvitations = filteredLobbyInvitations.concat(
      lobbyInvitations.filter(
        invitation => resolvedAtIsNull && invitation.resolvedAt === null
      )
    );

    return filteredLobbyInvitations;
  }

  get lobbyInvitationsAvailabiltyCount() {
    const { lobbyInvitations } = this.props;

    const resolvedAtIsNull = lobbyInvitations.filter(
      invitation => invitation.resolvedAt === null
    ).length;

    return { resolvedAtIsNull, total: lobbyInvitations.length };
  }

  toggleFilter = filterId => {
    this.setState(
      update(this.state, {
        filters: {
          [filterId]: {
            $set: !this.state.filters[filterId],
          },
        },
      })
    );
  };

  
  render() {
    const { allOpened } = this.state;
    const { isLoading, handleDelete } = this.props;
    return (
      <Fragment>
        <div className={styles.topbarWrapper}>

          <div className={styles.filterWrapper}>
            {filters.map(filter => (
              <ToggleFilter
                id={filter.id}
                key={filter.id}
                label={filter.label}
                count={this.lobbyInvitationsAvailabiltyCount[filter.id]}
                checked={this.state.filters[filter.id]}
                onToggleFilter={this.toggleFilter}
                background={filter.background}
                readonly={filter.readonly}
              />
            ))}
          
          </div>
          <div className={styles.accordionToggleButton}>
            <div onClick={this.toggleAll} className={styles.toggleButton}>
              {allOpened ? 'Close' : 'Open'} all
            </div>
          </div>
        </div>
        <div className={styles.tableHeader}>
          <Row>
            {columns.map(column => (
              <Col key={column.label} xs={column.size}>
                {column.label}
              </Col>
            ))}
          </Row>
        </div>
        {isLoading && (
          <div className={styles.spinnerWrapper}>
            <i className="fa fa-spinner fa-spin fa-fw" />
          </div>
        )}
        <div style={{ display: isLoading ? 'none' : 'block'}}>
          <CollapseList
            ref={this.lobbyInvitationsListRef}
            onChange={this.onCollapseListChange}
          >
          {
            this.lobbyInvitations.map(invite => (
              <LobbyInvitation key={invite.id} {...invite} handleDelete={handleDelete}/>
            ))
          }
          </CollapseList>
        </div>
      </Fragment>
    )
  }
}

LobbyInvitations.propTypes = {
  lobbyInvitations: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

LobbyInvitations.defaultProps = {
  isLoading: true,
  lobbyInvitations: [],
};

export default LobbyInvitations;
