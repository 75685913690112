import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import actions from './actions';
import { getFirmware, getFirmwareSuccess, getFirmwareFailure } from 'scenes/Firmwares/actions';

const INITIAL_STATE = {
  isLoading: false,
};

const editReducer = handleActions(
  {
    [actions.updateFirmware]: state =>
      update(state, {
        isUpdatePending: {
          $set: true,
        },
        updateError: {
          $set: '',
        },
      }),

    [actions.updateFirmwareSuccess]: state =>
      update(state, {
        isUpdatePending: {
          $set: false,
        },
        isUpdateSuccessful: {
          $set: true,
        },
        updateError: {
          $set: '',
        },
      }),

    [actions.updateFirmwareFailure]: (state, { payload }) =>
      update(state, {
        isUpdatePending: {
          $set: false,
        },
        isUpdateSuccessful: {
          $set: false,
        },
        updateError: {
          $set: payload,
        },
      }),

    [getFirmware]: (state, { payload: { isLoading } }) =>
      update(state, {
        isLoading: {
          $set: isLoading,
        },
        $unset: ['isUpdateSuccessful'],
      }),

    [getFirmwareSuccess]: state => ({
      ...state,
      isLoading: false,
    }),

    [getFirmwareFailure]: state => ({
      ...state,
      isLoading: false,
    }),

    [actions.deleteFirmwareFailure]: (state, { payload }) =>
      update(state, {
        updateError: {
          $set: payload,
        },
      }),
  },
  INITIAL_STATE
);

export default editReducer;
