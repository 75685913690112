import { createAction } from 'redux-actions';

export const getPrivateTournaments = createAction('PRIVATE_TOURNAMENTS_GET_REQUEST');
export const getPrivateTournamentsFailure = createAction('PRIVATE_TOURNAMENTS_GET_FAILURE');
export const getPrivateTournamentsSuccess = createAction('PRIVATE_TOURNAMENTS_GET_SUCCESS');

export default {
  getPrivateTournaments,
  getPrivateTournamentsFailure,
  getPrivateTournamentsSuccess,
};
