import { handleActions } from 'redux-actions';

import { getTokenPurchasesSuccess } from "scenes/TokenPurchases/components/List/actions"

const INITIAL_STATE = [];

const tokenPurchasesListReducer = handleActions(
  {
    [getTokenPurchasesSuccess]: (state, { payload: { data } }) => {
      return data;
    },
  },
  INITIAL_STATE
);

export default tokenPurchasesListReducer;
