import { createAction } from 'redux-actions';

export const getProductsList = createAction('PRODUCTS_LIST_GET_REQUEST', payload => ({ queryParams: payload }));
export const getProductsListFailure = createAction('PRODUCTS_LIST_GET_FAILURE');
export const getProductsListSuccess = createAction('PRODUCTS_LIST_GET_SUCCESS');

export default {
  getProductsList,
  getProductsListFailure,
  getProductsListSuccess,
};
