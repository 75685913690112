import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import { actions as downloadActions } from 'services/entityTable';
import { scoreCorrectionStatistics } from 'constants/Resources';

interface ScoreCorrectionStatisticsState {
  isDownloading: boolean;
}

const INITIAL_STATE: ScoreCorrectionStatisticsState = {
  isDownloading: false,
};

const list = handleActions(
  {
    [String(downloadActions.download)]: (state, { payload: { resource } }: any) => {
      if (resource === scoreCorrectionStatistics) {
        return update(state, {
          isDownloading: {
            $set: true,
          },
        });
      } else {
        return state;
      }
    },

    [String(downloadActions.downloadSuccessful)]: (state, { payload }: any) => {
      if (payload === scoreCorrectionStatistics) {
        return update(state, {
          isDownloading: {
            $set: false,
          },
        });
      } else {
        return state;
      }
    },

    [String(downloadActions.downloadFailure)]: (state, { payload }: any) => {
      if (payload === scoreCorrectionStatistics) {
        return update(state, {
          isDownloading: {
            $set: false,
          },
        });
      } else {
        return state;
      }
    },
  },

  INITIAL_STATE
);

export default list;
