import { all } from 'redux-saga/effects';

import { sagas as listFirmwaresSaga } from 'scenes/Firmwares/components/List';
import { sagas as createFirmwareSaga } from 'scenes/Firmwares/components/Create';
import { sagas as editFirmwaresSaga } from 'scenes/Firmwares/components/Edit';
import { sagas as showFirmwaresSaga } from 'scenes/Firmwares/components/Show';
import watchDeleteFirmware from './watchers/DeleteFirmware';
import watchDownloadFirmware from './watchers/DownloadFirmware';

export default function* firmwaresSaga() {
  yield all([
    createFirmwareSaga(),
    listFirmwaresSaga(),
    editFirmwaresSaga(),
    showFirmwaresSaga(),
    watchDeleteFirmware(),
    watchDownloadFirmware(),
  ]);
}
