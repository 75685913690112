import { createAction } from 'redux-actions';

export const updateBoard = createAction('BOARD_UPDATE_REQUEST');
export const updateBoardFailure = createAction('BOARD_UPDATE_FAILURE');
export const updateBoardSuccess = createAction('BOARD_UPDATE_SUCCESS');

export default {
  updateBoard,
  updateBoardFailure,
  updateBoardSuccess,
};
