import React from 'react'

const FlexBadge = () => {
  return (
    <svg width="58" height="24" viewBox="0 0 58 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.22877 18.1387C3.32888 8.12095 13.3288 0 23.5643 0H57.9828L56.7541 5.86133C54.654 15.879 44.654 24 34.4185 24H0L1.22877 18.1387Z" fill="url(#paint0_linear_738_13775)"/>
      <path d="M20.9051 9.85111H19.3038L18.1522 15.5781H15.8986L17.5997 7.11846C17.8219 6.01377 18.5703 5.38874 20.127 5.38874H21.9803L21.6734 6.91496H20.6949C20.1018 6.91496 19.8709 7.03125 19.7715 7.52545L19.6429 8.16501H21.2442L20.9051 9.85111ZM23.1783 15.5781H20.9247L22.9737 5.38874H25.2273L23.1783 15.5781Z" fill="white"/>
      <path d="M32.7542 11.9587L27.4846 12.8745L27.3881 13.3541C27.277 13.9065 27.4698 13.9065 28.2852 13.9065H32.3476L32.0115 15.5781H26.7037C25.31 15.5781 24.813 14.953 25.0439 13.8047L25.8185 9.95286C26.0728 8.68828 27.0083 8.16501 28.1944 8.16501H31.5303C32.9388 8.16501 33.3855 8.8191 33.1517 9.98193L32.7542 11.9587ZM30.711 10.9122L30.7987 10.4761C30.8951 9.99647 30.7761 9.85111 30.3461 9.85111H28.7152C28.226 9.85111 28.0691 9.9674 27.9698 10.4616L27.7739 11.4355L30.711 10.9122Z" fill="white"/>
      <path d="M34.4195 8.16501H36.9548L37.9044 10.4471L39.8163 8.16501H42.2478L38.9849 11.857L40.8312 15.5781H38.2959L37.2628 13.1216L35.212 15.5781H32.736L36.1409 11.7698L34.4195 8.16501Z" fill="white"/>
      <defs>
        <linearGradient id="paint0_linear_738_13775" x1="-2.82964" y1="24" x2="42.4475" y2="-17.1101" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0060A9"/>
          <stop offset="1" stopColor="#133385"/>
        </linearGradient>
      </defs>
    </svg>
  )
}

FlexBadge.displayName = 'FlexBadge';

export default FlexBadge