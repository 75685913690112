import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import { actions as manufacturersActions } from 'scenes/Manufacturers';
import { actions as listActions } from 'scenes/Manufacturers/components/List';
import { actions as showActions } from 'scenes/Manufacturers/components/Show';

const INITIAL_STATE = {};

const manufacturersReducer = handleActions(
  {
    [listActions.getManufacturersSuccess]: (_state, { payload }) => {
      const manufacturers = payload.reduce((manufacturers, manufacturer) => {
        manufacturers[manufacturer._id] = manufacturer;
        return manufacturers;
      }, {});

      return {
        ...manufacturers,
      };
    },

    [manufacturersActions.getManufacturerSuccess]: (state, { payload: manufacturer }) => {
      manufacturer.id = manufacturer._id;
      delete manufacturer._id;
      return update(state, {
        [manufacturer.id]: {
          $set : manufacturer,
        },
      });
    },

    [showActions.getManufacturerBoardsSuccess]: (state, { payload }) => {
      const manufacturerId = payload.manufacturerId;
      const activeBoardsCount = payload.activeBoardsCount;

      const manufacturerBoards = payload.boards.reduce((boards, board) => {
        boards[board._id] = board;
        return boards;
      }, {});

      return update(state, {
        [manufacturerId] : {
          $set : {
            ...state[manufacturerId],
            manufacturerBoards : { ...manufacturerBoards },
            activeBoardsCount: activeBoardsCount,
          },
        },
      });
    },
  },
  INITIAL_STATE
);

export default manufacturersReducer;
