import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Toggle } from 'components/Toggle';

import createMarkup from 'utils/createMarkup';

import styles from './styles.module.scss';

const COLORS = {
  success: '#5aab30',
  warning: '#ffc700',
  info: '#1b70e0',
  error: '#f25f5f',
};

const Preview = ({ title, content, type, isIconHidden, isControllable, name, onChange, disabled, value, isKeepToggleBright }) => {

  return (
    <div
      className={cn(styles.notificationWrapper, { [styles.isContentHidden]: isControllable, [styles.disabled]: disabled })}
      style={{ borderColor: COLORS[type] }}
    >
      {!isIconHidden && (
        <div className={styles.notificationIcon}>
          <div className={styles.icon} style={{ backgroundColor: COLORS[type] }}>
            <div className={styles.dot}></div>
            <div className={styles.letter}></div>
          </div>
        </div>
      )}
      <div className={cn(styles.notificationBody, { [styles.isControllable]: isControllable } )}>
        <div className={cn(styles.notificationTitle, { [styles.isThinTitle]: isControllable })}>
          {title === '' ? 'Notification title' : title}
        </div>
        {!isControllable && (
          <div
            className={styles.notificationContent}
            dangerouslySetInnerHTML={createMarkup(content === '' ? 'Notification content' : content)}
          />
        )}
        {isControllable && 
          <Toggle
            name={name}
            onChange={onChange}
            disabled={disabled}
            value={value}
            isKeepToggleBright={isKeepToggleBright}
          />
        }
      </div>
    </div>
  );
};

Preview.propTypes = {
  content: PropTypes.string,
  title: PropTypes.string,
  isIconHidden: PropTypes.bool,
  isControllable: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.bool,
  isKeepToggleBright: PropTypes.bool,
};

export default Preview;
