import React from 'react';
import { GeneralStatistics } from './components/GeneralStatistics';
import { GameSpecificStatistics } from './components/GameSpecificStatistics';
export const GameStatistics = ({ selectedGameType, userStats }) => {
    const { gamesCount } = userStats;
    if (!gamesCount) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(GeneralStatistics, { selectedGameType: selectedGameType, userStats: userStats }),
        React.createElement(GameSpecificStatistics, { selectedGameType: selectedGameType, userStats: userStats })));
};
