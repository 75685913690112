import React from 'react';
import cn from 'classnames';
import { ThemesEnum } from 'enums';
import { PackageOption } from './PackageOption.component';
import { SLabelContainer } from '../SLabelContainer';
import styles from './styles.module.scss';
export const SPackageRadio = ({ className = '', options, value, onSelect, label, isRequired, isVertical, name, showLabel, error, hint, disabled, theme = ThemesEnum.Light }) => {
    return (React.createElement(SLabelContainer, { label: label, isRequired: isRequired, name: name, showLabel: showLabel, error: error, hint: hint },
        React.createElement("div", { className: cn(styles.packagesWrapper, [className], {
                [styles.isVertical]: isVertical
            }) }, options.map((elem) => (React.createElement(PackageOption, { key: elem.value, onSelect: onSelect, isSelected: value === elem.value, theme: theme, name: name, ...elem, disabled: disabled || elem.disabled }))))));
};
SPackageRadio.displayName = 'SPackageRadio';
