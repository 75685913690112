import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import { actions as hardwareActions } from 'scenes/Hardwares/components/List';
import { actions as platformActions } from 'scenes/Platforms/components/List'

import { BoardForm } from 'scenes/Boards/components/BoardForm';

import { getGeneratedSerialNumbers } from './selectors';

import actions from './actions';

class Create extends Component {
  constructor(props) {
    super(props);

    this.props.dispatch(hardwareActions.getHardwares());
    this.props.dispatch(platformActions.getPlatforms());
  }

  componentWillUnmount(){
    this.props.dispatch(actions.resetCreateMultipleBoardsState());
  }

  handleFormSubmit = (values, _actions) => {
    if (this.props.isMultiple) {
      this.props.dispatch(
        actions.createMultipleBoards({
          values: {
            ...values,
            numberOfBoardsToGenerate: parseInt(
              values.numberOfBoardsToGenerate,
              10
            ),
          },
        })
      );
    } else {
      delete values.numberOfBoardsToGenerate;

      this.props.dispatch(
        actions.createBoard({
          board: values,
        })
      );
    }
  };

  render() {
    const {
      formError,
      isMultiple,
      generatedSerialNumbers,
    } = this.props;

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" lg={{ size: 10, offset: 1 }}>
            <Card color="white">
              <CardHeader>
                <div className="card-header__left">
                  <i className="fa fa-align-justify" /> New Board
                </div>
              </CardHeader>
              <CardBody>
                <BoardForm
                  formError={formError}
                  onSubmit={this.handleFormSubmit}
                  isMultiple={isMultiple}
                  generatedSerialNumbers={generatedSerialNumbers}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

Create.propTypes = {
  formError: PropTypes.string,
  isMulitple: PropTypes.bool,
};

const mapStateToProps = state => ({
  formError: state.scenes.boards.create.createError,
  generatedSerialNumbers: getGeneratedSerialNumbers(state),
});

export default connect(mapStateToProps)(Create);
