import { call, put, takeLatest } from 'redux-saga/effects';

import { Platforms } from 'services/api';
import { notifyError, notifySuccess } from 'services/notification/actions';

import { browserHistory } from 'services/history';
import { callApi } from 'services/rest';

import {
  updatePlatform,
  updatePlatformFailure,
  updatePlatformSuccess,
} from 'scenes/Platforms/components/Edit/actions';

function* watchUpdatePlatform({ payload: { platformId, description } }) {
  try {
    const { data: updatedPlatform } = yield call(callApi, Platforms.update, platformId, {
      description: description,
    });
    yield put(updatePlatformSuccess(updatedPlatform));
    yield put(notifySuccess(`Platform was updated successfully.`));

    browserHistory.push(`/platforms/${platformId}`);
  } catch (e) {
    if (!e.isFetch) {
      throw e;
    }
    yield put(updatePlatformFailure(e.data.message));
    yield put(notifyError('Failed to update platform.'));
  }
}

export default () => takeLatest(updatePlatform, watchUpdatePlatform);
