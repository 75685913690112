export const getRankings = state => ({
  data: state.data.rankings,
  page: state.scenes.rankings.list.page,
  pageSize: state.scenes.rankings.list.pageSize,
  totalCount: state.scenes.rankings.list.totalCount,
  isLoading: state.scenes.rankings.list.isLoading,
  isDownloading: state.scenes.rankings.list.isDownloading
})

export default {
  getRankings,
};
