import React from 'react'
import PropTypes from 'prop-types'

import { Card, CardBody, CardHeader } from 'reactstrap'

import CURRENCIES from 'constants/PaymentCurrencies'

import TableRow from 'components/TableRow'

const NextPayment = ({ isLoading, data }) => {
  return (
    <Card color="white">
      <CardHeader>
        <div className="card-header__left">
          <i className="fa fa-align-justify" /> NextPayment
        </div>
      </CardHeader>
      {!isLoading && data && (
        <CardBody>
          <TableRow.Default label="amount" value={data.amount} />
          <TableRow.Default label="currency" value={data.currency} />
          <TableRow.Date label="date" value={data.date} />
        </CardBody>
      )}
    </Card>
  )
}

NextPayment.displayName = 'NextPayment';

NextPayment.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    nextPayment: PropTypes.shape({
      amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      currency: PropTypes.oneOf(Object.keys(CURRENCIES)),
      date: PropTypes.string,
    }),
  })
}

export default NextPayment