import { DateTime } from 'luxon'

const oneDayInMS = 24 * 60 * 60 * 1000; // one day in milliseconds (24 hours * 60 minutes * 60 seconds * 1000 milliseconds)

export function formatDate(date: string, displayTime = false) {
  return DateTime.fromISO(date).toFormat(`LLL dd, yyyy ${displayTime ? '• HH:mm' : ''}`)
}

export const getFormattedDuration = (ms: number): string => {
  if (isNaN(ms)) {
    console.warn(`Expected milliseconds as number, received '${ms}'`);
    return 'N/A';
  }

  let days = Math.floor(ms / (oneDayInMS));
  let daysms = ms % (oneDayInMS);
  let hours = Math.floor(daysms / (60 * 60 * 1000));
  let hoursms = ms % (60 * 60 * 1000);
  let minutes = Math.floor(hoursms / (60 * 1000));
  let minutesms = ms % (60 * 1000);
  let seconds = Math.floor(minutesms / 1000);

  let times = [
    days && `${days}${days > 1 ? 'days' : 'day'}`,
    hours && `${hours}${hours > 1 ? 'h' : 'h'}`,
    minutes && `${minutes}${minutes > 1 ? 'm' : 'm'}`,
    seconds && `${seconds}${seconds > 1 ? 's' : 's'}`,
  ].filter(Boolean);

  if (times.length > 0) {
    return times.join(' ');
  }

  return 'N/A';
};

export function extractTime(dateStr: string): string {
  const dt = DateTime.fromISO(dateStr);

  const ret = dt.setLocale("en").toFormat('HH:mm');

  if (ret === 'Invalid DateTime') {
    return ''
  }

  return ret
}

export const formTimers = (timer: number) => {
  if (!timer) {
    return '-';
  }

  return `${Math.floor(timer / 60)}:${timer % 60 < 10 ? `0${Math.floor(timer % 60)}` : Math.floor(timer % 60)}`;
};
