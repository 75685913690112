import {
  get,
  // patch, post, del
} from 'services/rest';

const url = '/entity-logs';

const ResourceLogs = {
  index: queryParams => get(url, queryParams),
};

export default ResourceLogs;
