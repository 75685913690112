import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { Card, CardHeader, CardBody, Col, Row } from 'reactstrap';
import Matches from './components/Matches/Matches';
import { MainData } from './components/MainData/MainData.component';
import { Participants } from './components/Participants/Participants.component';
import { BackToTop } from 'components/BackToTop';
import createMarkup from 'utils/createMarkup';
import styles from './styles.module.scss';
import { isUndefined } from 'lodash';
const TournamentShow = ({ tournamentId, tournament, isLoading, isEditable, onDeleteTournament, onKillTournament, getTournamentAction, getTournamentSelector, subscribeAction, unsubscribeAction }) => {
    const dispatch = useDispatch();
    const [isMatches, setMatches] = useState(false);
    useEffect(() => {
        dispatch(getTournamentAction(tournamentId));
        dispatch(subscribeAction({ channel: 'tournament', tournamentId: tournamentId }));
        return () => {
            dispatch(unsubscribeAction({ channel: 'tournament', tournamentId: tournamentId }));
        };
    }, [dispatch, tournamentId, getTournamentAction, subscribeAction, unsubscribeAction]);
    const handleDelete = () => {
        const result = window.confirm(`Are you sure you want to delete this tournament?`);
        if (result) {
            onDeleteTournament(tournamentId);
        }
    };
    const handleKill = () => {
        if (isUndefined(onKillTournament)) {
            console.warn('onKillTournament is not implemented.');
            return;
        }
        const result = window.confirm(`Are you sure you want to gracefully kill this tournament?`);
        if (result) {
            onKillTournament(tournamentId);
        }
    };
    const handleRefresh = () => {
        dispatch(getTournamentAction(tournamentId));
    };
    const { description, stages } = tournament;
    if (isLoading) {
        return (React.createElement("i", { className: "fa fa-spinner fa-spin fa-fw" }));
    }
    return (React.createElement("div", { className: cn('animated', 'fadeIn', 'backToTopScrollable', styles.container) },
        React.createElement("div", { className: 'mw-100' },
            React.createElement("div", { className: styles.row },
                React.createElement(MainData, { tournament: tournament, isEditable: isEditable, onDelete: handleDelete, onRefresh: handleRefresh, onKill: handleKill }),
                React.createElement(Card, { color: "white" },
                    React.createElement(CardHeader, null,
                        React.createElement("div", { className: "card-header__left" },
                            React.createElement("i", { className: "fa fa-align-justify" }),
                            " Description")),
                    React.createElement(CardBody, null,
                        React.createElement("div", { dangerouslySetInnerHTML: createMarkup(description) })))),
            React.createElement(Row, null,
                !isUndefined(stages) && (React.createElement("div", { className: styles.buttonContainer },
                    React.createElement("button", { className: "btn btn-primary btn-sm ms-1", type: "button", disabled: !isMatches, onClick: () => setMatches(false) }, "Participants"),
                    React.createElement("button", { className: "btn btn-primary btn-sm ms-1", type: "button", disabled: isMatches, onClick: () => setMatches(true) }, "Matches"))),
                React.createElement(Col, { xs: "12", md: "12", lg: "12" }, isMatches ? (React.createElement(Matches, { getTournamentSelector: getTournamentSelector })) : (React.createElement(Participants, { getTournamentSelector: getTournamentSelector })))),
            React.createElement(BackToTop, null))));
};
TournamentShow.displayName = 'TournamentShow';
export default TournamentShow;
