import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import cn from 'classnames';

import { Input } from 'components/Form';

import { onlyNumbers } from 'utils';

import styles from './styles.module.scss';

const MIN_NUMBER_OF_DAYS_TO_COPY = 1;

export const ConfirmCopyModal = ({ isOpen, toggle, filters, onCopy }) => {
  const [daysToCopy, setDaysToCopy] = useState(MIN_NUMBER_OF_DAYS_TO_COPY);

  const onDaysChange = (event) => {
    setDaysToCopy(event.target.value);
  }

  const isInvalid = !daysToCopy || daysToCopy < 1;

  const handleCopy = () => {
    onCopy({ days: parseInt(daysToCopy), filter: filters });
    toggle();
  };

  useEffect(() => {
    setDaysToCopy(1);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} contentClassName={styles.modal}>
      <ModalHeader toggle={toggle} className={styles.header}>Copy last N days tournaments</ModalHeader>

      <ModalBody className="d-flex">
        <label className="m-0 mt-1 me-2">Number of days to copy:</label>
        <Input.Number
          name="tournamentDaysToCopy"
          className={cn(styles.input, 'mb-2')}
          value={daysToCopy}
          min={MIN_NUMBER_OF_DAYS_TO_COPY}
          onChange={onDaysChange}
          onKeyDown={onlyNumbers}
          error={isInvalid && 'Number of days must be greater than 0'}
        />
      </ModalBody>

      <ModalFooter>
        <Button color="primary" className={cn(styles.button, 'm-0')} disabled={isInvalid} onClick={handleCopy}>Submit</Button>
      </ModalFooter>
    </Modal>
  )
}

ConfirmCopyModal.displayName = 'ConfirmCopyModal';

ConfirmCopyModal.propTypes = {}
