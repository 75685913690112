import { call, put, takeLatest } from 'redux-saga/effects';

import { Notifications } from 'services/api';

import { callApi } from 'services/rest';
import { notifyError, notifySuccess } from 'services/notification/actions';

import {
  getNotifications,
  updateVisibilityRequest,
  updateVisibilityFailure,
  updateVisibilitySuccess,
} from 'scenes/Notifications/actions';

function* watchUpdateVisibility( payload ) {
  try {
    yield call(callApi, Notifications.updateVisibility, payload.payload);
    yield put(updateVisibilitySuccess());
    yield put(notifySuccess(`Notifications' visibility has been updated successfully.`));
    
    yield put(getNotifications());
  } catch (e) {
    yield put(updateVisibilityFailure(e));
    yield put(notifyError(`Failed to update notifications' visibility.`));
  }
}

export default () => takeLatest(updateVisibilityRequest, watchUpdateVisibility);
