import { call, put, takeLatest } from 'redux-saga/effects';

import { Manufacturers } from 'services/api';
import { callApi } from 'services/rest';

import {
  getManufacturers,
  getManufacturersFailure,
  getManufacturersSuccess,
} from 'scenes/Manufacturers/components/List/actions';

function* watchGetManufacturers({ payload: { queryParams } }) {
  try {
    const { data } = yield call(callApi, Manufacturers.allWithNumberOfBoards, queryParams);
    yield put(getManufacturersSuccess(data));
  } catch (e) {
    yield put(getManufacturersFailure());
  }
}

// It must be wrapped in a function, so execution will happen only on function call
export default () => takeLatest(getManufacturers, watchGetManufacturers);
