import { call, put, takeLatest } from 'redux-saga/effects';

import { Subscriptions } from 'services/api';
import { callApi } from 'services/rest';

import {
  getSubscriptionsList,
  getSubscriptionsListFailure,
  getSubscriptionsListSuccess,
} from 'scenes/Subscriptions/components/List/actions';

function* watchGetSubscriptionsList({ payload }) {
  try {
    const { data } = yield call(callApi, Subscriptions.index, payload);
    yield put(getSubscriptionsListSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(getSubscriptionsListFailure());
  }
}

// It must be wrapped in a function, so execution will happen only on function call
export default () => takeLatest(getSubscriptionsList, watchGetSubscriptionsList);
