import React from 'react'
import PropTypes from 'prop-types';

import TableRow from 'components/TableRow'

import styles from "../styles.module.scss"

const ViewerWrapper = ({ user }) => {
  return (
    <>
      <TableRow.Boolean label="Excessive usage email" value={user.boardExcessiveUsageEmail} />
      <TableRow.Boolean label="Excessive usage notification" value={user.boardExcessiveUsageNotification} />
      <TableRow.Boolean label="Banned from tournaments" value={user.isBannedFromTournaments} />
      {user.isBannedFromTournaments &&
        <div className={styles.dateRow}>
          <div className='col-md-12'>Banned Period</div>
          <TableRow.Date label="Date from" value={user.bannedPeriod.bannedFrom} />
          <TableRow.Date label="Date to" value={user.bannedPeriod.bannedTo} />
        </div>
      }
      <TableRow.Boolean label="Email is active" value={user.isEmailActive} />
    </>
  )
}

ViewerWrapper.propTypes = {
  user: PropTypes.object.isRequired,
};

export default ViewerWrapper