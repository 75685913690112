import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import { CollapseList } from 'scenes/StoreSnapshot/components/StoreTable/components/CollapseList';
import { ToggleFilter } from 'scenes/StoreSnapshot/components/StoreTable/components/ToggleFilter';

import { LobbyParticipant } from './components/LobbyParticipant';

import styles from './styles.module.scss';

const filters = [
  {
    id: 'online',
    label: 'Online',
    background: '#43C108',
  },
  {
    id: 'inGame',
    label: 'InGame',
    background: '#FFC700',
  },
  {
    id: 'total',
    label: 'Total',
    readonly: true,
    background: '#8252EA',
  },
];

const columns = [
  {
    label: 'Name',
    size: 3,
  },

  {
    label: 'Availabilty',
    size: 3,
  },
  {
    label: 'SBC',
    size: 6,
  },
];

class LobbyParticipants extends Component {
  constructor(props) {
    super(props);
    this.lobbyParticipantsListRef = React.createRef();
    this.state = {
      allOpened: false,
    };

    // Filters initialization
    this.state = {
      ...this.state,
      ...filters.reduce(
        (acc, curr) => {
          acc.filters[curr.id] = true;
          return acc;
        },
        { filters: {} }
      ),
    };
  }

  toggleAll = () => {
    this.state.allOpened
      ? this.lobbyParticipantsListRef.current.closeAll()
      : this.lobbyParticipantsListRef.current.openAll();
    this.setState({ allOpened: !this.state.allOpened });
  };

  onCollapseListChange = () => {
    this.lobbyParticipantsListRef.current.isAllOpened() &&
      this.setState({ allOpened: true });
    this.lobbyParticipantsListRef.current.isAllClosed() &&
      this.setState({ allOpened: false });
  };

  get getLobbyParticipants() {
    const { lobbyParticipants } = this.props;

    const {
      filters: { online, inGame },
    } = this.state;

    let filteredLobbyParticipants = [];

    filteredLobbyParticipants = filteredLobbyParticipants.concat(
      lobbyParticipants.filter(
        participant => online && participant.availability === 'Online'
      )
    );
    filteredLobbyParticipants = filteredLobbyParticipants.concat(
      lobbyParticipants.filter(
        participant => inGame && participant.availability === 'InGame'
      )
    );

    return filteredLobbyParticipants;
  }

  get lobbyParticipantsAvailabiltyCount() {
    const { lobbyParticipants } = this.props;

    const online = lobbyParticipants.filter(
      participant => participant.availability === 'Online'
    ).length;

    const inGame = lobbyParticipants.filter(
      participant => participant.availability === 'InGame'
    ).length;

    return { online, inGame, total: lobbyParticipants.length };
  }

  toggleFilter = filterId => {
    this.setState(
      update(this.state, {
        filters: {
          [filterId]: {
            $set: !this.state.filters[filterId],
          },
        },
      })
    );
  };

  render() {
    const { allOpened } = this.state;
    const { isLoading } = this.props;

    return (
      <div>
        <div className={styles.topbarWrapper}>
          <div className={styles.filterWrapper}>
            {filters.map(filter => (
              <ToggleFilter
                id={filter.id}
                key={filter.id}
                label={filter.label}
                count={this.lobbyParticipantsAvailabiltyCount[filter.id]}
                checked={this.state.filters[filter.id]}
                onToggleFilter={this.toggleFilter}
                background={filter.background}
                readonly={filter.readonly}
              />
            ))}
          </div>

          <div className={styles.accordionToggleButton}>
            <div onClick={this.toggleAll} className={styles.toggleButton}>
              {allOpened ? 'Close' : 'Open'} all
            </div>
          </div>
        </div>

        <div className={styles.tableHeader}>
          <Row>
            {columns.map(column => (
              <Col key={column.label} xs={column.size}>
                {column.label}
              </Col>
            ))}
          </Row>
        </div>

        {isLoading && (
          <div className={styles.spinnerWrapper}>
            <i className="fa fa-spinner fa-spin fa-fw" />
          </div>
        )}
        <div style={{ display: isLoading ? 'none' : 'block' }}>
          <CollapseList
            ref={this.lobbyParticipantsListRef}
            onChange={this.onCollapseListChange}
          >
            {this.getLobbyParticipants.map(participant => (
              <LobbyParticipant key={participant.user._id} {...participant} />
            ))}
          </CollapseList>
        </div>
      </div>
    );
  }
}

LobbyParticipants.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  lobbyParticipants: PropTypes.array,
};

LobbyParticipants.defaultProps = {
  isLoading: true,
  lobbyParticipants: [],
};

export default LobbyParticipants;
