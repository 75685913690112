import React, { useMemo, useState } from 'react';
import { compact, isNull, isUndefined } from 'lodash';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { InviteParticipantElement } from './components';
import { ThemesEnum, TournamentParticipantStatusEnum } from 'enums';
import { ArrowIcon, ArrowIconDirection, InfoIcon } from 'components/Icons';
import { SButton, SButtonVariantOptionsEnum, SScrollContainer, SizesEnum } from 'ScoliaComponents';
import { validateEmail, validateScoliaId, parseClipboardText, countOccurrences, trimWhiteSpace } from 'utils';
import { notifyError, notifySuccess } from 'services/notification';
import { useInvitationValues } from './useInvitationValues.hook';
import { handleValidateUsers } from './useUserValidation.hook';
import styles from './styles.module.scss';
import { post } from 'services/rest';
import { useFetchInvitationalTournament } from 'scenes/Tournaments/hooks';
function findMetaData(participantsByScoliaId, participantsByEmail, value) {
    if (!value) {
        return undefined;
    }
    if (validateEmail(value)) {
        return participantsByEmail[value.toLocaleLowerCase()];
    }
    if (validateScoliaId(value)) {
        return participantsByScoliaId[value];
    }
    return undefined;
}
export const TournamentParticipantInvite = ({ tournamentId, invitedUsers, maxParticipants, participants = {}, disabled }) => {
    const availableInvitationsToSendCounter = maxParticipants - invitedUsers;
    const alreadyInvitedParticipantsScoliaIds = Object.values(participants).filter(elem => elem.status !== TournamentParticipantStatusEnum.Declined).map((elem) => (elem?.scoliaId));
    const [focusedInputIndex, setFocusedInputIndex] = useState(null);
    const [participantsMetaDataByScoliaId, setParticipantsMetaDataByScoliaId] = useState({});
    const [notExistingUsers, setNotExistingUsers] = useState([]);
    const [errors, setErrors] = useState([]);
    const [isTipOpen, setIsTipOpen] = useState(false);
    const { inputValues, setInputValueAt, removeInputValueAt, addMultipleValues, resetList } = useInvitationValues({ maxParticipants, participants });
    const { refetch } = useFetchInvitationalTournament(tournamentId);
    const participantsMetaDataByEmail = useMemo(() => {
        return Object.keys(participantsMetaDataByScoliaId).reduce((acc, key) => {
            const currentItem = participantsMetaDataByScoliaId[key];
            if (currentItem.email) {
                acc[currentItem.email.toLocaleLowerCase()] = currentItem;
            }
            return acc;
        }, {});
    }, [participantsMetaDataByScoliaId]);
    const collectIdsFromUsers = (values) => {
        return compact(values.map(elem => findMetaData(participantsMetaDataByScoliaId, participantsMetaDataByEmail, elem)?._id));
    };
    const dispatch = useDispatch();
    const handleSubmit = async () => {
        const userIds = collectIdsFromUsers(inputValues);
        const requestUrl = `/private-tournaments/${tournamentId}/invite`;
        try {
            const response = await post(requestUrl, { userIds });
            const sentInvitations = response.data.successfullyInvitedUserIds;
            dispatch(notifySuccess(`${sentInvitations.length} invitation${sentInvitations.length > 1 ? 's' : ''} sent successfully!`));
            resetList();
            refetch();
        }
        catch (e) {
            console.error(e);
            dispatch(notifyError(`Couldn't send invitation${userIds.length > 1 ? 's' : ''}!`));
        }
        setErrors([]);
    };
    const handleFocus = (index) => {
        setFocusedInputIndex(index);
    };
    const onParticipantValidationFailure = (values) => {
        setNotExistingUsers((curr) => [...curr, ...values]);
    };
    /**
     * Handle scolia id validation success
     * @param scoliaIdsAndEmails Scolia ids to validate without # prefix
     * @param data UserOptionResponseDto array
     */
    const onParticipantValidationSuccess = (scoliaIdsAndEmails, data) => {
        const validParticipants = data.reduce((acc, elem) => {
            if (elem && elem.scoliaId) {
                return { ...acc, [`#${elem.scoliaId}`]: elem };
            }
            return acc;
        }, {});
        const existingUsers = [];
        const notExistingUsers = [];
        scoliaIdsAndEmails.forEach((scoliaId) => {
            if (!scoliaId) {
                return;
            }
            const exists = data.some((elem) => `#${elem?.scoliaId}` === scoliaId || elem?.email === scoliaId);
            if (exists) {
                //User ids which are in the array mapped by data array
                existingUsers.push(scoliaId);
            }
            else {
                //User ids which are not in the array mapped by data array
                notExistingUsers.push(scoliaId);
            }
        });
        setNotExistingUsers((curr) => [...curr, ...notExistingUsers].filter((id) => !existingUsers.includes(id)));
        setParticipantsMetaDataByScoliaId((curr) => ({
            ...curr,
            ...validParticipants,
        }));
    };
    const handleBlur = (index) => {
        setFocusedInputIndex(null);
        const participantEmailOrScoliaId = inputValues[index];
        if (isUndefined(participantEmailOrScoliaId)) {
            return;
        }
        if (!participantsMetaDataByScoliaId[participantEmailOrScoliaId] || validateEmail(participantEmailOrScoliaId)) {
            handleValidateUsers([participantEmailOrScoliaId], onParticipantValidationSuccess, onParticipantValidationFailure, () => { });
        }
    };
    const handleEnter = (currIndex) => {
        let newIndex = null;
        //find the next empty input
        for (let i = currIndex + 1; i < inputValues.length; i++) {
            if (!inputValues[i]) {
                newIndex = i;
                break;
            }
        }
        //if we found it, then call focus on it, otherwise clear it
        if (newIndex && newIndex < inputValues.length) {
            document.getElementById(`participant_${newIndex}`)?.focus(); //apply focus for the next element
            setFocusedInputIndex(newIndex);
        }
        else {
            setFocusedInputIndex(null);
        }
    };
    const handlePaste = (event) => {
        event.preventDefault();
        const text = event.clipboardData.getData('text');
        const parsedLines = parseClipboardText(text).filter(elem => !!elem);
        const clearedParsedLines = parsedLines.map(trimWhiteSpace);
        addMultipleValues(clearedParsedLines);
        if (!isNull(focusedInputIndex)) {
            handleEnter(focusedInputIndex);
        }
        if (event.target instanceof HTMLInputElement) {
            event.target.blur();
        }
    };
    const handleTipToggle = () => {
        setIsTipOpen(x => !x);
    };
    return (React.createElement("div", { className: styles.tournamentParticipantInvitationModal },
        React.createElement("div", { className: styles.modalContent },
            React.createElement(SScrollContainer, { className: styles.scrollableContent },
                React.createElement("p", { className: styles.description }, "Start inviting participants by entering their email addresses or their Scolia IDs."),
                React.createElement("div", { className: cn(styles.tipContainer, { [styles.isOpen]: isTipOpen }), role: "button", onClick: handleTipToggle },
                    React.createElement("div", { className: styles.tooltip },
                        React.createElement(InfoIcon, null),
                        React.createElement("span", { className: styles.tooltipText },
                            React.createElement("div", { className: styles.tooltipContent },
                                React.createElement("strong", null, "TIP: "),
                                React.createElement("span", null, "Add a list of participants at once by copy-pasting a list of email addresses or Scolia IDs."))),
                        React.createElement(SButton, { className: styles.tooltipButton, variant: SButtonVariantOptionsEnum.TertiaryGray, size: SizesEnum.Small, theme: ThemesEnum.Astro, name: "tip-indicator" },
                            React.createElement(ArrowIcon, { direction: isTipOpen ? ArrowIconDirection.Up : ArrowIconDirection.Down, color: '#ACC0C8' }))),
                    React.createElement("div", { className: styles.exampleWrapper },
                        React.createElement("div", { className: styles.exampleWrapperTitle }, "Example"),
                        React.createElement("div", { className: styles.examples },
                            React.createElement("span", null, "first_email@example.com"),
                            React.createElement("span", null, "other_email@example.com"),
                            React.createElement("span", null, "#12345"),
                            React.createElement("span", null, "#45678")))),
                React.createElement("div", { className: styles.inputContainer },
                    React.createElement("div", { className: styles.inputContainerLabel },
                        React.createElement("span", { className: styles.inputContainerLabelStatus }, `${invitedUsers}/${maxParticipants} participants invited`),
                        React.createElement("span", { className: styles.inputContainerLabelLabel }, availableInvitationsToSendCounter ? (`You can invite ${availableInvitationsToSendCounter} ${availableInvitationsToSendCounter > 1 ? 'players' : 'player'}.`) : ('You cannot invite any more players.'))),
                    availableInvitationsToSendCounter > 0 && (React.createElement("div", { className: styles.invitationInput, style: inputValues.length > 10 ? { height: `300px` } : undefined },
                        React.createElement("div", { className: styles.invitationInputScrollContainer }, inputValues.map((participantValue, index) => {
                            const id = `participant_${index}`;
                            const meta = findMetaData(participantsMetaDataByScoliaId, participantsMetaDataByEmail, participantValue);
                            const isWithPlaceholder = index === focusedInputIndex || (index === 0 && !focusedInputIndex);
                            const occurrencesCount = countOccurrences(inputValues, meta?.email?.toLowerCase() ?? null) + countOccurrences(inputValues, `#${meta?.scoliaId}` ?? null);
                            const isDuplicated = occurrencesCount > 1;
                            const isAlreadyInvited = !!(meta?.scoliaId && alreadyInvitedParticipantsScoliaIds.includes(meta?.scoliaId));
                            const isInvalid = participantValue && !meta;
                            if (!errors.includes(id) && (isInvalid || isDuplicated || isAlreadyInvited)) {
                                setErrors(arr => [...arr, id]);
                            }
                            if (errors.includes(id) && !isInvalid && !isDuplicated && !isAlreadyInvited) {
                                setErrors(arr => arr.filter(elem => elem !== id));
                            }
                            return (React.createElement("div", { key: id, className: styles.participantElement },
                                React.createElement("div", { className: styles.participantElementLabel }, index + 1),
                                React.createElement(InviteParticipantElement, { id: id, meta: meta, value: participantValue, onChange: (event) => setInputValueAt(index, event.target.value ? event.target.value : undefined), onFocus: () => handleFocus(index), onDelete: () => removeInputValueAt(index), onBlur: () => handleBlur(index), onEnter: () => handleEnter(index), onPaste: handlePaste, isDuplicated: isDuplicated, isWithPlaceholder: isWithPlaceholder, isAlreadyParticipant: isAlreadyInvited, isInFocus: index === focusedInputIndex, isMatchNotFound: !!(participantValue && notExistingUsers.includes(participantValue)) })));
                        }))))),
                React.createElement("div", { className: styles.invitationsSubmitContainer },
                    React.createElement(SButton, { name: "invitations-button", disabled: isUndefined(tournamentId) || disabled || availableInvitationsToSendCounter <= 0, onClick: handleSubmit }, "Send invitations"))))));
};
TournamentParticipantInvite.displayName = 'TournamentParticipantInvite';
