import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import { Create } from './components/Create';
import { Edit } from './components/Edit';
import { Show } from './components/Show';
import { List } from './components/List';

class Firmwares extends Component {
  render() {
    const {
      match: { url },
    } = this.props;

    return (
      <Switch>
        <Route component={List} exact name="Firmware list" path={`${url}/`} />
        <Route
          component={Create}
          exact
          name="New Firmware"
          path={`${url}/new`}
        />
        <Route
          component={Edit}
          exact
          name="Edit Firmware"
          path={`${url}/:firmwareId/edit`}
        />
        <Route
          component={Show}
          exact
          name="Firmware info"
          path={`${url}/:firmwareId`}
        />
      </Switch>
    );
  }
}

export default Firmwares;
