import { createAction } from 'redux-actions';

export const getPlatforms = createAction(
  'PLATFORMS_GET_REQUEST',
  (isLoading = true) => ({
    isLoading,
  })
);
export const getPlatformsFailure = createAction('PLATFORMS_GET_FAILURE');
export const getPlatformsSuccess = createAction('PLATFORMS_GET_SUCCESS');

export default {
  getPlatforms,
  getPlatformsFailure,
  getPlatformsSuccess,
};
