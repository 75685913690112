import { createAction } from 'redux-actions';

export const getSubscriptionsList = createAction('GET_SUBSCRIPTIONS_REQUEST');
export const getSubscriptionsListFailure = createAction('GET_SUBSCRIPTIONS_FAILURE');
export const getSubscriptionsListSuccess = createAction('GET_SUBSCRIPTIONS_SUCCESS');

export const getPriceOptions = createAction('GET_PRICE_REQUEST');
export const getPriceOptionsFailure = createAction('GET_PRICE_FAILURE');
export const getPriceOptionsSuccess = createAction('GET_PRICE_SUCCESS');

export default {
  getSubscriptionsList,
  getSubscriptionsListFailure,
  getSubscriptionsListSuccess,

  getPriceOptions,
  getPriceOptionsFailure,
  getPriceOptionsSuccess,
};
