import React, { useEffect, useState } from 'react'
import { Badge, Button, Card, CardBody, CardHeader, Table } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash-es'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { getStatusVariables } from 'constants/GameEndStatus';
import { getIsAdmin } from 'services/session/selectors';

import actions from './actions'
import selectors from './selectors'
import { InstantMessagesModal } from './components';

import { getFormattedConfig, formatDate, getFormattedDuration, isNullOrUndefined } from 'utils'

import styles from './styles.module.scss'

const WinnerBadge = ({ isWinner }) => {
  return (
    <Badge color={cn({ warning: isWinner, danger: !isWinner })}>
      {isWinner ? 'Won' : 'Lost'}
    </Badge>
  )
}

const StatusBadge = ({ status }) => {
  const { isFinished, isAborted, isForsaken, isForfeited } = getStatusVariables(status);
  return (
    <Badge color={cn({ success: isFinished, danger: isForsaken, secondary: isAborted || isForfeited })}>
      {status}
    </Badge>
  )
}

const TableRow = ({ data, userId, clientUrl }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  if ( isNullOrUndefined(data) || isEmpty(data) ) {
    return null
  }

  const { tournamentGameProperties, isOnlineGame, type, configuration, players, playerNumber, startTime, netDuration, boardName, boardId, _id, status, winnerIds, hasInstantMessages, gameId, statistics } = data;
  const isTournamentGame = !!tournamentGameProperties
  const gameConfiguration = getFormattedConfig(type, configuration)
  const gameStartTime = formatDate(startTime, true)
  const gameDuration = getFormattedDuration(netDuration)
  const isWinner = winnerIds?.includes(userId)
  const url = `${clientUrl}/history/${_id}`;
  const gameType = `${isTournamentGame ? 'Tournament ∙ ' : ''}${isOnlineGame ? 'Online' : 'Local'}`
  const gameAverage = statistics?.statistics?.roundAvg;
  const roundedGameAverage = gameAverage ? gameAverage.toFixed(2) : 0; // rounded to 2 decimal places

  const { isForfeited } = getStatusVariables(status);

  const playersToDisplay = players?.map((player, idx) => {
    const isBot = player.type === 'bot';
    const isUser = player.type === 'user';
    const isGuest = player.type === 'guest';
    const isLast = idx === players.length - 1;

    if ( isBot ) {
      return <span key={`${idx}_${player.type}`}>{'🤖' + (isLast ? '' : ', ')}</span>
    }

    if ( isUser ) {
      return <Link key={`${idx}_${player.name}`} to={`/users/${player._id}`}>{`${player.name}${isLast ? '' : ', '}`}</Link>
    }

    if(isGuest){
      return <span key={`${idx}_${player.type}`}>{player.name +  (isLast ? '' : ', ')}</span>
    }

    return <span key={idx}>{'-' + (isLast ? '' : ', ')}</span>
  })

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <tr>
      <td className={styles.result}>
        <WinnerBadge isWinner={isWinner} /> <StatusBadge status={isForfeited ? 'Forfeited' : status} />
      </td>
      <td className={styles.type}>
        <a href={url} target="_blank" rel="noreferrer noopener">{gameType}</a>
      </td>
      <td className={styles.configuration}>
        {gameConfiguration}
      </td>
      <td>
        {roundedGameAverage}
      </td>
      <td  className={styles.gamePlayers}>
        {`(${playerNumber}) `}
        {playersToDisplay}
      </td>
      <td className={styles.timersWrapper}>
        <div className={styles.startTime}>{gameStartTime}</div>
        <div className={styles.duration}>{gameDuration}</div>
      </td>
      <td className={styles.boardName}>
        <Link to={`/boards/${boardId}`}>{boardName}</Link>
      </td>
      <td className={styles.instantMessages}>
        {hasInstantMessages ? (
          <span onClick={toggleModal} className={styles.available}>
            <i className="fa fa-list" /> Available
          </span>
        ) : (
          <span>Not Available</span>
        )}
      </td>
      { isModalOpen && <InstantMessagesModal isModalOpen={isModalOpen} toggleModal={toggleModal} gameId={gameId} />}
    </tr>
  )
}

const GAME_HISTORY_LIMIT = 20;

const GameHistory = ({ userId, userNickname }) => {
  const dispatch = useDispatch()

  const { count, data } = useSelector(selectors.getGames)
  const clientUrl = useSelector(selectors.getClientUrl)

  const [limit, setLimit] = useState(GAME_HISTORY_LIMIT);

  useEffect(() => {
    dispatch(actions.getUserGameHistory({ userId, limit }))

    return () => {
      dispatch(actions.resetUserGameHistory())
    }
  }, [ userId, dispatch, limit ])

  const handleIncreaseLimit = () => {
    if(count - limit > GAME_HISTORY_LIMIT){
      setLimit(limit + GAME_HISTORY_LIMIT)
    }else {
      setLimit(count)
    }
  }

  return (
    <div>
    <Card color="white">
      <CardHeader>
        <div className="card-header__left">
          <i className="fa fa-align-justify" /> {userNickname ?? 'User'}'s Game History (Last {limit} out of {count})
        </div>
      </CardHeader>
      <CardBody>
        <Table responsive>
          <thead>
            <tr>
              <th className={styles.result}>Result</th>
              <th className={styles.type}>Type</th>
              <th className={styles.configuration}>Configuration</th>
              <th>Game average</th>
              <th className={styles.gamePlayers}>Players</th>
              <th className={styles.gameTimers}>Start time (length)</th>
              <th className={styles.boardName}>Board Name</th>
              <th className={styles.instantMessages}>Instant Messages</th>
            </tr>
          </thead>
          <tbody>
            {data?.map(row => (
              <TableRow key={row._id} data={row} clientUrl={clientUrl} userId={userId} />
            ))}
          </tbody>
        </Table>

          {count !== limit && count > limit &&
            <div className={styles.cardFooter}>
              <Button
                color="link"
                onClick={handleIncreaseLimit}>Show more</Button>
            </div>}
      </CardBody>
    </Card>
  </div>
  )
}

GameHistory.displayName = 'GameHistory';

GameHistory.propTypes = {
  userId: PropTypes.string,
  userNickname: PropTypes.string,
}

export default GameHistory