import React, { Component } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

class RefreshTimer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refreshRemainingTime: this.props.timeout,
      isOn: this.props.isOnByDefault
    };

    this.timerId = null;
  }

  componentDidMount() {
    this.props.isOnByDefault && this.startTimer();
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  startTimer = () => {
    this.timerId = setInterval(() => {
      // Decrease the remaining time until next refresh by now
      if (this.state.refreshRemainingTime > 0) {
        this.setState({
          refreshRemainingTime: this.state.refreshRemainingTime - 1,
        });
      } else {
        this.refreshAndResetTimer();
      }
    }, 1000);
  }

  // Fetch data and set the timer to the initial value
  refreshAndResetTimer = () => {
    this.props.action();

    this.setState({
      refreshRemainingTime: this.props.timeout,
    });
  };

  handleToggle = _e => {
    this.setState({
      isOn: !this.state.isOn
    }, () => {
      if (this.state.isOn) {
        this.startTimer();
      } else {
        clearInterval(this.timerId);
      }
    });
  };

  render() {
    return (
      <div className={styles.refreshTimerContainer}>
        <div className={styles.refreshTimerCheckbox}>
          <span>
          Auto refresh: 
          <input
            checked={this.state.isOn}
            name="isOn"
            type="checkbox"
            onChange={this.handleToggle}
          />
          </span>
        </div>
        <div className={styles.refreshTimerButton}>
          {this.state.isOn && 
          `Auto refresh in ${this.state.refreshRemainingTime} seconds..`
          }
          <span className={styles.refreshButton}>
            <Button outline color="primary" onClick={this.refreshAndResetTimer} disabled={this.props.disabled}>
              <i className="fas fa-sync-alt" />
            </Button>
          </span>
        </div>
      </div>
    );
  }
}

RefreshTimer.propTypes = {
  action: PropTypes.func,
  timeout: PropTypes.number,
  isOnByDefault: PropTypes.bool,
  disabled: PropTypes.bool,
};

RefreshTimer.defaultProps = {
  timeout: 30,
  isOnByDefault: true
};

export default RefreshTimer;
