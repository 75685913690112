import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import { GameTypeEnum } from 'enums';
import { CollapseList } from 'scenes/StoreSnapshot/components/StoreTable/components/CollapseList';
import { ToggleFilter } from 'scenes/StoreSnapshot/components/StoreTable/components/ToggleFilter';

import { Room } from './components/Room';

import styles from './styles.module.scss';

const filters = [
  {
    id: 'x01',
    label: 'X01',
  },
  {
    id: 'atc',
    label: 'ATC',
  },
  {
    id: 'bobs27',
    label: "Bob's 27",
  },
  {
    id: 'cricket',
    label: "Cricket",
  },
  {
    id: 'randomCricket',
    label: "Random Cricket",
  },
  {
    id: 'shanghai',
    label: "Shanghai",
  },
  {
    id: 'noGame',
    label: 'No Game',
  },
  {
    id: 'total',
    label: 'Total',
    readonly: true,
    background: '#8252EA'
  },
];

const columns = [
  {
    label: 'ID',
    size: 3,
  },

  {
    label: 'Type',
    size: 5,
  },
  {
    label: 'Start Time',
    size: 4,
  },
];

class Rooms extends Component {
  constructor(props) {
    super(props);
    this.roomsListRef = React.createRef();

    this.state = {
      allOpened: false,
    };

    // Filters initialization
    this.state = {
      ...this.state,
      ...filters.reduce(
        (acc, curr) => {
          acc.filters[curr.id] = true;
          return acc;
        },
        { filters: {} }
      ),
    };
  }

  get gameTypeCount() {
    const { rooms } = this.props;

    const atc = rooms.filter(room => room.game.type === GameTypeEnum.AroundTheClock)
      .length;
    const x01 = rooms.filter(room => room.game.type === GameTypeEnum.X01).length;
    const bobs27 = rooms.filter(room => room.game.type === GameTypeEnum.Bobs27).length;
    const cricket = rooms.filter(room => room.game.type === GameTypeEnum.Cricket && !room.game.isRandom).length;
    const randomCricket = rooms.filter(room => room.game.type === GameTypeEnum.Cricket && room.game.isRandom).length;
    const shanghai = rooms.filter(room => room.game.type === GameTypeEnum.Shanghai).length;
    const noGame = rooms.filter(room => !room.game.isExist).length;

    return { atc, x01, bobs27, cricket, randomCricket, shanghai, noGame, total: rooms.length };
  }

  get getRooms() {
    const { rooms } = this.props;

    const {
      filters: { x01, atc, bobs27, cricket, randomCricket, shanghai, noGame },
    } = this.state;
    let filteredRooms = [];

    filteredRooms = filteredRooms.concat(
      rooms.filter(room => atc && room.game.type === GameTypeEnum.AroundTheClock)
    );
    filteredRooms = filteredRooms.concat(
      rooms.filter(room => x01 && room.game.type === GameTypeEnum.X01)
    );
    filteredRooms = filteredRooms.concat(
      rooms.filter(room => bobs27 && room.game.type === GameTypeEnum.Bobs27)
    );
    filteredRooms = filteredRooms.concat(
      rooms.filter(room => cricket && (room.game.type === GameTypeEnum.Cricket && !room.game.isRandom))
    );
    filteredRooms = filteredRooms.concat(
      rooms.filter(room => randomCricket && room.game.type === GameTypeEnum.Cricket && room.game.isRandom)
    );
    filteredRooms = filteredRooms.concat(
      rooms.filter(room => shanghai && room.game.type === GameTypeEnum.Shanghai)
    );
    filteredRooms = filteredRooms.concat(
      rooms.filter(room => noGame && !room.game.isExist)
    );

    return filteredRooms;
  }

  toggleAll = () => {
    this.state.allOpened
      ? this.roomsListRef.current.closeAll()
      : this.roomsListRef.current.openAll();
    this.setState({ allOpened: !this.state.allOpened });
  };

  onCollapseListChange = () => {
    this.roomsListRef.current.isAllOpened() &&
      this.setState({ allOpened: true });
    this.roomsListRef.current.isAllClosed() &&
      this.setState({ allOpened: false });
  };

  toggleFilter = filterId => {
    this.setState(
      update(this.state, {
        filters: {
          [filterId]: {
            $set: !this.state.filters[filterId],
          },
        },
      })
    );
  };

  render() {
    const { allOpened } = this.state;
    const { isLoading } = this.props;

    return (
      <div>
        <div className={styles.topbarWrapper}>
          <div className={styles.filterWrapper}>
            {filters.map(filter => (
              <ToggleFilter
                id={filter.id}
                key={filter.id}
                label={filter.label}
                count={this.gameTypeCount[filter.id]}
                checked={this.state.filters[filter.id]}
                onToggleFilter={this.toggleFilter}
                readonly={filter.readonly}
                background={filter.background}
              />
            ))}
          </div>

          <div className={styles.accordionToggleButton}>
            <div onClick={this.toggleAll} className={styles.toggleButton}>
              {allOpened ? 'Close' : 'Open'} all
            </div>
          </div>
        </div>

        <div className={styles.tableHeader}>
          <Row>
            {columns.map(column => (
              <Col key={column.label} xs={column.size}>
                {column.label}
              </Col>
            ))}
          </Row>
        </div>

        {isLoading && (
          <div className={styles.spinnerWrapper}>
            <i className="fa fa-spinner fa-spin fa-fw" />
          </div>
        )}
        <div style={{ display: isLoading ? 'none' : 'block' }}>
          <CollapseList
            ref={this.roomsListRef}
            onChange={this.onCollapseListChange}
          >
            {this.getRooms.map(room => <Room key={room.id} {...room} />)}
          </CollapseList>
        </div>
      </div>
    );
  }
}

Rooms.propTypes = {
  rooms: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

Rooms.defaultProps = {
  rooms: [],
  isLoading: true,
};

export default Rooms;
