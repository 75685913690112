import { RefObject, useState, useLayoutEffect } from 'react';

export enum DropdownDirection {
  up = 'up',
  down = 'down'
}

export function useDropdownPositioning<T extends HTMLElement>(ref: RefObject<T>, optionsListHeight: number): DropdownDirection {
  const [direction, setDirection] = useState<DropdownDirection>(DropdownDirection.down);

  useLayoutEffect(() => {
    const updateDirection = () => {
      if (!ref.current) return;

      const rect = ref.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - rect.bottom;
      const spaceAbove = rect.top;

      if (spaceBelow >= optionsListHeight || spaceBelow >= spaceAbove) {
        setDirection(DropdownDirection.down);
      } else {
        setDirection(DropdownDirection.up);
      }
    };

    updateDirection();

    window.addEventListener('resize', updateDirection);
    window.addEventListener('orientationchange', updateDirection);

    return () => {
      window.removeEventListener('resize', updateDirection);
      window.removeEventListener('orientationchange', updateDirection);
    };
  }, [ref, optionsListHeight]);

  return direction;
}
