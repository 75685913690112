import React from 'react';
import cn from 'classnames';
import styles from '../../styles.module.scss';
export const Bobs27Statistics = ({ userStats }) => {
    const { medOverall } = userStats;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: cn(styles.rowHeader, 'col-sm-4') }, "MED"),
            React.createElement("div", { className: cn(styles.rowHeader, 'col-sm-4') }, "MHD"),
            React.createElement("div", { className: cn(styles.rowHeader, 'col-sm-4') }, "MVD")),
        medOverall && (React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col-sm-4" }, medOverall.med ? `${medOverall.med.toFixed(2)}mm` : '-'),
            React.createElement("div", { className: "col-sm-4" }, medOverall.mhd ? `${medOverall.mhd.toFixed(2)}mm` : '-'),
            React.createElement("div", { className: "col-sm-4" }, medOverall.mvd ? `${medOverall.mvd.toFixed(2)}mm` : '-')))));
};
