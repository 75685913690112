import { handleActions } from 'redux-actions';

import { actions as listActions } from 'scenes/Users/components/List';
import { actions as showActions } from 'scenes/Users/components/Show';

const INITIAL_STATE = [];

const usersReducer = handleActions(
  {
    [listActions.getUsersSuccess]: (state, { payload: { data } }) => {
      const users = data.reduce((users, user) => {
        user.id = user._id;
        delete user._id;

        users[user.id] = user;
        return users;
      }, {});

      return {
        ...users,
      };
    },

    [showActions.getUserSuccess]: (state, { payload }) => {
      const userId = payload._id;
      delete payload._id;
      return {
        ...state,
        [userId]: { ...state[userId], ...payload },
      };
    },
  },
  INITIAL_STATE
);

export default usersReducer;
