import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import { actions } from 'scenes/Notifications';

const INITIAL_STATE = {
  notifications : [],
};

const notificationsReducer = handleActions(
  {
    [actions.getNotificationsSuccess]: (state, { payload }) => {
      return update(state, {
        'notifications': {
          $set : payload,
        },
      });
    },
  },
  INITIAL_STATE
);

export default notificationsReducer;
