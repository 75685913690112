import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody } from 'reactstrap';
import cn from 'classnames';

import { formatNumberWithThousandSeparators } from 'utils';

import styles from './styles.module.scss';

const LABELS = {
  total: 'Total User',
  isConfirmed: 'Confirmed',
  isSubscribed: 'Subscribed',
  attachedWithGoogle: 'Using Google',
  attachedWithFacebook: 'Using Facebook',
};

const BORDER_COLOR_CLASSES = {
  isConfirmed: 'confirmed',
  isSubscribed: 'subscribed',
  attachedWithGoogle: 'google',
  attachedWithFacebook: 'facebook',
};

class Users extends Component {
  get dataForCallouts() {
    const data = [];

    let usersData = Object.entries(this.props.users);

    usersData.splice(0, 1);

    for (let [key, value] of usersData) {
      data.push({ key, value });
    }
    return data;
  }

  shouldShowPercentage = key =>
    ['isConfirmed', 'isSubscribed'].some(status => status === key);

  getCalloutValue = (key, value) => {
    const { total, isConfirmed, isSubscribed } = this.props.users;

    let percentage;

    if (key === 'isConfirmed') {
      percentage = ((isConfirmed / total) * 100).toFixed(0);
    } else if (key === 'isSubscribed') {
      percentage = ((isSubscribed / total) * 100).toFixed(0);
    }

    return (
      <div className={styles.calloutValueContainer}>
        <span className={styles.calloutValue}>
          {formatNumberWithThousandSeparators(value, ' ')}
        </span>
        {this.shouldShowPercentage(key) && (
          <span className={styles.calloutValuePercentage}>
            {percentage + '%'}
          </span>
        )}
      </div>
    );
  };

  get callouts() {
    return this.dataForCallouts.map(({ key, value }) => (
      <Col xs={12} sm={6} key={'users-callout_' + key}>
        <div className={cn('callout', styles[BORDER_COLOR_CLASSES[key]])}>
          {this.getCalloutValue(key, value)}
          <small className="text-muted text-uppercase fw-bold">
            {LABELS[key]}
          </small>
        </div>
      </Col>
    ));
  }

  render() {
    const {
      users: { total },
    } = this.props;

    return (
      <Card color="white">
        <CardBody>
          <Fragment>
            <Row>
              <Col xs={8}>
                <strong className={cn('text-value h2', styles.textValue)}>
                  {formatNumberWithThousandSeparators(total, ' ')}
                </strong>
                <span className="text-muted text-uppercase fw-bold">
                  Total Users
                </span>
              </Col>
              <Col xs={4}>
                <div className={cn('text-muted text-end', styles.cardHeader)}>
                  <i className="icon-people" />
                </div>
              </Col>
            </Row>
            <Row>{this.callouts}</Row>
          </Fragment>
        </CardBody>
      </Card>
    );
  }
}

Users.propTypes = {
  users: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

Users.defaultProps = {
  isLoading: true,
};

export default Users;
