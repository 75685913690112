import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Badge, Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { getIsAdmin } from 'services/session/selectors';

import { ConnectedBoards } from 'scenes/ServiceAccounts/components/ConnectedBoards';

import { ActiveBoards } from '../ActiveBoards';
import actions from './actions';

import styles from './styles.module.scss';

class Show extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActiveBoardsView: false,
    };
  }
  componentDidMount() {
    const { dispatch, match } = this.props;

    dispatch(actions.getServiceAccount(match.params.serviceAccountId));
  }

  handleViewChange = () =>
    this.setState({ isActiveBoardsView: !this.state.isActiveBoardsView });

  get basicInfos() {
    const {
      serviceAccount: { id, name, email, phoneNumber, socialAccessToken },
    } = this.props;
    const infos = [
      { label: 'Object ID', value: id },
      { label: 'Name', value: name },
      { label: 'E-mail', value: email },
      { label: 'Contact Phone Number', value: phoneNumber },
      { label: 'Social Access Token', value: socialAccessToken },
    ];

    return infos.map((info, key) => (
      <div className="row" key={key}>
        <div className="col-sm-4">{info.label}</div>
        <div className="col-sm-8">{info.value || '-'}</div>
      </div>
    ));
  }

  handleActivityFormSubmit = (e) => {
    const { minThrows, minDays, startDate, endDate } = e;
    this.props.dispatch(actions.getServiceAccountActivity(this.props.serviceAccount.id, minThrows, minDays, startDate, endDate));
  }

  render() {
    const { boards, serviceAccount, isLoading, isActivityLoading, isAdmin } = this.props;

    if (!serviceAccount || isLoading) {
      return <i className="fa fa-spinner fa-spin fa-fw" />;
    }

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12">
            {serviceAccount.deletedAt && (
              <div className="alert alert-danger" role="alert">
                The Service Account has been deleted!
              </div>
            )}
            <Card color="white">
              <CardHeader>
                <div className="card-header__left">
                  <i className="fa fa-align-justify" /> Main Data
                </div>

                <div className="card-header__right">
                  {isAdmin && <Link to={`/service-accounts/${serviceAccount.id}/edit`}>
                    <button className="btn btn-primary btn-sm" type="button">
                      Edit Service Account
                    </button>
                  </Link>}
                </div>
              </CardHeader>
              <CardBody>
                <div className="key-value-data-container">
                  {this.basicInfos}
                  <div className="row">
                    <div className="col-sm-4">Social api</div>
                    <div className="col-sm-8">
                      <Badge
                        color={cn({
                          success: serviceAccount.isSocialApiEnabled,
                          warning: !serviceAccount.isSocialApiEnabled,
                        })}
                      >
                        {serviceAccount.isSocialApiEnabled
                          ? 'Enabled'
                          : 'Disabled'}
                      </Badge>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">Can access for game</div>
                    <div className="col-sm-8">
                      <Badge
                        color={cn({
                          success: serviceAccount.canAccessForGame,
                          warning: !serviceAccount.canAccessForGame,
                        })}
                      >
                        {serviceAccount.canAccessForGame
                          ? 'Enabled'
                          : 'Disabled'}
                      </Badge>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">Can manage board connections</div>
                    <div className="col-sm-8">
                      <Badge
                        color={cn({
                          success: serviceAccount.canManageBoardConnections,
                          warning: !serviceAccount.canManageBoardConnections,
                        })}
                      >
                        {serviceAccount.canManageBoardConnections
                          ? 'Enabled'
                          : 'Disabled'}
                      </Badge>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">Forwarding to Scolia</div>
                    <div className="col-sm-8">
                      <Badge
                        color={cn({
                          success: serviceAccount.defaultForwardingToScolia,
                          warning: !serviceAccount.defaultForwardingToScolia,
                        })}
                      >
                        {serviceAccount.defaultForwardingToScolia
                          ? 'Enabled'
                          : 'Disabled'}
                      </Badge>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card color="white">
              {this.state.isActiveBoardsView ? (
                <ActiveBoards onNormalViewClick={this.handleViewChange} boardsActivity={serviceAccount.boardsActivity} onSubmit={this.handleActivityFormSubmit} isLoading={isActivityLoading}/>
              ) : (
                <Fragment>
                  <CardHeader>
                    <div className="card-header__left">
                      <i className="fa fa-align-justify" /> Connected Boards
                    </div>
                    <div
                      className={cn('card-header__right', styles.buttonWrapper)}
                    >
                      {serviceAccount.connectedBoards.length > 0 && (
                        <div className={styles.connectedBoardsWrapper}>
                          <div className={styles.labelWrapper}>
                            Connected Boards
                          </div>
                          <span> {serviceAccount.connectedBoards.length} </span>
                        </div>
                      )}

                      <button
                        className="btn btn-primary btn-sm"
                        type="button"
                        onClick={this.handleViewChange}
                      >
                        Active Boards View
                      </button>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div className="key-value-data-container">
                      <ConnectedBoards
                        boards={boards}
                        connectedBoards={serviceAccount.connectedBoards}
                        readOnly
                      />
                    </div>
                  </CardBody>
                </Fragment>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

Show.displayName = 'ServiceAccounts.Show';

const mapStateToProps = (state, ownProps) => ({
  serviceAccount:
    state.data.serviceAccounts[ownProps.match.params.serviceAccountId],
  boards: Object.values(state.data.boards).map(item => {
    return { serialNumber: item.serialNumber, boardId: item.id };
  }),
  isLoading: state.scenes.serviceAccounts.show.isLoading,
  isActivityLoading: state.scenes.serviceAccounts.show.isActivityLoading,
  isAdmin: getIsAdmin(state),
});

export default connect(mapStateToProps)(Show);
