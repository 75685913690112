import { call, put, takeLatest } from 'redux-saga/effects';

import { Firmwares } from 'services/api';
import { createFormData } from 'utils';

import { browserHistory } from 'services/history';
import { callApi } from 'services/rest';

import {
  createFirmware,
  createFirmwareFailure,
  createFirmwareSuccess,
} from 'scenes/Firmwares/components/Create/actions';

function* watchCreateFirmware({ payload: { firmware } }) {
  try {
    const formData = createFormData(firmware);

    yield call(callApi, Firmwares.create, formData);
    yield put(createFirmwareSuccess());
    browserHistory.push('/firmwares');
  } catch (e) {
    if (!e.isFetch) {
      throw e;
    }
    if(e.data.error === 'DuplicateCompatibleHardwareWithVersionNumber')
      yield put(createFirmwareFailure('Couldn\'t create firmware! Version number already exists.'));
    else yield put(createFirmwareFailure('An error occurred'));
  }
}

export default () => takeLatest(createFirmware, watchCreateFirmware);
