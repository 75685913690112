import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import { Edit } from './components/Edit';

class Notifications extends Component {
  render() {
    const {
      match: { url },
    } = this.props;

    return (
      <Switch>
        <Route
          component={Edit}
          exact
          name="Manage notifications"
          path={`${url}/`}
        />
      </Switch>
    );
  }
}

export default Notifications;

