import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import _ from 'lodash';

import { browserHistory } from 'services/history';
import { getTournamentMatchByMatchId } from 'utils/tournamentUtils';
import {
  getNumberOfParticipants,
  handleAddOrRemoveParticipant,
  handleChangeParticipantReply,
} from 'utils/tournamentUtilFunctions';

import actions from './actions';
import { prepareTournamentValues } from '../utils';

const INITIAL_STATE = {
  tournament: {},
  isLoading: false,
};

const show = handleActions(
  {
    [actions.getTournament]: (state, _action) =>
      update(state, {
        isLoading: { $set: true },
      }),

    [actions.clearTournament]: (state, _action) =>
      update(state, {
        tournament: { $set: {} },
      }),

    [actions.getTournamentSuccess]: (state, { payload }) =>
      update(state, {
        tournament: { $set: prepareTournamentValues(payload) },
        isLoading: { $set: false },
      }),

    [actions.getTournamentFailure]: (state) =>
      update(state, {
        tournament: { $set: {} },
        isLoading: { $set: false },
      }),

    [actions.updateTournamentMatch]: (storeState, { payload }) => {
      const { stageType, stageMatchIndex, tournamentMatch } = getTournamentMatchByMatchId(
        storeState.tournament,
        payload.matchId
      );
      const merged = _.merge(tournamentMatch, payload);

      //continuous updating would break immutability-helper's update so we update the object like this
      const newTournamentStage = [...storeState.tournament.stages[stageType]];
      newTournamentStage[stageMatchIndex] = merged;

      return {
        ...storeState,
        tournament: {
          ...storeState?.tournament,
          stages: {
            ...storeState?.tournament.stages,
            [stageType]: newTournamentStage,
          },
        },
      };
    },
    [actions.changeTournamentState]: (storeState, { payload: { changedState, changedStages } }) =>
      update(storeState, {
        tournament: {
          state: { $set: changedState },
          stages: { $set: changedStages ? changedStages : storeState?.tournament?.stages },
        },
      }),

    [actions.updateParticipants]: (state, { payload }) => {
      if (payload.newParticipants || payload.removedParticipant) {
        const newTournament = handleAddOrRemoveParticipant(state.tournament, [], payload);
        newTournament.numberOfParticipants = getNumberOfParticipants(
          Object.values(newTournament.participants ?? {}),
          state.tournament.registrationType
        );

        return update(state, {
          tournament: { $set: newTournament },
        });
      }

      if (payload.reply) {
        const newTournament = handleChangeParticipantReply(state.tournament, payload);
        newTournament.numberOfParticipants = getNumberOfParticipants(
          Object.values(newTournament.participants ?? {}),
          state.tournament.registrationType
        );

        return update(state, {
          tournament: { $set: newTournament },
        });
      }

      return state;
    },

    [actions.updateResults]: (state, { payload: { results, updatedParticipants } }) => {
      if (browserHistory.location.pathname.includes('editInvitational')) {
        return state;
      }

      let newResults = { ...state.tournament.results };
      let newParticipants = { ...state.tournament.participants };
      Object.keys(results).forEach((key) => (newResults[key] = results[key]));

      //updatedParticipants are participants with a complete object of the tournamentStats
      //so merge them into the new Participants that we are going to store
      Object.keys(updatedParticipants).forEach((key) => {
        const mergedTournamentStats = _.merge(
          newParticipants[key].tournamentStats,
          updatedParticipants[key]
        );

        newParticipants[key] = {
          ...newParticipants[key],
          tournamentStats: mergedTournamentStats,
        };
      });

      return {
        ...state,
        tournament: {
          ...state.tournament,
          results: newResults,
          participants: newParticipants,
        },
      };
    },
  },
  INITIAL_STATE
);

export default show;
