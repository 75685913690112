import { get, patch } from 'services/rest';

const url = '/users';

const Users = {
  index: queryParams => get(url, queryParams),
  show: userId => get(`${url}/${userId}`),
  update: (userId, payload) => patch(`${url}/${userId}`, payload),
  statistics: (userId, gametype) =>
    get(`${url}/${userId}/statistics/${gametype}`),
  boardsIndex: userId => get(`${url}/${userId}/boards`),
  emails: queryParams => get(`${url}/emails`, queryParams),
  tokenHistory: (userId, queryParams) => get(`/token/history/${userId}`, queryParams)
};

export default Users;
