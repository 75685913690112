import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import { actions as hardwareActions } from 'scenes/Hardwares';
//import { actions as editActions } from 'scenes/Hardwares/components/Edit';
import { actions as listActions } from 'scenes/Hardwares/components/List';

const INITIAL_STATE = {};

const hardwaresReducer = handleActions(
  {
    [listActions.getHardwaresSuccess]: (_state, { payload }) => {
      const hardwares = payload.reduce((hardwares, hardware) => {
        hardware.id = hardware._id;
        delete hardware._id;

        hardwares[hardware.id] = hardware;
        return hardwares;
      }, {});

      return {
        ...hardwares,
      };
    },

    [hardwareActions.getHardwareSuccess]: (state, { payload: hardware }) => {
      hardware.id = hardware._id;
      delete hardware._id;
      return update(state, {
        [hardware.id]: {
          $set: hardware,
        },
      });
    },
  },
  INITIAL_STATE
);

export default hardwaresReducer;
