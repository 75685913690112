import { useState, useEffect, useCallback } from 'react';

// Define the hook with TypeScript
export default function useIsKeyDown(keyCode: string): boolean {
  const [isKeyPressedDown, setIsKeyPressedDown] = useState<boolean>(false);

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === keyCode) {
      setIsKeyPressedDown(true);
    }
  }, [keyCode]);

  const handleKeyUp = useCallback((event: KeyboardEvent) => {
    if (event.key === keyCode) {
      setIsKeyPressedDown(false);
    }
  }, [keyCode]);

  useEffect(() => {
    // Adding event listeners
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    // Cleanup function
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]);

  return isKeyPressedDown;
}
