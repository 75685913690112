import { handleActions } from 'redux-actions';

import { actions as listActions } from 'scenes/RankingList/components/List';

const INITIAL_STATE = [];

const rankingListReducer = handleActions(
  {
    [listActions.getRankingListSuccess]: (state, { payload: { data } }) => {
      return data;
    },
  },
  INITIAL_STATE
);

export default rankingListReducer;
