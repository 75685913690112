import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import { Edit } from './components/Edit';

class Configs extends Component {
  render() {
    const {
      match: { url },
    } = this.props;

    return (
      <Switch>
        <Route
          component={Edit}
          exact
          name="Manage configs"
          path={`${url}/`}
        />
      </Switch>
    );
  }
}

export default Configs;

