import { TournamentPlaceTitles, TournamentStageType, TournamentState } from 'enums';

export const TournamentStageLabels = {
  [TournamentStageType.Final]: 'Final',
  [TournamentStageType.SemiFinal]: 'Semifinals',
  [TournamentStageType.QuarterFinal]: 'Top 8',
  [TournamentStageType.Top16]: 'Top 16',
  [TournamentStageType.Top32]: 'Top 32',
  [TournamentStageType.Top64]: 'Top 64',
  [TournamentStageType.Top128]: 'Top 128',
  [TournamentStageType.Top256]: 'Top 256',
} as const;

export const TournamentPlaceTitleLabels: { [key in TournamentPlaceTitles]: string } = {
  [TournamentPlaceTitles.winner]: '1st',
  [TournamentPlaceTitles.runnerUp]: '2nd',
  [TournamentPlaceTitles.semiFinalists]: '3rd',
  [TournamentPlaceTitles.top8]: 'Top 8',
  [TournamentPlaceTitles.top16]: 'Top 16',
  [TournamentPlaceTitles.top32]: 'Top 32',
  [TournamentPlaceTitles.top64]: 'Top 64',
  [TournamentPlaceTitles.top128]: 'Top 128',
  [TournamentPlaceTitles.top256]: 'Top 256',
} as const;

export const TOURNAMENT_STAGES = [
  {
    label: TournamentStageLabels[TournamentStageType.Final],
    key: TournamentStageType.Final,
  },
  {
    label: TournamentStageLabels[TournamentStageType.SemiFinal],
    key: TournamentStageType.SemiFinal,
  },
  {
    label: TournamentStageLabels[TournamentStageType.QuarterFinal],
    key: TournamentStageType.QuarterFinal,
  },
  {
    label: TournamentStageLabels[TournamentStageType.Top16],
    key: TournamentStageType.Top16,
  },
  {
    label: TournamentStageLabels[TournamentStageType.Top32],
    key: TournamentStageType.Top32,
  },
  {
    label: TournamentStageLabels[TournamentStageType.Top64],
    key: TournamentStageType.Top64,
  },
  {
    label: TournamentStageLabels[TournamentStageType.Top128],
    key: TournamentStageType.Top128,
  },
  {
    label: TournamentStageLabels[TournamentStageType.Top256],
    key: TournamentStageType.Top256,
  },
] as const;

export const STAGE_PRIZES = [
  {
    label: TournamentPlaceTitleLabels[TournamentPlaceTitles.winner],
    key: TournamentPlaceTitles.winner,
    playerNum: 1,
  },
  {
    label: TournamentPlaceTitleLabels[TournamentPlaceTitles.runnerUp],
    key: TournamentPlaceTitles.runnerUp,
    playerNum: 1,
  },
  {
    label: TournamentPlaceTitleLabels[TournamentPlaceTitles.semiFinalists],
    key: TournamentPlaceTitles.semiFinalists,
    playerNum: 2,
  },
  {
    label: TournamentPlaceTitleLabels[TournamentPlaceTitles.top8],
    key: TournamentPlaceTitles.top8,
    playerNum: 4,
  },
  {
    label: TournamentPlaceTitleLabels[TournamentPlaceTitles.top16],
    key: TournamentPlaceTitles.top16,
    playerNum: 8,
  },
  {
    label: TournamentPlaceTitleLabels[TournamentPlaceTitles.top32],
    key: TournamentPlaceTitles.top32,
    playerNum: 16,
  },
  {
    label: TournamentPlaceTitleLabels[TournamentPlaceTitles.top64],
    key: TournamentPlaceTitles.top64,
    playerNum: 32,
  },
  {
    label: TournamentPlaceTitleLabels[TournamentPlaceTitles.top128],
    key: TournamentPlaceTitles.top128,
    playerNum: 64,
  },
  {
    label: TournamentPlaceTitleLabels[TournamentPlaceTitles.top256],
    key: TournamentPlaceTitles.top256,
    playerNum: 128,
  },
] as const;

export const NOT_DELETABLE_STATES: TournamentState[] = [
  TournamentState.Running,
  TournamentState.Finished,
  TournamentState.Cancelled,
  TournamentState.Deleted,
] as const;

export const AVG_OPTIONS: { label: string; value: string; minValue: number; maxValue: number }[] = [
  { label: 'All averages', value: '0-180', minValue: 0, maxValue: 180 },
  { label: 'Below 40', value: '0-40', minValue: 0, maxValue: 40 },
  { label: 'Below 50', value: '0-50', minValue: 0, maxValue: 50 },
  { label: '40-50', value: '40-50', minValue: 40, maxValue: 50 },
  { label: 'Above 50', value: '50-180', minValue: 50, maxValue: 180 },
  { label: 'Below 60', value: '0-60', minValue: 0, maxValue: 60 },
  { label: '50-60', value: '50-60', minValue: 50, maxValue: 60 },
  { label: 'Above 60', value: '60-180', minValue: 60, maxValue: 180 },
  { label: '60-70', value: '60-70', minValue: 60, maxValue: 70 },
  { label: '70-80', value: '70-80', minValue: 70, maxValue: 80 },
  { label: 'Above 80', value: '80-180', minValue: 80, maxValue: 180 },
] as const;

export const CONFIGURATION_OPTIONS = {
  Fixed: 'Fixed',
  Progressive: 'Progressive Config',
} as const;

export const TournamentResultLabels = [
  {
    label: TournamentPlaceTitleLabels[TournamentPlaceTitles.winner],
    prizeKey: TournamentPlaceTitles.winner,
  },
  {
    label: TournamentPlaceTitleLabels[TournamentPlaceTitles.runnerUp],
    prizeKey: TournamentPlaceTitles.runnerUp,
  },
  {
    label: TournamentPlaceTitleLabels[TournamentPlaceTitles.semiFinalists],
    prizeKey: TournamentPlaceTitles.semiFinalists,
  },
  {
    label: TournamentPlaceTitleLabels[TournamentPlaceTitles.top8],
    prizeKey: TournamentPlaceTitles.top8,
  },
  {
    label: TournamentPlaceTitleLabels[TournamentPlaceTitles.top16],
    prizeKey: TournamentPlaceTitles.top16,
  },
  {
    label: TournamentPlaceTitleLabels[TournamentPlaceTitles.top32],
    prizeKey: TournamentPlaceTitles.top32,
  },
  {
    label: TournamentPlaceTitleLabels[TournamentPlaceTitles.top64],
    prizeKey: TournamentPlaceTitles.top64,
  },
  {
    label: TournamentPlaceTitleLabels[TournamentPlaceTitles.top128],
    prizeKey: TournamentPlaceTitles.top128,
  },
  {
    label: TournamentPlaceTitleLabels[TournamentPlaceTitles.top256],
    prizeKey: TournamentPlaceTitles.top256,
  },
] as const;

export const PrizesStagesRequiredParticipantNumber = {
  [TournamentPlaceTitles.winner]: 1,
  [TournamentPlaceTitles.runnerUp]: 2,
  [TournamentPlaceTitles.semiFinalists]: 4,
  [TournamentPlaceTitles.top8]: 8,
  [TournamentPlaceTitles.top16]: 16,
  [TournamentPlaceTitles.top32]: 32,
  [TournamentPlaceTitles.top64]: 64,
  [TournamentPlaceTitles.top128]: 128,
  [TournamentPlaceTitles.top256]: 256,
} as const;
