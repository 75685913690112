import { createAction } from 'redux-actions';

export const getPlatform = createAction(
    'PLATFORM_GET_REQUEST',
    (platformId, queryParams, isLoading = true) => ({
        platformId,
        queryParams,
        isLoading,
    })
);

export const getPlatformFailure = createAction('PLATFORM_GET_FAILURE');
export const getPlatformSuccess = createAction('PLATFORM_GET_SUCCESS');

export default {
    getPlatform,
    getPlatformFailure,
    getPlatformSuccess,
};
