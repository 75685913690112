import React, { useState } from 'react';
import { Button, ButtonGroup, ButtonToolbar, Card, CardBody, CardHeader, Col, FormGroup, Label, Row, } from 'reactstrap';
import DatePicker from 'react-datepicker';
const _getFormattedTime = (ms) => {
    if (isNaN(ms)) {
        console.warn(`Expected milliseconds as number, received '${ms}'`);
        return 'N/A';
    }
    let days = Math.floor(ms / (24 * 60 * 60 * 1000));
    let daysms = ms % (24 * 60 * 60 * 1000);
    let hours = Math.floor(daysms / (60 * 60 * 1000));
    let hoursms = ms % (60 * 60 * 1000);
    let minutes = Math.floor(hoursms / (60 * 1000));
    let minutesms = ms % (60 * 1000);
    let seconds = Math.floor(minutesms / 1000);
    let times = [
        days && `${days} ${days > 1 ? 'days' : 'day'}`,
        hours && `${hours} ${hours > 1 ? 'hours' : 'hour'}`,
        minutes && `${minutes} ${minutes > 1 ? 'minutes' : 'minute'}`,
        seconds && `${seconds} ${seconds > 1 ? 'seconds' : 'second'}`,
    ].filter(Boolean);
    if (times.length > 0) {
        return times.join(', ');
    }
    return '?';
};
const UsageStats = ({ gamesCount, throwsByGameType, gamesNetDuration, filters, maxSessionDuration, throwsCount, totalOnlineDuration, outcastThrows, outcastThrowsWithSAConnection, practiceGameThrows, onDateChange, onDatePresetSelect, onFilterSubmit, isLoading, }) => {
    const getDetectionError = () => {
        return !isNaN((throwsCount.manuallyAdded * 100) / throwsCount.total)
            ? Number(((throwsCount.manuallyAdded * 100) / throwsCount.total).toFixed(2))
            : 0;
    };
    const getLocalizationError = () => {
        return !isNaN(parseFloat(((throwsCount.modified * 100) / throwsCount.total).toFixed(2)))
            ? parseFloat(((throwsCount.modified * 100) / throwsCount.total).toFixed(2))
            : 0;
    };
    const getTotalError = () => {
        return getDetectionError() + getLocalizationError();
    };
    const getAccuracy = () => {
        return (100 - getTotalError()).toFixed(2);
    };
    const [isGeneralTabVisible, setIsGeneralTabVisible] = useState(true);
    return (React.createElement(Card, null,
        React.createElement(CardHeader, null,
            React.createElement("div", { className: "card-header__left" },
                React.createElement("i", { className: "fa fa-bar-chart" }),
                " Usage Statistics")),
        React.createElement(CardBody, null,
            React.createElement(Row, null,
                React.createElement(Col, { sm: "6" },
                    React.createElement(FormGroup, { style: { display: 'grid' } },
                        React.createElement(Label, { htmlFor: "usageFrom" }, "Date from"),
                        React.createElement(DatePicker, { selected: filters.usageFrom, onChange: (date) => {
                                onDateChange({ date: date, name: 'usageFrom' });
                            }, showTimeSelect: true, timeFormat: "HH:mm", timeIntervals: 5, dateFormat: "yyyy.MM.dd HH:mm", placeholderText: "\u00E9\u00E9\u00E9\u00E9. hh. nn.", className: "form-control", isClearable: true, disabled: isLoading }))),
                React.createElement(Col, { sm: "6" },
                    React.createElement(FormGroup, { style: { display: 'grid' } },
                        React.createElement(Label, { htmlFor: "usageTo" }, "Date to"),
                        React.createElement(DatePicker, { selected: filters.usageTo, onChange: (date) => {
                                onDateChange({ date, name: 'usageTo' });
                            }, showTimeSelect: true, timeFormat: "HH:mm", timeIntervals: 5, dateFormat: "yyyy.MM.dd HH:mm", placeholderText: "\u00E9\u00E9\u00E9\u00E9. hh. nn.", className: "form-control", isClearable: true, disabled: isLoading })))),
            React.createElement(ButtonToolbar, { className: "mb-3" },
                React.createElement(ButtonGroup, { className: "mr-2" },
                    React.createElement(Button, { name: "today", onClick: onDatePresetSelect, disabled: isLoading }, "Today"),
                    React.createElement(Button, { name: "yesterday", onClick: onDatePresetSelect, disabled: isLoading }, "Yesterday"),
                    React.createElement(Button, { name: "lastWeek", onClick: onDatePresetSelect, disabled: isLoading }, "Last Week"),
                    React.createElement(Button, { name: "lastMonth", onClick: onDatePresetSelect, disabled: isLoading }, "Last Month")),
                React.createElement("button", { className: "btn btn-success", onClick: onFilterSubmit, disabled: isLoading }, "Search")),
            React.createElement("hr", null),
            React.createElement(ButtonToolbar, { className: "mb-3" },
                React.createElement(ButtonGroup, { className: "mr-2" },
                    React.createElement(Button, { name: "general", onClick: () => setIsGeneralTabVisible(true), disabled: isLoading }, "General"),
                    React.createElement(Button, { name: "throwsByGames", onClick: () => setIsGeneralTabVisible(false), disabled: isLoading }, "Throws by Games"))),
            React.createElement("hr", null),
            !isLoading && gamesCount && isGeneralTabVisible && (React.createElement("div", { className: "key-value-data-container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-sm-4" }, "Games Count"),
                    React.createElement("div", { className: "col-sm-8" }, gamesCount.total)),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-sm-4" }, "Finished Games"),
                    React.createElement("div", { className: "col-sm-8" }, gamesCount.finished)),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-sm-4" }, "Aborted Games"),
                    React.createElement("div", { className: "col-sm-8" }, gamesCount.aborted)),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-sm-4" }, "Forsaken Games"),
                    React.createElement("div", { className: "col-sm-8" }, gamesCount.forsaken)),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-sm-4" }, "Throws Detected"),
                    React.createElement("div", { className: "col-sm-8" }, throwsCount.total)),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-sm-4" }, "Throws Automatic"),
                    React.createElement("div", { className: "col-sm-8" }, throwsCount.automaticallyDetected)),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-sm-4" }, "Throws Added Manually"),
                    React.createElement("div", { className: "col-sm-8" }, throwsCount.manuallyAdded)),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-sm-4" }, "Throws Added By GS"),
                    React.createElement("div", { className: "col-sm-8" }, throwsCount.addedByGs)),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-sm-4" }, "Throws Modified"),
                    React.createElement("div", { className: "col-sm-8" }, throwsCount.modified)),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-sm-4" }, "Detection error"),
                    React.createElement("div", { className: "col-sm-8" },
                        getDetectionError(),
                        "%")),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-sm-4" }, "Localization error"),
                    React.createElement("div", { className: "col-sm-8" },
                        getLocalizationError(),
                        "%")),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-sm-4" }, "Total error"),
                    React.createElement("div", { className: "col-sm-8" },
                        getTotalError(),
                        "%")),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-sm-4" }, "Accuracy"),
                    React.createElement("div", { className: "col-sm-8" },
                        getAccuracy(),
                        "%")),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-sm-4" }, "Outcast Throws on Scolia"),
                    React.createElement("div", { className: "col-sm-8" }, outcastThrows)),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-sm-4" }, "Outcast Throws on Scolia with Service Account connection"),
                    React.createElement("div", { className: "col-sm-8" }, outcastThrowsWithSAConnection)),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-sm-4" }, "Practice game throws"),
                    React.createElement("div", { className: "col-sm-8" }, practiceGameThrows)),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-sm-4" }, "Online Duration"),
                    React.createElement("div", { className: "col-sm-8" }, _getFormattedTime(totalOnlineDuration))),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-sm-4" }, "Games Duration"),
                    React.createElement("div", { className: "col-sm-8" }, _getFormattedTime(gamesNetDuration))),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-sm-4" }, "Max Session Duration"),
                    React.createElement("div", { className: "col-sm-8" }, _getFormattedTime(maxSessionDuration))))),
            !isLoading && !isGeneralTabVisible && (React.createElement("div", { className: "key-value-data-container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-sm-8" }, "X01 Throws"),
                    React.createElement("div", { className: "col-sm-4" }, throwsByGameType.x01Throws)),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-sm-8" }, "ATC Throws"),
                    React.createElement("div", { className: "col-sm-4" }, throwsByGameType.atcThrows)),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-sm-8" }, "Bob's 27 Throws"),
                    React.createElement("div", { className: "col-sm-4" }, throwsByGameType.bobs27Throws)),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-sm-8" }, "Cricket Throws"),
                    React.createElement("div", { className: "col-sm-4" }, throwsByGameType.cricketThrows)),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-sm-8" }, "Random Cricket Throws"),
                    React.createElement("div", { className: "col-sm-4" }, throwsByGameType.randomCricketThrows)),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-sm-8" }, "Shanghai Throws"),
                    React.createElement("div", { className: "col-sm-4" }, throwsByGameType.shanghaiThrows)),
                React.createElement("hr", null),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-sm-8" }, "Practice - 121 Throws"),
                    React.createElement("div", { className: "col-sm-4" }, throwsByGameType.practiceGame121Throws)),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-sm-8" }, "Practice - Checkout Streak Throws"),
                    React.createElement("div", { className: "col-sm-4" }, throwsByGameType.practiceGameCheckoutStreakThrows)),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-sm-8" }, "Practice - Power Scoring Throws"),
                    React.createElement("div", { className: "col-sm-4" }, throwsByGameType.practiceGamePowerScoringThrows)))))));
};
export default UsageStats;
