import React from 'react'
import { Link } from 'react-router-dom';

import { SSelect } from 'components/Form';

import { formatDate } from 'utils';

export const getColumns = ({ countries }) => {
  const columns = [
    {
      Header: '#',
      id: 'row',
      maxWidth: 200,
      accessor: "_id",
      sortable: false,
    },
    {
      Header: 'User email',
      accessor: 'email',
      filterable: true,
      Cell: props => <Link to={`/users/${props?.row?._original?.userId}`}> {props?.value} </Link>
    },
    {
      Header: 'User country',
      accessor: 'country',
      filterable: true,
      Filter: ({ filter, onChange }) => (
        <SSelect
          value={filter ? filter.value : ''}
          onChange={event => onChange(event.target.value)}
          options={countries}
        />
      )
    },
    {
      Header: 'Date',
      accessor: 'createdAt',
      Cell: props => formatDate(props.value, true)
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      sortable: true,
      maxWidth: 180,
    },
    {
      Header: 'Price',
      accessor: 'paymentInfo.amount',
      sortable: true,
      Cell: props => `${props.row._original.paymentInfo.currency} ${props.value}`,
    },
  ]

  return columns;
}