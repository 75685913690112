import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { Link } from 'react-router-dom';

import { default as formatDate } from 'utils/formatDate';

class MainInfo extends PureComponent {
  render() {
    const {
      id,
      versionNumber,
      releasedAt,
      lastModifiedAt,
      deletedAt,
      description,
      isLimitedHardware,
      isWifiCompatible,
      isAdmin,
    } = this.props;

    return (
      <div>
        <Card color="white">
          <CardHeader>
            <div className="card-header__left">
              <i className="fa fa-align-justify" /> Hardware Data
            </div>
            <div className="card-header__right">
              {isAdmin && <Link to={`/hardwares/${id}/edit`}>
                <button className="btn btn-primary btn-sm" type="button">
                  Edit Hardware
                </button>
              </Link>}
            </div>
          </CardHeader>

          <CardBody>
            <div className="key-value-data-container">
              <div className="row">
                <div className="col-sm-4">Version Number</div>
                <div className="col-sm-8">
                  <strong>{versionNumber || 'N/A'}</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">Release Date</div>
                <div className="col-sm-8">{formatDate(releasedAt)}</div>
              </div>
              <div className="row">
                <div className="col-sm-4">Description</div>
                <div className="col-sm-8">{description || '-'}</div>
              </div>
              <div className="row">
                <div className="col-sm-4">Limited Hardware</div>
                <div className="col-sm-8">{isLimitedHardware ? "Limited" : "Unlimited" }</div>
              </div>
              <div className="row">
                <div className="col-sm-4">WiFi Compatible</div>
                <div className="col-sm-8">{isWifiCompatible ? "Yes" : "No" }</div>
              </div>
              <hr className="mt-lg-5" />
              <div className="row">
                <div className="col-sm-4">Last Modified</div>
                <div className="col-sm-8">{formatDate(lastModifiedAt)}</div>
              </div>
              <div className="row">
                <div className="col-sm-4">Deleted At</div>
                <div className="col-sm-8">{formatDate(deletedAt)}</div>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

MainInfo.propTypes = {
  versionNumber: PropTypes.string,
  releasedAt: PropTypes.string,
  lastModifiedAt: PropTypes.string,
  deletedAt: PropTypes.string,
  description: PropTypes.string,
  isAdmin: PropTypes.bool,
  isWifiCompatible: PropTypes.bool,
};

export default connect()(MainInfo);
