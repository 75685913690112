import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { Badge } from 'reactstrap';
import cn from 'classnames';

import { users as usersResource } from 'constants/Resources'

import { EntityTable } from 'components/EntityTable';
import { formatDateNumber, getPreparedFiltersForSelectedFields } from 'utils';

import * as userActions from './actions';
import { SSelect } from 'components/Form';

const columns = [
  {
    Header: 'ScoliaId',
    accessor: 'scoliaId',
    filterable: true,
    Cell: props => {
      return (
        <Link to={`/users/${props?.row?._original?.id}`}> {props?.value} </Link>
      ) || null;
    },
  },
  {
    Header: 'Nickname',
    accessor: 'nickname',
    filterable: true,
    Cell: props => {
      return (
        <Link to={`/users/${props?.row?._original?.id}`}> {props?.value} </Link>
      ) || null;
    },
  },
  {
    Header: 'E-mail',
    accessor: 'email',
    filterable: true,
    Cell: props => {
      return (
        <Link to={`/users/${props?.row?._original?.id}`}> {props?.value} </Link>
      ) || null;
    },
  },
  {
    Header: 'Country',
    accessor: 'country',
    filterable: true,
    Filter: ({ filter, onChange }) => (
      <SSelect
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        options={useSelector(state => state.data.countries).map(option => ({ label: option.name, value: option.alpha3Code }))}
      />
    )
  },
  {
    Header: 'Subscription',
    accessor: 'isSubscribed',
    filterable: true,
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'All'}
      >
        <option value="All">All</option>
        <option value="true">Subscribed</option>
        <option value="false">Not Subscribed</option>
      </select>
    ),
    Cell: props => (
      <Badge
        color={cn({
          success: props.value === true,
          danger: props.value === false,
        })}
      >
        {props.value ? 'Subscribed' : 'No'}
      </Badge>
    ),
  },
  {
    Header: 'Registr. Confirmation',
    accessor: 'registrationConfirmed',
    filterable: true,
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'All'}
      >
        <option value="All">All</option>
        <option value="true">Confirmed</option>
        <option value="false">Not Confirmed</option>
      </select>
    ),
    Cell: props => (
      <Badge
        color={cn({
          success: props.value === true,
          danger: props.value === false,
        })}
      >
        {props.value ? 'Confirmed' : 'Not yet confirmed'}
      </Badge>
    ),
  },
  {
    Header: 'Register Date',
    accessor: 'registeredAt',
    Cell: props => {
      const date = new Date(props.value);
      return (
        date.getFullYear() +
        '.' +
        formatDateNumber(date.getMonth() + 1) +
        '.' +
        formatDateNumber(date.getDate()) +
        '.' +
        ' ' +
        formatDateNumber(date.getHours()) +
        ':' +
        formatDateNumber(date.getMinutes())
      );
    },
  },
  {
    Header: 'Permission',
    accessor: 'role',
    filterable: true,
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'All'}
      >
        <option value="All">All</option>
        <option value='Admin'>Admin</option>
        <option value='Viewer'>Viewer</option>
        <option value='User'>User</option>
      </select>
    ),
    Cell: props => {
      return (
        props?.original?.role || null
      );
    },
  },
];


const defaultSorted = [
  {
    id: 'registeredAt',
    desc: true,
  },
];

class List extends Component {
  constructor(props) {
    super(props);

    this.selectColumns = ['isSubscribed', 'registrationConfirmed', 'role', 'scoliaId'];
  }

  handleFetchData = ({ filter, ...rest }) => {
    const clonedFilter = getPreparedFiltersForSelectedFields(filter, this.selectColumns);

    return this.props.dispatch(userActions.getUsers({ filter: clonedFilter, ...rest }));
  };

  render() {
    const { users, page, pageSize, isLoading, totalCount, isDownloading, isError } = this.props;
    const data = isError ? [] : Object.values(users);

    return (
      <div className="animated fadeIn">
        <EntityTable
          resource={usersResource}
          columns={columns}
          selectColumns={this.selectColumns}
          data={data}
          page={page}
          pageSize={pageSize}
          defaultPageRowSize={200}
          totalCount={totalCount}
          defaultSorted={defaultSorted}
          datePropertyKey={'registeredAt'}
          hasDateFilter
          isDownloadPossible
          isLoading={isLoading}
          onFetchData={this.handleFetchData}
          isDownloading={isDownloading}
        />
      </div>
    );
  }
}

List.displayName = 'Users.List';

const mapStateToProps = state => ({
  users: state.data.users,
  page: state.scenes.users.list.page,
  pageSize: state.scenes.users.list.pageSize,
  totalCount: state.scenes.users.list.totalCount,
  isLoading: state.scenes.users.list.isLoading,
  isDownloading: state.scenes.users.list.isDownloading,
  isError: state.scenes.users.list.isError,
});

export default connect(mapStateToProps)(List);
