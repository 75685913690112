import { call, put, takeLatest } from 'redux-saga/effects';

import { Feedbacks } from 'services/api';
import { callApi } from 'services/rest';

import {
  getFeedbacks,
  getFeedbacksFailure,
  getFeedbacksSuccess,
} from 'scenes/Feedbacks/components/List/actions';

function* watchGetFeedbacks({ payload }) {
  try {
    const { data } = yield call(callApi, Feedbacks.index, payload);
    yield put(getFeedbacksSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(getFeedbacksFailure());
  }
}

// It must be wrapped in a function, so execution will happen only on function call
export default () => takeLatest(getFeedbacks, watchGetFeedbacks);
