import { StatisticsDto } from 'dtos';
import { isNullOrUndefined } from 'utils';

export const formatValue = (number: number, length = 1) =>
  Number.isNaN(number) || isNullOrUndefined(number) ? null : parseFloat(number.toFixed(length));

export const formatTP = (number: number) =>
  Number.isNaN(number) || isNullOrUndefined(number) ? null : formatMaxPrizePool(number.toFixed());

export const formatMaxPrizePool = (prize: string) => {
  const prizeAmount = prize.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return `${prizeAmount} TP`;
};

export const OverallStatsItems = (overallStats: any) => [
  {
    title: 'PLAYED GAMES',
    value: overallStats?.playedGames ?? '-',
  },
  {
    title: '180S',
    value: overallStats?.['180s'] ?? '-',
  },
  {
    title: 'MED',
    value: formatValue(overallStats?.med, 2) ? `${formatValue(overallStats?.med, 2)} mm` : '-',
  },
];

export const RecentFormItems = (recentForm: any) => [
  {
    title: 'First X Average',
    value: formatValue(recentForm?.last100Games?.firstXAverage) ?? '-',
  },
  {
    title: 'Average',
    value: formatValue(recentForm?.last100Games?.average) ?? '-',
  },
  {
    title: 'Checkout',
    value: recentForm?.last100Games?.checkout
      ? `${formatValue(recentForm?.last100Games?.checkout)}%`
      : '-',
  },
];

export const TournamentStatsItems = (tournamentStats: any) => [
  {
    title: 'Leaderboard',
    value: tournamentStats?.allTimePosition ? `#${tournamentStats?.allTimePosition}` : '-',
  },
  {
    title: 'Overall TP',
    value: formatTP(tournamentStats?.allTimeTP) ?? '-',
  },
  {
    title: 'Recent average',
    value: formatValue(tournamentStats?.recentAverage) ?? '-',
  },
];

export const PlayerCardBodyItems = (userStats: StatisticsDto) => [
  OverallStatsItems(userStats?.overallStats),
  RecentFormItems(userStats?.recentForm),
  TournamentStatsItems(userStats?.tournamentStats),
];
