import { all } from 'redux-saga/effects';

import { sagas as createHardwareSaga } from 'scenes/Hardwares/components/Create';
import { sagas as editSaga } from 'scenes/Hardwares/components/Edit';
import { sagas as listSaga } from 'scenes/Hardwares/components/List';
import { sagas as showSaga } from 'scenes/Hardwares/components/Show';

export default function* hardwaresSaga() {
  yield all([createHardwareSaga(), editSaga(), listSaga(), showSaga()]);
}
