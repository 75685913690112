import { handleActions } from 'redux-actions';

import actions from './actions';

import firmwareActions from 'scenes/Firmwares/actions';

const INITIAL_STATE = {
  isLoading: true,
  firmwares: {},
  isDownloading: false,
};

const listReducer = handleActions(
  {
    [firmwareActions.downloadFirmware]: state => ({
      ...state,
      isDownloading: true,
    }),

    [firmwareActions.downloadFirmwareSuccess]: state => ({
      ...state,
      isDownloading: false,
    }),

    [actions.getFirmwares]: state => ({
      ...state,
      isLoading: true,
    }),

    [actions.getFirmwaresSuccess]: () => ({
      isLoading: false,
    }),

    [actions.getFirmwaresFailure]: () => ({
      isLoading: false,
    }),
  },
  INITIAL_STATE
);

export default listReducer;
