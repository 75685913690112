import { isObject } from 'lodash';
import React, { useMemo } from 'react';
import ReactSelect from 'react-select';
import formatLabel from 'utils/formatLabel';
export function Select({ label, helperText, labelClass = 'col-sm-4', valueClass = 'col-sm-8', selectClass, isLabelFormatted = true, options, name, value, onChange, onInputChange, }) {
    const selectedOption = useMemo(() => {
        return options.find((option) => option.value === value);
    }, [options, value]);
    return (React.createElement("dl", { className: "row" },
        React.createElement("dt", { className: labelClass }, isLabelFormatted ? formatLabel(label) : label),
        React.createElement("dd", { className: valueClass, id: `${name}-container` },
            React.createElement(ReactSelect, { className: selectClass, options: options, name: name, value: isObject(value) ? value : selectedOption, isMulti: false, onChange: onChange, onInputChange: onInputChange }),
            helperText && helperText)));
}
