import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import { actions as listActions } from 'scenes/Chat/components/List';
import { actions as showActions } from 'scenes/Chat/components/Show';

//change every 'lobby' when there is more than one chat room
const INITIAL_STATE = {
  'lobby': {}
};

const chatReducer = handleActions(
  {
    [listActions.getChatRoomsSuccess]: (state, { payload }) => {
      const chatRooms = payload.reduce((chatRooms, chatRoom) => {
        if(state[chatRoom.id]){
          chatRooms[chatRoom.id] = {...state[chatRoom.id], ...chatRoom};  
        }
        else{
          chatRooms[chatRoom.id] = {messages: [], ...chatRoom};
        }
        return chatRooms;
      }, {});

      return {
        ...chatRooms,
      };
    },

    [showActions.getMessage]: (state, { payload: message }) => {

      const messages = state['lobby'].messages;
      messages.push(message);
      messages.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

      return update(state, {
        'lobby': {
          messages: {
            $set: [...messages],
          }
        },
      });
    },

    [showActions.getMessages]: (state, { payload }) => {
      const { messages } = payload;

      messages.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

      return update(state, {
        'lobby': {
          messages: {
            $set: [...messages],
          }
        },
      });
    },
  },
  INITIAL_STATE
);

export default chatReducer;
