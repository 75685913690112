import { createAction } from 'redux-actions';

export const getFirmware = createAction(
  'FIRMWARE_GET_REQUEST',
  (firmwareId, queryParams, isLoading = true) => ({
    firmwareId,
    queryParams,
    isLoading,
  })
);
export const getFirmwareFailure = createAction('FIRMWARE_GET_FAILURE');
export const getFirmwareSuccess = createAction('FIRMWARE_GET_SUCCESS');

export const deleteFirmware = createAction('DELETE_FIRMWARE_REQUEST');
export const deleteFirmwareFailure = createAction('DELETE_FIRMWARE_FAILURE');
export const deleteFirmwareSuccess = createAction('DELETE_FIRMWARE_SUCCESS');

export const downloadFirmware = createAction('DOWNLOAD_FIRMWARE_REQUEST');
export const downloadFirmwareFailure = createAction(
  'DOWNLOAD_FIRMWARE_FAILURE'
);
export const downloadFirmwareSuccess = createAction(
  'DOWNLOAD_FIRMWARE_SUCCESS'
);

export default {
  getFirmware,
  getFirmwareFailure,
  getFirmwareSuccess,
  deleteFirmware,
  deleteFirmwareSuccess,
  deleteFirmwareFailure,
  downloadFirmware,
  downloadFirmwareSuccess,
  downloadFirmwareFailure,
};
