import { call, put, takeLatest } from 'redux-saga/effects';

import { Manufacturers } from 'services/api';
import { callApi } from 'services/rest';

import {
  getManufacturerBoards,
  getManufacturerBoardsFailure,
  getManufacturerBoardsSuccess,
} from 'scenes/Manufacturers/components/Show/actions';

function* watchGetManufacturerBoards({ payload }) {
  try {
    const { data } = yield call(callApi, Manufacturers.boards, payload);
    const { data: boards, activeBoards} = data;
    yield put(getManufacturerBoardsSuccess({ boards, manufacturerId: payload, activeBoardsCount: activeBoards}));
  } catch (e) {
    console.log(e);
    yield put(getManufacturerBoardsFailure());
  }
}

// It must be wrapped in a function, so execution will happen only on function call
export default () => takeLatest(getManufacturerBoards, watchGetManufacturerBoards);
