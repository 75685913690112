import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

import { getIsAdmin } from 'services/session/selectors';

import { getManufacturer } from 'scenes/Manufacturers/actions';

import AssignedBoards from './components/AssignedBoards/AssignedBoards';
import { MainInfo } from './components/MainInfo';

import { getManufacturerBoards } from './actions';

class Show extends Component {
  componentDidMount() {
    const { match } = this.props;
    this.props.dispatch(getManufacturer(match.params.manufacturerId));
    this.props.dispatch(getManufacturerBoards(match.params.manufacturerId));
  }

  render() {
    const { manufacturer, assignedBoardsIsLoading, isAdmin } = this.props;

    if (!manufacturer || !manufacturer.manufacturerBoards) {
      return <i className="fa fa-spinner fa-spin fa-fw" />;
    }

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" lg={{ size: 10, offset: 1 }}>
            <MainInfo {...manufacturer} isAdmin={isAdmin} />
            <AssignedBoards boards={manufacturer.manufacturerBoards} activeBoardsCount={manufacturer.activeBoardsCount} isLoading={assignedBoardsIsLoading} />
          </Col>
        </Row>
      </div>
    );
  }
}

Show.displayName = 'Manufacturers.Show';

const mapStateToProps = (state, ownProps) => {
  return {
    manufacturer: state.data.manufacturers[ownProps.match.params.manufacturerId],
    assignedBoardsIsLoading: state.scenes.manufacturers.show.assignedBoards.isLoading,
    isAdmin: getIsAdmin(state),
  };
};

export default connect(mapStateToProps)(Show);
