import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { default as formatDate } from 'utils/formatDate';
import { default as formatElapsedTime } from 'utils/formatElapsedTime';
import styles from './styles.module.scss';

function SessionList({ sessions, isHybridSbc }) {
  const ROWS = {
    online: session =>
      [
        !isHybridSbc && { label: 'Board Code', value: session.rawData.boardCode },
        { label: 'Duration', value: formatElapsedTime(session.duration) },
        { label: 'Outcast Throws', value: session.rawData.outcastThrows },
        { label: 'Connected At', value: formatDate(session.rawData.connectedAt, true) },
        { label: 'Disconnected At', value: formatDate(session.rawData.disconnectedAt, true) },
        { label: 'Restarted', value: session.rawData.isRestarted ? 'Yes' : 'No' },
      ].filter(Boolean),
    offline: session => [
      { label: 'Duration', value: formatElapsedTime(session.duration) },
      { label: 'Throws', value: session.rawData.throws },
      { label: 'Online time', value: formatElapsedTime(session.onlineTime, true) },
      { label: 'Start time', value: formatDate(session.startTime, true) },
      { label: 'End time', value: formatDate(session.endTime, true) },
    ],
  };

  return (
    <div className={cn('key-value-data-container', styles.sessionList)}>
      {sessions.map((session, idx) => {
        return (
          <div key={idx}>
            <hr />
            {ROWS[session.type](session).map((row, i) => (
              <div className="row" key={`${i}_${row.value}`}>
                <div className="col-sm-4">{row.label}</div>
                <div className="col-sm-8">{row.value}</div>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
}

SessionList.propTypes = {
  sessions: PropTypes.array.isRequired,
  isHybridSbc: PropTypes.bool.isRequired,
};

export default SessionList;
