import { call, put, takeLatest } from 'redux-saga/effects';

import { Boards } from 'services/api';
import { callApi } from 'services/rest';

import {
  getOnlineBoards,
  getOnlineBoardsFailure,
  getOnlineBoardsSuccess,
} from 'scenes/OnlineBoards/components/List/actions';

function* watchGetOnlineBoards({ payload: { queryParams } }) {
  try {
    const { data } = yield call(callApi, Boards.onlineBoards, queryParams);
    yield put(getOnlineBoardsSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(getOnlineBoardsFailure());
  }
}

export default () => takeLatest(getOnlineBoards, watchGetOnlineBoards);
