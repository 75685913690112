import { useEffect, useState } from "react";
import { clone, isUndefined, set } from "lodash";
import { getInvitationStatuses } from "utils";
import { ParticipantMap } from "./TournamentParticipantInvite.component";

function replaceAt<T>(array: T[], value: T, index: number): T[] {
  return set(clone(array), [index], value)
}

interface useInvitationValuesParams {
  maxParticipants: number
  participants: ParticipantMap
}

export const useInvitationValues = ({ maxParticipants, participants }: useInvitationValuesParams) => {
  const { acceptedInvitations, pendingInvitations } = getInvitationStatuses(Object.values(participants));

  const sentActiveInvitations = acceptedInvitations + pendingInvitations;

  const availableInvitationsLeftCounter = maxParticipants - sentActiveInvitations;

  const [ inputValues, setInputValues ] = useState<(undefined | string)[]>([...Array(availableInvitationsLeftCounter)])

  const setInputValueAt = (index: number, value: string | undefined) => {
    setInputValues((curr) => (replaceAt<undefined | string>(curr, value, index)))
  }

  const removeInputValueAt = (index: number) => {
    setInputValues((curr) => (replaceAt<undefined | string>(curr, undefined, index)))
  }

  const addMultipleValues = (values: string[]) => {
    let valuesToAdd = clone(values)
    setInputValues((curr) => (curr.map(currValue => {
      if ( !isUndefined(currValue) ) {
        return currValue
      }

      if ( valuesToAdd.length ) {
        const [ elemToAdd, ...rest ] = valuesToAdd;
        valuesToAdd = rest
        return elemToAdd
      }
      
      return currValue
    })))
  }

  const removeMultipleValues = (values: string[]) => {
    setInputValues((curr) => (curr.map(currValue => (isUndefined(currValue) || values.includes(currValue) ? undefined : currValue))))
  }

  const addAndRemoveMultipleValues = (valuesToInsert: string[], valuesToRemove: string[]) => {
    setInputValues((curr) => {
      const filteredValues = curr.map(currValue => (isUndefined(currValue) || valuesToRemove.includes(currValue) ? undefined : currValue))
      
      let valuesToAdd = clone(valuesToInsert)
      const ret = filteredValues.map(currValue => {
        if ( !isUndefined(currValue) ) {
          return currValue
        }
  
        if ( valuesToAdd.length ) {
          const [ elemToAdd, ...rest ] = valuesToAdd;
          valuesToAdd = rest
          return elemToAdd
        }
        
        return currValue
      })
      return ret;
    })
  }

  const resetList = () => {
    setInputValues([...Array(availableInvitationsLeftCounter)])
  }

  useEffect(() => {
    setInputValues([...Array(availableInvitationsLeftCounter)])
  }, [ availableInvitationsLeftCounter ])

  return { inputValues, setInputValueAt, removeInputValueAt, resetList, addMultipleValues, removeMultipleValues, addAndRemoveMultipleValues }
}