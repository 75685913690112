import { handleActions } from 'redux-actions';

import { default as systemAnalyticsActions } from './actions';

const INITIAL_STATE = {
  isLoading: true,
};

const systemAnalyticsReducer = handleActions(
  {
    [systemAnalyticsActions.getSystemAnalytics]: (state, _payload) => ({
      ...state,
      isLoading: true,
    }),

    [systemAnalyticsActions.getSystemAnalyticsSuccess]: (state, _payload) => ({
      ...state,
      isLoading: false,
    }),
  },
  INITIAL_STATE
);

export default systemAnalyticsReducer;
