import { combineReducers } from 'redux';

import { reducer as list } from './components/List';
import { reducer as show } from './components/Show';

const Subscriptions = combineReducers({
  list,
  show,
});

export default Subscriptions;
