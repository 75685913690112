import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader } from 'reactstrap';

class Avatar extends Component {
  get userCountry() {
    const {
      countries,
      user: { country },
    } = this.props;
    const result = countries.find(c => c.alpha3Code === country);

    if (countries.length > 0 && country) {
      return result ? result.name : country;
    }

    return null;
  }

  render() {
    const { user } = this.props;
    return user ? (
      <div>
        <Card color="white">
          <CardHeader>
            <div className="card-header__left">
              <i className="fa fa-align-justify" /> Avatar
            </div>
          </CardHeader>
          <CardBody>
            <Fragment>
              <div className="key-value-data-container">
                <div className="row">
                  <div className="col-sm-4">Profile picture</div>
                  <div className="col-sm-8">
                    {user.avatar ? (
                      <img
                        src={user.avatar}
                        alt="avatar"
                        style={{
                          width: '100%',
                          maxWidth: '250px',
                          maxHeight: '250px',
                        }}
                      />
                    ) : (
                      'No profile picture available'
                    )}
                  </div>
                </div>
              </div>
            </Fragment>
          </CardBody>
        </Card>
      </div>
    ) : (
      <i className="fa fa-spinner fa-spin fa-fw" />
    );
  }
}

Avatar.propTypes = {
  user: PropTypes.object.isRequired,
  countries: PropTypes.array,
};

export default Avatar;
