import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { EntityTable } from 'components/EntityTable';
import { isNullOrUndefined } from 'utils';
import { getColumns, useScoreCorrectionStatistics } from './utils';
import { scoreCorrectionStatistics as scoreCorrectionStatisticsResource } from 'constants/Resources';
import { useQueryClient } from 'react-query';
const defaultSorted = [
    {
        id: 'lastCorrection',
        desc: true,
    },
];
const selectColumns = ['status'];
export const List = () => {
    const queryClient = useQueryClient();
    const { isDownloading } = useSelector((state) => state.scenes.scoreCorrectionStatistics.list);
    const [parameters, setParameters] = useState();
    const { data: scoreCorrectionStatistics, isLoading } = useScoreCorrectionStatistics(parameters);
    const handleFetchData = (parameters, isRefresh) => {
        setParameters(parameters);
        if (isRefresh) {
            queryClient.invalidateQueries(['scoreCorrection', 'statistics']);
        }
    };
    const data = isNullOrUndefined(scoreCorrectionStatistics) ? [] : scoreCorrectionStatistics.data;
    return (React.createElement("div", { className: "animated fadeIn" },
        React.createElement(EntityTable, { resource: scoreCorrectionStatisticsResource, columns: getColumns(), selectColumns: selectColumns, data: data, totalCount: scoreCorrectionStatistics?.totalCount ?? 0, defaultSorted: defaultSorted, datePropertyKey: "lastCorrection", hasDateFilter: true, isDownloading: isDownloading, isLoading: isLoading, onFetchData: handleFetchData })));
};
