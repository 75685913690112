import React from 'react';
import { useSelector } from 'react-redux';
import { Badge, Card, CardBody, CardHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import { isEmpty, isUndefined } from 'lodash';
import cn from 'classnames';
import { TournamentParticipantStatusEnum, TournamentRegistrationType, TournamentState } from 'enums';
import { ArrowIcon, ArrowIconDirection } from 'components/Icons';
import { Headers } from './Headers';
import { useSortedList } from './hooks';
export const Participants = ({ getTournamentSelector }) => {
    const { participants, participantsOrder, state, registrationType } = useSelector(getTournamentSelector);
    const { keyList: orderedParticipantsIds, orderingType, isBottomTopOrdering, handleOrderClick, handleReset } = useSortedList(participantsOrder ?? [], participants);
    const isTournamentStarted = state ? ![TournamentState.Running, TournamentState.Finished].includes(state) : false;
    const isInvitationalTournament = registrationType === TournamentRegistrationType.Invitational;
    const handleSorting = (header) => {
        const accessor = isTournamentStarted ? header?.overallAccessor : header?.tournamentAccessor;
        handleOrderClick({ key: header.key, accessor });
    };
    return (React.createElement(Card, { color: "white" },
        React.createElement(CardHeader, null,
            React.createElement("div", { className: "card-header__left" },
                React.createElement("i", { className: "fa fa-align-justify" }),
                " Participants"),
            React.createElement("div", { className: "card-header__right" },
                React.createElement("button", { onClick: handleReset, className: "btn btn-primary btn-sm ms-1", disabled: !orderingType && !isBottomTopOrdering }, "Reset sorting"))),
        React.createElement(CardBody, null,
            React.createElement("table", { className: 'table table-bordered table-hover w-100' },
                React.createElement("thead", null,
                    React.createElement("tr", null, Headers.map(header => (header.common || isInvitationalTournament) && (React.createElement("th", { key: header.key, onClick: () => handleSorting(header) },
                        isTournamentStarted ? header.overAllLabel : header.tournamentLabel,
                        React.createElement("span", { style: { opacity: orderingType === header.key ? 1 : 0, transition: '.3s' } },
                            React.createElement(ArrowIcon, { direction: isBottomTopOrdering ? ArrowIconDirection.Up : ArrowIconDirection.Down }))))))),
                React.createElement("tbody", null, !isUndefined(orderedParticipantsIds) && orderedParticipantsIds?.map((userId) => (React.createElement("tr", { key: userId },
                    React.createElement("td", null,
                        React.createElement(Link, { to: `/users/${userId}` }, participants[userId]?.nickname || '-')),
                    React.createElement("td", null,
                        React.createElement(Badge, { color: cn({
                                success: participants[userId]?.status === TournamentParticipantStatusEnum.Accepted || !isInvitationalTournament,
                                warning: participants[userId]?.status === TournamentParticipantStatusEnum.Pending,
                                danger: participants[userId]?.status === TournamentParticipantStatusEnum.Declined,
                            }) }, isInvitationalTournament ? participants[userId]?.status : 'Joined')),
                    React.createElement("td", null, participants[userId]?.scoliaId ? `#${participants[userId]?.scoliaId}` : '-'),
                    React.createElement("td", null, isTournamentStarted
                        ? participants[userId]?.overallStats?.tournamentPoints
                        : participants[userId]?.tournamentStats?.tournamentPoints),
                    React.createElement("td", null, isTournamentStarted
                        ? participants[userId]?.overallStats?.average?.toFixed(1)
                        : participants[userId]?.tournamentStats?.average?.value?.toFixed(1)),
                    React.createElement("td", null, isTournamentStarted
                        ? participants[userId]?.overallStats?.playedGames
                        : participants[userId]?.tournamentStats?.wonGames)))))),
            isUndefined(orderedParticipantsIds) || isEmpty(orderedParticipantsIds) && (React.createElement("span", { className: 'w-100 center' }, "There are no participants yet.")))));
};
