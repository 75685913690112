import React, { useMemo } from 'react';
export var ArrowIconDirection;
(function (ArrowIconDirection) {
    ArrowIconDirection["Up"] = "up";
    ArrowIconDirection["Down"] = "down";
    ArrowIconDirection["Left"] = "left";
    ArrowIconDirection["Right"] = "right";
})(ArrowIconDirection || (ArrowIconDirection = {}));
export const ArrowIcon = ({ size = 20, color = "#344054", direction = ArrowIconDirection.Up, }) => {
    const rotation = useMemo(() => {
        switch (direction) {
            case ArrowIconDirection.Up:
                return '90';
            case ArrowIconDirection.Right:
                return '180';
            case ArrowIconDirection.Down:
                return '270';
            case ArrowIconDirection.Left:
                return '0';
            default:
                return '90';
        }
    }, [direction]);
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: size, height: size, viewBox: "0 0 20 20", fill: "none", style: { transition: `transform .3s`, transform: `rotate(${rotation}deg)` } },
        React.createElement("path", { d: "M12.5 15L7.5 10L12.5 5", stroke: color, strokeWidth: "1.66667", strokeLinecap: "round", strokeLinejoin: "round" })));
};
