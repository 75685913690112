import { call, put, takeLatest } from 'redux-saga/effects';

import { Configuration } from 'services/api';

import { callApi } from 'services/rest';
import { notifySuccess } from 'services/notification/actions';

import { updateConfiguration, updateConfigurationFailure } from './actions';
import { fetchConfigurationSuccess } from 'services/configuration/actions'

function* watchUpdateConfig({ payload } = this.payload) {
  try {
    const { data } = yield call(callApi, Configuration.update, payload);
    yield put(fetchConfigurationSuccess(data));
    yield put(notifySuccess(`Configs have been updated successfully.`));
  } catch (e) {
    yield put(updateConfigurationFailure(e));
  }
}

export default () => takeLatest(updateConfiguration, watchUpdateConfig);
