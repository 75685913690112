import { take, call } from 'redux-saga/effects';

import { Socket, actions as wsActions } from 'services/ws';

import { unsubscribeToTournamentUpdates } from '../../actions';


export default function* watchUnsubscribeToTournamentUpdates() {
  while (true) {
    const { payload: { channel, tournamentId } } = yield take(unsubscribeToTournamentUpdates);
    const payload = wsActions.Management.tournamentUpdatesUnsubscribe({ channel, subjects: [tournamentId] })
    yield call([Socket, Socket.send], JSON.stringify(payload));
  }
}
