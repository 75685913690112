import React, { useState, useEffect } from 'react'
import { Card, Button } from 'reactstrap'
import { connect, useDispatch } from 'react-redux'

import TournamentCardList from './components/TournamentCardList/TournamentCardList'
import { tournamentEntityValidationObject } from './validationSchema'

import { getIsAdmin } from 'services/session/selectors'
import actions from './actions'

import styles from './styles.module.scss'

const ImportTournaments = props => {
  const { isAdmin, userId } = props;

  const [errors, setErrors] = useState([]);
  const [parsedFile, setParsedFile] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isExpandedList, setExpandedList] = useState(false);
  const [dataErrors, setDataErrors] = useState([]);
  const dispatch = useDispatch()

  //uploading the file
  const changeHandler = async (e) => {
    e.preventDefault()
    //reset errors
    setErrors([])
    setDataErrors([])
    setParsedFile([])
    //read the file
    const reader = new FileReader()
    reader.onload = async (e) => { 
      //validate content format and parsing
      validateContent(e?.target?.result)
    };
    if ( e?.target?.files[0] ) {
      reader.readAsText(e?.target?.files[0])
    }
    //reset the target so if we change the content of the file
    //and upload again, onChange can be triggered
    e.target.value = ''
  }

  //validate the file
  const validateContent = content => {
    let fileContent;
    try {
      fileContent = JSON.parse(content);
    } catch(e) {
      alert(e);
      return;
    }

    if( !Array.isArray(fileContent) ) {
      setErrors(['File content is not an array'])
      return;
    }
    setErrors([])
    setSelectedFile(content)
  }

  //parse the file content if the upload was successful
  useEffect(() => {
    if(selectedFile) {
      const parsed = JSON.parse(selectedFile);
      parsed.forEach(t => t.organiser.organiserId = userId)
      setParsedFile(parsed)
      const currentErrors = [];
      
      //validation of the parsed data
      parsed.forEach((t, idx) => {
        tournamentEntityValidationObject
          .validate(t, { abortEarly: false })
          .then((_responseData) => {
            //console.log("no validation errors");
            //console.log(responseData);
            currentErrors[idx] = [];
            if(idx === parsed.length -1) {
              setDataErrors(currentErrors);
            }
          })
          .catch((err) => {
            // console.log(err);
            //console.log(err.name); // ValidationError
            currentErrors[idx] = [...err.errors]
            if(idx === parsed.length -1) {
              setDataErrors(currentErrors);
            }
          });
      })
    }
  }, [selectedFile, userId])

  const getImportBtn = () => {
    return (
      <div className={styles.importWrapper}>
        <label htmlFor="file-upload" className={styles.customFileUpload}>
          <i className="icon-cloud-upload"/>Import JSON list
        </label>
        <input
          type="file"
          id="file-upload"
          accept=".json"
          onChange={changeHandler}
        />
      </div>
    )
  }

  const handleClick = () => {
    dispatch(actions.importTournaments(parsedFile))
  }

  return (
    <Card className="animated fadeIn">
      <div className={styles.cardWrapper}>
        <div className={styles.actionsWrapper}>
          <div>{getImportBtn()}</div>
          {isAdmin && <div>
            <Button
              color="success"
              disabled={
                dataErrors.filter(e => e.length > 0)?.length > 0 || 
                parsedFile?.length < 1 || 
                !parsedFile
              }
              style={{borderRadius: '8px'}}
              onClick={handleClick}
            >
              Save
            </Button>
          </div>}
          <div>
            <Button
              color="secondary"
              onClick={() => setExpandedList(!isExpandedList)}
            >
              {isExpandedList ? 'Contract all' : 'Expand all'}
            </Button>
          </div>
        </div>
        <div className={styles.errors}>
            {errors.map((e, idx) => (
              <div key={idx}>{e}</div>
            ))}
        </div>
        {parsedFile && <TournamentCardList
          fileContent={parsedFile}
          isExpandedList={isExpandedList}
          dataErrors={dataErrors}
          userId={userId}
        />}
      </div>
    </Card>
  );
}

ImportTournaments.displayName = 'Tournaments.Import';

const mapStateToProps = state => ({
  isAdmin: getIsAdmin(state),
  userId: state.session._id,
});

export default connect(mapStateToProps)(ImportTournaments);
