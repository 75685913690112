import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardText, CardTitle, Col, Row } from 'reactstrap';
import cn from 'classnames';

import { formatNumberWithThousandSeparators } from 'utils';

import styles from './styles.module.scss';

class SummaryCards extends Component {
  card = (key, label) => {
    return (
      <Col xs={12} sm={6} md={4} lg={2} key={'summary-card_' + key}>
        <Card color="white">
          <CardBody>
            <div className="h1 text-muted text-end mb-4">
              <i className={this.cardIconClasses[key]} />
            </div>
            <CardTitle>
                <strong className={cn('text-value h4', styles.textValue)}>
                  {this.cardValues[key]}
                </strong>
            </CardTitle>
            <CardText>
              <small className="text-muted text-uppercase fw-bold">
                {label}
              </small>
            </CardText>
          </CardBody>
        </Card>
      </Col>
    );
  };

  get cardIconClasses() {
    return {
      totalUsers: 'icon-people',
      boards: 'cui-cart',
      hoursPlayed: 'icon-speedometer',
      gamesPlayed: 'fa fa-gamepad',
      dartsThrown: 'fa fa-location-arrow',
      detectionPrecision: 'fa fa-bullseye',
    };
  }

  get cardValues() {
    const hoursPlayed = formatNumberWithThousandSeparators(
      (
        this.props.boardStatistics.general.gamesNetDuration /
        1000 /
        60 /
        60
      ).toFixed(2),
      ' '
    );

    const throwsCount = this.props.boardStatistics.general.throwsCount;

    const detectionPrecision =
      (
        (throwsCount.automaticallyDetected /
          (throwsCount.automaticallyDetected +
            throwsCount.manuallyAdded +
            throwsCount.modified)) *
        100
      ).toFixed(3) + ' %';

    return {
      totalUsers: formatNumberWithThousandSeparators(
        this.props.users.total,
        ' '
      ),
      boards: formatNumberWithThousandSeparators(this.props.boards.total, ' '),
      hoursPlayed,
      gamesPlayed: formatNumberWithThousandSeparators(
        this.props.boardStatistics.general.gamesCount.total,
        ' '
      ),
      dartsThrown: formatNumberWithThousandSeparators(throwsCount.total, ' '),
      detectionPrecision,
    };
  }

  get cardLabels() {
    return [
      { key: 'totalUsers', label: 'Total Users' },
      { key: 'boards', label: 'Boards' },
      { key: 'hoursPlayed', label: 'Hours Played' },
      {
        key: 'gamesPlayed',
        label: 'Games Played',
      },
      { key: 'dartsThrown', label: 'Darts Thrown' },
      { key: 'detectionPrecision', label: 'Detection Precision' },
    ];
  }

  get cards() {
    return this.cardLabels.map(({ key, label }) => this.card(key, label));
  }

  render() {
    return <Row>{this.cards}</Row>;
  }
}

SummaryCards.propTypes = {
  users: PropTypes.object.isRequired,
  boards: PropTypes.object.isRequired,
  boardStatistics: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

SummaryCards.defaultProps = {
  isLoading: true,
};

export default SummaryCards;
