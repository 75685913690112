import { call, put, takeLatest } from 'redux-saga/effects';

import { Products } from 'services/api';
import { callApi } from 'services/rest';
import { browserHistory } from 'services/history';

import {
  getProduct,
  getProductFailure,
  getProductSuccess,
} from 'scenes/Products/components/Show/actions';

function* watchGetProduct({ payload: productId }) {
  try {
    const { data } = yield call(callApi, Products.show, productId);
    yield put(getProductSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(getProductFailure());
    browserHistory.push('/products/');
  }
}

export default () => takeLatest(getProduct, watchGetProduct);
