import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useEventBus } from 'hooks/useEventBus';
import { tournaments } from 'queryKeys';

import { browserHistory } from 'services/history';
import { notifyError, notifySuccess } from 'services/notification';
import { get, patch, post } from 'services/rest';

import { isUndefined } from 'lodash';
import { prepareTournamentValues } from './components/utils';
import {
  getNumberOfParticipants,
  handleAddOrRemoveParticipant,
  handleChangeParticipantReply,
} from 'utils/tournamentUtilFunctions';

export const useFetchInvitationalTournament = (tournamentId?: string) => {
  useEventBus('participantsUpdate', mutateParticipantsUpdate);

  const { isLoading, error, data, refetch } = useQuery({
    queryKey: tournaments.index(tournamentId!).queryKey,
    queryFn: async () => {
      if (!tournamentId) {
        return null;
      }

      const data: any = await get(`/tournaments/${tournamentId}`);

      return prepareTournamentValues(data.data);
    },
    enabled: !isUndefined(tournamentId),
  });

  return { isLoading, error, data, refetch };
};

const mutateParticipantsUpdate = (payload: any, queryClient: QueryClient) => {
  const { data } = payload;

  queryClient.setQueryData(tournaments.index(payload.subject).queryKey, (oldData: any) => {
    const isAddOrRemove = Boolean(data.newParticipants || data.removedParticipant);
    const isReply = Boolean(data.reply);

    if (!isReply && !isAddOrRemove) {
      return oldData;
    }

    const newTournament = isAddOrRemove
      ? handleAddOrRemoveParticipant(oldData, [], data)
      : handleChangeParticipantReply(oldData, data);
    newTournament.numberOfParticipants = getNumberOfParticipants(
      Object.values(newTournament.participants ?? {}),
      payload.registrationType
    );

    return newTournament;
  });
};

export const useFormEditSubmit = (tournamentId: string) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: async (formattedValues: any) =>
      await patch(`/tournaments/${tournamentId}`, formattedValues),
    onSuccess: () => {
      dispatch(notifySuccess('Tournament edited successfully!'));
      browserHistory.push(`/tournaments/${tournamentId}`);
      queryClient.invalidateQueries(tournaments.index(tournamentId).queryKey);
    },
    onError: (error: unknown) => {
      console.error(error);
      dispatch(notifyError("Couldn't edit tournament!"));
    },
  });
};

export const useDeleteParticipantInvitation = (tournamentId: string) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate } = useMutation({
    mutationFn: async (userId: string) =>
      await post(`/private-tournaments/${tournamentId}/revoke-invitations`, {
        userIds: [userId],
      } as any),
    onSuccess: (response) => {
      if ((response?.data as any)?.successfullyRevokedUserIds?.length) {
        dispatch(notifySuccess('Invitation was revoked successfully!'));
        queryClient.invalidateQueries(tournaments.index(tournamentId).queryKey);
      } else {
        dispatch(notifyError("Couldn't revoke invitation!"));
      }
    },
    onError: (error: unknown) => {
      console.error(error);
      dispatch(notifyError('Something went wrong!'));
    },
  });

  return { mutate };
};

export const useResendParticipantInvitation = (tournamentId: string) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: async (userId: string) =>
      await post(`/private-tournaments/${tournamentId}/invite`, { userIds: [userId] } as any),
    onSuccess: () => {
      dispatch(notifySuccess('Invitation was sent successfully!'));
      queryClient.invalidateQueries(tournaments.index(tournamentId).queryKey);
    },
    onError: (error: unknown) => {
      console.error(error);
      dispatch(notifyError('Something went wrong!'));
    },
  });
};
