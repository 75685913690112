const formatElapsedTime = (milliseconds, withLabels = false) => {
  const ms = milliseconds % 1000;
  milliseconds = (milliseconds - ms) / 1000;
  const secs = milliseconds % 60;
  milliseconds = (milliseconds - secs) / 60;
  const mins = milliseconds % 60;
  const hrs = (milliseconds - mins) / 60;

  if (withLabels) {
    return (
      [[hrs, 'hr'], [mins, 'min'], [secs, 'sec']].reduce((formattedString, [time, label]) => {
        return time > 0
          ? (formattedString += ` ${time} ${label}${time > 1 ? 's' : ''}`)
          : formattedString;
      }, '') || '0 min'
    );
  } else {
    return hrs + ':' + mins + ':' + secs;
  }
};

export default formatElapsedTime;
