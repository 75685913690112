import React from 'react';
import cn from 'classnames';
import { Badge } from 'reactstrap';
import TableRow from 'components/TableRow';
export const LiveBoardViewShow = ({ config }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(TableRow.Default, { label: "Enabled on Online games", value: React.createElement(Badge, { color: cn({ success: config.enabledOnOnlineGames, secondary: !config.enabledOnOnlineGames }) }, config.enabledOnOnlineGames ? 'Yes' : 'No') }),
        React.createElement(TableRow.Default, { label: "Enabled on Scolia Tournaments", value: React.createElement(Badge, { color: cn({ success: config.enabledOnScoliaTournaments, secondary: !config.enabledOnScoliaTournaments }) }, config.enabledOnScoliaTournaments ? 'Yes' : 'No') }),
        React.createElement(TableRow.Default, { label: "Enabled on Private Tournaments", value: React.createElement(Badge, { color: cn({ success: config.enabledOnPrivateTournaments, secondary: !config.enabledOnPrivateTournaments }) }, config.enabledOnPrivateTournaments ? 'Yes' : 'No') }),
        React.createElement(TableRow.Default, { label: "Frames per second", value: config.streamingProperties.framesPerSecond }),
        React.createElement(TableRow.Default, { label: "Quality", value: `${config.streamingProperties.quality}%` }),
        React.createElement(TableRow.Default, { label: "Scale", value: `${config.streamingProperties.scale} => ${Math.round(720 * (config.streamingProperties.scale / 100))}px` })));
};
