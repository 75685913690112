import { createAction } from 'redux-actions';

export const getBoards = createAction('BOARDS_GET_REQUEST');
export const getBoardsFailure = createAction('BOARDS_GET_FAILURE');
export const getBoardsSuccess = createAction('BOARDS_GET_SUCCESS');

export const getCountries = createAction('COUNTRIES_GET_REQUEST');
export const getCountriesFailure = createAction('COUNTRIES_GET_FAILURE');
export const getCountriesSuccess = createAction('COUNTRIES_GET_SUCCESS');

export default {
  getBoards,
  getBoardsFailure,
  getBoardsSuccess,
  getCountries,
  getCountriesFailure,
  getCountriesSuccess,
};
