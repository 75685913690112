import { FlagProvider } from '@unleash/proxy-client-react';
import React from 'react';
const config = {
    url: `${window.origin}/unleash/proxy`,
    clientKey: 'public_unleash_client_key',
    refreshInterval: 60,
    appName: 'management',
};
export function withFlagProvider(Component) {
    return function WithFlagProvider() {
        return (React.createElement(FlagProvider, { config: config },
            React.createElement(Component, null)));
    };
}
