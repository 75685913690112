import { call, put, takeLatest } from 'redux-saga/effects';
import { boardTransactionInvoiceUrl, getInvoiceUrl } from '../../actions';
import { callApi } from 'services/rest';
import Transactions from 'services/api/Transactions';

export function* watchGetInvoiceUrl({payload}) {
  try {
    const { data } = yield call(callApi, Transactions.getTransactionInvoiceUrl, payload);
    const { url } = data;
    
    if (url) {
      window.open(url, '_blank');
    }
    yield put(boardTransactionInvoiceUrl());
  } catch (e) {
    if (!e.isFetch) {
      throw e;
    }
  }
}

export default () => takeLatest(getInvoiceUrl, watchGetInvoiceUrl);
