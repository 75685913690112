export const IMDUR = '#f6f6f6';
export const WOOL = '#e8e8e8';
export const SLUSH = '#979797';
export const GRRAY = '#333333';
export const MINESHAFT = '#252525';
export const CODGRAY = '#1c1c1c';
export const LICORICE = '#151515';
export const PANDA = '#121310';
export const AUTUMN = '#f9a602';
export const WARNING = '#ffc700';
export const WATTLE = '#dcca40';
export const CORAL = '#F25F5F';
export const DISCONNECT = '#b90504';
export const SCHIZO = '#990100';
export const LIMEADE = '#83bd4c';
export const LAPALMA = '#4c9a2d';
export const SALAD = '#008b3e';
export const EUCALYPTUS = '#007237';
export const CAMARONE = '#005c2e';
export const LAGUNE = '#59bfce';
export const METRO = '#1b70e0';
export const SUCCESS = '#5aab30';
export const INFO = '#1b70e0';
export const ERROR = '#f25f5f';
export const AZURERADIANCE = '#0C8AF7';
export const PANACHE = '#EFF7EB';
export const CHABILIS = '#FFEFEF';
export const MANGO = '#EF9D34';
export const ESCARGOT = '#FFF1D8';
export const KHMER_CURRY = '#EB5757';
export const WHITE = '#FFFFFF';
export const IRON = '#D0D5DD';