import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TournamentShow } from 'components/TournamentShow';

import { getPrivateTournament, killPrivateTournament, subscribeToPrivateTournamentUpdates, unsubscribeToPrivateTournamentUpdates } from './actions';
import { getPrivateTournament as getPrivateTournamentSelector } from './selectors';

const Show = ({
  match: {
    params: { tournamentId },
  },
}) => {
  const { tournament, isLoading } = useSelector(state => state.scenes.privateTournaments.show);

  const dispatch = useDispatch();

  const handleKillTournament = useCallback(
    tournamentId => {
      dispatch(killPrivateTournament(tournamentId));
    },
    [dispatch]
  );

  return (
    <TournamentShow
      tournamentId={tournamentId}
      tournament={tournament}
      isLoading={isLoading}
      getTournamentAction={getPrivateTournament}
      getTournamentSelector={getPrivateTournamentSelector}
      onKillTournament={handleKillTournament}
      subscribeAction={subscribeToPrivateTournamentUpdates}
      unsubscribeAction={unsubscribeToPrivateTournamentUpdates}
    />
  );
};

Show.displayName = 'Tournaments.Show';

export default Show;
