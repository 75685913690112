import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import * as STATUSES from 'constants/WsStatuses';

import actions from './actions';

const INITIAL_STATE = {
  status: STATUSES.CLOSED,
};

const ws = handleActions(
  {
    [actions.initWsConnection]: state =>
      update(state, {
        status: { $set: STATUSES.OPENING },
      }),

    [actions.initWsConnectionSuccess]: state =>
      update(state, {
        status: { $set: STATUSES.OPENED },
      }),

    [actions.initWsConnectionFailure]: state =>
      update(state, {
        status: { $set: STATUSES.CLOSED },
      }),

    [actions.closeWsConnection]: state =>
      update(state, {
        status: { $set: STATUSES.CLOSED },
      }),
  },
  INITIAL_STATE
);

export default ws;
