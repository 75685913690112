import { getFilter } from 'components/ListSelector';
import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { ScoreCorrections } from 'services/api';
import { isNullOrUndefined } from 'utils';
import formatLabel from 'utils/formatLabel';
import { formatDate } from 'utils/timestampFormatter';
const ScoreCorrectionEventTypeEnum = {
    CounterReset: 'CounterReset',
    WarningSent: 'WarningSent',
    NotificationSent: 'NotificationSent',
    Suspension: 'Suspension',
    ScoreCorrection: 'ScoreCorrection',
};
const ScoreCorrectionDecisionEnum = {
    Approved: 'Approved',
    Declined: 'Declined',
    Canceled: 'Canceled',
};
export const useScoreCorrectionHistory = (userId, queryFilters) => {
    const query = useQuery({
        queryKey: ['scoreCorrection', 'history', userId, queryFilters],
        queryFn: () => ScoreCorrections.history(userId, queryFilters),
        select: (data) => data?.data,
        refetchOnWindowFocus: false,
        enabled: !isNullOrUndefined(queryFilters),
    });
    return query;
};
export const getColumns = (clientUrl) => {
    const getAlignedCell = ({ value }, styles) => (React.createElement("div", { style: { width: '100%', textAlign: 'center', ...styles } }, value));
    const getAdvantageousLabel = (value) => {
        let advantage;
        let color;
        if (isNullOrUndefined(value)) {
            advantage = '-';
        }
        else {
            advantage = value ? 'For' : 'Against';
            color = value ? 'red' : 'green';
        }
        return [{ value: advantage }, { color, fontWeight: 'bold' }];
    };
    const headerValues = [
        {
            Header: 'Event type',
            accessor: 'eventType',
            sortable: false,
            filterable: true,
            Filter: getFilter(ScoreCorrectionEventTypeEnum),
            Cell: (props) => getAlignedCell({ value: formatLabel(props.value) }),
        },
        {
            Header: 'GameID',
            accessor: 'gameId',
            sortable: false,
            filterable: true,
            Cell: ({ value, original }) => getAlignedCell({
                value: (React.createElement("a", { target: "_blank", rel: "noreferrer noopener", href: `${clientUrl}/history/${original.gameRef}` }, value)),
            }),
        },
        {
            Header: 'Opponent',
            accessor: 'opponent',
            sortable: false,
            Cell: ({ value, original }) => getAlignedCell({ value: React.createElement(Link, { to: `${value}` }, original.nickname) }),
        },
        {
            Header: 'Date',
            accessor: 'date',
            sortable: true,
            Cell: ({ value }) => getAlignedCell({ value: formatDate(value) }),
        },
        {
            Header: 'From',
            accessor: 'fromSector',
            sortable: false,
            Cell: (props) => getAlignedCell(props),
        },
        {
            Header: 'To',
            accessor: 'toSector',
            sortable: false,
            Cell: (props) => getAlignedCell(props),
        },
        {
            Header: 'F/A',
            accessor: 'isAdvantageous',
            sortable: false,
            filterable: true,
            Filter: getFilter({ Against: 'Against', For: 'For' }),
            Cell: ({ value }) => getAlignedCell(...getAdvantageousLabel(value)),
        },
        {
            Header: 'Decision',
            accessor: 'status',
            sortable: false,
            filterable: true,
            Filter: getFilter(ScoreCorrectionDecisionEnum),
            Cell: (props) => getAlignedCell(props),
        },
    ];
    return headerValues;
};
