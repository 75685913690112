import React, { Component } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

class ToggleFilter extends Component {
  onToggleHandler = () =>
    !this.props.readonly &&
    this.props.id &&
    this.props.onToggleFilter(this.props.id);

  render = () => {
    const { checked, count, label, background, readonly } = this.props;

    return (
      <div
        className={cn([
          styles.wrapper,
          { [styles.checked]: checked, [styles.wrapperReadonly]: readonly },
        ])}
        onClick={this.onToggleHandler}
      >
        <div
          style={{ background: background }}
          className={cn([styles.countBadge, { [styles.readonly]: readonly }])}
        >
          {count}
        </div>
        <div className={styles.label}>{label}</div>
      </div>
    );
  };
}

ToggleFilter.propTypes = {
  onToggleFilter: PropTypes.func,
  checked: PropTypes.bool,
  count: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  background: PropTypes.string,
  readonly: PropTypes.bool,
  id: PropTypes.string,
};

ToggleFilter.defaultProps = {
  checked: true,
  background: '#4FA7D3',
};

export default ToggleFilter;
