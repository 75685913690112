import React from 'react'
import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'

import { Card, CardBody, CardHeader } from 'reactstrap'

import PAYMENT_INTERVALS from 'constants/PaymentInterval'
import PAYMENT_PROVIDERS from 'constants/PaymentProviders'
import SUBSCRIPTIONS_STATUSES from 'constants/SubscriptionsStatuses'

import TableRow from 'components/TableRow'

import selectors from './selectors'

const PADDLE_SANDBOX_URL = 'https://sandbox-vendors.paddle.com/customers-v2/'
const PADDLE_URL = 'https://vendors.paddle.com/customers-v2/'

const STATUS_COLORS = {
  active: 'success',
  canceled: 'info',
  scheduled_for_canceling: 'danger',
}

const PROVIDER_COLORS = {
  Paddle: 'blue',
  Stripe: 'red',
}

const MainData = ({ isLoading, data }) => {
  const isPaddleSandbox = useSelector(selectors.getIsUsingPaymentSandbox)

  const paddleLink = isPaddleSandbox ? PADDLE_SANDBOX_URL : PADDLE_URL

  return (
    <Card color="white">
      <CardHeader>
        <div className="card-header__left">
          <i className="fa fa-align-justify" /> Subscription details
        </div>
      </CardHeader>
      {!isLoading && data && (
        <CardBody>
          <div className="key-value-data-container">
            <TableRow.Link label="userReference" value={data.userReference} />
            <TableRow.Link label="boardReference" value={data.boardReference} />
            <TableRow.Link label="productReference" value={data.productReference} />
            <TableRow.Link label="paddleCustomerReference" value={{...data.paddleCustomerReference, name: 'Click to visit paddle'}} defaultUrlBody={paddleLink} />
            <TableRow.Default label="trialPeriodDays" value={data.trialPeriodDays} />
            <TableRow.Boolean label="isScheduledForCancellation" value={data.isScheduledForCancellation} />
            <TableRow.Boolean label="isServiceActive" value={data.isServiceActive} />
            <TableRow.Enum label="status" value={data.status} colorMap={STATUS_COLORS} />
            <TableRow.Default label="interval" value={data.interval} />
            <TableRow.Enum label="paymentProvider" value={data.paymentProvider} colorMap={PROVIDER_COLORS} />
            <TableRow.Date label="createdDate" value={data.createdDate} />
            <TableRow.Date label="signupDate" value={data.signupDate} />
            <TableRow.Date label="startDate" value={data.startDate} />
            <TableRow.Date label="endDate" value={data.endDate} />
          </div>
        </CardBody>
      )}
    </Card>
  )
}

MainData.displayName = 'MainData';

MainData.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    trialPeriodDays: PropTypes.number,
    isServiceActive: PropTypes.bool,
    isScheduledForCancellation: PropTypes.bool,
    interval: PropTypes.oneOf(Object.values(PAYMENT_INTERVALS)),
    status: PropTypes.oneOf(Object.values(SUBSCRIPTIONS_STATUSES)),
    paymentProvider: PropTypes.oneOf(Object.values(PAYMENT_PROVIDERS)),
    createdDate: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    signupDate: PropTypes.string,
    productReference: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
    userReference: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
    boardReference: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
    paddleCustomerReference: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
  })
}

export default MainData