import React from 'react';
import cn from 'classnames';
import { SBadge } from '../SBadge';
import { CheckboxIcon } from 'components/Icons';
import styles from './styles.module.scss';
export const PackageOption = ({ title, description, disabled, theme, isSelected, onSelect, value, name, badgeProps }) => {
    return (React.createElement("button", { name: name, value: value, onClick: onSelect, className: cn(styles.packageOption, { [styles.isSelected]: isSelected }), disabled: disabled, type: "button" },
        React.createElement("div", { className: styles.selectedBox },
            React.createElement(CheckboxIcon, { isActive: isSelected, theme: theme })),
        React.createElement("div", { className: styles.title }, title),
        React.createElement("div", { className: styles.description }, description),
        badgeProps && (React.createElement("div", { className: styles.badge },
            React.createElement(SBadge, { ...badgeProps })))));
};
