import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNullOrUndefined, formatDate } from 'utils';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import cn from 'classnames';
import isoCountries from 'i18n-iso-countries';

import SUBSCRIPTIONS_STATUSES from 'constants/SubscriptionsStatuses';
import PAYMENT_PRODUCT_TYPES from 'constants/PaymentProductTypes';
import PRODUCT_FEATURE_SET from 'constants/ProductFeatureSets';

import { EntityTable } from 'components/EntityTable';
import { getFilter } from 'components/ListSelector';

import * as subscriptionsActions from './actions';
import { getIsAdmin } from 'services/session/selectors';

import formatLabel from 'utils/formatLabel';


const getColumns = (PRICE_OPTIONS = {}, countries) => [
  {
    Header: '#',
    id: 'row',
    maxWidth: 80,
    accessor: d => d.index,
    Cell: props => <Link to={`/subscriptions/${props.row._original.id}`}>{props.page * props.pageSize + props.index + 1 + '. subscription'}</Link>,
    sortable: false,
  },
  {
    Header: 'User email',
    accessor: 'email',
    filterable: true,
    Cell: props => <Link to={`/users/${props.row._original.userId}`}>{props.value}</Link>,
  },
  {
    Header: 'Board',
    accessor: 'boardSerialNumber',
    filterable: true,
    Cell: props => <Link to={`/boards/${props.row._original.boardId}`}>{props.value}</Link>,
  },
  {
    Header: 'Board\'s country ',
    accessor: 'boardCountry',
    filterable: true,
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : ''}
          >
            <option value="">All</option>
            {countries.map(country => {
              return (
                <option key={isoCountries.alpha3ToAlpha2(country.alpha3Code)} value={isoCountries.alpha3ToAlpha2(country.alpha3Code)}>
                  {country.name}
                </option>
              );
            })}
          </select>
        ),
        Cell: props => <div>{props.value && props.value}</div>        
  },
  {
    Header: 'Product name',
    accessor: 'name',
    filterable: true,
    Cell: props => <Link to={`/products/${props.row._original.productId}`}>{formatLabel(props.value)}</Link>,
    Filter: getFilter(PRODUCT_FEATURE_SET),
  },
  {
    Header: 'Status',
    accessor: 'status',
    filterable: true,
    Cell: ({ value, row }) => (
      <Badge
        color={cn({
          success: value === SUBSCRIPTIONS_STATUSES.active && !row._original.isScheduledForCancellation,
          autumn:  value === SUBSCRIPTIONS_STATUSES.canceled && !row._original.isScheduledForCancellation,
          danger: value === SUBSCRIPTIONS_STATUSES.past_due && !row._original.isScheduledForCancellation,
          secondary: value === SUBSCRIPTIONS_STATUSES.trialing && !row._original.isScheduledForCancellation,
          cyan: row._original.isScheduledForCancellation,
        })}
      >
        {formatLabel(row._original.isScheduledForCancellation ? SUBSCRIPTIONS_STATUSES.scheduled_for_canceling : value)}
      </Badge>
    ),
    Filter: getFilter(SUBSCRIPTIONS_STATUSES),
  },
  {
    Header: 'Starts at',
    accessor: 'startDate',
    Cell: props => formatDate(props.value),
  },
  {
    Header: 'Type',
    accessor: 'type',
    filterable: true,
    Filter: getFilter(PAYMENT_PRODUCT_TYPES),
  },
  {
    Header: 'Price',
    accessor: 'nextPayment.amount',
    filterable: true,
    Filter: getFilter(PRICE_OPTIONS, false),
    Cell: props => `${props.row._original.nextPayment.currency} ${props.value}`,
  },
]

const defaultSorted = [];

const List = () => {
  const { data, page, pageSize, isLoading, totalCount, isDownloading, priceOptions: { options } } = useSelector(state => state.scenes.subscriptions.list);
  const { countries } = useSelector(state => state.data)
  const isAdmin = useSelector(getIsAdmin);
  const dispatch = useDispatch();

  //ScheduledForCanceling is not part of the API enum therefore we need to change the outgoing filter here to the requested flag
  const onQueryMapping = ({ sorted, filter }) => {
    sorted.forEach(elem => {
      if (elem.id === 'status') {
        sorted.push({ id: 'isScheduledForCancellation', value: elem.value });
      }
    });

    filter.forEach(elem => {
      if (elem.id === 'status') {
        if (elem.value === SUBSCRIPTIONS_STATUSES.active) {
          elem.id = 'eq:isScheduledForCancellation';
          elem.value = false;
          filter.push({ id: 'status', value: 'Active' });
        } else if (elem.value === SUBSCRIPTIONS_STATUSES.scheduled_for_canceling) {
          elem.id = 'eq:isScheduledForCancellation';
          elem.value = true;
        }
      } else if (elem.id === 'nextPayment.amount') {
        elem.id = 'eq:nextPayment.amount';
      }
    });
  };

  const handleFetchData = query => {
    dispatch(subscriptionsActions.getSubscriptionsList(query));
  };

  useEffect(() => {
    if ( isNullOrUndefined(options) ) {
      dispatch(subscriptionsActions.getPriceOptions());
    }
  }, [ options, dispatch ])

  const PRICE_OPTIONS = useMemo(() => {
    return options ? options.reduce((acc, elem) => {
      const label = `${elem.currency} ${elem.amount}`;
      acc[elem.value] = label;
      return acc;
    }, {}) : {};
  }, [ options ]);
  

  return (
    <EntityTable
      resource="subscriptions"
      columns={getColumns(PRICE_OPTIONS, countries)}
      data={data ?? []}
      page={page}
      pageSize={pageSize}
      defaultPageRowSize={200}
      defaultSorted={defaultSorted}
      totalCount={totalCount}
      isLoading={isLoading}
      onQueryMapping={onQueryMapping}
      onFetchData={handleFetchData}
      datePropertyKey={'createdAt'}
      isDownloading={isDownloading}
      isAdmin={isAdmin}
    />
  );
}

List.displayName = 'Subscriptions.List';

export default List;
