import React from 'react';
import cn from 'classnames';
import { ThemesEnum } from 'enums';
import { mapBoolPropsToClassnames as mapBPTC } from 'utils';
import { SCardVariants } from './SCard.emum';
import styles from './styles.module.scss';
export const SCard = ({ className, outline, theme = ThemesEnum.Light, variant = SCardVariants.Transparent, ...rest }) => {
    return (React.createElement("div", { className: cn(styles.SCard, [className], styles[theme], styles[variant], mapBPTC(styles, { outline })), ...rest }));
};
SCard.displayName = 'SCard';
