import React from 'react';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import TableRow from 'components/TableRow';
import { getIsAdmin } from 'services/session/selectors';
import actions from 'services/configuration/actions';
import { DateTime } from 'luxon';
import * as Yup from 'yup';
export const TournamentConfigsForm = ({ config }) => {
    const isAdmin = useSelector(getIsAdmin);
    const dispatch = useDispatch();
    const data = config?.tournamentAutoCreation;
    const validationSchema = Yup.object().shape({
        enabled: Yup.boolean(),
        daysToCopy: Yup.number().min(1).required(),
        cronDate: Yup.string()
            .required()
            .test('is-valid-time', 'Invalid time', (value) => DateTime.fromFormat(value ?? '', 'HH:mm').isValid),
    });
    const extractTimeFromDate = (timeString) => {
        let date = new Date();
        const fragments = timeString.split(':');
        const hours = parseInt(fragments[0], 10);
        const minutes = parseInt(fragments[1], 10);
        date.setHours(hours, minutes, 0);
        return date;
    };
    const handleSubmit = (values) => dispatch(actions.updateConfiguration({
        tournamentAutoCreation: {
            enabled: values.enabled,
            daysToCopy: values.daysToCopy,
            cronDate: values.cronDate,
        },
    }));
    return data ? (React.createElement("div", null,
        React.createElement(Formik, { initialValues: data, validationSchema: validationSchema, onSubmit: handleSubmit }, ({ errors, values, handleBlur, handleChange, handleSubmit, setFieldValue }) => (React.createElement("form", { onSubmit: handleSubmit },
            React.createElement(TableRow.Checkbox, { label: "Enabled", labelClass: "col-sm-3", valueClass: "col-sm-9", isLabelFormatted: false, name: "enabled", value: values.enabled, onBlur: handleBlur, onChange: handleChange, isDisabled: false }),
            React.createElement(TableRow.Text, { label: "Copy last N days", labelClass: "col-sm-3", valueClass: "col-sm-9", isLabelFormatted: false, inputClass: "s-input", error: errors.daysToCopy, name: "daysToCopy", type: "number", min: "1", value: values.daysToCopy, required: false, onBlur: handleBlur, onChange: handleChange, placeholder: 1, isDisabled: !values.enabled }),
            React.createElement("dl", { className: "row" },
                React.createElement("dt", { className: "col-sm-12", style: { fontStyle: 'italic' } }, "Enter the number of past days to replicate tournaments. For example, if set to 2, the system will copy tournaments from the last 2 days to the next 2 days.")),
            React.createElement(TableRow.DatePicker, { label: "Check upcoming tournament time (CET/CEST)", name: "cronDate", value: extractTimeFromDate(values.cronDate), showTimeSelectOnly: true, dateFormat: "HH:mm", onBlur: handleBlur, isError: !!errors.daysToCopy, errorMsg: errors.daysToCopy ?? '', labelClass: "col-sm-3", valueClass: "col-sm-9", disabled: !values.enabled, inline: false, onChange: (date) => {
                    setFieldValue('cronDate', date.toLocaleTimeString('en-GB', {
                        hour: '2-digit',
                        minute: '2-digit',
                    }));
                } }),
            React.createElement("dl", { className: "row" },
                React.createElement("dt", { className: "col-sm-12", style: { fontStyle: 'italic' } }, "Set the time for the script to start running. The script will check the database between 1 AM - 12 PM tomorrow to ensure there is at least one tournament scheduled.")),
            React.createElement("dl", { className: "row" },
                React.createElement("dt", { className: "col-sm-12" },
                    "Date Now (CET/CEST): ",
                    DateTime.now().setZone('Europe/Berlin').toFormat('HH:mm'))),
            React.createElement("div", { className: "action-footer" }, isAdmin && (React.createElement("button", { className: "btn btn-success btn-md", type: "submit", disabled: !!Object.keys(errors).length }, "Save config")))))))) : (React.createElement("div", null,
        React.createElement("i", { className: "fa fa-spinner fa-spin fa-fw" })));
};
