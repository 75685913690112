import { createAction } from 'redux-actions';

export const createPlatform = createAction('CREATE_PLATFORM_REQUEST');
export const createPlatformFailure = createAction('CREATE_PLATFORM_FAILURE');
export const createPlatformSuccess = createAction('CREATE_PLATFORM_SUCCESS');

export default {
  createPlatform,
  createPlatformFailure,
  createPlatformSuccess,
};
