import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { getIsAdmin } from 'services/session/selectors';

import actions from './actions';

class List extends Component {
  componentDidMount() {
    this.props.dispatch(actions.getManufacturers());
  }
  handleRefreshButtonClick = (_e) => this.props.dispatch(actions.getManufacturers());

  get manufacturerList() {
    const { manufacturerList, isLoading } = this.props;

    if (isLoading) {
      return null;
    }

    return Object.keys(manufacturerList).map((manufacturerId, idx) => {
      const manufacturer = manufacturerList[manufacturerId];
      // const boards = manufacturer.boards.join(', ');
      return (
        <tr key={Object.getOwnPropertyNames(manufacturerList)[idx]}>
          <td>
            <Link to={`/manufacturers/${manufacturer._id}`}>{manufacturer.name}</Link>
          </td>
          <td>{manufacturer.phoneNumber}</td>
          <td>{manufacturer.email}</td>
          <td>{manufacturer.address}</td>
          <td>{manufacturer.numberOfBoards}</td>
        </tr>
      );
    });
  }

  render() {
    const { isLoading, isAdmin } = this.props;

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" lg={{ size: 10, offset: 1 }}>
            <Card color="white">
              <CardHeader>
                <div className="card-header__left">
                  <i className="fa fa-align-justify" /> Hybrid SBC - Manufacturers
                  {isLoading && <i className="fa fa-spinner fa-spin fa-fw" />}
                </div>
                <div className="card-header__right">
                  {isAdmin && <Link to="/manufacturers/new">
                    <button className="btn btn-primary btn-md" type="button">
                      Create Manufacturer
                    </button>
                  </Link>}
                  <button className="btn  btn-md ms-1" disabled={isLoading} onClick={this.handleRefreshButtonClick}>
                    <i className="cui-reload" />
                  </button>
                </div>
              </CardHeader>

              <CardBody>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Telephone</th>
                      <th>E-Mail</th>
                      <th>Address</th>
                      <th>Assigned Boards</th>
                    </tr>
                  </thead>
                  <tbody>{this.manufacturerList}</tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

List.displayName = 'Manufacturers.List';

const mapStateToProps = (state) => ({
  manufacturerList: state.data.manufacturers,
  isLoading: state.scenes.manufacturers.list.isLoading,
  isAdmin: getIsAdmin(state),
});

export default connect(mapStateToProps)(List);
