import { all } from 'redux-saga/effects';

import { sagas as createBoardSaga } from 'scenes/Boards/components/Create';
import { sagas as editSaga } from 'scenes/Boards/components/Edit';
import { sagas as listSaga } from 'scenes/Boards/components/List';
import { sagas as showSaga } from 'scenes/Boards/components/Show';

export default function* boardsSaga() {
  yield all([createBoardSaga(), editSaga(), listSaga(), showSaga()]);
}
