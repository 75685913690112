
import { take, call, select, delay } from 'redux-saga/effects';

import { Socket, actions as wsActions } from 'services/ws';

import { OPENED } from 'constants/WsStatuses'

import {
  subscribeToBoardUpdates
} from 'scenes/Boards/components/Show/actions.js';

export default function* watchSubscribeToBoardUpdates() {
  while (true) {
    const { payload: { channel, sbcId, userId } } = yield take(subscribeToBoardUpdates);
    const payload = wsActions.Management.boardUpdatesSubscribe({ channel, subjects: [sbcId, userId] });

    const wsConnection = yield select((state) => state.ws.status === OPENED);

    if (wsConnection) {
      yield call([Socket, Socket.send], JSON.stringify(payload));
    }
    else {
      yield take(wsActions.initWsConnectionSuccess);
      // Don't know why, but it needs a delay for the connection to properly init 
      yield delay(200);
      yield call([Socket, Socket.send], JSON.stringify(payload));
    }
  }
}
