import { all } from 'redux-saga/effects';

import watchGetBoard from './watchers/GetBoard';
import watchGetSnapshot from './watchers/GetSnapshot';
import watchDeleteBoard from './watchers/DeleteBoard';
import watchGetBoardSessions from './watchers/GetBoardSessions';
import watchGetBoardStatistics from './watchers/GetBoardStatistics';
import watchGetBoardCurrentState from './watchers/GetBoardCurrentState';
import watchGetBoardLimitedThrows from './watchers/GetBoardLimitedThrows';
import watchGetInvoiceUrl from './watchers/GetInvoiceUrl';
import watchBoardCalibrate from './watchers/BoardCalibrate';
import watchSubscribeToBoardUpdates from './watchers/SubscribeToBoardUpdates';
import watchUnsubscribeToBoardUpdates from './watchers/UnsubscribeToBoardUpdates';
import watchUpgradeLimitedBoardService from './watchers/UpgradeLimitedBoardService';
import watchBoardUpdate from './watchers/UpdateBoard'
import watchChangeTestMode from './watchers/ChangeTestMode'
import watchChangeWifiConfiguration from './watchers/ChangeWifiConfiguration'
import streamSaga from './stream';

export default function* showSaga() {
  yield all([
    watchGetBoard(),
    watchGetSnapshot(),
    streamSaga(),
    watchDeleteBoard(),
    watchGetBoardSessions(),
    watchGetBoardStatistics(),
    watchGetBoardCurrentState(),
    watchGetBoardLimitedThrows(),
    watchBoardCalibrate(),
    watchSubscribeToBoardUpdates(),
    watchUnsubscribeToBoardUpdates(),
    watchBoardUpdate(),
    watchUpgradeLimitedBoardService(),
    watchChangeTestMode(),
    watchChangeWifiConfiguration(),
    watchGetInvoiceUrl(),
  ]);
}
