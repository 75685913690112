import { isUndefined } from 'lodash-es';
import { DateTime } from 'luxon';

export function getNextHourDate(hoursToAdd) {
  let dt = DateTime.local();

  if (dt.minute > 0 || dt.second > 0 || dt.millisecond > 0) {
    dt = dt.plus({ hours: 1 });
  }
  dt = dt.set({ minute: 0, second: 0, millisecond: 0 });

  if (!isUndefined(hoursToAdd)) {
    dt = dt.plus({ hours: hoursToAdd });
  }

  return dt.toISO();
}