import { all } from 'redux-saga/effects'

import { sagas as listSaga } from './components/List'
import { sagas as showSaga } from './components/Show'

export default function* tournamentsSaga() {
  yield all([
    listSaga(),
    showSaga(),
  ])
}
