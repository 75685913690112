import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import cn from 'classnames';

import { RefreshTimer } from 'components/RefreshTimer';
import { Store } from './components/Store';
import { StoreTable } from './components/StoreTable';
import { getStoreSnapshot } from './components/actions';

import styles from './styles.module.scss';

class StoreSnapshot extends Component {
  componentDidMount() {
    this.fetchStoreSnapshot();
  }

  fetchStoreSnapshot = () => this.props.dispatch(getStoreSnapshot());

  render() {
    const { store, isLoading } = this.props;
    return (
      <div>
        <Row>
          <Col xs={12}>
            <div className={cn('text-muted', styles.cardHeader)}>
              <RefreshTimer action={this.fetchStoreSnapshot} disabled={isLoading}/>
            </div>
          </Col>
        </Row>

        <Store {...store} isLoading={isLoading} />
        <Row>
          <Col>
            <StoreTable />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.scenes.storeSnapshot.isLoading,
  store: state.data.storeSnapshot.store,
});

export default connect(mapStateToProps)(StoreSnapshot);
