import React, { Component, Fragment } from 'react';
import { Collapse, Col, Row, Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { GameTypeEnum, GameTypeLabelEnum } from 'enums';
import { ToggleFilter } from 'scenes/StoreSnapshot/components/StoreTable/components/ToggleFilter';

import styles from './styles.module.scss';

class Room extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  open() {
    this.setState({ isOpen: true });
  }

  close() {
    this.setState({ isOpen: false });
  }

  isOpened() {
    return this.state.isOpen;
  }

  render() {
    const {
      id: roomId,
      game: { id: gameId, isExist, type, startTime, isThrowForBull, isRandom },
      activeConnections,
    } = this.props;

    return (
      <Fragment>
        <Row>
          <div className={styles.wrapper}>
            <Col xs={3} className={styles.id}>
              {roomId}
            </Col>
            <Col xs={5} className={styles.type}>
              {isExist ? (
                <span>
                  {gameId} | <b>{type === GameTypeEnum.Cricket && isRandom ? GameTypeLabelEnum.RandomCricket : type}</b> {isThrowForBull && <Fragment> | <Badge color={'warning'}>ThrowForBull</Badge></Fragment>}
                </span>
              ) : (
                  'No Game'
                )}
            </Col>
            <Col xs={4} className={styles.startTime}>
              {startTime ? startTime : null}
            </Col>

            {activeConnections.length > 0 && (
              <i
                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                className={cn(
                  styles.toggleIcon,
                  `fa fa-angle-${this.state.isOpen ? 'up' : 'down'}`
                )}
              />
            )}
          </div>
        </Row>
        <div
          className={cn([
            styles.border,
            { [styles.borderActive]: this.state.isOpen },
          ])}
        />
        <Collapse
          isOpen={this.state.isOpen}
          onEntering={this.props.onChange}
          onExiting={this.props.onChange}
        >
          {activeConnections.length > 0 && (
            <div className={styles.collapse}>
              <div className={styles.filterWrapper}>
                <ToggleFilter
                  readonly
                  label="Active Connections"
                  count={activeConnections.length}
                />
              </div>

              {activeConnections.map((connection, index) => (
                <Row
                  key={`${connection.type}-${index}`}
                  className={styles.wsConnectionRow}
                >
                  <Col style={{ maxWidth: '70px' }}>
                    <b>{connection.type}</b>
                  </Col>
                  <Col xs={8}>
                    <ul className={styles.wsConnectionList}>
                      {connection.wsConnections.length > 0 ?connection.wsConnections.map(wsConnection => (
                        <li key={wsConnection}>{wsConnection}</li>
                      )) : <i>No WS connection</i>}
                    </ul>
                  </Col>
                  <Col className={styles.textEllipsis}>
                    {connection.entityUrl ? (
                      <Link to={connection.entityUrl}>
                        {connection.entityLabel}
                      </Link>
                    ) : (
                        connection.entityLabel
                      )}
                  </Col>
                </Row>
              ))}
            </div>
          )}
        </Collapse>
      </Fragment>
    );
  }
}

Room.propTypes = {
  id: PropTypes.string.isRequired,
  game: PropTypes.object.isRequired,
  activeConnections: PropTypes.array.isRequired,
};

export default Room;
