import { patch, get, post, del } from 'services/rest';

const url = '/notifications';
const notificationEndPoint = 'Notification';
const visibilityEndPoint = 'Visibilities';

const Notifications = {
  index: queryParams => get(url, queryParams),
  update: (notificationId, requestBody) => patch(`${url}/update${notificationEndPoint}/${notificationId}`, requestBody),
  updateVisibility: requestBody => patch(`${url}/update${visibilityEndPoint}`, requestBody),
  create: (requestBody) => post(url, requestBody),
  delete: notificationId => del(`${url}/${notificationId}`),
};

export default Notifications;
