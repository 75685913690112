import { call, put, takeLatest } from 'redux-saga/effects';

import { Tournaments } from 'services/api';

import { browserHistory } from 'services/history';
import { callApi } from 'services/rest';
import { notifyError, notifySuccess } from 'services/notification/actions';

import {
  editTournament,
  editTournamentFailure,
  editTournamentSuccess,
} from 'scenes/Tournaments/components/Edit/actions';

function* watchCreateTournament({ payload: { data, tournamentId } }) {
  try {
    yield call(callApi, Tournaments.update, tournamentId, data);
    yield put(editTournamentSuccess());
    yield put(notifySuccess(`Tournament edit was successful.`))
    browserHistory.push('/tournaments');
  } catch (e) {
    if (!e.isFetch) {
      throw e;
    }
    yield put(editTournamentFailure(e.data.error));
    yield put(notifyError(`Failed to edit tournament`))
  }
}

export default () => takeLatest(editTournament, watchCreateTournament);
