import { get, patch, post, del, getBlob } from 'services/rest';

const url = '/firmwares';

const Firmwares = {
  index: queryParams => get(url, queryParams),
  create: firmware => post(url, firmware),
  delete: firmwareId => del(`${url}/${firmwareId}`),
  download: firmwareId => getBlob(`${url}/${firmwareId}/file`),
  show: firmwareId => get(`${url}/${firmwareId}`),
  update: (firmwareId, payload) => patch(`${url}/${firmwareId}`, payload),
};

export default Firmwares;
