import { combineReducers } from 'redux';

import { reducer as list } from './components/List';
import { reducer as create } from './components/Create';
import { reducer as edit } from './components/Edit';

const platforms = combineReducers({
  list,
  create,
  edit,
});

export default platforms;
