import { call, put, takeLatest } from 'redux-saga/effects';

import { Users } from 'services/api';

import { callApi } from 'services/rest';

import { getUsersEmails, getUsersEmailsFailure, getUsersEmailsSuccess } from "./actions"

function* watchGetUsersEmails({ payload }) {
  try {
    const { data } = yield call(callApi, Users.emails, payload)
    yield put(getUsersEmailsSuccess(data))
  } catch (e) {
    console.log(e);
    yield put(getUsersEmailsFailure());
  }
}

export default () => takeLatest(getUsersEmails, watchGetUsersEmails);