import React, { useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { MainData } from './components/MainData'
import { NextPayment } from './components/NextPayment'

import actions from './actions'
import selectors from './selectors'

const Show = ({ match: { params: { subscriptionId } } }) => {
  const dispatch = useDispatch();
  const { data, isLoading } = useSelector(selectors.getSubscriptionShowData);

  useEffect(() => {
    dispatch(actions.getSubscription(subscriptionId));

    return () => {
      dispatch(actions.resetSubscription());
    }
  }, [dispatch, subscriptionId]);

  return (
    <div className="animated fadeIn">
      <Row>
        <Col sm="12" md="6">
          <MainData data={data} isLoading={isLoading} />
        </Col>
        <Col sm="12" md="6">
          <NextPayment data={data?.nextPayment} isLoading={isLoading} />
        </Col>
      </Row>
    </div>
  )
}

Show.displayName = 'Show';

Show.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      subscriptionId: PropTypes.string,
    })
  })
}

export default Show