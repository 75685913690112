import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

import * as STATUSES from 'constants/WsStatuses';

import avatar6 from 'images/avatars/6.jpg';

class HeaderDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
    };
  }

  handleLogoutClick = e => {
    e.preventDefault();
    this.props.onLogoutClick();
  };

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  render() {
    const { dropdownOpen } = this.state;
    const { connectionStatus } = this.props;

    return (
      <Dropdown nav isOpen={dropdownOpen} toggle={this.toggle}>
        <DropdownToggle nav className={`ddt__ws-status--${connectionStatus}`}>
          <img
            alt="admin@bootstrapmaster.com"
            className="img-avatar"
            src={avatar6}
          />
        </DropdownToggle>
        <DropdownMenu end>
          <DropdownItem onClick={this.handleLogoutClick}>
            <i className="fa fa-lock" /> Logout
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

HeaderDropdown.propTypes = {
  connectionStatus: PropTypes.oneOf(
    Object.keys(STATUSES).map(key => STATUSES[key])
  ).isRequired,
  onLogoutClick: PropTypes.func.isRequired,
};

export default HeaderDropdown;
