import { createAction } from 'redux-actions';

export const getSystemUsage = createAction('SYSTEM_USAGE_GET_REQUEST');
export const getSystemUsageSuccess = createAction('SYSTEM_USAGE_GET_SUCCESS');
export const getSystemUsageFailure = createAction('SYSTEM_USAGE_GET_FAILURE');

export const downloadSystemUsage = createAction('SYSTEM_USAGE_DOWNLOAD_REQUEST');
export const downloadSystemUsageSuccess = createAction('SYSTEM_USAGE_DOWNLOAD_SUCCESS');
export const downloadSystemUsageFailure = createAction('SYSTEM_USAGE_DOWNLOAD_FAILURE');

export default {
  getSystemUsage,
  getSystemUsageSuccess,
  getSystemUsageFailure,
  downloadSystemUsage,
  downloadSystemUsageSuccess,
  downloadSystemUsageFailure,
};
