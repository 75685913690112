import { call, put, takeLatest } from 'redux-saga/effects';

import { Subscriptions } from 'services/api';
import { callApi } from 'services/rest';

import {
  getPriceOptions,
  getPriceOptionsFailure,
  getPriceOptionsSuccess,
} from 'scenes/Subscriptions/components/List/actions';

function* watchGetPriceOptions() {
  try {
    const { data } = yield call(callApi, Subscriptions.prices);
    yield put(getPriceOptionsSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(getPriceOptionsFailure());
  }
}

// It must be wrapped in a function, so execution will happen only on function call
export default () => takeLatest(getPriceOptions, watchGetPriceOptions);
