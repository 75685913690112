import { call, put, takeLatest } from 'redux-saga/effects';

import { Firmwares } from 'services/api';
import { callApi } from 'services/rest';

import {
  downloadFirmware,
  downloadFirmwareFailure,
  downloadFirmwareSuccess,
} from 'scenes/Firmwares/actions';

function* watchDownloadFirmware({ payload }) {
  try {
    const file = yield call(callApi, Firmwares.download, `${payload}`);
    yield put(downloadFirmwareSuccess(file));
  } catch (e) {
    console.log(e);
    yield put(downloadFirmwareFailure());
  }
}

export default () => takeLatest(downloadFirmware, watchDownloadFirmware);
