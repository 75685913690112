import { createAction } from 'redux-actions';

import * as TYPES from 'constants/NotificationTypes';

// Make sure ids are always unique
let counter = 0;
const _getId = () => counter++;

const createNotificationPayload = (type: string) => (message: string, title?: string) => ({
  id: _getId(),
  type,
  title,
  message,
});

// Use the function with createAction, ensuring it conforms to expected usage
export const notifySuccess = createAction('SUCCESS_NOTIFY', createNotificationPayload(TYPES.SUCCESS));
export const notifyError = createAction('ERROR_NOTIFY', createNotificationPayload(TYPES.ERROR));
export const notifyWarning = createAction('WARNING_NOTIFY', createNotificationPayload(TYPES.WARNING));

export const removeNotification = createAction('NOTIFICATION_REMOVE');

export default {
  removeNotification,
  notifySuccess,
  notifyWarning,
  notifyError,
};
