import React, { useMemo } from 'react';
import { Card, CardBody, CardHeader, Spinner } from "reactstrap";
import { useSelector } from 'react-redux';
import { getIsAdmin } from 'services/session/selectors';
import { LiveBoardViewEdit } from './LiveBoardViewEdit.component';
import { LiveBoardViewShow } from './LiveBoardViewShow.component';
export function LiveBoardViewConfigurationForm({ config }) {
    const isAdmin = useSelector(getIsAdmin);
    const Component = useMemo(() => {
        return isAdmin ? LiveBoardViewEdit : LiveBoardViewShow;
    }, [isAdmin]);
    return (React.createElement(Card, { color: "white" },
        React.createElement(CardHeader, null,
            React.createElement("div", { className: "card-header__left" },
                React.createElement("i", { className: "fa fa-align-justify" }),
                " Live board view configuration")),
        React.createElement(CardBody, null, !config ? (React.createElement("div", { className: 'w-100 h-100 d-flex align-items-center justify-content-center' },
            React.createElement(Spinner, null))) : (React.createElement(Component, { config: config })))));
}
