import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { isUndefined } from 'lodash';
import { ThemesEnum } from 'enums';
import { SButton, SizesEnum, SButtonVariantOptionsEnum, } from 'ScoliaComponents';
import styles from './styles.module.scss';
import { CloseIcon } from 'components/Icons';
export const InviteParticipantElement = ({ meta, isInFocus, isDuplicated, isMatchNotFound, isWithPlaceholder, isAlreadyParticipant, value, onBlur, onDelete, onFocus, onEnter, id, ...rest }) => {
    const [isEdit, setEdit] = useState(false);
    const matchExists = meta && meta?.nickname && meta?.scoliaId;
    const handleEditClick = () => {
        setEdit(true);
        setTimeout(() => document.getElementById(id)?.focus(), 0);
    };
    const handleBlur = () => {
        setEdit(false);
        onBlur();
    };
    const handleFocus = () => {
        setEdit(true);
        onFocus();
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.currentTarget.blur();
            onEnter();
        }
    };
    useEffect(() => {
        if (!isEdit && value) {
            onBlur();
        }
    }, [isEdit, value]);
    if (isMatchNotFound && !isEdit) {
        return (React.createElement("div", { className: styles.matchContainer },
            React.createElement("span", { className: styles.noMatchValue }, value),
            React.createElement("span", { className: styles.noMatchLabel }, "No match"),
            React.createElement(SButton, { name: "invitation-edit", variant: SButtonVariantOptionsEnum.TertiaryGray, size: SizesEnum.Small, theme: ThemesEnum.Astro, className: styles.actionButton, onClick: handleEditClick }, "Edit"),
            React.createElement(SButton, { name: "invitation-delete", variant: SButtonVariantOptionsEnum.TertiaryGray, size: SizesEnum.Small, theme: ThemesEnum.Astro, className: styles.actionButton, onClick: onDelete },
                React.createElement(CloseIcon, null))));
    }
    if (matchExists && !isEdit) {
        return (React.createElement("div", { className: styles.matchContainer },
            React.createElement("div", { className: cn(styles.container, {
                    [styles.wrapped]: !!meta.email,
                    [styles.error]: isDuplicated || isAlreadyParticipant,
                }) },
                React.createElement("span", null,
                    React.createElement("b", null, meta.nickname),
                    React.createElement("span", { className: styles.separate }, ` ∙ `),
                    React.createElement("span", null, `#${meta.scoliaId}`)),
                !isUndefined(meta.email) && (React.createElement("span", { className: styles.emailContainer },
                    React.createElement("span", { className: styles.separate }, ` ∙ `),
                    React.createElement("span", null, meta.email)))),
            isDuplicated && (React.createElement("span", { className: styles.errorSign }, "Duplicated")),
            isDuplicated && isAlreadyParticipant && (React.createElement("span", { className: styles.errorSign }, `∙`)),
            isAlreadyParticipant && (React.createElement("span", { className: styles.errorSign }, "Already invited")),
            React.createElement(SButton, { name: "invitation-edit", variant: SButtonVariantOptionsEnum.TertiaryGray, size: SizesEnum.Small, theme: ThemesEnum.Astro, className: styles.actionButton, onClick: handleEditClick }, "Edit"),
            React.createElement(SButton, { name: "invitation-delete", variant: SButtonVariantOptionsEnum.TertiaryGray, size: SizesEnum.Small, theme: ThemesEnum.Astro, className: styles.actionButton, onClick: onDelete },
                React.createElement(CloseIcon, null))));
    }
    return (React.createElement("input", { id: id, autoComplete: "off", className: cn(styles.participantElementInput, {
            [styles.isInFocus]: isInFocus,
        }), placeholder: 'Start typing', value: value, onBlur: handleBlur, onFocus: handleFocus, onKeyDown: handleKeyDown, ...rest }));
};
InviteParticipantElement.displayName = 'InviteParticipantElement';
