import { ScoreCorrectionHistoryResponse, ScoreCorrectionStatisticsResponse } from 'dtos';
import { PaginatedQuery, PaginationParameters } from 'interfaces/Query.interface';
import { get } from 'services/rest';

const url = '/score-correction';

export const ScoreCorrections = {
  statistics: (queryParams: PaginationParameters<ScoreCorrectionStatisticsResponse>) =>
    get(`${url}/statistics`, queryParams) as Promise<
      PaginatedQuery<ScoreCorrectionStatisticsResponse>
    >,
  history: (userId: string, queryParams: PaginationParameters<ScoreCorrectionHistoryResponse>) =>
    get(`${url}/history/${userId}`, queryParams) as Promise<
      PaginatedQuery<ScoreCorrectionHistoryResponse>
    >,
};
