import { handleActions } from 'redux-actions';

import { default as systemUsageActions } from './actions';

const INITIAL_STATE = {
  isLoading: true,
  isError: false,
};

const systemUsageReducer = handleActions(
  {
    [systemUsageActions.getSystemUsage]: (state, _payload) => ({
      ...state,
      isLoading: true,
      isError: false,
    }),

    [systemUsageActions.getSystemUsageSuccess]: (state, _payload) => ({
      ...state,
      isLoading: false,
      isError: false,
    }),

    [systemUsageActions.getSystemUsageFailure]: () => ({
      isLoading: false,
      isError: true,
    }),

    [systemUsageActions.downloadSystemUsage]: (state, _payload) => ({
      ...state,
      isDownloading: true,
    }),

    [systemUsageActions.downloadSystemUsageSuccess]: (state, _payload) => ({
      ...state,
      isDownloading: false,
    }),

    [systemUsageActions.downloadSystemUsageFailure]: (state, _payload) => ({
      ...state,
      isDownloading: false,
    }),
  },
  INITIAL_STATE
);

export default systemUsageReducer;
