import cn from 'classnames';
import React from 'react';
import { uniqueId } from 'lodash';
import ThemedAbstractModal from './ThemedAbstractModal.component';
import { Checkbox } from 'components/Checkbox';
import { SButton, SButtonVariantOptionsEnum, SizesEnum } from 'ScoliaComponents';
import { CloseIcon } from 'components/Icons';
import { isNullOrUndefined } from 'utils';
import styles from './styles.module.scss';
const ThemedModal = ({ title, storageKey, skipConfirmation, onSkipConfirmation, onAccept, onDismiss, customAccept, customDecline, customDeclineFn, acceptType = 'success', cancelType, isDeclineHidden = false, //if it's true, onDismiss still required but the dismiss btn won't be displayed
isAcceptHidden = false, //if it's true, accept btn won't be displayed
isAcceptPending, isAcceptDisabled, children, modalContentClass, ...rest }) => {
    const isPending = !isNullOrUndefined(isAcceptPending) && isAcceptPending;
    const id = uniqueId('modalButton_');
    return (React.createElement(ThemedAbstractModal, { onDismiss: onDismiss, ...rest },
        React.createElement(React.Fragment, null,
            React.createElement("div", { className: cn(styles.modalHeader, { [styles.hasTitle]: !!title }) },
                React.createElement("div", { className: styles.closeBtn },
                    React.createElement("button", { type: "button", className: styles.closeBtnBtn, onClick: onDismiss },
                        React.createElement(CloseIcon, null))),
                React.createElement("div", { className: styles.title }, title)),
            React.createElement("div", { className: cn(styles.modalContent, modalContentClass) }, children),
            onSkipConfirmation && storageKey && (React.createElement("div", { className: styles.modalStorage },
                React.createElement("label", null, `Don't show this message.  `),
                React.createElement(Checkbox, { checked: !!skipConfirmation, name: "skipConfirmation", onChange: onSkipConfirmation, className: "" }))),
            (!isDeclineHidden || !isAcceptHidden) && (React.createElement("div", { className: styles.modalActions },
                !isDeclineHidden && (React.createElement(SButton, { name: "modal-cancel", type: "button", className: cn(styles.cancelButton, cancelType ? styles[cancelType] : undefined), onClick: customDeclineFn ?? onDismiss, size: SizesEnum.Small, variant: SButtonVariantOptionsEnum.SecondaryGray }, customDecline ?? 'Cancel')),
                !isAcceptHidden && (React.createElement(SButton, { name: id, type: "button", onClick: onAccept, disabled: isPending || isAcceptDisabled, className: cn(styles.acceptButton, {
                        [styles[acceptType]]: !!acceptType,
                        [styles.disabled]: isPending || isAcceptDisabled,
                    }), size: SizesEnum.Small, variant: SButtonVariantOptionsEnum.Primary },
                    isPending && React.createElement("i", { style: { marginRight: '3px' }, className: "fa fa-fw fa-spinner fa-spin" }),
                    customAccept ?? 'Accept')))))));
};
export default ThemedModal;
