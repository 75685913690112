export const BoardState = {
  Offline: { type: 'Offline', label: 'Offline' },
  Online: { type: 'Online', label: 'Online' },
  InGame: { type: 'InGame', label: 'In game' },
  Calibrating: { type: 'Calibrating', label: 'Calibrating' },
  Initializing: { type: 'Initializing', label: 'Initializing' },
  Updating: { type: 'Updating', label: 'Updating' },
  Error: { type: 'Error', label: 'Error' },
  Unknown: { type: 'Unknown', label: 'Unknown' },
};
