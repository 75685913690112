const formatDate = (date, addTimeData = false) => {
  const d = new Date(date);
  let formattedDateString =
    d.getFullYear().toString() +
    '-' +
    ((d.getMonth() + 1).toString().length === 2
      ? (d.getMonth() + 1).toString()
      : '0' + (d.getMonth() + 1).toString()) +
    '-' +
    (d.getDate().toString().length === 2
      ? d.getDate().toString()
      : '0' + d.getDate().toString());

  if (addTimeData) {
    formattedDateString += ` (${d.getHours()}:${
      d.getMinutes().toString().length === 2
        ? d.getMinutes()
        : '0' + d.getMinutes()
    })`;
  }

  return date ? formattedDateString : '-';
};

export default formatDate;
