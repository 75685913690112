export const EMAIL_ADDRESS = new RegExp(
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

// This was the original PhoneNumber validation in the Frontned
//export const PHONENUMBER = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/);
// Regex used on backend:
// eslint-disable-next-line no-useless-escape
export const PHONENUMBER = new RegExp(/^(\+|\d)(\d|\/|\-)+(\d)+$/);

export const VERSION_NUMBER = new RegExp(/^([0-9]+)\.([0-9]+)\.([0-9]+)$/);