import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader } from 'reactstrap';
import DatePicker from 'react-datepicker';

const LimitedThrows = ({
  isLoading,
  filters: { selectedPeriod },
  onDateChange,
  limitedThrows: { limited, unlimited } = {},
}) => {
  return (
    <Card color="white">
      <CardHeader>
        <div className="card-header__left">
          <i className="fa fa-align-justify" />
          Limited Throws
          {isLoading && <i className="fa fa-spinner fa-spin fa-fw" />}
        </div>
        <DatePicker
          selected={selectedPeriod}
          onChange={onDateChange}
          dateFormat="MMMM yyyy"
          showMonthYearPicker
          placeholderText="éééé. hh."
          className="form-control"
          disabled={isLoading}
        />
      </CardHeader>
      <CardBody>
        <div className="key-value-data-container">
          <div className="row">
            <div className="col-sm-4">Throw Type</div>
            <div className="col-sm-4">Limited</div>
            <div className="col-sm-4">Unlimited</div>
          </div>
          <div className="row">
            <div className="col-sm-4">Ingame Throws</div>
            <div className="col-sm-4">
              {limited?.detectedInGameOnScoliaWithoutSAConnection ?? 0}
            </div>
            <div className="col-sm-4">
              {unlimited?.detectedInGameOnScoliaWithoutSAConnection ?? 0}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4">Throws added by score correction</div>
            <div className="col-sm-4">{limited?.addedByScoreCorrection ?? 0}</div>
            <div className="col-sm-4">{unlimited?.addedByScoreCorrection ?? 0}</div>
          </div>
          <div className="row">
            <div className="col-sm-4">Throws Forwarded to Service Account</div>
            <div className="col-sm-4">{limited?.throwsForwardedToSAWithoutScoliaGame ?? 0}</div>
            <div className="col-sm-4">{unlimited?.throwsForwardedToSAWithoutScoliaGame ?? 0}</div>
          </div>
          <div className="row">
            <div className="col-sm-4">Ingame Throws with Service Account</div>
            <div className="col-sm-4">{limited?.detectedInGameOnScoliaWithSAConnection ?? 0}</div>
            <div className="col-sm-4">{unlimited?.detectedInGameOnScoliaWithSAConnection ?? 0}</div>
          </div>          
          <div className="row">
            <div className="col-sm-4">Practice game throws</div>
            <div className="col-sm-4">{limited?.practiceGameThrows ?? 0}</div>
            <div className="col-sm-4">{unlimited?.practiceGameThrows ?? 0}</div>
          </div>
          <div className="row">
            <div className="col-sm-4">Practice game throws with Service Account</div>
            <div className="col-sm-4">{limited?.practiceGameThrowsWithSAConnection ?? 0}</div>
            <div className="col-sm-4">{unlimited?.practiceGameThrowsWithSAConnection ?? 0}</div>
          </div>
          <div className="row">
            <div className="col-sm-4">Throws under limit</div>
            <div className="col-sm-4">{limited?.numberOfThrowsUnderLimit ?? 0}</div>
            <div className="col-sm-4">{unlimited?.numberOfThrowsUnderLimit ?? 0}</div>
          </div>
          <div className="row">
            <div className="col-sm-4">Outcast Throws</div>
            <div className="col-sm-4">{limited?.outcastOnScolia ?? 0}</div>
            <div className="col-sm-4">{unlimited?.outcastOnScolia ?? 0}</div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

LimitedThrows.propTypes = {
  filters: PropTypes.shape({
    selectedPeriod: PropTypes.instanceOf(Date),
  }).isRequired,
  onDateChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  limitedThrows: PropTypes.shape({
    limited: PropTypes.object,
    unlimited: PropTypes.object,
  }).isRequired,
};

export default LimitedThrows;
