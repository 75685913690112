import React, { useState } from 'react';
import { useMedia } from 'react-use';
import { isEmpty, isNull, isUndefined } from 'lodash';
import { LG_M } from 'constants/Breakpoints';
import { isTournamentDeletable, isTournamentUpcoming } from 'enums';
import { WizardPageTitles, WizardPages } from './constants';
import { WizardHeader, WizardBottom, SideNavigation, Pager } from './components';
import { Appearance, Basics, Description, GameConfiguration, PrizePool, Participants } from './components/Pages';
import { deepClone } from 'utils';
import styles from './styles.module.scss';
const components = {
    [WizardPages.Basics]: Basics,
    [WizardPages.Appearance]: Appearance,
    [WizardPages.Description]: Description,
    [WizardPages.GameConfiguration]: GameConfiguration,
    [WizardPages.PrizePool]: PrizePool,
    [WizardPages.Participants]: Participants,
};
export const TournamentWizardForm = ({ onChange, onSubmit, onDelete, onBlur, values, errors, touched, onSetFieldValue, onSetFieldError, onSetErrors, isEdit = false, tournamentId, defaultTab = WizardPages.Basics }) => {
    const tabOptions = Object.values(WizardPages);
    const blockOptions = tabOptions.filter((value) => isEdit ? true : value !== WizardPages.Participants);
    const blocks = blockOptions.map((value) => ({
        label: WizardPageTitles[value],
        value: value,
    }));
    const isLargeDisplay = useMedia(`(min-width: ${LG_M}px)`);
    const [selectedTab, setSelectedTab] = useState(defaultTab);
    const deleteDisabled = values?.state ? !isTournamentDeletable(values.state) : true;
    const editDisabled = values.state ? !isTournamentUpcoming(values.state) : false;
    const selectedTabOption = isLargeDisplay ? (selectedTab ?? defaultTab) : selectedTab;
    const prevTab = !isNull(selectedTabOption) ? blockOptions[blockOptions.indexOf(selectedTabOption) - 1] : null;
    const nextTab = !isNull(selectedTabOption) ? blockOptions[blockOptions.indexOf(selectedTabOption) + 1] : null;
    const handleSelect = (elem) => {
        setSelectedTab(elem);
    };
    const handleGoBack = () => {
        setSelectedTab(null);
    };
    const handleNextTab = () => {
        if (!isNull(nextTab)) {
            handleSelect(nextTab);
        }
    };
    const handlePrevTab = () => {
        if (!isNull(prevTab)) {
            handleSelect(prevTab);
        }
    };
    const handleSetFieldError = (name, error) => {
        if (!isUndefined(error)) {
            onSetFieldError(name, error);
        }
        const newErrors = deepClone(errors);
        if (!isUndefined(newErrors[name])) {
            delete newErrors[name];
        }
        onSetErrors(newErrors);
    };
    let Component = selectedTabOption && Object.keys(components).includes(selectedTabOption) ? components[selectedTabOption] : null;
    return (React.createElement("form", { onSubmit: onSubmit, className: styles.tournamentConfigurations },
        React.createElement(WizardHeader, { subTitle: selectedTabOption, onBack: handleGoBack }),
        React.createElement("div", { className: styles.tournamentConfigurationsContainer }, isLargeDisplay ? (React.createElement(React.Fragment, null,
            React.createElement(SideNavigation, { blocks: blocks, onSelect: handleSelect, selectedValue: selectedTabOption, isWide: !isLargeDisplay }),
            React.createElement("div", { className: styles.pageContainer },
                !isNull(Component) && (React.createElement(Component, { title: selectedTabOption, values: values, errors: errors, touched: touched, isEdit: isEdit, tournamentId: tournamentId, onBlur: onBlur, onChange: onChange, onSetFieldValue: onSetFieldValue, onSetFieldError: handleSetFieldError, isEditingDisabled: editDisabled })),
                React.createElement(Pager, { nextTab: nextTab, onNext: handleNextTab, prevTab: prevTab, onPrev: handlePrevTab })))) : selectedTabOption ? (React.createElement("div", { className: styles.pageContainer },
            !isNull(Component) && (React.createElement(Component, { title: selectedTabOption, values: values, errors: errors, touched: touched, isEdit: isEdit, tournamentId: tournamentId, onBlur: onBlur, onChange: onChange, onSetFieldValue: onSetFieldValue, onSetFieldError: handleSetFieldError, onBack: handleGoBack, isEditingDisabled: editDisabled })),
            React.createElement(Pager, { nextTab: nextTab, onNext: handleNextTab, prevTab: prevTab, onPrev: handlePrevTab }))) : (React.createElement(SideNavigation, { blocks: blocks, onSelect: handleSelect, selectedValue: selectedTabOption, isWide: !isLargeDisplay }))),
        React.createElement(WizardBottom, { tournamentId: tournamentId, disabled: !isEmpty(errors), isEdit: isEdit, onDelete: onDelete, deleteDisabled: deleteDisabled })));
};
