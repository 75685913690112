import { call, put, takeLatest } from 'redux-saga/effects';

import { Firmwares } from 'services/api';
import { callApi } from 'services/rest';

import {
  getFirmwares,
  getFirmwaresFailure,
  getFirmwaresSuccess,
} from 'scenes/Firmwares/components/List/actions';

function* watchGetFirmwares({ payload: { queryParams } }) {
  try {
    const { data } = yield call(callApi, Firmwares.index, queryParams);
    yield put(getFirmwaresSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(getFirmwaresFailure());
  }
}

export default () => takeLatest(getFirmwares, watchGetFirmwares);
