import { put, take } from 'redux-saga/effects';

import { actions as wsActions } from 'services/ws';

import {
  getMessage
} from 'scenes/Chat/components/Show/actions';

export default function* watchGetMessage() {
  while (true) {
    const { payload } = yield take(wsActions.Api.groupChatMessage);
    yield put(getMessage(payload));
  }
}
