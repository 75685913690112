import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import { scoreCorrections } from 'constants/Resources';

import actions from 'scenes/Users/components/Show/actions';
import gamesActions from 'scenes/Users/components/Show/components/GameHistory/actions';
import tokenActions from 'scenes/Users/components/Show/components/TokenHistory/actions';
import { actions as downloadActions } from 'services/entityTable';


const INITIAL_STATE = {
  isLoading: false,
  isStatisticsLoading: false,
  games: {},
  tokenHistory: {
    data: [],
    isLoading: true,
    page: 0,
    totalCount: 0,
    pageSize: 0,
  },
  scoreCorrectionHistory: {
    isDownloading: false,
  }
};

const showReducer = handleActions(
  {
    [actions.getUser]: state =>
      update(state, {
        isLoading: { $set: true },
      }),

    [actions.getUserSuccess]: (state, { payload }) =>
      update(state, {
        isLoading: { $set: false },
        user: { $set: { ...payload } }
      }),

    [actions.getUserFailure]: state =>
      update(state, {
        isLoading: { $set: false }
      }),

    [actions.resetUser]: state =>
      update(state, {
        user: { $set: null },
        userStats: { $set: null }
      }),

    [actions.getUserStatistics]: state =>
      update(state, {
        isStatisticsLoading: { $set: true }
      }),

    [actions.getUserStatisticsSuccess]: (state, { payload: { aggregatedStats } }) =>
      update(state, {
        isStatisticsLoading: { $set: false },
        userStats: { $set: aggregatedStats ?? {} }
      }),

    [actions.getUserStatisticsFailure]: state =>
      update(state, {
        isStatisticsLoading: { $set: false },
        userStats: { $set: null }
      }),

    [gamesActions.getUserGameHistory]: state =>
      update(state, {
        games: {
          $set: {
            isLoading: true,
            data: null
          }
        },
      }),

    [gamesActions.getUserGameHistorySuccess]: (state, { payload }) =>
      update(state, {
        games: {
          $set: {
            isLoading: false,
            ...payload,
          }
        },
      }),

    [gamesActions.getUserGameHistoryFailure]: state =>
      update(state, {
        games: {
          $set: {
            isLoading: false,
            data: null
          }
        },
      }),

    [gamesActions.resetUserGameHistory]: state =>
      update(state, {
        games: {
          $set: {
            isLoading: false,
            data: null
          }
        },
      }),

    [tokenActions.getUserTokenHistory]: state =>
      update(state, {
        tokenHistory: {
          $set: {
            ...state.tokenHistory,
            isLoading: true,
          }
        },
      }),

    [tokenActions.getUserTokenHistorySuccess]: (state, { payload }) =>
      update(state, {
        tokenHistory: {
          $set: {
            ...state.tokenHistory,
            isLoading: false,
            ...payload,
          }
        },
      }),

    [tokenActions.getUserTokenHistoryFailure]: state =>
      update(state, {
        tokenHistory: {
          $set: {
            ...state.tokenHistory,
            isLoading: false,
            data: null
          }
        },
      }),

    [tokenActions.resetUserTokenHistory]: state =>
      update(state, {
        tokenHistory: {
          $set: {
            isLoading: false,
            data: null
          }
        },
      }),

    [downloadActions.download]: (state, { payload: { resource } }) => {
      if (resource === scoreCorrections) {
        return update(state, {
          scoreCorrectionHistory: {
            $set: {
              ...state.scoreCorrectionHistory,
              isDownloading: true,
            },
          }
        });
      } else {
        return state;
      }
    },

    [downloadActions.downloadSuccessful]: (state, { payload }) => {
      if (payload === scoreCorrections) {
        return update(state, {
          scoreCorrectionHistory: {
            $set: {
              ...state.scoreCorrectionHistory,
              isDownloading: false,
            },
          }
        });
      } else {
        return state;
      }
    },

    [downloadActions.downloadFailure]: (state, { payload }) => {
      if (payload === scoreCorrections) {
        return update(state, {
          scoreCorrectionHistory: {
            $set: {
              ...state.scoreCorrectionHistory,
              isDownloading: false,
            },
          }
        });
      } else {
        return state;
      }
    },

  },
  INITIAL_STATE
);

export default showReducer;
