import React, { useState } from 'react';
import cn from 'classnames';
import { Formik } from 'formik';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import * as Yup from 'yup';

import formatLabel from 'utils/formatLabel';

import { Input } from 'components/Form';

import styles from './styles.module.scss';

const EditableToken = ({ onSubmit, value }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newBalance, setNewBalance] = useState(value);

  const form = {
    token_diff: 0,
    token_balance: value,
  };

  const validationSchema = Yup.object().shape({
    token_balance: Yup.number()
      .min(0, 'Must be zero or more')
      .integer('Must be an integer')
      .test(
        'isNotChanged',
        "New balance can't be the same as the current one",
        curValue => curValue !== value
      )
      .required('Required'),
    token_diff: Yup.number()
      .integer('Must be an integer')
      .test(
        'isTooBig',
        "Negative difference can't be bigger than current balance",
        curValue => !(curValue < 0 && Math.abs(curValue) > value)
      )
      .required('Required'),
  });

  const handleChange = (eventValue, setFieldValue, isDiff) => {
    const diffValue = isDiff ? eventValue : parseInt(eventValue) - value;
    const newBalanceValue = isDiff ? value + parseInt(eventValue) : eventValue;

    setFieldValue('token_diff', isNaN(diffValue) ? 0 : diffValue);
    setFieldValue('token_balance', isNaN(newBalanceValue) ? 0: newBalanceValue);
    setNewBalance(newBalanceValue);
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const handleSubmit = () => {
    onSubmit(newBalance);
    toggleModal();
  };

  return (
    <div className="row">
      <div className="col-sm-4">{formatLabel('Token Balance')}</div>
      <div className={cn('col-sm-8', styles.editWrapper)}>
        <span>{value}</span>
        <i onClick={toggleModal} className="fa fa-fw fa-edit" />
      </div>
      <Formik
        initialValues={form}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnBlur
      >
        {({ errors, touched, values, handleSubmit, setFieldValue, setFieldTouched }) => (
          <Modal isOpen={isModalOpen} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Token balance update</ModalHeader>
            <ModalBody>
              <form onSubmit={handleSubmit}>
                <dl className="row">
                  <dt className="col-sm-4 required">Current balance</dt>
                  <dd className="col-sm-10 mb-0">{value}</dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-4 required">Token difference</dt>
                  <dd className="col-sm-10 mb-0">
                    <Input.Number
                      name="token_diff"
                      className={styles.emailInput}
                      value={values.token_diff}
                      error={touched.token_diff && errors.token_diff}
                      onChange={event => {
                        setFieldTouched('token_diff', true);
                        handleChange(event.currentTarget.value, setFieldValue, true)
                      }}
                    />
                  </dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-4 required">New Balance</dt>
                  <dd className="col-sm-10 mb-0">
                    <Input.Number
                      name="token_balance"
                      className={styles.emailInput}
                      value={values.token_balance}
                      error={touched.token_balance && errors.token_balance}
                      onChange={event => {
                        setFieldTouched('token_balance', true);
                        handleChange(event.currentTarget.value, setFieldValue, false)
                      }}
                    />
                  </dd>
                </dl>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={handleSubmit}
                disabled={Object.keys(errors).length > 0}
              >
                Submit
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </Formik>
    </div>
  );
};

export default EditableToken;
