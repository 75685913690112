import tournamentBackground1 from 'assets/images/tournaments/backgrounds/default-tournament-background-1.jpg'
import tournamentBackground2 from 'assets/images/tournaments/backgrounds/default-tournament-background-2.jpg'
import tournamentBackground3 from 'assets/images/tournaments/backgrounds/default-tournament-background-3.jpg'
import tournamentBackground4 from 'assets/images/tournaments/backgrounds/default-tournament-background-4.jpg'
import tournamentBackground5 from 'assets/images/tournaments/backgrounds/default-tournament-background-5.jpg'

import publicTournamentBackground1 from 'assets/images/tournaments/backgrounds/public-tournament-background-1.png'
import publicTournamentBackground2 from 'assets/images/tournaments/backgrounds/public-tournament-background-2.png'
import publicTournamentBackground3 from 'assets/images/tournaments/backgrounds/public-tournament-background-3.png'
import publicTournamentBackground4 from 'assets/images/tournaments/backgrounds/public-tournament-background-4.jpg'
import publicTournamentBackground5 from 'assets/images/tournaments/backgrounds/public-tournament-background-5.png'
import publicTournamentBackground6 from 'assets/images/tournaments/backgrounds/public-tournament-background-6.png'
import publicTournamentBackground7 from 'assets/images/tournaments/backgrounds/public-tournament-background-7.png'
import publicTournamentBackground8 from 'assets/images/tournaments/backgrounds/public-tournament-background-8.png'
import publicTournamentBackground9 from 'assets/images/tournaments/backgrounds/public-tournament-background-9.jpg'
import publicTournamentBackground10 from 'assets/images/tournaments/backgrounds/public-tournament-background-10.jpg'

import { PrivateTournamentBackgroundImagesEnum } from 'enums'

export const backgroundImages: { src: any, value: PrivateTournamentBackgroundImagesEnum }[] = [
  { src: tournamentBackground1, value: PrivateTournamentBackgroundImagesEnum.tournamentBackground1 },
  { src: tournamentBackground2, value: PrivateTournamentBackgroundImagesEnum.tournamentBackground2 },
  { src: tournamentBackground3, value: PrivateTournamentBackgroundImagesEnum.tournamentBackground3 },
  { src: tournamentBackground4, value: PrivateTournamentBackgroundImagesEnum.tournamentBackground4 },
  { src: tournamentBackground5, value: PrivateTournamentBackgroundImagesEnum.tournamentBackground5 },
  { src: publicTournamentBackground1, value: PrivateTournamentBackgroundImagesEnum.publicTournamentBackground1 },
  { src: publicTournamentBackground2, value: PrivateTournamentBackgroundImagesEnum.publicTournamentBackground2 },
  { src: publicTournamentBackground3, value: PrivateTournamentBackgroundImagesEnum.publicTournamentBackground3 },
  { src: publicTournamentBackground4, value: PrivateTournamentBackgroundImagesEnum.publicTournamentBackground4 },
  { src: publicTournamentBackground5, value: PrivateTournamentBackgroundImagesEnum.publicTournamentBackground5 },
  { src: publicTournamentBackground6, value: PrivateTournamentBackgroundImagesEnum.publicTournamentBackground6 },
  { src: publicTournamentBackground7, value: PrivateTournamentBackgroundImagesEnum.publicTournamentBackground7 },
  { src: publicTournamentBackground8, value: PrivateTournamentBackgroundImagesEnum.publicTournamentBackground8 },
  { src: publicTournamentBackground9, value: PrivateTournamentBackgroundImagesEnum.publicTournamentBackground9 },
  { src: publicTournamentBackground10, value: PrivateTournamentBackgroundImagesEnum.publicTournamentBackground10 },
]
