import React, { Component, Fragment } from 'react';
import styles from './styles.module.scss';
import { Button, Row, Col } from 'reactstrap';
import { ConnectedBoard } from './components/ConnectedBoard';
import cn from 'classnames';
import PropTypes from 'prop-types';

export default class ConnectedBoards extends Component {
  onDelete = index => {
    const newConnectedBoards = [...this.props.connectedBoards];
    newConnectedBoards.splice(index, 1);

    this.props.onChange({ connectedBoards: newConnectedBoards });
  };

  addConnectedBoard = () => {
    const connectedBoard = {
      isSuspended: false,
      enableMessageForwardToScolia: this.props.isSAForwarding,
      serialNumber: '',
      boardId: '',
    };

    this.props.onChange({
      connectedBoards: [connectedBoard, ...this.props.connectedBoards],
    });
  };

  handleIsSuspendedChange = index => {
    const newConnectedBoards = [...this.props.connectedBoards];
    newConnectedBoards[index].isSuspended = !this.props.connectedBoards[index]
      .isSuspended;
    this.props.onChange({ connectedBoards: newConnectedBoards });
  };

  handleIsForwardingChange = index => {
    const newConnectedBoards = [...this.props.connectedBoards];
    newConnectedBoards[index].enableMessageForwardToScolia = !this.props.connectedBoards[index]
      .enableMessageForwardToScolia;
    this.props.onChange({ connectedBoards: newConnectedBoards });
  };

  handleSerialNumberChange = (index, board) => {
    const newConnectedBoards = [...this.props.connectedBoards];
    newConnectedBoards[index].serialNumber = board.serialNumber;
    newConnectedBoards[index].boardId = board.boardId;
    this.props.onChange({ connectedBoards: newConnectedBoards });
  };

  render() {
    const {
      boards,
      errors,
      connectedBoards,
      readOnly,
      onSerialNumberInputChange,
    } = this.props;
    return (
      <div className={styles.connectedBoards}>
        <div className={styles.header}>
          {readOnly ? (
            <Row>
              <Col sm={4}>
                <span>Serial Number</span>
              </Col>
              <Col sm={3}>
                <span>Suspended</span>
              </Col>
              <Col sm={3}>
                <span>Forwarding</span>
              </Col>
              <Col sm={2}>
                <span>Attach Date</span>
              </Col>
            </Row>
          ) : (
            <Fragment>
              <div className={styles.connectedBoardsLabels}>
                <span>Connected Boards</span>
                {connectedBoards.length > 0 && (
                  <span style={{ float: 'right' }}>{connectedBoards.length}</span>
                )}
              </div>
              <Row>
                <Col md={6}>
                  <div
                    className={cn({
                      [styles.required]: connectedBoards.length,
                    })}
                    style={{ paddingRight: '0px' }}
                  >
                    <span>Serial Number</span>
                  </div>
                </Col>
                <Col md={2} style={{ textAlign: 'center', paddingLeft: '0px' }}>
                  <span>Suspended</span>
                </Col>
                <Col md={2} style={{ textAlign: 'center', paddingLeft: '0px' }}>
                  <span>Forwarding</span>
                </Col>
                <Col md={2} className={styles.addBoardCol}>
                  <span>
                    <Button
                      className={cn(
                        'btn-success btn-sm',
                        styles.addBoardButton
                      )}
                      onClick={this.addConnectedBoard}
                    >
                      Add board
                    </Button>
                  </span>
                </Col>
              </Row>
            </Fragment>
          )}
        </div>
        <div className={styles.body}>
          {connectedBoards.map((connectedBoard, index) => {
            return (
              <ConnectedBoard
                key={index}
                error={errors && errors[index]}
                readOnly={readOnly}
                board={connectedBoard}
                boards={boards}
                isSuspended={connectedBoard.isSuspended || false} //Controlled input
                isForwarding={connectedBoard.enableMessageForwardToScolia}
                onSerialNumberChange={this.handleSerialNumberChange}
                onSerialNumberInputChange={onSerialNumberInputChange}
                onIsSuspendedChange={this.handleIsSuspendedChange}
                onIsForwardingChange={this.handleIsForwardingChange}
                onDelete={this.onDelete}
                index={index}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

ConnectedBoards.propTypes = {
  boards: PropTypes.array.isRequired,
  connectedBoards: PropTypes.array,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  errors: PropTypes.array,
  isSAForwarding: PropTypes.bool,
};
