import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import cn from 'classnames';

import { getFirmware, deleteFirmware } from 'scenes/Firmwares/actions';
import { Form } from 'scenes/Firmwares/components/Form';

import actions from './actions';

class Edit extends Component {
  handleFormSubmit = ({ fileContent, ...restValues }, _actions) => {
    this.props.dispatch(
      actions.updateFirmware({
        firmwareId: this.firmwareId,
        firmware: restValues,
      })
    );
  };

  componentDidMount() {
    this.props.dispatch(getFirmware(this.firmwareId));
  }

  delete(id) {
    const result = window.confirm(
      'Are you sure you want to delete this firmware?'
    );
    if (result) {
      this.props.dispatch(deleteFirmware(id));
    }
  }

  get firmwareId() {
    return this.props.match.params.firmwareId;
  }

  render() {
    const {
      updateError,
      firmware,
      isLoading,
      isUpdateSuccessful,
      isAdmin,
    } = this.props;

    if (!firmware || isLoading) {
      return <i className="fa fa-spinner fa-spin fa-fw" />;
    }

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" lg={{ size: 10, offset: 1 }}>
            <Card color="white">
              <CardHeader>
                <div className="card-header__left">
                  <i className="fa fa-align-justify" /> Edit Firmware
                </div>
                <div className="card-header__right">
                  {isAdmin && <button
                    className={cn('btn', 'btn-danger', 'btn-sm', 'me-1')}
                    disabled={firmware.isStable}
                    onClick={() => this.delete(firmware.id)}
                  >
                    Delete Firmware
                  </button>}
                </div>
              </CardHeader>
              <CardBody>
                <Form
                  formError={updateError}
                  onSubmit={this.handleFormSubmit}
                  firmware={firmware}
                  isSuccessful={isUpdateSuccessful}
                  isEdit
                  isAdmin={isAdmin}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

Edit.displayName = 'Firmwares.Edit';

const mapStateToProps = (state, ownProps) => ({
  isUpdateSuccessful: state.scenes.firmwares.edit.isUpdateSuccessful,
  updateError: state.scenes.firmwares.edit.updateError,
  isLoading: state.scenes.firmwares.edit.isLoading,
  firmware: state.data.firmwares[ownProps.match.params.firmwareId],
});

export default connect(mapStateToProps)(Edit);
