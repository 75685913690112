import { combineReducers } from 'redux';

import { reducer as systemUsage } from './components/SystemUsage';
import { reducer as systemAnalytics } from './components/SystemAnalytics';

const systemUsages = combineReducers({
  systemAnalytics,
  systemUsage,
});

export default systemUsages;

