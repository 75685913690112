import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './styles.module.scss';

// REVIEW: https://codepen.io/andreasstorm/pen/ZGjNwZ?editors=0100
const Toggle = ({ label, name, value, onChange, noHeight, noMargin, disabled, smallVariant, isKeepToggleBright, children, ...rest }) => (
    <div className={cn(styles.flex, {[styles.margin]: !noMargin})}>
      <input type="checkbox"           
          id={name}
          name={name}
          checked={value || false}
          onChange={onChange} 
          className={cn(styles.input, { [styles.isKeepToggleBright]: isKeepToggleBright })}
          disabled={disabled}
          {...rest}
      />
      <label htmlFor={name} className={value ? styles.switchChecked : styles.switchUnchecked}></label>
    </div>
);

Toggle.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  noHeight: PropTypes.bool,
  noMargin: PropTypes.bool,
  disabled: PropTypes.bool,
  smallVariant: PropTypes.bool,
  isKeepToggleBright: PropTypes.bool,
};

export { Toggle };

export default Toggle;
