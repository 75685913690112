import { call, put, takeLatest } from 'redux-saga/effects';

import { Users } from 'services/api';
import { callApi } from 'services/rest';

import {
  getUserTokenHistory,
  getUserTokenHistoryFailure,
  getUserTokenHistorySuccess
} from 'scenes/Users/components/Show/components/TokenHistory/actions';

function* watchGetUserTokenHistory({payload: { userId, ...rest }}) {
  try {
    const { data } = yield call(callApi, Users.tokenHistory, userId, rest);
    yield put(getUserTokenHistorySuccess(data));
  } catch (e) {
    yield put(getUserTokenHistoryFailure(e.isFetch ? e.data.error : e));
  }
}

export default () => takeLatest(getUserTokenHistory, watchGetUserTokenHistory);
