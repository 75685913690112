import { call, put, takeLatest } from 'redux-saga/effects';

import { Hardwares } from 'services/api';
import { callApi } from 'services/rest';

import {
  getHardwares,
  getHardwaresFailure,
  getHardwaresSuccess,
} from 'scenes/Hardwares/components/List/actions';

function* watchGetBoards({ payload: { queryParams } }) {
  try {
    const { data } = yield call(callApi, Hardwares.index, queryParams);
    yield put(getHardwaresSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(getHardwaresFailure());
  }
}

// It must be wrapped in a function, so execution will happen only on function call
export default () => takeLatest(getHardwares, watchGetBoards);
