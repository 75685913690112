import React from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { useFlag } from '@unleash/proxy-client-react';
import { FlexBoardConfigurationForm, GeneralConfigsForm, TournamentConfigsForm, LiveBoardViewConfigurationForm, } from '../ConfigsForm';
import { useQuery } from 'react-query';
import { get } from 'services/rest';
export const Edit = () => {
    const isLiveBoardViewFormEnabled = useFlag('feature-14323-live-board-view');
    const url = '/config/management';
    const { data } = useQuery([url], async () => {
        const response = (await get(url));
        return response?.data;
    });
    return (React.createElement("div", { className: "animated fadeIn" },
        React.createElement(Row, null,
            React.createElement(Col, { xs: "12", lg: { size: 10, offset: 1 } },
                React.createElement(Card, null,
                    React.createElement(CardHeader, null,
                        React.createElement("div", { className: "card-header__left" },
                            React.createElement("i", { className: "fa fa-align-justify" }),
                            " General")),
                    React.createElement(CardBody, null,
                        React.createElement(GeneralConfigsForm, null))),
                React.createElement(Card, null,
                    React.createElement(CardHeader, null,
                        React.createElement("div", { className: "card-header__left" },
                            React.createElement("i", { className: "fa fa-align-justify" }),
                            " Tournament autocreation")),
                    React.createElement(CardBody, null,
                        React.createElement(TournamentConfigsForm, { config: data }))),
                React.createElement(Card, null,
                    React.createElement(CardHeader, null,
                        React.createElement("div", { className: "card-header__left" },
                            React.createElement("i", { className: "fa fa-align-justify" }),
                            " Flex board configuration")),
                    React.createElement(CardBody, null,
                        React.createElement(FlexBoardConfigurationForm, { config: data }))),
                isLiveBoardViewFormEnabled && (React.createElement(LiveBoardViewConfigurationForm, { config: data?.liveBoardViewConfiguration }))))));
};
Edit.displayName = 'Configs.Edit';
