import { InvitedParticipantStatus } from "./constants"

export const getPrivateTournamentInvitedAndAcceptedParticipantsNumber = (participants = {}) => {
  return Object.values(participants).reduce(
    (acc, participant) => {
      if (participant.status === InvitedParticipantStatus.accepted) {
        acc.acceptedParticipantsNumber += 1;
      }
      if (participant.status !== InvitedParticipantStatus.declined) {
        acc.numberOfInvitedParticipants += 1;
      }
      return acc;
    },
    { numberOfInvitedParticipants: 0, acceptedParticipantsNumber: 0 }
  );
};