import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { getHardware } from 'scenes/Hardwares/actions';
import HardwareForm from '../HardwareForm/HardwareForm';

import { getIsAdmin } from 'services/session/selectors';

import actions from './actions';

class Edit extends Component {
  componentDidMount() {
    this.props.dispatch(getHardware(this.hardwareId));
  }

  handleFormSubmit = ({ ...hardwareProperties }, _actions) => {
    this.props.dispatch(
      actions.updateHardware({
        hardwareId: this.hardwareId,
        description: hardwareProperties.description,
        isWifiCompatible: hardwareProperties.isWifiCompatible,
      })
    );
  };

  get hardwareId() {
    return this.props.match.params.hardwareId;
  }
  render() {
    const { hardware, updateError, isLoading, isUpdateSuccessful, isAdmin } = this.props;

    if (!hardware || isLoading) {
      return <i className="fa fa-spinner fa-spin fa-fw" />;
    }

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" lg={{ size: 10, offset: 1 }}>
            <Card color="white">
              <CardHeader>
                <div className="card-header__left">
                  <i className="fa fa-align-justify" /> Edit Hardware
                </div>
              </CardHeader>
              <CardBody>
                <HardwareForm
                  hardware={hardware}
                  onSubmit={this.handleFormSubmit}
                  isSuccessful={isUpdateSuccessful}
                  formError={updateError}
                  isEdit
                  isAdmin={isAdmin}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

Edit.displayName = 'Hardwares.Edit';

const mapStateToProps = (state, ownProps) => ({
  isUpdateSuccessful: state.scenes.hardwares.edit.isUpdateSuccessful,
  updateError: state.scenes.hardwares.edit.updateError,
  isLoading: state.scenes.hardwares.edit.isLoading,
  hardware: state.data.hardwares[ownProps.match.params.hardwareId],
  isAdmin: getIsAdmin(state),
});

export default connect(mapStateToProps)(Edit);
