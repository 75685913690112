import React from 'react';
import { TournamentParticipantInvite } from './TournamentParticipantInvite';
import { SScrollContainer } from 'ScoliaComponents';
import { isNull, isUndefined } from 'lodash';
import { HeaderTitle } from '../../HeaderTitle';
import { TournamentParticipantStatusEnum } from 'enums';
export const Participants = ({ title, onBack, values, tournamentId, isEditingDisabled }) => {
    const invitedUsers = isUndefined(values.participants) ? 0 : Object.values(values.participants).filter(elem => elem.status !== TournamentParticipantStatusEnum.Declined).length;
    return (React.createElement(SScrollContainer, null,
        !isNull(title) && (React.createElement(HeaderTitle, { title: title, onBack: onBack })),
        React.createElement(TournamentParticipantInvite, { tournamentId: tournamentId, invitedUsers: invitedUsers, maxParticipants: values.maxParticipants, disabled: isEditingDisabled, participants: values.participants })));
};
