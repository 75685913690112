import { call, put, takeLatest } from 'redux-saga/effects';

import { StoreSnapshot } from 'services/api';
import { callApi } from 'services/rest';

import {
  deleteWSConnection,
  deleteWSConnectionFailure,
  deleteWSConnectionSuccess,
  getStoreSnapshot
} from 'scenes/StoreSnapshot/components/actions';

function* watchDeleteWSConnection({ payload }) {
  try {
    yield call(callApi, StoreSnapshot.deleteWSConnection, payload);
    yield put(deleteWSConnectionSuccess());
    yield put(getStoreSnapshot());
  } catch (e) {
    console.log(e);
    yield put(deleteWSConnectionFailure());
  }
}

export default () => takeLatest(deleteWSConnection, watchDeleteWSConnection);
