import { handleActions } from 'redux-actions';

import showDetailsActions from 'scenes/Users/components/Show/components/Details/actions';
import { getUserFailure, getUserSuccess } from 'scenes/Users/components/Show/actions';

const INITIAL_STATE = {
  isLoading: false,
  isSaveDisabled: false,
};

const showDetailsReducer = handleActions(
  {
    [showDetailsActions.updateUser]: state => ({
      ...state,
      isLoading: true,
      isSaveDisabled: true,
    }),

    [showDetailsActions.updateUserSuccess]: state => ({
      ...state,
      isLoading: false,
      isSaveDisabled: false,
      editError: '',
    }),

    [showDetailsActions.updateUserFailure]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      isSaveDisabled: false,
      editError: payload,
    }),

    [getUserSuccess]: state => ({
      ...state,
      isSaveDisabled: false,
    }),

    [getUserFailure]: state => ({
      ...state,
      isSaveDisabled: false,
    }),
  },
  INITIAL_STATE
);

export default showDetailsReducer;
