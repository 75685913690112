export const statuses = [
  {
    value: 'InStock',
    label: 'In stock',
  },
  {
    value: 'ReadyToActivate',
    label: 'Ready to activate',
  },
  {
    value: 'Attached',
    label: 'Attached',
  },
  {
    value: 'Detached',
    label: 'Detached',
  },
  {
    value: 'Suspended',
    label: 'Suspended',
  },
];
