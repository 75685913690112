import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';

import defaultProfilePic from 'images/avatars/defaultProfilePic.png';
import { formatJSDateToFullDate, formatJSDateToHHMM } from 'utils/dateFormatter';

import styles from './styles.module.scss';

export const Message = ({message, isInstantMessage}) => {

  const [imgSrc, setImgSrc] = useState(message.avatar);

  const date = new Date(isInstantMessage ? message.createdAt : message.timestamp);

  const displayPlayerNickname = !message.isGuest ? (
    <Link to={`/users/${message.playerId}`}>{message.nickname}</Link>
  ) : (
    message.nickname
  )

  return (
    <div className={styles.wrapper}>
      <div className={styles.avatarContainer}>
        <img src={imgSrc || defaultProfilePic} onError={() => setImgSrc(defaultProfilePic)} alt='avatar'>
        </img>
      </div>
      <div className={styles.messageContainer}>
        <div className={styles.messageHeader}>
          <div className={styles.playerName}>
            { isInstantMessage ? 
             (<>
             <span>{displayPlayerNickname}</span>
             {' '}
             <Link to={`/boards/${message.boardId}`}>[{message.boardCode}]</Link>
             </>): 
             message.nickname}
          </div>
          <div className={styles.sentDate}>
            {
              isInstantMessage ? 
                formatJSDateToFullDate(date) :
                formatJSDateToHHMM(date)
            }
          </div>
        </div>
        <div className={styles.messageText}>
          {message.message}
        </div>
      </div>
    </div>
  )
  }

Message.propTypes = {
  message: PropTypes.object.isRequired,
  isInstantMessage: PropTypes.bool,
}

export default Message
