import React, { Component } from 'react';
import { connect } from 'react-redux';

import { EntityTable } from 'components/EntityTable';
import { getHmsFromMs, formatNumberWithThousandSeparators } from 'utils';

import * as rankingListActions from './actions';

const columns = [
  {
    Header: '#',
    id: 'row',
    maxWidth: 40,
    accessor: d => d.index,
    Cell: row => {
      return <div>{row.page * row.pageSize + row.index + 1 + '.'}</div>;
    },
    sortable: false,
  },
  {
    Header: 'Board Name',
    accessor: 'boardName',
    filterable: true,
  },
  {
    Header: 'Board SN',
    accessor: 'boardSerialNumber',
    filterable: true,
  },
  {
    Header: 'Total Throws',
    accessor: 'throwsTotal',
    Cell: row => formatNumberWithThousandSeparators(row.value, ' '),
  },
  {
    Header: 'Modified Throws',
    accessor: 'throwsModified',
    Cell: row => formatNumberWithThousandSeparators(row.value, ' '),
  },
  {
    Header: 'Games Duration',
    accessor: 'gamesNetDuration',
    Cell: row => {
      const hms = getHmsFromMs(row.value);
      return (
        <div>
          {hms[0] > 0 && hms[0] + 'h '}
          {hms[1] > 0 && hms[1] + 'm '}
          {hms[2] > 0 && hms[2] + 's'}
        </div>
      );
    },
  },
  {
    Header: 'Games Count',
    accessor: 'gamesCount',
    Cell: row => formatNumberWithThousandSeparators(row.value, ' '),
  },
];

const defaultSorted = [
  {
    id: 'throwsTotal',
    desc: true,
  },
];

class List extends Component {
  handleFetchData = query => {
    this.props.dispatch(rankingListActions.getRankingList(query));
  };

  render() {
    const { data, page, pageSize, isLoading, totalCount, isDownloading } = this.props;

    return (
      <div>
        <EntityTable
          resource={'board-ranking'}
          columns={columns}
          data={data}
          page={page}
          pageSize={pageSize}
          defaultSorted={defaultSorted}
          totalCount={totalCount}
          isLoading={isLoading}
          onFetchData={this.handleFetchData}
          hasDateFilter
          datePropertyKey={'createdAt'}
          isDownloading={isDownloading}
        />
      </div>
    );
  }
}

List.displayName = 'RankingList.List';

const mapStateToProps = state => ({
  data: state.data.rankingList,
  page: state.scenes.rankingList.list.page,
  pageSize: state.scenes.rankingList.list.pageSize,
  totalCount: state.scenes.rankingList.list.totalCount,
  isLoading: state.scenes.rankingList.list.isLoading,
  isDownloading: state.scenes.rankingList.list.isDownloading
});

export default connect(mapStateToProps)(List);
