import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import { actions as firmwareActions } from 'scenes/Firmwares';

const INITIAL_STATE = {};

const downloadReducer = handleActions(
  {
    [firmwareActions.downloadFirmwareSuccess]: (
      state,
      { payload: { data, filename } }
    ) => {
      return update(state, {
        file: {
          $set: data,
        },
        filename: {
          $set: filename,
        },
      });
    },
  },
  INITIAL_STATE
);

export default downloadReducer;
