import React from 'react';
import cn from 'classnames';
import { TournamentParticipantStatusEnum } from 'enums';
import styles from './styles.module.scss';
function Status({ status, counter }) {
    return (React.createElement("div", { className: cn(styles.status, styles[status]), id: status },
        React.createElement("span", { className: styles.value }, counter),
        React.createElement("span", { className: styles.label }, status)));
}
export function ParticipantsStatusHeader({ acceptedInvitations, declinedInvitations, pendingInvitations, maxParticipants }) {
    const sentInvitations = acceptedInvitations + pendingInvitations;
    const numberOfAvailableInvitationsToSend = maxParticipants - sentInvitations;
    return (React.createElement("div", { className: styles.listCard },
        React.createElement("div", { className: styles.listCardHeader },
            React.createElement("div", { className: styles.statusContainer },
                React.createElement("div", { className: styles.currentStatus },
                    React.createElement("div", { className: styles.primary },
                        sentInvitations,
                        "/",
                        maxParticipants,
                        " participants invited"),
                    React.createElement("div", { className: styles.secondary }, numberOfAvailableInvitationsToSend > 0 ? (`You can still invite ${numberOfAvailableInvitationsToSend} ${numberOfAvailableInvitationsToSend > 1 ? 'players' : 'player'}.`) : (`You cannot invite any more players`))),
                React.createElement("div", { className: styles.statuses },
                    React.createElement(Status, { status: TournamentParticipantStatusEnum.Accepted, counter: acceptedInvitations }),
                    React.createElement(Status, { status: TournamentParticipantStatusEnum.Pending, counter: pendingInvitations }),
                    React.createElement(Status, { status: TournamentParticipantStatusEnum.Declined, counter: declinedInvitations }))))));
}
