import { call, put, takeLatest } from 'redux-saga/effects';

import { Boards } from 'services/api';
import { callApi } from 'services/rest';

import {
  changeWifiConfiguration,
  changeWifiConfigurationFailure,
  changeWifiConfigurationSuccess,
} from 'scenes/Boards/components/Show/actions';
import { getBoard } from 'scenes/Boards/actions';
import { notifySuccess, notifyError } from 'services/notification';

function* watchChangeWifiConfiguration({ payload: { boardId, isWifiConfigurationActive } }) {
  try {
    const { data: isSuccessFull } = yield call(callApi, Boards.changeWifiConfiguration, boardId, isWifiConfigurationActive);
    if (isSuccessFull) {
      yield put(changeWifiConfigurationSuccess(boardId));
      yield put(notifySuccess('Wifi configuration value changed successfully.'));
      yield put(getBoard(boardId))
    } else {
      yield put(changeWifiConfigurationFailure());
      yield put(notifyError('Couldn\'t change wifi configuration value.'));
    }
  } catch (e) {
    console.log(e);
    yield put(changeWifiConfigurationFailure());
    yield put(notifyError('Couldn\'t change wifi configuration value.'));
  }
}

// It must be wrapped in a function, so execution will happen only on function call
export default () => takeLatest(changeWifiConfiguration, watchChangeWifiConfiguration);
