import React from 'react'
import PropTypes from 'prop-types'
import { Badge, Card, CardBody, CardHeader, Table } from 'reactstrap'
import cn from 'classnames'

const Prices = ({ prices, isLoading }) => {

  return (
    <Card color="white">
      <CardHeader>
        <div className="card-header__left">
          <i className="fa fa-align-justify" /> Prices
        </div>
      </CardHeader>
      {!isLoading && (
        <CardBody>
          <Table>
            <thead>
              <tr>
                <th>Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {prices?.map(price => (
                <tr key={price._id}>
                  <td>{price.currency} {price.amount}</td>
                  <td>
                    <Badge
                      color={cn({
                        'success': price.status === 'active'
                      })}
                    >
                      {price.status}
                    </Badge>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      )}
    </Card>
  )
}

Prices.displayName = 'Prices';

Prices.propTypes = {
  prices: PropTypes.arrayOf(
    PropTypes.shape({
      stripePriceId: PropTypes.string,
      currency: PropTypes.string,
      amount: PropTypes.string,
      createdAt: PropTypes.string,
      validFrom: PropTypes.string,
      validUntil: PropTypes.string,
      isLatest: PropTypes.bool,
    })
  ),
  isLoading: PropTypes.bool,
}

export default Prices