import { call, put, takeLatest } from 'redux-saga/effects';

import { Statistics } from 'services/api';
import { callApi } from 'services/rest';

import {
  getDashboard,
  getDashboardSuccess,
  getDashboardFailure,
} from 'scenes/Dashboard/actions';

function* watchGetDashboard(_payload) {
  try {
    const { data } = yield call(callApi, Statistics.dashboard);
    yield put(getDashboardSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(getDashboardFailure());
  }
}

// It must be wrapped in a function, so execution will happen only on function call
export default () => takeLatest(getDashboard, watchGetDashboard);
