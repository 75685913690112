import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import actions from 'scenes/Subscriptions/components/Show/actions';

const INITIAL_STATE = {
  isLoading: false,
  data: null,
};

const showReducer = handleActions(
  {
    [actions.getSubscription]: state =>
      update(state, {
        isLoading: { $set: true },
        data: { $set: null },
      }),

    [actions.getSubscriptionSuccess]: (state, { payload }) => 
      update(state, {
        isLoading: { $set: false },
        data: { $set: payload },
      }),

    [actions.getSubscriptionFailure]: state =>
      update(state, {
        isLoading: { $set: false },
        data: { $set: null },
      }),

    [actions.resetSubscription]: state =>
      update(state, {
        isLoading: { $set: false },
        data: { $set: null },
      }),
  },
  INITIAL_STATE
);

export default showReducer;
