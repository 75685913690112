import { formatDate } from 'utils/timestampFormatter';
import React from 'react';
import { getFilter } from 'components/ListSelector';
import { Badge } from 'reactstrap';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';
import { formatNumberWithThousandSeparators } from 'utils';

const TRANSACTION_TYPES = {
  TournamentRefunded: 'TournamentRefunded',
  CommonPurchased: 'CommonPurchased',
  CommonManual: 'CommonManual',
  TournamentCreated: 'TournamentCreated',
};

const PADDLE_SANDBOX_URL = 'https://sandbox-vendors.paddle.com/transactions-v2/';
const PADDLE_URL = 'https://vendors.paddle.com/transactions-v2/';

export const getColumns = isPaddleSandbox => {
  const getAlignedCell = ({ value }) => (
    <div style={{ width: '100%', textAlign: 'center' }}>{value}</div>
  );

  const getTypeBadge = transactionType => {
    let color;

    switch (transactionType) {
      case TRANSACTION_TYPES.TournamentRefunded:
        color = 'info';
        break;
      case TRANSACTION_TYPES.CommonPurchased:
        color = 'success';
        break;
      case TRANSACTION_TYPES.CommonManual:
        color = 'warning';
        break;
      case TRANSACTION_TYPES.TournamentCreated:
        color = 'primary';
        break;
      default:
        color = 'danger';
    }

    return (
      <Badge color={color} className="text-dark">
        {transactionType}
      </Badge>
    );
  };

  const getMixedCell = ({ tournamentId, paymentTransactionId }) => {
    if (tournamentId) {
      return getAlignedCell({
        value: <Link to={`/private-tournaments/${tournamentId}`}>Go to Tournament</Link>,
      });
    } else if (paymentTransactionId) {
      const paddleLink = isPaddleSandbox ? PADDLE_SANDBOX_URL : PADDLE_URL;
      return getAlignedCell({
        value: (
          <a href={`${paddleLink}${paymentTransactionId}`} target="_blank " rel="noopener noreferrer">
            Go to Paddle
          </a>
        ),
      });
    } else return null;
  };

  const headerValues = [
    {
      Header: 'Date',
      accessor: 'createdAt',
      Cell: ({ value }) => getAlignedCell({ value: formatDate(value) }),
    },
    {
      Header: 'Transaction type',
      accessor: 'transactionType',
      filterable: true,
      Filter: getFilter(TRANSACTION_TYPES),
      Cell: ({ value }) => getAlignedCell({ value: getTypeBadge(value) }),
    },
    {
      Header: 'Balance before',
      accessor: 'balanceBefore',
      Cell: ({value}) => getAlignedCell({value: formatNumberWithThousandSeparators(value)}),
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: ({ value }) =>
        getAlignedCell({
          value: (
            <span
              className={cn({
                [styles.positive]: value > 0,
                [styles.zero]: value === 0,
                [styles.negative]: value < 0,
              })}
            >
              {formatNumberWithThousandSeparators(value)}
            </span>
          ),
        }),
    },
    {
      Header: 'Balance after',
      accessor: 'balanceAfter',
      Cell: ({value}) => getAlignedCell({value: formatNumberWithThousandSeparators(value)}),
    },
    {
      Header: 'Related',
      disableSortBy: true,
      Cell: ({ original }) => getMixedCell(original)
    },
  ];
  return headerValues;
};
