import { call, put, takeLatest } from 'redux-saga/effects';

import { Subscriptions } from 'services/api';
import { callApi } from 'services/rest';
import { browserHistory } from 'services/history';

import {
  getSubscription,
  getSubscriptionFailure,
  getSubscriptionSuccess,
} from 'scenes/Subscriptions/components/Show/actions';

function* watchGetSubscription({ payload: subscriptionId }) {
  try {
    const { data } = yield call(callApi, Subscriptions.show, subscriptionId);
    yield put(getSubscriptionSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(getSubscriptionFailure());
    browserHistory.push('/subscriptions/');
  }
}

export default () => takeLatest(getSubscription, watchGetSubscription);
