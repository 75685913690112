import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Show } from './components/Show';
import { List } from './components/List';

const Subscriptions = ({ match: { url } }) => {
  return (
    <Switch>
      <Route component={List} exact name="Subscriptions List" path={`${url}/`} />
      <Route component={Show} exact name="Subscription info" path={`${url}/:subscriptionId`} />
    </Switch>
  );
}

export default Subscriptions;
