import React from 'react'
import { Switch, Route } from 'react-router-dom';

import { List } from './components/List';
import { Create } from './components/Create';
import { Edit } from "./components/Edit"
import { Show } from './components/Show';

const Platforms = ({ match: { url } }) => {
  return (
    <Switch>
      <Route component={List} exact name="Platforms list" path={`${url}/`} />
      <Route
        component={Create}
        exact
        name="Create platform"
        path={`${url}/new`}
      />

      <Route
        component={Edit}
        exact
        name="Edit platform"
        path={`${url}/:platformId/edit`}
      />

      <Route
        component={Show}
        exact
        name="Platform info"
        path={`${url}/:platformId`}
      />
    </Switch>
  )
}

export default Platforms