import { createAction } from 'redux-actions';

export const updateHardware = createAction('UPDATE_HARDWARE_REQUEST');
export const updateHardwareFailure = createAction('UPDATE_HARDWARE_FAILURE');
export const updateHardwareSuccess = createAction('UPDATE_HARDWARE_SUCCESS');

export default {
  updateHardware,
  updateHardwareFailure,
  updateHardwareSuccess,
};
