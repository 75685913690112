import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

// Create saga middleware itself
const sagaMiddleware = createSagaMiddleware();

// Apply it to the store as a middleware, inside Redux-Devtools wrapper
// REVIEW: https://github.com/zalmoxisus/redux-devtools-extension#13-use-redux-devtools-extension-package-from-npm
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);

// Start the sagas
sagaMiddleware.run(rootSaga);

export default store;
