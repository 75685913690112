import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';

import styles from './styles.module.scss';

const Header = ({
  fromDate,
  toDate,
  onFromDateChange,
  onToDateChange,
  onExportDownloadButtonClick,
  onResetFiltersButtonClick,
  onRefreshButtonClick,
  hasDateFilter = false,
  showMonthYearPicker,
  createResourceButtons = [],
  customDownloadButtons,
  children,
  isAdmin,
  isLoading,
  isDownloading,
  customDeleteButtonLabel,
  isCustomDeleteDisabled,
  handleCustomDelete,
  isDownloadPossible,
  customButtons,
  quickFilters,
  quickFilter,
  downloadParams
}) => {
  return (
    <div className={styles.reactTableCustomHeader}>
      <Row>
        {hasDateFilter && !showMonthYearPicker && (
          <Col xs={6}>
            <>
              <div className={styles.dateInput}>
                <span className="text-muted text-uppercase fw-bold">
                  From
                </span>
                <DatePicker
                  selected={fromDate}
                  onChange={onFromDateChange}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  dateFormat="yyyy.MM.dd HH:mm"
                  placeholderText="éééé. hh. nn."
                  isClearable
                  disabled={isLoading}
                />
              </div>
              <div className={styles.dateInput}>
                <span className="text-muted text-uppercase fw-bold">
                  To
                </span>
                <DatePicker
                  selected={toDate}
                  onChange={onToDateChange}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  dateFormat="yyyy.MM.dd HH:mm"
                  placeholderText="éééé. hh. nn."
                  isClearable
                  disabled={isLoading}
                />
              </div>
            </>
          </Col>
        )}
        {hasDateFilter && showMonthYearPicker && (
          <Col xs={6}>
            <div className={styles.dateInput}>
              <span className="text-muted text-uppercase fw-bold">
                Filter
              </span>
              <DatePicker
                selected={fromDate}
                onChange={onFromDateChange}
                timeFormat="HH:mm"
                timeIntervals={5}
                dateFormat="yyyy.MM"
                placeholderText="éééé. hh."
                isClearable
                disabled={isLoading}
                showMonthYearPicker
              />
            </div>
          </Col>
        )}
        <Col xs={hasDateFilter ? 6 : 12} className={styles.buttons}>
          {children}
          {!!children && <span style={{ flexGrow: 1 }} />}
          {isAdmin && customDeleteButtonLabel && (
            <div className={styles.button}>
              <Button color="danger" onClick={handleCustomDelete} disabled={isLoading || isDownloading || isCustomDeleteDisabled}>
                <i className="cui-trash" />
              </Button>
            </div>
          )}
          {customButtons && customButtons.map((button, index) => (
            <div key={`customButton-${index}`} className={styles.button}>
              <Button color='primary' onClick={e => button.onClickAction(e)}>
                {button.name}
              </Button>
            </div>
          ))}
          {quickFilters && quickFilters.map((button, index) => (
            <div key={`customButton-${index}`} className={styles.button}>
              <Button color='primary' onClick={() => quickFilter(button.filterId, button.filterValue)}>
                {button.name}
              </Button>
            </div>
          ))}
          {createResourceButtons && createResourceButtons.map((button, index) => (
            isAdmin && <div key={`resourceButton-${index}`} className={styles.button}>
              <Link to={button.link}>
                <Button color={button.color || 'primary'}>
                  {button.value || 'Create resource'}
                </Button>
              </Link>
            </div>
          ))}
          {customDownloadButtons ? (
            customDownloadButtons.map(btn => (
              <div className={styles.button} key={btn.name}>
                <Button color="info" onClick={e => onExportDownloadButtonClick(e, btn.actionParams)} disabled={isLoading || isDownloading}>
                  {btn.name} <i className={isLoading || isDownloading ? "fa fa-spinner fa-spin fa-fw" : "cui-save"} />
                </Button>
              </div>
            ))
          ) : (
            isDownloadPossible && <div className={styles.button}>
              <Button color="info" onClick={e => onExportDownloadButtonClick(e, downloadParams)} disabled={isLoading || isDownloading}>
                <i className="cui-save" />
              </Button>
            </div>
          )}
          <div className={styles.button}>
            <Button color="warning" onClick={onResetFiltersButtonClick} disabled={isLoading}>
              Reset Filters
            </Button>
          </div>
          <div className={styles.button}>
            <Button color="success" onClick={onRefreshButtonClick} disabled={isLoading}>
              <i className="cui-reload" />
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}

Header.propTypes = {
  fromDate: PropTypes.instanceOf(Date),
  toDate: PropTypes.instanceOf(Date),
  onFromDateChange: PropTypes.func.isRequired,
  onToDateChange: PropTypes.func.isRequired,
  onExportDownloadButtonClick: PropTypes.func.isRequired,
  onResetFiltersButtonClick: PropTypes.func.isRequired,
  onRefreshButtonClick: PropTypes.func.isRequired,
  hasDateFilter: PropTypes.bool.isRequired,
  showMonthYearPicker: PropTypes.bool,
  createResourceButtons: PropTypes.array,
  isAdmin: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDownloading: PropTypes.bool,
  customDownloadButtons: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      action: PropTypes.func,
    })
  ),
  customDeleteButtonLabel: PropTypes.string,
  isCustomDeleteDisabled: PropTypes.bool,
  handleCustomDelete: PropTypes.func,
  customButtons: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClickAction: PropTypes.func,
    })
  )
};

export default Header;
