export const boardUpdateProgress = 'board-update-progress';
export const boards = 'boards';
export const feedbacks = 'feedbacks';
export const privateTournaments = 'private-tournaments';
export const tournaments = 'tournaments';
export const boardRankings = 'board-ranking';
export const resourceLogs = 'resource-logs';
export const serviceAccounts = 'service-accounts';
export const users = 'users';
export const tokenTransactions = 'token-transactions';
export const tokenPurchases = 'token-purchases'
export const scoreCorrectionStatistics = 'score-correction-statistics'
export const scoreCorrections = 'score-corrections'
