import { handleActions } from 'redux-actions';

import { actions as listActions } from 'scenes/OnlineBoards/components/List';

const INITIAL_STATE = [];

const onlineBoardsReducer = handleActions(
  {
    [listActions.getOnlineBoardsSuccess]: (state, { payload: {data} }) => {
      return data;
    },
  },
  INITIAL_STATE
);

export default onlineBoardsReducer;
