import { handleActions } from 'redux-actions';

import { actions } from 'scenes/Manufacturers/components/Show';

const INITIAL_STATE = {
  isLoading: true,
};

const assignedBoards = handleActions(
  {
    [actions.getManufacturerBoards]: (state) => ({
      ...state,
      isLoading: true,
    }),

    [actions.getManufacturerBoardsSuccess]: () => ({
      isLoading: false,
    }),

    [actions.getManufacturerBoardsFailure]: () => ({
      isLoading: false,
    }),
  },
  INITIAL_STATE
);

export default assignedBoards;
