export enum PrivateTournamentBackgroundImagesEnum {
  tournamentBackground1 = 'tournamentBackground1',
  tournamentBackground2 = 'tournamentBackground2',
  tournamentBackground3 = 'tournamentBackground3',
  tournamentBackground4 = 'tournamentBackground4',
  tournamentBackground5 = 'tournamentBackground5',
  publicTournamentBackground1 = 'publicTournamentBackground1',
  publicTournamentBackground2 = 'publicTournamentBackground2',
  publicTournamentBackground3 = 'publicTournamentBackground3',
  publicTournamentBackground4 = 'publicTournamentBackground4',
  publicTournamentBackground5 = 'publicTournamentBackground5',
  publicTournamentBackground6 = 'publicTournamentBackground6',
  publicTournamentBackground7 = 'publicTournamentBackground7',
  publicTournamentBackground8 = 'publicTournamentBackground8',
  publicTournamentBackground9 = 'publicTournamentBackground9',
  publicTournamentBackground10 = 'publicTournamentBackground10',
}