import React from 'react'
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash-es';

import { NOT_DELETABLE_STATES } from 'constants/Tournament';

import { Checkbox, CheckboxController } from 'components/Checkbox';

import { getFilter } from 'components/ListSelector';
import { TournamentState } from 'enums';

export const getColumns = ({ columns, url, selectedKeys, selectableElements, handleSelectOne, handleClearAll, handleSelectAll, isDeletable }) => {

  const getAlignedCell = ({ value }) => (
    <div style={{ width: '100%', textAlign: 'center' }}>{value}</div>
  );

  const headerValues = []

  if ( isDeletable ) {
    headerValues.push({
      Header: () => (
        <CheckboxController
          onClear={handleClearAll}
          onSelectAll={handleSelectAll}
          isAllSelected={selectedKeys.length === selectableElements.length}
          isNoneSelected={isEmpty(selectedKeys)}
        />
      ), // No header
      accessor: '_id',
      sortable: false,
      Cell: ({ value, original }) => (
        <Checkbox
          checked={selectedKeys.includes(value)}
          disabled={NOT_DELETABLE_STATES.includes(original.state)}
          onChange={_e => handleSelectOne(value)}
        />
      ),
      maxWidth: 40,
    })
  }
  
  if ( columns.includes('name') ) {
    headerValues.push({
      Header: 'Name',
      accessor: 'name',
      filterable: true,
      Cell: ({ value, original }) => <Link to={`${url ?? '/tournaments'}/${original._id}`}>{value}</Link>,
    })
  }

  if ( columns.includes('organiser') ) {
    headerValues.push({
      Header: 'Organiser',
      accessor: 'organiser.email',
      filterable: true,
      Cell: ({ value, original }) => <Link to={`/users/${original.organiser.organiserId}`}>{value}</Link>,
    })
  }

  if ( columns.includes('state') ) {
    headerValues.push({
      Header: 'State',
      accessor: 'state',
      filterable: true,
      Filter: getFilter(TournamentState),
      Cell: props => getAlignedCell(props),
    })
  }

  if ( columns.includes('currentTokenCost') ) {
    headerValues.push({
      Header: 'Current token cost',
      accessor: 'currentTokenCost',
      Cell: props => getAlignedCell(props),
    })
  }

  if ( columns.includes('tournamentStartDate') ) {
    headerValues.push({
      Header: 'Tournament start date',
      accessor: 'tournamentStartDate',
      Cell: props => getAlignedCell(props),
    })
  }

  if ( columns.includes('createdAt') ) {
    headerValues.push({
      Header: 'Creation date',
      accessor: 'createdAt',
      Cell: props => getAlignedCell(props),
    })
  }

  if ( columns.includes('minParticipants') ) {
    headerValues.push({
      Header: 'Min. participants',
      accessor: 'minParticipants',
      Cell: props => getAlignedCell(props),
    })
  }

  if ( columns.includes('maxParticipants') ) {
    headerValues.push({
      Header: 'Max. participants',
      accessor: 'maxParticipants',
      Cell: props => getAlignedCell(props),
      filterable: true,
    })
  }

  if ( columns.includes('numberOfParticipants') ) {
    headerValues.push({
      Header: 'Registered',
      accessor: 'numberOfParticipants',
      Cell: props => getAlignedCell(props),
    })
  }

  if ( columns.includes('minAverage') ) {
    headerValues.push({
      Header: 'Min. avg.',
      accessor: 'minAverage',
      Cell: props => getAlignedCell(props),
      filterable: true,
    })
  }

  if ( columns.includes('maxAverage') ) {
    headerValues.push({
      Header: 'Max. avg.',
      accessor: 'maxAverage',
      Cell: props => getAlignedCell(props),
      filterable: true,
    })
  }

  if ( columns.includes('maxPrizePool') ) {
    headerValues.push({
      Header: 'Prize pool',
      accessor: 'maxPrizePool',
      Cell: props => getAlignedCell(props),
    })
  }

  if ( columns.includes('config') ) {
    headerValues.push({
      Header: 'Config',
      accessor: 'gameConfig',
      Cell: props => getAlignedCell(props),
      filterable: true,
      minWidth: 230,
    })
  }

  if ( columns.includes('isScoreCorrectionAllowed') ) {
    headerValues.push({
      Header: 'Score correction',
      accessor: 'isScoreCorrectionAllowed',
      filterable: true,
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'All'}
        >
          <option value="">All</option>
          <option value="true">Allowed</option>
          <option value="false">Disabled</option>
        </select>
      ),
      Cell: ({ value }) => getAlignedCell({ value: value ? 'Allowed' : 'Disabled' }),
    })
  }

  return headerValues
}