import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getPreparedFiltersForSelectedFields } from 'utils';

import * as boardActions from 'scenes/Boards/components/List/actions';
import { EntityTable } from 'components/EntityTable';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import cn from 'classnames';
import { boardUpdateProgress } from 'constants/Resources';

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
    filterable: true,
  },
  {
    Header: 'Serial Number',
    accessor: 'serialNumber',
    filterable: true,
    Cell: props => {
      return <Link to={`/boards/${props.row._original.id}`}> {props.value} </Link>;
    },
  },
  {
    Header: 'SBC Type',
    accessor: 'isFakeSbc',
    filterable: true,
    Cell: props => (
      <Badge
        color={cn({
          secondary: props.value === true,
          info: props.value === false,
        })}
      >
        {props.value ? 'FakeSBC' : 'SBC'}
      </Badge>
    ),
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'All'}
      >
        <option value="All">All</option>
        <option value="true">FakeSBC</option>
        <option value="false">SBC</option>
      </select>
    ),
  },
  {
    Header: 'Status',
    accessor: 'status',
    filterable: true,
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'All'}
      >
        <option value="All">All</option>
        <option value="Attached">Attached</option>
        <option value="ReadyToActivate">ReadyToActivate</option>
        <option value="InStock">InStock</option>
        <option value="Detached">Detached</option>
        <option value="Suspended">Suspended</option>
      </select>
    ),
  },
  {
    Header: 'Only Stable Firmware',
    accessor: 'firmware.onlyStableFirmware',
    filterable: true,
    Cell: props => (
      <Badge
        color={cn({
          success: props.value === true,
          warning: props.value === false,
        })}
      >
        {props.value ? 'Stable' : 'Unstable'}
      </Badge>
    ),
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'All'}
      >
        <option value="All">All</option>
        <option value="true">Stable</option>
        <option value="false">Unstable</option>
      </select>
    ),
  },
  {
    Header: 'Last Sent Firmware',
    accessor: 'firmware.lastSentVersionNumber',
    filterable: true,
    sortable: true,
    Cell: props => (
      <Badge
        color={cn({
          info: props.value !== null,
          light: props.value === null,
        })}
      >
        {props.value}
      </Badge>
    ),
  },
  {
    Header: 'Auto Update',
    accessor: 'firmware.isAutoUpdateEnabled',
    filterable: true,
    Cell: props => (
      <Badge
        color={cn({
          success: props.value === true,
          warning: props.value === false,
        })}
      >
        {props.value ? 'Auto Update' : 'Suspended'}
      </Badge>
    ),
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'All'}
      >
        <option value="All">All</option>
        <option value="true">Auto Update</option>
        <option value="false">Suspended</option>
      </select>
    ),
  },
  {
    Header: 'Last Reported Firmware',
    accessor: 'firmware.lastReportedVersionNumber',
    filterable: true,
    sortable: true,
    Cell: props => (
      <Badge
        color={cn({
          info: props.value !== null,
          secondary: props.value === null,
        })}
      >
        {props.value}
      </Badge>
    ),
  },
  {
    Header: 'Last Error Message',
    accessor: 'firmware.lastErrorMessage',
    filterable: true,
    sortable: true,
    Cell: props => props.value ? props.value : '-'
  },
];

const defaultSorted = [
  {
    id: 'name',
    desc: false,
  },
];

class List extends Component {
  constructor(props) {
    super(props);

    this.selectColumns = [
      'isFakeSbc',
      'status',
      'firmware.onlyStableFirmware',
      'firmware.isAutoUpdateEnabled',
    ];
  }

  handleFetchData = ({ filter, ...rest }) => {
    const clonedFilter = getPreparedFiltersForSelectedFields(filter, this.selectColumns);

    this.props.dispatch(boardActions.getBoards({ filter: clonedFilter, ...rest }));
  };

  render() {
    const { data, page, pageSize, isLoading, totalCount, isDownloading } = this.props;
    const boards = Object.values(data);

    return (
      <div>
        <EntityTable
          resource={boardUpdateProgress}
          columns={columns}
          selectColumns={this.selectColumns}
          data={boards}
          page={page}
          pageSize={pageSize}
          totalCount={totalCount}
          defaultSorted={defaultSorted}
          isLoading={isLoading}
          onFetchData={this.handleFetchData}
          isDownloading={isDownloading}
        />
      </div>
    );
  }
}

List.displayName = 'Boards.List';

const mapStateToProps = state => ({
  data: state.data.boards,
  page: state.scenes.boards.list.page,
  pageSize: state.scenes.boards.list.pageSize,
  totalCount: state.scenes.boards.list.totalCount,
  isLoading: state.scenes.boards.list.isLoading,
  isDownloading: state.scenes.boards.list.isDownloadingUpdateProgress,
});

export default connect(mapStateToProps)(List);
