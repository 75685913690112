import React from 'react';
import formatLabel from 'utils/formatLabel';
export function Slider({ label, helperText, labelClass = 'col-sm-4', valueClass = 'col-sm-8', isLabelFormatted = true, value, min, max, name, onChange, }) {
    return (React.createElement("dl", { className: "row" },
        React.createElement("dt", { className: labelClass }, isLabelFormatted ? formatLabel(label) : label),
        React.createElement("dd", { className: valueClass, id: `${name}-container` },
            React.createElement("input", { name: name, type: "range", min: min, max: max, value: value, onChange: onChange }),
            helperText && helperText)));
}
;
