import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from 'recharts';
import { Card, CardBody, Col, Row } from 'reactstrap';
import cn from 'classnames';

import { formatNumberWithThousandSeparators } from 'utils';
import { tooltipFormatter } from 'utils/tooltipFormatter';
import styles from './styles.module.scss';

const RADIAN = Math.PI / 180;
const CHART_COLORS = ['#3B5998', '#20A8DA', '#79cae8', '#a5dcf0'];
const LABELS = {
  singleOut: 'Single Out',
  doubleOut: 'Double Out',
  masterOut: 'Master Out',
  raceTo: 'Race To',
  bestOf: 'Best Of',
};

class X01Stats extends Component {
  constructor(props) {
    super(props);

    this.state = { isAnimationActive: true };
  }

  componentDidMount() {
    setTimeout(() => this.setState({ isAnimationActive: false }), 1900);
  }

  get dataForCallouts() {
    const {
      stats: { singleOut, doubleOut, masterOut, raceTo, bestOf },
    } = this.props;

    return [
      { key: 'singleOut', value: singleOut },
      { key: 'doubleOut', value: doubleOut },
      { key: 'masterOut', value: masterOut },
      { key: 'raceTo', value: raceTo },
      { key: 'bestOf', value: bestOf },
    ];
  }

  get gamesByPoint() {
    return [
      { name: 'Custom', value: this.props.stats['custom'] },
      { name: 501, value: this.props.stats['501'] },
      { name: 301, value: this.props.stats['301'] },
      { name: 170, value: this.props.stats['170'] },
    ];
  }

  shouldShowPercentage = key =>
    ['singleOut', 'doubleOut', 'masterOut'].some(visibility => visibility === key);

  getCalloutValue = (key, value) => {
    const { stats, stats: { total }  } = this.props || {};

    let percentage;

    if (['singleOut', 'doubleOut', 'masterOut'].includes(key)) {
      percentage = ((stats[key] / total) * 100).toFixed(0);
    }

    return (
      <div className={styles.calloutValueContainer}>
        <span className={styles.calloutValue}>
          { formatNumberWithThousandSeparators(value) }
        </span>
        {this.shouldShowPercentage(key) && (
          <span className={styles.calloutValuePercentage}>
            {percentage + '%'}
          </span>
        )}
      </div>
    );
  };

  get callouts() {
    return this.dataForCallouts.map(({ key, value }) => (
      <Col xs={6} key={'x01stats-callout_' + key}>
        <div className={cn('callout ')}>
          {this.getCalloutValue(key, value)}
          <small className="text-muted text-uppercase fw-bold">
            {LABELS[key]}
          </small>
        </div>
      </Col>
    ));
  }

  renderChartLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const innerLabelRadius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const innerLabelX = cx + innerLabelRadius * Math.cos(-midAngle * RADIAN);
    const innerLabelY = cy + innerLabelRadius * Math.sin(-midAngle * RADIAN);

    const outerLabelRadius = innerRadius + (outerRadius - innerRadius) * 1.1;
    const outerLabelX = cx + outerLabelRadius * Math.cos(-midAngle * RADIAN);
    const outerLabelY = cy + outerLabelRadius * Math.sin(-midAngle * RADIAN);

    return (
      <Fragment>
        <text
          x={innerLabelX}
          y={innerLabelY}
          fill="white"
          textAnchor={innerLabelX > cx ? 'start' : 'end'}
          dominantBaseline="central"
        >
          {`${(percent * 100).toFixed(0)}%`}
        </text>
        <text
          x={outerLabelX}
          y={outerLabelY}
          fill="black"
          textAnchor={outerLabelX > cx ? 'start' : 'end'}
          dominantBaseline="central"
        >
          {this.gamesByPoint[index].name}
        </text>
      </Fragment>
    );
  };

  get renderChart() {
    return (
      <ResponsiveContainer width="100%" height={180}>
        <PieChart width={400} height={400}>
          <Pie
            data={this.gamesByPoint}
            labelLine={false}
            label={this.renderChartLabel}
            outerRadius={60}
            isAnimationActive={this.state.isAnimationActive}
            fill="#8884d8"
            dataKey="value"
          >
            {this.gamesByPoint.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={CHART_COLORS[index % CHART_COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip formatter={(value) => tooltipFormatter(value, this.gamesByPoint)} />
        </PieChart>
      </ResponsiveContainer>
    );
  }

  render() {
    const {
      stats: { total },
    } = this.props || {};

    return (
      <Card color="white">
        <CardBody>
          <Fragment>
            <Row>
              <Col xs={8}>
                <strong className={cn('text-value h2', styles.textValue)}>
                  { formatNumberWithThousandSeparators(total) }
                </strong>
                <span className="text-muted text-uppercase fw-bold">
                  Total Games
                </span>
              </Col>
              <Col xs={4}>
                <div className={cn('text-muted text-end', styles.cardHeader)}>
                  X01
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Row>{this.callouts}</Row>
              </Col>
              <Col xs={12} md={6}>
                {this.renderChart}
              </Col>
            </Row>
          </Fragment>
        </CardBody>
      </Card>
    );
  }
}

X01Stats.propTypes = {
  stats: PropTypes.object.isRequired,
};

export default X01Stats;
