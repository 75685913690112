import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha/lib/index';

import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from 'reactstrap';

import { CAPTCHA_REQUIRED, LOGIN_FAILED } from 'constants/ApiErrors';
import { LOGIN_FAILED_ERRORS } from 'constants/LoginErrors';
import { actions as sessionActions } from 'services/session';

import styles from './styles.module.scss';

class Login extends Component {
  constructor(props) {
    super(props);
    this.recaptchaTagRef = React.createRef();

    this.state = {
      form: {
        email: '',
        password: '',
      },
      isRecaptchaRequired: false,
      isRecaptchaLoaded: false,
      recaptchaToken: false,
      isRecaptchaTokenSent: false,
    };
  }

  componentDidMount() {
    const { loginError } = this.props;
    if (loginError === CAPTCHA_REQUIRED) {
      this.setState({ isRecaptchaRequired: true });
    }
  }

  componentDidUpdate(prevProps, _prevState) {
    const { loginError, isLoginPending } = this.props;
    const { isRecaptchaRequired, isRecaptchaLoaded, recaptchaToken } = this.state;

    if (
      !isRecaptchaRequired &&
      loginError === CAPTCHA_REQUIRED
    ) {
      this.setState({ isRecaptchaRequired: true });
    } else if (
      prevProps.isLoginPending &&
      !isLoginPending &&
      isRecaptchaRequired &&
      isRecaptchaLoaded &&
      recaptchaToken
    ) {
      this.recaptchaReset();
    }
  }

  getValidationSchema = () =>
    Yup.object().shape({
      email: Yup.string().required('email is required'),
      password: Yup.string().required('password is required'),
    });

  handleLoginSubmit = values => {
    const { isRecaptchaRequired, recaptchaToken } = this.state;

    if (isRecaptchaRequired && recaptchaToken) {
      values.token = this.recaptchaTagRef.current.getValue();
    }
    this.props.dispatch(sessionActions.logIn(values));
  };


  recaptchaAsyncScriptOnLoad = () => {
    this.setState({ isRecaptchaLoaded: true });
  };

  recaptchaOnChange = (e) => {
    this.setState({ recaptchaToken: e });
  };

  recaptchaReset = () => {
    try {
      this.recaptchaTagRef.current.reset();
    } catch (e) {
      console.error(e);
    }
    this.setState({ recaptchaToken: null });
  };

  render() {
    const { form, isRecaptchaRequired } = this.state;
    const { loginError, siteKey } = this.props;

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4" color="light">
                  <CardBody>
                    <Formik
                      initialValues={form}
                      validationSchema={this.getValidationSchema}
                      onSubmit={this.handleLoginSubmit}
                    >
                      {({
                        errors,
                        touched,
                        values,
                        handleChange,
                        handleSubmit,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <h1>Login</h1>
                          <p className="text-muted">
                            Sign in to Scolia management site!
                          </p>
                          <InputGroup className="mb-3">
                            <InputGroupText>
                              <i className="icon-user" />
                            </InputGroupText>
                            <Input
                              name="email"
                              placeholder="Email"
                              type="text"
                              value={values.email}
                              onChange={handleChange}
                            />
                            {touched.email &&
                              errors.email && (
                                <FormFeedback>{errors.email}</FormFeedback>
                              )}
                          </InputGroup>
                          <InputGroup className="mb-4">
                            <InputGroupText>
                              <i className="icon-lock" />
                            </InputGroupText>
                            <Input
                              name="password"
                              placeholder="Password"
                              type="password"
                              value={values.password}
                              onChange={handleChange}
                            />
                            {touched.password &&
                              errors.password && (
                                <FormFeedback>{errors.password}</FormFeedback>
                              )}

                          </InputGroup>
                          {isRecaptchaRequired && (
                            <ReCAPTCHA
                              ref={this.recaptchaTagRef}
                              sitekey={siteKey}
                              onChange={this.recaptchaOnChange}
                              asyncScriptOnLoad={this.recaptchaAsyncScriptOnLoad}
                              hl={'en-GB'}
                            />
                          )}
                          {loginError && (
                            <div className={styles.formError}>{LOGIN_FAILED_ERRORS[loginError] || LOGIN_FAILED_ERRORS[LOGIN_FAILED]}</div>
                          )}
                          <Row>
                            <Col xs="6">
                              <Button type="submit" color="primary" className="px-4">
                                Login
                              </Button>
                            </Col>
                          </Row>
                        </form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loginError: state.scenes.login.loginForm.error,
  isLoginPending: state.scenes.login.isLoginPending,
  siteKey: state.configuration.siteKey,
});

export default connect(
  mapStateToProps,
  null
)(Login);
