import { put } from 'redux-saga/effects';
import {
  changePrivateTournamentState,
  updateParticipants,
  updatePrivateTournamentMatch,
  updateResults,
} from '../../actions';

const TOURNAMENT_EVENT_TYPES = {
  matchUpdate: 'matchUpdate',
  participantsUpdate: 'participantsUpdate',
  tournamentStateChange: 'tournamentStateChange',
  resultsUpdate: 'resultsUpdate',
};

export default function* tournamentSaga({ data: { eventType, ...data } }) {
  switch (eventType) {
    case TOURNAMENT_EVENT_TYPES.matchUpdate:
      yield put(updatePrivateTournamentMatch(data));
      break;
    case TOURNAMENT_EVENT_TYPES.tournamentStateChange:
      yield put(
        changePrivateTournamentState({
          changedState: data.state,
          changedStages: data?.stages,
        })
      );
      break;
    case TOURNAMENT_EVENT_TYPES.participantsUpdate:
      yield put(updateParticipants(data));
      break;
    case TOURNAMENT_EVENT_TYPES.resultsUpdate:
      yield put(updateResults(data));
      break;
    default:
      console.error('Unprocessable event type on tournament channel: ' + eventType);
      break;
  }
}
