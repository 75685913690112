import { get, patch, post, del } from 'services/rest';

const url = '/platform';

const Platforms = {
  index: queryParams => get(url, queryParams),
  create: platform => post(url, platform),
  delete: platformId => del(`${url}/${platformId}`),
  show: platformId => get(`${url}/${platformId}`),
  update: (platformId, payload) => patch(`${url}/${platformId}`, payload),
};

export default Platforms;
