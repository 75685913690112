import { createAction } from 'redux-actions';

export const getManufacturers = createAction('MANUFACTURERS_GET_REQUEST', (isLoading = true) => ({
  isLoading,
}));
export const getManufacturersFailure = createAction('MANUFACTURERS_GET_FAILURE');
export const getManufacturersSuccess = createAction('MANUFACTURERS_GET_SUCCESS');

export default {
  getManufacturers,
  getManufacturersFailure,
  getManufacturersSuccess,
};
