import { handleActions } from 'redux-actions';

import { actions as listActions } from 'scenes/ResourceLogs/components/List';

const INITIAL_STATE = [];

const resourceLogsReducer = handleActions(
  {
    [listActions.getResourceLogsSuccess]: (state, { payload: { data } }) => {
      return data;
    },
  },
  INITIAL_STATE
);

export default resourceLogsReducer;
