import { call, put, takeLatest } from 'redux-saga/effects';

import { Tournaments } from 'services/api';

import { browserHistory } from 'services/history';
import { callApi } from 'services/rest';
import { notifyError, notifySuccess } from 'services/notification/actions';

import {
  importTournaments,
  importTournamentsFailure,
  importTournamentsSuccess,
} from 'scenes/Tournaments/components/Import/actions';

function* watchImportTournaments({ payload }) {
  try {
    yield call(callApi, Tournaments.import, {tournaments: payload});

    yield put(importTournamentsSuccess());
    yield put(notifySuccess(`Tournaments were created successfully.`))
    browserHistory.push('/tournaments');
  } catch (e) {
    if (!e.isFetch) {
      throw e;
    }
    yield put(importTournamentsFailure(e.data.error));
    yield put(notifyError(`Failed to create tournaments.`))

  }
}

export default () => takeLatest(importTournaments, watchImportTournaments);
