import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'

import { TournamentForm } from 'scenes/Tournaments/components/TournamentForm'

import { getTournament } from '../Show/actions';
import actions from './actions'

const Create = ({ match }) => {
  const dispatch = useDispatch();

  const tournament = useSelector(state => state.scenes.tournaments.show.tournament);
  const userId = useSelector(state => state.session._id);

  const [isReady, setReady] = useState(false);

  useEffect(() => {
    if(match?.params?.tournamentId && Object.keys(tournament)?.length === 0){
      const tournamentId = match?.params?.tournamentId;
      dispatch(getTournament(tournamentId));
    } else {
      setReady(true)
    }

    return () => dispatch(actions.resetCreateMultipleTournamentsState());
  }, [ dispatch, tournament, match ])

  const handleFormSubmit = data => {
    dispatch(actions.createTournament({data}))
  };

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" lg={{ size: 10, offset: 1 }}>
          <Card color="white">
            <CardHeader>
              <div className="card-header__left">
                <i className="fa fa-align-justify" /> New Tournament
              </div>
            </CardHeader>
            <CardBody>
              {isReady && <TournamentForm
                onSubmit={handleFormSubmit}
                tournament={(match?.params?.tournamentId && Object.keys(tournament)?.length > 0) ? tournament : null}
                userId={userId}
              />}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

Create.displayName = 'Tournaments.Create';

export default Create;
