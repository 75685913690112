import { all } from 'redux-saga/effects';

import watchGetUser from './watchers/GetUser';
import watchGetUserBoards from './watchers/GetUserBoards';
import watchGetUserGameHistory from './watchers/GetUserGameHistory';
import watchGetUserTokenHistory from './watchers/GetUserTokenHistory';

export default function* showUserSaga() {
  yield all([
    watchGetUser(),
    watchGetUserBoards(),
    watchGetUserGameHistory(),
    watchGetUserTokenHistory(),
  ]);
}
