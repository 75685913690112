export enum SButtonVariantOptionsEnum {
    Primary = 'Primary',
    SecondaryGray = 'SecondaryGray',
    SecondaryColor = 'SecondaryColor',
    TertiaryGray = 'TertiaryGray',
    TertiaryColor = 'TertiaryColor',
    LinkGray = 'LinkGray',
    LinkColor = 'LinkColor',
    PrimaryOnBrand = 'PrimaryOnBrand',
    ErrorPrimary = 'ErrorPrimary',
    ErrorSecondary = 'ErrorSecondary',
    ErrorTertiary = 'ErrorTertiary',
    ErrorLink = 'ErrorLink',
}