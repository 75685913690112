import { createAction } from 'redux-actions';

export const getServiceAccount = createAction(
  'SERVICE_ACCOUNT_GET_REQUEST',
  (serviceAccountId, queryParams, isLoading = true) => ({
    serviceAccountId,
    queryParams,
    isLoading,
  })
);

export const getServiceAccountFailure = createAction(
  'SERVICE_ACCOUNT_GET_FAILURE'
);
export const getServiceAccountSuccess = createAction(
  'SERVICE_ACCOUNT_GET_SUCCESS'
);

export const getServiceAccountActivity = createAction(
  'SERVICE_ACCOUNT_ACTIVITY_GET_REQUEST',
  (serviceAccountId, minThrows, minDay, from, to, isLoading = true) => ({
    serviceAccountId,
    minThrows,
    minDay,
    from,
    to,
    isLoading,
  })
);

export const getServiceAccountActivityFailure = createAction(
  'SERVICE_ACCOUNT_ACTIVITY_GET_FAILURE'
);
export const getServiceAccountActivitySuccess = createAction(
  'SERVICE_ACCOUNT_ACTIVITY_GET_SUCCESS'
);


export default {
  getServiceAccount,
  getServiceAccountFailure,
  getServiceAccountSuccess,
  getServiceAccountActivity,
  getServiceAccountActivityFailure,
  getServiceAccountActivitySuccess
};
