import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { GameTypeEnum, GameTypeLabelEnum, PlayerCardEnum, PlayerCardLabelEnum } from 'enums';
const StatisticsTypesToDisplay = [
    PlayerCardEnum.PlayerCard,
    GameTypeEnum.X01,
    GameTypeEnum.AroundTheClock,
    GameTypeEnum.Bobs27,
    GameTypeEnum.Cricket,
    // GameTypeEnum.RandomCricket,
    GameTypeEnum.Shanghai,
];
const StatisticsTabLabel = { ...GameTypeLabelEnum, ...PlayerCardLabelEnum };
export const StatisticsTabs = ({ handleGameTypeSelect }) => {
    return (React.createElement(ButtonToolbar, { className: "mb-3" }, StatisticsTypesToDisplay.map(statisticsType => (React.createElement(Button, { key: statisticsType, name: statisticsType, onClick: () => handleGameTypeSelect(statisticsType) }, StatisticsTabLabel[statisticsType])))));
};
