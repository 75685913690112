import React, { PureComponent } from 'react';

import styles from './styles.module.scss';

export class Skeleton extends PureComponent {
  render() {
    return (
      <article className={styles.skeleton}>
        <i className="fa fa-2x fa-spinner fa-spin fa-fw" />
      </article>
    );
  }
}

export default Skeleton;
