import { GameTypeEnum, GameTypeLabelEnum } from "enums";

export const Straight = 'Straight';
export const Double = 'Double';
export const Master = 'Master';

export const GAME_MODE_OPTIONS = [
  { value: 'Standard', label: 'Standard' },
  { value: 'AllowNegativeScore', label: 'Allow Negative Score' }
];

export const BOBS27_GAME_MODE_OPTIONS = GAME_MODE_OPTIONS.reduce((acc, option) => {
  acc[option.value] = option.label;
  return acc;
}, {});

const formatAroundTheClockOrderType = (type) => {
  switch (type) {
    case '1to20':
      return type[0] + ' ' + type.substring(1, 3) + ' ' + type.substring(3, 5);
    case '20to1':
      return type.substring(0, 2) + ' ' + type.substring(2, 4) + ' ' + type[4];
    default:
      return type;
  }
};

const x01Formatter = (
  { startScore, sets, legs, scoreType, gameOutType, gameInType, isOnlineGame },
  boardCode
) => {
  scoreType = scoreType.replace(/^[a-z]|[A-Z]/g, (v, i) =>
    i === 0 ? v.toUpperCase() : ' ' + v.toLowerCase()
  );

  const legsLabel = (!legs || legs < 2) ? `leg` : `legs`;

  return `${startScore} ∙${isOnlineGame ? ' Online ∙' : ''
    } ${scoreType} ${(sets && sets > 1) ? `${sets} sets, ${legs ? legs : 1} ${legsLabel}` : `${legs ? legs : 1} ${legsLabel}`} ∙ ${gameOutType[0]
    }-Out${gameInType === Double ? ` ∙ D-In` : ''
    }${boardCode ? ' ∙ ' + boardCode : ''
    }`;
};

const aroundTheClockFormatter = ({ multiplierType, targetOrderType }, showType) => {
  return `${showType ? "Around the Clock ∙ " : ""}${multiplierType === 'Triples' ? 'Trebles' : multiplierType
    } ∙ ${formatAroundTheClockOrderType(targetOrderType)}`;
};

const bobs27Formatter = ({ gameMode }, showType) => {
  return `${showType ? "Bob's 27 ∙ " : ""}${BOBS27_GAME_MODE_OPTIONS[gameMode]}`;
};

const cricketFormatter = ({ gameMode, isOnlineGame, scoreType, sets, legs, isRandom, isHiddenSectors }, showType) => {
  if (scoreType) {
    scoreType = scoreType.replace(/^[a-z]|[A-Z]/g, (v, i) =>
      i === 0 ? v.toUpperCase() : ' ' + v.toLowerCase()
    )
  }
  let legsLabel = (!legs || legs < 2) ? `leg` : `legs`;
  return `${showType ? `${isRandom ? GameTypeLabelEnum.RandomCricket : GameTypeLabelEnum.Cricket} ∙ ` : ""
    }${isRandom && isHiddenSectors ? 'Hidden' : ``
    }${isOnlineGame ? 'Online ∙ ' : ``
    }${scoreType ? `${scoreType}` : 'Race to'
    }${sets && sets > 1
      ? ` ${sets} sets, ${legs ? legs : 1} ${legsLabel}`
      : ` ${legs ? legs : 1} ${legsLabel}`} ∙ ${gameMode === 'CutThroat' ? 'Cut-Throat' : gameMode}`;
};

const shanghaiFormatter = ({ gameMode }, showType) => {
  return `${showType ? "Shanghai ∙ " : ""}${gameMode}`;
}

export const getFormattedConfig = (gameType, configuration, boardCode, showType = true) => {

  if (configuration.hasOwnProperty('nextGame')) {
    gameType = configuration.nextGame.gameType;
    configuration = configuration.nextGame.configuration;
  }

  switch (gameType) {
    case GameTypeEnum.X01:
      return x01Formatter(configuration, boardCode);
    case GameTypeEnum.AroundTheClock:
      return aroundTheClockFormatter(configuration, showType);
    case GameTypeEnum.Bobs27:
      return bobs27Formatter(configuration, showType);
    case GameTypeEnum.Cricket:
      return cricketFormatter(configuration, showType);
    case GameTypeEnum.RandomCricket:
      return cricketFormatter(configuration, showType);
    case GameTypeEnum.Shanghai:
      return shanghaiFormatter(configuration, showType);
    default:
      throw new Error(`Unhandled game type: ${gameType}`);
  }
};

export default getFormattedConfig