import { call, takeLatest, select } from 'redux-saga/effects';

import { Socket, actions as wsActions } from 'services/ws';

import { BoardState } from 'constants/BoardState';

import { boardCalibrate } from 'scenes/Boards/components/Show/actions';
import { getBoardState } from 'scenes/Boards/components/Show/selectors'

function* watchBoardCalibrate({ payload: { boardId } }) {
  const payload = wsActions.Management.recalibrate({
    boardId
  });
  try {
    const boardStatus = yield select(state => getBoardState(state, boardId));
    if (boardStatus !== BoardState.Calibrating.type) {
      yield call([Socket, Socket.send], JSON.stringify(payload));
    }

  } catch (e) {
    if (!e.isFetch) {
      throw e;
    }
  }
}

export default () => takeLatest(boardCalibrate, watchBoardCalibrate);
