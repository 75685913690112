import { call, put, takeLatest } from 'redux-saga/effects';

import { Hardwares } from 'services/api';

import { browserHistory } from 'services/history';
import { callApi } from 'services/rest';

import {
  updateHardware,
  updateHardwareFailure,
  updateHardwareSuccess,
} from 'scenes/Hardwares/components/Edit/actions';

function* watchUpdateHardware({ payload: { hardwareId, description, isWifiCompatible } }) {
  try {
    const { data: updatedHardware } = yield call(callApi, Hardwares.update, hardwareId, {
      description: description,
      isWifiCompatible: isWifiCompatible,
    });
    yield put(updateHardwareSuccess(updatedHardware));

    browserHistory.push(`/hardwares/${hardwareId}`);
  } catch (e) {
    if (!e.isFetch) {
      throw e;
    }
    yield put(updateHardwareFailure(e.data.message));
  }
}

export default () => takeLatest(updateHardware, watchUpdateHardware);
