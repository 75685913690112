import { get, patch, post, del } from 'services/rest';

const url = '/manufacturers';

const Manufacturers = {
  index: (queryParams) => get(url, queryParams),
  allWithNumberOfBoards: (queryParams) => get(`${url}/numberOfBoards/getAll`, queryParams),
  create: (manufacturer) => post(url, manufacturer),
  delete: (manufacturerId) => del(`${url}/${manufacturerId}`),
  show: (manufacturerId) => get(`${url}/${manufacturerId}`),
  update: (manufacturerId, payload) => patch(`${url}/${manufacturerId}`, payload),
  boards: (manufacturerId) => get(`${url}/${manufacturerId}/boards`),
};

export default Manufacturers;
