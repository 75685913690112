import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import { Form } from 'scenes/Firmwares/components/Form';
import actions from './actions';

class Create extends Component {
  handleFormSubmit = (values, _actions) =>
    this.props.dispatch(
      actions.createFirmware({
        firmware: values,
      })
    );

  render() {
    const { createError, isCreateSuccessful } = this.props;

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" lg={{ size: 10, offset: 1 }}>
            <Card color="white">
              <CardHeader>
                <div className="card-header__left">
                  <i className="fa fa-align-justify" /> New Firmware
                </div>
              </CardHeader>
              <CardBody>
                <Form
                  formError={createError}
                  onSubmit={this.handleFormSubmit}
                  isSuccessful={isCreateSuccessful}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  // formError: state.scenes.firmwares.create.createError,
  createError: state.scenes.firmwares.create.createError,
  isCreateSuccessful: state.scenes.firmwares.create.isCreateSuccessful,
});

export default connect(mapStateToProps)(Create);
