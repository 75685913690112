import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardBody, CardHeader } from 'reactstrap'

import PAYMENT_PROVIDERS from 'constants/PaymentProviders'
import PRODUCT_FEATURE_SET from 'constants/ProductFeatureSets'
import PAYMENT_PRODUCT_TYPES from 'constants/PaymentProductTypes'

import TableRow from 'components/TableRow'

const MainData = ({ name, description, featureSet, paymentProvider, trialPeriodDays, type, createdAt, updatedAt, isLoading }) => {

  const PROVIDER_COLORS = {
    Paddle: 'blue',
    Stripe: 'red',
  }

  return (
    <Card color="white">
      <CardHeader>
        <div className="card-header__left">
          <i className="fa fa-align-justify" /> {name ?? 'Product information'}
        </div>
      </CardHeader>
      {!isLoading && (
        <CardBody>
          <div className="key-value-data-container">
            <TableRow.Default label="Description" value={description} />
            <TableRow.Default label="Feature set" value={featureSet} />
            <TableRow.Enum label="Payment provider" value={paymentProvider} colorMap={PROVIDER_COLORS} />
            <TableRow.Default label="Trial Period Days" value={trialPeriodDays} />
            <TableRow.Enum label="Type" value={type} />
            <TableRow.Date label="Created" value={createdAt} />
            <TableRow.Date label="Last updated" value={updatedAt} />
          </div>
        </CardBody>
      )}
    </Card>
  )
}

MainData.displayName = 'MainData';

MainData.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  featureSet: PropTypes.oneOf(Object.values(PRODUCT_FEATURE_SET)),
  paymentProvider: PropTypes.oneOf(Object.values(PAYMENT_PROVIDERS)),
  trialPeriodDays: PropTypes.number,
  type: PropTypes.oneOf(Object.values(PAYMENT_PRODUCT_TYPES)),
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  isLoading: PropTypes.bool,
}

export default MainData