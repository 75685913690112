import { BullThrowEnum, TournamentConfigType, TournamentStageType } from 'enums';
import {
  Participant,
  PreparedTournament,
  Tournament,
  TournamentApiResponseDto,
  TournamentWizardForm,
} from 'interfaces';
import { isUndefined } from 'lodash';
import { DateTime } from 'luxon';
import { put } from 'services/rest';
import { deepClone, isNullOrUndefined, dataURLtoFile } from 'utils';

const prepareParticipants = (tournament: Tournament) => {
  const orderedParticipants = Object.values(tournament.participants).map((elem) => elem.userId);
  const participantsById: { [key: string]: Participant } = {};

  Object.values(tournament.participants).forEach((user) => {
    participantsById[user.userId] = user;
  });

  return { orderedParticipants, participantsById };
};

export const prepareTournamentValues = (tournament: Tournament): PreparedTournament | undefined => {
  if (isNullOrUndefined(tournament)) {
    return undefined;
  }

  let bullThrow: BullThrowEnum | undefined;
  if (
    tournament?.configType === TournamentConfigType.Various &&
    !isNullOrUndefined(tournament?.variousGameConfig)
  ) {
    bullThrow = tournament?.variousGameConfig[TournamentStageType.Final]?.bullThrow;
  } else {
    bullThrow = tournament?.gameConfig?.bullThrow;
  }

  const { participantsById, orderedParticipants } = prepareParticipants(tournament);

  return {
    ...deepClone(tournament),
    bullThrow,
    participantsOrder: orderedParticipants,
    participants: participantsById,
  };
};

export const createPresetFromTournament = (tournament: PreparedTournament) => {
  if (tournament) {
    delete tournament.registrationStartDate;
    delete tournament.registrationEndDate;
    delete tournament.tournamentEndDate;
    delete tournament.state;
  }

  function validateDate(value: DateTime) {
    const now = new Date();
    const start = value.toJSDate();
    return start.getTime() >= now.getTime();
  }

  if (!isUndefined(tournament?.tournamentStartDate)) {
    const newStartDate =
      typeof tournament.tournamentStartDate === 'string'
        ? DateTime.fromISO(tournament.tournamentStartDate)
        : DateTime.fromJSDate(tournament.tournamentStartDate);

    if (validateDate(newStartDate)) {
      return tournament;
    }

    const updatedStartDate = newStartDate.plus({ days: 1 });
    tournament.tournamentStartDate = updatedStartDate.toISO() ?? tournament.tournamentStartDate;
  }

  return tournament;
};

export async function uploadThumbnail(thumbnailImage: string) {
  try {
    const formData = new FormData();
    const pictureFile = dataURLtoFile(thumbnailImage, 'image');
    formData.append('file', pictureFile);

    if (isNullOrUndefined(formData)) {
      throw new Error("Couldn't find image to upload.");
    }

    const {
      data: { fileName },
    }: any = await put('/private-tournaments/thumbnail', formData as any);

    return fileName as string;
  } catch (e) {
    console.error(e);
  }
}
