export enum WizardPages {
  Basics = 'Basics',
  GameConfiguration = 'GameConfiguration',
  Description = 'Description',
  Appearance = 'Appearance',
  PrizePool = 'PrizePool',
  Participants = 'Participants',
}

export enum WizardPageTitles {
  Basics = 'Basics',
  GameConfiguration = 'Game configuration',
  Description = 'Description',
  Appearance = 'Appearance',
  PrizePool = 'Prize pool',
  Participants = 'Participants',
}
