import { get } from 'services/rest'

const url = '/subscriptions'

const Subscriptions = {
  index: queryParams => get(url, queryParams),
  show: subscriptionId => get(`${url}/extended/${subscriptionId}`),
  prices: () => get(`${url}/prices`),
}

export default Subscriptions
