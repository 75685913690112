import React, { useState, useEffect } from 'react';

function useScrollFade(ref: React.RefObject<HTMLElement>, checkForResize = false) {
  const [scrollState, setScrollState] = useState({
    isFadeTop: false,
    isFadeBottom: false,
    isFadeLeft: false,
    isFadeRight: false
  });

  const checkScroll = () => {
    if (!ref.current) return;

    const { scrollTop, scrollLeft, scrollHeight, scrollWidth, clientHeight, clientWidth } = ref.current;

    setScrollState({
      isFadeTop: scrollTop > 0,
      // We allow a slight variance in case of potential rounding errors.
      isFadeBottom: Math.abs(scrollHeight - scrollTop - clientHeight) > 1,
      isFadeLeft: scrollLeft > 0,
      isFadeRight: Math.abs(scrollWidth - scrollLeft - clientWidth) > 1
    });
  };

  useEffect(() => {
    const currentRef = ref.current;
    const resizeObserver = checkForResize ? new ResizeObserver(checkScroll) : null;
  
    if (currentRef) {
      currentRef.addEventListener('scroll', checkScroll);
      if (checkForResize && resizeObserver) resizeObserver.observe(ref.current);
      checkScroll(); // initial check
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', checkScroll);
        if (checkForResize && resizeObserver) resizeObserver.disconnect();
      }
    };
  }, [ref]);

  return scrollState;
}

export default useScrollFade;
