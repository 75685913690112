import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import actions from './actions';

const INITIAL_STATE = {};
const createReducer = handleActions(
  {
    [actions.createServiceAccount]: state =>
      update(state, {
        isCreatePending: {
          $set: true,
        },
        createError: {
          $set: '',
        },
      }),

    [actions.createServiceAccountSuccess]: state =>
      update(state, {
        isCreatePending: {
          $set: false,
        },
        isCreateSuccessful: {
          $set: true,
        },
        createError: {
          $set: '',
        },
      }),

    [actions.createServiceAccountFailure]: (state, { payload }) =>
      update(state, {
        isCreatePending: {
          $set: false,
        },
        isCreateSuccessful: {
          $set: false,
        },
        createError: {
          $set: payload,
        },
      }),
  },
  INITIAL_STATE
);

export default createReducer;
