import React from 'react';
import { Label, FormGroup } from 'reactstrap';
import { default as ReactDatePicker } from 'react-datepicker';
import styles from './styles.module.scss';
const ColumnWrapper = ({ children, label, name, isError, errorMsg }) => {
    return (React.createElement("div", { className: "col-sm-4" },
        React.createElement(FormGroup, { className: styles.formGroup },
            React.createElement(Label, { htmlFor: name }, label),
            children,
            isError && errorMsg)));
};
const RowWrapper = ({ children, label, labelClass, valueClass }) => {
    return (React.createElement("dl", { className: "row" },
        React.createElement("dt", { className: labelClass }, label),
        React.createElement("dd", { className: valueClass }, children)));
};
export const DatePicker = ({ label, value, name, onBlur, onChange, isError, errorMsg, inline = true, disabled = false, labelClass = 'col-sm-4', valueClass = 'col-sm-8', dateFormat = 'yyyy.MM.dd HH:mm', showTimeSelectOnly = false, }) => {
    const Wrapper = inline ? ColumnWrapper : RowWrapper;
    return (React.createElement(Wrapper, { label: label, name: name, isError: isError, errorMsg: errorMsg, labelClass: labelClass, valueClass: valueClass },
        React.createElement(ReactDatePicker, { selected: value, name: name, onBlur: onBlur, onChange: onChange, showTimeSelect: true, showTimeSelectOnly: showTimeSelectOnly, timeFormat: "HH:mm", timeIntervals: 5, dateFormat: dateFormat, placeholderText: "\u00E9\u00E9\u00E9\u00E9. hh. nn.", className: "form-control", disabled: disabled, isClearable: true })));
};
