import { FilterElement, PreparedFilterElement } from 'interfaces';
import { clone, isEqual, reduce } from 'lodash';
import { GetKeys } from 'types';

export { default as dataURIToBlob } from './dataURIToBlob';
export { default as createFormData } from './createFormData';
export { default as formatDate } from './formatDate';
export { default as formatLabel } from './formatLabel';
export { getFormattedDuration, extractTime } from './timestampFormatter';
export { getInvitationStatuses } from './getInvitationStatuses';
export { mapBoolPropsToClassnames } from './mapBoolPropsToClassnames';
export { isDefaultThumbnail } from './isDefaultThumbnail';
export { validateEmail } from './validateEmail';
export { validateScoliaId } from './validateScoliaId';
export { parseClipboardText } from './parseClipboardText';
export { countOccurrences } from './countOccurrences';
export { trimWhiteSpace } from './trimWhiteSpace';
export { getNextHourDate } from './getNextHourDate';
export { onlyNumbers } from './onlyNumbers';
export { getTodayMorning } from './getTodayMorning';
export { dataURLtoFile } from './dataUrlToFile';
export { getTextContentFromMarkup } from './getTextContentFromMarkup';
export { checkMobilePlatform } from './checkMobilePlatform';
export { mergeDateTime } from './mergeDateTime';

export function debounce<T extends (...args: any[]) => void>(fn: T, delay = 300) {
  let timeout: NodeJS.Timeout;
  return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
    const ctx = this;
    clearTimeout(timeout);
    timeout = setTimeout(fn.bind(ctx, ...args), delay);
  };
}

export function minToSec(min: number) {
  return min * 60;
}

export function secToMin(sec: number) {
  return sec / 60;
}

/**
 * Deep clone the provided object.
 */
export function deepClone<T extends Array<any>>(value: T): T;
export function deepClone<T extends object>(value: T): T;
export function deepClone<T extends any>(value: T): T;
export function deepClone(value: Array<any> | object | any) {
  // deepClone by Value
  if (value && Array.isArray(value)) {
    const val = ([] as typeof value).concat(value); // Copy by Value instead of Reference - Array.prototype.concat

    val.forEach((_element, idx) => {
      val[idx] = deepClone(val[idx]); // Recursion
    });
    return val;
  } else if (value && typeof value === 'object') {
    const val = clone(value); // Copy by Value instead of Reference - Lodash `clone` util

    Object.keys(val).forEach((key) => {
      val[key] = deepClone(val[key]); // Recursion
    });
    return val;
  } else {
    return value; // Returns original value and stops!
  }
}

export const formatDateNumber = (date: number): string => (date < 10 ? '0' + date : String(date));

/*export const parseDateFromInput = (date: string, hr = 0, min = 0, sec = 0, ms = 0) => {
  let fields = date.split(/\D/);
  return new Date(fields[0], --fields[1], fields[2], hr, min, sec, ms);
};*/

export const formatNumberWithThousandSeparators = (
  number: any,
  separator = ' ',
  defaultValue = '0'
) => {
  if (!number || typeof number === 'object' || typeof number === 'boolean') return defaultValue;
  if (typeof number === 'string') number = Number(number);
  return Number(number)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};

export const getHmsFromMs = (ms: number) => {
  let s = Math.floor(ms / 1000);
  let m = Math.floor(s / 60);
  s = m > 0 ? s % (m * 60) : s;
  let h = Math.floor(m / 60);
  m = h > 0 ? m % (h * 60) : m;
  return [h, m, s];
};

/**
 * Prepares the filter object's selected fields and returns it.
 * @param filter
 * @param fieldNames
 * @returns array
 */
export function getPreparedFiltersForSelectedFields<T extends Record<any, any>>(
  filter: FilterElement<GetKeys<T>>[],
  fieldNames: readonly string[]
): PreparedFilterElement<GetKeys<T>>[] {
  const clonedFilter = deepClone(filter);

  fieldNames.forEach((fieldName) => {
    const filterIndex = clonedFilter.findIndex((filter) => filter?.id === fieldName);
    const { id, value } = clonedFilter[filterIndex] ?? {};

    if (filterIndex >= 0) {
      if (value === 'All') {
        clonedFilter.splice(filterIndex, 1);
      } else if (!id.startsWith('eq:')) {
        clonedFilter[filterIndex].id = `eq:${id}` as GetKeys<T>;
      }
    }
  });

  return clonedFilter;
}

/**
 * Checks if the provided value(s) is/are either undefined or null.
 * If the provided value is an array, the function will check every item of the
 * array with OR condition.
 * You can turn on strict checking (AND condition) with the second parameter
 * set to true.
 * @param value
 * @param {boolean} arrayCheckWithAnd
 * @returns {boolean}
 */
export function isNullOrUndefined(
  value: any,
  arrayCheckWithAnd = false
): value is null | undefined {
  if (Array.isArray(value)) {
    if (arrayCheckWithAnd) {
      return value.every((item) => item === undefined || item === null);
    } else {
      return value.some((item) => item === undefined || item === null);
    }
  }

  return value === undefined || value === null;
}

export function datesEqual(d1: Date, d2: Date) {
  if (d1 > d2 || d1 < d2) return false;
  else return true;
}

export function startOfDayOf(date: Date) {
  return new Date(date.setHours(0, 0, 0, 0));
}

export function endOfDayOf(date: Date) {
  return new Date(date.setHours(23, 59, 59, 999));
}

export function splitCamelCase(string: string) {
  const notNullString = string || '';
  const trimmedString = notNullString.trim();
  const arrayOfStrings = trimmedString.split(' ');

  const splitStringsArray: string[] = [];
  arrayOfStrings.forEach((tempString) => {
    if (tempString !== '') {
      const splitWords = tempString.split(/(?=[A-Z])/).join(' ');
      splitStringsArray.push(splitWords.toLowerCase());
    }
  });

  return splitStringsArray.join(' ');
}

/**
 * Used for comparing difference between objects.
 * Returns an array of the changed keys.
 * @param {object} obj1
 * @param {object} obj2
 * @returns {string}
 */
export function changedKeys(
  obj1: Record<string, unknown>,
  obj2: Record<string, unknown>
): string[] {
  return reduce(
    obj1,
    (result, value, key) => (isEqual(value, obj2[key]) ? result : result.concat(key)),
    [] as string[]
  );
}

export { default as getConfiguration } from './x01ConfigFormatter';
export { default as getFormattedConfig } from './gameConfigFormatter';
