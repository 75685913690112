import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import styles from './styles.module.scss';
import { getInvoiceUrl } from 'scenes/Boards/components/Show/actions';

const DownloadInvoiceButton = ({ paddleTransactionId }) => {
  const currentlyPendingInvoiceUrl = useSelector(state => state.scenes.boards.show.currentlyPendingInvoiceUrl);
  const dispatch = useDispatch();

  const handleStartGettingInvoiceUrl = () => {
    if (!currentlyPendingInvoiceUrl) {
      dispatch(getInvoiceUrl({paddleTransactionId}));
    }
  }

  return (
    <button onClick={handleStartGettingInvoiceUrl} className={styles.button}>
      {currentlyPendingInvoiceUrl === paddleTransactionId && (
        <i className={cn(styles.spinner, 'fa fa-fw fa-spinner fa-spin')} />
      )}
      Download invoice
    </button>
  );
};

DownloadInvoiceButton.displayName = 'DownloadInvoiceButton';

DownloadInvoiceButton.propTypes = {
  paddleTransactionId: PropTypes.string,
};

export default DownloadInvoiceButton;
