import { TournamentParticipantStatusEnum } from "enums";
import { Participant } from "interfaces";

interface InvitationStatuses {
  acceptedInvitations: number;
  declinedInvitations: number;
  pendingInvitations: number;
}

export function getInvitationStatuses(participants: Participant[]): InvitationStatuses {
  return participants.reduce((acc: InvitationStatuses, participant: Participant) => {
    const { status } = participant;
    if (status === TournamentParticipantStatusEnum.Accepted) {
      acc.acceptedInvitations += 1;
    } else if (status === TournamentParticipantStatusEnum.Declined) {
      acc.declinedInvitations += 1;
    } else if (status === TournamentParticipantStatusEnum.Pending) {
      acc.pendingInvitations += 1;
    }
    return acc;
  }, { acceptedInvitations: 0, declinedInvitations: 0, pendingInvitations: 0 });
}
