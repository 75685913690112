import { call, put, takeLatest } from 'redux-saga/effects';

import { Firmwares } from 'services/api';
import { callApi } from 'services/rest';
import { browserHistory } from 'services/history';

import {
  getFirmware,
  getFirmwareFailure,
  getFirmwareSuccess,
} from 'scenes/Firmwares/actions';

function* watchGetFirmware({ payload: { firmwareId, queryParams } }) {
  try {
    const { data } = yield call(callApi, Firmwares.show, firmwareId, queryParams);
    yield put(getFirmwareSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(getFirmwareFailure());
    browserHistory.push('/firmwares/');
  }
}

export default () => takeLatest(getFirmware, watchGetFirmware);
