import { call, put, takeLatest } from 'redux-saga/effects';

import { ServiceAccounts } from 'services/api';
import { callApi } from 'services/rest';
import { browserHistory } from 'services/history';

import {
  getServiceAccount,
  getServiceAccountFailure,
  getServiceAccountSuccess,
} from 'scenes/ServiceAccounts/components/Show/actions';

function* watchGetServiceAccount({
  payload: { serviceAccountId, queryParams },
}) {
  try {
    const { data } = yield call(
      callApi,
      ServiceAccounts.show,
      serviceAccountId,
      queryParams
    );
    yield put(getServiceAccountSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(getServiceAccountFailure());
    browserHistory.push('/service-accounts/');
  }
}

export default () => takeLatest(getServiceAccount, watchGetServiceAccount);
