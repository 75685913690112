import React, { Component } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { Badge, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

import { EntityTable } from 'components/EntityTable';
import { BoardState } from 'constants/BoardState';
import { isNullOrUndefined } from 'utils';

import { getOnlineBoards } from './actions';

import styles from './styles.module.scss';

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Serial Number',
    accessor: 'serialNumber',
    Cell: row => (
      <Link to={`/boards/${row.row._original._id}`}>
        {row.value}
      </Link>
    )
  },
  {
    Header: 'Address',
    accessor: 'formattedAddress',
  },
  {
    Header: 'State',
    accessor: 'currentState.state',
    Cell: row => {
      return (
        <div>
          <Badge
            color={cn({
              dark: isNullOrUndefined(row.value),
              autumn:
                row.value === BoardState.InGame.type,
              success:
                row.value === BoardState.Online.type,
              danger:
                row.value === BoardState.Error.type,
              'orange-2':
                row.value ===
                BoardState.Initializing.type ||
                row.value === BoardState.Updating.type,
              'blue-2':
                row.value ===
                BoardState.Calibrating.type,
            })}
          >
            {!isNullOrUndefined(row.value)
              ? BoardState[row.value].label
              : BoardState.Unknown.label}
          </Badge>
        </div>
      )
    }
  },
  {
    Header: 'Board Code',
    accessor: 'currentState.boardCode',
    Cell: row => {
      return (
        <div>
          <Badge
            color={cn({
              dark: row.value,
            })}
          >
            {row.value}
          </Badge>
        </div>
      );
    },
  },
  {
    Header: 'Board Type',
    accessor: 'isHomeSbc',
    Cell: row => (
      <div>{row.value ? 'Home' : 'Pro'}</div>
    )
  },
  {
    Header: 'Service Account',
    accessor: 'serviceAccount',
    filterable: true,
    sortable: true,
    Cell: row => (
      row.value ?
        row.value.map((serviceAccount, i) =>
          <Link key={`/service-accounts/${serviceAccount.id}`} to={`/service-accounts/${serviceAccount.id}`}>{serviceAccount.name} {i < row.value.length - 1 ? ', ' : ''}</Link>
    ) : (<div />))
  },
];

class List extends Component {

  boardStateBlock(count, state, conditionIndicatorStyle) {
    return (
      <div className={styles.boardsStatistics}>
        <div>{state}</div>
        <div>
          <i className={cn('fa fa-fw fa-circle', conditionIndicatorStyle)} />
          {count}
        </div>
      </div>
    );
  }

  get boardsDetails() {
    const { onlineBoards: boards } = this.props;

    const {
      Online,
      InGame: { length: inGameBoardsCount },
      Calibrating,
      Initializing,
      Updating,
      Error,
      Unknown,
    } = Object.keys(boards)
      .map(boardId => boards[boardId])
      .reduce(
        (acc, curr) => {
          if (isNullOrUndefined(curr.currentState.state)) {
            acc.Unknown.push(curr);
          } else {
            acc[curr.currentState.state].push(curr);
          }
          return acc;
        },
        {
          Online: [],
          InGame: [],
          Calibrating: [],
          Initializing: [],
          Updating: [],
          Error: [],
          Unknown: [],
        }
      );

    const onlineBoardsCount = [
      ...Online,
      ...Calibrating,
      ...Initializing,
      ...Updating,
      ...Error,
      ...Unknown,
    ].length;

    return (
      <div className={styles.myBoardsListHeader}>
        <div className={styles.boardsDetails}>
          <div className={styles.boardNumbers}>
            <div>Number of boards</div>
            <div>{onlineBoardsCount + inGameBoardsCount}</div>
          </div>
          <div className={styles.boardsStatisticsWrapper}>
            {this.boardStateBlock(inGameBoardsCount, 'In Game', styles.boardsStatisticsIngame)}
            {this.boardStateBlock(Online.length, 'Online', styles.boardsStatisticsOnline)}
            {this.boardStateBlock(Initializing.length, 'Initializing', styles.boardsStatisticsInitializing)}
            {this.boardStateBlock(Calibrating.length, 'Calibrating', styles.boardsStatisticsCalibrating)}
            {this.boardStateBlock(Error.length, 'Error', styles.boardsStatisticsError)}
          </div>
        </div>
      </div>
    );
  }

  handleFetchData = query => {
    this.props.dispatch(getOnlineBoards(query));
  }

  render() {
    const { onlineBoards, page, pageSize, isLoading, totalCount } = this.props;

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" lg={{ size: 10, offset: 1 }}>
            <Card color="white">
              <CardHeader>
                <div className="card-header__left">
                  <i className="fa fa-gamepad" /> Online Boards
                  {isLoading && <i className="fa fa-spinner fa-spin fa-fw" />}
                </div>
              </CardHeader>

              <CardBody>
                <EntityTable
                  resource={'online'}
                  columns={columns}
                  data={onlineBoards}
                  page={page}
                  pageSize={pageSize}
                  totalCount={totalCount}
                  isLoading={isLoading}
                  onFetchData={this.handleFetchData}
                  defaultPageRowSize={500}
                >
                  {this.boardsDetails}
                </EntityTable>

              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

List.displayName = 'OnlineBoards.List';

const mapStateToProps = state => ({
  onlineBoards: state.data.onlineBoards,
  page: parseInt(state.scenes.onlineBoards.list.page),
  pageSize: parseInt(state.scenes.onlineBoards.list.pageSize),
  totalCount: state.scenes.onlineBoards.list.totalCount,
  isLoading: state.scenes.onlineBoards.list.isLoading,
});

export default connect(mapStateToProps)(List);
