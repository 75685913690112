import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader } from 'reactstrap';

class AddressData extends PureComponent {
  render() {
    const {
      addressData: {
        country,
        formattedAddress,
        geometry,
        locality,
        placeId,
        postalCode,
        route,
        streetNumber,
      },
    } = this.props;

    return (
      <div>
        <Card color="white">
          <CardHeader>
            <div className="card-header__left">
              <i className="fa fa-align-justify" /> Address Data
            </div>
          </CardHeader>

          <CardBody>

            <div className="key-value-data-container">
              <div className="row">
                <div className="col-sm-5">Country</div>
                <div className="col-sm-7"><strong>{country || '-'}</strong></div>
              </div>
              <div className="row">
                <div className="col-sm-5">Postal Code</div>
                <div className="col-sm-7">{postalCode || '-'}</div>
              </div>
              <div className="row">
                <div className="col-sm-5">Locality</div>
                <div className="col-sm-7">{locality || '-'}</div>
              </div>
              <div className="row">
                <div className="col-sm-5">Route</div>
                <div className="col-sm-7">{route || '-'}</div>
              </div>
              <div className="row">
                <div className="col-sm-5">Street Number</div>
                <div className="col-sm-7">{streetNumber || '-'}</div>
              </div>
              <div className="row">
                <div className="col-sm-5">Geometry lat</div>
                <div className="col-sm-7">{(geometry && geometry.lat) || '-'}</div>
              </div>
              <div className="row">
                <div className="col-sm-5">Geometry lng</div>
                <div className="col-sm-7">{(geometry && geometry.lng) || '-'}</div>
              </div>

              <hr className="mt-lg-5"/>

              <div className="row">
                <div className="col-sm-5">Address</div>
                <div className="col-sm-7"><strong>{formattedAddress || '-'}</strong></div>
              </div>
              <div className="row">
                <div className="col-sm-5">Place ID</div>
                <div className="col-sm-7">{placeId || '-'}</div>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

AddressData.propTypes = {
  addressData: PropTypes.object.isRequired,
};

AddressData.defaultProps = {
  addressData: {},
};

export default AddressData;
