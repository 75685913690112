import { call, put, takeLatest } from 'redux-saga/effects';

import { Users } from 'services/api';

import {
  getUsers,
  getUsersFailure,
  getUsersSuccess,
} from 'scenes/Users/components/List/actions';
import { callApi } from 'services/rest';

function* watchGetUsers({ payload }) {
  try {
    const { data } = yield call(callApi, Users.index, payload);
    yield put(getUsersSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(getUsersFailure());
  }
}

// It must be wrapped in a function, so execution will happen only on function call
export default () => takeLatest(getUsers, watchGetUsers);
