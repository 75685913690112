import { call, delay, put, takeLatest } from 'redux-saga/effects';

import { Boards } from 'services/api';
import { callApi } from 'services/rest';

import {
  getBoardCurrentState,
  getBoardCurrentStateFailure,
  getBoardCurrentStateSuccess,
} from 'scenes/Boards/actions';

function* watchGetBoardCurrentState({ payload: boardId }) {
  try {
    let { data } = yield call(callApi, Boards.currentState, boardId);
    data.boardId = boardId;
    yield delay(200);
    yield put(getBoardCurrentStateSuccess(data));
  } catch (e) {
    yield put(getBoardCurrentStateFailure(e.isFetch ? e.data.error : e));
  }
}

export default () =>
  takeLatest(getBoardCurrentState, watchGetBoardCurrentState);
