import { handleActions } from 'redux-actions';

import actions from './actions';

const INITIAL_STATE = {
  isLoading: true,
  hardwares: [],
};

const list = handleActions(
  {
    [actions.getHardwares]: state => ({
      ...state,
      isLoading: true,
    }),

    [actions.getHardwaresSuccess]: () => ({
      isLoading: false,
    }),

    [actions.getHardwaresFailure]: () => ({
      isLoading: false,
    }),
  },
  INITIAL_STATE
);

export default list;
