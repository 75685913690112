import { call, put, takeLatest } from 'redux-saga/effects';

import { Tournaments } from 'services/api';

import { browserHistory } from 'services/history';
import { callApi } from 'services/rest';
import { notifyError, notifySuccess } from 'services/notification/actions';

import {
  createTournament,
  createTournamentFailure,
  createTournamentSuccess,
} from 'scenes/Tournaments/components/Create/actions';

function* watchCreateTournament({ payload: { data } }) {
  try {
    yield call(callApi, Tournaments.create, data);
    yield put(createTournamentSuccess());
    yield put(notifySuccess(`${data.meta.multipleTournamentQuantity === 1 ? 'Tournament' : 'Tournaments'} was created successfully.`))
    browserHistory.push('/tournaments');
  } catch (e) {
    if (!e.isFetch) {
      throw e;
    }
    yield put(createTournamentFailure(e.data.error));
    yield put(notifyError(`Failed to create ${data.meta.multipleTournamentQuantity === 1 ? 'tournament' : 'tournaments'}.`))
  }
}

export default () => takeLatest(createTournament, watchCreateTournament);
