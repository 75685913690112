import { all } from 'redux-saga/effects';

import watchGetMessage from './watchers/getMessage';
import watchSubscribeToGroup from './watchers/subscribeToGroup';
import watchUnsubscribeToGroup from './watchers/unsubscribeToGroup';
import watchGetMessages from './watchers/getMessages';

export default function* showSaga() {
  yield all([
    watchGetMessage(),
    watchGetMessages(),
    watchSubscribeToGroup(),
    watchUnsubscribeToGroup(),
  ]);
}