import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Badge, Card, CardBody, CardHeader, Table } from 'reactstrap';
import { Link } from 'react-router-dom';

import { statuses } from 'constants/BoardStatuses';
import { BoardState } from 'constants/BoardState';

class UsersBoards extends Component {
  get userBoards() {
    const { boards } = this.props;
    delete boards['__isStale'];

    return Object.values(boards).map((board) => {
      const { boardCode, state } = board.currentState || {};

      const status = statuses.find(
        statusObj => statusObj.value === board.status
      ) || { value: board.status, label: board.status };

      return (
        <tr key={board.id}>
          <td>
            <Link to={`/boards/${board.id}`}>{board.name}</Link>
          </td>
          <td>{board.serialNumber || '-'}</td>
          <td>
            {board.addressData ? board.addressData.formattedAddress : '-'}
          </td>
          <td>
            <Badge
              color={cn({
                info:
                  status.value === 'InStock' ||
                  status.value === 'ReadyToActivate',
                success: status.value === 'Attached',
                warning:
                  status.value === 'Suspended' || status.value === 'Detached',
              })}
            >
              {status.label}
            </Badge>
          </td>
          <td>
            <Badge
              color={cn({
                orange: state === BoardState.InGame.type,
                success: state === BoardState.Online.type,
                secondary: state === BoardState.Offline.type,
                danger: state === BoardState.Error.type,
                'orange-2': (state === BoardState.Initializing.type || state === BoardState.Updating.type),
                'blue-2': state === BoardState.Calibrating.type
              })}
            >
              {state ? BoardState[state].label : '-'}
            </Badge>
          </td>
          <td>{boardCode || '-'}</td>
        </tr>
      );
    });
  }

  render() {
    return (
      <div>
        <Card color="white">
          <CardHeader>
            <div className="card-header__left">
              <i className="fa fa-align-justify" /> User's Boards
            </div>
          </CardHeader>
          <CardBody>
            <Table responsive>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Serial Number</th>
                  <th>Address</th>
                  <th>Status</th>
                  <th>State</th>
                  <th>Board Code</th>
                </tr>
              </thead>
              <tbody>{this.userBoards}</tbody>
            </Table>
          </CardBody>
        </Card>
      </div>
    );
  }
}

UsersBoards.propTypes = {
  boards: PropTypes.object.isRequired,
};

UsersBoards.defaultProps = {
  boards: {},
};

export default UsersBoards;
