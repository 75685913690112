import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import actions from './actions';

const INITIAL_STATE = {
  isLoading: true,
};

const reducer = handleActions(
  {
    [actions.getStoreSnapshot]: (state, { _payload }) => {
      return update(state, {
        isLoading: {
          $set: true,
        },
      });
    },

    [actions.getStoreSnapshotSuccess]: () => ({
      isLoading: false,
    }),

    [actions.getStoreSnapshotFailure]: () => ({
      isLoading: false,
    }),
  },
  INITIAL_STATE
);

export default reducer;
