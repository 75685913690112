import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Router } from 'react-router-dom';
import { withFlagProvider } from './withFlagProvider';
import { QueryClientProvider } from 'react-query';
import { queryClient } from 'queryClient';
import { AuthorizedRoute, PublicRoute } from 'routes';
import { MainLayout } from 'layouts/MainLayout';
import { Notifications } from 'components/Notifications';
import { Login } from 'scenes/Login';
import Page404 from 'scenes/Page404';
import Page500 from 'scenes/Page500';
import { Skeleton } from 'scenes/Skeleton';
import { browserHistory } from 'services/history';
import { getConfiguration } from 'services/session/selectors';
import { useSetupFlagContext } from 'useSetupFlagContext';
export function App() {
    const configuration = useSelector(getConfiguration);
    useSetupFlagContext();
    return (React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement(Notifications, null),
        configuration ? (React.createElement(Router, { history: browserHistory },
            React.createElement(Switch, null,
                React.createElement(PublicRoute, { exact: true, path: "/login", name: "Login Page", component: Login }),
                React.createElement(PublicRoute, { exact: true, path: "/404", name: "Page 404", component: Page404 }),
                React.createElement(PublicRoute, { exact: true, path: "/500", name: "Page 500", component: Page500 }),
                React.createElement(AuthorizedRoute, { path: "/", name: "Home", component: MainLayout })))) : (React.createElement(Skeleton, null))));
}
export default withFlagProvider(App);
