import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { Link } from 'react-router-dom';

import { default as formatDate } from 'utils/formatDate';

const MainInfo = ({ id, versionNumber, releasedAt, lastModifiedAt, deletedAt, description, isAdmin }) => {
  return (
    <div>
      <Card>
        <CardHeader>
          <div className="card-header__left">
            <i className="fa fa-align-justify" /> Platform Data
          </div>
          <div className="card-header__right">
            {isAdmin && <Link to={`/platforms/${id}/edit`}>
              <button className="btn btn-primary btn-sm" type="button">
                Edit Platform
              </button>
            </Link>}
          </div>
        </CardHeader>

        <CardBody>
          <div className="key-value-data-container">
            <div className="row">
              <div className="col-sm-4">Version Number</div>
              <div className="col-sm-8">
                <strong>{versionNumber || 'N/A'}</strong>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4">Release Date</div>
              <div className="col-sm-8">{formatDate(releasedAt)}</div>
            </div>
            <div className="row">
              <div className="col-sm-4">Description</div>
              <div className="col-sm-8">{description || '-'}</div>
            </div>

            <hr className="mt-lg-5" />
            <div className="row">
              <div className="col-sm-4">Last Modified</div>
              <div className="col-sm-8">{formatDate(lastModifiedAt)}</div>
            </div>
            <div className="row">
              <div className="col-sm-4">Deleted At</div>
              <div className="col-sm-8">{formatDate(deletedAt)}</div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

MainInfo.propTypes = {
  versionNumber: PropTypes.string,
  releasedAt: PropTypes.string,
  lastModifiedAt: PropTypes.string,
  deletedAt: PropTypes.string,
  description: PropTypes.string,
  isAdmin: PropTypes.bool,
};

export default MainInfo
