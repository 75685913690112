import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { capitalize } from 'lodash-es';

import styles from './styles.module.scss';

const ICON_MAPPINGS = {
  success: 'fa-check',
  warning: 'fa-info',
  error: 'fa-times',
};

class Toast extends Component {
  render() {
    const { title, message, type, onClose, children } = this.props;

    return (
      <div className={styles[`toast${type}`]}>
        <div className={cn('fa-stack', styles.toastIcon)}>
          <i className="fa fa-circle fa-stack-2x" />
          <i className={`fa ${ICON_MAPPINGS[type]} fa-stack-1x fa-inverse`} />
        </div>
        <div className={cn(styles.toastContent)}>
          <div className={cn(styles.toastContentTitle)}>
            {title || capitalize(type)}
          </div>
          <div
            className={cn(styles.toastContentMessage, {
              [styles.toastContentMessageError]: type === 'error',
            })}
          >
            {message || children}
          </div>
        </div>
        {typeof onClose === 'function' && (
          <div role="button" className={styles.toastClose} onClick={onClose}>
            <i className="sis-fw sis-close" />
          </div>
        )}
      </div>
    );
  }
}

Toast.propTypes = {
  type: PropTypes.oneOf(['success', 'warning', 'error']).isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
};

export default Toast;
