import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { AddressData } from './components/AddressData';
import { ConnectionHistory } from './components/ConnectionHistory';
import { GameInfo } from './components/GameInfo';
import { LiveStream } from './components/LiveStream';
import { MainInfo } from './components/MainInfo';
import { UsageStats } from './components/UsageStats';
import { LimitedThrows } from './components/LimitedThrows';
import { BanHistory } from 'components/BanHistory';

import { getIsAdmin, getRole } from 'services/session/selectors';
import { BoardState } from 'constants/BoardState';

import { actions as hardwareActions } from 'scenes/Hardwares/components/List';
import { actions as platformActions } from 'scenes/Platforms/components/List'
import {
  getBoard,
  getBoardStatistics,
  getBoardCurrentState,
  getBoardSessions,
  getBoardLimitedThrows,
} from 'scenes/Boards/actions';
import {
  subscribeToBoardUpdates,
  unsubscribeToBoardUpdates,
} from 'scenes/Boards/components/Show/actions'
import actions from './actions';
import { PaymentHistory } from './components/PaymentHistory';

const _getDateRange = interval => {
  let from = new Date();
  let to = new Date();

  switch (interval) {
    case 'today':
      from.setHours(0, 0, 0, 0);
      to = new Date(to.setHours(23, 59, 0));
      break;
    case 'yesterday':
      from.setHours(0, 0, 0, 0);
      from.setDate(from.getDate() - 1);
      to.setHours(23, 59, 0, 0);
      to.setDate(to.getDate() - 1);
      break;
    case 'lastWeek':
      from.setDate(to.getDate() - 7);
      break;
    case 'lastMonth':
      from.setDate(to.getDate() - 30);
      break;
    default:
      throw new Error(
        `Expected one of [today, yesterday, lastWeek, lastMonth] for interval, received ${interval}`
      );
  }

  return { from, to };
};

class Show extends Component {
  constructor(props) {
    super(props);

    const { from: today, to: tomorrow } = _getDateRange('today');

    this.state = {
      streamConfig: {
        quality: 50,
        scale: 25,
      },
      usageFilters: {
        usageFrom: today,
        usageTo: tomorrow,
      },
      connectionFilters: {
        startDate: today,
        endDate: tomorrow,
      },
      limitedThrowsFilters: {
        selectedPeriod: today,
      }
    };

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleDatePresetSelect = this.handleDatePresetSelect.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    const { usageFrom, usageTo } = this.state.usageFilters;
    const { selectedPeriod } = this.state.limitedThrowsFilters;
    const boardId = match.params.boardId;

    this.props.dispatch(getBoard(boardId));
    this.props.dispatch(getBoardCurrentState(boardId));
    this.props.dispatch(
      getBoardStatistics({
        boardId,
        filters: { from: usageFrom.toISOString(), to: usageTo.toISOString() },
      })
    );
    this.props.dispatch(
      getBoardLimitedThrows({
        boardId,
        filters: {
          periodStart: new Date(Date.UTC(selectedPeriod.getFullYear(), selectedPeriod.getMonth(), 1)),
          periodEnd: new Date(Date.UTC(selectedPeriod.getFullYear(), selectedPeriod.getMonth() + 1, 1)),
        },
      })
    );
    this.props.dispatch(hardwareActions.getHardwares());
    this.props.dispatch(platformActions.getPlatforms());
    this.props.dispatch(subscribeToBoardUpdates({ channel: 'boardUpdates', sbcId: boardId, userId: this.props.userId }));
  }

  componentWillUnmount() {
    const { match } = this.props;
    const boardId = match.params.boardId;
    this.props.dispatch(actions.finishStream());
    this.props.dispatch(actions.configureStream(null));
    this.props.dispatch(unsubscribeToBoardUpdates({ channel: 'boardUpdates', sbcId: boardId, userId: this.props.userId }))
  }

  handleStreamToggle = _e =>
    this.props.dispatch(
      this.props.isStreaming
        ? actions.finishStream()
        : actions.startStream({ boardId: this.props.board.id })
    );

  handleSnapshotClick = _e =>
    this.props.dispatch(actions.getSnapshot({ boardId: this.props.board.id }));

  handleCalibrateClick = _e =>
    this.props.dispatch(actions.boardCalibrate({ boardId: this.props.board.id }));


  handleStreamConfigurationChange = e =>
    this.setState(
      {
        streamConfig: {
          ...this.state.streamConfig,
          [e.target.name]: parseInt(e.target.value, 0),
        },
      },
      this.updateStreamConfig
    );

  updateStreamConfig = () => this.props.dispatch(actions.configureStream(this.state.streamConfig));

  handleDateChange = (type, e) => {
    const { name, date } = e;
    switch (type) {
      case 'usage':
        this.setState(state => ({
          usageFilters: {
            ...state.usageFilters,
            [name]: date,
          },
        }));
        break;
      default:
        throw new Error(`Expected one of [usage, connection] for type, received ${type}`);
    }
  };

  handleDatePresetSelect = (type, e) => {
    try {
      const { from, to } = _getDateRange(e.target.name);

      switch (type) {
        case 'usage':
          this.setState({
            usageFilters: {
              usageFrom: from,
              usageTo: to,
            },
          });
          break;

        default:
          throw new Error(`Expected one of [usage, connection] for type, received ${type}`);
      }
    } catch (e) {
      console.warn(e.message);
    }
  };

  handleFilterSubmit = _e => {
    const { usageFilters } = this.state;
    const { board } = this.props;

    const isFilterValid = Object.keys(usageFilters).every(key => usageFilters[key]);
    if (!isFilterValid) {
      console.warn('Please fill in the date ranges in usage filter');
      return;
    }

    this.props.dispatch(getBoard(board.id, null, false));
    this.props.dispatch(getBoardCurrentState(board.id));
    this.props.dispatch(
      getBoardStatistics({
        boardId: board.id,
        filters: {
          from: usageFilters.usageFrom.toISOString(),
          to: usageFilters.usageTo.toISOString(),
        },
      })
    );
  };

  onConnectionFilterChange = dateRange => {
    const {
      board: { id: boardId, isHybridSbc },
    } = this.props;

    this.setState({ connectionFilters: { ...dateRange } });

    this.props.dispatch(
      getBoardSessions({
        boardId,
        isHybridSbc,
        filters: {
          from: new Date(dateRange.startDate).toISOString(),
          to: new Date(dateRange.endDate).toISOString(),
        },
      })
    );
  };

  onLimitedThrowFilterChange = date => {
    const {
      board: { id: boardId },
    } = this.props;
    this.setState({ limitedThrowsFilters: { selectedPeriod: date } });
    if (!date) return;
    this.props.dispatch(
      getBoardLimitedThrows({
        boardId,
        filters: {
          periodStart: new Date(Date.UTC(date.getFullYear(), date.getMonth(), 1)),
          periodEnd: new Date(Date.UTC(date.getFullYear(), date.getMonth() + 1, 1)),
        },
      })
    );
  };

  handleJoinBoard = () => {
    const { clientUrl } = this.props

    window.open(`${clientUrl}/game?boardCode=${this.props.board.boardCode}`, "_blank")
  }

  render() {
    const {
      board,
      isLoading,
      isLoadingCurrentState,
      isLoadingStatistics,
      isLoadingLimitedThrows,
      isSnapshotPending,
      isStreaming,
      stream,
      hardwares,
      platforms,
      isAdmin,
      snapshotError
    } = this.props;
    const { streamConfig, usageFilters, connectionFilters, limitedThrowsFilters } = this.state;

    if (!board || (board && !board.firmware)) {
      return <i className="fa fa-spinner fa-spin fa-fw" />;
    }

    const { addressData, currentState, statistics, id, limitedThrows, isLimitedBoard, subscriptionMetadata, banHistory } = board;

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12">
            <Row>
              <Col xs="12" md="7">
                <MainInfo
                  {...board}
                  id={id}
                  currentState={currentState}
                  hardwares={hardwares}
                  platforms={platforms}
                  isLoading={isLoading || isLoadingCurrentState}
                  isAdmin={isAdmin}
                  canUpdate={this.props.role === 'Viewer' && board.ownerId === this.props.userId}
                />
              </Col>
              <Col xs="12" md="5">
                <AddressData addressData={addressData} />
                <BanHistory banHistory={banHistory} />
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <UsageStats
                  {...statistics}
                  isLoading={isLoadingStatistics}
                  filters={usageFilters}
                  onDateChange={this.handleDateChange.bind(this, 'usage')}
                  onDatePresetSelect={this.handleDatePresetSelect.bind(this, 'usage')}
                  onFilterSubmit={this.handleFilterSubmit}
                />
              </Col>
              <Col xs="12" md="6">
                <ConnectionHistory
                  boardId={id}
                  filters={connectionFilters}
                  onDateRangeChange={this.onConnectionFilterChange}
                />
              </Col>
            </Row>
            <Row>
              {isLimitedBoard && (
                <Col xs="12" md="6">
                  <LimitedThrows
                    limitedThrows={limitedThrows}
                    isLoading={isLoadingLimitedThrows}
                    filters={limitedThrowsFilters}
                    onDateChange={this.onLimitedThrowFilterChange}
                  />
                </Col>
              )}
              <Col xs="12" md={!isLimitedBoard ? "12" : "6"}>
                {currentState ? (
                  <GameInfo
                    {...currentState.gameData}
                    isLoading={isLoading}
                    onJoinBoard={this.handleJoinBoard}
                  />
                ) : (
                  <i className="fa fa-spinner fa-spin fa-fw" />
                )}
              </Col>
            </Row>

            {isLimitedBoard && subscriptionMetadata && (
              <PaymentHistory history={subscriptionMetadata.transactions} />
            )}

            <LiveStream
              isLoading={isLoading}
              isSnapshotPending={isSnapshotPending}
              isStreaming={isStreaming}
              stream={stream}
              isCalibrating={board.currentState ? (board.currentState.state === BoardState.Calibrating.type) : board.state === BoardState.Calibrating.type}
              streamConfig={streamConfig}
              onSnapshotClick={this.handleSnapshotClick}
              onStreamConfigurationChange={this.handleStreamConfigurationChange}
              onStreamToggle={this.handleStreamToggle}
              onCalibratePress={this.handleCalibrateClick}
              isAdmin={isAdmin}
              isStreamEnabled={currentState?.state && currentState?.state !== BoardState.Offline.type && currentState?.state !== BoardState.Initializing.type}
              snapshotError={snapshotError}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

Show.displayName = 'Boards.Show';

const mapStateToProps = (state, ownProps) => ({
  board: state.data.boards[ownProps.match.params.boardId],
  isLoading: state.scenes.boards.show.isLoading,
  isLoadingStatistics: state.scenes.boards.show.isLoadingStatistics,
  isLoadingCurrentState: state.scenes.boards.show.isLoadingCurrentState,
  isLoadingLimitedThrows: state.scenes.boards.show.isLoadingLimitedThrows,
  isSnapshotPending: state.scenes.boards.show.isSnapshotPending,
  isStreaming: state.scenes.boards.show.isStreaming,
  stream: state.scenes.boards.show.stream,
  hardwares: state.data.hardwares,
  platforms: state.data.platforms,
  isAdmin: getIsAdmin(state),
  role: getRole(state),
  userId: state.session._id,
  snapshotError: state.scenes.boards.show.snapshotError,
  clientUrl: state.configuration.clientUrl
});

export default connect(mapStateToProps)(Show);
