import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';

class Input extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // REVIEW: Shit -> https://github.com/facebook/react/issues/12188
      value: props.value, // Must include this here to be accessible from getDerivedStateFromProps
      errorMessage: undefined,
      isDirty: false,
    };
  }

  render() {
    const { hint, error, ...rest } = this.props;
    const hasError = Boolean(error);

    return (
      <div>
        <div>
          <input {...rest} />
        </div>
        {hasError && <Alert color="danger"><i className="fa fa-exclamation-triangle" />{error}</Alert>}
        {hint && <Alert color="secondary"><i className="fa fa-info-circle" />{hint}</Alert>}
      </div>
    );
  }
}

Input.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  validators: PropTypes.array,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  isSubmitted: PropTypes.bool,
};

Input.defaultProps = {
  validators: [],
};

export default Input;
