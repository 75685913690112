import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { HistoryTable } from './components/HistoryTable';

const PaymentHistory = ({ history }) => {
	return (
		<Card color="white">
			<CardHeader>
				<div className="card-header__left">
					<i className="fa fa-align-justify" />
					Payment History
				</div>
			</CardHeader>
			<CardBody>
				<HistoryTable history={history} />
			</CardBody>
		</Card>
	);
};

PaymentHistory.displayName = 'PaymentHistory';

PaymentHistory.propTypes = {
  history: PropTypes.array,
};

export default PaymentHistory;
