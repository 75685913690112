import React from 'react';
import cn from 'classnames';
import { useMedia } from 'react-use';
import { LG_M } from 'constants/Breakpoints';
import { ArrowIcon, ArrowIconDirection } from 'components/Icons';
import { SButton, SButtonVariantOptionsEnum, SizesEnum } from 'ScoliaComponents';
import { WizardPageTitles } from 'components/TournamentWizard/constants';
import styles from './styles.module.scss';
export const Pager = ({ nextTab, onNext, prevTab, onPrev }) => {
    const isLargeDisplay = useMedia(`(min-width: ${LG_M}px)`);
    if (!nextTab && !prevTab) {
        return null;
    }
    return (React.createElement("div", { className: cn(styles.tabContainer, { [styles.endAlign]: !prevTab }) },
        prevTab && (React.createElement(SButton, { onClick: onPrev, variant: SButtonVariantOptionsEnum.PrimaryOnBrand, className: styles.button, name: "prev", size: isLargeDisplay ? SizesEnum.Medium : SizesEnum.Small, type: "button" },
            React.createElement(ArrowIcon, { direction: ArrowIconDirection.Left }),
            ' ',
            WizardPageTitles[prevTab])),
        nextTab && (React.createElement(SButton, { onClick: onNext, variant: SButtonVariantOptionsEnum.PrimaryOnBrand, className: styles.button, name: "next", size: isLargeDisplay ? SizesEnum.Medium : SizesEnum.Small, type: "button" },
            WizardPageTitles[nextTab],
            ' ',
            React.createElement(ArrowIcon, { direction: ArrowIconDirection.Right })))));
};
