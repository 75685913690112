import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { SizesEnum } from '../enums'

import styles from './styles.module.scss'

export const SRadio = ({ size = SizesEnum.Medium, name, value, ...rest }) => {
  return (
    <div className={cn(styles.container, styles[size])}>
      <input className={styles.input} type="radio" id={`${name}-${value}`} name={name} value={value} {...rest} />
      <span className={styles.inputRadio} />
    </div>
  )
}

SRadio.displayName = 'SRadio';

SRadio.propTypes = {
  size: PropTypes.oneOf(Object.values(SizesEnum)),
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default SRadio