import React from 'react'
import PropTypes from 'prop-types'

import { SLabelContainer } from '../SLabelContainer'
import { SRadioDescriptionOption } from './SRadioDescriptionOption'

import styles from './styles.module.scss'

export const SRadioDescription = ({ name, label = '', showLabel = '', isRequired = false, options, value, error, hint, onChange }) => {
  return (
    <SLabelContainer name={name} label={label} showLabel={showLabel} isRequired={isRequired} error={error} hint={hint}>
      <div className={styles.options}>
        {options.map(op => (
          <SRadioDescriptionOption
            name={name}
            key={op.value}
            onChange={e => onChange({ ...e, target: { name, value: op.value }})}
            checked={op.value === value}
            {...op}
          />
        ))}
      </div>
    </SLabelContainer>
  )
}

SRadioDescription.displayName = 'SRadioDescription';

SRadioDescription.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  error: PropTypes.string,
  hint: PropTypes.string,
  showLabel: PropTypes.bool.isRequired,
  isRequired: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  })).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func.isRequired,
}

export default SRadioDescription