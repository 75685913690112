import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';

import { getPlatformById } from "data/Platforms/selectors"

import { getIsAdmin } from 'services/session/selectors';

import { getPlatform } from 'scenes/Platforms/actions';
import { MainInfo } from './components/MainInfo';

const Show = ({ match }) => {
  const dispatch = useDispatch()
  const platform = useSelector(getPlatformById(match.params.platformId));
  const isAdmin = useSelector(getIsAdmin)

  useEffect(() => {
    dispatch(getPlatform(match.params.platformId))
  }, [dispatch, match.params.platformId])

  if (!platform) {
    return null;
  }

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" lg={{ size: 10, offset: 1 }}>
          <MainInfo {...platform} isAdmin={isAdmin} />
        </Col>
      </Row>
    </div>
  )
}

Show.displayName = 'Platforms.Show';

export default Show
