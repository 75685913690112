import { call, put, takeLatest } from 'redux-saga/effects';

import { Notifications } from 'services/api';

import { callApi } from 'services/rest';
import { notifyError, notifySuccess } from 'services/notification/actions';

import {
  getNotifications,
  updateNotification,
  updateNotificationFailure,
  updateNotificationSuccess,
} from 'scenes/Notifications/actions';

function* watchUpdateNotification({
  payload: {
    notificationId,
    payload: { title, content, isActive, type },
  },
}) {
  try {
    yield call(callApi, Notifications.update, notificationId, {
      title,
      content,
      isActive,
      type,
    });
    yield put(updateNotificationSuccess());
    yield put(notifySuccess(`Notification (${notificationId}) has been updated successfully.`));

    yield put(getNotifications());
  } catch (e) {
    yield put(updateNotificationFailure(e));
    yield put(notifyError(`Failed to update notification ${notificationId}.`));
  }
}

export default () => takeLatest(updateNotification, watchUpdateNotification);
