import { call, put, takeLatest } from 'redux-saga/effects';

import { Boards } from 'services/api';
import { callApi } from 'services/rest';

import {
  upgradeLimitedBoardService,
  upgradeLimitedBoardServiceFailure,
  upgradeLimitedBoardServiceSuccess,
} from 'scenes/Boards/components/Show/actions';
import { getBoard } from 'scenes/Boards/actions';
import { notifySuccess, notifyError } from 'services/notification';

function* watchUpgradeLimitedBoardService({ payload: { boardId, isServiceActive } }) {
  try {
    const { data: isSuccessFull } = yield call(callApi, Boards.updateLimitedBoardService, boardId, isServiceActive);
    if (isSuccessFull) {
      yield put(upgradeLimitedBoardServiceSuccess(boardId));
      yield put(notifySuccess('Limited board\'s service changed successfully.'));
      yield put(getBoard(boardId))
    } else {
      yield put(upgradeLimitedBoardServiceFailure());
      yield put(notifyError('Couldn\'t change limited board\'s service.'));
    }
  } catch (e) {
    console.log(e);
    yield put(upgradeLimitedBoardServiceFailure());
    yield put(notifyError('Couldn\'t change limited board\'s service.'));
  }
}

// It must be wrapped in a function, so execution will happen only on function call
export default () => takeLatest(upgradeLimitedBoardService, watchUpgradeLimitedBoardService);
