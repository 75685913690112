import { put, take } from 'redux-saga/effects';

import { actions as wsActions } from 'services/ws';
import { updateBoard } from '../../actions';

export default function* watchBoardUpdate() {
  while (true) {
    const { payload } = yield take(wsActions.Api.publish);
    if( payload.channel !== 'tournament')
      yield put(updateBoard(payload));
  }
}
