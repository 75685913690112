import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { SizesEnum } from '../enums'

import styles from './styles.module.scss';

export const Tab = ({
  label,
  disabled,
  isSelected,
  onSelect,
  value,
  size,
  className,
}) => {
  const handleSelect = () => {
    onSelect(value);
  };

  return (
    <button
      onClick={handleSelect}
      className={cn(styles.tab, styles[size], { [styles.isSelected]: isSelected })}
      disabled={disabled}
      type="button"
    >
      <div className={cn(styles.label, styles[size], styles[className],{ [styles.isSelected]: isSelected })}>{label}</div>
    </button>
  );
};

export const STabs = ({
  tabs = [],
  value,
  onChange,
  theme,
  className,
  size = SizesEnum.Large,
  ...rest
}) => {
  return (
    <div className={cn(styles.tabsWrapper, styles[size])} {...rest}>
      {tabs.map(elem => (
        <Tab 
          key = {elem.value}
          isSelected={value === elem.value}
          size = { size }   
          onSelect={onChange}
          className={className}
          {...elem} />
      ))}
    </div>
  );
};

STabs.displayName = 'STabs';

STabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.node,
      ]).isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    })
  ),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  size: PropTypes.oneOf(Object.values(SizesEnum)),
};

export default STabs;
