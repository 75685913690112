import { call, put, takeLatest } from 'redux-saga/effects';

import { Boards } from 'services/api';
import { browserHistory } from 'services/history';
import { callApi } from 'services/rest';

import {
  deleteBoard,
  deleteBoardFailure,
  deleteBoardSuccess,
} from 'scenes/Boards/components/Show/actions';

function* watchDeleteBoard({ payload }) {
  try {
    yield call(callApi, Boards.delete, `${payload}`);
    yield put(deleteBoardSuccess());
    browserHistory.push(`/boards`);
  } catch (e) {
    console.log(e);
    yield put(deleteBoardFailure());
  }
}

// It must be wrapped in a function, so execution will happen only on function call
export default () => takeLatest(deleteBoard, watchDeleteBoard);
