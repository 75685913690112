import { all } from 'redux-saga/effects'

import { sagas as createSaga } from './components/Create'
import { sagas as importSaga } from './components/Import'
import { sagas as listSaga } from './components/List'
import { sagas as editSaga } from './components/Edit'
import { sagas as showSaga } from './components/Show'

export default function* tournamentsSaga() {
  yield all([
    createSaga(),
    listSaga(),
    importSaga(),
    editSaga(),
    showSaga(),
  ])
}
