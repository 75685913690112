import { TournamentParticipantStatusEnum, TournamentRegistrationType } from 'enums';
import { Participant } from 'interfaces';

export const getNumberOfParticipants = (
  participantsList: Participant[],
  registrationType: TournamentRegistrationType
) => {
  if (registrationType === TournamentRegistrationType.Invitational) {
    return participantsList.filter(
      (participant) => participant.status === TournamentParticipantStatusEnum.Accepted
    ).length;
  } else {
    return participantsList.length;
  }
};
