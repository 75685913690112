import React, { Component, Fragment } from 'react';
import { Collapse, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { ToggleFilter } from 'scenes/StoreSnapshot/components/StoreTable/components/ToggleFilter';

import styles from './styles.module.scss';

class WebSocketConnection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  open() {
    this.setState({ isOpen: true });
  }

  close() {
    this.setState({ isOpen: false });
  }

  isOpened() {
    return this.state.isOpen;
  }

  render() {
    const { type, id, entityUrl, entityLabel, wsConnections, handleDelete, isAdmin } = this.props;
    return (
      <Fragment>
        <Row>
          <div className={styles.wrapper}>
            <Col xs={2} className={styles.type}>
              {type}
            </Col>
            <Col xs={5} className={styles.id}>
              {id}
            </Col>
            <Col xs={5} className={styles.entity}>
              {entityUrl ? (
                <Link to={entityUrl}>{entityLabel}</Link>
              ) : (
                  entityLabel
                )}
            </Col>

            {wsConnections.length > 0 && (
              <i
                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                className={cn(
                  styles.toggleIcon,
                  `fa fa-angle-${this.state.isOpen ? 'up' : 'down'}`
                )}
              />
            )}
          </div>
        </Row>
        <div
          className={cn([
            styles.border,
            { [styles.borderActive]: this.state.isOpen },
          ])}
        />
        <Collapse
          isOpen={this.state.isOpen}
          onEntering={this.props.onChange}
          onExiting={this.props.onChange}
        >
          {wsConnections.length > 0 && (
            <div className={styles.collapse}>
              <ToggleFilter
                readonly
                label="Websocket Connections"
                count={wsConnections.length}
              />
              <ul className={styles.wsList}>
                {wsConnections.map(connectionKey =>
                  <li key={connectionKey.wsId ?? connectionKey}>
                    {type !== "ServiceAccount" ? connectionKey :  (
                      <div>
                        {`${connectionKey.wsId} `}
                        <Link to={`/boards/${connectionKey.boardId}`}>
                          ({connectionKey.serialNumber})
                        </Link>
                      </div>
                    ) }
                    {isAdmin && <i className='fa fa-lg fa-trash'
                       onClick={() => handleDelete(connectionKey)}
                       aria-hidden='true'></i>}
                  </li>
                )}
              </ul>
            </div>
          )}
        </Collapse>
      </Fragment>
    );
  }
}

WebSocketConnection.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  entityLabel: PropTypes.string.isRequired,
  entityUrl: PropTypes.string.isRequired,
  wsConnections: PropTypes.array.isRequired,
  handleDelete: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
};

export default WebSocketConnection;
