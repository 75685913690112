import { call, put, takeLatest } from 'redux-saga/effects';

import { StoreSnapshot } from 'services/api';
import { callApi } from 'services/rest';

import {
  deleteLobbyInvitation,
  deleteLobbyInvitationFailure,
  deleteLobbyInvitationSuccess,
  getStoreSnapshot
} from 'scenes/StoreSnapshot/components/actions';

function* watchDeleteLobbyInvitation({ payload }) {
  try {
    yield call(callApi, StoreSnapshot.deleteLobbyInvitation, payload);
    yield put(deleteLobbyInvitationSuccess());
    yield put(getStoreSnapshot());
  } catch (e) {
    console.log(e);
    yield put(deleteLobbyInvitationFailure());
  }
}

export default () => takeLatest(deleteLobbyInvitation, watchDeleteLobbyInvitation);
