import { TournamentParticipationType } from 'enums';
export const tournamentTypeOptions = [
    {
        title: "Online",
        description: "All tournament games are played in an Online Game format, using two Scolia devices per game.",
        value: TournamentParticipationType.Online,
        disabled: false,
    },
    {
        title: "Local",
        description: "All tournament games are played in a Local Game format, using a single Scolia device per game.",
        value: TournamentParticipationType.Local,
        disabled: true,
        badgeProps: {
            children: 'Coming Soon!',
            backgroundColor: '#EFF7E8',
            textColor: '#2B6B0A',
            style: { border: '1px solid #BFE0AE', fontWeight: '500' },
        }
    },
];
export const participantsOptions = [
    { label: 4, value: 4 },
    { label: 8, value: 8 },
    { label: 16, value: 16 },
    { label: 32, value: 32 },
    { label: 64, value: 64 },
    { label: 128, value: 128 },
    //{ label: 256, value: 256 },
];
export const maxParticipantsOptions = [
    { label: 4, value: 4 },
    { label: 8, value: 8 },
    { label: 16, value: 16 },
    { label: 32, value: 32 },
    { label: 64, value: 64 },
    { label: 128, value: 128 },
    // { label: 256, value: 256 },
];
export const minParticipantsOptions = [
    { label: 4, value: 4 },
    { label: 8, value: 8 },
    { label: 16, value: 16 },
    { label: 32, value: 32 },
    { label: 64, value: 64 },
    { label: 128, value: 128 },
    //{ label: 256, value: 256 },
];
