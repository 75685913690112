import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import { PlatformForm } from 'scenes/Platforms/components/PlatformForm';

import { getIsAdmin } from 'services/session/selectors';

import actions from './actions';
import { getCreateError } from "./selectors"

const Create = () => {
  const dispatch = useDispatch()

  const formError = useSelector(getCreateError)
  const isAdmin = useSelector(getIsAdmin)

  const handleFormSubmit = (values, _actions) =>
    dispatch(
      actions.createPlatform({
        platform: values,
      })
    );

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" lg={{ size: 10, offset: 1 }}>
          <Card>
            <CardHeader>
              <div className="card-header__left">
                <i className="fa fa-align-justify" /> New Platform
              </div>
            </CardHeader>
            <CardBody>
              <PlatformForm
                formError={formError}
                onSubmit={handleFormSubmit}
                isAdmin={isAdmin}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Create
