import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import { List } from './components/List';

class OnlineBoards extends Component {
  render() {
    const {
      match: { url },
    } = this.props;

    return (
      <Switch>
        <Route component={List} exact name="Online boards" path={`${url}/`} />
      </Switch>
    );
  }
}

export default OnlineBoards;
