import React, { Component } from 'react';
import { Card, CardBody, CardText, CardTitle, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './styles.module.scss';

class Store extends Component {
  card = (key, label) => {
    return (
      <Col xs={12} sm={12} md={4} lg={3} key={'summary-card_' + key}>
        <Card color="white">
          <CardBody>
            <div className="h1 text-muted text-end mb-4">
              <i className={this.cardIconClasses[key]} />
            </div>
            <CardTitle>
              <strong className={cn('text-value h4', styles.textValue)}>
                {this.props.isLoading ? <i className="fa fa-spinner fa-spin fa-fw" /> : this.cardValues[key]}
              </strong>
            </CardTitle>
            <CardText>
              <small className="text-muted text-uppercase fw-bold">{label}</small>
            </CardText>
          </CardBody>
        </Card>
      </Col>
    );
  };

  get cardIconClasses() {
    return {
      usedMemory: 'icon-disc',
      datasetSize: 'icon-layers',
      pubSubChannelsCount: 'icon-info',
      keysCount: 'icon-key',
    };
  }

  get cardValues() {
    const usedMemory = this.bytesToSize(this.props.usedMemory);
    const datasetSize = this.bytesToSize(this.props.datasetSize);
    const pubSubChannelsCount = this.props.pubSubChannelsCount;
    const keysCount = this.props.keysCount;

    return {
      usedMemory,
      datasetSize,
      pubSubChannelsCount,
      keysCount,
    };
  }

  bytesToSize(bytes, seperator = ' ') {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return 'n/a';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) return `${bytes}${seperator}${sizes[i]}`;
    return `${(bytes / 1024 ** i).toFixed(1)}${seperator}${sizes[i]}`;
  }

  get cardLabels() {
    return [
      { key: 'usedMemory', label: 'Used Memory' },
      { key: 'datasetSize', label: 'Dataset Size' },
      {
        key: 'pubSubChannelsCount',
        label: `Pub/Sub Channels Count`,
      },
      { key: 'keysCount', label: 'Keys Count' },
    ];
  }

  get cards() {
    return this.cardLabels.map(({ key, label }) => this.card(key, label));
  }

  render() {
    return <Row>{this.cards}</Row>;
  }
}

Store.propTypes = {
  usedMemory: PropTypes.number,
  datasetSize: PropTypes.number,
  pubSubChannelsCount: PropTypes.number,
  keysCount: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
};

Store.defaultProps = {
  isLoading: true,
};

export default Store;
