import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

import { MainInfo } from './components/MainInfo';

import { getIsAdmin } from 'services/session/selectors';

import { getFirmware } from 'scenes/Firmwares/actions';
import { actions as hardwareActions } from 'scenes/Hardwares/components/List';

class Show extends Component {
  componentDidMount() {
    const { match } = this.props;

    this.props.dispatch(getFirmware(match.params.firmwareId));

    this.props.dispatch(hardwareActions.getHardwares());
  }

  render() {
    const {
      firmware,
      hardwares,
      isHardwaresLoading,
      file,
      filename,
      isFirmwareDownloading,
      isAdmin,
    } = this.props;

    if (!firmware || isHardwaresLoading) {
      return <i className="fa fa-spinner fa-spin fa-fw" />;
    }

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12">
            <Row>
              <Col xs="10">
                <MainInfo
                  {...firmware}
                  hardwares={hardwares}
                  file={file}
                  filename={filename}
                  isFirmwareDownloading={isFirmwareDownloading}
                  isAdmin={isAdmin}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

Show.displayName = 'Firmwares.Show';

const mapStateToProps = (state, ownProps) => {
  return {
    firmware: state.data.firmwares[ownProps.match.params.firmwareId],
    hardwares: state.data.hardwares,
    isHardwaresLoading: state.scenes.hardwares.list.isLoading,
    file: state.data.download.file,
    filename: state.data.download.filename,
    isFirmwareDownloading: state.scenes.firmwares.show.isDownloading,
    isAdmin: getIsAdmin(state),
  };
};

export default connect(mapStateToProps)(Show);
