import React from 'react';
import { useQuery } from 'react-query';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Message } from 'scenes/Chat/components/Show/components/Message';
import { get } from 'services/rest';
import styles from './styles.module.scss';
export const InstantMessagesModal = ({ isModalOpen, toggleModal, gameId, }) => {
    const url = `/instant-messages/${gameId}`;
    const { isLoading, error, data } = useQuery([url, gameId], async () => {
        const data = (await get(url));
        return data?.data?.messages;
    });
    return (React.createElement(Modal, { isOpen: isModalOpen, toggle: toggleModal },
        React.createElement(ModalHeader, { toggle: toggleModal }, "Instant Messages"),
        React.createElement(ModalBody, null, isLoading ? (React.createElement("i", { className: "fa fa-spinner fa-spin fa-fw" })) : error ? (React.createElement("div", null, "Something went wrong!")) : (data && (React.createElement("div", { className: styles.chatWrapper }, data.map((msg) => (React.createElement(Message, { key: msg._id, message: msg, isInstantMessage: true }))))))),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { color: "secondary", onClick: toggleModal }, "Close"))));
};
