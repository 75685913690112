import { eventBus } from 'eventBus';
import { useCallback } from 'react';
import { QueryClient, useQueryClient } from 'react-query';
import { useEvent } from 'react-use';

export const useEventBus = (
  type: string,
  listener: (payload: unknown, queryClient: QueryClient) => void
) => {
  const queryClient = useQueryClient();
  const callback = useCallback(
    (payload: unknown) => listener(payload, queryClient),
    [queryClient, listener]
  );

  useEvent(type, callback, eventBus);
};
