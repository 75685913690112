import React from 'react';
import cn from 'classnames';
import { firstXAverage } from '../../utils';
import styles from '../../styles.module.scss';
export const X01Statistics = ({ userStats }) => {
    const { average, checkout } = userStats;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: cn(styles.rowHeader, 'col-sm-4') }, "First X Avg"),
            React.createElement("div", { className: cn(styles.rowHeader, 'col-sm-4') }, "Avg"),
            React.createElement("div", { className: cn(styles.rowHeader, 'col-sm-4') }, "Checkout")),
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col-sm-4" }, firstXAverage(userStats)),
            React.createElement("div", { className: "col-sm-4" }, average && average.value ? average.value.toFixed(2) : '-'),
            React.createElement("div", { className: "col-sm-4" }, checkout && checkout.double.percentage
                ? `${checkout.double.percentage.toFixed(2)}%`
                : '-'))));
};
