import { createAction } from 'redux-actions';

export const getManufacturerBoards = createAction('MANUFACTURER_BOARDS_GET_REQUEST');
export const getManufacturerBoardsFailure = createAction('MANUFACTURER_BOARDS_GET_FAILURE');
export const getManufacturerBoardsSuccess = createAction('MANUFACTURER_BOARDS_GET_SUCCESS');

export const deleteManufacturer = createAction('DELETE_MANUFACTURER_REQUEST');
export const deleteManufacturerFailure = createAction('DELETE_MANUFACTURER_FAILURE');
export const deleteManufacturerSuccess = createAction('DELETE_MANUFACTURER_SUCCESS');

export default {
  getManufacturerBoards,
  getManufacturerBoardsFailure,
  getManufacturerBoardsSuccess,
  deleteManufacturer,
  deleteManufacturerFailure,
  deleteManufacturerSuccess,
};
