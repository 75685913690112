import { createAction } from 'redux-actions';

export const getOnlineBoards = createAction(
  'ONLINE_BOARDS_GET_REQUEST',
  (queryParams, isLoading = true) => ({
    queryParams,
    isLoading,
  })
);

export const getOnlineBoardsFailure = createAction('ONLINE_BOARDS_GET_FAILURE');

export const getOnlineBoardsSuccess = createAction('ONLINE_BOARDS_GET_SUCCESS');

export default {
  getOnlineBoards,
  getOnlineBoardsFailure,
  getOnlineBoardsSuccess,
};
