import { call, put, takeLatest } from 'redux-saga/effects';

import { Users } from 'services/api';
import { notifyError, notifySuccess } from 'services/notification/actions';

import {
  updateUser,
  updateUserFailure,
  updateUserSuccess,
} from 'scenes/Users/components/Show/components/Details/actions';
import { getUserSuccess, getUser } from 'scenes/Users/components/Show/actions';

import { callApi } from 'services/rest';
import { changedKeys, splitCamelCase, isNullOrUndefined } from 'utils';

import { EMAIL_ALREADY_TAKEN } from 'constants/ApiErrors';

function* watchEditUser({
  payload: {
    userId,
    email,
    userRole,
    boardExcessiveUsageEmail,
    boardExcessiveUsageNotification,
    prevUserProps,
    isBannedFromTournaments,
    bannedPeriod,
    isEmailActive,
    tokenBalance
  },
}) {
  try {
    // If the email is defined, update the email only
    if (!isNullOrUndefined(email)) {
      // call the api, get the data
      const { data: updatedUser } = yield call(callApi, Users.update, userId, { email });

      // set the isLoading state false
      yield put(updateUserSuccess());

      // handle notifications
      yield put(notifySuccess('E-mail address has been changed successfully.'));

      // update the page so it will render based on the valid props
      yield put(getUserSuccess(updatedUser));
    } else if (!isNullOrUndefined(tokenBalance)) {
      // call the api, get the data
      const { data: updatedUser } = yield call(callApi, Users.update, userId, { tokenBalance });

      // set the isLoading state false
      yield put(updateUserSuccess());

      // handle notifications
      yield put(notifySuccess('Token balance has been changed successfully.'));

      // update the page so it will render based on the valid props
      yield put(getUserSuccess(updatedUser));
    } else {
      // call the api, get the data
      const { data: updatedUser } = yield call(callApi, Users.update, userId, {
        role: userRole,
        boardExcessiveUsageEmail: boardExcessiveUsageEmail,
        boardExcessiveUsageNotification: boardExcessiveUsageNotification,
        isBannedFromTournaments: isBannedFromTournaments,
        bannedPeriod: bannedPeriod,
        isEmailActive: isEmailActive,
      });

      // set the isLoading state false
      yield put(updateUserSuccess());

      // handle notifications
      const changedSettings = changedKeys(
        {
          userRole: prevUserProps.role,
          boardExcessiveUsageEmail: prevUserProps.boardExcessiveUsageEmail,
          boardExcessiveUsageNotification: prevUserProps.boardExcessiveUsageNotification,
          isBannedFromTournaments: prevUserProps.isBannedFromTournaments,
          bannedPeriod: prevUserProps.bannedPeriod,
          isEmailActive: prevUserProps.isEmailActive,
        },
        {
          userRole,
          boardExcessiveUsageEmail,
          boardExcessiveUsageNotification,
          isBannedFromTournaments,
          bannedPeriod,
          isEmailActive,
        }
      )
        .map((item, index) => `${index > 0 ? ', ' : ''}${splitCamelCase(item)}`)
        .join('');

      if (changedSettings.length > 0) {
        yield put(notifySuccess(`The following settings has been changed successfully: ${changedSettings}.`));
      }

      // update the page so it will render based on the valid props
      yield put(getUserSuccess(updatedUser));
    }
  } catch (e) {
    if (!e.isFetch) {
      throw e;
    }

    const failedSettings = e?.data?.payload
      ?.map((item, index) => `${index > 0 ? ', ' : ''}${splitCamelCase(item.property)}`)
      .join('');

    // set the isLoading state false
    yield put(updateUserFailure(e.error));

    // handle notifications
    if (failedSettings?.length > 0) {
      yield put(notifyError(`Couldn't change the following settings: ${failedSettings}.`));
    }

    if (e?.data?.error) {
      if (e.data.error === EMAIL_ALREADY_TAKEN) {
        yield put(notifyError('This email address is already taken.'));
      } else {
        yield put(notifyError(e.data.error));
      }
    }

    // update the page so it will render based on the valid props
    yield put(getUser(userId));
  }
}

export default () => takeLatest(updateUser, watchEditUser);
