import makeTypedNotificationWatcher from '../makeTypedNotificationWatcher';

import { notifySuccess } from 'services/notification/actions';
// TODO: Register (info) actions here
const actions = [];

export default makeTypedNotificationWatcher({
  actions,
  actionCreator: notifySuccess,
});
