import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { Translation } from 'react-i18next'

import { Toast } from 'components/Toast'

import styles from './styles.module.scss'

const MAX_CONCURRENT_NOTIFICATIONS = 5

const CSS_TRANSITION_CLASSNAMES = {
  // appear: 'my-appear',
  // appearActive: 'my-active-appear',
  enter: styles.fadeEnter,
  enterActive: styles.fadeEnterActive,
  // enterDone: 'my-done-enter',
  exit: styles.fadeExit,
  exitActive: styles.fadeExitActive,
  // exitDone: 'my-done-exit',
}

class Notifications extends Component {
  render() {
    // REVIEW: Might be only rendered when neccessary
    // if (!this.props.notifications.length) {
    //   return null;
    // }

    return (
      <Translation ns={['apiErrors']}>
        {_t => (
          <TransitionGroup className={styles.notifications}>
            {this.props.notifications.slice(MAX_CONCURRENT_NOTIFICATIONS * -1).map(n => (
              <CSSTransition key={n.id} timeout={500} classNames={CSS_TRANSITION_CLASSNAMES}>
                <div className={styles.notification}>
                  <Toast
                    type={n.type}
                    // message={t(n.message)} Somehow the translator splits the input string at some special characters like: \:, \., etc..
                    message={n.message}
                    title={n.title}
                  />
                </div>
              </CSSTransition>
            ))}
          </TransitionGroup>
        )}
      </Translation>
    )
  }
}

const mapStateToProps = state => ({
  notifications: state.notifications,
})

export default connect(mapStateToProps)(Notifications)
