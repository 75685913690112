import React, { Component, createRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { DateRangePicker } from 'react-date-range';
import { Alert, Badge, Col, Row } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import cn from 'classnames';

import { default as formatDate } from 'utils/formatDate';
import { datesEqual } from 'utils';

import styles from './styles.module.scss';

export default class ActiveBoards extends Component {
  constructor(props) {
    super(props);

    this.dateWrapperRef = createRef();

    const { firstDay, lastDay } = this.getFirstAndLastDay();

    this.state = {
      range: {
        startDate: firstDay,
        endDate: lastDay,
        key: 'dateRange',
      },
      form: {
        minDays: 4,
        minThrows: 30,
      },
      dateRangePickerVisible: false,
    };
  }

  componentDidMount() {
    this.getBoardsActivity();
  }

  getValidationSchema = () =>
    Yup.object().shape({
      minDays: Yup.number()
        .min(1, 'Minimum 1 Day')
        .max(31, 'Maximum 31 Days')
        .required('Minimum days are required!'),
      minThrows: Yup.number()
        .min(1, 'Minimum 1')
        .required('Minimum throws are required'),
    });

  handleDateInputClick = _e => {
    this.setState({
      ...this.state,
      dateRangePickerVisible: !this.state.dateRangePickerVisible,
    });
  };

  getFirstAndLastDay = (
    date = (() => {
      // Default last month
      const date = new Date();
      date.setMonth(date.getMonth() - 1);
      return date;
    })()
  ) => ({
    firstDay: new Date(date.getFullYear(), date.getMonth(), 1),
    lastDay: new Date(date.getFullYear(), date.getMonth() + 1, 0),
  });

  closeDateRangePicker = e => {
    if (this.state.dateRangePickerVisible && !this.dateWrapperRef.current.contains(e.target)) {
      this.setState({ dateRangePickerVisible: false });
      this.getBoardsActivity();
    }
  };

  handleDateRangeChange = ({ dateRange }) => {
    if (
      this.definedRanges.find(
        item =>
          datesEqual(item.range().startDate, dateRange.startDate) &&
          datesEqual(item.range().endDate, dateRange.endDate)
      )
    ) {
      this.setState({ dateRangePickerVisible: false });
      this.setState({ range: dateRange }, this.getBoardsActivity);
    } else {
      this.setState({ range: dateRange });
    }
  };

  handleClear = () => {
    const { firstDay, lastDay } = this.getFirstAndLastDay();

    this.setState({
      range: {
        startDate: firstDay,
        endDate: lastDay,
        key: 'dateRange',
      },
    });

    this.getBoardsActivity();
  };

  getBoardsActivity = () => {
    const {
      form: { minDays, minThrows },
      range: { startDate, endDate },
    } = this.state;

    this.props.onSubmit({
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      minDays,
      minThrows,
    });
  };

  createDefinedRange = (label, startDate, endDate) => {
    return {
      label,
      hasCustomRendering: false,
      range: () => ({
        startDate,
        endDate,
      }),
      isSelected: () => {
        const { startDate: sDate, endDate: eDate } = this.state.range;
        return datesEqual(sDate, startDate) && datesEqual(eDate, endDate);
      },
    };
  };

  get definedRanges() {
    const months = [...new Array(12)].map((_, index) => {
      const date = new Date();
      date.setMonth(index);

      const { firstDay, lastDay } = this.getFirstAndLastDay(date);

      const month = date.toLocaleString('en-Us', { month: 'long' });

      return this.createDefinedRange(month, firstDay, lastDay);
    });

    const date = new Date();
    let { firstDay, lastDay } = this.getFirstAndLastDay(date);

    const actualMonth = this.createDefinedRange('Actual month', firstDay, lastDay);

    date.setMonth(date.getMonth() - 1);

    ({ firstDay, lastDay } = this.getFirstAndLastDay(date));

    const lastMonth = this.createDefinedRange('Last month', firstDay, lastDay);

    return [lastMonth, actualMonth, ...months];
  }

  get activeBoards() {
    let boardsActivity = this.props.boardsActivity;
    boardsActivity = boardsActivity.sort((a) => (a.isActive ? -1 : 1));
    return boardsActivity;
  }

  render() {
    const { onNormalViewClick, isLoading } = this.props;
    const { firstDay, lastDay } = this.getFirstAndLastDay();
    const {
      form,
      range: { startDate, endDate },
    } = this.state;

    const isDefaultDateSet = datesEqual(startDate, firstDay) && datesEqual(endDate, lastDay);

    return (
      <div className={styles.wrapper} onClick={this.closeDateRangePicker}>
        <div className={styles.headerWrapper}>
          <div className={styles.labelIcon}>
            <i className="fas fa-wallet" /> Service Account Billing
          </div>

          <div className={styles.filtersWrapper}>
            <div className={styles.dateInputWrapper}>
              <div className={styles.labelWrapper}>Date Range</div>
              <span>
                <input
                  value={`${formatDate(this.state.range.startDate)} - ${formatDate(
                    this.state.range.endDate
                  )}  `}
                  readOnly={true}
                  onClick={this.handleDateInputClick}
                />
                {!isDefaultDateSet && <i onClick={this.handleClear} className="fas fa-times" />}
              </span>
            </div>
            <span ref={this.dateWrapperRef} style={{ position: 'absolute' }}>
              <DateRangePicker
                onChange={this.handleDateRangeChange}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={[this.state.range]}
                direction="horizontal"
                staticRanges={this.definedRanges}
                inputRanges={[]}
                className={cn(styles.dateRangePicker, {
                  [styles.hidden]: !this.state.dateRangePickerVisible,
                })}
              />
            </span>

            <Formik
              initialValues={form}
              validationSchema={this.getValidationSchema}
              onSubmit={this.getBoardsActivity}
            >
              {({ errors, values, handleChange, handleSubmit }) => (
                <form onSubmit={handleSubmit} className={styles.form}>
                  <div className={styles.minDaysWrapper}>
                    <div className={styles.labelWrapper}>Min. Days</div>
                    <input
                      name="minDays"
                      value={values.minDays}
                      onChange={e => {
                        this.setState({
                          form: { ...this.state.form, minDays: e.target.value },
                        });
                        handleChange(e);
                      }}
                      type="number"
                    />
                    {errors.minDays && (
                      <Alert color="danger" className={cn('alert-select', styles.errorMessage)}>
                        <i className="fa fa-exclamation-triangle" />
                        {errors.minDays}
                      </Alert>
                    )}
                  </div>

                  <div className={styles.minThrowsWrapper}>
                    <div className={styles.labelWrapper}>Min. Throws</div>
                    <input
                      name="minThrows"
                      value={values.minThrows}
                      onChange={e => {
                        this.setState({
                          form: {
                            ...this.state.form,
                            minThrows: e.target.value,
                          },
                        });
                        handleChange(e);
                      }}
                      type="number"
                    />
                    {errors.minThrows && (
                      <Alert color="danger" className={cn('alert-select', styles.errorMessage)}>
                        <i className="fa fa-exclamation-triangle" />
                        {errors.minThrows}
                      </Alert>
                    )}
                  </div>

                  <button
                    className="btn btn-primary btn-sm"
                    style={{ borderRadius: '5px' }}
                    type="submit"
                  >
                    Refresh
                  </button>
                </form>
              )}
            </Formik>
          </div>
          <div className={styles.button}>
            <button className="btn btn-primary btn-sm" type="button" onClick={onNormalViewClick}>
              Normal View
            </button>
          </div>
        </div>
        {isLoading ? (
          <div className={styles.spinnerWrapper}>
            <i className="fa fa-spinner fa-spin fa-fw" />
          </div>
        ) : this.activeBoards.length > 0 ? (
          <div className={styles.listWrapper}>
            <div className={styles.listHeader}>
              <Row className={styles.headerRow}>
                <Col style={{ fontWeight: 500, textAlign: 'left' }}>Serial Number</Col>
                <Col>Total Throws</Col>
                <Col>Active Days</Col>
                <Col>Board is active</Col>
              </Row>
            </div>

            <div className={styles.listItems}>
              {this.activeBoards.map(board => (
                <div key={board.serialNumber} className={styles.listItem}>
                  <Row className={styles.listItemRow}>
                    <Col style={{ textAlign: 'left' }}><Link to={`/boards/${board.boardId}`}>{board.serialNumber}</Link></Col>
                    <Col>{board.throws}</Col>
                    <Col>{board.activeDayCount}</Col>
                    <Col>
                      <Badge
                        color={cn({
                          success: board.isActive,
                          secondary: !board.isActive,
                        })}
                      >
                        {board.isActive ? 'Active' : 'Inactive'}
                      </Badge>
                    </Col>
                  </Row>
                </div>
              ))}
            </div>

            <div className={styles.listFooter}>
              <div className={styles.activeBoardsWrapper}>
                <div className={styles.labelWrapper}>Active Boards</div>
                <span>{this.activeBoards.filter(i => i.isActive).length}</span>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.noActiveBoardsLabel}>No active boards</div>
        )}
      </div>
    );
  }
}

ActiveBoards.propTypes = {
  onNormalViewClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  boardsActivity: PropTypes.array,
};

ActiveBoards.defaultProps = {
  boardsActivity: [],
};
