import React, { useState } from 'react';
import cn from 'classnames';
import { Formik } from 'formik';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import * as Yup from 'yup';

import formatLabel from 'utils/formatLabel';

import { Input } from 'components/Form';

import styles from './styles.module.scss';

const EditableEmail = ({ onSubmit, value }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const form = {
    email: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Required')
      .strict(),
  });

  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const handleSubmit = () => {
    onSubmit(inputValue);
    toggleModal();
  };

  return (
    <div className="row">
      <div className="col-sm-4">{formatLabel('Email')}</div>
      <div className={cn('col-sm-8', styles.editWrapper)}>
        <span>{value}</span>
        <i onClick={toggleModal} className="fa fa-fw fa-edit" />
      </div>
      <Formik initialValues={form} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ errors, touched, values, handleSubmit, setFieldValue }) => (
          <Modal isOpen={isModalOpen} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Email address update</ModalHeader>
            <ModalBody>
              <form onSubmit={handleSubmit}>
                <dl className={cn('row', styles.emailInputWrapper)}>
                  <dt className="col-sm-2 required">Email</dt>
                  <dd className="col-sm-10 mb-0">
                    <Input.Text
                      name="email"
                      className={styles.emailInput}
                      placeholder={value}
                      value={values.email}
                      error={touched.email && errors.email}
                      onChange={event => {
                        setInputValue(event.currentTarget.value);
                        setFieldValue('email', event.currentTarget.value);
                      }}
                    />
                  </dd>
                </dl>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={handleSubmit}
                disabled={Object.keys(errors).length > 0}
              >
                Submit
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </Formik>
    </div>
  );
};

export default EditableEmail;
