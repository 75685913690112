import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Bar, BarChart, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis, } from 'recharts';
import { Alert, Button, Card, CardBody, CardHeader, Col, FormGroup, Label, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import cn from 'classnames';
import { actions as notificationActions } from 'services/notification';
import { formatNumberWithThousandSeparators } from 'utils';
import { getSystemUsage, downloadSystemUsage } from './actions';
import styles from './styles.module.scss';
const INTERVALS = ['day', 'week', 'month'];
const PERIODS = [
    { key: 'allTime', label: 'All Time' },
    { key: 'year', label: 'Last Year' },
    { key: 'month', label: 'Last Month' },
    { key: 'week', label: 'Last Week' },
];
const minDate = 2015;
const maxDate = 2050;
const DateAlerts = ({ dateFrom, dateTo, maxDate, minDate }) => {
    const fromYear = dateFrom?.getFullYear() ?? 0;
    const toYear = dateTo?.getFullYear() ?? 0;
    const now = new Date();
    now.setHours(23, 59, 59);
    const dateNow = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();
    const fromDatems = dateFrom
        ? new Date(fromYear, dateFrom.getMonth(), dateFrom.getDate()).getTime()
        : null;
    const toDatems = dateTo ? new Date(toYear, dateTo.getMonth(), dateTo.getDate()).getTime() : null;
    const datesHasNoUnion = (dateFrom && dateTo && dateFrom > dateTo) ?? false;
    return (React.createElement(Fragment, null,
        React.createElement(Col, { xs: 12 },
            React.createElement(Alert, { color: "danger", isOpen: fromDatems ? dateNow < fromDatems : false }, "From date cannot set to future date!")),
        React.createElement(Col, { xs: 12 },
            React.createElement(Alert, { color: "danger", isOpen: toDatems ? dateNow < toDatems : false }, "To date cannot set to future date!")),
        React.createElement(Col, { xs: 12 },
            React.createElement(Alert, { color: "danger", isOpen: datesHasNoUnion }, "From date is later than to date!")),
        React.createElement(Col, { xs: 12 },
            React.createElement(Alert, { color: "danger", isOpen: (dateFrom ? dateFrom && dateFrom.getFullYear() > maxDate : true) ||
                    (dateTo ? dateTo && dateTo.getFullYear() > maxDate : true) },
                "Maximum year is ",
                maxDate,
                "!")),
        React.createElement(Col, { xs: 12 },
            React.createElement(Alert, { color: "danger", isOpen: (dateFrom ? dateFrom && dateFrom.getFullYear() < minDate : true) ||
                    (dateTo ? dateTo && dateTo.getFullYear() < minDate : true) },
                "Minimum year is ",
                minDate,
                "!"))));
};
const SystemUsageChart = ({ chartData, shouldShowThrowsByType, shouldShowGames, shouldShowThrowsByGames, isValueVisible, }) => {
    let order = 100;
    return (React.createElement(ResponsiveContainer, { width: "100%", height: 400 },
        React.createElement(BarChart, { data: chartData, margin: {
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            } },
            React.createElement(XAxis, { dataKey: "label" }),
            React.createElement(YAxis, null),
            React.createElement(Tooltip, { formatter: (number) => formatNumberWithThousandSeparators(number, ','), itemSorter: () => order-- }),
            React.createElement(Legend, null),
            shouldShowThrowsByType && (React.createElement(Bar, { dataKey: "automaticallyDetectedThrows", name: "Automatically detected throws", stackId: "throws", fill: "#20A8DA", isAnimationActive: false })),
            shouldShowThrowsByType && (React.createElement(Bar, { dataKey: "addedOrModifiedThrows", name: "Added/Modified throws", stackId: "throws", fill: "#ffc658", isAnimationActive: false })),
            shouldShowThrowsByType && (React.createElement(Bar, { dataKey: "practiceGameThrows", name: "Practice game throws", stackId: "throws", fill: "#8481DD", isAnimationActive: false })),
            shouldShowThrowsByType && (React.createElement(Bar, { dataKey: "outcastThrows", name: "Outcast throws", stackId: "throws", fill: "#82ca9d", isAnimationActive: false }, isValueVisible && (React.createElement(LabelList, { formatter: (number) => formatNumberWithThousandSeparators(number, ','), dataKey: "totalThrows", position: "top" })))),
            shouldShowGames && (React.createElement(Bar, { dataKey: "finishedGames", name: "Finished Games", stackId: "games", fill: "#20A8DA", isAnimationActive: false })),
            shouldShowGames && (React.createElement(Bar, { dataKey: "unfinishedGames", name: "Unfinished Games", stackId: "games", fill: "#79cae8", isAnimationActive: false }, isValueVisible && (React.createElement(LabelList, { formatter: (number) => formatNumberWithThousandSeparators(number, ','), dataKey: "totalGames", position: "top" })))),
            shouldShowThrowsByGames && (React.createElement(Bar, { dataKey: "totalPracticeGameCheckoutStreakThrows", name: "Checkout streak", stackId: "gameThrows", fill: "#4CB140", isAnimationActive: false })),
            shouldShowThrowsByGames && (React.createElement(Bar, { dataKey: "totalPracticeGame121Throws", name: "121", stackId: "gameThrows", fill: "#38812F", isAnimationActive: false })),
            shouldShowThrowsByGames && (React.createElement(Bar, { dataKey: "totalPracticeGamePowerScoringThrows", name: "Power Scoring", stackId: "gameThrows", fill: "#7CC674", isAnimationActive: false })),
            shouldShowThrowsByGames && (React.createElement(Bar, { dataKey: "totalX01Throws", name: "X01", stackId: "gameThrows", fill: "#73C5C5", isAnimationActive: false })),
            shouldShowThrowsByGames && (React.createElement(Bar, { dataKey: "totalRandomCricketThrows", name: "Random Cricket", stackId: "gameThrows", fill: "#06C", isAnimationActive: false })),
            shouldShowThrowsByGames && (React.createElement(Bar, { dataKey: "totalCricketThrows", name: "Cricket", stackId: "gameThrows", fill: "#519DE9", isAnimationActive: false })),
            shouldShowThrowsByGames && (React.createElement(Bar, { dataKey: "totalShanghaiThrows", name: "Shanghai", stackId: "gameThrows", fill: "#8481DD", isAnimationActive: false })),
            shouldShowThrowsByGames && (React.createElement(Bar, { dataKey: "totalAtcThrows", name: "ATC", stackId: "gameThrows", fill: "#F6D173", isAnimationActive: false })),
            shouldShowThrowsByGames && (React.createElement(Bar, { dataKey: "totalBobs27Throws", name: "Bob's 27", stackId: "gameThrows", fill: "#EF9234", isAnimationActive: false }, isValueVisible && (React.createElement(LabelList, { formatter: (number) => formatNumberWithThousandSeparators(number, ','), dataKey: "totalGameThrows", position: "top" })))))));
};
const SystemUsage = ({ systemUsage, isLoading, isDownloading, isError, dispatch, }) => {
    const thirtyDaysEarlier = new Date();
    thirtyDaysEarlier.setDate(thirtyDaysEarlier.getDate() - 30);
    thirtyDaysEarlier.setHours(0, 0, 0, 0);
    const [viewType, setViewType] = useState('day');
    const [dateFrom, setDateFrom] = useState(thirtyDaysEarlier);
    const [dateTo, setDateTo] = useState(new Date(new Date().setHours(23, 59, 59)));
    const [shouldShowThrowsByType, setShouldShowThrowsByType] = useState(true);
    const [shouldShowGames, setShouldShowGames] = useState(false);
    const [shouldShowThrowsByGames, setShouldShowThrowsByGames] = useState(false);
    const [systemUsagePeriod, setSystemUsagePeriod] = useState(PERIODS[2].key);
    const [isValueVisible, setIsValueVisible] = useState(true);
    const [isDateControlVisible, setIsDateControlVisible] = useState(false);
    const [retrigger, setRetrigger] = useState(false);
    useEffect(() => {
        if (dateFrom && dateTo) {
            const fromYear = dateFrom.getFullYear();
            const toYear = dateTo.getFullYear();
            if (dateFrom < dateTo &&
                fromYear > minDate &&
                fromYear < maxDate &&
                toYear > minDate &&
                toYear < maxDate) {
                dispatch(getSystemUsage({
                    viewType,
                    dateFrom: dateFrom.toISOString(),
                    dateTo: dateTo.toISOString(),
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    timeZoneOffset: dateFrom.getTimezoneOffset(),
                    isCustomQuery: isDateControlVisible,
                }));
            }
        }
    }, [dateFrom, dateTo, systemUsagePeriod, viewType, isDateControlVisible, dispatch, retrigger]);
    const handleDateFilterChange = () => {
        setIsDateControlVisible(!isDateControlVisible);
        if (!isDateControlVisible) {
            const fromDate = dateFrom
                ? new Date(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate())
                : null;
            const toDate = dateTo
                ? new Date(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate(), 23, 59, 59)
                : null;
            setDateFrom(fromDate);
            setDateTo(toDate);
        }
    };
    const handleValueChange = (date, field) => {
        if (!date || date > new Date()) {
            dispatch(notificationActions.notifyError('Date cannot set to future date.'));
            return;
        }
        if (!isDateControlVisible && date) {
            date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
            field === 'dateTo' && date.setHours(23, 59, 59);
        }
        if (field === 'dateFrom') {
            setDateFrom(date);
        }
        else {
            setDateTo(date);
        }
        setSystemUsagePeriod(null);
    };
    const renderLastXPeriod = (_e, period) => {
        let daysCount;
        switch (period) {
            case 'week':
                daysCount = 7;
                break;
            case 'month':
                daysCount = 30;
                break;
            case 'year':
                daysCount = 365;
                break;
            default:
                daysCount = 30;
                break;
        }
        const now = new Date();
        const beginning = new Date(2016, 0, 1);
        const xDaysEarlier = new Date();
        xDaysEarlier.setDate(xDaysEarlier.getDate() - daysCount);
        if (!isDateControlVisible) {
            now.setHours(23, 59, 59);
            beginning.setHours(0, 0, 0, 0);
            xDaysEarlier.setHours(0, 0, 0, 0);
        }
        setDateFrom(period === 'allTime' ? beginning : xDaysEarlier);
        setDateTo(now);
        setSystemUsagePeriod(period);
    };
    const toggleDisplay = (showThrowsByType, showGames, showThrowsByGames) => {
        setShouldShowThrowsByType(showThrowsByType);
        setShouldShowGames(showGames);
        setShouldShowThrowsByGames(showThrowsByGames);
    };
    const toggleThrowsByTypeData = (e) => {
        e.preventDefault();
        toggleDisplay(true, false, false);
    };
    const toggleGamesData = (_e) => {
        toggleDisplay(false, true, false);
    };
    const toggleThrowsByGameData = (_e) => {
        toggleDisplay(false, false, true);
    };
    const chartData = !isError
        ? systemUsage.data.map((data) => ({
            id: data._id,
            label: data._label,
            automaticallyDetectedThrows: data.automaticallyDetectedThrows,
            practiceGameThrows: data.practiceGameThrows,
            addedOrModifiedThrows: data.addedOrModifiedThrows,
            outcastThrows: data.outcastThrows,
            totalThrows: data.totalThrows,
            unfinishedGames: data.totalGames - data.finishedGames,
            finishedGames: data.finishedGames,
            totalGames: data.totalGames,
            totalX01Throws: data.totalX01Throws,
            totalCricketThrows: data.totalCricketThrows,
            totalRandomCricketThrows: data.totalRandomCricketThrows,
            totalShanghaiThrows: data.totalShanghaiThrows,
            totalAtcThrows: data.totalAtcThrows,
            totalBobs27Throws: data.totalBobs27Throws,
            totalPracticeGamePowerScoringThrows: data.totalPracticeGamePowerScoringThrows,
            totalPracticeGameCheckoutStreakThrows: data.totalPracticeGameCheckoutStreakThrows,
            totalPracticeGame121Throws: data.totalPracticeGame121Throws,
            totalGameThrows: data.totalX01Throws +
                data.totalCricketThrows +
                data.totalRandomCricketThrows +
                data.totalShanghaiThrows +
                data.totalAtcThrows +
                data.totalBobs27Throws +
                data.totalPracticeGamePowerScoringThrows +
                data.totalPracticeGameCheckoutStreakThrows +
                data.totalPracticeGame121Throws,
        }))
        : [];
    const handleDownload = () => {
        dispatch(downloadSystemUsage({
            viewType,
            dateFrom,
            dateTo,
            _label: 'Date',
            finishedGames: 'Finished games',
            unfinishedGames: 'Unfinished games',
            automaticallyDetectedThrows: 'Automatically detected throws',
            addedOrModifiedThrows: 'Added/Modified throws',
            outcastThrows: 'Outcast throws',
            practiceGameThrows: 'Practice game throws',
            totalGames: 'Total games',
            totalThrows: 'Total throws',
            totalX01Throws: 'X01 throws',
            totalCricketThrows: 'Cricket throws',
            totalRandomCricketThrows: 'Random Cricket throws',
            totalShanghaiThrows: 'Shanghai throws',
            totalAtcThrows: 'ATC throws',
            totalBobs27Throws: "Bob's 27 throws",
            totalPracticeGamePowerScoringThrows: 'Power Scoring throws',
            totalPracticeGameCheckoutStreakThrows: 'Checkout streak throws',
            totalPracticeGame121Throws: '121 throws',
            isCustomQuery: isDateControlVisible,
        }));
    };
    const dataTypeButtons = (React.createElement("div", null,
        React.createElement("button", { className: styles.exportButton, onClick: handleDownload, disabled: isDownloading },
            React.createElement("i", { className: "cui-save" })),
        React.createElement("button", { className: cn(styles.dataTypeButton, { [styles.active]: shouldShowThrowsByType }), type: "button", onClick: toggleThrowsByTypeData },
            !isError ? formatNumberWithThousandSeparators(systemUsage.dataSum.totalThrowsSum, ' ') : 0,
            ' ',
            "Throws by type"),
        React.createElement("button", { className: cn(styles.dataTypeButton, { [styles.active]: shouldShowGames }), type: "button", onClick: toggleGamesData },
            !isError ? formatNumberWithThousandSeparators(systemUsage.dataSum.totalGamesSum, ' ') : 0,
            ' ',
            "Games"),
        React.createElement("button", { className: cn(styles.dataTypeButton, { [styles.active]: shouldShowThrowsByGames }), type: "button", onClick: toggleThrowsByGameData },
            !isError ? formatNumberWithThousandSeparators(systemUsage.dataSum.totalThrowsSum, ' ') : 0,
            ' ',
            "Throws by games")));
    const dataIntervalButtons = INTERVALS.map((interval) => (React.createElement(Button, { key: `interval-button-${interval}`, outline: true, color: "primary", className: cn(styles.dataIntervalButton, { active: viewType === interval }), onClick: (_e) => setViewType(interval), disabled: isLoading },
        React.createElement("span", { className: "text-uppercase" }, interval))));
    const dateInputs = (React.createElement(Row, { className: styles.dateInputs },
        React.createElement(Col, { sm: "6", md: "5" },
            React.createElement(FormGroup, { className: cn(styles.datePickerFormGroup, {
                    [styles.smallDatePicker]: !isDateControlVisible,
                }) },
                React.createElement(Label, { htmlFor: "From" }, "From"),
                React.createElement(DatePicker, { selected: dateFrom, onChange: (date) => handleValueChange(date, 'dateFrom'), showTimeSelect: isDateControlVisible, timeFormat: "HH:mm", timeIntervals: 5, dateFormat: isDateControlVisible ? 'yyyy.MM.dd HH:mm' : 'yyyy.MM.dd', placeholderText: "\u00E9\u00E9\u00E9\u00E9. hh. nn.", className: "form-control", isClearable: true }))),
        React.createElement(Col, { sm: "6", md: "5" },
            React.createElement(FormGroup, { className: cn(styles.datePickerFormGroup, {
                    [styles.smallDatePicker]: !isDateControlVisible,
                }) },
                React.createElement(Label, { htmlFor: "From" }, "To"),
                React.createElement(DatePicker, { selected: dateTo, onChange: (date) => handleValueChange(date, 'dateTo'), showTimeSelect: isDateControlVisible, timeFormat: "HH:mm", timeIntervals: 5, dateFormat: isDateControlVisible ? 'yyyy.MM.dd HH:mm' : 'yyyy.MM.dd', placeholderText: "\u00E9\u00E9\u00E9\u00E9. hh. nn.", className: "form-control", isClearable: true }))),
        React.createElement(Col, { sm: "12", md: "2" },
            React.createElement(Button, { outline: true, color: "primary", className: styles.refreshButton, onClick: (_e) => setRetrigger(!retrigger), disabled: isLoading },
                React.createElement("span", { className: "text-uppercase" }, "Refresh")))));
    const periodButtons = PERIODS.map(({ key, label }) => (React.createElement(Col, { sm: "3", key: key + '-period-button' },
        React.createElement(Button, { outline: true, color: "primary", className: cn(styles.dataPeriodButton, { active: systemUsagePeriod === key }), onClick: (e) => renderLastXPeriod(e, key), disabled: isLoading },
            React.createElement("span", { className: "text-uppercase" }, label)))));
    return (React.createElement(Card, null,
        React.createElement(CardHeader, null,
            React.createElement("div", { className: "card-header__left" },
                React.createElement("i", { className: "fa fa-bar-chart" }),
                " System Usage"),
            React.createElement("div", { className: "card-header__right" }, dataTypeButtons)),
        React.createElement(CardBody, null,
            React.createElement(Fragment, null,
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 6 },
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 12 }, dateInputs),
                            React.createElement(Col, { sx: 12 },
                                React.createElement(Row, null, periodButtons))),
                        React.createElement(Row, null,
                            React.createElement(Col, null,
                                React.createElement("div", { className: styles.filterUnit },
                                    React.createElement("label", { className: styles.container },
                                        "Show Values",
                                        React.createElement("input", { checked: isValueVisible, name: "isValueVisible", type: "checkbox", onChange: () => setIsValueVisible(!isValueVisible) }),
                                        React.createElement("span", { className: styles.checkmark })))),
                            React.createElement(Col, null,
                                React.createElement("div", { className: styles.filterUnit },
                                    React.createElement("label", { className: styles.container },
                                        "Detailed date filtering",
                                        React.createElement("input", { checked: isDateControlVisible, name: "isDateControlVisible", type: "checkbox", onChange: handleDateFilterChange }),
                                        React.createElement("span", { className: styles.checkmark })))),
                            React.createElement(Col, null),
                            React.createElement(Col, null))),
                    React.createElement(Col, { xs: 6 },
                        React.createElement("div", { className: styles.dataIntervalButtons }, dataIntervalButtons),
                        shouldShowThrowsByType && (React.createElement(Alert, { color: "info", className: styles.practiceGameNote },
                            React.createElement("span", null, "Note:"),
                            " Practice game throws are not added to the final sum, as they are already included as outcasts.")),
                        shouldShowThrowsByGames && (React.createElement(Alert, { color: "info", className: styles.practiceGameNote },
                            React.createElement("span", null, "Note:"),
                            " Only saved(finished) practice games are shown, see the first table for physical throws.")))),
                React.createElement(Row, null,
                    React.createElement(DateAlerts, { dateFrom: dateFrom, dateTo: dateTo, maxDate: maxDate, minDate: minDate })),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12 },
                        React.createElement(SystemUsageChart, { chartData: chartData, shouldShowThrowsByType: shouldShowThrowsByType, shouldShowGames: shouldShowGames, shouldShowThrowsByGames: shouldShowThrowsByGames, isValueVisible: isValueVisible })))))));
};
const mapStateToProps = (state) => ({
    systemUsage: state.data.systemUsages.systemUsage,
    isLoading: state.scenes.systemUsages.systemUsage.isLoading,
    isDownloading: state.scenes.systemUsages.systemUsage.isDownloading,
    isError: state.scenes.systemUsages.systemUsage.isError,
});
const connector = connect(mapStateToProps);
export default connector(SystemUsage);
