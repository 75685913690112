import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, isRegExp } from 'lodash-es';

import { ConfirmCopyModal } from './ConfirmCopyModal';
import { getIsAdmin } from 'services/session/selectors';
import { browserHistory } from 'services/history';

import { EntityTable } from 'components/EntityTable';

import { useListSelection } from './hooks';
import { getColumns } from './utils'

import { isNullOrUndefined } from 'utils';

const DEFAULT_SORT = [
  {
    id: 'tournamentStartDate',
    desc: true,
  },
];

const TournamentList = ({ url, data, page, selectColumns, pageSize, totalCount, filters, isLoading, onDelete, onCopy, onFetchData, canBeCreated, isDeletable, canBeCopied, resource, columns = [] }) => {
  const isAdmin = useSelector(getIsAdmin);

  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);

  const [selectedKeys, selectableElements, handleSelectOne, handleSelectAll, handleReset] = useListSelection(data)
  const header = getColumns({ url, columns, selectedKeys, selectableElements, handleSelectOne, handleClearAll: handleReset, handleSelectAll, canBeCreated, isDeletable })

  const toggleCopyModal = () => {
    setIsCopyModalOpen((prevValue) => !prevValue);
  }

  const handleDelete = () => {
    const result = window.confirm(`Are you sure you want to delete ${selectedKeys?.length} tournament${selectedKeys?.length > 1 ? 's' : ''}?`);

    if (result) {
      onDelete({ keys: [...selectedKeys], filter: filters });
      handleReset();
    }
  };

  const customResourceButtons = []

  if ( canBeCreated ) {
    customResourceButtons.push({ value: 'New Public Tournament', link: `${browserHistory.location.pathname}/create` })
    customResourceButtons.push({ value: 'New Invitational Tournament', link: `${browserHistory.location.pathname}/createInvitational` })
  }

  return (
    <div>
      {canBeCopied && (
        <ConfirmCopyModal toggle={toggleCopyModal} isOpen={isCopyModalOpen} filters={filters} onCopy={onCopy} />
      )}
      <EntityTable
        resource={resource}
        columns={header}
        selectColumns={selectColumns}
        data={data}
        page={page}
        pageSize={pageSize}
        defaultPageRowSize={500}
        totalCount={totalCount}
        defaultSorted={DEFAULT_SORT}
        isLoading={isLoading}
        onFetchData={onFetchData}
        createResourceButtons={customResourceButtons}
        isAdmin={isAdmin}
        isDownloading={false}
        customDeleteButtonLabel={isDeletable ? 'Delete selected rows' : undefined}
        isCustomDeleteDisabled={isNullOrUndefined(selectedKeys) || isEmpty(selectedKeys)}
        handleCustomDelete={isRegExp ? handleDelete : undefined}
        isDownloadPossible={true}
        customButtons={canBeCopied ? [{
          name: 'Copy last N days',
          onClickAction: toggleCopyModal,
        }] : null}
      />
    </div>
  );
};

TournamentList.displayName = 'TournamentList';

export default TournamentList;