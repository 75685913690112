import React from 'react';
import { TournamentWizard } from 'components/TournamentWizard';
import { prepareWizardFormValues } from 'components/TournamentWizard/utils';
import { post } from 'services/rest';
import { useDispatch } from 'react-redux';
import { notifyError, notifySuccess } from 'services/notification';
import { browserHistory } from 'services/history';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useFetchInvitationalTournament } from 'scenes/Tournaments/hooks';
import { isImagePrivateTournamentThumbnail } from 'constants/ScoliaThumbnails';
import { createPresetFromTournament, uploadThumbnail } from '../utils';
import { isNullOrUndefined } from 'utils';
const requestUrl = '/tournaments/';
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});
export function CreateInvitational(props) {
    return (React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement(CreateInvitationalQuery, { ...props })));
}
function CreateInvitationalQuery({ match }) {
    const dispatch = useDispatch();
    const presetTournamentId = match?.params?.tournamentId;
    const { isLoading, error, data } = useFetchInvitationalTournament(presetTournamentId);
    if (isLoading) {
        return (React.createElement("i", { className: "fa fa-spinner fa-spin fa-fw" }));
    }
    if (error) {
        return 'Something went wrong!';
    }
    const handleFormSubmit = async (values) => {
        const formattedValues = prepareWizardFormValues(values);
        const { thumbnailUrl, thumbnailImage } = values;
        if (thumbnailUrl && !isImagePrivateTournamentThumbnail(thumbnailUrl) && thumbnailImage) {
            const fileName = await uploadThumbnail(thumbnailImage);
            if (!isNullOrUndefined(fileName)) {
                formattedValues.thumbnailUrl = fileName;
                delete formattedValues.thumbnailImage;
            }
        }
        delete formattedValues.participantsOrder;
        delete formattedValues.penalty;
        delete formattedValues.registrationStartDate;
        delete formattedValues.currentTokenCost;
        try {
            const response = await post(`${requestUrl}invitational`, formattedValues);
            dispatch(notifySuccess("Tournament created successfully!"));
            const newTournamentId = response.data?._id;
            if (newTournamentId) {
                browserHistory.push(`/tournaments/editInvitational/${newTournamentId}`);
            }
        }
        catch (e) {
            console.error(e);
            dispatch(notifyError('Couldn\'t create tournament!'));
        }
    };
    const tournament = isNullOrUndefined(data) ? undefined : createPresetFromTournament(data);
    return (React.createElement(TournamentWizard, { values: tournament, onSubmit: handleFormSubmit }));
}
