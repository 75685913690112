import { call, put, takeLatest } from 'redux-saga/effects';

import { Statistics } from 'services/api';
import { callApi } from 'services/rest';

import {
  getSystemUsage,
  getSystemUsageSuccess,
  getSystemUsageFailure,
} from 'scenes/SystemUsages/components/SystemUsage/actions';

function* watchGetSystemUsage({payload: {
  viewType,
  dateFrom,
  dateTo,
  timeZone,
  timeZoneOffset,
  isCustomQuery,
}}) {
  try {
    const { data } = yield call(callApi, Statistics.systemUsageChart, 
      {
        isCustomQuery
      }, {
        viewType,
        dateFrom,
        dateTo,
        timeZone,
        timeZoneOffset,
      }
    );

    // Aggregate total throws and games
    const dataSum = data.reduce(
      (aggregation, portion) => {
        aggregation.totalThrowsSum += portion.totalThrows;
        aggregation.totalGamesSum += portion.totalGames;
        return aggregation;
      },
      {
        totalGamesSum: 0,
        totalThrowsSum: 0,
      }
    );

    yield put(getSystemUsageSuccess({ data, dataSum }));
  } catch (e) {
    console.log(e);
    yield put(getSystemUsageFailure());
  }
}

// It must be wrapped in a function, so execution will happen only on function call
export default () => takeLatest(getSystemUsage, watchGetSystemUsage);
