import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import actions from './actions';

const INITIAL_STATE = {
  isLoading: true,
  isDownloading: false,
};

const list = handleActions(
  {
    [actions.getChatRooms]: (state) => {
      return update(state, {
        isLoading: {
          $set: true,
        }
      })
    },

    [actions.getChatRoomsSuccess]: (state) => {
      return update(state, {
        isLoading: {
          $set: false,
        }
      })
    },

    [actions.getChatRoomsFailure]: (state) => {
      return update(state, {
        isLoading: {
          $set: false,
        }
      })
    },

    [actions.download]: (state) => {
      return update(state, {
        isDownloading: {
          $set: true,
        }
      })
    },

    [actions.downloadSuccessful]: (state) => {
      return update(state, {
        isDownloading: {
          $set: false,
        }
      })
    },

    [actions.downloadFailure]: (state) => {
      return update(state, {
        isDownloading: {
          $set: false,
        }
      })
    },
  },
  INITIAL_STATE
);

export default list;
