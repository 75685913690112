import { uniq } from 'autoprefixer/lib/utils';
import { isEmpty } from 'lodash-es';

import { CONFIGURATION_OPTIONS } from 'constants/Tournament';
import formatLabel from 'utils/formatLabel';

import { formatDate, isNullOrUndefined, getConfiguration } from 'utils';
import { PrizesStagesRequiredParticipantNumber } from 'constants/Tournament';
import { TournamentStageType } from 'enums';

export const TOURNAMENT_STAGES = Object.values(TournamentStageType)

export const getStageState = arrayOfMatches => {
  const reducedMatchArray = arrayOfMatches.map(match => match.state);
  const reducedStates = uniq(reducedMatchArray);
  if (reducedStates.length > 1) return 'Ongoing';

  return reducedStates[0];
};

export const getParentMatches = (previousStage, localIndex) => {
  let firstParent;
  let secondParent;

  if (previousStage) {
    firstParent = previousStage[localIndex * 2];
    secondParent = previousStage[localIndex * 2 + 1];
  }

  return { firstParent, secondParent };
};
export const stages = ['final', 'semiFinal', 'quarterFinal', 'top16', 'top32', 'top64', 'top128', 'top256'];

export const getTournamentMatchByMatchId = (tournament, matchId) => {
  const participantsCount = Object.values(tournament.participants).length;
  const numberOfStages = Math.ceil(Math.log2(participantsCount));
  const highestStage = Math.log2(Math.pow(2, numberOfStages));
  const tournamentStages = stages.slice(0, highestStage);
  const highestStagePower = Math.pow(2, numberOfStages);
  const numberOfByePlayers = highestStagePower - participantsCount;

  /* Tracking the global first match index of the stage.
   In case of 8 participants
   - quarterFinal = 1,
   - semiFinal = 5,
   - final = 7
  */
  let currentGlobalMatchIndex = 1;

  // Going through the stages of the tournament from the highest to the lowest (left to right), and finding the match by checking the global match index boundaries of the stage.
  // It's O(1) (Actually its O(N), where N = numberOfStages in the tournament. But N is in range of [2, 8], so it's constant.)

  for (let stageIndex = highestStage; stageIndex >= 1; stageIndex--) {
    // Number of matches in the stage
    let numberOfMatches = Math.pow(2, stageIndex) / 2;

    if (stageIndex === highestStage) {
      numberOfMatches -= numberOfByePlayers;
    }

    // Checks if the given globalMatchId is in the currentStage
    if (matchId < currentGlobalMatchIndex + numberOfMatches) {
      // Stage local index of the given match (In the filtered array containing only non bye matches)
      const filteredLocalMatchIndex = matchId - currentGlobalMatchIndex;

      // Stage type (final, semiFinal, ...)
      const stageType = tournamentStages[stageIndex - 1];

      // The stage entity from the DB
      const stageEntity = tournament.stages[tournamentStages[stageIndex - 1]].filter(i => i.matchId !== -1);

      return {
        stageType,
        stageMatchIndex: tournament.stages[tournamentStages[stageIndex - 1]].indexOf(
          stageEntity[filteredLocalMatchIndex]
        ),
        tournamentMatch: stageEntity[filteredLocalMatchIndex],
      };
    }

    currentGlobalMatchIndex += numberOfMatches;
  }
};

/// @param prize should be a number
/// @param checkForThousands should be a boolean
/// @return formatted prize as string
export const formatMaxPrizePool = (prize, withLabel = true) => {
  return prize.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + (withLabel ? ' TP' : '')
}

export const prepareTournamentList = (data, isPrivate) => {
  if (isNullOrUndefined(data) || isEmpty(data)) {
    return []
  }

  return Object.values(data).map(tournament => {
    const { registrationEndDate, registrationStartDate, tournamentStartDate, configType, gameConfig, createdAt, state, ...rest } = tournament;

    return ({
      ...rest,
      registrationStartDate: formatDate(registrationStartDate, true),
      registrationEndDate: formatDate(registrationEndDate, true),
      tournamentStartDate: formatDate(tournamentStartDate, true),
      gameConfig: configType === CONFIGURATION_OPTIONS.Fixed
        ? getConfiguration(gameConfig)
        : CONFIGURATION_OPTIONS.Progressive,
      state: formatLabel(state),
      ...(isPrivate ? { createdAt: formatDate(createdAt, true) } : {}),
    })
  })
}

export const getAvailableTournamentStages = (maxParticipants) => {
  const numberOfStages = Math.ceil(Math.log2(maxParticipants));
  const tournamentStages = [...TOURNAMENT_STAGES.slice(0, numberOfStages)].reverse();

  return tournamentStages;
}

export const getAllowedPrizePoolsByParticipants = (maxParticipantsNr = 0) => {
  return Object.entries(PrizesStagesRequiredParticipantNumber).reduce((acc, [key, value]) => {
    if (maxParticipantsNr >= value) {
      acc.push(key);
    }
    return acc;
  }, []);
};
