import { combineReducers } from 'redux';

import { reducer as list } from './components/List';
import { reducer as show } from './components/Show';
import { reducer as create } from './components/Create';
import { reducer as edit } from './components/Edit';

const serviceAccounts = combineReducers({
  list,
  show,
  create,
  edit,
});

export default serviceAccounts;
