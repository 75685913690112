import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Portal } from 'components/Portal';
import { DropdownDirection, useDropdownPositioning } from '../hooks';
import { SizesEnum } from '../enums';
import { SScrollContainer } from '../SScrollContainer';
import { SAnimate, SAnimateVariantsEnum } from '../SAnimate';
import { SCard, SCardVariants } from '../SCard';
import { SLabelContainer } from '../SLabelContainer';
import { mapBoolPropsToClassnames as mapBPTC } from 'utils';
import styles from './styles.module.scss';
import { ArrowIcon, ArrowIconDirection } from 'components/Icons';
const containerMaxHeight = 280;
export const SSelect = ({ name, hint, error, label, value, theme, tooltip, placeholder, className, options = [], size = SizesEnum.Medium, showLabel = true, lockedOptionContainerSize = false, isRequired = false, isDropdownIconVisible = true, isTransparent = false, optionsContainerClassName = '', onSelect, onFocus, onChange = () => { }, onBlur, ...rest }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const timeout = useRef();
    const dropdownDirection = useDropdownPositioning(dropdownRef, containerMaxHeight);
    const handlePreventDefault = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };
    const handleFocus = (event) => {
        onFocus && onFocus(event);
        setIsOpen(true);
    };
    const handleBlur = (event) => {
        timeout.current = setTimeout(() => {
            onBlur && onBlur(event);
            setIsOpen(false);
        }, 300);
    };
    const handleChange = (event, selectedValue) => {
        handlePreventDefault(event);
        onSelect && onSelect(selectedValue, name);
        setIsOpen(false);
    };
    const handleCloseOptions = () => {
        setIsOpen(false);
    };
    useEffect(() => {
        return () => {
            clearTimeout(timeout.current);
        };
    }, []);
    const isUp = dropdownDirection === DropdownDirection.up;
    const isDown = dropdownDirection === DropdownDirection.down;
    let dropdownSizes;
    let containerStyles = {};
    let scrollContainerStyles = {};
    if (dropdownRef?.current) {
        const rect = dropdownRef.current.getBoundingClientRect();
        dropdownSizes = {
            width: dropdownRef.current.clientWidth,
            height: dropdownRef.current.clientHeight,
            top: rect.top,
            left: rect.left,
        };
        containerStyles = {
            top: `${isDown ? (dropdownSizes?.top ?? 0) + (dropdownSizes?.height ?? 0) + 8 : dropdownSizes?.top - 8}px`,
            left: `${dropdownSizes?.left}px`,
            width: lockedOptionContainerSize ? `${dropdownSizes?.width}px` : 'min-content',
            height: isUp ? 'auto' : `${containerMaxHeight}px`,
        };
        scrollContainerStyles = {
            maxHeight: `${containerMaxHeight}px`,
        };
    }
    const handleOnMouseDown = (event, name) => {
        event.preventDefault();
        document.getElementById(name)?.focus();
    };
    return (React.createElement(SLabelContainer, { error: error, hint: hint, label: label, name: name, isRequired: isRequired, showLabel: showLabel, tooltip: tooltip, style: { position: 'relative' } },
        React.createElement("select", { id: name, name: name, value: value, ref: dropdownRef, className: cn(styles.SInput, styles[size], styles[theme], [className], {
                [styles.hasIcon]: isDropdownIconVisible,
                [styles.error]: !!error,
                [styles.transparent]: isTransparent,
            }), onFocus: handleFocus, onChange: onChange, onBlur: handleBlur, onMouseDown: e => handleOnMouseDown(e, name), ...rest },
            placeholder && (React.createElement("option", { value: undefined, disabled: true, hidden: true }, placeholder)),
            value ? (React.createElement("option", { value: value, style: { display: 'none' } }, options.find((option) => option.value === value)?.label)) : options.map((option, index) => (React.createElement("option", { key: index, value: option.value, style: { display: 'none' } }, option.label)))),
        React.createElement(Portal, null,
            React.createElement("div", { className: cn(styles.optionsContainerBackground, mapBPTC(styles, { isOpen })), onClick: handleCloseOptions },
                React.createElement("div", { style: containerStyles, className: cn(styles.optionsContainer, [optionsContainerClassName], mapBPTC(styles, { isUp, isDown, isOpen, [size]: size })), onClick: handlePreventDefault },
                    React.createElement(SAnimate, { variant: SAnimateVariantsEnum.SlideInTop, in: isOpen, onClick: handlePreventDefault },
                        React.createElement(SCard, { theme: theme, className: styles.optionsWrapper, variant: SCardVariants.Solid, outline: true },
                            React.createElement(SScrollContainer, { style: scrollContainerStyles }, options.map((option, index) => (React.createElement("button", { key: index, value: option.value, className: styles.option, onClick: (event) => handleChange(event, option.value) }, option.label))))))))),
        isDropdownIconVisible && (React.createElement("div", { className: cn(styles.arrow, styles[size], mapBPTC(styles, { isOpen })) },
            React.createElement(ArrowIcon, { direction: ArrowIconDirection.Down })))));
};
SSelect.displayName = 'SSelect';
