// Compute highest lower power of 2 for n in [1, 2**31-1]:
function highestPowerof2(n) {
  let res = 0;
  for (let i = n; i >= 1; i--) {
    // If i is a power of 2
    if ((i & (i - 1)) === 0) {
      res = i;
      break;
    }
  }
  return res;
}

export default highestPowerof2;
