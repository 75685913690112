import React from 'react';
import { isNull } from 'lodash';
import { SLabel, SScrollContainer } from 'ScoliaComponents';
import { PrivateTournamentThumbnailOptions } from 'constants/ScoliaThumbnails';
import { ImageUpload, ImageWrapper } from './components';
import { backgroundImages } from './constants';
import { HeaderTitle } from '../../HeaderTitle';
import styles from './styles.module.scss';
export const Appearance = ({ title, onBack, values, errors, onChange }) => {
    return (React.createElement(SScrollContainer, null,
        !isNull(title) && (React.createElement(HeaderTitle, { title: title, onBack: onBack })),
        React.createElement("span", { className: styles.subtitle }, "Thumbnail image"),
        React.createElement(SLabel, { htmlFor: "thumbnailLabel", nowrap: false }, "This will be displayed on the listing page."),
        React.createElement(ImageUpload, { name: "thumbnailUrl", onChange: onChange, value: values.thumbnailUrl, uploadedFile: values.thumbnailImage }),
        React.createElement(ImageWrapper, { label: "Or just select one of these:", name: "thumbnailUrl", options: PrivateTournamentThumbnailOptions, selectedValue: values.thumbnailUrl, onChange: onChange, error: errors.thumbnailUrl }),
        React.createElement("span", { className: styles.subtitle }, "Background image"),
        React.createElement(ImageWrapper, { label: "This will be the background of your tournament's page.", name: "backgroundUrl", options: backgroundImages, selectedValue: values.backgroundUrl, onChange: onChange, error: errors.backgroundUrl })));
};
