import { createSelector } from 'reselect';

export const getIsSnapshotPending = state =>
  state.scenes.boards.show.isSnapshotPending;
export const getIsStreaming = state => state.scenes.boards.show.isStreaming;
export const getShouldPreProcessApiImages = createSelector(
  [getIsStreaming, getIsSnapshotPending],
  (stream, snapshot) => stream || snapshot
);
export const getBoardState = (state, boardId) => state.data.boards[boardId].currentState.state
export const getStreamConfig = state => state.scenes.boards.show.streamConfig;

export default {
  getIsSnapshotPending,
  getIsStreaming,
  getShouldPreProcessApiImages,
  getStreamConfig,
};
