import { combineReducers } from 'redux';

import { reducer as create } from './components/Create';
import { reducer as edit } from './components/Edit';
import { reducer as list } from './components/List';
import { reducer as show } from './components/Show';

const manufacturers = combineReducers({
  create,
  edit,
  list,
  show
});

export default manufacturers;
