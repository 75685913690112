import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TournamentShow } from 'components/TournamentShow';
import { deleteTournament, getTournament, subscribeToTournamentUpdates, unsubscribeToTournamentUpdates } from './actions';
import { getTournamentRequestState, getTournament as getTournamentSelector } from './selectors';
export function Show({ match: { params: { tournamentId } } }) {
    const { tournament, isLoading } = useSelector(getTournamentRequestState);
    const dispatch = useDispatch();
    const handleDeleteTournament = (paramId) => {
        dispatch(deleteTournament([paramId]));
    };
    return (React.createElement(TournamentShow, { tournamentId: tournamentId, tournament: tournament, isLoading: isLoading, getTournamentAction: getTournament, onDeleteTournament: handleDeleteTournament, isEditable: true, getTournamentSelector: getTournamentSelector, subscribeAction: subscribeToTournamentUpdates, unsubscribeAction: unsubscribeToTournamentUpdates }));
}
