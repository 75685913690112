import { delay, put, takeEvery } from 'redux-saga/effects';

import {
  notifySuccess,
  notifyWarning,
  notifyError,
  removeNotification,
} from 'services/notification/actions';

const watchDeleteNotification = function*({ payload }) {
  yield delay(3000);
  yield put(removeNotification(payload.id));
};

export default () =>
  takeEvery(
    [notifySuccess, notifyWarning, notifyError],
    watchDeleteNotification
  );
