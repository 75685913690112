import { put, take, call, select } from 'redux-saga/effects';

import { Socket, actions as wsActions } from 'services/ws';
import { OPENED } from 'constants/WsStatuses'

import {
  getMessages,
  getMessagesRequest
} from 'scenes/Chat/components/Show/actions';


export default function* watchGetMessages() {
  while (true) {
    const { payload: {groupId} } = yield take(getMessagesRequest);

    const payload = wsActions.Management.getGroupChatMessages({groupId});
    const wsConnection = yield select((state) => state.ws.status === OPENED);

    if(wsConnection){
      yield call([Socket, Socket.send], JSON.stringify(payload));
    }
    else{
      yield take(wsActions.initWsConnectionSuccess);
      yield call([Socket, Socket.send], JSON.stringify(payload));
    }
    const { payload: { messages } } = yield take(wsActions.Api.groupChatMessages);
    yield put(getMessages({messages, groupId}));
  }
}
