import React from 'react';
import { GameTypeEnum } from 'enums';
import { X01Statistics } from '../X01Statistics';
import { AtcStatistics } from '../AtcStatistics';
import { Bobs27Statistics } from '../Bobs27Statistics';
import { CricketStatistics } from '../CricketStatistics';
export const GameSpecificStatistics = ({ selectedGameType, userStats }) => {
    switch (selectedGameType) {
        case GameTypeEnum.X01:
            return React.createElement(X01Statistics, { userStats: userStats });
        case GameTypeEnum.AroundTheClock:
            return React.createElement(AtcStatistics, { userStats: userStats });
        case GameTypeEnum.Bobs27:
            return React.createElement(Bobs27Statistics, { userStats: userStats });
        case GameTypeEnum.Cricket:
        case GameTypeEnum.RandomCricket:
            return React.createElement(CricketStatistics, { userStats: userStats });
        default:
            return null;
    }
};
