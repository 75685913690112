import React from "react";
import { useLocalStorage } from "react-use";
import { ThemesEnum } from "enums";
import { SKIP_CHANGE_STAGE_MODAL } from "constants/StoreKeys";
import { ThemedModal } from "components/ThemedModal";
import styles from './styles.module.scss';
export const ConfirmModifyStagesModal = ({ isOpen, onAccept, onDismiss }) => {
    const [skipChangeStage, setSkipChangeStage] = useLocalStorage(SKIP_CHANGE_STAGE_MODAL, false);
    return (React.createElement(ThemedModal, { title: "Change stage", customAccept: "Yes, change it", isOpen: isOpen, onAccept: onAccept, onDismiss: onDismiss, acceptType: "success", storageKey: SKIP_CHANGE_STAGE_MODAL, skipConfirmation: skipChangeStage, onSkipConfirmation: () => setSkipChangeStage(!skipChangeStage), theme: ThemesEnum.Light, modalContentClass: styles.changeStageModalContent },
        React.createElement("span", null, "Are you sure you want to change the range of the stage? It will affect the rounds that belong to other stages as well.")));
};
