export enum TournamentState {
  Announced = 'Announced',
  RegistrationOpen = 'RegistrationOpen',
  RegistrationFull = 'RegistrationFull',
  RegistrationClosed = 'RegistrationClosed',
  Running = 'Running',
  Finished = 'Finished',
  Cancelled = 'Cancelled',
  Deleted = 'Deleted',
}

export const isTournamentInRegistrationState = (state: TournamentState): boolean => {
  return [TournamentState.RegistrationOpen, TournamentState.RegistrationFull].includes(state);
}

export const isTournamentDeletable = (state: TournamentState): boolean => {
  return [TournamentState.Announced, TournamentState.RegistrationOpen, TournamentState.RegistrationFull, TournamentState.RegistrationClosed].includes(state);
}

export const isTournamentUpcoming = (state: TournamentState): boolean => {
  return [TournamentState.Announced, TournamentState.RegistrationOpen, TournamentState.RegistrationFull].includes(state);
}