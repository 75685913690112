import { createAction } from 'redux-actions';

export const getTournaments = createAction('TOURNAMENTS_GET_REQUEST');
export const getTournamentsFailure = createAction('TOURNAMENTS_GET_FAILURE');
export const getTournamentsSuccess = createAction('TOURNAMENTS_GET_SUCCESS');

export const copyTournaments = createAction('TOURNAMENTS_COPY_REQUEST');
export const copyTournamentsFailure = createAction('TOURNAMENTS_COPY_FAILURE');
export const copyTournamentsSuccess = createAction('TOURNAMENTS_COPY_SUCCESS');

export const deleteMultipleTournaments = createAction('TOURNAMENTS_MULTIPLE_DELETE_REQUEST');
export const deleteMultipleTournamentsFailure = createAction('TOURNAMENTS_MULTIPLE_DELETE_FAILURE');
export const deleteMultipleTournamentsSuccess = createAction('TOURNAMENTS_MULTIPLE_DELETE_SUCCESS');

export default {
  getTournaments,
  getTournamentsFailure,
  getTournamentsSuccess,
  copyTournaments,
  copyTournamentsFailure,
  copyTournamentsSuccess,
  deleteMultipleTournaments,
  deleteMultipleTournamentsFailure,
  deleteMultipleTournamentsSuccess,
};
