import React from 'react';
import cn from 'classnames';
import { formatNumber } from '../../utils';
import styles from '../../styles.module.scss';
export const CricketStatistics = ({ userStats }) => {
    const { averages: { marksPerRound, scorePerRound }, } = userStats;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: cn(styles.rowHeader, 'col-sm-4') }, "Marks Per Round"),
            React.createElement("div", { className: cn(styles.rowHeader, 'col-sm-4') }, "Round")),
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col-sm-4" }, formatNumber(marksPerRound?.value)),
            React.createElement("div", { className: "col-sm-4" }, formatNumber(marksPerRound?.rounds))),
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: cn(styles.rowHeader, 'col-sm-4') }, "Points Per Round"),
            React.createElement("div", { className: cn(styles.rowHeader, 'col-sm-4') }, "Round")),
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col-sm-4" }, formatNumber(scorePerRound?.value)),
            React.createElement("div", { className: "col-sm-4" }, formatNumber(scorePerRound?.rounds)))));
};
