import { createAction } from 'redux-actions';

export const getFeedbacks = createAction('FEEDBACKS_GET_REQUEST');

export const getFeedbacksFailure = createAction('FEEDBACKS_GET_FAILURE');
export const getFeedbacksSuccess = createAction('FEEDBACKS_GET_SUCCESS');

export default {
  getFeedbacks,
  getFeedbacksFailure,
  getFeedbacksSuccess,
};
