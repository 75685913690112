import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Card, CardBody, CardHeader } from 'reactstrap';

import { Image } from './components/Image';

class LiveStream extends Component {
  get configuration() {
    const {
      streamConfig: { quality, scale },
      onStreamConfigurationChange,
    } = this.props;

    return (
      <form className="form--horizontal">
        <div className="row form-group">
          <div className="col-sm-3">
            <label htmlFor="quality">Quality ({quality}%)</label>
          </div>
          <div className="col-sm-9">
            <input
              name="quality"
              type="range"
              min="1"
              max="80"
              value={quality}
              onChange={onStreamConfigurationChange}
            />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-sm-3">
            <label htmlFor="quality">
              Scale ({parseInt(720 * (scale / 100), 0)}px)
            </label>
          </div>
          <div className="col-sm-9">
            <input
              name="scale"
              type="range"
              min="1"
              max="40"
              value={scale}
              onChange={onStreamConfigurationChange}
            />
          </div>
        </div>
      </form>
    );
  }

  get images() {
    return (
      <div>
        {this.props.stream.map((imgBlob, idx) => (
          <Image key={idx} idx={idx} blob={imgBlob} />
        ))}
      </div>
    );
  }

  get error() {
    return this.props.snapshotError ? <div style={{margin: '15px'}}>{this.props.snapshotError}</div> : null;
  }

  render() {
    const {
      isLoading,
      isSnapshotPending,
      isStreaming,
      isCalibrating,
      onSnapshotClick,
      onStreamToggle,
      onCalibratePress,
      isAdmin,
      isStreamEnabled,
    } = this.props;

    return (
      <Card color="white">
        <CardHeader>
          <div className="card-header__left">
            <i className="fa fa-video-camera fa-align-justify" />
            Live Stream
          </div>
          <div className="card-header__right">
           {isAdmin && <button
              className={cn('btn', 'btn-primary', 'btn-padding')}
              disabled={isLoading || isStreaming || isSnapshotPending || isCalibrating}
              onClick={onCalibratePress}
              style={{ marginRight: " 4px" }}
            >
              Calibrate
            </button>}
            <button
              className={cn('btn', 'btn-warning', 'btn-sm', 'me-1')}
              disabled={isLoading || isStreaming || isSnapshotPending}
              onClick={onSnapshotClick}
            >
              Grab snapshot
            </button>
            {isStreamEnabled && <button
              className={cn(
                'btn',
                `btn-${isStreaming ? 'danger' : 'primary'}`,
                'btn-sm'
              )}
              disabled={isLoading || isSnapshotPending}
              onClick={onStreamToggle}
            >
              {isStreaming ? 'Stop streaming' : 'Start streaming'}
            </button>}
          </div>
        </CardHeader>
        <CardBody>
          {this.configuration}
          {this.images}
          {this.error}
        </CardBody>
      </Card>
    );
  }
}

LiveStream.propTypes = {
  stream: PropTypes.array.isRequired,
  streamConfig: PropTypes.shape({
    quality: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    scale: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSnapshotPending: PropTypes.bool.isRequired,
  isStreaming: PropTypes.bool.isRequired,
  isCalibrating: PropTypes.bool.isRequired,
  onSnapshotClick: PropTypes.func.isRequired,
  onStreamConfigurationChange: PropTypes.func.isRequired,
  onStreamToggle: PropTypes.func.isRequired,
  onCalibratePress: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
  isStreamEnabled: PropTypes.bool,
  snapshotError: PropTypes.string
};

export default LiveStream;
