import { call, put, takeLatest } from 'redux-saga/effects';

import { ResourceLogs } from 'services/api';
import { callApi } from 'services/rest';

import {
  getResourceLogs,
  getResourceLogsFailure,
  getResourceLogsSuccess,
} from 'scenes/ResourceLogs/components/List/actions';

function* watchGetResourceLogs({ payload }) {
  try {
    const { data } = yield call(callApi, ResourceLogs.index, payload);
    yield put(getResourceLogsSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(getResourceLogsFailure());
  }
}

// It must be wrapped in a function, so execution will happen only on function call
export default () => takeLatest(getResourceLogs, watchGetResourceLogs);
