import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import actions from './actions';
import { actions as downloadActions } from 'services/entityTable';
import { serviceAccounts } from 'constants/Resources';

const INITIAL_STATE = {
  isLoading: true,
  page: 0,
  pageSize: 0,
  totalCount: 0,
  isDownloading: false,
};

const list = handleActions(
  {
    [actions.getServiceAccounts]: (state, { _payload }) => {
      return update(state, {
        isLoading: {
          $set: true,
        },
      });
    },

    [actions.getServiceAccountsSuccess]: (state, { payload: { page, pageSize, totalCount } }) => ({
      page,
      pageSize,
      totalCount,
      isLoading: false,
    }),

    [actions.getServiceAccountsFailure]: () => ({
      isLoading: false,
    }),

    [downloadActions.download]: (state, { payload: { resource } }) => {
      if (resource === serviceAccounts) {
        return update(state, {
          isDownloading: {
            $set: true,
          },
        });
      } else {
        return state;
      }
    },

    [downloadActions.downloadSuccessful]: (state, { payload }) => {
      if (payload === serviceAccounts) {
        return update(state, {
          isDownloading: {
            $set: false,
          },
        });
      } else {
        return state;
      }
    },

    [downloadActions.downloadFailure]: (state, { payload }) => {
      if (payload === serviceAccounts) {
        return update(state, {
          isDownloading: {
            $set: false,
          },
        });
      } else {
        return state;
      }
    },
  },
  INITIAL_STATE
);

export default list;
