import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { getIsAdmin } from 'services/session/selectors';

const _getRedirectPath = passedState =>
  passedState && passedState.from ? passedState.from.pathname : '/dashboard';

// TODO: Something more powerful session-check
const PublicRoute = ({ component: Component, session, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !session ? (
        <Component {...props} />
      ) : (
        <Redirect to={_getRedirectPath(props.location.state)} />
      )
    }
  />
);

// TODO: Something more powerful session-check
const AuthorizedRoute = ({ component: Component, session, location, isAdmin, ...rest }) =>(
  session ?
    isAdmin ?
      <AdminRoute component={Component} session={session} {...rest}/> :
      <ViewerRoute component={Component} session={session} location={location} {...rest}/>
    :
      <Route
        render={props =>
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        }
      />
)

const AdminRoute = ({ component: Component, session, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Component {...props} />
    )}
  />
);

const permittedViewerRoutes = [
  /^\/boards\/(.*)\/edit$/,
];

const ViewerRoute = ({ component: Component, location, ...rest }) => {
  const isEditOrCreateRoute = location?.pathname.includes('/edit') || location?.pathname.includes('/new');
  const scene = location?.pathname.split('/')[1];
  const canView = !isEditOrCreateRoute || permittedViewerRoutes.some(e => location?.pathname.match(e));

  return (
    <Route
      {...rest}
      render={props =>
        canView ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/' + scene,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
};

const mapStateToProps = state => ({
  session: state.session,
  isAdmin: getIsAdmin(state),
});

const ConnectedPublicRoute = connect(mapStateToProps)(PublicRoute);
const ConnectedAuthorizedRoute = connect(mapStateToProps)(AuthorizedRoute);

export {
  ConnectedPublicRoute as PublicRoute,
  ConnectedAuthorizedRoute as AuthorizedRoute,
};
