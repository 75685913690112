import React from 'react';
import { minToSec } from 'utils';
import { TimeOption } from '../TimeOption';
import { X01GameInOutTypeEnum } from 'enums';
export const startScoreOptions = [
    { label: 701, value: 701 },
    { label: 501, value: 501 },
    { label: 301, value: 301 },
    { label: 170, value: 170 },
];
export const SetOptions = [
    { label: '1 set', value: 1 },
    { label: '2 sets', value: 2 },
    { label: '3 sets', value: 3 },
    { label: '4 sets', value: 4 },
    { label: '5 sets', value: 5 },
    { label: '6 sets', value: 6 },
    { label: '7 sets', value: 7 },
    { label: '8 sets', value: 8 },
    { label: '9 sets', value: 9 },
    { label: '10 sets', value: 10 },
    { label: '11 sets', value: 11 },
    { label: '12 sets', value: 12 },
    { label: '13 sets', value: 13 },
    { label: '14 sets', value: 14 },
    { label: '15 sets', value: 15 },
];
export const LegOptions = [
    { label: '1 leg', value: 1 },
    { label: '2 legs', value: 2 },
    { label: '3 legs', value: 3 },
    { label: '4 legs', value: 4 },
    { label: '5 legs', value: 5 },
    { label: '6 legs', value: 6 },
    { label: '7 legs', value: 7 },
    { label: '8 legs', value: 8 },
    { label: '9 legs', value: 9 },
    { label: '10 legs', value: 10 },
    { label: '11 legs', value: 11 },
    { label: '12 legs', value: 12 },
    { label: '13 legs', value: 13 },
    { label: '14 legs', value: 14 },
    { label: '15 legs', value: 15 },
];
export const GameInOptions = [
    { label: 'Straight-in', value: X01GameInOutTypeEnum.Straight },
    { label: 'Double-in', value: X01GameInOutTypeEnum.Double },
];
export const GameOutOptions = [
    { label: 'Straight-out', value: X01GameInOutTypeEnum.Straight },
    { label: 'Double-out', value: X01GameInOutTypeEnum.Double },
    { label: 'Master-out', value: X01GameInOutTypeEnum.Master },
];
export const GameStartTimerOptions = [
    { label: React.createElement(TimeOption, { value: 5, label: "min to start" }), value: minToSec(5) },
    { label: React.createElement(TimeOption, { value: 10, label: "min to start" }), value: minToSec(10) },
    { label: React.createElement(TimeOption, { value: 20, label: "min to start" }), value: minToSec(20) },
];
export const GameFinishTimerOptions = [
    //{ label: 'Without finish timer', value: "" },
    { label: React.createElement(TimeOption, { value: 15, label: "min to finish" }), value: minToSec(15) },
    { label: React.createElement(TimeOption, { value: 30, label: "min to finish" }), value: minToSec(30) },
    { label: React.createElement(TimeOption, { value: 45, label: "min to finish" }), value: minToSec(45) },
];
export const GameInactivityTimerOptions = [
    { label: 'Without per visit timer', value: '' },
    { label: '90 min to finish', value: minToSec(90) },
    { label: '120 min to finish', value: minToSec(120) },
    { label: '150 min to finish', value: minToSec(150) },
    { label: '180 min to finish', value: minToSec(180) },
    { label: '210 min to finish', value: minToSec(210) },
];
