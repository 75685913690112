import { createSelector } from 'reselect';

const transformedOnlineSessions = (state, props) =>
  (state.data.boards[props.boardId].sessions || []).map((session) => ({
    rawData: session,
    type: 'online',
    startTime: Date.parse(session.connectedAt),
    endTime: Date.parse(session.disconnectedAt),
  }));

const transformedOfflineSessions = (state, props) =>
  (state.data.boards[props.boardId].offlineSessions || []).map((session) => ({
    rawData: session,
    type: 'offline',
    startTime: Date.parse(session.startTime),
    endTime: Date.parse(session.endTime),
  }));

const calculateSessionDimensions = (sessions, props) => {
  const filterStartDate = Date.parse(props.filters.startDate);
  const filterEndDate = Date.parse(props.filters.endDate);
  const filterDuration = filterEndDate - filterStartDate;

  if (filterDuration < 0) {
    return [];
  }

  return sessions
    .map((currentSession) => {
      const sessionFrom = Math.max(filterStartDate, currentSession.startTime);
      const sessionTo = Math.min(filterEndDate, currentSession.endTime);

      // using percentage values so we don't have to recalculate the coordinates
      // when the container dimension changes
      return {
        ...currentSession,
        startTime: sessionFrom,
        endTime: sessionTo,
        duration: sessionTo - sessionFrom,
        percentageFrom: `${((sessionFrom - filterStartDate) / filterDuration) * 100}%`,
        percentageWidth: `${((sessionTo - sessionFrom) / filterDuration) * 100}%`,
      };
    })
    .sort((a, b) => a.startTime - b.startTime);
};

const getCalculatedOnlineSessions = (state, props) => {
  const onlineSessions = transformedOnlineSessions(state, props);

  return calculateSessionDimensions(onlineSessions, props);
};

const getCalculatedOfflineSessions = (state, props) => {
  const offlineSessions = transformedOfflineSessions(state, props);

  return calculateSessionDimensions(offlineSessions, props);
};

// split overlapping offline sessions in rows
const getCalculatedOfflineSessionsInRows = createSelector(
  getCalculatedOfflineSessions,
  getCalculatedOnlineSessions,
  (offlineSessions, onlineSessions) => {
    let rows = [];
    let rowLevel = 0;
    let overlapEnd = 0;
    let currentSession = offlineSessions.shift();

    while (currentSession) {
      while (rows[rowLevel] && rows[rowLevel].slice(-1)[0].endTime > currentSession.startTime) {
        rows[rowLevel].slice(-1)[0].overlapped = true;
        rowLevel++;
      }

      if (overlapEnd > currentSession.startTime) {
        currentSession.overlapped = true;
      }

      // calculate overlapping online time
      currentSession.onlineTime = onlineSessions.reduce((onlineTime, currentOnlineSession) => {
        const onlineFrom = Math.max(currentOnlineSession.startTime, currentSession.startTime);
        const onlineTo = Math.min(currentOnlineSession.endTime, currentSession.endTime);

        return onlineTime + Math.max(0, onlineTo - onlineFrom);
      }, 0);

      rows[rowLevel] = [...(rows[rowLevel] || []), currentSession];
      overlapEnd = Math.max(overlapEnd, currentSession.endTime);
      rowLevel = 0;
      currentSession = offlineSessions.shift();
    }

    return rows.reverse();
  }
);

const getSessionsForList = createSelector(
  getCalculatedOfflineSessionsInRows,
  getCalculatedOnlineSessions,
  (offlineSessions, onlineSessions) =>
    [...onlineSessions, ...offlineSessions.flat()].sort((a, b) => b.startTime - a.startTime)
);

const getSessionsAreLoading = (state) => state.scenes.boards.show.isLoadingSessions;

const getIsHybridSbc = (state, props) => state.data.boards[props.boardId].isHybridSbc;

export {
  getCalculatedOfflineSessionsInRows,
  getCalculatedOnlineSessions,
  getSessionsAreLoading,
  getSessionsForList,
  getIsHybridSbc,
};
