import React from 'react'

import { Input } from 'components/Form';

import formatLabel from 'utils/formatLabel';

const Text = ({ label, value, name, type = "text", placeholder, error, onBlur, onChange, isDisabled, labelClass = "col-sm-4", valueClass = "col-sm-8", inputClass, isLabelFormatted = true, ...other }) => {
  return (
    <dl className='row'>
      <dt className={labelClass}>{isLabelFormatted ? formatLabel(label) : label}</dt>
      <dd className={valueClass}>
        <Input.Text
          name={name}
          className={inputClass}
          placeholder={placeholder}
          type={type}
          value={value}
          error={error}
          onBlur={onBlur}
          onChange={onChange}
          disabled={isDisabled}
          {...other}
        />
      </dd>
    </dl>
  )
}

export default Text