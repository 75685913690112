import { call, put, takeLatest } from 'redux-saga/effects';
import fileDownload from 'js-file-download';

import { ChatMessages } from 'services/api';
import { callApi } from 'services/rest';
import { download, downloadSuccessful, downloadFailure } from 'scenes/Chat/components/List/actions';

function* watchChatMessagesDownload({ payload: { startDate, endDate } }) {

  try {

    const filter = [];

    if (startDate) {
      filter.push({
        id: `gte:startDate`,
        value: startDate.toISOString(),
      });
    }

    if (endDate) {
      filter.push({
        id: `lte:endDate`,
        value: endDate.toISOString(),
      });
    }

    const { data } = yield call(
      callApi,
      ChatMessages.download,
      {
        resource:"chat-message",
        columns: {
          userId:"User Id",
          username:"Username",
          content:"Content",
          date:"Date"
        }
      },
      {
        filter,
        sort: [{id: 'date', value: 'desc'}]
      }
    );

    fileDownload(JSON.stringify(data), `${startDate.toISOString()}-${endDate.toISOString()}-chat-messages.json`);
    yield put(downloadSuccessful());
  } catch (e) {
    console.warn('TODO: Handle these with eg. toaster');
    console.log(e);
    yield put(downloadFailure());
  }
}

export default () => takeLatest(download, watchChatMessagesDownload);
