import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import cn from 'classnames';
import Download from '@axetroy/react-download';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { debounce } from "lodash-es";

import { Input } from 'components/Form';
import TableRow from 'components/TableRow'

import styles from './styles.module.scss';

import { statuses } from 'constants/BoardStatuses';

import { deepClone } from 'utils';
import { checkWhitespaceOnly, checkWhitespaceStartEnd } from 'utils/checkWhitespace';

import { getManufacturersForSelect } from 'scenes/Manufacturers/selectors';
import actions from 'scenes/Manufacturers/components/List/actions';

import { browserHistory } from 'services/history';

import { getUsersEmails } from "data/UsersEmails/actions"
import { getUsersEmailsForSelect } from "data/UsersEmails/selectors"
import { getHardwaresForSelect } from "data/Hardwares/selectors"
import { getPlatformsForSelect } from 'data/Platforms/selectors';
import { SBCModel } from 'enums';

const redirectionUrlOptions = [
  {
    value: 'api.develop.scoliadarts.com/api/localizator',
    label: 'api.develop.scoliadarts.com/api/localizator',
  },
  {
    value: 'api.develop2.scoliadarts.com/api/localizator',
    label: 'api.develop2.scoliadarts.com/api/localizator',
  },
  {
    value: 'api.develop3.scoliadarts.com/api/localizator',
    label: 'api.develop3.scoliadarts.com/api/localizator',
  },
  {
    value: 'api.develop4.scoliadarts.com/api/localizator',
    label: 'api.develop4.scoliadarts.com/api/localizator',
  },
  {
    value: 'api.staging.scoliadarts.com/api/localizator',
    label: 'api.staging.scoliadarts.com/api/localizator',
  },
  {
    value: 'api.game.scoliadarts.com/api/localizator',
    label: 'api.game.scoliadarts.com/api/localizator',
  },
  {
    value: 'api.apitest.scoliadarts.com/api/localizator',
    label: 'api.apitest.scoliadarts.com/api/localizator',
  },
  {
    value: 'api.benchmark.scoliadarts.com/api/localizator',
    label: 'api.benchmark.scoliadarts.com/api/localizator',
  },
];
const uploadOptions = [
  {
    value: false,
    label: 'Game end',
  },
  {
    value: true,
    label: 'Takeout',
  },
];

const saveOptions = [
  {
    value: false,
    label: 'Corrected images',
  },
  {
    value: true,
    label: 'All images',
  },
]

export const SBCModels = Object.entries(SBCModel).map(([key, value]) => ({ value, label: key }));

const isHome = (model) => [SBCModel.Home, SBCModel.Home2].includes(model);


const SERIAL_NUMBER_FILE_NAME_PREFIX = 'SerialNumbers';

class BoardForm extends Component {
  constructor(props) {
    super(props);

    const { board, isMultiple, session } = props;

    if (session.role === 'Viewer' && board.ownerId !== session._id) {
      browserHistory.push(`/boards`);
    }

    const redirectionUrl = redirectionUrlOptions.find(
      option => option.value === board.redirectionUrl
    );

    const bannedFromDate = new Date(board?.bannedPeriod?.bannedFrom);
    const bannedToDate = new Date(board?.bannedPeriod?.bannedTo);

    const isBanValid = bannedToDate >= Date.now();
    const isBanned = board?.isBannedFromTournaments && isBanValid;

    const bannedFrom = board?.bannedPeriod?.bannedFrom && isBanValid ? bannedFromDate : new Date(new Date().setHours(0, 0, 0, 0));
    const bannedTo = board?.bannedPeriod?.bannedFrom && isBanValid ? bannedToDate : undefined;

    this.state = {
      form: {
        ipAddress: board.ipAddress || '',
        isFakeSbc: board.isFakeSbc || false,
        isHomeSbc: board.isHomeSbc || false,
        isHybridSbc: board.isHybridSbc || false,
        name: isMultiple ? 'My Scolia board' : board.name || '',
        serialNumber: '',
        uploadCameraImages: board.uploadCameraImages || false,
        ftpUploadAtTakeout: board.ftpUploadAtTakeout || false,
        saveAll: board.saveAll || false,
        firmware: {
          onlyStableFirmware: board.firmware.onlyStableFirmware ?? true,
          isAutoUpdateEnabled: board.firmware.isAutoUpdateEnabled || false,
        },
        status: board.status || '',
        ownerId: board.ownerId || null,
        hardwareVersion: board.hardwareVersion || '',
        platformVersion: board.platformVersion || '',
        manufacturerId: board.manufacturerId || null,
        isRedirected: board.isRedirected || false,
        redirectionUrl: redirectionUrl
          ? redirectionUrl.value
          : board.redirectionUrl
            ? 'custom'
            : '',
        redirectionUrlText: redirectionUrl ? '' : board.redirectionUrl ? board.redirectionUrl : '',
        useFixedBoardCode: board.useFixedBoardCode || false,
        numberOfBoardsToGenerate: 1,
        isBannedFromTournaments: isBanned,
        bannedPeriod: {
          bannedFrom: bannedFrom,
          bannedTo: bannedTo,
        },
        sbcModel: board.sbcModel || '',
      },
      selectedStatus: this.findObjectByValue(statuses, board.status) || {
        label: '',
        value: '',
      },
      selectedHardware: {
        label: '',
        value: '',
      },
      selectedPlatform: {
        label: '',
        value: '',
      },
      selectedManufacturer: {
        label: '',
        value: '',
      },
      selectedRedirectionUrl: {
        label: redirectionUrl ? redirectionUrl.value : board.redirectionUrl ? 'Custom' : '',
        value: redirectionUrl ? redirectionUrl.value : board.redirectionUrl ? 'custom' : '',
      },
      selectedUpload: this.findObjectByValue(uploadOptions, board.ftpUploadAtTakeout) || {
        label: '',
        value: '',
      },
      selectedSave: this.findObjectByValue(saveOptions, board.saveAll) || {
        label: '',
        value: '',
      },
      selectedOwner: { label: board.ownerEmail || "", value: board.ownerId || "" },
      selectedSBCModel: this.findObjectByValue(SBCModels, board.sbcModel) || {
        label: '',
        value: '',
      },
    };
  }

  componentDidMount() {
    this.props.dispatch(actions.getManufacturers());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.hardwares !== this.props.hardwares) {
      this.setState({
        selectedHardware: this.findObjectByValue(this.props.hardwares, this.props.board.hardwareVersion) || {
          label: '',
          value: '',
        }
      });
    }

    if (prevProps.manufacturers !== this.props.manufacturers) {
      this.setState({
        selectedManufacturer: this.props.manufacturers.find(
          option => option.value === this.props.board.manufacturerId
        ),
      });
    }

    if (prevProps.platforms !== this.props.platforms) {
      this.setState({
        selectedPlatform: this.findObjectByValue(this.props.platforms, this.props.board.platformVersion) || {
          label: '',
          value: '',
        }
      })
    }
  }

  fetchUsersEmails = debounce((input) => {
    if (input.length >= 1) {
      this.props.dispatch(getUsersEmails({ filter: [{ id: 'email', value: input }] }))
    }
  }, 500)

  findObjectByValue(array, value) {
    return array.find(object => object.value === value);
  }

  getValidationSchema = () => {
    let boardSchema = Yup.object().shape({
      name: Yup.string()
        .required('Name is required')
        .max(60, 'Must be max 60 characters long!')
        .test(
          'white-space-free',
          'Name is required and cannot contain only spaces!',
          checkWhitespaceOnly
        )
        .test('white-space-free', 'Name cannot start or end with space!', checkWhitespaceStartEnd),
      status: Yup.string().required('Status is required'),
      hardwareVersion: Yup.string().required('Hardware version is required'),
      platformVersion: Yup.string().required('Platform version is required'),
      isHybridSbc: Yup.boolean(),
      sbcModel: Yup.string().required('SBC Model is required'),
      manufacturerId: Yup.string().when('isHybridSbc', {
        is: true,
        then: Yup.string()
          .required('Manufacturer is required')
          .nullable(),
        otherwise: Yup.string().nullable(),
      }),
      isRedirected: Yup.boolean(),
      redirectionUrl: Yup.string().when('isRedirected', {
        is: val => Boolean(val),
        then: Yup.string().required('Redirection URL is required'),
      }),
      redirectionUrlText: Yup.string().when('redirectionUrl', {
        is: val => val === 'custom',
        then: Yup.string()
          .required('Redirection URL is required')
          .test(
            'white-space-free',
            'Redirection URL is required and cannot contain only spaces!',
            checkWhitespaceOnly
          )
          .test(
            'white-space-free',
            'Redirection URL cannot start or end with space!',
            checkWhitespaceStartEnd
          ),
      }),
      useFixedBoardCode: Yup.boolean(),
      ftpUploadAtTakeout: Yup.boolean(),
      saveAll: Yup.boolean(),
      isBannedFromTournaments: Yup.boolean(),
      bannedPeriod: Yup.object().when('isBannedFromTournaments', {
        is: true,
        then: Yup.object().shape({
          bannedFrom: Yup.date().required("The date is required!").nullable(),
          bannedTo: Yup.date().required("The date is required!").nullable().min(Yup.ref('bannedFrom'), "The bannedTo field must be later than bannedFrom!")
        })
      })
    });

    if (this.props.isMultiple) {
      boardSchema = boardSchema.shape({
        numberOfBoardsToGenerate: Yup.number().required('Number of Boards is required'),
      });
    }
    return boardSchema;
  };

  handleSubmit = values => {
    const { isEdit, onSubmit } = this.props;
    const { form } = this.state;

    if (values.redirectionUrlText && values.redirectionUrl === 'custom') {
      values.redirectionUrl = values.redirectionUrlText;
    }
    !values.serialNumber && delete values.serialNumber;
    delete values.redirectionUrlText;

    if (values.uploadCameraImages === false) {
      values.ftpUploadAtTakeout = false;
      values.saveAll = false;
    }

    if (values.status !== 'Attached') {
      delete values.ownerId;
    }

    if (!values.isBannedFromTournaments) {
      delete values.bannedPeriod;
    }

    const clonedValues = deepClone(values);

    if (isEdit) {
      Object.keys(clonedValues).forEach(key => {
        if (
          typeof clonedValues[key] === 'object' &&
          clonedValues[key] !== null &&
          Object.entries(clonedValues[key]).toString() === Object.entries(form[key]).toString()
        ) {
          delete clonedValues[key];
        } else if (typeof values[key] !== 'object' && clonedValues[key] === form[key]) {
          delete clonedValues[key];
        }
      });
    }

    onSubmit(clonedValues);
  };

  get generatedSerialNumbers() {
    return this.props.generatedSerialNumbers.reduce((acc, curr) => (acc += `${curr}\n`), []);
  }

  get fileName() {
    const date = new Date();

    const month = Intl.DateTimeFormat('hu-HU', { month: '2-digit' }).format(date);
    const day = Intl.DateTimeFormat('hu-HU', { day: '2-digit' }).format(date);
    const hour = Intl.DateTimeFormat('hu-HU', { hour: '2-digit' }).format(date);
    const minute = date.toLocaleTimeString().split(':')[1];

    return `${SERIAL_NUMBER_FILE_NAME_PREFIX}--${date.getUTCFullYear()}-${month}-${day}--${hour}-${minute}.txt`;
  }

  get isDownloadReady() {
    return this.props.generatedSerialNumbers && this.props.generatedSerialNumbers.length > 0;
  }

  render() {
    const { form } = this.state;
    const { isEdit, formError, isMultiple, manufacturers, hardwares, platforms, usersEmails } = this.props;

    return (
      <div>
        <Formik
          initialValues={form}
          validationSchema={this.getValidationSchema}
          onSubmit={this.handleSubmit}
        >
          {({ errors, touched, values, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              {!isEdit && (
                <TableRow.Text
                  label="Serial number"
                  labelClass={cn("col-sm-3", {
                    [styles.labelDisabled]: isMultiple,
                  })}
                  isLabelFormatted={false}
                  valueClass={cn("col-sm-9", {
                    [styles.labelDisabled]: isMultiple,
                  })}
                  inputClass="s-input"
                  error={touched.serialNumber && errors.serialNumber}
                  name="serialNumber"
                  type="text"
                  value={values.serialNumber || ''}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={isMultiple}
                />
              )}
              <TableRow.Text
                label="Name"
                labelClass="col-sm-3 required"
                isLabelFormatted={false}
                valueClass="col-sm-9"
                name="name"
                inputClass="s-input"
                error={touched.name && errors.name}
                value={values.name || ''}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={this.isDownloadReady}
                maxLength={60}
              />
              <TableRow.Checkbox
                label="Fake SBC"
                labelClass="col-sm-3"
                isLabelFormatted={false}
                valueClass="col-sm-9"
                value={values.isFakeSbc}
                error={touched.isFakeSbc && errors.isFakeSbc}
                name="isFakeSbc"
                type="checkbox"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={this.isDownloadReady}
              />
              <TableRow.Select
                label="SBC Model"
                labelClass="col-sm-3 required"
                valueClass="col-sm-9"
                name="sbcModel"
                isLabelFormatted={false}
                value={this.state.selectedSBCModel}
                onChange={selectedSBCModel => {
                  setFieldValue('sbcModel', selectedSBCModel.value);
                  setFieldValue('isHomeSbc', isHome(selectedSBCModel.value));
                  this.setState({
                    selectedSBCModel,
                  });
                }}
                options={SBCModels}
                error={touched.sbcModel && errors.sbcModel}
                isDisabled={this.isDownloadReady}
                helperText={errors.sbcModel && (
                  <Alert color="danger" className="alert-select">
                    <i className="fa fa-exclamation-triangle" />
                    {errors.sbcModel}
                  </Alert>
                )}
              />
              {!values.isHomeSbc && (
                <TableRow.Checkbox
                  label="Fixed board code"
                  labelClass="col-sm-3"
                  isLabelFormatted={false}
                  valueClass="col-sm-9"
                  value={!values.isHomeSbc && values.useFixedBoardCode}
                  name="useFixedBoardCode"
                  type="checkbox"
                  onBlur={handleBlur}
                  onChange={e => setFieldValue('useFixedBoardCode', e.target.checked)}
                  disabled={this.isDownloadReady}
                />
              )}
              <TableRow.Checkbox
                label="Hybrid SBC"
                labelClass="col-sm-3"
                isLabelFormatted={false}
                valueClass="col-sm-9"
                value={values.isHybridSbc}
                error={touched.isHybridSbc && errors.isHybridSbc}
                name="isHybridSbc"
                type="checkbox"
                onBlur={handleBlur}
                onChange={e => {
                  handleChange(e);

                  setFieldValue('manufacturerId', null);
                  this.setState({
                    selectedManufacturer: null,
                  });
                }}
                disabled={this.isDownloadReady}
              />
              {values.isHybridSbc && (
                <TableRow.Select
                  label="Manufacturer"
                  labelClass="col-sm-3 required"
                  valueClass="col-sm-9"
                  name="manufacturerId"
                  value={this.state.selectedManufacturer}
                  onChange={manufacturer => {
                    setFieldValue('manufacturerId', manufacturer.value);
                    this.setState({
                      selectedManufacturer: manufacturer,
                    });
                  }}
                  options={manufacturers}
                  error={touched.manufacturerId && errors.manufacturerId}
                  isDisabled={this.isDownloadReady}
                  helperText={errors.manufacturerId && (
                    <Alert color="danger" className="alert-select">
                      <i className="fa fa-exclamation-triangle" />
                      {errors.manufacturerId}
                    </Alert>
                  )}
                />
              )}
              <TableRow.Text
                label="IP Address"
                labelClass="col-sm-3"
                isLabelFormatted={false}
                name="ipAddress"
                inputClass="s-input"
                value={values.ipAddress}
                valueClass="col-sm-9"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={this.isDownloadReady}
              />
              <TableRow.Checkbox
                label="Uploading images"
                labelClass="col-sm-3"
                isLabelFormatted={false}
                valueClass="col-sm-9"
                value={values.uploadCameraImages}
                name="uploadCameraImages"
                type="checkbox"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={this.isDownloadReady}
              />
              {values.uploadCameraImages ?
                <TableRow.Select
                  label="Save"
                  labelClass="col-sm-3"
                  valueClass="col-sm-9"
                  name="saveAll"
                  value={this.state.selectedSave}
                  onChange={selectedSave => {
                    setFieldValue('saveAll', selectedSave.value);
                    this.setState({
                      selectedSave,
                    });
                  }}
                  options={saveOptions}
                  error={touched.saveAll && errors.saveAll}
                  isDisabled={this.isDownloadReady}
                  helperText={errors.saveAll && (
                    <Alert color="danger" className="alert-select">
                      <i className="fa fa-exclamation-triangle" />
                      {errors.saveAll}
                    </Alert>
                  )}
                /> : null}
              {values.uploadCameraImages ?
                <TableRow.Select
                  label="Upload at"
                  labelClass="col-sm-3"
                  isLabelFormatted={false}
                  valueClass="col-sm-9"
                  name="ftpUploadAtTakeout"
                  value={this.state.selectedUpload}
                  onChange={selectedUpload => {
                    setFieldValue('ftpUploadAtTakeout', selectedUpload.value);
                    this.setState({
                      selectedUpload,
                    });
                  }}
                  options={uploadOptions}
                  error={touched.ftpUploadAtTakeout && errors.ftpUploadAtTakeout}
                  isDisabled={this.isDownloadReady}
                  helperText={errors.ftpUploadAtTakeout && (
                    <Alert color="danger" className="alert-select">
                      <i className="fa fa-exclamation-triangle" />
                      {errors.ftpUploadAtTakeout}
                    </Alert>
                  )}
                /> : null}
              <TableRow.Checkbox
                label="Only stable firmware"
                labelClass="col-sm-3"
                isLabelFormatted={false}
                valueClass="col-sm-9"
                value={values.firmware && values.firmware.onlyStableFirmware}
                name="firmware.onlyStableFirmware"
                type="checkbox"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={this.isDownloadReady}
              />
              <TableRow.Checkbox
                label="Automatic updates"
                labelClass="col-sm-3"
                isLabelFormatted={false}
                valueClass="col-sm-9"
                value={values.firmware && values.firmware.isAutoUpdateEnabled}
                name="firmware.isAutoUpdateEnabled"
                type="checkbox"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={this.isDownloadReady}
              />
              <TableRow.Select
                label="Status"
                labelClass="col-sm-3 required"
                valueClass="col-sm-9"
                name="status"
                value={this.state.selectedStatus}
                onChange={selectedStatus => {
                  setFieldValue('status', selectedStatus.value);
                  this.setState({
                    selectedStatus,
                  });
                }}
                options={statuses}
                error={touched.status && errors.status}
                isDisabled={this.isDownloadReady}
                helperText={errors.status && (
                  <Alert color="danger" className="alert-select">
                    <i className="fa fa-exclamation-triangle" />
                    {errors.status}
                  </Alert>
                )}
              />
              {this.state.selectedStatus.value === "Attached" &&
                <TableRow.Select
                  label="Owner"
                  labelClass="col-sm-3"
                  valueClass="col-sm-9"
                  name="ownerId"
                  options={usersEmails}
                  value={this.state.selectedOwner}
                  onInputChange={(input) => this.fetchUsersEmails(input)}
                  onChange={selectedOwner => { setFieldValue('ownerId', selectedOwner.value); this.setState({ selectedOwner }) }}
                />
              }
              <TableRow.Select
                label="Hardware version"
                labelClass="col-sm-3 required"
                isLabelFormatted={false}
                valueClass="col-sm-9"
                name="hardwareVersion"
                value={this.state.selectedHardware}
                onChange={selectedHardware => {
                  setFieldValue('hardwareVersion', selectedHardware.value);
                  this.setState({
                    selectedHardware,
                  });
                }}
                options={hardwares}
                error={touched.hardwareVersion && errors.hardwareVersion}
                isDisabled={this.isDownloadReady}
                helperText={errors.hardwareVersion && (
                  <Alert color="danger" className="alert-select">
                    <i className="fa fa-exclamation-triangle" />
                    {errors.hardwareVersion}
                  </Alert>
                )}
              />

              <TableRow.Select
                label="Platform version"
                labelClass="col-sm-3 required"
                isLabelFormatted={false}
                valueClass="col-sm-9"
                name="hardwareVersion"
                value={this.state.selectedPlatform}
                onChange={selectedPlatform => {
                  setFieldValue('platformVersion', selectedPlatform.value);
                  this.setState({
                    selectedPlatform,
                  });
                }}
                options={platforms}
                error={touched.platformVersion && errors.platformVersion}
                isDisabled={this.isDownloadReady}
                helperText={errors.platformVersion && (
                  <Alert color="danger" className="alert-select">
                    <i className="fa fa-exclamation-triangle" />
                    {errors.platformVersion}
                  </Alert>
                )}
              />

              <TableRow.Checkbox
                label="Redirected"
                labelClass="col-sm-3"
                isLabelFormatted={false}
                valueClass="col-sm-9"
                value={values.isRedirected && values.isRedirected}
                name="isRedirected"
                type="checkbox"
                onBlur={handleBlur}
                onChange={e => {
                  setFieldValue('isRedirected', e.target.checked);

                  if (!e.target.checked) {
                    setFieldValue('redirectionUrl', '');
                    this.setState({
                      selectedRedirectionUrl: '',
                    });
                  }
                }}
                disabled={this.isDownloadReady}
              />
              <TableRow.Select
                label="Redirection URL"
                labelClass="col-sm-3 required"
                valueClass="col-sm-9"
                isLabelFormatted={false}
                name="redirectionUrl"
                value={this.state.selectedRedirectionUrl}
                onChange={selectedRedirectionUrl => {
                  setFieldValue('redirectionUrl', selectedRedirectionUrl.value);
                  this.setState({
                    selectedRedirectionUrl,
                  });
                }}
                options={[...redirectionUrlOptions, { value: 'custom', label: 'Custom' }]}
                isDisabled={!values.isRedirected || this.isDownloadReady}
                error={touched.redirectionUrl && errors.redirectionUrl}
                helperText={errors.redirectionUrl && (
                  <Alert color="danger" className="alert-select">
                    <i className="fa fa-exclamation-triangle" />
                    {errors.redirectionUrl}
                  </Alert>
                )}
              />
              {values.redirectionUrl === 'custom' && (
                <TableRow.Text
                  label=""
                  labelClass="col-sm-3"
                  isLabelFormatted={false}
                  valueClass="col-sm-9"
                  inputClass="s-input"
                  error={touched.redirectionUrlText && errors.redirectionUrlText}
                  name="redirectionUrlText"
                  value={values.redirectionUrlText}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={this.isDownloadReady}
                />
              )}
              {isEdit && <>
                <TableRow.Checkbox
                  label="Banned from tournaments"
                  labelClass="col-sm-3"
                  isLabelFormatted={false}
                  value={values.isBannedFromTournaments}
                  valueClass="col-sm-9"
                  name='isBannedFromTournaments'
                  type='checkbox'
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {values.isBannedFromTournaments &&
                  <>
                    <div className='row'>
                      <div className='col-sm-12'>Banned period</div>
                    </div>
                    <div className='row'>
                      <TableRow.DatePicker
                        label="Date from"
                        value={values.bannedPeriod?.bannedFrom}
                        name='bannedPeriod.bannedFrom'
                        onBlur={handleBlur}
                        onChange={date => {
                          setFieldValue('bannedPeriod.bannedFrom', date);
                        }}
                        isError={!!errors.bannedPeriod?.bannedFrom}
                        errorMsg={errors.bannedPeriod?.bannedFrom && (
                          <Alert color="danger" className="alert-select">
                            <i className="fa fa-exclamation-triangle" />
                            {errors.bannedPeriod.bannedFrom}
                          </Alert>
                        )}
                      />
                      <TableRow.DatePicker
                        label="Date to"
                        value={values.bannedPeriod?.bannedTo}
                        name='bannedPeriod.bannedTo'
                        onBlur={handleBlur}
                        onChange={date => {
                          setFieldValue('bannedPeriod.bannedTo', date);
                        }}
                        isError={!!errors.bannedPeriod?.bannedTo}
                        errorMsg={errors.bannedPeriod?.bannedTo && (
                          <Alert color="danger" className="alert-select">
                            <i className="fa fa-exclamation-triangle" />
                            {errors.bannedPeriod.bannedTo}
                          </Alert>
                        )}
                      />
                    </div>
                  </>
                }
              </>}
              {formError && <div className={cn(styles.formError, 'col-sm-12')}>{formError}</div>}
              <div
                className={cn('action-footer', {
                  [styles.multipleActionFooter]: isMultiple,
                })}
              >
                {isMultiple && (
                  <div className={styles.multipleGenerateWrapper}>
                    <div className={styles.inputWrapper}>
                      <span>Number of Boards to generate: </span>

                      <Input.Text
                        type="text"
                        name="numberOfBoardsToGenerate"
                        value={values.numberOfBoardsToGenerate}
                        onBlur={handleBlur}
                        onChange={numberOfBoardsToGenerate => {
                          const { value } = numberOfBoardsToGenerate.target;
                          if (value.includes(',') || value.includes('.')) return;

                          if ((value > 0 && value < 1001) || !value) {
                            setFieldValue('numberOfBoardsToGenerate', value);
                            this.setState({
                              numberOfBoardsToGenerate: value,
                            });
                          }
                        }}
                        disabled={this.isDownloadReady}
                      />
                    </div>
                    {touched.numberOfBoardsToGenerate && errors.numberOfBoardsToGenerate && (
                      <Alert color="danger" className="alert-select" style={{ width: '95%' }}>
                        <i className="fa fa-exclamation-triangle" />
                        {errors.numberOfBoardsToGenerate}
                      </Alert>
                    )}
                  </div>
                )}
                {this.isDownloadReady ? (
                  <Download file={this.fileName} content={this.generatedSerialNumbers}>
                    <button
                      className={cn('btn btn-primary btn-md -align-right', styles.actionButtons)}
                      type="button"
                    >
                      Download Serial Numbers
                    </button>
                  </Download>
                ) : (
                  <button
                    className={cn('btn btn-success btn-md -align-right', styles.actionButtons)}
                    type="submit"
                  >
                    {isEdit
                      ? 'Edit Board'
                      : `Create ${isMultiple
                        ? `${values.numberOfBoardsToGenerate} ${values.numberOfBoardsToGenerate > 1 ? 'Boards' : 'Board'
                        }`
                        : 'Board'
                      }`}
                  </button>
                )}
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

BoardForm.propTypes = {
  board: PropTypes.object.isRequired,
  formError: PropTypes.string,
  isEdit: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  isMultiple: PropTypes.bool,
  generatedSerialNumbers: PropTypes.array,
};

BoardForm.defaultProps = {
  board: {
    firmware: {},
  },
};

const mapStateToProps = state => ({
  hardwares: getHardwaresForSelect(state),
  platforms: getPlatformsForSelect(state),
  manufacturers: getManufacturersForSelect(state),
  usersEmails: getUsersEmailsForSelect(state),
  session: state.session
});

export default connect(mapStateToProps)(BoardForm);
