import { call, put, takeLatest } from 'redux-saga/effects';

import { Platforms } from 'services/api';
import { notifyError, notifySuccess } from 'services/notification/actions';

import { browserHistory } from 'services/history';

import {
  createPlatform,
  createPlatformFailure,
  createPlatformSuccess,
} from 'scenes/Platforms/components/Create/actions';
import { callApi } from 'services/rest';

function* watchCreatePlatform({ payload: { platform } }) {
  try {
    yield call(callApi, Platforms.create, platform);
    yield put(createPlatformSuccess());
    yield put(notifySuccess(`Platform was create successfully.`));

    browserHistory.push('/platforms');
  } catch (e) {
    if (!e.isFetch) {
      throw e;
    }
    yield put(createPlatformFailure(e.error));
    yield put(notifyError('Failed to create platform.'));
  }
}

export default () => takeLatest(createPlatform, watchCreatePlatform);
