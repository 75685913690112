/**
 * Formats a string by capitalizing each word, replacing underscores with spaces,
 * and separating them with a space.
 * 
 * @param {string} input - The input string that needs to be formatted.
 * @returns {string} The input string formatted with each word capitalized,
 *                   underscores replaced by spaces, and separated by a space.
 */
function formatLabel(input) {
  if ( !input ) {
    return '-'
  }

  const words = input
    .replace(/_/g, ' ') // Replace underscores with spaces
    .replace(/([A-Z])/g, ' $1') // Add a space before uppercase letters
    .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
    .trim() // Remove leading and trailing spaces
    .split(' ');

  const formattedWords = words.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  return formattedWords.join(' ');
}

export default formatLabel