import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { EntityTable } from 'components/EntityTable';
import { formatDateNumber, getPreparedFiltersForSelectedFields, formatDate } from 'utils';

import actions from './actions';
import { feedbacks } from 'constants/Resources';

const columns = [
  {
    Header: 'Feedback id',
    accessor: 'feedbackId',
    filterable: true,
  },
  {
    Header: 'Sent at',
    accessor: 'sentAt',
    Cell: props => {
      const date = new Date(props.value);
      return (
        date.getFullYear() +
        '.' +
        formatDateNumber(date.getMonth() + 1) +
        '.' +
        formatDateNumber(date.getDate()) +
        '.' +
        ' ' +
        formatDateNumber(date.getHours()) +
        ':' +
        formatDateNumber(date.getMinutes())
      );
    },
  },
  {
    Header: 'Email',
    accessor: 'email',
    filterable: true,
  },
  {
    Header: 'Category',
    accessor: 'category',
    filterable: true,
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'All'}
      >
        <option value="All">All</option>
        <option value="GeneralFeedback">General Feedback</option>
        <option value="BugReport">Bug Report</option>
        <option value="FeatureRequest">Feature Request</option>
      </select>
    ),
  },
  {
    Header: 'Message',
    accessor: 'message',
  },
];

class List extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
    };

    this.selectColumns = ['category', 'feedbackId'];
  }

  handleFetchData = ({ filter, ...rest }) => {
    const clonedFilter = getPreparedFiltersForSelectedFields(filter, this.selectColumns);

    return this.props.dispatch(
      actions.getFeedbacks({ filter: clonedFilter, ...rest })
    );
  };

  handleRowClick = row => {
    this.setState({
      selectedRow: row,
      isModalOpen: true,
    });
  };

  toggleModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  get entityModal() {
    const { selectedRow } = this.state;
    if (selectedRow) {
      return (
        <div>
          <Modal isOpen={this.state.isModalOpen} toggle={this.toggleModal}>
            <ModalHeader toggle={this.toggleModal}>Feedback [#{selectedRow.feedbackId}]</ModalHeader>
            <ModalBody>
              <div className="key-value-data-container">
                <div className="row">
                  <div className="col-sm-4">Sent by:</div>
                  <div className="col-sm-8">
                    {selectedRow.userId ? (
                      <Link to={`/users/${selectedRow.userId}`}>
                        {selectedRow.email}
                      </Link>
                    ) : (
                      selectedRow.email
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">Sent at:</div>
                  <div className="col-sm-8">
                    {formatDate(selectedRow.sentAt, true)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">Category:</div>
                  <div className="col-sm-8">{selectedRow.category}</div>
                </div>
                <div className="row">
                  <div className="col-sm-4">Message:</div>
                  <div
                    className="col-sm-8"
                    style={{ maxHeight: '40vh', overflow: 'auto' }}
                  >
                    {selectedRow.message}
                  </div>
                </div>
              </div>
              <hr />
              <div className="key-value-data-container">
                <div className="row">
                  <div className="col-sm-4">App scene:</div>
                  <div className="col-sm-8">
                    {selectedRow.clientData.appScene}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">Display screen:</div>
                  <div className="col-sm-8">
                    {selectedRow.clientData.displayWidth}px *{' '}
                    {selectedRow.clientData.displayHeight}px
                  </div>
                </div>{' '}
                <div className="row">
                  <div className="col-sm-4">Viewport size:</div>
                  <div className="col-sm-8">
                    {selectedRow.clientData.viewportWidth}px *{' '}
                    {selectedRow.clientData.viewportHeight}px
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">Platform:</div>
                  <div className="col-sm-8">
                    {selectedRow.clientData.platform}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">User agent:</div>
                  <div className="col-sm-8">
                    {selectedRow.clientData.userAgent}
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      );
    } return null;
  }

  render() {
    const { data, page, pageSize, isLoading, totalCount, isDownloading, isError } = this.props;

    return (
      <div className="animated fadeIn">
        <EntityTable
          resource={feedbacks}
          columns={columns}
          selectColumns={this.selectColumns}
          data={isError ? [] : data}
          page={page}
          pageSize={pageSize}
          totalCount={totalCount}
          isLoading={isLoading}
          hasDateFilter
          datePropertyKey={'sentAt'}
          onFetchData={this.handleFetchData}
          onRowClick={this.handleRowClick}
          isDownloading={isDownloading}
        />
        {this.entityModal}
      </div>
    );
  }
}

List.displayName = 'Feedbacks.List';

const mapStateToProps = state => ({
  data: state.data.feedbacks,
  page: state.scenes.feedbacks.list.page,
  pageSize: state.scenes.feedbacks.list.pageSize,
  totalCount: state.scenes.feedbacks.list.totalCount,
  isLoading: state.scenes.feedbacks.list.isLoading,
  isDownloading: state.scenes.feedbacks.list.isDownloading,
  isError: state.scenes.feedbacks.list.isError,
});

export default connect(mapStateToProps)(List);
