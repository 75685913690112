export enum TournamentStageType {
  Final = 'final',
  SemiFinal = 'semiFinal',
  QuarterFinal = 'quarterFinal',
  Top16 = 'top16',
  Top32 = 'top32',
  Top64 = 'top64',
  Top128 = 'top128',
  Top256 = 'top256',
}
