import { call, put, takeLatest } from 'redux-saga/effects';
import { DateTime } from 'luxon'

import { Games } from 'services/api';
import { callApi } from 'services/rest';

import {
  getUserGameHistory,
  getUserGameHistoryFailure,
  getUserGameHistorySuccess,
} from 'scenes/Users/components/Show/components/GameHistory/actions';

import { isNullOrUndefined } from 'utils';

const isNullish = (value) => {
  return value === 'All' || value === '' || isNullOrUndefined(value)
}

function* watchGetUserGameHistory({ payload: { userId, limit } }) {
  const currentDate = DateTime.now();

  const offset = 0;
  const filters = {
    startDate: currentDate.toISODate(),
    gameType: "",
    outCome: "",
    numberOfPlayers: ["1", '2', '3', '4', '5', '6', '7', '8']
  };


  const filteredFilters = { ...filters };

  Object.entries(filteredFilters).forEach(([key, value]) => {
    if (isNullish(value)) {
      delete filteredFilters[key];
    }
  });

  try {
    const { data } = yield call(callApi, Games.index,userId, {
      offset,
      limit,
      ...filteredFilters,
    });
    yield put(getUserGameHistorySuccess(data));
  } catch (e) {
    if (!e.isFetch) {
      throw e;
    }

    yield put(getUserGameHistoryFailure(e.data.error));
  }
}


export default () => takeLatest(getUserGameHistory, watchGetUserGameHistory);
