import React, { useEffect, useState, useCallback } from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

export const BackToTop = () => {

  const [isVisible, setVisible] = useState(false);
  const parentNode = document.body.getElementsByClassName('backToTopScrollable')[0];

  //Note that the array of dependencies is not passed as arguments to the callback since v18,
  //More information: https://reactjs.org/docs/hooks-reference.html#usecallback
  const handleScroll = useCallback(
    _e => {
      if (parentNode.scrollTop > 300) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    },
    [parentNode]
    );
    
    const handleTopScroll = () => {
      parentNode.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };
    
    useEffect(() => {
    parentNode && parentNode.addEventListener('scroll', handleScroll, false);

    return () => {
      parentNode &&
      parentNode.removeEventListener('scroll', handleScroll, false);
    };
  }, [parentNode, handleScroll]);

  return (
    <div
      className={cn(styles.returnToTop, {
        [styles.isVisible]: isVisible,
      })}
      onClick={handleTopScroll}
    >
      <i className='fa fa-fw fa-arrow-up' />
    </div>
  );
};

export default BackToTop;
