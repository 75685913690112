import { put, takeLatest } from 'redux-saga/effects';

import countries from 'i18n-iso-countries';

import { getCountries, getCountriesSuccess, getCountriesFailure } from './actions';
import en from 'i18n-iso-countries/langs/en.json';

function* watchGetCountries() {
  countries.registerLocale(en);

  try {
    const alpha3Codes = countries.getAlpha3Codes();

    const data = Object.keys(alpha3Codes).reduce((acc, code) => {
      acc.push({
        name: countries.getName(code, 'en'),
        alpha3Code: code,
      });
      return acc;
    }, []);

    yield put(getCountriesSuccess(data));
  } catch (e) {
    console.warn('TODO: Handle these with eg. toaster');
    console.log(e);
    yield put(getCountriesFailure());
  }
}

export default () => takeLatest(getCountries, watchGetCountries);
