import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import PAYMENT_PROVIDERS from 'constants/PaymentProviders';
import PAYMENT_PRODUCT_TYPES from 'constants/PaymentProductTypes';

import * as ProductsActions from './actions';

import { EntityTable } from 'components/EntityTable';
import { getFilter } from 'components/ListSelector';

import { formatDate } from 'utils';


const columns = [
  {
    Header: 'Product name',
    accessor: 'name',
    filterable: true,
    Cell: props => <Link to={`/products/${props?.row?._original?._id}`}>{props?.value}</Link>,
  },
  {
    Header: 'Provider',
    accessor: 'paymentProvider',
    filterable: true,
    Filter: getFilter(PAYMENT_PROVIDERS),
  },
  {
    Header: 'Type',
    accessor: 'type',
    filterable: true,
    Filter: getFilter(PAYMENT_PRODUCT_TYPES),
  },
  {
    Header: 'Created',
    accessor: 'createdAt',
    Cell: props => formatDate(props.value),
  },
  {
    Header: 'Last updated',
    accessor: 'updatedAt',
    Cell: props => formatDate(props.value),
  },
];

const defaultSorted = [
  {
    id: 'throwsTotal',
    desc: true,
  },
];

const List = () => {
  const { data, page, pageSize, isLoading, totalCount, isDownloading } = useSelector(state => state.scenes.products.list);
  const dispatch = useDispatch();

  const handleFetchData = query => {
    dispatch(ProductsActions.getProductsList(query));
  };

  return (
    <EntityTable
      resource="products"
      columns={columns}
      data={data ?? []}
      page={page}
      pageSize={pageSize}
      defaultSorted={defaultSorted}
      totalCount={totalCount}
      isLoading={isLoading}
      onFetchData={handleFetchData}
      datePropertyKey="createdAt"
      isDownloading={isDownloading}
    />
  );
}

List.displayName = 'Products.List';

export default List;
