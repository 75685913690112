import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import actions from './actions';
import { getHardware, getHardwareSuccess, getHardwareFailure } from 'scenes/Hardwares/actions';

const INITIAL_STATE = {
  isLoading: false,
};

const editReducer = handleActions(
  {
    [actions.updateHardware]: state =>
      update(state, {
        isUpdatePending: {
          $set: true,
        },
        updateError: {
          $set: '',
        },
      }),

    [actions.updateHardwareSuccess]: state =>
      update(state, {
        isUpdatePending: {
          $set: false,
        },
        isUpdateSuccessful: {
          $set: true,
        },
        updateError: {
          $set: '',
        },
      }),

    [actions.updateHardwareFailure]: (state, { payload }) =>
      update(state, {
        isUpdatePending: {
          $set: false,
        },
        isUpdateSuccessful: {
          $set: false,
        },
        updateError: {
          $set: payload,
        },
      }),

    [getHardware]: (state, { payload: { isLoading } }) => ({
      ...state,
      isLoading,
    }),

    [getHardwareSuccess]: state => ({
      ...state,
      isLoading: false,
    }),

    [getHardwareFailure]: state => ({
      ...state,
      isLoading: false,
    }),

  },
  INITIAL_STATE
);

export default editReducer;
