import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import { List } from './components/List';
import { Show } from './components/Show';
import { Create } from './components/Create';
import { Edit } from './components/Edit';

class ServiceAccounts extends Component {
  render() {
    const {
      match: { url },
    } = this.props;
    return (
      <Switch>
        <Route
          component={Create}
          exact
          name="Create service account"
          path={`${url}/new`}
        />
        <Route
          component={Edit}
          exact
          name="Edit service account"
          path={`${url}/:serviceAccountId/edit`}
        />
      <Route component={List} exact name="Service account list" path={`${url}/`} />
        <Route
          component={Show}
          exact
          name="Service account info"
          path={`${url}/:serviceAccountId`}
        />
      </Switch>
    );
  }
}

export default ServiceAccounts;
