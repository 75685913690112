import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import ROUTES from 'constants/Routes';

const findRouteName = url => ROUTES[url];

const getPaths = pathname => {
  const paths = ['/'];

  if (pathname === '/') return paths;

  pathname.split('/').reduce((prev, curr) => {
    const currPath = `${prev}/${curr}`;
    paths.push(currPath);
    return currPath;
  });
  return paths;
};

const BreadcrumbsItem = ({ match, ..._rest }) => {
  const routeName = findRouteName(match.url);
  if (routeName) {
    return match.isExact ? (
      <BreadcrumbItem active>{routeName}</BreadcrumbItem>
    ) : (
      <BreadcrumbItem>
        <Link to={match.url || ''}>{routeName}</Link>
      </BreadcrumbItem>
    );
  }
  return null;
};

const Breadcrumbs = ({ location: { pathname }, _match, ..._rest }) => {
  const paths = getPaths(pathname);
  const items = paths.map((path, i) => (
    <Route key={i++} path={path} component={BreadcrumbsItem} />
  ));
  return <Breadcrumb>{items}</Breadcrumb>;
};

const RoutedBreadcrumbs = (props) => (
  <div>
    <Route path="/:path" component={Breadcrumbs} {...props} />
  </div>
);


export default RoutedBreadcrumbs