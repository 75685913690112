import { call, put, takeLatest } from 'redux-saga/effects';

import { Manufacturers } from 'services/api';
import { browserHistory } from 'services/history';
import { callApi } from 'services/rest';

import {
  createManufacturer,
  createManufacturerFailure,
  createManufacturerSuccess,
} from 'scenes/Manufacturers/components/Create/actions';

function* watchCreateManufacturer({ payload: { manufacturer } }) {
  try {
    yield call(callApi, Manufacturers.create, manufacturer);
    yield put(createManufacturerSuccess());
    browserHistory.push('/manufacturers');
  } catch (e) {
    if (!e.isFetch) {
      throw e;
    }
    yield put(createManufacturerFailure(e.error));
  }
}

export default () => takeLatest(createManufacturer, watchCreateManufacturer);
