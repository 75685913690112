import { useUnleashContext } from '@unleash/proxy-client-react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getSessionUserId } from 'services/session/selectors';

export function useSetupFlagContext() {
  const userId = useSelector(getSessionUserId);

  const updateContext = useUnleashContext();

  useEffect(() => {
    updateContext({ userId });
  }, [userId]);
}
