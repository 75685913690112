import React, { useState } from 'react';
import Resizer from "react-image-file-resizer";
import cn from 'classnames';
import { isNull } from 'lodash';
import { SError, SButton, SAnimate, SAnimateVariantsEnum, SizesEnum, SButtonVariantOptionsEnum } from 'ScoliaComponents';
import { PrivateTournamentThumbnails } from 'constants/ScoliaThumbnails';
import { UploadIcon, TrashIcon } from 'components/Icons';
import { CropModal } from 'components/CropModal';
import { ThemesEnum } from 'enums';
import { isDefaultThumbnail, mapBoolPropsToClassnames as mapBPTC } from 'utils';
import styles from './styles.module.scss';
export const ImageUpload = ({ name, onChange, value = undefined, uploadedFile = undefined }) => {
    const [state, setState] = useState({
        file: isDefaultThumbnail(value) ? null : uploadedFile,
        temporaryFile: null,
        isCropModalOpen: false,
        fileExtensionError: false,
    });
    const { fileExtensionError, file, isCropModalOpen, temporaryFile } = state;
    const handleResizeFile = (file) => (new Promise((resolve, reject) => {
        try {
            Resizer.imageFileResizer(file, // Is the file of the image which will resized.
            500, // Is the maxWidth of the resized new image. (ratio is preserved)
            500, // Is the maxHeight of the resized new image. (ratio is preserved)
            "JPEG", // Is the compressFormat of the resized new image.
            100, // Is the quality of the resized new image.
            0, // Is the degree of clockwise rotation to apply to uploaded image.
            (uri) => resolve(uri), // Is the callBack function of the resized new image URI.
            "base64", // Is the output type of the resized new image.
            300, // Is the minWidth of the resized new image.
            300);
        }
        catch (error) {
            reject(error);
        }
    }));
    function dataURItoBlob(dataURI) {
        var byteString = atob(dataURI.split(',')[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: 'image/jpeg' });
    }
    function hasValidFileExtensionByPath(path) {
        return /\.(jpe?g|png)$/i.test(path);
    }
    const handlePictureUpload = (e) => {
        const filePath = e.target.value;
        if (isNull(e.target.files)) {
            throw new Error('No file uploaded.');
        }
        const file = e.target.files[0];
        if (!hasValidFileExtensionByPath(filePath)) {
            setState({ fileExtensionError: true, file: null, isCropModalOpen: false, temporaryFile: null });
        }
        else {
            setState({ fileExtensionError: false, file: file, isCropModalOpen: true, temporaryFile: URL.createObjectURL(file) });
        }
        // This is necessary, because onChange event only fires if the value changes (~the user selects DIFFERENT picture)
        e.target.value = '';
    };
    const handleDelete = () => {
        setState({
            isCropModalOpen: false,
            fileExtensionError: false,
            file: null,
        });
        onChange({ target: { name, value: PrivateTournamentThumbnails.goldenTrophy } });
    };
    const handleSelect = () => {
        if (!value || !file || (value === file && file)) {
            return;
        }
        onChange({ target: { name, value: 'custom' } });
    };
    const preventAction = (event) => {
        event.stopPropagation();
    };
    const resizeFile = (fileToResize) => {
        handleResizeFile(fileToResize).then(res => {
            onChange({ target: { name: 'thumbnailImage', value: res } });
            onChange({ target: { name, value: 'custom' } });
            setState({ fileExtensionError: false, file: res, isCropModalOpen: false, temporaryFile: null });
        }).catch(err => {
            console.error(err);
        });
    };
    const handleDismiss = () => {
        resizeFile(file);
    };
    const handlePictureCropSubmit = (croppedImage) => {
        resizeFile(dataURItoBlob(croppedImage));
    };
    const isInactive = value && file && value !== 'custom';
    const isActive = file && value === 'custom';
    const src = typeof temporaryFile === 'string' ? temporaryFile : typeof file === 'string' ? file : undefined;
    return (React.createElement("div", { className: styles.imageUpload },
        file && (React.createElement(CropModal, { isOpen: isCropModalOpen, onDismiss: handleDismiss, onSubmit: handlePictureCropSubmit, isDeclineHidden: true, title: "Edit your thumbnail image", image: file, theme: ThemesEnum.Astro, borderRadius: 0, width: 200, height: 150, isRotatable: false, shouldResize: false })),
        React.createElement("div", { className: styles.upload },
            React.createElement("button", { type: "button", onClick: handleSelect, className: cn(styles.uploadedImageContainer, mapBPTC(styles, { isInactive, isActive })) }, file ? (React.createElement("img", { src: src, alt: "thumbnail", className: styles.uploadedImage, loading: "lazy" })) : (React.createElement("label", { htmlFor: name, className: styles.uploadFallback, onClick: preventAction },
                React.createElement(UploadIcon, null),
                " ",
                "Upload an image"))),
            React.createElement("div", { className: styles.photoActions },
                React.createElement("div", { className: styles.uploadButton },
                    React.createElement("label", { htmlFor: name, className: styles.inputFileButton },
                        React.createElement(UploadIcon, null),
                        file ? "Change" : "Upload"),
                    React.createElement("input", { onChange: handlePictureUpload, type: 'file', accept: 'image/x-png,image/jpeg', id: name, name: name, className: styles.inputFile })),
                React.createElement("div", { className: styles.removeButtonContainer },
                    React.createElement(SAnimate, { in: !!file, variant: SAnimateVariantsEnum.SlideInTop },
                        React.createElement(SButton, { variant: SButtonVariantOptionsEnum.TertiaryGray, size: SizesEnum.Small, theme: ThemesEnum.Astro, className: styles.removeButton, onClick: handleDelete, name: "removeButton" },
                            React.createElement(TrashIcon, null),
                            "Remove"))))),
        React.createElement(SAnimate, { in: fileExtensionError, variant: SAnimateVariantsEnum.SlideInTop },
            React.createElement(SError, null, "Please make sure to upload a JPG or PNG file and try again."))));
};
