import { call, put, takeLatest } from 'redux-saga/effects';

import { Users } from 'services/api';
import { callApi } from 'services/rest';
import { browserHistory } from 'services/history';

import {
  getUser,
  getUserFailure,
  getUserSuccess,
} from 'scenes/Users/components/Show/actions';

function* watchGetUser({ payload: { userId, queryParams } }) {
  try {
    const { data } = yield call(callApi, Users.show, userId, queryParams);
    yield put(getUserSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(getUserFailure());
    browserHistory.push('/users/');
  }
}

export default () => takeLatest(getUser, watchGetUser);
