import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';

import { actions as dashboardActions } from 'scenes/Dashboard';

const INITIAL_STATE = {
  isLoading: true,
};

const dashboardReducer = handleActions(
  {
    [dashboardActions.getDashboard]: (state, _payload) => ({
      ...state,
      isLoading: true,
    }),

    [dashboardActions.getDashboardSuccess]: (state, _payload) => ({
      ...state,
      isLoading: false,
    }),
  },
  INITIAL_STATE
);

const dashboard = combineReducers({
  dashboard: dashboardReducer,
});

export default dashboard;
