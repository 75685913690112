import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { Link } from 'react-router-dom';

import { deleteManufacturer } from 'scenes/Manufacturers/components/Show/actions';

import styles from './styles.module.scss';

class MainInfo extends PureComponent {
  delete(id) {
    const result = window.confirm('Are you sure you want to delete this manufacturer?');
    if (result) {
      this.props.dispatch(deleteManufacturer(id));
    }
  }
  render() {
    const { id, name, email, phoneNumber, address, manufacturerBoards, isAdmin } = this.props;

    let length = 0;
    if (manufacturerBoards) length = Object.keys(manufacturerBoards).length;

    return (
      <div>
        <Card color="white">
          <CardHeader>
            <div className="card-header__left">
              <i className="fa fa-align-justify" /> Manufacturer's Data
            </div>
            <div className="card-header__right">
              {isAdmin && <Link to={`/manufacturers/${id}/edit`}>
                <button className="btn btn-primary btn-sm" type="button">
                  Edit
                </button>
              </Link>}
              <div className={styles.tooltipWrapper}>
                {isAdmin && <button className="btn btn-danger btn-sm ms-1" disabled={length > 0}
                  type="button"
                  onClick={() => this.delete(id)}
                >
                  Delete
              </button>}
                {length > 0 && <div className={styles.tooltip}>You can't delete manufacturer with board(s) attached to it!</div>}
              </div>

            </div>
          </CardHeader>

          <CardBody>
            <div className="key-value-data-container">
              <div className="row">
                <div className="col-sm-2">Name</div>
                <div className="col-sm-10">
                  <strong>{name || 'N/A'}</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-2">E-mail</div>
                <div className="col-sm-10">
                  <strong>{email || '-'}</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-2">Telephone</div>
                <div className="col-sm-10">
                  <strong>{phoneNumber || '-'}</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-2">Address</div>
                <div className="col-sm-10">
                  <strong>{address || '-'}</strong>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

MainInfo.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  address: PropTypes.string,
  isAdmin: PropTypes.bool,
};

export default connect()(MainInfo);
