import { combineReducers } from 'redux';

import { reducer as mainInfo } from './components/MainInfo';
import { reducer as assignedBoards } from './components/AssignedBoards';

const show = combineReducers({
  mainInfo,
  assignedBoards
});

export default show;
