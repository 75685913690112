import React, { useCallback, useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { getPreparedFiltersForSelectedFields, isNullOrUndefined } from 'utils';
import { actions as entityTableActions } from 'services/entityTable';
import { actions as notificationActions } from 'services/notification';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { DateTime } from 'luxon';
export const EntityTable = function ({ columns, data, totalCount, isLoading, resource, hasValidation, validators, showMonthYearPicker, createResourceButtons, customButtons, customDeleteButtonLabel, isCustomDeleteDisabled, quickFilters, isDownloading, customDownloadButtons, downloadParams, isDownloadPossible = true, isAdmin = false, selectColumns = [], defaultSorted = [], hasDateFilter = false, datePropertyKey = 'date', defaultPageRowSize = 20, pageSizeOptions = [5, 10, 20, 25, 50, 100, 200, 500], onFetchData, onRowClick, handleCustomDelete, onQueryMapping = () => { }, children, }) {
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(defaultPageRowSize);
    const [sorted, setSorted] = useState(defaultSorted);
    const [filtered, setFiltered] = useState([]);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    useEffect(() => {
        debouncedFetchData();
        return debouncedFetchData.cancel;
    }, [page, pageSize, sorted, filtered, fromDate, toDate]);
    const getQueryParams = () => {
        const mutatedSort = sorted.reduce((mutatedArray, { id, desc }) => {
            mutatedArray.push({ id, value: desc ? 'desc' : 'asc' });
            return mutatedArray;
        }, []);
        const clonedFilter = getPreparedFiltersForSelectedFields(filtered, selectColumns);
        if (fromDate) {
            clonedFilter.push({
                id: `gte:${datePropertyKey}`,
                value: fromDate.toISOString(),
            });
        }
        if (toDate) {
            clonedFilter.push({
                id: `lte:${datePropertyKey}`,
                value: toDate.toISOString(),
            });
        }
        onQueryMapping({ sorted: mutatedSort, filter: clonedFilter });
        return {
            page: page + 1,
            pageSize: pageSize,
            sort: mutatedSort,
            filter: clonedFilter,
        };
    };
    const getHeaderValidity = () => {
        return filtered.every(({ id, value }) => {
            if (!id || !value) {
                return true;
            }
            return validators?.[id] ? validators[id](value) : true;
        });
    };
    const fetchData = useCallback((isRefresh = false) => {
        if (hasValidation && !getHeaderValidity()) {
            return;
        }
        else {
            return onFetchData(getQueryParams(), isRefresh);
        }
    }, [hasValidation, getHeaderValidity, onFetchData, getQueryParams]);
    const debouncedFetchData = debounce(fetchData, 300, { trailing: true });
    const handleFetchData = ({ page, pageSize }) => {
        setPage(page);
        setPageSize(pageSize);
    };
    const handleRefreshButtonClick = () => {
        fetchData(true);
    };
    const handleResetFiltersButtonClick = () => {
        setFiltered([]);
        setSorted(defaultSorted);
        setFromDate(null);
        setToDate(null);
    };
    const handleExportDownloadButtonClick = (actionParams) => {
        const requestBody = getQueryParams();
        // Provide resource type as a query param
        requestBody.resource = actionParams?.resource ?? resource;
        requestBody.columns = actionParams?.columns ?? getColumnNameLabelPairs();
        requestBody.discriminator = downloadParams?.discriminator;
        // Delete useless props for export
        delete requestBody.page;
        delete requestBody.pageSize;
        dispatch(entityTableActions.download(requestBody));
    };
    const checkDates = ({ type, date, dateAsText, }) => {
        if (!isNullOrUndefined(dateAsText) &&
            dateAsText !== '' &&
            !DateTime.fromFormat(dateAsText, 'yyyy.mm.dd hh:mm').isValid &&
            !DateTime.fromFormat(dateAsText, 'yyyy.mm.dd').isValid) {
            return;
        }
        const checkDate = ({ msg, dateEarly, dateLate, }) => {
            if (!dateEarly)
                return { ok: true, dateToSet: dateEarly };
            if (!dateLate)
                dateLate = new Date();
            if (dateEarly < dateLate) {
                return { ok: true, dateToSet: dateEarly };
            }
            else {
                dispatch(notificationActions.notifyError(msg));
                return { ok: false, dateToSet: dateEarly };
            }
        };
        const dateChecks = {
            fromDate: {
                dateEarly: date,
                dateLate: toDate,
                msg: 'Earlier than the from date!',
            },
            toDate: {
                dateEarly: fromDate,
                dateLate: date,
                msg: 'Later than the to date!',
            },
        };
        const resultDates = checkDate(dateChecks[type]);
        if (resultDates.ok) {
            const resultFuture = checkDate({
                msg: 'Cannot set future date!',
                dateEarly: date,
            });
            if (resultFuture.ok) {
                if (type === 'fromDate') {
                    setFromDate(resultFuture.dateToSet);
                }
                else {
                    setToDate(resultFuture.dateToSet);
                }
                setPage(0);
            }
            return;
        }
        if (type === 'fromDate') {
            setFromDate(resultDates.dateToSet);
        }
        else {
            setToDate(resultDates.dateToSet);
        }
    };
    const handleFromDateChange = (date, event) => {
        checkDates({ type: 'fromDate', date, dateAsText: event?.currentTarget?.value });
    };
    const handleToDateChange = (date, event) => {
        checkDates({ type: 'toDate', date, dateAsText: event?.currentTarget?.value });
    };
    const handleFilteredChange = (filtered) => {
        setFiltered(filtered);
    };
    const handleQuickFilter = (filterId, filterValue) => {
        setPage(0);
        setFiltered([
            ...filtered.filter((filter) => filter.id !== filterId),
            { id: filterId, value: filterValue },
        ]);
    };
    const handleSortedChange = (sorted) => {
        setSorted(sorted);
    };
    const handlePageChange = (page) => {
        setPage(page);
    };
    const tableRow = (_state, row) => {
        if (onRowClick && row && row.row && row.original) {
            return {
                onClick: () => onRowClick(row.original),
                style: {
                    cursor: 'pointer',
                },
            };
        }
        else {
            return {};
        }
    };
    const getColumnNameLabelPairs = () => {
        return columns.reduce((acc, curr) => {
            if (typeof curr.accessor === 'string') {
                acc[curr.accessor] = curr['Header'];
            }
            return acc;
        }, {});
    };
    const pageCount = totalCount % pageSize === 0 ? totalCount / pageSize : Math.floor(totalCount / pageSize) + 1;
    return (React.createElement("div", { className: "animated fadeIn" },
        React.createElement(Header, { fromDate: fromDate, toDate: toDate, onFromDateChange: handleFromDateChange, onToDateChange: handleToDateChange, onExportDownloadButtonClick: handleExportDownloadButtonClick, onResetFiltersButtonClick: handleResetFiltersButtonClick, onRefreshButtonClick: handleRefreshButtonClick, hasDateFilter: hasDateFilter, showMonthYearPicker: showMonthYearPicker, createResourceButtons: createResourceButtons, customDownloadButtons: customDownloadButtons, isAdmin: isAdmin, isLoading: isLoading, isDownloading: isDownloading, customDeleteButtonLabel: customDeleteButtonLabel, isCustomDeleteDisabled: isCustomDeleteDisabled, handleCustomDelete: handleCustomDelete, isDownloadPossible: isDownloadPossible, customButtons: customButtons, quickFilters: quickFilters, quickFilter: handleQuickFilter, downloadParams: downloadParams }, children),
        React.createElement(ReactTable, { manual: true, noDataText: "No data available", columns: columns, data: data, pages: pageCount, page: page, loading: isLoading, filtered: filtered, sorted: sorted, onPageChange: handlePageChange, onFilteredChange: handleFilteredChange, onSortedChange: handleSortedChange, onFetchData: handleFetchData, className: "-striped -highlight", getTrProps: tableRow, pageSizeOptions: pageSizeOptions, defaultPageSize: defaultPageRowSize }),
        React.createElement(Footer, { page: page, pageSize: pageSize, totalCount: totalCount ? totalCount : 0 })));
};
