import { createAction } from 'redux-actions';

export const updateFirmware = createAction('UPDATE_FIRMWARE_REQUEST');
export const updateFirmwareFailure = createAction('UPDATE_FIRMWARE_FAILURE');
export const updateFirmwareSuccess = createAction('UPDATE_FIRMWARE_SUCCESS');

export default {
  updateFirmware,
  updateFirmwareFailure,
  updateFirmwareSuccess,
};
