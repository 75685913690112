import makeTypedNotificationWatcher from '../makeTypedNotificationWatcher';

import { notifyError } from 'services/notification/actions';

const actions = [];

export default makeTypedNotificationWatcher({
  actions,
  actionCreator: notifyError,
});
