import { call, put, takeLatest } from 'redux-saga/effects';

import { Boards } from 'services/api';
import { callApi } from 'services/rest';

import {
  changeTestMode,
  changeTestModeFailure,
  changeTestModeSuccess,
} from 'scenes/Boards/components/Show/actions';
import { getBoard } from 'scenes/Boards/actions';
import { notifySuccess, notifyError } from 'services/notification';

function* watchChangeTestMode({ payload: { boardId, isInTestMode } }) {
  try {
    const { data: isSuccessFull } = yield call(callApi, Boards.changeTestMode, boardId, isInTestMode);
    if (isSuccessFull) {
      yield put(changeTestModeSuccess(boardId));
      yield put(notifySuccess('Test mode value changed successfully.'));
      yield put(getBoard(boardId))
    } else {
      yield put(changeTestModeFailure());
      yield put(notifyError('Couldn\'t change test mode value.'));
    }
  } catch (e) {
    console.log(e);
    yield put(changeTestModeFailure());
    yield put(notifyError('Couldn\'t change test mode value.'));
  }
}

// It must be wrapped in a function, so execution will happen only on function call
export default () => takeLatest(changeTestMode, watchChangeTestMode);
