import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import cn from 'classnames';

import { EntityTable } from 'components/EntityTable';

import { serviceAccounts as serviceAccountsResource } from 'constants/Resources'

import { getPreparedFiltersForSelectedFields } from 'utils';
import { getIsAdmin } from 'services/session/selectors';

import * as serviceAccountsActions from './actions';

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
    filterable: true,
    Cell: props => {
      return (
        <Link to={`/service-accounts/${props.row._original.id}`}>
          {props.value}
        </Link>
      );
    },
  },
  {
    Header: 'E-mail',
    accessor: 'email',
    filterable: true,
  },
  {
    Header: 'Contact Phone Number',
    accessor: 'phoneNumber',
  },
  {
    Header: 'Social api',
    accessor: 'isSocialApiEnabled',
    filterable: true,
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'All'}
      >
        <option value="All">All</option>
        <option value="true">Enabled</option>
        <option value="false">Disabled</option>
      </select>
    ),
    Cell: props => (
      <Badge
        color={cn({
          success: props.value === true,
          danger: props.value === false,
        })}
      >
        {props.value ? 'Enabled' : 'Disabled'}
      </Badge>
    ),
  },
  {
    Header: 'Social Access Token',
    accessor: 'socialAccessToken',
  },
];

const defaultSorted = [
  {
    id: 'name',
    desc: false,
  },
];

class List extends Component {
  constructor(props) {
    super(props);

    this.selectColumns = ['isSocialApiEnabled'];
  }

  handleFetchData = ({ filter, ...rest }) => {
    const clonedFilter = getPreparedFiltersForSelectedFields(
      filter,
      this.selectColumns
    );

    return this.props.dispatch(
      serviceAccountsActions.getServiceAccounts({
        filter: clonedFilter,
        ...rest,
      })
    );
  };

  render() {
    const { data, page, pageSize, isLoading, totalCount, isAdmin, isDownloading } = this.props;

    const serviceAccounts = Object.values(data);

    return (
      <div>
        <EntityTable
          resource={serviceAccountsResource}
          columns={columns}
          selectColumns={this.selectColumns}
          data={serviceAccounts}
          page={page}
          pageSize={pageSize}
          totalCount={totalCount}
          defaultSorted={defaultSorted}
          isLoading={isLoading}
          onFetchData={this.handleFetchData}
          createResourceButtons={[
            {
              value: 'New Service Account',
              link: '/service-accounts/new',
            },
          ]}
          isAdmin={isAdmin}
          isDownloading={isDownloading}
          defaultPageRowSize={100}
        />
      </div>
    );
  }
}

List.displayName = 'ServiceAccounts.List';

const mapStateToProps = state => ({
  data: state.data.serviceAccounts,
  page: state.scenes.serviceAccounts.list.page,
  pageSize: state.scenes.serviceAccounts.list.pageSize,
  totalCount: state.scenes.serviceAccounts.list.totalCount,
  isLoading: state.scenes.serviceAccounts.list.isLoading,
  isAdmin: getIsAdmin(state),
  isDownloading: state.scenes.serviceAccounts.list.isDownloading,
});

export default connect(mapStateToProps)(List);
