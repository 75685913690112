import { createAction } from 'redux-actions';

export const updateServiceAccount= createAction('UPDATE_SERVICE_ACCOUNT_REQUEST');
export const updateServiceAccountFailure = createAction('UPDATE_SERVICE_ACCOUNT_FAILURE');
export const updateServiceAccountSuccess = createAction('UPDATE_SERVICE_ACCOUNT_SUCCESS');

export default {
  updateServiceAccount,
  updateServiceAccountFailure,
  updateServiceAccountSuccess,
};
