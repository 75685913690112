import { UserOptionResponseDto } from 'dtos';
import { validateEmail, validateScoliaId } from 'utils'

import { post } from 'services/rest';

const scoliaIdsUrl = '/users/validate-users';

interface ErrorResponse {
  status?: number;
  message?: string;
}

//TODO remove these, when we have proper ts rest calls
function isErrorWithStatus(error: any): error is ErrorResponse {
  return typeof error === 'object' && error !== null && 'status' in error;
}

export const handleValidateUsers = async (
  scoliaIdsAndEmails: string[],
  onSuccess: (
    scoliaIdsAndEmails: string[],
    data: UserOptionResponseDto[],
  ) => void,
  onFailure: (scoliaIdsAndEmails: string[]) => void,
  callBack: (emails: string[]) => void,
) => {
  let data: UserOptionResponseDto[];
  const emails = scoliaIdsAndEmails.filter(validateEmail);
  const filteredScoliaIds = scoliaIdsAndEmails.filter(validateScoliaId);

  callBack(emails);

  try {
    const response = await post(scoliaIdsUrl, {
      scoliaIds: filteredScoliaIds,
      emails,
    } as any);
    data = response?.data as UserOptionResponseDto[];
  } catch (e: unknown) {
    if (isErrorWithStatus(e) && e.status === 404) {
      onFailure(scoliaIdsAndEmails);
      return;
    }
    console.error(e);
    return;
  }

  if (data) {
    onSuccess(scoliaIdsAndEmails, data);
  } else {
    onFailure(scoliaIdsAndEmails);
  }
};