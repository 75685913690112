import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import App from './App';
import store from './store';
import * as serviceWorker from './serviceWorker';
import './i18nextScolia'; // Only for init sideeffect - using `reactI18nextModule`
import 'stylesheets/main.scss';
const container = document.getElementById('root');
const root = createRoot(container);
root.render(React.createElement(Provider, { store: store },
    React.createElement(App, null)));
serviceWorker.unregister();
