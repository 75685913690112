import { call, put, takeLatest } from 'redux-saga/effects';

import { RankingList } from 'services/api';
import { callApi } from 'services/rest';

import {
  getRankingList,
  getRankingListFailure,
  getRankingListSuccess,
} from 'scenes/RankingList/components/List/actions';

function* watchGetRankingList({ payload: { queryParams } }) {
  try {

    const { data } = yield call(callApi, RankingList.index, queryParams);
    yield put(getRankingListSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(getRankingListFailure());
  }
}

// It must be wrapped in a function, so execution will happen only on function call
export default () => takeLatest(getRankingList, watchGetRankingList);
