import { call, put, takeLatest } from 'redux-saga/effects';

import { StoreSnapshot } from 'services/api';
import { callApi } from 'services/rest';

import {
  getStoreSnapshot,
  getStoreSnapshotSuccess,
  getStoreSnapshotFailure,
} from 'scenes/StoreSnapshot/components/actions';

function* watchGetStoreSnapshot({ payload }) {
  try {
    const { data } = yield call(callApi, StoreSnapshot.index, payload);

    yield put(getStoreSnapshotSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(getStoreSnapshotFailure());
  }
}

// It must be wrapped in a function, so execution will happen only on function call
export default () => takeLatest(getStoreSnapshot, watchGetStoreSnapshot);
