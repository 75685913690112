import { handleActions } from 'redux-actions';

import actions from './actions';

const INITIAL_STATE = [];

const usersReducer = handleActions(
  {
    [actions.getUsersEmails]: (state) => {
      return { ...state }
    },

    [actions.getUsersEmailsSuccess]: (state, { payload }) => {
      const usersEmails = payload.reduce((acc, user) => {
        user.id = user._id;
        delete user._id;

        acc[user.id] = user;
        return acc;
      }, {});

      return { ...usersEmails };
    },

    [actions.getUsersEmailsFailure]: (state) => {
      return { ...state }
    }

  },
  INITIAL_STATE
);

export default usersReducer;
