import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { SSelect, SAnimate, SAnimateVariantsEnum, SOptionsInput, SizesEnum } from 'ScoliaComponents'
import { BullThrowEnum, ThemesEnum, ThrowOrderTypeEnum, X01GameInOutTypeEnum } from 'enums';

import { ScoreTypes, Temp_ScoreTypeOptions } from 'constants/scoreTypeOptions'
import { CloseIcon } from 'components/Icons'

import { GameFinishTimerOptions, GameInOptions, GameOutOptions, GameStartTimerOptions, LegOptions, startScoreOptions } from './constants'
import { TournamentStageLabels } from 'constants/Tournament'
import { minToSec, secToMin } from 'utils'
import { TimeOption } from '../TimeOption'

import styles from './styles.module.scss'

const TIME_MAX_LENGTH = 3;

const GameConfigurationCard = ({ gameConfig, from, to, onBlur, onSelect, idx, values, onToStageClick, onRemoveStageClick, errors, touched, disabled }) => {

  const handleTimeChange = (value, name) => {
    onSelect(minToSec(value), name);
  }

  const tournamentStageOptions = useMemo(() => {
    const stages = gameConfig.stages;

    return stages.map((stage) => ({
      label: TournamentStageLabels[stage],
      value: stage
    }));
  }, [gameConfig.stages])

  const handleStageChange = (value) => {
    onToStageClick(value);
  }
  
  const filteredLegOptions = gameConfig.scoreType === ScoreTypes.RaceTo ? LegOptions : LegOptions.filter((elem) => elem.value % 2 !== 0)

  const handleSelect = (value, name) => {
    onSelect(value, name)

    if ( value === ScoreTypes.BestOf && gameConfig.legs % 2 === 0 ) {
      onSelect(gameConfig.legs - 1, `variousGameConfig[${idx}].legs`)
    }
  }

  return (
    <div className={styles.gameConfigurationCard}>
      {from && to && (
        <div className={styles.stagesRange}>
          <div className={styles.labelContainer}>
            {from !== to && (
              <>
                <span>
                  From
                </span>
                <span className={styles.stageLabel}>
                  {TournamentStageLabels[from]}  
                </span>
                <span>
                  to
                </span>
                <SSelect
                  name={`variousGameConfig[${idx}].stage`}
                  value={to}
                  onSelect={handleStageChange}
                  onBlur={onBlur}
                  options={tournamentStageOptions}
                  showLabel={false}
                  size="sm"
                  theme={ThemesEnum.Astro}
                  isTransparent
                  className={styles.stageSelector}
                  disabled={disabled}
                />
              </>
            )}
            {from === to && (
              <span className={styles.stageLabel}>
                {TournamentStageLabels[from]}
              </span>
            )}
          </div>
          {idx !== 0 && (
            <div>
              <button type="button" className={styles.headerButton} onClick={onRemoveStageClick(idx)}>
              <CloseIcon />
              </button>
          </div>
          )}
        </div>
      )}
      <div className={styles.gameConfiguration}>
        <div className={cn(styles.gameOption, styles.startScore)}>
          <SSelect
            name={`variousGameConfig[${idx}].startScore`}
            value={gameConfig.startScore}
            onSelect={onSelect}
            onBlur={onBlur}
            options={startScoreOptions}
            isDropdownIconVisible={false}
            showLabel={false}
            size="sm"
            theme={ThemesEnum.Astro}
            disabled={disabled}
          />
        </div>
        <div className={styles.separator}>
          {`∙`}
        </div>
        <div className={styles.gameOption}>
          <SSelect
            name={`variousGameConfig[${idx}].scoreType`}
            value={gameConfig.scoreType}
            onSelect={handleSelect}
            onBlur={onBlur}
            options={Temp_ScoreTypeOptions}
            isDropdownIconVisible={false}
            showLabel={false}
            size="sm"
            theme={ThemesEnum.Astro}
            disabled={disabled}
          />
        </div>
        <div className={cn(styles.gameOption, styles.setLeg)}>
          <SSelect
            name={`variousGameConfig[${idx}].legs`}
            value={gameConfig.legs}
            onSelect={onSelect}
            onBlur={onBlur}
            options={filteredLegOptions}
            isDropdownIconVisible={false}
            showLabel={false}
            size="sm"
            theme={ThemesEnum.Astro}
            disabled={disabled}
          />
        </div>
        <div className={styles.separator}>
          {`∙`}
        </div>
        <div className={styles.gameOption}>
          <SSelect
            name={`variousGameConfig[${idx}].gameOutType`}
            value={gameConfig.gameOutType}
            onSelect={onSelect}
            onBlur={onBlur}
            options={GameOutOptions}
            isDropdownIconVisible={false}
            showLabel={false}
            size="sm"
            theme={ThemesEnum.Astro}
            disabled={disabled}
          />
        </div>
        <div className={styles.separator}>
          {`∙`}
        </div>
        <div className={styles.gameOption}>
          <SSelect
            name={`variousGameConfig[${idx}].gameInType`}
            options={GameInOptions}
            value={gameConfig.gameInType}
            onSelect={onSelect}
            onBlur={onBlur}
            isDropdownIconVisible={false}
            showLabel={false}
            size="sm"
            theme={ThemesEnum.Astro}
            disabled={disabled}
          />
        </div>
        <SAnimate variant={SAnimateVariantsEnum.ZoomIn} in={values.globalStartTimer === 'custom'} className={styles.gameOption}>
          <div className={styles.gameConfigElem}>
            <SOptionsInput
              onChange={handleTimeChange}
              name={`variousGameConfig[${idx}].startTimer`}
              options={GameStartTimerOptions}
              value={secToMin(gameConfig.startTimer) ?? 0}
              error={touched.variousGameConfig && touched.variousGameConfig[idx]?.startTimer && errors.variousGameConfig && errors.variousGameConfig[idx]?.startTimer}
              onSelect={onSelect}
              onBlur={onBlur}
              theme={ThemesEnum.Astro}
              lockedOptionContainerSize
              showLabel={false}
              size={SizesEnum.Small}
              isDropdownIconVisible={false}
              maxLength={TIME_MAX_LENGTH}
              useFiltering={false}
              className={styles.timeInput}
              min={0}
              step={1}
              max={20}
              disabled={disabled}
            />
            <div className={styles.timeOptionMask}>
              <TimeOption value={secToMin(gameConfig.startTimer)} label="min to start" hideValue />
            </div>
          </div>
        </SAnimate>
        <SAnimate variant={SAnimateVariantsEnum.ZoomIn} in={values.globalFinishTimer === 'custom'} className={styles.gameOption}>
          <div className={styles.gameConfigElem}>
            <SOptionsInput
              onChange={handleTimeChange}
              name={`variousGameConfig[${idx}].finishTimer`}
              options={GameFinishTimerOptions}
              value={secToMin(gameConfig.finishTimer) ?? 0}
              error={touched.variousGameConfig && touched.variousGameConfig[idx]?.finishTimer && errors.variousGameConfig && errors.variousGameConfig[idx]?.finishTimer}
              onSelect={onSelect}
              onBlur={onBlur}
              theme={ThemesEnum.Astro}
              lockedOptionContainerSize
              showLabel={false}
              size={SizesEnum.Small}
              isDropdownIconVisible={false}
              maxLength={TIME_MAX_LENGTH}
              useFiltering={false}
              className={styles.timeInput}
              min={0}
              step={1}
              max={45}
              disabled={disabled}
            />
            <div className={styles.timeOptionMask}>
              <TimeOption value={secToMin(gameConfig.finishTimer)} label="min to finish" hideValue />
            </div>
          </div>
        </SAnimate>
      </div>
    </div>
  )
}

GameConfigurationCard.displayName = 'GameConfigurationCard';

GameConfigurationCard.propTypes = {
  gameConfig: PropTypes.shape({
    startScore: PropTypes.number.isRequired,
    sets: PropTypes.number.isRequired,
    legs: PropTypes.number.isRequired,
    scoreType: PropTypes.oneOf(Object.values(ScoreTypes)),
    gameInType: PropTypes.oneOf([X01GameInOutTypeEnum.Double, X01GameInOutTypeEnum.Straight]),
    gameOutType: PropTypes.oneOf([X01GameInOutTypeEnum.Double, X01GameInOutTypeEnum.Straight, X01GameInOutTypeEnum.Master]),
    throwOrderType: PropTypes.oneOf(Object.values(ThrowOrderTypeEnum)),
    bullThrow: PropTypes.oneOf(Object.values(BullThrowEnum)),
    startTimer: PropTypes.number,
    finishTimer: PropTypes.number,
    inactivityTimer: PropTypes.number,
  }),
  from: PropTypes.string,
  to: PropTypes.string,
  onSelect: PropTypes.func,
  onBlur: PropTypes.func,
  onRemoveStageClick: PropTypes.func,
  onToStageClick: PropTypes.func,
  idx: PropTypes.number,
  //values: tournamentPropTypes,
}

export default GameConfigurationCard