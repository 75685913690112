import { createAction } from 'redux-actions';

export const getCountries = createAction('COUNTRIES_GET_REQUEST');
export const getCountriesSuccess = createAction('COUNTRIES_GET_SUCCESS');
export const getCountriesFailure = createAction('COUNTRIES_GET_FAILURE');

export default {
  getCountries,
  getCountriesSuccess,
  getCountriesFailure,
};
