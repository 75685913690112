import { call, put, takeLatest } from 'redux-saga/effects';

import { Groups } from 'services/api';
import { callApi } from 'services/rest';

import {
  getChatRooms,
  getChatRoomsFailure,
  getChatRoomsSuccess,
} from 'scenes/Chat/components/List/actions'

function* watchGetChatRooms() {
  try {
    const { data } = yield call(callApi, Groups.index);
    yield put(getChatRoomsSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(getChatRoomsFailure());
  }
}

// It must be wrapped in a function, so execution will happen only on function call
export default () => takeLatest(getChatRooms, watchGetChatRooms);
