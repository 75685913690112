import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { List } from './components/List'

const Rankings = ({ match: { url } }) => {
  return (
    <Switch>
      <Route component={List} exact name="Ranking List" path={`${url}/`} />
    </Switch>
  )
}

export default Rankings
