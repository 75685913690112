import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import cn from 'classnames';

import { RefreshTimer } from 'components/RefreshTimer';

import { getDashboard } from './actions';
import { Users } from './components/Users';
import { Boards } from './components/Boards';
import { Throws } from './components/Throws';
import { Games } from './components/Games';
import { X01Stats } from './components/X01Stats';
import { ATCStats } from './components/ATCStats';
import { Bobs27Stats } from './components/Bobs27Stats';
import { CricketStats } from './components/CricketStats';
import { ShanghaiStats } from './components/ShanghaiStats';
//import { RandomCricketStats } from './components/RandomCricketStats';
import { SummaryCards } from './components/SummaryCards';
import styles from './styles.module.scss';

class Dashboard extends Component {
  componentDidMount() {
    this.getDashboard();
  }

  getDashboard = () => this.props.dispatch(getDashboard());

  render() {
    const { isLoading, users, boards, boardStatistics } = this.props;

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs={12}>
            <div className={cn('text-muted text-end', styles.cardHeader)}>
              <RefreshTimer action={this.getDashboard} isOnByDefault={false} disabled={isLoading} />
            </div>
          </Col>
        </Row>
        <SummaryCards
          users={users}
          boards={boards}
          boardStatistics={boardStatistics}
          isLoading={isLoading}
        />
        <Row>
          <Col xs="12" lg={4} xl={3}>
            <Users users={users} isLoading={isLoading} />
          </Col>
          <Col xs="12" lg={8} xl={6}>
            <Boards boards={boards} />
          </Col>
          <Col xs="12" lg={4} xl={3}>
            <Throws
              outcastThrowsCount={boardStatistics.general.outcastThrowsCount}
              throws={boardStatistics.general.throwsCount}
              isLoading={isLoading}
            />
          </Col>
          <Col xs="12" lg={8} xl={6}>
            <Games boardStatistics={boardStatistics} />
          </Col>
          <Col xs="12" lg={8} xl={6}>
            <X01Stats stats={boardStatistics.x01} />
          </Col>
          <Col xs="12" lg={8} xl={6}>
            <ATCStats
              stats={boardStatistics.aroundTheClock}
              isLoading={isLoading}
            />
          </Col>
          <Col xs="12" lg={8} xl={6}>
            <Bobs27Stats stats={boardStatistics.bobs27} isLoading={isLoading} />
          </Col>
          <Col xs="12" lg={8} xl={6}>
            <CricketStats stats={boardStatistics.cricket} isLoading={isLoading} />
          </Col>
          <Col xs="12" lg={8} xl={6}>
            <ShanghaiStats stats={boardStatistics.shanghai} isLoading={isLoading} />
          </Col>
          {/* <Col xs="12" lg={8} xl={6}>
            <RandomCricketStats stats={boardStatistics.randomCricket} isLoading={isLoading} />
          </Col> */}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, _ownProps) => ({
  users: state.data.dashboard.users,
  boards: state.data.dashboard.boards,
  boardStatistics: state.data.dashboard.boardStatistics,
  isLoading: state.scenes.dashboard.dashboard.isLoading,
});

export default connect(mapStateToProps)(Dashboard);
