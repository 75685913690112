import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
  Label,
  Tooltip,
} from 'recharts';
import { Card, CardBody, Col, Row } from 'reactstrap';
import cn from 'classnames';

import { formatNumberWithThousandSeparators } from 'utils';
import { tooltipFormatter } from 'utils/tooltipFormatter';

import styles from './styles.module.scss';
import { isEmpty } from 'lodash';

const LABELS = {
  inStock: 'In Stock',
  readyToActivate: 'Ready To Activate',
  attached: 'Attached',
  detached: 'Detached',
  suspended: 'Suspended',
  private: 'Private',
  public: 'Public',
};

class Boards extends Component {
  constructor(props) {
    super(props);

    this.state = { isAnimationActive: true };
  }

  componentDidMount() {
    setTimeout(() => this.setState({ isAnimationActive: false }), 1900);
  }

  get boardsDataByVisibility() {
    if (!this.props.boards || isEmpty(this.props.boards)) {
      return [];
    }
    const { boards } = this.props;

    return [
      { key: 'public', value: boards.public },
      { key: 'private', value: boards.private },
    ];
  }

  get boardsDataByStatus() {
    if (!this.props.boards || isEmpty(this.props.boards)) {
      return [];
    }

    let data = [];

    for (let [key, value] of Object.entries(
      this.props.boards.groupedByStatus
    )) {
      data.push({ name: LABELS[key], value });
    }

    return data;
  }

  shouldShowPercentage = key =>
    ['public', 'private'].some(visibility => visibility === key);

  getCalloutValue = (key, value) => {
    const {
      total,
      public: publicBoards,
      private: privateBoards,
    } = this.props.boards;

    let percentage;

    if (key === 'public') {
      percentage = ((publicBoards / total) * 100).toFixed(0);
    } else if (key === 'private') {
      percentage = ((privateBoards / total) * 100).toFixed(0);
    }

    return (
      <div className={styles.calloutValueContainer}>
        <span className={styles.calloutValue}>
          {formatNumberWithThousandSeparators(value, ' ')}
        </span>
        {this.shouldShowPercentage(key) && (
          <span className={styles.calloutValuePercentage}>
            {percentage + '%'}
          </span>
        )}
      </div>
    );
  };

  get renderChart() {
    return (
      <ResponsiveContainer width="100%" height={160}>
        <PieChart>
          <Pie
            data={this.boardsDataByStatus}
            dataKey="value"
            innerRadius={40}
            outerRadius={60}
            isAnimationActive={this.state.isAnimationActive}
            fill="#111"
            labelLine={false}
            label={({
                      cx,
                      cy,
                      midAngle,
                      outerRadius,
                      value,
                    }) => {
              const RADIAN = Math.PI / 180;

              let characterHeightCompensate = 0;

              if (midAngle > 180 && midAngle < 330) {
                characterHeightCompensate = 16;
              }

              const radius = outerRadius + characterHeightCompensate + 5;
              const x = cx + radius * Math.cos(-midAngle * RADIAN);
              const y = cy + radius * Math.sin(-midAngle * RADIAN);

              return (
                <text
                  x={x}
                  y={y}
                  fill="#222"
                  fontSize="16px"
                  textAnchor={x > cx ? 'start' : 'end'}
                >
                  {value}
                </text>
              );
            }}
          >
            <Label position="center" />
            <Cell fill="#676766" />
            <Cell fill="#A5A8AA" />
            <Cell fill="#20A8D9" />
            <Cell fill="#96d7ef" />
            <Cell fill="#eaf7fc" />
          </Pie>
          <Tooltip formatter={(value) => tooltipFormatter(value, this.boardsDataByStatus)} />
          <Legend align="right" verticalAlign="middle" layout="vertical" />
        </PieChart>
      </ResponsiveContainer>
    );
  }

  get callouts() {
    return this.boardsDataByVisibility.map(({ key, value }) => (
      <Col xs={12} sm={6} md={12} key={'boards-callout_' + key}>
        <div className={cn('callout ')}>
          {this.getCalloutValue(key, value)}
          <small className="text-muted text-uppercase fw-bold">
            {LABELS[key]}
          </small>
        </div>
      </Col>
    ));
  }

  render() {
    const {
      boards: { total },
    } = this.props;

    return (
      <Card color="white">
        <CardBody>
          <Fragment>
            <Row>
              <Col xs={8}>
                <strong className={cn('text-value h2', styles.textValue)}>
                  {total}
                </strong>
                <span className="text-muted text-uppercase fw-bold">
                  Total Boards
                </span>
              </Col>
              <Col xs={4}>
                <div className={cn('text-muted text-end', styles.cardHeader)}>
                  <i className="cui-cart" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <Row>{this.callouts}</Row>
              </Col>
              <Col xs={12} md={8}>
                {this.renderChart}
              </Col>
            </Row>
          </Fragment>
        </CardBody>
      </Card>
    );
  }
}

Boards.propTypes = {
  boards: PropTypes.object.isRequired,
};

export default Boards;
