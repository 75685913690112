import { isEmpty } from "lodash";
import { DateTime } from "luxon";

import { deepClone } from "utils";
import { getAvailableTournamentStages } from 'utils/tournamentUtils';
import { TournamentWizardStageConfig } from "typeDefs";

export function parseTime(timeString: string): { hours: number; minutes: number } {
  const parts = timeString.split(':');
  const hours = parseInt(parts[0], 10);
  const minutes = parseInt(parts[1], 10);

  return { hours, minutes };
}

export function validateTime(valueToValidate: string) {
  const timeRegex = /^(2[0-3]|[01]?[0-9]):[0-5][0-9]$/;

  const isValidFormat = timeRegex.test(valueToValidate)

  if ( !isValidFormat ) {
    return false
  }
  
  const { hours, minutes } = parseTime(valueToValidate)
  const isHoursValid = 0 <= hours && hours <= 23
  const isMinutesValid = 0 <= minutes && minutes <= 59

  return isHoursValid && isMinutesValid
}

export function formatDate(dateString: string): string {
  const date = DateTime.fromISO(dateString);
  return date.toFormat('yyyy-MM-dd');
}

export type TimeOption = {
  label: string;
  value: string;
};

export function generateHalfHourTimes(): TimeOption[] {
  const times: TimeOption[] = [];
  for (let hour = 0; hour < 24; hour++) {
    const formattedHour = hour.toString().padStart(2, '0');
    
    times.push({ label: `${formattedHour}:00`, value: `${formattedHour}:00` });
    times.push({ label: `${formattedHour}:30`, value: `${formattedHour}:30` });
  }
  return times;
}

export function filterStagesByMaxParticipants(maxParticipants: number, variousGameConfig: TournamentWizardStageConfig[], maxParticipantValue: number) {
  const availableStages = getAvailableTournamentStages(maxParticipants);
  const newGameConfig = deepClone(variousGameConfig);

  let isStageChanged = false;

  if(maxParticipants > maxParticipantValue) {
    const firstStage = newGameConfig[0].stages[0];
    const indexToSplit = availableStages.indexOf(firstStage);
    const stagesToInsert = availableStages.slice(0, indexToSplit);

    newGameConfig[0].stages = [...stagesToInsert, ...newGameConfig[0].stages];
  }

  if(maxParticipants < maxParticipantValue) {
    const newConfigs = newGameConfig.filter((config: any) => {
      const filteredStages = config.stages.filter((stage: any) => availableStages.includes(stage));
      if (isEmpty(filteredStages)) {
          isStageChanged = true;
          return false;
      }

      config.stages = filteredStages;
      return true;
    });

    newGameConfig.splice(0, newGameConfig.length, ...newConfigs);
  }

  return {
    newGameConfig,
    isStageChanged
  }
}

export function isTimeBeforeNow(time: string): boolean {
  return new Date(time).getTime() < new Date().getTime();
}