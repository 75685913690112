import React, { useMemo } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { Badge, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { isEmpty, isUndefined } from 'lodash';
import { TournamentRegistrationType, TournamentState, TournamentOrganiserTypeEnum, TournamentConfigType } from 'enums';
import { NOT_DELETABLE_STATES, AVG_OPTIONS, TournamentStageLabels } from 'constants/Tournament';
import PrizePool from '../PrizePool/PrizePool';
import formatDate from 'utils/formatDate';
import { getConfiguration } from 'utils/x01ConfigFormatter';
import { getClientUrl } from 'services/configuration/selectors';
import { getIsAdmin } from 'services/session/selectors';
import styles from './styles.module.scss';
export function MainData({ tournament, isEditable, onDelete, onKill, onRefresh, }) {
    const formTimers = (timer) => {
        if (timer)
            return `${Math.floor(timer / 60)}:${timer % 60 < 10 ? `0${Math.floor(timer % 60)}` : Math.floor(timer % 60)}`;
        return '-';
    };
    const clientUrl = useSelector(getClientUrl);
    const isAdmin = useSelector(getIsAdmin);
    const getRecommendedAverage = (min, max) => {
        if (isUndefined(min) || isUndefined(max)) {
            return '-';
        }
        const element = AVG_OPTIONS.find(a => a.minValue === min && a.maxValue === max);
        if (isUndefined(element)) {
            return 'Not recognized values.';
        }
        return element.label;
    };
    const isActualPrizePoolVisible = useMemo(() => {
        if (isUndefined(tournament.actualPrizing) || isUndefined(tournament.initialPrizing)) {
            return false;
        }
        return tournament.actualPrizing.maxPrizePool !== tournament.initialPrizing.maxPrizePool;
    }, [tournament.actualPrizing, tournament.initialPrizing]);
    const isRegistrationClosed = useMemo(() => {
        switch (tournament.state) {
            case TournamentState.Announced:
            case TournamentState.RegistrationOpen:
            case TournamentState.RegistrationFull:
                return false;
            default:
                return true;
        }
    }, [tournament.state]);
    const isTournamentActive = useMemo(() => {
        return (tournament.state !== TournamentState.Cancelled &&
            tournament.state !== TournamentState.Finished &&
            tournament.state !== TournamentState.Deleted);
    }, [tournament.state]);
    const isActualPrizePoolVisibleInPrizePoolTable = useMemo(() => {
        return !isActualPrizePoolVisible && isRegistrationClosed;
    }, [isActualPrizePoolVisible, isRegistrationClosed]);
    const isPrivateTournament = tournament?.organiserType !== TournamentOrganiserTypeEnum.Scolia;
    const isInvitational = tournament?.registrationType === TournamentRegistrationType.Invitational;
    const privateTournamentAcceptedParticipantsNumber = useMemo(() => {
        if (isUndefined(tournament?.participants) || isEmpty(Object.values(tournament?.participants))) {
            return 0;
        }
        return Object.values(tournament?.participants).reduce((acc, participant) => {
            if (participant.status === 'Accepted')
                acc += 1;
            return acc;
        }, 0);
    }, [tournament?.participants]);
    if (isUndefined(tournament)) {
        return 'No tournament data available';
    }
    const { _id: tournamentId, state, name, currentTokenCost, minParticipants, maxParticipants, numberOfParticipants, registrationStartDate, registrationEndDate, tournamentStartDate, tournamentEndDate, configType, gameConfig, organiser, structure, registrationType, organiserType, entryFee, constraints, initialPrizing, penalty, actualPrizing, isScoreCorrectionAllowed } = tournament ?? {};
    if (isUndefined(state)) {
        return 'This tournament has no state';
    }
    return (React.createElement(Card, { color: "white" },
        React.createElement(CardHeader, null,
            React.createElement("div", { className: "card-header__left" },
                React.createElement("i", { className: "fa fa-align-justify" }),
                " Tournament data"),
            React.createElement("div", { className: "card-header__right" },
                isAdmin && tournament?.organiserType === 'User' && isTournamentActive && (React.createElement("button", { className: "btn btn-danger btn-sm ms-1", type: "button", onClick: onKill }, "Kill tournament")),
                state !== 'Cancelled' && (React.createElement(Link, { to: { pathname: `${clientUrl}/tournaments/${tournamentId}` }, target: "_blank" },
                    React.createElement("button", { className: "btn btn-warning btn-sm ms-1", type: "button" }, "Go to tournament"))),
                isEditable && isAdmin && (React.createElement(React.Fragment, null,
                    React.createElement(Link, { to: isInvitational ? `/tournaments/createInvitational/${tournamentId}` : `/tournaments/create/${tournamentId}` },
                        React.createElement("button", { className: "btn btn-success btn-sm ms-1", type: "button" }, "Use as preset")),
                    !NOT_DELETABLE_STATES.includes(state) && (React.createElement(Link, { to: isInvitational ? `/tournaments/editInvitational/${tournamentId}` : `/tournaments/edit/${tournamentId}` },
                        React.createElement("button", { className: "btn btn-primary btn-sm ms-1", type: "button" }, "Edit Tournament"))),
                    !NOT_DELETABLE_STATES.includes(state) && (React.createElement("button", { className: "btn btn-danger btn-sm ms-1", type: "button", onClick: onDelete }, "Delete Tournament")))),
                React.createElement("button", { className: "btn btn-warning btn-sm ms-1", type: "button", onClick: onRefresh },
                    React.createElement("i", { className: "cui-reload" })))),
        React.createElement(CardBody, null,
            React.createElement(Col, null,
                React.createElement("div", { className: styles.container },
                    React.createElement("div", { className: styles.label }, "TOURNAMENT STATE"),
                    React.createElement("div", { className: styles.wrapper },
                        React.createElement(Row, null,
                            React.createElement("dt", { className: "col-sm-4" }, "Name:"),
                            React.createElement("dd", { className: "col-sm-8" }, name)),
                        React.createElement(Row, null,
                            React.createElement("dt", { className: "col-sm-4" }, "State:"),
                            React.createElement("dd", { className: "col-sm-8" }, state)),
                        !isUndefined(currentTokenCost) && (React.createElement(Row, null,
                            React.createElement("dt", { className: "col-sm-4" }, "Current token cost:"),
                            React.createElement("dd", { className: "col-sm-8" }, currentTokenCost))),
                        React.createElement(Row, null,
                            React.createElement("dt", { className: "col-sm-4" }, "Min participants:"),
                            React.createElement("dd", { className: "col-sm-8" }, minParticipants)),
                        React.createElement(Row, null,
                            React.createElement("dt", { className: "col-sm-4" }, "Max participants:"),
                            React.createElement("dd", { className: "col-sm-8" }, maxParticipants)),
                        React.createElement(Row, null,
                            React.createElement("dt", { className: "col-sm-4" }, isPrivateTournament ? "Current number of invited participants" : "Current participants number:"),
                            React.createElement("dd", { className: "col-sm-8" }, numberOfParticipants ?? 0)),
                        isPrivateTournament && React.createElement(Row, null,
                            React.createElement("dt", { className: "col-sm-4" }, "Current number of accepted participants:"),
                            React.createElement("dd", { className: "col-sm-8" }, privateTournamentAcceptedParticipantsNumber)))),
                React.createElement("div", { className: styles.container },
                    React.createElement("div", { className: styles.label }, "DATES"),
                    React.createElement("div", { className: styles.wrapper },
                        React.createElement(Row, null,
                            React.createElement("dt", { className: "col-sm-4" }, "Registration start date:"),
                            React.createElement("dd", { className: "col-sm-8" }, formatDate(registrationStartDate, true))),
                        React.createElement(Row, null,
                            React.createElement("dt", { className: "col-sm-4" }, "Registration end date:"),
                            React.createElement("dd", { className: "col-sm-8" }, formatDate(registrationEndDate, true))),
                        React.createElement(Row, null,
                            React.createElement("dt", { className: "col-sm-4" }, "Tournament start date:"),
                            React.createElement("dd", { className: "col-sm-8" }, formatDate(tournamentStartDate, true))),
                        React.createElement(Row, null,
                            React.createElement("dt", { className: "col-sm-4" }, "Tournament end date:"),
                            React.createElement("dd", { className: "col-sm-8" }, formatDate(tournamentEndDate, true))))),
                React.createElement("div", { className: styles.container },
                    React.createElement("div", { className: styles.label }, "GAME SETUP"),
                    React.createElement("div", { className: styles.wrapper },
                        React.createElement(Row, null,
                            React.createElement("dt", { className: "col-sm-4" }, "Game configuration:"),
                            React.createElement("dd", { className: "col-sm-8", style: { display: 'flex', gap: '8px' } },
                                React.createElement(Badge, { color: cn({
                                        warning: configType === TournamentConfigType.Various,
                                        secondary: configType === TournamentConfigType.Fixed,
                                    }) }, configType),
                                (configType === TournamentConfigType.Fixed && !isUndefined(gameConfig)) ?
                                    getConfiguration(gameConfig, true) : null)),
                        tournament?.configType === 'Various' &&
                            Object.entries(tournament?.variousGameConfig).map(([key, value]) => (React.createElement(Row, { key: key },
                                React.createElement("dd", { className: "col-sm-2" }),
                                React.createElement("dt", { className: "col-sm-2", style: { whiteSpace: 'nowrap' } }, TournamentStageLabels[key]),
                                React.createElement("dd", { className: "col-sm-8" }, getConfiguration(value, true))))),
                        React.createElement(Row, null,
                            React.createElement("dt", { className: "col-sm-4" }, "Participation Type:"),
                            React.createElement("dd", { className: "col-sm-8" }, tournament?.participationType)),
                        React.createElement(Row, null,
                            React.createElement("dt", { className: "col-sm-4" }, "Score Correction Allowed:"),
                            React.createElement("dd", { className: "col-sm-8" },
                                React.createElement(Badge, { color: cn({
                                        warning: isScoreCorrectionAllowed === true,
                                        secondary: isScoreCorrectionAllowed === false,
                                    }) }, isScoreCorrectionAllowed ? 'Yes' : 'No'))))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: "12", md: "6" },
                        React.createElement("br", null),
                        React.createElement("div", { className: styles.container },
                            React.createElement("div", { className: styles.label }, "CONFIGURATION"),
                            React.createElement("div", { className: styles.wrapper },
                                React.createElement(Row, null,
                                    React.createElement("dt", { className: "col-sm-8" }, "Organiser name:"),
                                    React.createElement("dd", { className: "col-sm-4" },
                                        React.createElement(Link, { to: `/users/${organiser?.organiserId}` }, organiser?.name))),
                                React.createElement(Row, null,
                                    React.createElement("dt", { className: "col-sm-8" }, "Structure:"),
                                    React.createElement("dd", { className: "col-sm-4" }, structure)),
                                React.createElement(Row, null,
                                    React.createElement("dt", { className: "col-sm-8" }, "Registration Type:"),
                                    React.createElement("dd", { className: "col-sm-4" }, registrationType)),
                                React.createElement(Row, null,
                                    React.createElement("dt", { className: "col-sm-8" }, "Organiser Type:"),
                                    React.createElement("dd", { className: "col-sm-4" }, organiserType)),
                                React.createElement(Row, null,
                                    React.createElement("dt", { className: "col-sm-8" }, "Entry fee:"),
                                    React.createElement("dd", { className: "col-sm-4" }, entryFee)),
                                React.createElement(Row, null,
                                    React.createElement("dt", { className: "col-sm-8" }, "Recommended average:"),
                                    React.createElement("dd", { className: "col-sm-4" }, getRecommendedAverage(constraints?.recommended?.averageRange?.min, constraints?.recommended?.averageRange?.max))),
                                React.createElement("br", null),
                                tournament?.configType === TournamentConfigType.Fixed && (React.createElement(React.Fragment, null,
                                    React.createElement(Row, null,
                                        React.createElement("dt", { className: "col-sm-8" }, "Start timer:"),
                                        React.createElement("dd", { className: "col-sm-4" }, !isUndefined(gameConfig?.startTimer)
                                            ? `${formTimers(gameConfig?.startTimer)} (${gameConfig?.startTimer}s)`
                                            : null)),
                                    React.createElement(Row, null,
                                        React.createElement("dt", { className: "col-sm-8" }, "Finish timer:"),
                                        React.createElement("dd", { className: "col-sm-4" }, !isUndefined(gameConfig?.finishTimer)
                                            ? `${formTimers(gameConfig?.finishTimer)} (${gameConfig?.finishTimer}s)`
                                            : null)),
                                    React.createElement(Row, null,
                                        React.createElement("dt", { className: "col-sm-8" }, "Inactivity timer:"),
                                        React.createElement("dd", { className: "col-sm-4" }, !isUndefined(gameConfig?.inactivityTimer)
                                            ? `${formTimers(gameConfig?.inactivityTimer)} (${gameConfig?.inactivityTimer}s)`
                                            : null))))))),
                    React.createElement(Col, { xs: "12", md: "6" },
                        initialPrizing && (React.createElement(PrizePool, { pool: initialPrizing.stagePrizes, maxPrizePool: initialPrizing.maxPrizePool, achievablePrizePool: isActualPrizePoolVisibleInPrizePoolTable
                                ? actualPrizing.achievablePrizePool
                                : undefined, penalty: isActualPrizePoolVisible ? undefined : penalty })),
                        isActualPrizePoolVisible && (React.createElement(PrizePool, { achievablePrizePool: actualPrizing.achievablePrizePool, label: "Actual prize pool", pool: actualPrizing.stagePrizes, maxPrizePool: actualPrizing.maxPrizePool, penalty: penalty })))),
                tournament?.configType === 'Various' &&
                    Object.entries(tournament?.variousGameConfig).map(([key, value]) => (React.createElement("table", { style: { margin: '12px 0 12px 24px', width: 'calc(100% - 24px)' }, key: key },
                        React.createElement("thead", { style: { fontWeight: 'bold' } },
                            React.createElement("tr", null,
                                React.createElement("th", { colSpan: 3 }, TournamentStageLabels[key]))),
                        React.createElement("tbody", null,
                            React.createElement("tr", null,
                                React.createElement("td", null, "Start timer"),
                                React.createElement("td", null, "Finish timer"),
                                React.createElement("td", null, "Inactivity timer")),
                            React.createElement("tr", { style: { whiteSpace: 'nowrap' } },
                                React.createElement("td", null, `${formTimers(value?.startTimer)} (${value?.startTimer}s)`),
                                React.createElement("td", null, `${formTimers(value?.finishTimer)} (${value?.finishTimer}s)`),
                                React.createElement("td", null, !isUndefined(value.inactivityTimer) ? `${formTimers(value?.inactivityTimer)} (${value?.inactivityTimer}s)` : '-'))))))))));
}
