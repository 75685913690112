import React from 'react';
import { Badge } from 'reactstrap';
import cn from 'classnames';

import formatLabel from 'utils/formatLabel';

const Default = ({ label, value }) => {
  return (
    <div className="row">
      <div className="col-sm-4">{formatLabel(label)}</div>
      <div className="col-sm-8">
        <Badge
          color={cn({
            success: value,
            warning: !value,
          })}
        >
          {value ? 'Yes' : 'No'}
        </Badge>
      </div>
    </div>
  )
}

export default Default