import { call, put, takeLatest } from 'redux-saga/effects';

import { Boards } from 'services/api';

import { browserHistory } from 'services/history';
import { callApi } from 'services/rest';

import { notifyError, notifySuccess } from 'services/notification/actions';

import {
  createBoard,
  createBoardFailure,
  createBoardSuccess,
} from 'scenes/Boards/components/Create/actions';

function* watchCreateBoard({ payload: { board } }) {
  try {
    yield call(callApi, Boards.create, board);
    yield put(createBoardSuccess());
    yield put(notifySuccess(`Board was created successfully.`));
    browserHistory.push('/boards');
  } catch (e) {
    if (!e.isFetch) {
      throw e;
    }
    yield put(createBoardFailure(e.data.error));
    yield put(notifyError('Invalid serial number format'));
  }
}

export default () => takeLatest(createBoard, watchCreateBoard);
