import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
//import { Link } from 'react-router-dom';
import Select from 'react-select';

import { Form } from '../Form';
import { Preview } from '../Preview';

import BellLogo from 'assets/images/material-notification-icon.svg';
import AddNew from 'assets/images/material-add-new.svg';

import { getIsAdmin } from 'services/session/selectors';

import actions from 'scenes/Notifications/actions';

import styles from './styles.module.scss';

const globalLabel = 'Global notifications';
const userLabel = 'User notifications';

class Edit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedNotification: null,
      globalOptions: [],
      selectedOption: null,
      existedNotifications: [],
      changedVisibilities: [],
      isCreate: false,
    };
  }

  componentDidMount = () => this.props.dispatch(actions.getNotifications());

  componentDidUpdate = prevProps => {
    const { isLoading, notifications } = this.props;

    // we only calculate optionsArray if we need it later but we do this here so we don't need to do it later twice
    const optionsArray = [];
    if (
      prevProps.notifications !== notifications ||
      (prevProps.isLoading !== isLoading && !isLoading && notifications.length > 0)
    )
      notifications.map(notification =>
        !('userNotificationSubject' in notification)
          ? optionsArray.push({
              value: notification._id,
              label: notification.title,
            })
          : ''
      );

    // we filter the global notifications for the edit form selector, and handle the filtering
    if (prevProps.notifications !== notifications) {
      this.setState({
        globalOptions: optionsArray,
        existedNotifications: notifications,
      });
    }

    //we set the selected notification on first fetch
    if (prevProps.isLoading !== isLoading && !isLoading && notifications.length > 0) {
      let selected; //select active global
      
      if( prevProps.notifications?.length < notifications?.length )
        selected = notifications[notifications?.length - 1]
      if (!selected)
        selected = notifications.find(
          notification => !('userNotificationSubject' in notification) && notification.isActive
        );
      if (!selected)
        //or select global
        selected = notifications.find(notification => !('userNotificationSubject' in notification));
      if (!selected && notifications.length > 0)
        //or select first one
        selected = notifications[0];

      this.setState({
        selectedNotification: selected ? selected?._id : null,
        selectedOption: optionsArray.find(option => option.value === selected?._id),
        isCreate: optionsArray.length === 0,
      });
    }
  };

  handleSubmit = values => {
    const { selectedNotification } = this.state;
    this.props.dispatch(actions.updateNotification(selectedNotification, values));
  };

  handleDelete = () => {
    const result = window.confirm('Are you sure you want to delete this notification?');
    if (result) this.props.dispatch(actions.deleteNotification(this.state.selectedNotification));
  };

  handleActivityChange = (id, prevValue, isUserNotification = false) => {
    const { existedNotifications } = this.state;
    const { notifications } = this.props;

    const tmpNotifications = [];
    const changedVisibilities = [];

    // copy the current notifications
    existedNotifications.forEach((n, idx) => {
      tmpNotifications[idx] = { ...n };
    });

    // find the one that changes and apply the change
    // there can be only one active global notification
    // the user ones get disabled if there is any active global not., but keep their truth value
    tmpNotifications.forEach((notification, idx) => {
      if (notification._id === id) {
        tmpNotifications[idx].isActive = !tmpNotifications[idx].isActive;
      } else if (!prevValue && !('userNotificationSubject' in notification) && !isUserNotification) {
        tmpNotifications[idx].isActive = false;
      }
    });

    // calculate the new changes for all the visibility
    tmpNotifications.forEach((n, idx) => {
      if (notifications[idx]?.isActive !== n?.isActive)
        changedVisibilities.push({
          id: n._id,
          isActive: n?.isActive,
        });
    });

    // apply the changes
    this.setState({
      existedNotifications: tmpNotifications,
      changedVisibilities: changedVisibilities,
    });
  };

  handleAddNewNotification = _e => {
    this.setState({
      isCreate: true,
      selectedNotification: null,
      selectedOption: null,
    });
  };

  handleSelectNotification = notification => {
    this.setState({
      selectedNotification: notification.value,
      selectedOption: this.state.globalOptions.find(option => option.value === notification.value),
      isCreate: false,
    });
  };

  // payload is an object with visibility array -> changed objects of ( id, isActive ) keys
  handleSubmitVisibility = _e => {
    const { changedVisibilities } = this.state;

    this.props.dispatch(
      actions.updateVisibilityRequest({
        visibilities: changedVisibilities,
      })
    );

    this.setState({
      changedVisibilities: [],
    });
  };

  handleFormSubmit = (values, _actions) => this.props.dispatch(actions.createNotification(values));

  get globalNotifications() {
    const { existedNotifications } = this.state;
    const { isAdmin } = this.props;

    return (
      <div className={styles.visibilityTypeContainer}>
        <div className={styles.visibilityLabel}>{globalLabel.toUpperCase()}</div>
       <div className={styles.visibilityToastsContainer}>
          {existedNotifications.map(
            notification =>
              !('userNotificationSubject' in notification) && (
                <Preview
                  type={notification.type}
                  title={notification.title}
                  isIconHidden
                  isControllable
                  disabled={!isAdmin}
                  name={notification.title}
                  onChange={() =>
                    this.handleActivityChange(notification._id, notification.isActive)
                  }
                  value={notification.isActive}
                  key={notification._id}
                />
              )
          )}
        </div>
      </div>
    );
  }

  get userNotifications() {
    const { existedNotifications } = this.state;
    const { isAdmin } = this.props;

    return (
       <div className={styles.visibilityTypeContainer}>
        <div className={styles.visibilityLabel}>{userLabel.toUpperCase()}</div>
        <div className={styles.visibilityToastsContainer}>
          {existedNotifications.map(
            notification =>
              'userNotificationSubject' in notification && (
                <Preview
                  type={notification.type}
                  title={notification.title}
                  isIconHidden
                  isControllable
                  disabled={!isAdmin}
                  name={notification.title}
                  onChange={() => this.handleActivityChange(notification._id, notification.isActive, true)}
                  isKeepToggleBright
                  value={notification.isActive}
                  key={notification._id}
                />
              )
          )}
        </div>
      </div>
    );
  }

  render() {
    const { isLoading, notifications, isAdmin } = this.props;
    const { selectedNotification, globalOptions, changedVisibilities, isCreate } = this.state;

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" lg={{ size: 10, offset: 1 }}>
            <Card color="white">
              <CardHeader>
                <div className="card-header__left">
                  <i className="fa fa-align-justify" /> Global Notifications
                  {isLoading && <i className="fa fa-spinner fa-spin fa-fw" />}
                </div>
                <div className="card-header__right">
                  <Row style={{ float: 'right', width: 'fit-content', marginRight: '0px' }}>
                    <div className={styles.selectorContainer}>
                      <Select
                        name="globalNotificationsSelect"
                        value={this.state.selectedOption}
                        options={globalOptions}
                        onChange={notification => this.handleSelectNotification(notification)}
                      />
                    </div>
                    {isAdmin && <button
                      className="btn  btn-md ms-1"
                      disabled={isLoading || !isAdmin}
                      onClick={() => this.handleAddNewNotification()}
                    >
                      <img src={AddNew} alt="add" />
                    </button>}
                    {isAdmin && <button
                      className="btn  btn-md ms-1"
                      disabled={isLoading || !isAdmin}
                      onClick={() => this.props.dispatch(actions.getNotifications())}
                    >
                      <i className="cui-reload" />
                    </button>}
                  </Row>
                </div>
              </CardHeader>
              <CardBody>
                {isLoading ? (
                  <i className="fa fa-spinner fa-spin fa-fw" />
                ) : notifications.length > 0 ? (
                  <>
                    {isCreate ? (
                      <Form onSubmit={this.handleFormSubmit} isAdmin={isAdmin} />
                    ) : (
                      <Form
                        onSubmit={this.handleSubmit}
                        notification={notifications.find(n => n._id === selectedNotification)}
                        onDelete={this.handleDelete}
                        isEdit
                        isAdmin={isAdmin}
                      />
                    )}
                  </>
                ) : (
                  <div>There are no notifications.</div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col xs="12" lg={{ size: 5, offset: 1 }}>
            <Card color="white">
              <div className={styles.customCardHeader}>
                <img src={BellLogo} alt="Bell Logo" /> Notifications Visibility
              </div>
              <div className={styles.customCardBody}>
                {this.globalNotifications}
                {this.userNotifications}
              </div>
             { isAdmin && <div className={styles.customCardFooter}>
                <button
                  className="btn btn-success btn-md"
                  type="submit"
                  onClick={this.handleSubmitVisibility}
                  disabled={changedVisibilities.length === 0 || !isAdmin}
                >
                  Save Visibility
                </button>
              </div>}
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

Edit.displayName = 'Notifications.Edit';

const mapStateToProps = state => {
  return {
    notifications: state.data.notifications.notifications,
    isLoading: state.scenes.notifications.isLoading,
    isAdmin: getIsAdmin(state),
  };
};

export default connect(mapStateToProps)(Edit);
