import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { startOfDayOf, endOfDayOf } from 'utils';

import { DateRangeFilter } from 'components/DateRangeFilter';

import actions from './actions';
import { actions as notificationActions } from 'services/notification';

import styles from './styles.module.scss';

export const List = ({ isLoading, chatRooms, dispatch, isDownloading }) => {
  useEffect(
    () => {
      dispatch(actions.getChatRooms());
    },
    [dispatch]
  );

  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const handleDateChange = useCallback(e => {
    const { startDate, endDate } = e;

    const fromDate = startOfDayOf(startDate);
    const toDate = endOfDayOf(endDate);
    const nowDate = endOfDayOf(new Date());

    const nowDatems = nowDate.getTime();
    const fromDatems = fromDate.getTime();
    const toDatems = toDate.getTime();

    let setStart = startDate
    let setEnd = endDate;

    if (fromDatems > toDatems) {
      dispatch(notificationActions.notifyError("Start date can't be later than end date!"));
      setStart = endDate;
    }
    if (fromDatems > nowDatems) {
      dispatch(notificationActions.notifyError("You can't select future date to start date!"));
      setStart = nowDate;
    }
    if (toDatems > nowDatems) {
      dispatch(notificationActions.notifyError("You can't select future date to end date!"));
      setEnd = nowDate;
    }

    setDateRange({startDate: setStart, endDate: setEnd});
  }, [dispatch]);

  const handleDownload = () => {
    dispatch(actions.download(dateRange));
  };

  const getChatRoomsList = () => {
    if (isLoading) {
      return null;
    }

    return Object.keys(chatRooms).map((roomId, idx) => {
      const room = chatRooms[roomId];
      return (
        <tr key={Object.getOwnPropertyNames(chatRooms)[idx]}>
          <td>
            <Link to={`/chat/${room.id}`}>{room.id}</Link>
          </td>
          <td>{room.participantCount}</td>
        </tr>
      );
    });
  };

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" lg={{ size: 10, offset: 1 }}>
          <Card color="white">
            <CardHeader>
              <div className="card-header__left">
                <i className="fa fa-align-justify" /> Chat Rooms
                {isLoading && <i className="fa fa-spinner fa-spin fa-fw" />}
              </div>
              <div className={cn('card-header__right', styles.cardHeader)}>
                <div className={styles.downloadWrapper}>
                  <DateRangeFilter
                    dateRange={dateRange}
                    onDateRangeChange={handleDateChange}
                    disabled={isLoading}
                  />
                  <button className="btn btn-primary btn-md" type="button" onClick={handleDownload} disabled={isDownloading}>
                    Download Messages
                  </button>
                </div>
              </div>
            </CardHeader>

            <CardBody>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Participants</th>
                  </tr>
                </thead>
                <tbody>{getChatRoomsList()}</tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = state => ({
  chatRooms: state.data.chat,
  isLoading: state.scenes.chat.list.isLoading,
  isDownloading: state.scenes.chat.list.isDownloading,
});

export default connect(mapStateToProps)(List);
