import React from 'react';
import { SRadio } from '../SRadio';
import styles from './styles.module.scss';
import { SLabel, SizesEnum } from 'ScoliaComponents';
export const SRadioGroupOption = ({ name, value, checked, label, tooltip, disabled, onSelect }) => {
    const handleSelect = () => {
        onSelect && onSelect(value, name);
    };
    return (React.createElement("button", { type: 'button', name: name, onClick: handleSelect, disabled: disabled, className: styles.option },
        React.createElement("div", { className: styles.optionInputContainer },
            React.createElement(SRadio, { checked: checked, value: value, name: name, size: SizesEnum.Medium, disabled: disabled, onChange: handleSelect })),
        React.createElement("div", { className: styles.optionMetaContainer },
            React.createElement(SLabel, { htmlFor: name, tooltip: tooltip, nowrap: true, className: styles.optionLabel }, label))));
};
