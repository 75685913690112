import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import Badge from 'reactstrap/lib/Badge';
import cn from 'classnames';

import styles from './styles.module.scss';

class AssignedBoards extends PureComponent {
  get boardList() {
    const { isLoading } = this.props;

    const { boards } = this.props;
    if (isLoading || !boards) {
      return null;
    }

    return Object.keys(boards).map((boardId, idx) => {
      if (boardId === 'length') {
        return null;
      }
      const board = boards[boardId];
      return (
        <tr key={Object.getOwnPropertyNames(boards)[idx]}>
          <td>{board.name}</td>
          <td>
            <Link to={`/boards/${board._id}`}>{board.serialNumber}</Link>
          </td>
          <td>{board.totalThrows}</td>
          <td>
            <Badge color={board.state === 'Online' ? 'success' : 'light'}>
              {board.state}
            </Badge>
          </td>
        </tr>
      );
    });
  }

  render() {
    const { isLoading, boards, activeBoardsCount} = this.props;
    return (
      <div className="animated fadeIn">
        <Card color="white">
          <CardHeader>
            <div className="card-header__left">
              <i className="fa fa-align-justify" /> Assigned Boards
              {isLoading && <i className="fa fa-spinner fa-spin fa-fw" />}
            </div>
            <div className={cn(styles.activeBoards, 'card-header__right')}>
              <div className={styles.activeBoardsWrapper}>
                <div className={styles.labelWrapper}>ACTIVE BOARDS</div>
                <span>
                  {boards ?
                    activeBoardsCount
                    : <i className="fa fa-spinner fa-spin fa-fw"/>
                  }
                </span>
              </div>
            </div>
          </CardHeader>

          <CardBody>
            <Table responsive>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Serial Number</th>
                  <th>Total Throws</th>
                  <th>State</th>
                </tr>
              </thead>
              <tbody>{this.boardList}</tbody>
            </Table>
          </CardBody>
        </Card>
      </div>
    );
  }
}

AssignedBoards.propTypes = {
  boards: PropTypes.object.isRequired,
  id: PropTypes.string,
  name: PropTypes.string,
  serialNumber: PropTypes.string,
  totalThrows: PropTypes.string,
  state: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default connect()(AssignedBoards);
