import { useState, useMemo, useEffect } from 'react'

import { NOT_DELETABLE_STATES } from 'constants/Tournament';

import { useIsKeyDown } from 'hooks';
import { isNullOrUndefined } from 'utils';

export const useListSelection = ( collection = [] ) => {
  const [selectedKeys, setSelectedKeys] = useState([]);

  const [prevSelectedIndex, setPrevSelectedIndex] = useState()

  const isShiftDown = useIsKeyDown('Shift')

  const collectionIds = useMemo(() => collection.map(elem => elem._id), [ collection ])

  const selectableElements = useMemo(() => collection.reduce((acc, item) => {
    if (!NOT_DELETABLE_STATES.includes(item.state)) {
      acc.push(item._id);
    }
    return acc;
  }, []), [ collection ])


  const removeOne = (idToRemove, updatedSelectedKeys) => {
    return updatedSelectedKeys.filter(elem => elem !== idToRemove)
  }

  const removeMultiple = (idsToRemove, updatedSelectedKeys) => {
    return updatedSelectedKeys.filter(elem => !idsToRemove.includes(elem))
  }

  const addOne = (idToAdd, updatedSelectedKeys, selectableElements) => {
    if ( selectableElements.includes(idToAdd) && !updatedSelectedKeys.includes(idToAdd) ) {
      return updatedSelectedKeys.concat([idToAdd])
    }
    
    return updatedSelectedKeys
  }

  const addMultiple = (idsToAdd, updatedSelectedKeys, selectableElements) => {
    const newSelectedKeys = [...updatedSelectedKeys]
    idsToAdd.forEach(id => {
      if ( selectableElements.includes(id) && !updatedSelectedKeys.includes(id) ) {
        newSelectedKeys.push(id)
      }
    })

    return newSelectedKeys
  }

  const handleSelectAll = () => {
    setSelectedKeys(selectableElements)
  }

  const handleReset = () => {
    setSelectedKeys([])
  }

  const handleSelect = value => {  
    const isRemoving = selectedKeys.includes(value)
    const newSelectedElemIndex = collectionIds.indexOf(value)
    const isMultiple = isShiftDown && !isNullOrUndefined(prevSelectedIndex)
  
    let updatedSelectedKeys = [...selectedKeys]
   
    if ( isMultiple ) {  
      const [from, to] = prevSelectedIndex > newSelectedElemIndex
        ? [newSelectedElemIndex, prevSelectedIndex]
        : [prevSelectedIndex, newSelectedElemIndex];

      updatedSelectedKeys = isRemoving
        ? removeMultiple(collectionIds.slice(from, to + 1), updatedSelectedKeys)
        : addMultiple(collectionIds.slice(from, to + 1), updatedSelectedKeys, selectableElements)
    } else {
      updatedSelectedKeys = isRemoving
        ? removeOne(value, updatedSelectedKeys)
        : addOne(value, updatedSelectedKeys, selectableElements)
    }

    setPrevSelectedIndex(newSelectedElemIndex)
    setSelectedKeys(updatedSelectedKeys)
    return
  };

  //if the data set changed via filtering, let's delete those selected rows which are not in the new set
  useEffect(() => {
    setSelectedKeys(prevKeys => prevKeys.filter(id => selectableElements.includes(id)))
  }, [ selectableElements, setSelectedKeys ])

  return [selectedKeys, selectableElements, handleSelect, handleSelectAll, handleReset]
}