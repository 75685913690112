import { handleActions } from 'redux-actions';

import { getManufacturer, getManufacturerFailure, getManufacturerSuccess } from 'scenes/Manufacturers/actions';

const INITIAL_STATE = {
  isLoading: true
};

const mainInfo = handleActions(
  {
    [getManufacturer]: (state, { payload: { isLoading } }) => ({
      ...state,
      isLoading
    }),

    [getManufacturerSuccess]: (state) => ({
      ...state,
      isLoading: false
    }),

    [getManufacturerFailure]: (state) => ({
      ...state,
      isLoading: false
    })
  },
  INITIAL_STATE
);

export default mainInfo;
