import { handleActions } from 'redux-actions';

import actions from './actions';

const INITIAL_STATE = {
  isLoading: true,
  manufacturers: []
};

const list = handleActions(
  {
    [actions.getManufacturers]: (state) => ({
      ...state,
      isLoading: true
    }),

    [actions.getManufacturersSuccess]: () => ({
      isLoading: false
    }),

    [actions.getManufacturersFailure]: () => ({
      isLoading: false
    })
  },
  INITIAL_STATE
);

export default list;
