import { createAction } from 'redux-actions';

export const getStoreSnapshot = createAction('STORE_SNAPSHOT_GET_REQUEST');
export const getStoreSnapshotFailure = createAction('STORE_SNAPSHOT_GET_FAILURE');
export const getStoreSnapshotSuccess = createAction('STORE_SNAPSHOT_GET_SUCCESS');

export const deleteWSConnection = createAction('DELETE_WSCONNECTION_REQUEST');
export const deleteWSConnectionFailure = createAction('DELETE_WSCONNECTION_FAILURE');
export const deleteWSConnectionSuccess = createAction('DELETE_WSCONNECTION_SUCCESS');

export const deleteLobbyInvitation = createAction('DELETE_LOBBYINVITATION_REQUEST');
export const deleteLobbyInvitationFailure = createAction('DELETE_LOBBYINVITATION_FAILURE');
export const deleteLobbyInvitationSuccess = createAction('DELETE_LOBBYINVITATION_SUCCESS');

export default {
  getStoreSnapshot,
  getStoreSnapshotFailure,
  getStoreSnapshotSuccess,
  deleteWSConnection,
  deleteWSConnectionFailure,
  deleteWSConnectionSuccess,
  deleteLobbyInvitation,
  deleteLobbyInvitationFailure,
  deleteLobbyInvitationSuccess,
};
