import { createAction } from 'redux-actions';

export const getNotifications = createAction(
  'NOTIFICATIONS_GET_REQUEST',
  payload => ({
    queryParams: payload,
  })
);

export const updateNotification = createAction(
  'UPDATE_NOTIFICATION_REQUEST',
  (notificationId, payload) => ({
    notificationId,
    payload,
  })
);

export const deleteNotification = createAction(
  'DELETE_NOTIFICATION_REQUEST',
  notificationId => ({
    notificationId,
  })
);

export const createNotification = createAction(
  'CREATE_NOTIFICATION_REQUEST',
  (payload) => ({
    payload,
  })
);

export const deleteNotificationFailure = createAction('DELETE_NOTIFICATION_FAILURE');
export const deleteNotificationSuccess = createAction('DELETE_NOTIFICATION_SUCCESS');

export const createNotificationFailure = createAction('CREATE_NOTIFICATION_FAILURE');
export const createNotificationSuccess = createAction('CREATE_NOTIFICATION_SUCCESS');

export const updateNotificationFailure = createAction('UPDATE_NOTIFICATION_FAILURE');
export const updateNotificationSuccess = createAction('UPDATE_NOTIFICATION_SUCCESS');

export const getNotificationsFailure = createAction('NOTIFICATIONS_GET_FAILURE');
export const getNotificationsSuccess = createAction('NOTIFICATIONS_GET_SUCCESS');

export const updateVisibilityRequest = createAction('UPDATE_VISIBILITY_REQUEST')
export const updateVisibilityFailure = createAction('UPDATE_VISIBILITY_FAILURE');
export const updateVisibilitySuccess = createAction('UPDATE_VISIBILITY_SUCCESS');

export default {
  getNotifications,
  updateNotification,
  updateNotificationSuccess,
  updateNotificationFailure,
  getNotificationsFailure,
  getNotificationsSuccess,
  createNotification,
  createNotificationFailure,
  createNotificationSuccess,
  deleteNotification,
  deleteNotificationFailure,
  deleteNotificationSuccess,
  updateVisibilityRequest,
  updateVisibilityFailure,
  updateVisibilitySuccess
};
