import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EntityTable } from 'components/EntityTable'
import { tokenPurchases } from 'constants/Resources';

import { selectors } from "data/TokenPurchases"
import actions from './actions'
import { getColumns } from "./utils"

const defaultSorted = [
  {
    id: 'createdAt',
    desc: true,
  },
]

const List = () => {
  const dispatch = useDispatch()
  const countries = useSelector(state => state.data.countries).map(option => ({ label: option.name, value: option.alpha3Code }))
  const columns = getColumns({ countries });

  const { data, page, pageSize, isLoading, totalCount, isDownloading } = useSelector(state => selectors.getTokenPurchases(state))

  const handleFetchData = query => dispatch(actions.getTokenPurchases(query))

  return (
    <EntityTable
      resource={tokenPurchases}
      columns={columns}
      data={data}
      page={page}
      pageSize={pageSize}
      totalCount={totalCount}
      isLoading={isLoading}
      defaultSorted={defaultSorted}
      onFetchData={handleFetchData}
      hasDateFilter
      datePropertyKey={'createdAt'}
      isDownloadPossible
      isDownloading={isDownloading}
    />
  )
}

export default List