import { call, put, takeLatest } from 'redux-saga/effects';

import { Boards } from 'services/api';
import { callApi } from 'services/rest';
import { browserHistory } from 'services/history';

import {
  getBoard,
  getBoardFailure,
  getBoardSuccess,
} from 'scenes/Boards/actions';

function* watchGetBoard({ payload: { boardId } }) {
  try {
    const { data } = yield call(callApi, Boards.show, boardId);
    yield put(getBoardSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(getBoardFailure());
    browserHistory.push('/boards/');
  }
}

// It must be wrapped in a function, so execution will happen only on function call
export default () => takeLatest(getBoard, watchGetBoard);
