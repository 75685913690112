import { createAction } from 'redux-actions';

export const createServiceAccount = createAction('CREATE_SERVICE_ACCOUNT_REQUEST');
export const createServiceAccountFailure = createAction('CREATE_SERVICE_ACCOUNT_FAILURE');
export const createServiceAccountSuccess = createAction('CREATE_SERVICE_ACCOUNT_SUCCESS');

export default {
  createServiceAccount,
  createServiceAccountFailure,
  createServiceAccountSuccess,
};
