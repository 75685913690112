import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import styles from './styles.module.scss'
import Checkbox from './Checkbox'


const CheckboxController = ({ disabled, isAllSelected, isNoneSelected, onClear, onSelectAll }) => {

  const handleClick = _e => {
    isNoneSelected ? onSelectAll() : onClear()
  }
  
  return (
    <Checkbox
      disabled={disabled}
      checked={isAllSelected}
      onChange={handleClick}
      className={cn({
        [styles.isNoneSelected]: isNoneSelected,
        [styles.isSomeSelected]: !isNoneSelected && !isAllSelected,
      })}
    />
  )
}

CheckboxController.displayName = 'CheckboxController';

CheckboxController.propTypes = {
  disabled: PropTypes.bool,
  onClear: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  isAllSelected: PropTypes.bool.isRequired,
  isNoneSelected: PropTypes.bool.isRequired,
}

export default CheckboxController