import { createSelector } from 'reselect';

const getRawConnections = state => state.connections;
const getRawRooms = state => state.room;
const getRawLobbyParticipants = state => state.lobbyParticipants;

export const getConnections = createSelector(getRawConnections, connections => {
  const formattedConnections = {
    client: [],
    guest: [],
    sbc: [],
    serviceAccount: [],
  };
  const { sbc = {}, client = {}, guest = {}, serviceAccount = {} } = connections;

  // SBC
  Object.keys(sbc).forEach(id => {
    const currentSbc = sbc[id];

    const formattedSbc = {
      type: 'SBC',
      id,
      entityUrl: `/boards/${currentSbc.entity._id}`,
      entityLabel: currentSbc.entity.name,
      wsConnections: currentSbc.wsConnections,
    };
    formattedConnections.sbc.push(formattedSbc);
  });

  // CLIENT
  Object.keys(client).forEach(id => {
    const currentClient = client[id];

    const formattedClient = {
      type: 'Client',
      id,
      entityUrl: `/users/${currentClient.entity._id}`,
      entityLabel: currentClient.entity.nickname,
      wsConnections: currentClient.wsConnections,
    };
    formattedConnections.client.push(formattedClient);
  });

  // GUEST
  Object.keys(guest).forEach(id => {
    const currentGuest = guest[id];

    const formattedGuest = {
      type: 'Guest',
      id,
      entityUrl: '',
      entityLabel: id,
      wsConnections: currentGuest.wsConnections,
    };
    formattedConnections.guest.push(formattedGuest);
  });

  // SERVICE ACCOUNT
  Object.keys(serviceAccount).forEach(id => {
    const currentServiceAccount = serviceAccount[id];

    const formattedServiceAccount = {
      type: 'ServiceAccount',
      id,
      entityUrl: `/service-accounts/${currentServiceAccount.entity._id}`,
      entityLabel: currentServiceAccount.entity.name,
      wsConnections: currentServiceAccount.wsConnections,
    };
    formattedConnections.serviceAccount.push(formattedServiceAccount);
  });
  return formattedConnections;
});

export const getRooms = createSelector(getRawRooms, rooms => {

  const roomsDTO = {
    rooms: [],
  };

  Object.keys(rooms).forEach(id => {
    const room = rooms[id];
    const { isExist, id: gameId, type, startTime, isThrowForBull, isRandom } = room.game;
    const { Client: activeClients, Guest: activeGuests, SBC: activeSBCs } = room.activeConnections;

    let activeConnections = [];

    //Active Connections
    //Client
    Object.keys(activeClients).forEach(id => {
      const activeClient = {};
      activeClient.wsConnections = activeClients[id].wsConnections;
      activeClient.type = 'Client';
      activeClient.entityLabel = activeClients[id].name;
      activeClient.entityUrl = `/users/${id}`;
      activeConnections.push(activeClient);
    });

    //Guest
    Object.keys(activeGuests).forEach(id => {
      const activeGuest = {};
      activeGuest.wsConnections = activeGuests[id].wsConnections
      activeGuest.type = 'Guest';
      activeGuest.entityLabel = id;
      activeGuest.entityUrl = '';
      activeConnections.push(activeGuest);
    });

    //SBC
    Object.keys(activeSBCs).forEach(id => {
      const activeSBC = {};
      activeSBC.wsConnections = activeSBCs[id].wsConnections
      activeSBC.type = 'SBC';
      activeSBC.entityLabel = activeSBCs[id].name;
      activeSBC.entityUrl = `/boards/${id}`;
      activeConnections.push(activeSBC);
    });

    const dto = {
      id,
      game: {
        id: gameId,
        isExist,
        type,
        startTime: startTime ? new Date(startTime).toLocaleString() : null,
        isThrowForBull,
        isRandom,
      },
      activeConnections,
    };
    roomsDTO.rooms.push(dto);
  });

  return roomsDTO;
});

export const getLobbyParticipants = createSelector(getRawLobbyParticipants, lobbyParticipants =>
  lobbyParticipants.map(participant => {
    participant.sbc.entityUrl = `/boards/${participant.sbc._id}`;
    participant.user.entityUrl = `/users/${participant.user._id}`;
    return participant;
  }));

export default {
  getConnections,
  getRooms,
};
