export const toolbarConfig = {
  options: [
    'inline',
  //  'fontSize',
  //  'fontFamily',
  //  'textAlign',
    'list',
    'link',
  //  'colorPicker',
  //  'emoji',
  //  'remove',
  //  'history',
  ],
  inline: {
    options: [
      'bold',
      'italic',
  //    'underline',
  //    'strikethrough',
  //    'superscript',
  //    'subscript',
    ],
  },
  // fontSize: {
  //   options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
  // },
  //fontFamily: {
  //  options: [
  //    'Arial',
  //    'Georgia',
  //     'Impact',
  //     'Tahoma',
  //     'Times New Roman',
  //     'Verdana',
  //   ],
  // },
  list: {
    inDropdown: false,
    options: [
      'ordered',
      'unordered',
  //  'indent',
  //  'outdent'
    ],
  },
  //textAlign: {
  //  inDropdown: false,
  //  options: ['left', 'center', 'right', 'justify'],
  //},
  //colorPicker: {
  //  popupClassName: styles.colorPickerPopUp,
  //  colors: colors,
  //},
  link: {
    inDropdown: false,
    showOpenOptionOnHover: true,
    defaultTargetOption: '_self',
    options: [
      'link',
      'unlink'
    ],
  },
}