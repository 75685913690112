import React, { forwardRef } from 'react';
import cn from 'classnames';
import { SLabelContainer } from '../SLabelContainer';
import { SAnimate } from '../SAnimate';
import styles from './styles.module.scss';
export const SInput = forwardRef(({ className, label, theme, isRequired, hint, error, name, showLabel, size, ...rest }, ref) => {
    return (React.createElement(SLabelContainer, { label: label, name: name, isRequired: isRequired, showLabel: showLabel, error: error, hint: hint },
        React.createElement("input", { id: name, name: name, ref: ref, className: cn(styles.SInput, styles[theme], size ? styles[size] : undefined, [className], { [styles.error]: !!error }), ...rest }),
        React.createElement(SAnimate, { in: !!error },
            React.createElement("i", { className: cn('sis-failed', styles.errorIcon) }))));
});
SInput.displayName = 'SInput';
