import { get, patch, post, del } from 'services/rest';

const url = '/tournaments';

const Tournaments = {
  index: queryParams => get(url, queryParams),
  list: queryParams => get(`${url}/index`, queryParams),
  create: payload => post(url, payload),
  import: payload => post(`${url}/import`, payload),
  deleteMultiple: (payload, query) => post(`${url}/multipleDelete/`, payload, query),
  delete: id => del(`${url}/${id}`),
  show: tournamentId => get(`${url}/${tournamentId}`),
  update: (tournamentId, payload) => patch(`${url}/${tournamentId}`, payload),
  copy: payload => post(`${url}/copy`, payload),
};

export default Tournaments;
