import { get, post, patch } from 'services/rest';

const url = '/service-accounts';

const ServiceAccounts = {
  index: queryParams => get(url, queryParams),
  show: serviceAccountId => get(`${url}/${serviceAccountId}`),
  create: serviceAccount => post(url, serviceAccount),
  update: (serviceAccountId, payload) => patch(`${url}/${serviceAccountId}`, payload),
  activity: (serviceAccountId, payload) => get(`${url}/${serviceAccountId}/boards-activity`, payload)
};

export default ServiceAccounts;
