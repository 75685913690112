import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader } from 'reactstrap';
import cn from 'classnames';

import { GameTypeEnum } from 'enums';

import styles from './styles.module.scss';

class GameInfo extends PureComponent {
  get noRunningGame() {
    return <span>No running game</span>;
  }

  get gameInfo() {
    const { configuration, gameType, players, startDate } = this.props;

    const { nextGame, ...configWithoutNextGame } = configuration;

    const gameStartDate = new Date(startDate);

    return (
      <div className="key-value-data-container">
        <div className="row">
          <div className="col-sm-4">Game type</div>
          <div className="col-sm-8">
            <strong>{gameType === GameTypeEnum.BullThrow ? `${nextGame.gameType} (Bull Throw)` : gameType}</strong>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4">Players</div>
          <div className="col-sm-8">
            <ul className={styles.players}>
              {players.map((player, idx) => (
                <li key={idx}>
                  {player.botEntity ? `🤖 ${player.nickname}` : player.nickname}
                </li>
              ))}
            </ul>
          </div>
        </div>

        {Object.keys(configWithoutNextGame).map(configItem => (
          <Fragment key={configItem}>
            <div className="row">
              <div className="col-sm-4">
                {configItem
                  // insert a space before all caps
                  .replace(/([A-Z])/g, ' $1')
                  // uppercase the first character
                  .replace(/^./, function(str) {
                    return str.toUpperCase();
                  })}
              </div>
              <div className="col-sm-8">
                {configWithoutNextGame[configItem]
                  .toString()
                  .replace('Triples', 'Trebles')}
              </div>
            </div>
          </Fragment>
        ))}
        <div className="row">
          <div className="col-sm-4">Start date</div>
          <div className="col-sm-8">{gameStartDate.toString()}</div>
        </div>
      </div>
    );
  }

  render() {
    const { isLoading, gameType, onJoinBoard } = this.props;

    return (
      <Card color="white">
        <CardHeader>
          <div className="card-header__left">
            <i className="fa fa-trophy" /> Current Game
            {isLoading && <i className="fa fa-spinner fa-spin fa-fw" />}
          </div>
          <div className="card-header__right">
            {!!gameType && (
              <button className={cn('btn', 'btn-primary', 'btn-sm')} onClick={onJoinBoard}>
                Join board
              </button>
            )}
          </div>
        </CardHeader>
        <CardBody>{gameType ? this.gameInfo : this.noRunningGame}</CardBody>
      </Card>
    );
  }
}

GameInfo.propTypes = {
  configuration: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  players: PropTypes.array.isRequired,
  type: PropTypes.string,
  startDate: PropTypes.string,
  onJoinBoard: PropTypes.func,
};

GameInfo.defaultProps = {
  configuration: {},
  players: [],
};

export default GameInfo;
