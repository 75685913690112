import { get, post } from 'services/rest';

const Statistics = {
  dashboard: () => get(`/statistics/dashboard`),
  systemUsageChart: (payload, queryParams) =>
    post(`/statistics/system-usage-chart`, payload, queryParams),
  systemAnalyticsChart: payload =>
    get('/statistics/system-analytics-chart',payload),
};

export default Statistics;
