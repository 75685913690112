import React, { useState } from "react";
import cn from 'classnames';
import { DateTime } from "luxon";
import { ThemesEnum } from "enums";
import { ArrowIcon, ArrowIconDirection } from "components/Icons";
import { SButton, SButtonVariantOptionsEnum } from "../../../SButton";
import { SizesEnum } from '../../../enums';
import { getTodayMorning, isNullOrUndefined, mapBoolPropsToClassnames as mapBPTC } from "utils";
import { uniqueId } from "lodash";
import { getCurrentDateTime, getWeeksInMonth } from "./utils";
import styles from './styles.module.scss';
export const DatePickerSelector = ({ value, min, max, onChange }) => {
    const currentDate = getCurrentDateTime(DateTime.fromISO(value));
    const id = uniqueId();
    const [selected, setSelected] = useState(currentDate);
    const handlePrevious = () => {
        setSelected((prev) => {
            if (prev.month === 1) {
                return ({ ...prev, year: prev.year - 1, month: 12 });
            }
            return ({ ...prev, month: prev.month - 1 });
        });
    };
    const handleNext = () => {
        setSelected((prev) => {
            if (prev.month === 12) {
                return ({ ...prev, year: prev.year + 1, month: 1 });
            }
            return ({ ...prev, month: prev.month + 1 });
        });
    };
    const handleSelect = (newValue) => {
        onChange && onChange(newValue);
    };
    const selectedDate = getCurrentDateTime(DateTime.local(selected.year, selected.month, selected.day));
    const { year, month, monthString } = selectedDate;
    const weeks = getWeeksInMonth(year, month);
    return (React.createElement("div", { className: styles.datePickerSelector },
        React.createElement("div", { className: styles.datePickerSelectorHeader },
            React.createElement(SButton, { name: `datepicker_${id}_prev`, size: SizesEnum.Small, theme: ThemesEnum.Light, variant: SButtonVariantOptionsEnum.TertiaryGray, onClick: handlePrevious },
                React.createElement(ArrowIcon, { direction: ArrowIconDirection.Left })),
            React.createElement("div", { className: styles.title }, `${year} ${monthString}`),
            React.createElement(SButton, { name: `datepicker_${id}_next`, size: SizesEnum.Small, theme: ThemesEnum.Light, variant: SButtonVariantOptionsEnum.TertiaryGray, onClick: handleNext },
                React.createElement(ArrowIcon, { direction: ArrowIconDirection.Right }))),
        React.createElement("div", { className: styles.currentMonth },
            React.createElement("div", { className: styles.week }, ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((weekDayName) => (React.createElement("div", { className: styles.dayName, key: weekDayName }, weekDayName.slice(0, 2))))),
            weeks.map((week, idx) => {
                if (isNullOrUndefined(week)) {
                    return null;
                }
                return (React.createElement("div", { className: styles.week, key: `week_${idx}` }, week.map(({ day: weekDay, month: weekMonth }) => {
                    const isNotCurrent = weekMonth !== selected.month;
                    const isSelected = currentDate.year === selected.year && currentDate.month === weekMonth && currentDate.day === weekDay;
                    const dateValue = DateTime.local(selected.year, weekMonth, weekDay);
                    const disabled = !!((min && DateTime.fromISO(min) > dateValue) || (max && DateTime.fromISO(max) < dateValue));
                    return (React.createElement("button", { key: weekDay, className: cn(styles.day, mapBPTC(styles, { isNotCurrent, isSelected })), onClick: (_event) => handleSelect((DateTime.local(selected.year, weekMonth, weekDay)).toString()), disabled: disabled }, weekDay));
                })));
            })),
        React.createElement("div", { className: styles.datePickerSelectorBottom },
            React.createElement(SButton, { name: `datepicker_${id}_set_today`, size: SizesEnum.Small, theme: ThemesEnum.Light, variant: SButtonVariantOptionsEnum.TertiaryColor, onClick: (_event) => handleSelect(getTodayMorning().toString()) }, "Set today"))));
};
