import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';

import { debounce } from 'utils';
import { getIsAdmin } from 'services/session/selectors';

import { actions as boardActions } from 'scenes/Boards/components/List';
import { ServiceAccountForm } from 'scenes/ServiceAccounts/components/ServiceAccountForm';

import selectors from './selectors';
import actions from './actions';

class Create extends Component {
  componentDidMount() {
    this.props.dispatch(boardActions.getBoards());
  }

  handleFormSubmit = (values, _actions) => {
    this.props.dispatch(
      actions.createServiceAccount({
        serviceAccount: values,
      })
    );
  };

  handleSerialNumberInputChange = e => {
    e && this.props.dispatch(boardActions.getBoards({ filter: [{ id: 'serialNumber', value: e }] }));
  };

  debouncedHandleSerialInputChange = debounce(this.handleSerialNumberInputChange);

  render() {
    const { formError, boards, isAdmin } = this.props;

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" lg={{ size: 10, offset: 1 }}>
            <Card color="white">
              <CardHeader>
                <div className="card-header__left">
                  <i className="fa fa-align-justify" />New Service Account
                </div>
              </CardHeader>
              <CardBody>
                <ServiceAccountForm
                  formError={formError}
                  boards={boards}
                  onSubmit={this.handleFormSubmit}
                  onSerialNumberInputChange={this.debouncedHandleSerialInputChange}
                  isAdmin={isAdmin}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  formError: selectors.getServiceAccountFormError(state),
  boards: selectors.getBoardsForServiceAccount(state),
  isAdmin: getIsAdmin(state),
});

export default connect(mapStateToProps)(Create);

Create.propTypes = {
  formError: PropTypes.string,
  boards: PropTypes.array.isRequired,
  isAdmin: PropTypes.bool,
};
