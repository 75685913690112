import React from 'react';
import { Link } from 'react-router-dom';
import { GameTypeEnum, GameTypeLabelEnum } from 'enums';
import { formatDate, getFormattedConfig, isNullOrUndefined } from 'utils';
import { useQuery } from 'react-query';
import { GamesList } from 'services/api';
export const useGetGames = (queryFilter) => {
    const games = useQuery({
        queryKey: ['games', 'index', queryFilter],
        queryFn: () => GamesList.index(queryFilter),
        select: (data) => data?.data,
        enabled: !isNullOrUndefined(queryFilter),
        refetchOnWindowFocus: false,
    });
    return games;
};
const getAlignedCell = ({ value }) => (React.createElement("div", { style: { width: '100%', textAlign: 'center' } }, value));
export const getColumns = (clientUrl) => [
    {
        Header: 'Game Type',
        accessor: 'type',
        sortable: false,
        filterable: true,
        Filter: ({ filter, onChange }) => (React.createElement("select", { onChange: (event) => onChange(event.target.value), style: { width: '100%' }, value: filter ? filter.value : 'All' },
            React.createElement("option", { value: "All" }, "All"),
            Object.values(GameTypeEnum).map((gameType) => gameType !== 'BullThrow' && (React.createElement("option", { key: gameType, value: gameType }, GameTypeLabelEnum[gameType]))))),
        Cell: (props) => {
            return getAlignedCell({ value: GameTypeLabelEnum[props.value] });
        },
    },
    {
        Header: 'Configuration',
        accessor: 'configuration',
        width: 285,
        sortable: false,
        Cell: (props) => {
            return getAlignedCell({
                value: getFormattedConfig(props?.row?.type, props?.value, undefined, false),
            });
        },
    },
    {
        Header: 'Start Time',
        accessor: 'startTime',
        Cell: (props) => {
            return getAlignedCell({ value: formatDate(props.value, true) });
        },
    },
    {
        Header: 'Players',
        accessor: 'players',
        sortable: false,
        style: { overflow: 'hidden', whiteSpace: 'normal' },
        Cell: (props) => {
            return getAlignedCell({
                value: props.value.map((player, idx) => {
                    return player._id ? (React.createElement(React.Fragment, { key: idx },
                        idx !== 0 && ', ',
                        React.createElement(Link, { to: `/users/${player._id}` }, player.nickname))) : idx === 0 ? (player.nickname) : (`, ${player.nickname}`);
                }),
            });
        },
    },
    {
        Header: 'Boards',
        accessor: 'boards',
        sortable: false,
        style: { overflow: 'hidden', whiteSpace: 'normal' },
        Cell: (props) => {
            return getAlignedCell({
                value: props.value.map((board, idx) => (React.createElement(Link, { key: idx, to: `/boards/${board._id}` }, idx === 0 ? board.name : `, ${board.name}`))),
            });
        },
    },
    {
        Header: 'Online Game',
        accessor: 'isOnlineGame',
        sortable: false,
        Cell: (props) => {
            return getAlignedCell({ value: props.value ? 'True' : 'False' });
        },
    },
    {
        Header: 'Tournament Game',
        accessor: 'isTournamentGame',
        sortable: false,
        Cell: (props) => {
            return getAlignedCell({ value: props.value ? 'True' : 'False' });
        },
    },
    {
        Header: 'Result',
        accessor: 'status',
        sortable: false,
        filterable: true,
        Filter: ({ filter, onChange }) => (React.createElement("select", { onChange: (event) => onChange(event.target.value), style: { width: '100%' }, value: filter ? filter.value : 'All' },
            React.createElement("option", { value: "All" }, "All"),
            React.createElement("option", { value: "Aborted" }, "Aborted"),
            React.createElement("option", { value: "Finished" }, "Finished"),
            React.createElement("option", { value: "Forsaken" }, "Forsaken"))),
        Cell: (props) => {
            return getAlignedCell({ value: props.value });
        },
    },
    {
        Header: 'Visit',
        accessor: 'gameId',
        sortable: false,
        Cell: (props) => {
            return getAlignedCell({
                value: (React.createElement("a", { target: "_blank", rel: "noreferrer noopener", href: `${clientUrl}/history/${props?.row?._original?._id}` }, props?.value)),
            });
        },
    },
];
