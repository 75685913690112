import { call, put, takeLatest } from 'redux-saga/effects';

import Tournaments from 'services/api/Tournaments';
import { callApi } from 'services/rest';

import { browserHistory } from 'services/history';

import {
  getTournament,
  getTournamentSuccess,
  getTournamentFailure
} from 'scenes/Tournaments/components/Show/actions';

function* watchGetTournament({ payload }) {
  try {
    const { data } = yield call(callApi, Tournaments.show, payload);
    yield put(getTournamentSuccess(data));

  } catch (e) {
    yield put(getTournamentFailure(e.isFetch ? e.data.error : e));
    if (e.status === 400) {
      browserHistory.push('/tournaments');
    }
  }
}

export default () => takeLatest(getTournament, watchGetTournament);