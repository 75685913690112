import React from 'react';
import { Formik } from 'formik';
import TableRow from 'components/TableRow';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { getIsAdmin, getConfiguration } from 'services/session/selectors';
import { formatDate } from 'utils';
import actions from 'services/configuration/actions';
export const FlexBoardConfigurationForm = ({ config }) => {
    const isAdmin = useSelector(getIsAdmin);
    const dispatch = useDispatch();
    const restoreDate = useSelector(getConfiguration).limitedBoard.restoreDate;
    const flexBoardConfiguration = config?.flexBoardConfiguration;
    const handleSubmit = (values) => dispatch(actions.updateConfiguration({
        flexBoardConfiguration: {
            newMaxNumberOfThrows: values.newMaxNumberOfThrows,
            isPracticeGameIncluded: values.isPracticeGameIncluded,
        },
    }));
    const validationSchema = Yup.object().shape({
        isPracticeGameIncluded: Yup.boolean(),
        newMaxNumberOfThrows: Yup.number().min(1).required(),
    });
    return flexBoardConfiguration ? (React.createElement("div", null,
        React.createElement(Formik, { initialValues: flexBoardConfiguration, validationSchema: validationSchema, onSubmit: handleSubmit }, ({ errors, values, handleBlur, handleChange, handleSubmit }) => (React.createElement("form", { onSubmit: handleSubmit },
            React.createElement("dl", { className: "row" },
                React.createElement("dt", { className: "col-sm-12" },
                    "Max number of throws:",
                    ' ',
                    React.createElement("span", { className: "font-weight-bold" }, values.maxNumberOfThrows))),
            React.createElement(TableRow.Text, { label: "New throw limit", labelClass: "col-sm-3", valueClass: "col-sm-9", isLabelFormatted: false, inputClass: "s-input", error: errors.newMaxNumberOfThrows, name: "newMaxNumberOfThrows", type: "number", placeholder: 1, isDisabled: false, min: "1", value: values.newMaxNumberOfThrows, required: false, onBlur: handleBlur, onChange: handleChange }),
            React.createElement("dl", { className: "row" },
                React.createElement("dt", { className: "col-sm-12", style: { fontStyle: 'italic' } },
                    "If the new limit differs from the current one, it will be applied at ",
                    formatDate(restoreDate, true),
                    ".")),
            React.createElement(TableRow.Checkbox, { label: "Is practice game included", labelClass: "col-sm-3", valueClass: "col-sm-9", isLabelFormatted: false, name: "isPracticeGameIncluded", value: values.isPracticeGameIncluded, onBlur: handleBlur, onChange: handleChange, isDisabled: false }),
            React.createElement("div", { className: "action-footer" }, isAdmin && (React.createElement("button", { className: "btn btn-success btn-md", type: "submit", disabled: !!Object.keys(errors).length }, "Save config")))))))) : (React.createElement("div", null,
        React.createElement("i", { className: "fa fa-spinner fa-spin fa-fw" })));
};
