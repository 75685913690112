import { createAction } from 'redux-actions';

export const getResourceLogs = createAction('RESOURCE_LOGS_GET_REQUEST');
export const getResourceLogsFailure = createAction('RESOURCE_LOGS_GET_FAILURE');
export const getResourceLogsSuccess = createAction('RESOURCE_LOGS_GET_SUCCESS');

export default {
  getResourceLogs,
  getResourceLogsFailure,
  getResourceLogsSuccess,
};
