import { TournamentStageType } from "enums";

export const START_SCORE_OPTIONS = [
  { label: 'Race To', value: 'RaceTo' },
  { label: 'Best Of', value: 'BestOf' }
];

export const FIRST_TO_THROW = [
  { label: 'WDF', value: 'WDF' },
  { label: 'PDC', value: 'PDC' }
];

export const FREQUENCY_OPTIONS = [
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
]

export const SCORE_CORRECTION = [
  { label: 'Allowed', value: true },
  { label: 'Disabled', value: false },
]

export const CONFIG_TYPES = [
  { label: 'Fixed', value: 'Fixed' },
  { label: 'Various', value: 'Various' },
]

export const GAME_IN_TYPES = [
  { label: 'Straight-In', value: 'Straight' },
  { label: 'Double-In', value: 'Double' }
];

export const GAME_OUT_TYPES = [
  { label: 'Straight-Out', value: 'Straight' },
  { label: 'Double-Out', value: 'Double' },
  { label: 'Master-Out', value: 'Master' }
];

export const SET_OPTIONS = [
  { label: '1 set', value: '1' },
  { label: '2 sets', value: '2' },
  { label: '3 sets', value: '3' },
  { label: '4 sets', value: '4' },
  { label: '5 sets', value: '5' },
  { label: '6 sets', value: '6' },
  { label: '7 sets', value: '7' },
  { label: '8 sets', value: '8' },
  { label: '9 sets', value: '9' },
  { label: '10 sets', value: '10' },
  { label: '11 sets', value: '11' },
  { label: '12 sets', value: '12' },
  { label: '13 sets', value: '13' },
  { label: '14 sets', value: '14' },
  { label: '15 sets', value: '15' },
]

export const LEG_OPTIONS = [
  { label: '1 leg', value: '1' },
  { label: '2 legs', value: '2' },
  { label: '3 legs', value: '3' },
  { label: '4 legs', value: '4' },
  { label: '5 legs', value: '5' },
  { label: '6 legs', value: '6' },
  { label: '7 legs', value: '7' },
  { label: '8 legs', value: '8' },
  { label: '9 legs', value: '9' },
  { label: '10 legs', value: '10' },
  { label: '11 legs', value: '11' },
  { label: '12 legs', value: '12' },
  { label: '13 legs', value: '13' },
  { label: '14 legs', value: '14' },
  { label: '15 legs', value: '15' },
]

export const COLOR_OPTIONS = [
  '#000000', //black
  '#252525', //mineshaft
  '#333333', //grray
  '#e8e8e8', //wool
  '#ffffff', //white
  '#4c9a2d', //lapalma
  '#5aab30', //success
  '#EFF7EB', //panache
  '#1b70e0', //info
  '#ffc700', //warning
  '#EF9D34', //mango
  '#EB5757', //khmer-curry
  '#f25f5f', //error
  '#F25F5F', //coral
  '#FFEFEF', //chabilis
]

export const DEFAULT_GAME_CONFIG = {
  startScore: 501,
  scoreType: 'RaceTo',
  sets: 1,
  legs: 5,
  gameInType: 'Straight',
  gameOutType: 'Double',
  firstToThrow: 'WDF',
  throwOrderType: 'Bull',
  __type: 'X01'
}

export const STAGES_BY_ORDER = Object.values(TournamentStageType)

export const DEFAULT_TOURNAMENT_CONFIG_FORM = {
  name: '',
  description: '',
  registrationStartDate: undefined,
  registrationEndDate: undefined,
  tournamentStartDate: undefined,
  maxParticipants: 256,
  minParticipants: 129,
  gameConfig : DEFAULT_GAME_CONFIG,
  variousGameConfig: {
    final: DEFAULT_GAME_CONFIG,
    semiFinal: DEFAULT_GAME_CONFIG,
    quarterFinal: DEFAULT_GAME_CONFIG,
    top16: DEFAULT_GAME_CONFIG,
    top32: DEFAULT_GAME_CONFIG,
    top64: DEFAULT_GAME_CONFIG,
    top128: DEFAULT_GAME_CONFIG,
    top256: DEFAULT_GAME_CONFIG,
  },
  constraints: {
    recommended: {
      averageRange: {
        min: 0,
        max: 180
      }
    },
  },
  stagePrizes:{
    winner: undefined,
    runnerUp: undefined,
    semiFinalists: undefined,
    top8: undefined,
    top16: undefined,
    top32: undefined,
    top64: undefined,
    top128: undefined,
    top256: undefined,
  },
  organiser: {
    name: 'Scolia',
  },
  structure: 'SingleKnockout',
  entryFee: 'Free',
  isScoreCorrectionAllowed: false,
  configType: 'Fixed',
  gameType: 'X01',
  organiserType: 'Scolia',
  registrationType: 'Open',
  participationType: 'Online',
}

export default {
  START_SCORE_OPTIONS,
  FIRST_TO_THROW,
  FREQUENCY_OPTIONS,
  SCORE_CORRECTION,
  CONFIG_TYPES,
  GAME_IN_TYPES,
  GAME_OUT_TYPES,
  SET_OPTIONS,
  LEG_OPTIONS,
  COLOR_OPTIONS,
}