import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TournamentList } from 'components/TournamentList';

import { getPreparedFiltersForSelectedFields } from 'utils';

import selectors from './selectors';
import actions from './actions';
import { tournaments } from 'constants/Resources';

const selectColumns = ['isScoreCorrectionAllowed', 'organiserType'];

const columns = ['name', 'state', 'tournamentStartDate', 'minParticipants', 'maxParticipants', 'numberOfParticipants', 'minAverage', 'maxAverage', 'maxPrizePool', 'config', 'isScoreCorrectionAllowed']

const List = ({ match: { url } }) => {
  const data = useSelector(selectors.getTournaments);
  const tournamentList = useSelector(selectors.getTournamentList);

  const [ filters, setFilters ] = useState({});

  const dispatch = useDispatch();

  const handleDelete = (params) => {
    dispatch(actions.deleteMultipleTournaments(params));
  };

  const handleCopy = (params) => {
    dispatch(actions.copyTournaments(params));
  }

  const handleFetchData = ({ filter, ...rest }) => {
    const clonedFilter = getPreparedFiltersForSelectedFields([...filter, { id: 'organiserType', value: 'Scolia' }], selectColumns);

    dispatch(actions.getTournaments({ filter: clonedFilter, ...rest }));

    //save filtering for deleting
    setFilters({ filters: { filter: clonedFilter, ...rest } });
  };

  return (
    <TournamentList
      url={url}
      data={data}
      {...tournamentList}
      onDelete={handleDelete}
      onCopy={handleCopy}
      onFetchData={handleFetchData}
      filters={filters}
      selectColumns={selectColumns}
      resource={tournaments}
      columns={columns}
      canBeCreated
      isDeletable
      canBeCopied
    />
  )
};

List.displayName = 'Tournaments.List';

export default List;