import React from 'react';
import { ThemesEnum } from 'enums';
import { SButton, SButtonVariantOptionsEnum } from 'ScoliaComponents';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
import { isUndefined } from 'lodash';
export const WizardBottom = ({ disabled, isEdit, onDelete, deleteDisabled, tournamentId }) => {
    const handleDelete = () => {
        const result = window.confirm(`Are you sure you want to delete this tournament?`);
        if (result) {
            onDelete();
        }
    };
    return (React.createElement("div", { className: styles.wizardBottom },
        (isEdit && !deleteDisabled) && (React.createElement("div", null,
            React.createElement(SButton, { name: "deleteTournament", variant: SButtonVariantOptionsEnum.ErrorLink, theme: ThemesEnum.Light, onClick: handleDelete }, "Delete tournament"))),
        !isUndefined(tournamentId) && (React.createElement(Link, { to: `/tournaments/${tournamentId}`, className: styles.link }, "Visit show page")),
        React.createElement("div", { className: styles.wrapper },
            React.createElement("div", { className: styles.actions },
                React.createElement(SButton, { disabled: disabled, name: "createTournament", type: "submit" }, isEdit ? 'Save Changes' : 'Create Tournament')))));
};
