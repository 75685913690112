// icons: https://simplelineicons.github.io/
export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer',
      /*badge: {
                variant: 'info',
                text: 'NEW'
            }*/
    },
    {
      title: true,
      name: 'Monitoring',
      wrapper: {
        // optional wrapper object
        element: '', // required valid HTML5 element tag
        attributes: {}, // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: '', // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: 'Online Boards',
      url: '/online-boards',
      icon: 'icon-game-controller',
    },
    {
      name: 'System Usages',
      url: '/system-usages',
      icon: 'icon-graph',
    },
    {
      name: 'Boards',
      url: '/boards',
      icon: 'icon-book-open',
    },
    {
      name: 'Users',
      url: '/users',
      icon: 'icon-user',
    },
    {
      name: 'Games',
      url: '/games',
      icon: 'icon-game-controller',
    },
    {
      name: 'Feedbacks',
      url: '/feedbacks',
      icon: 'icon-bubble',
    },
    {
      name: 'Commercial Partners',
      url: '/commercial-partners',
      icon: 'icon-user-following',
    },
    {
      name: 'Service Accounts',
      url: '/service-accounts',
      icon: 'icon-bubble',
    },
    {
      name: 'Chat',
      url: '/chat',
      icon: 'icon-bubbles',
    },
    {
      title: true,
      name: 'Tournaments',
      wrapper: {
        // optional wrapper object
        element: '', // required valid HTML5 element tag
        attributes: {}, // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: '', // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: 'Tournaments',
      url: '/tournaments',
      icon: 'icon-trophy',
    },
    {
      name: 'Private Tournaments',
      url: '/private-tournaments',
      icon: 'icon-trophy',
    },
    {
      name: 'Rankings',
      url: '/rankings',
      icon: 'icon-list',
    },
    {
      name: 'SC Statistics',
      url: '/score-correction-statistics',
      icon: 'icon-pencil',
    },
    {
      name: 'Token purchases',
      url: '/token-purchases',
      icon: 'icon-basket',
    },
    {
      title: true,
      name: 'Payment',
      wrapper: {
        element: '',
        attributes: {},
      },
      class: '',
    },
    {
      name: 'Subscriptions',
      url: '/subscriptions',
      icon: 'icon-wallet',
    },
    {
      name: 'Products',
      url: '/products',
      icon: 'icon-tag',
    },
    {
      title: true,
      name: 'Technical management',
      wrapper: {
        element: '',
        attributes: {},
      },
      class: '',
    },
    {
      name: 'Hardware versions',
      url: '/hardwares',
      icon: 'icon-settings',
    },
    {
      name: 'Platform versions',
      url: '/platforms',
      icon: 'icon-layers',
    },
    {
      name: 'Firmwares',
      url: '/firmwares',
      icon: 'icon-disc',
    },
    {
      name: 'Notifications',
      url: '/notifications',
      icon: 'icon-bell',
    },
    {
      name: 'Configs',
      url: '/configs',
      icon: 'icon-wrench',
    },
    {
      name: 'Board Update Progress',
      url: '/board-update-progress',
      icon: 'icon-refresh',
    },
    {
      name: 'Store Snapshot',
      url: '/store-snapshot',
      icon: 'icon-pie-chart',
    },
    {
      name: 'Resource Logs',
      url: '/resource-logs',
      icon: 'icon-list',
    },
    {
      name: 'Hybrid SBC-s',
      url: '/manufacturers',
      icon: 'icon-diamond',
    },
  ],
};
