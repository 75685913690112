import * as Yup from 'yup';

import { X01 } from "constants/GameTypes"

import { getTextContentFromMarkup, minToSec } from 'utils';
import { TournamentStructure, TournamentParticipationType, TournamentEntryFee, TournamentConfigType, TournamentRegistrationType, TournamentPlaceTitles, ScoreTypeEnum, X01GameInOutTypeEnum } from 'enums'
import { FinishTimerOptions, PerVisitTimerOptions, StartTimerOptions } from './components/Pages/GameConfiguration/constants';

export const maxNameLength = 32

export const maxDescriptionLength = 3000

export const minStartScore = 101
export const maxStartScore = 9999

export const minSetLegNumber = 1
export const maxSetLegNumber = 15

export const minParticipants = 4
export const maxParticipants = 128

export function validateDescriptionMaxLength(value) {
  const text = getTextContentFromMarkup(value);
  return text.length <= maxDescriptionLength;
}

export function validateDescriptionMinLength(value) {
  const text = getTextContentFromMarkup(value);
  return text.length > 10;
}

function validateSetsAndLegs(value) {
  const { scoreType } = this.parent;

  return scoreType === ScoreTypeEnum.RaceTo || value % 2 !== 0;
}

export function transformTimer(_, val) {
  return val === Number(val) ? val : null
}

export function checkWhitespaceOnly(value) {
  if (value?.match(/^\s+$/g) && value.match(/^\s+$/g).length > 0) {
    return false;
  } else {
    return true;
  }
}

export function checkWhitespaceStartEnd(value) {
  if (value?.match(/^\s+.*/my) || value?.match(/.*\s+$/my)) {
    return false;
  } else {
    return true;
  }
}

export function validateGivenAndCurrentDate(value) {
  const now = new Date();
  const start = new Date(value);
  return start.getTime() >= now.getTime();
}

function compareMaxAndMinParticipants(value) {
  const { minParticipants } = this.parent;
  return value >= minParticipants;
}

function compareMinAndMaxParticipants(value) {
  const { maxParticipants } = this.parent;

  if ( !maxParticipants ) {
    return true
  }

  return value <= maxParticipants;
}

const GameConfigValidationSchema = {
  startScore: Yup.number()
    .required("Start score is required.")
    .min(minStartScore, `The value must be at least ${minStartScore}.`)
    .max(maxStartScore, `The value must not exceed ${maxStartScore}.`),
  sets: Yup.number()
    .required("The number of sets is required.")
    .min(minSetLegNumber, `The value of sets must be at least ${minSetLegNumber}.`)
    .max(maxSetLegNumber, `The value of sets must not exceed ${maxSetLegNumber}.`)
    .test(`set-number-is-even-check`,"Even sets are not permitted for \"Best of\" type settings.", validateSetsAndLegs),
  legs: Yup.number()
    .required("The number of legs is required.")
    .min(minSetLegNumber, `The value of legs must be at least ${minSetLegNumber}.`)
    .max(maxSetLegNumber, `The value of legs must not exceed ${maxSetLegNumber}.`)
    .test(`leg-number-is-even-check`, "Even legs are not permitted for \"Best of\" type settings.", validateSetsAndLegs),
  scoreType: Yup.string()
    .required("Score type is required.")
    .oneOf(Object.values(ScoreTypeEnum)),
  gameInType: Yup.string()
    .required("Game in type is required.")
    .oneOf([X01GameInOutTypeEnum.Straight, X01GameInOutTypeEnum.Double]),
  gameOutType: Yup.string()
    .required("Game out type is required.")
    .oneOf([X01GameInOutTypeEnum.Straight, X01GameInOutTypeEnum.Double, X01GameInOutTypeEnum.Master]),
  startTimer: Yup.lazy((_, schemaContext) => {
    return schemaContext.from && schemaContext.from[1]?.value?.globalStartTimer === 'custom' ? (
      Yup.number()
        .positive()
        .integer()
        .min(1, `Must be min 1.`)
        .max(minToSec(20), `Must be max 20.`)
        .nullable(true)
        .required("Start timer is required.")
        .transform(transformTimer)
    ) : (
      Yup.number().nullable(true)
    )
  }),
  finishTimer: Yup.lazy((_, schemaContext) => {
    return (schemaContext.from && schemaContext.from[1]?.value?.globalFinishTimer === 'custom') ? (
      Yup.number()
        .positive()
        .integer()
        .min(1, `Must be min 1.`)
        .max(minToSec(45), `Must be max 45.`)
        .nullable(true)
        .required(`Finish timer per player is required.`)
        .transform(transformTimer)
    ) : (
      Yup.number().nullable(true)
    )
  }),
  inactivityTimer: Yup.number()
    .positive()
    .integer()
    .min(1, `Must be min 1.`)
    .max(minToSec(5), `Must be max 5.`)
    .nullable(true)
    .transform(transformTimer),
}

const StagePrizesValidationSchema = {
  [TournamentPlaceTitles.winner]: Yup.string(),
  [TournamentPlaceTitles.runnerUp]: Yup.string(),
  [TournamentPlaceTitles.semiFinalists]: Yup.string(),
  [TournamentPlaceTitles.top8]: Yup.string(),
  [TournamentPlaceTitles.top16]: Yup.string(),
  [TournamentPlaceTitles.top32]: Yup.string(),
  [TournamentPlaceTitles.top64]: Yup.string(),
  [TournamentPlaceTitles.top128]: Yup.string(),
  [TournamentPlaceTitles.top256]: Yup.string(),
}

export const editableTournamentWizardValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required(`The tournament name is required. Please enter a name that is ${maxNameLength} characters or fewer.`)
    .max(maxNameLength, `Please shorten the tournament name. It must be no more than ${maxNameLength}} characters long.`)
    .test(`description-white-space-free`, `Name is required and cannot contain only spaces.`, checkWhitespaceOnly)
    .test(`description-white-space-commence`, `Name cannot start or end with space.`, checkWhitespaceStartEnd),
  description: Yup.string()
    .required(`Description is required.`)
    .test(`description-min-length`, `A minimum of 10 characters is needed for the description.`, validateDescriptionMinLength)
    .test(`description-max-length`, `Description must be max ${maxDescriptionLength} characters long.`, validateDescriptionMaxLength),
});


export const initialTournamentWizardValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required(`The tournament name is required. Please enter a name that is ${maxNameLength} characters or fewer.`)
    .max(maxNameLength, `Please shorten the tournament name. It must be no more than ${maxNameLength}} characters long.`)
    .test(`description-white-space-free`, `Name is required and cannot contain only spaces.`, checkWhitespaceOnly)
    .test(`description-white-space-commence`, `Name cannot start or end with space.`, checkWhitespaceStartEnd),
  description: Yup.string()
    .required(`Description is required.`)
    .test(`description-min-length`, `A minimum of 10 characters is needed for the description.`, validateDescriptionMinLength)
    .test(`description-max-length`, `Description must be max ${maxDescriptionLength} characters long.`, validateDescriptionMaxLength),
  tournamentStartDate: Yup.date()
    .required(`Tournament start date is required.`)
    .test(`tournament-start-date-and-current-date-compare`, `The tournament start date must not be earlier than the current time.`, validateGivenAndCurrentDate),
  maxParticipants: Yup.number()
    .required(`The maximum number of participants is required.`)
    .min(minParticipants,`The minimum number of the maximum participants must be at least ${minParticipants}.`)
    .max(maxParticipants,`The maximum number of the maximum participants must be at least ${maxParticipants}.`)
    .test(`max-participants-compare`, `Maximum participants number cannot be less than the minimum participants number`, compareMaxAndMinParticipants),
  minParticipants: Yup.number()
    .required(`The minimum number of participants is required.`)
    .min(minParticipants, `The minimum number of the minimum participants must be at least ${minParticipants}.`)
    .max(maxParticipants, `The maximum number of the minimum participants must be at least ${maxParticipants}.`)
    .test(`min-participants-compare`, `Minimum participants number cannot be more than the maximum participants number.`, compareMinAndMaxParticipants),
  variousGameConfig: Yup.array()
    .of(
      Yup.object().shape({
        stages: Yup.array(),
        ...GameConfigValidationSchema
      })
    ),
  structure: Yup.string()
    .required(`Tournament structure is required.`)
    .oneOf(Object.values(TournamentStructure)),
  participationType: Yup.string()
    .required(`Allowed game types is required.`)
    .oneOf(Object.values(TournamentParticipationType)),
  entryFee: Yup.string()
    .required(`Entry fee is required.`)
    .oneOf(Object.values(TournamentEntryFee)),
  isScoreCorrectionAllowed: Yup.bool()
    .required(`Score correction permission is required.`),
    registrationType: Yup.string()
    .required(`Registration type is required.`)
    .oneOf(Object.values(TournamentRegistrationType)),
  configType: Yup.string()
    .required(`Games configuration type is required.`)
    .oneOf(Object.values(TournamentConfigType)),
  gameType: Yup.string()
    .required(`Game type is required.`)
    .oneOf([X01.type]),
  stagePrizes: Yup.object()
    .shape(StagePrizesValidationSchema),
  backgroundUrl: Yup.string()
    .required(`Background image is required.`),
  thumbnailUrl: Yup.string()
    .required(`Thumbnail image is required.`),
  globalStartTimer: Yup.mixed().oneOf(StartTimerOptions.map(({value})=>value)),
  globalFinishTimer: Yup.mixed().oneOf(FinishTimerOptions.map(({value})=>value)),
  globalInactivityTimer: Yup.mixed().oneOf(PerVisitTimerOptions.map(({value})=>value)),
});