import { call, put, takeLatest } from 'redux-saga/effects';

import { Manufacturers } from 'services/api';
import { browserHistory } from 'services/history';
import { callApi } from 'services/rest';

import {
  updateManufacturer,
  updateManufacturerFailure,
  updateManufacturerSuccess,
} from 'scenes/Manufacturers/components/Edit/actions';

function* watchUpdateManufacturer({ payload: { manufacturerId, ...payload } }) {
  try {
    const { data: updatedManufacturer } = yield call(callApi, Manufacturers.update, manufacturerId, {
      ...payload,
    });
    yield put(updateManufacturerSuccess(updatedManufacturer));

    browserHistory.push(`/manufacturers/${manufacturerId}`);
  } catch (e) {
    if (!e.isFetch) {
      throw e;
    }
    yield put(updateManufacturerFailure(e.data.message));
  }
}

export default () => takeLatest(updateManufacturer, watchUpdateManufacturer);
