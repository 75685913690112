import { call, put, takeLatest } from 'redux-saga/effects';

import { Users } from 'services/api';
import { callApi } from 'services/rest';

import {
  getUserBoards,
  getUserBoardsSuccess,
  getUserBoardsFailure,
} from 'scenes/Users/components/Show/actions';

function* watchGetUserBoards({ payload: {ownerId} }) {
  try {
    const { data } = yield call(callApi, Users.boardsIndex, ownerId);
    yield put(getUserBoardsSuccess(data));
  } catch (e) {
    yield put(getUserBoardsFailure(e.isFetch ? e.data.error : e));
  }
}

export default () => takeLatest(getUserBoards, watchGetUserBoards);
