import { combineReducers } from 'redux';

import { reducer as configuration } from 'services/configuration';
import { reducer as data } from 'data';
import { reducer as notifications } from 'services/notification';
import { reducer as scenes } from 'scenes';
import { reducer as session } from 'services/session';
import { reducer as ws } from 'services/ws';

// REVIEW: https://github.com/gajus/redux-immutable
// REVIEW: https://medium.com/front-end-hacking/using-immutable-js-with-redux-ba89025e45e2
const rootReducer = combineReducers({
  configuration,
  data,
  notifications,
  scenes,
  session,
  ws,
});

export default rootReducer;
