import { getHardwareLabel } from 'utils/getHardwareLabel';

const getPlatformsForSelect = state => {
  return (Object.entries(state.data.platforms) || []).map(([key, value]) => ({
    value: key, label: getHardwareLabel(value)
  }))
}

const getPlatforms = state => state.data.platforms;
const getPlatformById = (platformId) => state => state.data.platforms[platformId]

export { getPlatformsForSelect, getPlatforms, getPlatformById }