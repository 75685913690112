import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import _ from 'lodash';

import { browserHistory } from 'services/history';
import actions from './actions';
import { getTournamentMatchByMatchId } from 'utils/tournamentUtils';

import { getPrivateTournamentInvitedAndAcceptedParticipantsNumber } from './utils';

const INITIAL_STATE = {
  tournament: {},
  isLoading: false,
};

const show = handleActions(
  {
    [actions.getPrivateTournament]: (state, _action) =>
      update(state, {
        isLoading: { $set: true },
      }),

    [actions.clearPrivateTournament]: (state, _action) =>
      update(state, {
        tournament: { $set: {} },
      }),

    [actions.getPrivateTournamentSuccess]: (state, { payload }) => {
      const participantsById = {};
      const participantsOrder = [];

      payload.participants.forEach((p) => {
        participantsById[p.userId] = p;
        participantsOrder.push(p.userId);
      });

      return {
        tournament: {
          ...payload,
          participants: participantsById,
          participantsOrder: participantsOrder,
          ...getPrivateTournamentInvitedAndAcceptedParticipantsNumber(participantsById),
        },
        isLoading: false,
      };
    },

    [actions.getPrivateTournamentFailure]: (state) =>
      update(state, {
        tournament: { $set: {} },
        isLoading: { $set: false },
      }),

    [actions.updatePrivateTournamentMatch]: (storeState, { payload }) => {
      const { stageType, stageMatchIndex, tournamentMatch } = getTournamentMatchByMatchId(
        storeState.tournament,
        payload.matchId
      );
      const merged = _.merge(tournamentMatch, payload);

      //continuous updating would break immutability-helper's update so we update the object like this
      const newTournamentStage = [...storeState.tournament.stages[stageType]];
      newTournamentStage[stageMatchIndex] = merged;

      return {
        ...storeState,
        tournament: {
          ...storeState?.tournament,
          stages: {
            ...storeState?.tournament.stages,
            [stageType]: newTournamentStage,
          },
        },
      };
    },
    [actions.changePrivateTournamentState]: (
      storeState,
      { payload: { changedState, changedStages } }
    ) =>
      update(storeState, {
        tournament: {
          state: { $set: changedState },
          stages: { $set: changedStages ? changedStages : storeState?.tournament?.stages },
        },
      }),

    [actions.updateParticipants]: (state, { payload }) => {
      if (browserHistory.location.pathname.includes('editInvitational')) {
        return state;
      }

      let newParticipants = { ...state.tournament.participants };
      let newParticipantsOrder = [...state.tournament.participantsOrder];

      if (payload.newParticipants) {
        payload.newParticipants.forEach((participant) => {
          newParticipants[participant?.userId] = participant;
          if (!newParticipantsOrder.includes(participant?.userId)) {
            newParticipantsOrder = [...newParticipantsOrder, participant?.userId];
          }
        });
      } else if (payload.participant) {
        newParticipants[payload.participant?.userId] = payload.participant;
      } else if (payload.removedParticipant) {
        delete newParticipants[payload.removedParticipant];
        newParticipantsOrder = newParticipantsOrder.filter((p) => p !== payload.removedParticipant);
      }

      return {
        ...state,
        tournament: {
          ...state.tournament,
          participants: newParticipants,
          participantsOrder: newParticipantsOrder,
          numberOfParticipants:
            payload.numberOfParticipants ?? state.tournament.numberOfParticipants,
          ...getPrivateTournamentInvitedAndAcceptedParticipantsNumber(newParticipants),
        },
      };
    },

    [actions.updateResults]: (state, { payload: { results, updatedParticipants } }) => {
      let newResults = { ...state.tournament.results };
      let newParticipants = { ...state.tournament.participants };
      Object.keys(results).forEach((key) => (newResults[key] = results[key]));

      //updatedParticipants are participants with a complete object of the tournamentStats
      //so merge them into the new Participants that we are going to store
      Object.keys(updatedParticipants).forEach((key) => {
        const mergedTournamentStats = _.merge(
          newParticipants[key].tournamentStats,
          updatedParticipants[key]
        );

        newParticipants[key] = {
          ...newParticipants[key],
          tournamentStats: mergedTournamentStats,
        };
      });

      return {
        ...state,
        tournament: {
          ...state.tournament,
          results: newResults,
          participants: newParticipants,
        },
      };
    },
  },
  INITIAL_STATE
);

export default show;
