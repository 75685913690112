import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { EntityTable } from 'components/EntityTable';
import { getColumns, useGetGames } from './utils';
import { useQueryClient } from 'react-query';
const defaultSorted = [
    {
        id: 'startTime',
        desc: true,
    },
];
const selectColumns = ['type', 'status'];
export const List = () => {
    const { clientUrl } = useSelector((state) => state.configuration);
    const queryClient = useQueryClient();
    const [parameters, setParameters] = useState();
    const { isLoading, data: { data: games = [], totalCount } = {} } = useGetGames(parameters);
    const handleFetchData = (paginationParameters, isRefresh) => {
        setParameters(paginationParameters);
        if (isRefresh) {
            queryClient.invalidateQueries(['games', 'index']);
        }
    };
    return (React.createElement("div", { className: "animated fadeIn" },
        React.createElement(EntityTable, { resource: 'games', columns: getColumns(clientUrl), selectColumns: selectColumns, data: games, totalCount: totalCount ?? 0, defaultSorted: defaultSorted, datePropertyKey: 'startTime', hasDateFilter: true, isLoading: isLoading, onFetchData: handleFetchData })));
};
