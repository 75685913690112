import React from 'react';
import cn from 'classnames';
import { mapBoolPropsToClassnames as mapBPTC } from 'utils';
import styles from './styles.module.scss';
export const TimeOption = ({ value, label, hideValue, }) => {
    return (React.createElement("div", { className: styles.timeOption },
        React.createElement("div", { className: cn(styles.timeOptionSection, mapBPTC(styles, { hideValue })) },
            React.createElement("b", null, value),
            React.createElement("span", null, label))));
};
