import { isNullOrUndefined } from "utils";
import React from "react";

type NormalizedEvent = {
  originalEvent: Event;
  inputType?: string;
  navigationType?: string;
  data?: string;
};

export const onlyNumbers = (event: React.KeyboardEvent<HTMLInputElement>, regExp: RegExp) => {
  const normalizedEvent = normalizeInputEvent(event.nativeEvent);
  if (!isNullOrUndefined(normalizedEvent.data) && !regExp.test(normalizedEvent.data!)) {
    event.preventDefault();
  }
};

const normalizeInputEvent = (event: KeyboardEvent | InputEvent): NormalizedEvent => {
  const e: NormalizedEvent = {
    originalEvent: event,
  };

  if (event instanceof KeyboardEvent) {
    if (event.key === 'Backspace') {
      e.inputType = 'deleteContentBackward';
      e.navigationType = 'cursorLeft';
    } else if (event.key === 'Delete') {
      e.inputType = 'deleteContentForward';
    } else if (event.key.startsWith('Arrow')) {
      e.navigationType = event.key.replace('Arrow', 'cursor');
    } else {
      e.data = event.key;
      e.inputType = 'insertText';
    }
  } else if (event instanceof InputEvent) {
    const { inputType } = event;
    e.inputType = inputType;
    e.data = event.data ?? undefined;

    if (inputType === 'insertText') {
      e.navigationType = 'cursorRight';
    }
  }

  return e;
};