import { take, call } from 'redux-saga/effects';

import { Socket, actions as wsActions } from 'services/ws';

import {
  unsubscribeToBoardUpdates
} from 'scenes/Boards/components/Show/actions.js'

export default function* watchUnsubscribeToBoardUpdates() {
  while (true) {
    const { payload: { channel, sbcId, userId } } = yield take(unsubscribeToBoardUpdates);
    const payload = wsActions.Management.boardUpdatesUnsubscribe({ channel, subjects: [sbcId, userId] })
    yield call([Socket, Socket.send], JSON.stringify(payload));
  }
}
