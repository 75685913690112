import React from 'react'
import PropTypes from 'prop-types'

import TournamentCard from '../TournamentCard/TournamentCard'

import styles from './styles.module.scss'

const TournamentCardList = ({ fileContent, isExpandedList, dataErrors, userId }) => {
  return (
    <div className={styles.cardList}>
      {fileContent.map((tournament, idx) => (
        <TournamentCard
          key={idx}
          idx={idx}
          tournament={tournament}
          dataErrors={dataErrors[idx] || []}
          isExpandedList={isExpandedList}
          userId={userId}
        />
      ))}
    </div>
  );
}

TournamentCardList.propTypes = {
  fileContent: PropTypes.array.isRequired,
  isExpandedList: PropTypes.bool,
  dataErrors: PropTypes.array,
  userId: PropTypes.string.isRequired
}

export default TournamentCardList;