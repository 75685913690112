import blackAndWhiteBoard from 'assets/images/tournaments/defaultThumbnails/black-and-white-board.png';
import bullThrow from 'assets/images/tournaments/defaultThumbnails/bull-throw.png';
import finals from 'assets/images/tournaments/defaultThumbnails/finals.png';
import goldenTrophy from 'assets/images/tournaments/defaultThumbnails/golden-trophy.png';
import oneHundredEighteen from 'assets/images/tournaments/defaultThumbnails/one-hundred-eighteen.png';
import scoliaCamera from 'assets/images/tournaments/defaultThumbnails/scolia-camera.png';
import smokeTrophy from 'assets/images/tournaments/defaultThumbnails/smoke-trophy.png';

export enum PrivateTournamentThumbnails {
  goldenTrophy = 'goldenTrophy',
  blackAndWhiteBoard = 'blackAndWhiteBoard',
  bullThrow = 'bullThrow',
  finals = 'finals',
  oneHundredEighteen = 'oneHundredEighteen',
  scoliaCamera = 'scoliaCamera',
  smokeTrophy = 'smokeTrophy'
}

export const isImagePrivateTournamentThumbnail = (image: string) => {
  return Object.values(PrivateTournamentThumbnails)?.includes(image as PrivateTournamentThumbnails)
}

export const PrivateTournamentThumbnailOptions = [
  { src: goldenTrophy, value: PrivateTournamentThumbnails.goldenTrophy },
  { src: bullThrow, value: PrivateTournamentThumbnails.bullThrow },
  { src: finals, value: PrivateTournamentThumbnails.finals },
  {
    src: oneHundredEighteen,
    value: PrivateTournamentThumbnails.oneHundredEighteen,
  },
  { src: scoliaCamera, value: PrivateTournamentThumbnails.scoliaCamera },
  { src: smokeTrophy, value: PrivateTournamentThumbnails.smokeTrophy },
  {
    src: blackAndWhiteBoard,
    value: PrivateTournamentThumbnails.blackAndWhiteBoard,
  },
];
