import { get, del } from 'services/rest';

const url = '/private-tournaments';

const PrivateTournaments = {
  show: tournamentId => get(`${url}/${tournamentId}`),
  kill: tournamentId => del(`${url}/${tournamentId}/kill-gracefully`),
};

export default PrivateTournaments;
