import React, { useRef } from 'react';
import cn from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { SAnimateVariantsEnum } from './SAnimate.enum';
import styles from './styles.module.scss';
export const SAnimate = ({ in: isActive, className, mountOnEnter, variant = SAnimateVariantsEnum.ZoomIn, onExited, ...rest }) => {
    const nodeRef = useRef(null);
    return (React.createElement(CSSTransition, { unmountOnExit: true, mountOnEnter: mountOnEnter, nodeRef: nodeRef, onExited: onExited, in: isActive, timeout: 300, classNames: {
            enter: styles['fade-enter'],
            enterActive: styles['fade-enter-active'],
            exit: styles['fade-exit'],
            exitActive: styles['fade-exit-active'],
        } },
        React.createElement("div", { ref: nodeRef, className: cn(styles.animated, [className], styles[variant]), ...rest })));
};
