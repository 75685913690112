import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import { HardwareForm } from 'scenes/Hardwares/components/HardwareForm';

import { getIsAdmin } from 'services/session/selectors';

import actions from './actions';

class Create extends Component {

  handleFormSubmit = (values, _actions) =>
    this.props.dispatch(
      actions.createHardware({
        hardware: values,
      })
    );

  render() {
    const { formError, isAdmin } = this.props;

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" lg={{ size: 10, offset: 1 }}>
            <Card color="white">
              <CardHeader>
                <div className="card-header__left">
                  <i className="fa fa-align-justify" /> New Hardware
                </div>
              </CardHeader>
              <CardBody>
                <HardwareForm
                  formError={formError}
                  onSubmit={this.handleFormSubmit}
                  isAdmin={isAdmin}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  formError: state.scenes.hardwares.create.createError,
  isAdmin: getIsAdmin(state),
});

export default connect(mapStateToProps)(Create);
