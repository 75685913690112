import store from 'store2';
import { call, cancel, fork, put, take } from 'redux-saga/effects';

// import { delay } from 'redux-saga';

import { Auth } from 'services/api';
import { get } from 'services/rest';
import { callApi } from 'services/rest';

import actions from './actions';

function* authorize(credentials) {
  try {
    const payload = yield call(callApi, Auth.login, credentials);

    // Check User role
    const {
      token,
      ttl,
      user: { role },
    } = payload.data;
    if (role === 'Admin' || role === 'Viewer') {
      store.set('token', token);
      yield put(actions.logInSuccess({ token, ttl }));
      // Currently the response doesn't contain ttl, so we can't rely on it for a delayed auto logout
      // yield fork(autoLogout, ttl);
    } else {
      yield put(
        actions.logInFailure({ error: 'You are not authorized to login!' })
      );
    }
  } catch (e) {
    yield put(actions.logInFailure(e.data));
  }
}

function* verify(token) {
  try {
    const payload = yield call(callApi, get, '/users/me');
    yield put(actions.logInSuccess({ token, ttl: payload.data.ttl }));
    // Currently the response doesn't contain ttl, so we can't rely on it for a delayed auto logout
    // yield fork(autoLogout, payload.data.ttl);
  } catch (e) {
    store.remove('token');
    yield put(actions.logInFailure(e.data));
  }
}

// REVIEW: https://redux-saga.js.org/docs/advanced/TaskCancellation.html
// function* autoLogout(ttl) {
//   yield call(delay, ttl);
//   yield put(actions.logOut());
// }

// REVIEW: https://redux-saga.js.org/docs/advanced/NonBlockingCalls.html
export default function* sessionSaga() {
  let authTask;

  while (true) {
    if (store.has('token')) {
      authTask = yield fork(verify, store.get('token'));
    } else {
      const { payload: credentials } = yield take(actions.logIn);
      // Start the auth process in the background...
      authTask = yield fork(authorize, credentials);
    }

    // So that here we can catch an intermediate logout action too
    const { type } = yield take([actions.logInFailure, actions.logOut]);

    if (type === actions.logOut.toString()) {
      if (authTask) {
        yield cancel(authTask);
      }
      store.remove('token');
    }
  }
}
