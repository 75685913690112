import { call, put, takeLatest } from 'redux-saga/effects';

import { PrivateTournaments } from 'services/api';
import { callApi } from 'services/rest';

import { browserHistory } from 'services/history';

import {
  getPrivateTournament,
  getPrivateTournamentSuccess,
  getPrivateTournamentFailure
} from 'scenes/PrivateTournaments/components/Show/actions';

function* watchGetTournament({ payload }) {
  try {
    const { data } = yield call(callApi, PrivateTournaments.show, payload);
    yield put(getPrivateTournamentSuccess(data.tournament));

  } catch (e) {
    yield put(getPrivateTournamentFailure(e.isFetch ? e.data.error : e));
    if (e.status === 400) {
      browserHistory.push('/tournaments');
    }
  }
}

export default () => takeLatest(getPrivateTournament, watchGetTournament);