export default {
  '/': 'Home',

  '/dashboard': 'Dashboard',

  '/online-boards': 'Online Boards',
  '/ranking-list': 'Ranking List',
  '/system-usages': 'System Usages',
  '/board-update-progress': 'Board Update Progress',
  '/store-snapshot': 'Store Snapshot',
  '/chat': 'Chat',
  '/notifications': 'Notifications',
  '/configs': 'Configs',

  '/tournaments': 'Tournaments',
  '/private-tournaments': 'Private Tournaments',
  '/rankings': 'Rankings',
  '/score-correction-statistics': 'SC Statistics',
  '/token-purchases': 'Token purchases',

  '/subscriptions': 'Subscriptions',
  '/products': 'Products',

  '/boards': 'Boards',
  '/firmwares': 'Firmwares',
  '/platforms': 'Platform versions',
  '/hardwares': 'Hardware versions',
  '/users': 'Users',
  '/games': 'Games',
  '/feedbacks': 'Feedbacks',
  '/service-accounts': 'Service Accounts',
  '/commercial-partners': 'Commercial Partners',
  '/manufacturers': 'Hybrid SBC-s',

  '/resource-logs': 'Resource Logs',
};
