import { call, delay, put, take } from 'redux-saga/effects';

import { Configuration } from 'services/api';
import { callApi } from 'services/rest';

import actions from './actions';

export default function* fetchConfig() {
  let isInitial = true;

  while (true) {
    if (!isInitial) {
      yield take(actions.fetchConfiguration);
    }

    try {
      const { data } = yield call(callApi, Configuration.fetch);
      yield put(actions.fetchConfigurationSuccess(data));
      isInitial && (isInitial = false);
    } catch (e) {
      yield put(
        actions.fetchConfigurationFailure(e.isFetch ? e.data.error : e)
      );
      // Do not overcomplicate it with backoffs, yet...
      isInitial && (yield delay(4000));
    }
  }
}