import { call, put, takeLatest } from 'redux-saga/effects';

import { Boards } from 'services/api';
import { browserHistory } from 'services/history';
import { notifyError } from 'services/notification/actions';

import {
  updateBoard,
  updateBoardFailure,
  updateBoardSuccess,
} from 'scenes/Boards/components/Edit/actions';
import { callApi } from 'services/rest';

import { splitCamelCase } from 'utils';


function* watchEditBoard({ payload: { boardId, board } }) {
  try {
    const { data: updatedBoard } = yield call(callApi, Boards.update, boardId, board);
    yield put(updateBoardSuccess(updatedBoard));
    browserHistory.push(`/boards/${boardId}`);
  } catch (e) {
    if (!e.isFetch) {
      throw e;
    }
    yield put(updateBoardFailure(e.error));

    // eslint-disable-next-line no-unsafe-optional-chaining
    const failedSettings = (e?.data?.payload?.map((item) => splitCamelCase(item.property))).join(", ")

    if (failedSettings?.length > 0) {
      yield put(notifyError(`Couldn't change the following settings: ${failedSettings}.`));
    }
  }
}

export default () => takeLatest(updateBoard, watchEditBoard);
