import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody } from 'reactstrap';
import cn from 'classnames';

import { formatNumberWithThousandSeparators } from 'utils';

import styles from './styles.module.scss';

const LABELS = {
  addedByGs: 'Added By GS.',
  automaticallyDetected: 'Auto. Det.',
  manuallyAdded: 'Manually Added',
  modified: 'Modified',
  total: 'Total Throws',
};

const BORDER_COLOR_CLASSES = {
  automaticallyDetected: 'automaticallyDetected',
  manuallyAdded: 'manuallyAdded',
  modified: 'modified',
  addedByGs: 'addedByGs',
};

class Throws extends Component {
  get dataForCallouts() {
    const data = [];

    let throwsData = Object.entries(this.props.throws);

    throwsData.splice(0, 1);

    for (let [key, value] of throwsData) {
      data.push({ key, value });
    }

    return data;
  }

  shouldShowPercentage = (key) =>
    ['modified', 'manuallyAdded'].some((status) => status === key);

  getCalloutValue = (key, value) => {
    const {
      throws: { automaticallyDetected, manuallyAdded, modified },
    } = this.props;

    let percentage;

    if (key === 'modified') {
      percentage = (
        (modified / (automaticallyDetected + manuallyAdded + modified)) *
        100
      ).toFixed(2);
    } else if (key === 'manuallyAdded') {
      percentage = (
        (manuallyAdded / (automaticallyDetected + manuallyAdded + modified)) *
        100
      ).toFixed(2);
    }

    return (
      <div className={styles.calloutValueContainer}>
        <span className={styles.calloutValue}>
          {formatNumberWithThousandSeparators(value, ' ')}
        </span>
        {this.shouldShowPercentage(key) && (
          <span className={styles.calloutValuePercentage}>
            {percentage + '%'}
          </span>
        )}
      </div>
    );
  };

  get callouts() {
    return this.dataForCallouts.map(({ key, value }) => (
      <Col xs={12} sm={6} key={'throws-callout_' + key}>
        <div className={cn('callout', styles[BORDER_COLOR_CLASSES[key]])}>
          {this.getCalloutValue(key, value)}
          <small className="text-muted text-uppercase fw-bold">
            {LABELS[key]}
          </small>
        </div>
      </Col>
    ));
  }

  render() {
    const {
      outcastThrowsCount,
      throws: { total },
    } = this.props;

    return (
      <Card color="white">
        <CardBody>
          <Fragment>
            <Row>
              <Col xs={6}>
                <strong className={cn('text-value h2', styles.textValue)}>
                  {formatNumberWithThousandSeparators(total, ' ')}
                </strong>
                <span className="text-muted text-uppercase fw-bold">
                  Total Throws
                </span>
              </Col>
              <Col xs={6}>
                <div className={styles.outcastThrowsContainer}>
                  <strong className={cn('text-value h3')}>
                    {formatNumberWithThousandSeparators(outcastThrowsCount, ' ')}
                  </strong>
                  <span className="text-muted text-uppercase">
                    Outcast Thr.
                  </span>
                </div>
              </Col>
            </Row>
            <Row>{this.callouts}</Row>
          </Fragment>
        </CardBody>
      </Card>
    );
  }
}

Throws.propTypes = {
  throws: PropTypes.object.isRequired,
  outcastThrowsCount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

Throws.defaultProps = {
  isLoading: true,
};

export default Throws;
