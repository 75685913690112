import { call, put, takeLatest } from 'redux-saga/effects';

import { Notifications } from 'services/api';
import { browserHistory } from 'services/history';

import { callApi } from 'services/rest';
import { notifyError, notifySuccess } from 'services/notification/actions';

import {
  getNotifications,
  createNotification,
  createNotificationFailure,
  createNotificationSuccess,
} from 'scenes/Notifications/actions';

function* watchCreateNotification({
  payload: {
    payload: { title, content, isActive, type },
  },
}) {
  try {
    yield call(callApi, Notifications.create, {
      title,
      content,
      isActive,
      type,
    });
    yield put(createNotificationSuccess());
    yield put(notifySuccess(`Notification has been created successfully.`));
    yield put(getNotifications());

    browserHistory.push(`/notifications/`);
  } catch (e) {
    yield put(createNotificationFailure(e));
    yield put(notifyError(`Failed to create notification.`));
  }
}

export default () => takeLatest(createNotification, watchCreateNotification);
