import { handleActions } from 'redux-actions';

import { actions as listActions } from 'scenes/Feedbacks/components/List';

const INITIAL_STATE = [];

const feedbacksReducer = handleActions(
  {
    [listActions.getFeedbacksSuccess]: (state, { payload: { data } }) => {
      const feedbacks = data.reduce((feedbacks, feedback) => {
        feedback.id = feedback._id;
        delete feedback._id;

        feedbacks[feedback.id] = feedback;
        return feedbacks;
      }, {});

      return Object.values(feedbacks);
    },
  },
  INITIAL_STATE
);

export default feedbacksReducer;
