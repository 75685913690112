
import { take, call, select, delay } from 'redux-saga/effects';

import { Socket, actions as wsActions } from 'services/ws';

import { OPENED } from 'constants/WsStatuses'
import { subscribeToTournamentUpdates } from '../../actions';


export default function* watchSubscribeToTournamentUpdates() {
  while (true) {
    const { payload: { channel, tournamentId } } = yield take(subscribeToTournamentUpdates);
    const payload = wsActions.Management.tournamentUpdatesSubscribe({ channel, subjects: [tournamentId] });

    const wsConnection = yield select((state) => state.ws.status === OPENED);

    if (wsConnection) {
      yield call([Socket, Socket.send], JSON.stringify(payload));
    }
    else {
      yield take(wsActions.initWsConnectionSuccess);
      // Don't know why, but it needs a delay for the connection to properly init 
      yield delay(200);
      yield call([Socket, Socket.send], JSON.stringify(payload));
    }
  }
}
