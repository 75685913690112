import React, { useRef, useState } from 'react';
import { DateTime } from 'luxon';
import cn from 'classnames';
import { ThemesEnum } from 'enums';
import { Portal } from 'components/Portal';
import { mapBoolPropsToClassnames as mapBPTC } from 'utils';
import { CalendarIcon } from 'components/Icons';
import { SizesEnum } from '../enums';
import { useDropdownPositioning, DropdownDirection } from '../hooks';
import { SAnimate, SAnimateVariantsEnum } from '../SAnimate';
import { SCard, SCardVariants } from '../SCard';
import { SLabelContainer } from '../SLabelContainer';
import { DatePickerSelector } from './components';
import styles from './styles.module.scss';
const containerMaxHeight = 380;
const containerWidth = 320;
export const SDatePicker = ({ className, label, theme = ThemesEnum.Light, isRequired, hint, error, name, value, showLabel = true, size = SizesEnum.Medium, min, max, onFocus, onChange, ...rest }) => {
    const [isOpen, setIsOpen] = useState(false);
    const inputRef = useRef(null);
    const dropdownDirection = useDropdownPositioning(inputRef, containerMaxHeight);
    const handlePreventDefault = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };
    const handleFocus = (event) => {
        onFocus && onFocus(event);
        setIsOpen(curr => !curr);
    };
    const handleCloseOptions = () => {
        setIsOpen(false);
    };
    const handleSelect = (newValue) => {
        onChange && onChange(newValue, name);
        handleCloseOptions();
    };
    const isUp = dropdownDirection === DropdownDirection.up;
    const isDown = dropdownDirection === DropdownDirection.down;
    let dropdownSizes;
    let containerStyles = {};
    if (inputRef?.current) {
        const rect = inputRef.current.getBoundingClientRect();
        dropdownSizes = {
            width: containerWidth,
            height: inputRef.current.clientHeight,
            top: rect.top,
            left: rect.left,
        };
        const wouldOverFlow = window.innerWidth < dropdownSizes?.left + containerWidth;
        let left = '0';
        if (wouldOverFlow) {
            left = `${(window.innerWidth - containerWidth) / 2}px`;
        }
        else {
            left = `${dropdownSizes?.left}px`;
        }
        containerStyles = {
            top: `${isDown ? (dropdownSizes?.top ?? 0) + (dropdownSizes?.height ?? 0) + 8 : dropdownSizes?.top}px`,
            left,
            width: `${containerWidth}px`,
            height: `${containerMaxHeight}px`,
        };
    }
    function formatISODateToCustom(isoDateString) {
        const date = DateTime.fromISO(isoDateString);
        return date.toFormat('MMMM dd, yyyy');
    }
    return (React.createElement(SLabelContainer, { label: label, name: name, isRequired: isRequired, showLabel: showLabel, error: error, hint: hint },
        React.createElement("input", { id: name, name: name, type: "text", ref: inputRef, readOnly: true, onFocus: handleFocus, value: formatISODateToCustom(value), className: cn(styles.SInput, styles[theme], styles[size], [className], {
                [styles.error]: !!error,
            }), ...rest }),
        React.createElement("div", { className: cn(styles.icon, mapBPTC(styles, { isOpen })) },
            React.createElement(CalendarIcon, null)),
        React.createElement(Portal, null,
            React.createElement("div", { className: cn(styles.optionsContainerBackground, mapBPTC(styles, { isOpen })), onClick: handleCloseOptions },
                React.createElement("div", { style: containerStyles, className: cn(styles.optionsContainer, mapBPTC(styles, { isUp, isDown, isOpen })), onClick: handlePreventDefault },
                    React.createElement(SAnimate, { variant: SAnimateVariantsEnum.SlideInTop, in: isOpen, onClick: handlePreventDefault },
                        React.createElement(SCard, { theme: theme, className: styles.optionsWrapper, variant: SCardVariants.Solid, outline: true },
                            React.createElement(DatePickerSelector, { value: value, onChange: handleSelect, min: min, max: max }))))))));
};
SDatePicker.displayName = 'SDatePicker';
