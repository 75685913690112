import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import * as Yup from 'yup';
import cn from 'classnames';

import { PHONENUMBER } from 'constants/Validation-regexp';

import { Input } from 'components/Form';

import { checkWhitespaceOnly, checkWhitespaceStartEnd } from 'utils/checkWhitespace';

import { ConnectedBoards } from 'scenes/ServiceAccounts/components/ConnectedBoards';

import styles from './styles.module.scss';

import { withFormik } from 'formik';
import randomBytes from 'randombytes';


const getValidationSchema = () => {
  return Yup.object().shape({
    name: Yup.string()
      .required('Name is required')
      .test('white-space-free', 'Name is required and cannot contain only spaces!', checkWhitespaceOnly)
      .test('white-space-free', 'Name cannot start or end with space!', checkWhitespaceStartEnd),
    email: Yup.string()
      .required('E-mail is required')
      .email('Not a valid e-mail address'),
    phoneNumber: Yup.string().matches(PHONENUMBER, 'Not a valid phone number! Prefered format: +123456789012'),
    connectedBoards: Yup.array().of(
      Yup.object().shape({
        serialNumber: Yup.string().required('Serial number is required'),
      })
    ),
  });
};

class ServiceAccountForm extends Component {
  handleChange = (e, formikHandleChange) => {
    if (e.connectedBoards) {
      const { setFieldValue } = this.props;
      setFieldValue('connectedBoards', e.connectedBoards);
    } else {
      formikHandleChange(e);
    }
  };

  generateAccessToken = cb => {
    const { isEdit } = this.props;
    if (isEdit) {
      let confirmed = window.confirm(
        "Generating a new Social Access Token, will immediately invalidate the previous one, and a Service Account using the old token won't be able to authenticate into Scolia systems anymore!"
      );
      confirmed && cb('socialAccessToken', randomBytes(16).toString('hex'));
    } else {
      cb('socialAccessToken', randomBytes(16).toString('hex'));
    }
  };

  render() {
    const {
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      isEdit,
      setFieldValue,
      touched,
      values,
      boards,
      formError,
      onSerialNumberInputChange,
      isAdmin
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <dl className="row">
          <dt className="col-sm-3 required">Name</dt>
          <dd className="col-sm-9">
            <Input.Text
              error={touched.name && errors.name}
              name="name"
              type="text"
              value={values.name}
              onBlur={handleBlur}
              onChange={e => this.handleChange(e, handleChange)}
            />
          </dd>
        </dl>
        <dl className="row">
          <dt className="col-sm-3 required">E-mail</dt>
          <dd className="col-sm-9">
            <Input.Text
              error={touched.email && errors.email}
              name="email"
              type="text"
              value={values.email}
              onBlur={handleBlur}
              onChange={e => this.handleChange(e, handleChange)}
            />
          </dd>
        </dl>
        <dl className="row">
          <dt className="col-sm-3">Contact Phone Number</dt>
          <dd className="col-sm-9">
            <Input.Text
              error={touched.phoneNumber && errors.phoneNumber}
              name="phoneNumber"
              type="text"
              value={values.phoneNumber}
              onBlur={handleBlur}
              onChange={e => this.handleChange(e, handleChange)}
            />
          </dd>
        </dl>
        <dl className="row">
          <dt className="col-sm-3">Social Api Enabled</dt>
          <dd className="col-sm-9">
            <input
              checked={values.isSocialApiEnabled}
              name="isSocialApiEnabled"
              type="checkbox"
              onBlur={handleBlur}
              onChange={e => this.handleChange(e, handleChange)}
            />
          </dd>
        </dl>
        <dl className="row">
          <dt className="col-sm-3">Social Access Token</dt>
          <dd className={cn('col-sm-9', styles.socialAccessToken)}>
            <Input.Text
              readOnly={true}
              error={touched.socialAccessToken && errors.socialAccessToken}
              name="socialAccessToken"
              type="text"
              value={values.socialAccessToken}
              onBlur={handleBlur}
              onChange={e => this.handleChange(e, handleChange)}
            />
            <div className={styles.refreshButton} onClick={() => this.generateAccessToken(setFieldValue)}>
              <i className={`fas fa-sync-alt fa-lg`} />
            </div>
          </dd>
        </dl>
        <dl className="row">
          <dt className="col-sm-3">Can access for game</dt>
          <dd className="col-sm-9">
            <input
              checked={values.canAccessForGame}
              name="canAccessForGame"
              type="checkbox"
              onBlur={handleBlur}
              onChange={e => this.handleChange(e, handleChange)}
            />
          </dd>
        </dl>
        <dl className="row">
          <dt className="col-sm-3">Can manage board connections</dt>
          <dd className="col-sm-9">
            <input
              checked={values.canManageBoardConnections}
              name="canManageBoardConnections"
              type="checkbox"
              onBlur={handleBlur}
              onChange={e => this.handleChange(e, handleChange)}
            />
          </dd>
        </dl>
        <dl className="row">
          <dt className="col-sm-3">Forwarding to Scolia</dt>
          <dd className="col-sm-9">
            <input
              checked={values.defaultForwardingToScolia}
              name="defaultForwardingToScolia"
              type="checkbox"
              onBlur={handleBlur}
              onChange={e => this.handleChange(e, handleChange)}
            />
          </dd>
        </dl>
        <dl className="row">
          <dd className={cn('col-sm-12', styles.connectedBoardsWrapper)}>
            <ConnectedBoards
              boards={boards.filter(
                board => !values.connectedBoards.find(cb => cb.serialNumber === board.serialNumber)
              )}
              connectedBoards={values.connectedBoards}
              onChange={e => this.handleChange(e, handleChange)}
              errors={touched.connectedBoards && errors.connectedBoards}
              onSerialNumberInputChange={onSerialNumberInputChange}
              isSAForwarding={values.defaultForwardingToScolia}
            />
          </dd>
        </dl>

        {formError && <div className={cn(styles.formError, 'col-sm-12')}>{formError}</div>}

        <div className="action-footer">
          {isAdmin && (
            <button className="btn btn-success btn-md" type="submit">
              {isEdit ? 'Edit Service Account' : 'Create Service Account'}
            </button>
          )}
        </div>
      </form>
    );
  }
}

ServiceAccountForm.propTypes = {
  serviceAccount: PropTypes.object,
  formError: PropTypes.string,
  isEdit: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  boards: PropTypes.array.isRequired,
  isAdmin: PropTypes.bool,
};

export default compose(
  withFormik({
    mapPropsToValues: props => {
      const { serviceAccount = {} } = props;

      return {
        name: serviceAccount.name || '',
        email: serviceAccount.email || '',
        phoneNumber: serviceAccount.phoneNumber || '',
        isSocialApiEnabled: (serviceAccount.isSocialApiEnabled === undefined ? true : serviceAccount.isSocialApiEnabled),
        socialAccessToken: serviceAccount.socialAccessToken || randomBytes(16).toString('hex'),
        canAccessForGame: serviceAccount.canAccessForGame,
        canManageBoardConnections: serviceAccount.canManageBoardConnections,
        defaultForwardingToScolia: (serviceAccount.defaultForwardingToScolia === undefined ? true : serviceAccount.defaultForwardingToScolia),
        connectedBoards: serviceAccount.connectedBoards || [],
      };
    },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: props => getValidationSchema(props.boards),
    handleSubmit: (values, formikBag) => formikBag.props.onSubmit(values, formikBag),
  })
)(ServiceAccountForm);
