import React from 'react';
import { GameTypeEnum } from 'enums';
export const GeneralStatistics = ({ selectedGameType, userStats }) => {
    const isSelectedGameTypeCricket = selectedGameType === GameTypeEnum.Cricket || selectedGameType === GameTypeEnum.RandomCricket;
    let totalGamesCount;
    let totalAborted;
    let totalThrowsCount;
    if (isSelectedGameTypeCricket) {
        const { gamesCount, hitRate } = userStats;
        totalGamesCount = gamesCount?.total;
        totalAborted = gamesCount?.aborted;
        totalThrowsCount = hitRate?.total;
    }
    else {
        const { gamesCount, singleThrowsCount, throwsCount } = userStats;
        totalGamesCount = gamesCount.total + gamesCount.singleTotal;
        totalAborted = gamesCount.aborted + gamesCount.singleAborted;
        totalThrowsCount = throwsCount + singleThrowsCount;
    }
    const { gamesCount } = userStats;
    return (React.createElement("div", { className: "key-value-data-container" },
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col-sm-4" }, "Games Count"),
            React.createElement("div", { className: "col-sm-8" }, totalGamesCount)),
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col-sm-4" }, "Aborted games"),
            React.createElement("div", { className: "col-sm-8" }, totalAborted)),
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col-sm-4" }, "Throws Count"),
            React.createElement("div", { className: "col-sm-8" }, totalThrowsCount)),
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col-sm-4" }, "Win Rate"),
            React.createElement("div", { className: "col-sm-8" }, gamesCount.won + gamesCount.lost > 0
                ? `${((gamesCount.won / (gamesCount.won + gamesCount.lost)) * 100).toFixed(1)}% (won: ${gamesCount.won} / lost: ${gamesCount.lost})`
                : `- (won: ${gamesCount.won} / lost: ${gamesCount.lost})`))));
};
