import React from 'react';
import cn from 'classnames';
import { isNull } from 'lodash';
import { useMedia } from 'react-use';
import { MD } from 'constants/Breakpoints';
import { ArrowIcon, ArrowIconDirection } from 'components/Icons';
import { formatLabel } from 'utils';
import styles from './styles.module.scss';
export const WizardHeader = ({ subTitle, onBack }) => {
    const title = "Tournament settings";
    const isMediumDisplay = useMedia(`(min-width: ${MD}px)`);
    return (React.createElement("div", { className: styles.wizardHeader },
        subTitle && !isMediumDisplay && (React.createElement("button", { type: "button", className: cn(styles.headerButton, styles.active), onClick: onBack },
            React.createElement(ArrowIcon, { direction: ArrowIconDirection.Left }))),
        React.createElement("div", { className: styles.title }, isMediumDisplay ? title : (!isNull(subTitle) ? formatLabel(subTitle) : title))));
};
