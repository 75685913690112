import { handleActions, combineActions } from 'redux-actions';

import showActions from 'scenes/ServiceAccounts/components/Show/actions';

const INITIAL_STATE = {
  isLoading: false,
  isActivityLoading: false
};

const showReducer = handleActions(
  {
    [showActions.getServiceAccount]: state => ({
      ...state,
      isLoading: true,
    }),

    [showActions.getServiceAccountActivity]: state => ({
      ...state,
      isActivityLoading: true,
    }),

    [combineActions(
      showActions.getServiceAccountSuccess,
      showActions.getServiceAccountFailure
    )]: state => ({
      ...state,
      isLoading: false,
    }),

    [combineActions(
      showActions.getServiceAccountActivityFailure,
      showActions.getServiceAccountActivitySuccess
    )]: (state) => ({
      ...state,
      isActivityLoading: false,
    }),
  },
  INITIAL_STATE
);

export default showReducer;
