import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Create } from './components/Create';
import { List } from './components/List';
import { Show } from './components/Show';
import { Edit } from './components/Edit';
import { ImportTournaments } from './components/Import';
import { CreateInvitational } from './components/CreateInvitational';
import { EditInvitational } from './components/EditInvitational';
export function Tournaments({ match: { url } }) {
    return (React.createElement(Switch, null,
        React.createElement(Route, { render: props => React.createElement(ImportTournaments, { ...props, isMultiple: true }), exact: true, path: `${url}/import` }),
        React.createElement(Route, { component: Create, exact: true, path: [`${url}/create`, `${url}/create/:tournamentId`] }),
        React.createElement(Route, { component: Edit, exact: true, path: `${url}/edit/:tournamentId` }),
        React.createElement(Route, { component: CreateInvitational, exact: true, path: [`${url}/createInvitational`, `${url}/createInvitational/:tournamentId`] }),
        React.createElement(Route, { component: EditInvitational, exact: true, path: `${url}/editInvitational/:tournamentId` }),
        React.createElement(Route, { component: Show, exact: true, path: `${url}/:tournamentId` }),
        React.createElement(Route, { component: List, exact: true, path: `${url}/` })));
}
