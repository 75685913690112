import { call, put, takeLatest } from 'redux-saga/effects';

import { Tournaments } from 'services/api';
import { callApi } from 'services/rest';
import { notifyError, notifySuccess } from 'services/notification/actions';

import {
  copyTournaments,
  copyTournamentsFailure,
  copyTournamentsSuccess,
} from 'scenes/Tournaments/components/List/actions';

function* watchCopyTournaments({ payload: { days, filter } }) {
  const filterObj = {};
  filter.filters.filter.forEach(field => filterObj[field.id] = field.value)

  const sortObj = {};
  filter.filters.sort.forEach(field => sortObj[field.id] = field.value)

  try {
    const { data } = yield call(callApi, Tournaments.copy, {
      days,
      meta: {
        filter: filterObj,
        sort: sortObj,
        pageSize: filter.filters.pageSize,
        page: filter.filters.page,
      }
    });

    yield put(copyTournamentsSuccess(data));
    yield put(notifySuccess('Tournaments copied successfully'))
  } catch (e) {
    yield put(copyTournamentsFailure());
    yield put(notifyError(e?.data?.error ? e.data.error : 'Tournament copy failed'))
  }
}

// It must be wrapped in a function, so execution will happen only on function call
export default () => takeLatest(copyTournaments, watchCopyTournaments);
