import { createAction } from 'redux-actions';

export const updatePlatform = createAction('UPDATE_PLATFORM_REQUEST');
export const updatePlatformFailure = createAction('UPDATE_PLATFORM_FAILURE');
export const updatePlatformSuccess = createAction('UPDATE_PLATFORM_SUCCESS');

export default {
  updatePlatform,
  updatePlatformFailure,
  updatePlatformSuccess,
};
