import { createAction } from 'redux-actions';

export const getRankingList = createAction(
  'RANKING_LIST_GET_REQUEST',
  payload => ({
    queryParams: payload,
  })
);
export const getRankingListFailure = createAction('RANKING_LIST_GET_FAILURE');
export const getRankingListSuccess = createAction('RANKING_LIST_GET_SUCCESS');

export default {
  getRankingList,
  getRankingListFailure,
  getRankingListSuccess,
};
