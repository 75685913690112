import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';

import { currencyFormatter } from 'utils/currencyFormatter';
import { DownloadInvoiceButton } from '../DownloadInvoiceButton';
import { formatDate } from 'utils/timestampFormatter';

const HistoryTable = ({ history }) => {
  const historyTableData = () => {
    return history.map((item, idx) => (
      <tr key={`${idx}_${item.paddleTransactionId}`}>
        <td>{formatDate(item.date)}</td>
        <td>
          <span>{item.paddleTransactionId}</span>
        </td>
        <td>
          {currencyFormatter(item.currency)}
          {item.amount}
        </td>
        <td>{`${formatDate(item.billingPeriod.startsAt)} - ${formatDate(item.billingPeriod.endsAt)}`}</td>
        <td style={{textAlign: 'center'}}>
          <DownloadInvoiceButton
            paddleTransactionId={item.paddleTransactionId}
          />
        </td>
      </tr>
    ))
  }

  return (
    <Table responsive>
      <thead>
        <tr>
          <th>Date</th>
          <th>Transaction ID</th>
          <th>Price</th>
          <th>Subscribed period</th>
          <th />
        </tr>
      </thead>
      <tbody>{historyTableData()}</tbody>
    </Table>
  );
};

HistoryTable.displayName = 'HistoryTable';

HistoryTable.propTypes = {
  history: PropTypes.array.isRequired,
};

export default HistoryTable;
