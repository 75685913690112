import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { WHITE, LAPALMA } from 'constants/Colors';
import { ThemesEnum } from 'enums';
import styles from './styles.module.scss';
const FadeIn = ({ isActive, children }) => {
    return (React.createElement(CSSTransition, { unmountOnExit: true, in: isActive, timeout: 300, classNames: {
            enter: styles['fade-enter'],
            enterActive: styles['fade-enter-active'],
            exit: styles['fade-exit'],
            exitActive: styles['fade-exit-active'],
        } }, children));
};
export const CheckboxIcon = ({ isActive, theme = ThemesEnum.Light, onClick }) => {
    return (React.createElement("div", { className: cn(styles.checkbox, styles[theme]), onClick: onClick, role: onClick ? 'input' : 'div' },
        React.createElement(FadeIn, { isActive: !isActive },
            React.createElement("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                React.createElement("rect", { x: "0.5", y: "0.5", width: "15", height: "15", rx: "7.5" }),
                React.createElement("rect", { x: "0.5", y: "0.5", width: "15", height: "15", rx: "7.5", className: styles.inactiveBorder }))),
        React.createElement(FadeIn, { isActive: isActive },
            React.createElement("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                React.createElement("rect", { width: "16", height: "16", rx: "8", fill: LAPALMA }),
                React.createElement("path", { d: "M11.3333 5.5L6.74996 10.0833L4.66663 8", stroke: WHITE, strokeWidth: "1.66667", strokeLinecap: "round", strokeLinejoin: "round" })))));
};
CheckboxIcon.displayName = 'CheckboxIcon';
CheckboxIcon.propTypes = {
    isActive: PropTypes.bool.isRequired,
    theme: PropTypes.oneOf(Object.values(ThemesEnum)),
    onClick: PropTypes.func,
};
export default CheckboxIcon;
