import React, { Component } from 'react';

import { SystemUsage } from './components/SystemUsage';
import { SystemAnalytics } from './components/SystemAnalytics';

class SystemUsages extends Component {
  render() {
       return (
      <div>
        <SystemUsage/>
        <SystemAnalytics/>
      </div>
    );
  }
}

export default SystemUsages;
