import { get, del} from 'services/rest';

const url = '/system';

const StoreSnapshot = {
  index: (queryParams) => get(`${url}/store-snapshot`, queryParams),
  deleteWSConnection: (connectionKey) => del(`${url}/websocket/${connectionKey}`),
  deleteLobbyInvitation: (id) => del(`${url}/invitations/${id}`),
};


export default StoreSnapshot;
