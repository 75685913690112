import React from 'react'
import cn from 'classnames';

import styles from "./styles.module.scss"

const Button = ({ onClick, btnLabel, isDisabled }) => {
  return (
    <div className="row">
      <div className="col-md-5"></div>
      <div className="col-md-7">
        <div className={styles.submitBtn}>
          <button
            className={cn('btn btn-primary btn-md -align-right', styles.actionButtons)}
            type='submit'
            onClick={onClick}
            disabled={isDisabled}
          >
            {btnLabel}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Button