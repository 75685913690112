import { getLocalTime } from './getLocalTime';

export const csvTimeZoneCorrection = (csvData, { separator } = { separator: ';' }) => {
  if (typeof csvData !== "string") {
    return csvData;
  }
  if (!csvData?.includes('date')) {
    return csvData;
  }

  const csvRows = csvData.split('\n'); // remove quotes and split to rows
  const csvCells = csvRows.map(row => row.split(separator)); // split to cells by separator
  const [header, ...body] = csvCells; // split header and body

  const csvDateColNrs = header.reduce((acc, headerCell, index) => {
    if (headerCell.includes('date')) {
      acc.push(index);
    }
    return acc;
  }, []); // find date columns

  const csvDateRows = body.map(row => {
    csvDateColNrs.forEach(csvDateColNr => {
      const date = row[csvDateColNr];
      if (date && date !== 'Invalid Date') {
        const localDate = getLocalTime(date);
        const correctedDate = new Date(localDate);
        row[csvDateColNr] = correctedDate?.toISOString();
      }
    });
    return row;
  }); // correct date columns

  const newTable = [header, ...csvDateRows]
    .map(rows => rows.join(separator))
    .join('\n'); // join back to csv

  return newTable;
};
