import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import { List } from './components/List';

class BoardUpdateProgress extends Component {
  render() {
    const {
      match: { url },
    } = this.props;

    return (
      <Switch>
        <Route component={List} exact name="Board Update Progress List" path={`${url}/`} />
      </Switch>
    );
  }
}

export default BoardUpdateProgress;
