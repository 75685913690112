import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import { getPlatformById } from "data/Platforms/selectors"
import { getPlatform } from 'scenes/Platforms/actions';

import { getIsAdmin } from 'services/session/selectors';

import actions from './actions';
import { getUpdateState } from "./selectors"
import { PlatformForm } from '../PlatformForm';

const Edit = ({ match }) => {
  const dispatch = useDispatch();
  const { isUpdateSuccessful, updateError, isLoading } = useSelector(getUpdateState);
  const platform = useSelector(getPlatformById(match.params.platformId));
  const isAdmin = useSelector(getIsAdmin);

  useEffect(() => {
    dispatch(getPlatform(match.params.platformId));
  }, [dispatch, match.params.platformId])

  const handleFormSubmit = ({ ...platformProperties }, _actions) => {
    dispatch(
      actions.updatePlatform({
        platformId: match.params.platformId,
        description: platformProperties.description,
      })
    );
  };

  if (!platform) {
    return null;
  }

  if (isLoading) {
    return <i className="fa fa-spinner fa-spin fa-fw" />;
  }

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" lg={{ size: 10, offset: 1 }}>
          <Card>
            <CardHeader>
              <div className="card-header__left">
                <i className="fa fa-align-justify" /> Edit Platform
              </div>
            </CardHeader>
            <CardBody>
              <PlatformForm
                platform={platform}
                onSubmit={handleFormSubmit}
                isSuccessful={isUpdateSuccessful}
                formError={updateError}
                isEdit
                isAdmin={isAdmin}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

Edit.displayName = 'Platforms.Edit';

export default Edit
