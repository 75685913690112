import { call, put, takeLatest } from 'redux-saga/effects';

import { Notifications } from 'services/api';
import { callApi } from 'services/rest';

import {
  getNotifications,
  getNotificationsFailure,
  getNotificationsSuccess
} from 'scenes/Notifications/actions';

function* watchGetNotifications({ payload: { queryParams } }) {
  try {
    const { data } = yield call(callApi, Notifications.index, queryParams);
    yield put(getNotificationsSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(getNotificationsFailure());
  }
}

export default () => takeLatest(getNotifications, watchGetNotifications);
